import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from "react-phone-number-input/input";
import { Button, Spinner, Modal } from "react-bootstrap";

import { Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  postMethodData,
  postMethodDataUser,
} from "../../../../../admin/adminApi/api";
import LoginUser from "../loginUser/loginUser";
import {
  modalToggelValue,
  modalRgisterlValue,
  modalConditionValue,
  modalPolicyValue,
  caddRegisterValue,
  designPathValue,
} from "../../../../../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";

import FormValidation from "../../../../../../formValidation/formValidation";

const RegisterUser = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [mobileErr, setMobileErr] = useState([""]);
  const [emailErr, setEmailErr] = useState([""]);
  const [modalLoginShow, setModalLoginShow] = React.useState(false);
  const USERTOKEN = localStorage.getItem("userToken");
  const ModalRegisterValue = useSelector((state) => state.modalRegisterReducer);
  const CaddRegister = useSelector((state) => state.caddRegisterReducer);
  const designRes = useSelector((state) => state.designResPathValueReducer);

  // form validation rules
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum.")
      .max(18, "Password is too long - should be 18 chars minimum.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
        "Please Check Password Format"
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .min(6, "Confirm password is too short - should be 6 chars minimum.")
      .max(18, "Password is too long - should be 18 chars minimum.")
      .required("Confirm Password is required"),
    email: Yup.string().email("The email must be a valid email. "),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  // const dispatch = useDispatch()
  // const PORT = PORTNUMBER.PORTNUMBER

  const Token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [passwordErr, setPassErr] = useState();

  const [checkedValue, setCheckedValue] = useState(true);

  const [visibility, setVisibility] = useState(true);
  const [loading, setLoading] = useState(false);

  const [visibilityConfirm, setVisibilityConfirm] = useState(true);
  const FormValidationNew = FormValidation;

  const [formValidation, setFormValidation] = useState({
    Email: "",
    Mobile: "",
    default: "",
    Phone: "",
    setPassErrFormat: "",
    Success: null,
  });

  const designPatFunc = useCallback(() => {
    if (designRes === true) {
      dispatch(designPathValue(true));
    }
  }, [designRes]);

  const pwVisibility = (value) => {
    setVisibility(value);
  };
  const pwVisibilityConfirm = (value) => {
    setVisibilityConfirm(value);
  };

  useEffect(() => {
    setMobileErr("");
    setEmailErr("");
  }, [props]);

  useEffect(() => {
    reset();
    setValue("");
  }, [ModalRegisterValue]);

  const onSubmit = (data) => {
    setLoading(true);
    data.register_with_cad_file = CaddRegister;
    postMethodDataUser("/register", data, USERTOKEN)
      .then((res) => {
        if (res?.data?.status === true) {
          setLoading(false);
          if (res.data.result.data.token) {
            localStorage.setItem("userToken", res.data.result.data.token);
            localStorage.setItem(
              "username",
              res.data.result.data.user.first_name
            );
            localStorage.setItem("roleUser", res.data.result.data.user.role_id);

            dispatch(caddRegisterValue(false));

            dispatch(modalToggelValue(false));
            dispatch(modalRgisterlValue(false));
            designPatFunc();
          } else {
            dispatchToggle();
          }
          if (res?.data?.message !== "") {
            toast(res?.data?.message);
          }
          props.onHide();
          navigate("/");
          reset();
        } else {
          setLoading(false);
          setMobileErr(res?.data?.result?.errors?.phone);
          setEmailErr(res?.data?.result?.errors?.email);
          // toast.error(res?.data?.message)
        }

        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
    return false;
  };

  useEffect(() => {
    FormValidationNew();
  }, [FormValidationNew, formOptions]);

  const dispatchToggle = () => {
    dispatch(modalToggelValue(true));
    dispatch(modalRgisterlValue(false));
  };

  return (
    <React.Fragment>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content">
          <div className="create-account">
            <div className="row">
              <div className="col-md-12 position-relative;">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => props.onHide()}
                />
                <h3 className="text-center m-0 py-3">
                  Please Sign In or Create an Account
                </h3>
                <h6 className="text-center m-0 pb-5">
                    Have an Account?
                  <a
                    className="pt-3 ms-1"
                    data-bs-toggle="modal"
                    data-bs-target="#signinmodal"
                    onClick={() => dispatchToggle()}
                  >
                    Sign In
                  </a>
                </h6>
                <small className=" form-text d-block text-center  text-muted">
                  {formValidation.default}
                </small>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="needs-validation"
                  noValidate
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          maxLength="15"
                          className="form-control"
                          id="validationCustom01"
                          {...register("first_name", { required: true })}
                          placeholder="First Name*"
                          required
                        />
                        <div className="invalid-feedback">
                          Please Enter Customer First Name.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          maxLength="15"
                          className="form-control"
                          id="validationCustomNew"
                          {...register("last_name", { required: true })}
                          placeholder="Last Name*"
                          required
                        />
                        <div className="invalid-feedback">
                          Please Enter Customer Last Name.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="email"
                          maxLength="100"
                          className="form-control"
                          name="email"
                          id="validationCustomNew1"
                          {...register("email", { required: true })}
                          placeholder="Email*"
                          required
                        />

                        <small className="text-danger">
                          {" "}
                          {errors.email?.message}
                        </small>
                        <div className="invalid-feedback">
                          Please Enter Valid Email Address.
                        </div>
                        <small className="form-text text-muted">
                          {emailErr}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          maxLength="100"
                          className="form-control"
                          name="company_name"
                          id="validationCustomNew2"
                          {...register("company_name", { required: false })}
                          placeholder="Company Name"
                        />

                        <div className="invalid-feedback">
                          Please Enter Company Name.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <Input
                          className="form-control"
                          minLength={14}
                          maxLength={14}
                          name="phone"
                          defaultCountry="US"
                          placeholder="Phone*"
                          {...register("phone", { required: true })}
                          value={value}
                          onChange={setValue}
                          required
                        />

                        <small className="font-12 d-block">
                          E.g (xxx) xxx-xxxx , No Need for Country Code
                        </small>

                        <small className="form-text text-muted">
                          {mobileErr}
                        </small>
                        <div className="invalid-feedback">
                          Please enter mobile number.
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group icon-position">
                        <input
                          type={visibility ? "password" : "text"}
                          maxLength="18"
                          className={`form-control ${
                            errors.password ? "is-invalid" : ""
                          }`}
                          id="password"
                          name="password"
                          minLength="6"
                          {...register("password", { required: true })}
                          placeholder="Password*"
                          required
                        />
                        {visibility ? (
                          <span
                            className="icon"
                            onClick={() => pwVisibility(false)}
                          >
                            <FaEyeSlash />
                          </span>
                        ) : (
                          <span
                            className="icon"
                            onClick={() => pwVisibility(true)}
                          >
                            <FaEye />
                          </span>
                        )}
                        <small className="font-12 d-block">
                          ( 6 Digit, Alphanumeric with 1 Special Char and 1
                          Capital Letter )
                        </small>

                        <small className="text-danger">
                          {" "}
                          {errors.password?.message}
                        </small>

                        <div className="invalid-feedback">
                          Please enter valid password.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group icon-position password-field">
                        <input
                          type={visibilityConfirm ? "password" : "text"}
                          maxLength="18"
                          name="confirm_password"
                          minLength="6"
                          className={`form-control ${
                            errors.confirm_password ? "is-invalid" : ""
                          }`}
                          id="validationCustom05"
                          {...register("confirm_password", { required: true })}
                          placeholder="Confirm Password*"
                          required
                        />
                        {visibilityConfirm ? (
                          <span
                            className="icon"
                            onClick={() => pwVisibilityConfirm(false)}
                          >
                            <FaEyeSlash />
                          </span>
                        ) : (
                          <span
                            className="icon"
                            onClick={() => pwVisibilityConfirm(true)}
                          >
                            <FaEye />
                          </span>
                        )}
                        <small className="font-12 d-block">
                          ( 6 Digit, Alphanumeric with 1 Special Char and 1
                          Capital Letter )
                        </small>
                        <small className="text-danger">
                          {" "}
                          {errors.confirm_password?.message}
                        </small>

                        <div className="invalid-feedback">
                          Please enter valid confirm password.
                        </div>
                        <small className=" form-text text-muted">
                          {passwordErr}
                        </small>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="invalidCheck2"
                          onClick={() => setCheckedValue(!checkedValue)}
                          required
                          checked={checkedValue}
                        />
                        <label className="form-check-label">
                          <span className="text-light-gray  ms-2">
                            I agree to Mach2{" "}
                            <span
                              onClick={() =>
                                dispatch(modalConditionValue(true))
                              }
                              className="text-decoration-none text-primary pointer"
                            >
                              Terms of Service
                            </span>{" "}
                            and{" "}
                            <span
                              onClick={() => dispatch(modalPolicyValue(true))}
                              className="text-decoration-none pointer text-primary"
                            >
                              Privacy Policy
                            </span>
                          </span>
                        </label>
                        <div
                          id="invalidCheck3Feedback"
                          className="invalid-feedback"
                        >
                          You must agree before submitting.
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 text-center">
                      {loading === false ? (
                        <button
                          type="submit"
                          className="btn btn-primary-orange m-auto  mt-3"
                        >
                          Create An Account
                        </button>
                      ) : (
                        <Button
                          className="btn  btn-primary-orange m-auto  mt-3 spinner"
                          variant="dark"
                          disabled
                        >
                          <Spinner
                            as="span"
                            animation="grow"
                            className="me-2"
                            role="status"
                            size="sm"
                            aria-hidden="true"
                          />
                          Please wait...
                        </Button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default RegisterUser;
