import React, { useState, useEffect, useCallback } from "react";
import { getMethodDataUser } from "../../../../../admin/adminApi/api";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegBell } from "react-icons/fa";

import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import Pagination from "react-js-pagination";
const NotificationListsss = () => {
  const USERTOKEN = localStorage.getItem("userToken");
  const SUPPLIERTOKEN = localStorage.getItem("supplierToken");
  const [value, setValue] = useState([]);
  const [time, settime] = useState();
  const getProfile = useCallback(() => {
    getMethodDataUser("/notifications", USERTOKEN || SUPPLIERTOKEN).then(
      (res) => {
        setValue(res.data.result.data);
      }
    );
  }, [USERTOKEN]);
  function  clear_notification() {
    getMethodDataUser("/clear-notifications", USERTOKEN || SUPPLIERTOKEN)
    setValue([])
  }

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <React.Fragment>
      <section className="analyst-sec">
        <div className="container">
          <div className="row">
            <section className="pt-5 mt-3">
              <div className="container dashboard-heading-block py-3">
                <div className="row align-items-center">
                  <div className="col-lg-12 dashboard-headline text-start">
                    <span style={{ fontSize: "35px", fontWeight: "900" }}>
                      Notification List
                    </span>
                    {(value?.length != 0) 
                    ?
                    <button
                      style={{ float: "right" }}
                      className="btn btn-primary-orange m-0 min-hp-40 dashboard-headline"
                      onClick={() => { clear_notification()}} 
                    >
                      Clear All
                    </button>
                    :
                    null
                    }
                    
                  </div>
                </div>
              </div>
            </section>
            <section className="pb-5 pt-3 mt-5 edit-profile-page">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 mt-3  ">
                    {value?.map((item) => {
                      // console.log(item);
                      return (
                        <div className="tab-head box rounded shadow d-flex align-items-center py-2 px-5 mb-3">
                          <div style={{ fontSize: "30px" }}>
                            <FaRegBell />
                          </div>
                          <div className="graph w-100" >
                            <div className="box-con pl-3  d-flex align-items-center justify-content-between  ">
                              <p> {item.title} </p>
                              <span>{item.message}</span>
                         
                              <span style={{ fontSize: "11px" }}>
                                {item.notification_age}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default NotificationListsss;
