import React, { useEffect, useState } from 'react'
import { useParams,useNavigate, Link } from 'react-router-dom';
import { GiAutoRepair } from 'react-icons/gi';
import Input from 'react-phone-number-input/input'
import { getMethodDataUser,postMethodDataUser } from '../../../../admin/adminApi/api'
import { toast } from 'react-toastify';
import axios from 'axios';

let USERTOKEN = localStorage.getItem("userToken")

const Checkout = () => {

  const stateList = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ];



    const navigate = useNavigate();
    const {id} = useParams()
    const [loading, setLoading] = useState(false)
    const [loadingcard, setLoadingcard] = useState(false)

    const [codeBtn, setcodeBtn] = useState(false);
    const [quoteparts,setQuoteparts] = useState()
    const [orderSummery,setorderSummery] = useState()
    const [promocode,setPromocode] = useState()
    const [disabledcode,setDisabledcode] = useState(false)
    const [promocodeprice,setPromocodeprice] = useState()
    const [Shippingmain,setShippingmain] = useState(true)
    
    const [nullvalue,setnullvalue] = useState()
    
    
    const [Shipping,setShipping] = useState(false)

    const [Shippingindex,setShippingindex] = useState(0)

    const [Shippingaddressindex,setShippingaddressindex] = useState(0)

    const [Shippingbtn,setShippingbtn] = useState(false)

    const [Billingmain,setBillingmain] = useState(false)
    const [Billing,setBilling] = useState(false)

    const [Billingbtn,setBillingbtn] = useState(false)

    const [Shippingaddress,setShippingaddress] = useState()
    const [Billingaddress,setBillingaddress] = useState()

    const [ShippingId,setShippingId] = useState()

    const [BillinggId,setBillinggId] = useState()

    const [FormValue,setFormValue] = useState()

    const [ShippingCost,setShippingCost] = useState()

    const [sameAddress,setSameAddress] = useState(false)

    const [formValuenew, setFormValuenew] = useState({
      address_name: "",
      contact_name: "",
      phone: "",
      street: "",
      address: "",
      city: "",
      state: "",
      postal_code: "",
    });

    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormValuenew((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    };
    const { address_name, contact_name, phone, street,address, city, state, postal_code } = formValuenew;

// quote details
    const getQuotedetails = () => {
      setLoadingcard(false)
        getMethodDataUser(`/quotes/details/${id}`,USERTOKEN).then((res) => {
            if(res.data.status == true){
              setorderSummery(res.data.result.data)
              setQuoteparts(res.data.result.data.quote_parts)
              setLoadingcard(true)
            }
            setLoadingcard(true)
        })
    }

  // apply promocode
  const promocodeapi = (e) => {
    setLoadingcard(false)
      if(!promocode){
        toast("The Promocode field is required.")
        setLoadingcard(true)
      }
      else{
        postMethodDataUser(`/apply-coupon-code`,{coupon_code:promocode,quote_id:id},USERTOKEN).then((res) => {
          if(res.data.status === true){
            toast.success(res.data.message)
            getQuotedetails()
            setPromocodeprice(res.data.result.data.discount.toFixed(2))
            setcodeBtn(true)
            setDisabledcode(true)
            setLoadingcard(true)
          }
          else if(res.data.result.data.code_already_used === true){
            toast.warning(res.data.message)
            setDisabledcode(false)
            setLoadingcard(true)
          }
          else{
            setDisabledcode(false)
            toast.warning(res.data.message)
            setLoadingcard(true)
          }
      })
      }
  }

// remove promocode
  const removepromocodeapi = (e) => {
    setLoadingcard(false)
      postMethodDataUser(`/remove-coupon-code`,{quote_id:id},USERTOKEN).then((res) => {
        if(res.data.status == true){
        toast.success(res.data.message)
        setcodeBtn(false)
        setDisabledcode(false)
        setPromocode('')
        setPromocodeprice()
        getQuotedetails()
        setLoadingcard(true)
        }
        else{
          setLoadingcard(true)
          toast.warning(res.data.message)
        }
    })
}

// shipping address list
  const getShippingaddress = () => {
    setLoading(false);
    getMethodDataUser(`/shipping-addres-list`,USERTOKEN).then((res) => {
        if(res.data.result.data.length < 1){
          setShipping(true)
          setShippingbtn(false)
          setLoading(true);
          
        }else{
          setShippingaddress(res.data.result.data)
          setShippingbtn(true)
          setLoading(true);
        }
    })
}

// billing address list
const getBillingaddress = () => {
  setLoading(false);
  getMethodDataUser(`/billing-addres-list`,USERTOKEN).then((res) => {
      if(res.data.result.data.length < 1){
        setShippingmain(false)
        setBillingmain(true)
        setBilling(true)
        setBillingbtn(false)
        setLoading(true);
      }else{
        setBillingaddress(res.data.result.data)
        setShippingmain(false)
        setBillingmain(true)
        setBilling(false)
        setBillingbtn(true)
        setLoading(true);
      } 
  })
}
const getBillingaddressagain = () => {
  setLoading(false);
  getMethodDataUser(`/billing-addres-list`,USERTOKEN).then((res) => {
      if(res.data.result.data.length < 1){
        setBilling(true)
        setBillingbtn(false)
        setLoading(true);
      }else{
        setBillingaddress(res.data.result.data)
        setBilling(false)
        setBillingbtn(true)
        setLoading(true);
      } 
  })
}
// post shipping address api
    const onSubmitNew = (e) => {
      e.preventDefault()
      setLoading(false);
      var inputs = document.getElementsByClassName('form-control');
      const fd = new FormData();
      setFormValue(fd)
      for (let i = 0; i < inputs.length; i++) {
          fd.append(inputs[i].name, inputs[i].value);
      }
      postMethodDataUser(`/shipping-addres/store`,fd, USERTOKEN)
          .then((res) => {
              if(res.data.status === true){
                getShippingaddress()
                setShippingId(res.data.result.data.address_id)
                setShipping(false)
                setShippingmain(false)
                getShippingCost(res.data.result.data.address_id)
                getBillingaddress()
                setLoading(true);
              }
              else if(res.data.result.errors.street != null){
                setLoading(true);
                toast.warning(res.data.result.errors.street[0])
                
              }
              setLoading(true);
              toast.warning(res.data.result.errors[0].message)
              
              toast.warning(res.data.result.errors[1].message)
              
              
          }).catch((error) => {
              // console.log(error);
          })

    };

// post billing address api
    const onSubmitbilling = (e) => {
      e.preventDefault()
      setLoading(false);
      var inputs = document.getElementsByClassName('form-control');
      const fd = new FormData();
      setFormValue(fd)
      for (let i = 0; i < inputs.length; i++) {
          fd.append(inputs[i].name, inputs[i].value);
      }
      postMethodDataUser(`/billing-addres/store`,fd, USERTOKEN)
          .then((res) => {
              if(res.data.status === true){
                getBillingaddressagain()
                setBillinggId(res.data.result.data.address_id)
                setBillingmain(false)
                setShippingmain(false)
                setLoading(true);
              }
              else if(res.data.result.errors.street != null){
                setLoading(true);
                toast.warning(res.data.result.errors.street[0])
              }
              setLoading(true);
              toast.warning(res.data.result.errors[0].message)
              toast.warning(res.data.result.errors[1].message)

          }).catch((error) => {
              // console.log(error);
          })

    };

    // get shipping cost api
  const getShippingCost = (e) => {
      setLoadingcard(false)
      
      postMethodDataUser(`/get-order-shipping-costs`,{quote_id:id,shipping_address_id:e},USERTOKEN)
      .then((res) => {
        if(res.data.status === true){
          
          setShippingCost(res.data.result.data)
          setLoadingcard(true)
        }
        }).catch((error) => {
            // console.log(error);
        })
    }
    

    useEffect(() => {
        USERTOKEN = localStorage.getItem("userToken")
        getQuotedetails()
        getShippingaddress()
    }, [])

    const shippingidform = () => {
      if(ShippingId == null){
        toast.warning("Please Select Shipping Address.")
      }else{
        setBilling(true)
        setBillingmain(true)
        getBillingaddress()
        setShipping(false)
        setShippingmain(false)
      }
    }

    const billingidform = () => {
      if(BillinggId == null){
        toast.warning("Please Select Billing Address.")
      }else{
        setBillingmain(false)
        setShippingmain(false)
      }
    }


    // admin settings if shipping api not working
    const [modifyData, setModifyData] = useState();
    useEffect(() => {
      const BASE_URL = process.env.REACT_APP_BASE_URL_USER;
      axios.get(`${BASE_URL}/user/get-globle-config/ENABLE_SHIPPING`).then((req) => {
        setModifyData(req?.data?.result?.data?.value);
        // // console.log(req?.data?.result?.data?.value);
      });
    }, []);



    // proceed to payment api

    const ProceedPayment = (e) => {
      postMethodDataUser(`/checkout/update-shipping-details`,{
        quote_id:id,
        shipping_address_id:ShippingId,
        billing_address_id:BillinggId,
        shipping_service_code:(modifyData === "Yes" ? ShippingCost[Shippingindex].service_code : 0),
        shipping_amount:(modifyData === "Yes" ? ShippingCost[Shippingindex].total_shipping_amount : 0),
        coupon_code:promocode,
        discount_amount: promocodeprice
      },
        USERTOKEN)
      .then((res) => {
        if(res.data.status === true){ 
          toast.success(res.data.message)
          navigate(`/customer/payment${res.data.result.data.id}`)
        }
        else{
          // console.log(res.data)
          toast.warning(res.data.message)
        }
       }).catch((error) => {
            // console.log(error);
        })
      }
      function transaction_failed(){
        toast.warning("Something Went wrong  Please Try Again Later  ")
      }

      const SameaddressFunction = () => {
          if(document.getElementById("sameaddress").checked == true){
            setSameAddress(true)
            setBillinggId()
          }
          else{
            setFormValuenew({
              address_name: "",
              contact_name: "",
              phone: "",
              street: "",
              address: "",
              city: "",
              state: "",
              postal_code: "",
            })
            setSameAddress(false)
          }
      }

      const dateStr = ShippingCost ? (ShippingCost?.[Shippingindex ?? 0].estimated_delivery_date) != "-" ? ShippingCost?.[Shippingindex ?? 0].estimated_delivery_date :"" : "";
      let formattedDate = "TBA";

      if (dateStr) {
        const [day, month, year] = dateStr.split("-");
        formattedDate = `${month}-${day}-${year}`;
      }
      
    return (
        <React.Fragment>
        <section className="pt-5 mt-3">
          <div className="container dashboard-heading-block py-3">
            <div className="row align-items-center">
              <div className="col-lg-6 dashboard-headline text-start">
                <h2>Check Out</h2>
       
              </div>
              <div className="col-lg-6 mb-5 text-md-end text-center">
                <Link to={`../quotes/quotedetails${id}`}>
                  <button type="button" className="btn btn-primary-orange">Back To Quote</button>
                  </Link>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row flex-lg-row-reverse flex-md-row-reverse">
              <div className="col-lg-5 position-relative">
              {
                  !loadingcard ?
                    <div className="loader-wrapper">
                      <div className="loader">
                        <div className="loading-svg">
                          <GiAutoRepair />
                        </div>
                      </div>
                    </div> : ""
                  }
                <div className="white-bg-box">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="coupan-box">
                        <div className="coupan-header"><h5>Apply promo code</h5></div>
                        <div className="coupan-body px-0">
                          <p className="semi-bold-font">Promotion code ( one per order )</p>
                          <form className='form-top'>
                            <div className="input-box">
                              <input 
                              value={promocode}
                              defaultValue={promocode}
                              onChange={(e) => {setPromocode(e.target.value);setcodeBtn(false)}} 
                              type="text" 
                              disabled={disabledcode ? true : false}
                              placeholder={"Promocode"} />
                              {
                                codeBtn
                                ?
                                <button onClick={() => removepromocodeapi()} type="button" className>Remove</button>
                                :
                                <button onClick={() => promocodeapi()} type="button" className>Apply</button>
                              }
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                      <div className="col-lg-6 col-md-6 col-6"><p>Total Items</p></div>
                      <div className="col-lg-6 col-md-6 col-6 text-end"><p>{orderSummery ? orderSummery.quote_parts_count : 0}</p></div>
                  </div>
                  <div className="row">
                      <div className="col-lg-6 col-md-6 col-6"><p>Estimated Delivery</p></div>
                      <div className="col-lg-6 col-md-6 col-6 text-end"><p>{formattedDate ? (formattedDate ?? 'TBA') : 'TBA'}</p></div>
                  </div>
                  <div className="row border-top-total-price mt-3 pt-3">
                      <div className="col-lg-6 col-md-6 col-6"><p>Subtotal</p></div>
                      <div className="col-lg-6 col-md-6 col-6 text-end"><p>${orderSummery ? orderSummery.product_price : 0}</p></div>
                  </div>
                  <div className="row">
                      <div className="col-lg-6 col-md-6 col-6"><p>Shipping Fees</p></div>
                      <div className="col-lg-6 col-md-6 col-6 text-end">{
                        ShippingCost
                        ?
                        <p>
                          ${ShippingCost?.[Shippingindex ?? 0].total_shipping_amount}
                        </p>
                        :
                        <p>TBA</p>
                      }</div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-6"><p>Discount</p></div>
                    <div className="col-lg-6 col-md-6 col-6 text-end"><p>${promocodeprice ?? 0}</p></div>
                  </div>
                  <div className="row pt-3 pb-3 border-top-total-price mt-3">
                    <div className="col-lg-6 col-md-6 col-6"><h5>Total Price</h5></div>
                    <div className="col-lg-6 col-md-6 col-6 text-end">
                      <h5>
                      ${orderSummery 
                      ? 
                      (parseFloat(orderSummery.product_price) 
                      -
                      parseFloat(promocodeprice ?? 0)
                      + 
                      parseFloat(ShippingCost?.[Shippingindex ?? 0].total_shipping_amount 
                      ?? 
                      '0'
                      )
                      ).toFixed(2) : 0 }</h5>
                    </div>
                  </div>
              {ShippingCost?.[Shippingindex ?? 0].total_shipping_amount == 0 ?  <>
                <div className="row">
                          <div className="col-lg-12">
                                  {!Shippingmain && !Billingmain && ShippingId && BillinggId
                                  ?
                                  <button 
                                  // onClick={(e) => {transaction_failed(e)}}
                                  onClick={(e) => { if(modifyData === "No"){
                                    ProceedPayment(e)
                                  }
                                  else{
                                    transaction_failed(e)
                                    // ProceedPayment(e)
                                  }
                                  }}
                                  type="button" 
                                  className="btn btn-primary-orange m-0 w-100">Proceed to Payment</button>
                                  :
                                  <button 
                                  disabled
                                  type="button" 
                                  className="btn btn-primary-orange m-0 w-100">Proceed to Payment</button>
                                  }
                          </div>
                          
                      </div>
                  
              
              
              
              </>: <>
            
              <div className="row">
                          <div className="col-lg-12">
                                  {!Shippingmain && !Billingmain && ShippingId && BillinggId
                                  ?
                                  <button 
                                  onClick={(e) => {ProceedPayment(e)}}
                                  type="button" 
                                  className="btn btn-primary-orange m-0 w-100">Proceed to Payment</button>
                                  :
                                  <button 
                                  disabled
                                  type="button" 
                                  className="btn btn-primary-orange m-0 w-100">Proceed to Payment</button>
                                  }
                          </div>
                      </div>
              
              </>}
                
                </div>
              </div>
              <div className="col-lg-7 position-relative">
              {
              !loading ?
                <div className="loader-wrapper">
                  <div className="loader">
                    <div className="loading-svg">
                      <GiAutoRepair />
                    </div>
                  </div>
                </div> : ""
              }
              {
                  Shippingmain
                  ?
                  (Shipping
                  ?
                  <>
                <h4 className="mb-4 mt-5">Shipping Address</h4>
                <form onSubmit={(e)=>onSubmitNew(e)}>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <input 
                    name='address_name'
                    type="text" 
                    placeholder="Address name" 
                    className="form-control mb-4 input-design" 
                    required={true}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <input 
                    name='contact_name'
                    type="text" 
                    placeholder="Contact name" 
                    className="form-control mb-4 input-design" 
                    required={true}
                    />
                  </div>
                </div>
                <div className="row">
                  
                  <div className="col-lg-6 col-md-6">
                    <input 
                    name='address'
                    type="text" 
                    placeholder="Address 1" 
                    maxLength="40"
                    className="form-control mb-4 input-design" 
                    required={true}
                    />
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <input 
                    name='street'
                    type="text" 
                    placeholder="Address 2" 
                    className="form-control mb-4 input-design" 
                    // required={true}
                    />
                  </div>

                </div>
                <div className="row">
                  
                  <div className="col-lg-6 col-md-6">
                    <input 
                    name='city'
                    type="text" 
                    placeholder="City" 
                    className="form-control mb-4 input-design" 
                    required={true}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {/* <input 
                    name='state'
                    type="text" 
                    placeholder="State" 
                    className="form-control mb-4 input-design" 
                    required={true}
                    /> */}
                    <select name='state' required={true} defaultValue="" className="form-control mb-4 input-design" >
                      <option className='d-none' value="">State</option>
                      {stateList?.map((data,index) => (
                        <option value={data?.abbreviation} key={index}>
                          {data?.name}
                        </option>
                      ))}
                      
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <input 
                    name='postal_code'
                    type="text" 
                    placeholder="ZipCode" 
                    className="form-control mb-4 input-design" 
                    required={true}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <input 
                    name='country'
                    type="text" 
                    placeholder="Country" 
                    className="form-control mb-4 input-design" 
                    value={'United States'}
                    disabled
                    required={true}
                    />
                  </div>
                </div>
                <div className="row">
                  
                  <div className="col-lg-6 col-md-6">
                    <Input 
                    name='phone'
                    type="text"
                    value={''}
                    onChange={() => {}}
                    defaultCountry="US"
                    minLength={14}
                    maxLength={14}
                    placeholder="Phone" 
                    className="form-control mb-4 input-design" 
                    required={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mb-5 mt-3 d-flex align-items-center justify-content-between">
                    {Shippingbtn
                    ?
                    <button onClick={(e) => {setShipping(!Shipping)}} type="button" className="btn btn-primary-outline  btn-max-width-134">Back</button>
                  :
                  <></>}
                    <button type="submit" className="btn btn-primary-orange  btn-max-width-134">Next</button>
                  </div>
                </div>
                </form>
                  </>
                  :
                  <>
                  <form>
                  <div className="row">
                  <div className="col-lg-6 col-md-6">
                     <h4 className="mb-4 mt-5">Shipping Address</h4>
                  </div>
                  <div className="col-lg-6 col-md-6 text-end">
                    <button 
                    className='border-0 p-0 mb-4 mt-5' 
                    onClick={(e) => setShipping(!Shipping)}>
                    <ins>Add new shipping Address</ins>
                    </button>
                  </div>
                  {Shippingaddress?.map((items1,index) => (
                    <div key={index} className="col-lg-6 col-md-6 mb-3">
                      <div className='border p-3'>
                      <div className='d-flex align-items-center'>
                      <input 
                      defaultChecked={(ShippingId == items1.id) ? true : false}
                      checked={(ShippingId == items1.id) ? true : false} 
                      onClick={(e) => {setShippingId(e.target.value);getShippingCost(e.target.value);setShippingaddressindex(index)}}
                      // required={true} 
                      value={items1.id}
                      defaultValue={items1.id}
                      type="radio" 
                      name="shippingid" 
                      id={`shipping${items1.id}`}
                      />
                      <p className='mb-0 ms-2'><strong>{items1.address_name}</strong></p>
                      </div>
                      <p className='mb-1'>{items1.contact_name}</p>
                      <p className='mb-1'>{items1.address} {items1?.street ?? ''},{items1.city}</p>
                      <p className='mb-0'>{items1.state}</p>
                      <p className='mb-0'>{items1.postal_code}</p>
                      </div>
                  </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-lg-12 mb-5 text-md-end text-center">
                    <button
                    onClick={() => {shippingidform()}}
                    type="button" className="btn btn-primary-orange  btn-max-width-134">Next</button>
                  </div>
                </div>
                  </form>
                </>
                  )
                  :
                  (Billingmain
                  ?
                  (Billing
                  ?
                  <>
                    <h4 className="mb-4 mt-5">Billing Address</h4>
                    <form className='same-form' onSubmit={(e)=>onSubmitbilling(e)}>
                      <div className='row'>
                      <div className="col-lg-12 col-md-12 mb-3">
                        <input
                          defaultChecked={sameAddress ?? false} 
                          checked={sameAddress ?? false}
                          onChange={() => {SameaddressFunction()}}
                          type="checkbox" 
                          name="sameaddress"
                          id="sameaddress"
                          />
                      <label for="billingsameasshipping" className='ms-2'>
                        <strong>Same as shipping Address</strong>
                      </label>
                  </div>
                      </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        {sameAddress
                        ?
                        <input 
                        value={Shippingaddress?.[Shippingaddressindex ?? 0].address_name}
                        name='address_name'
                        type="text" 
                        placeholder="Address name" 
                        className="form-control mb-4 input-design" 
                        disabled
                        required={true}
                        />
                      :
                      <input 
                        name='address_name'
                        type="text" 
                        onChange={handleChange}
                        value={address_name}
                        placeholder="Address name" 
                        className="form-control mb-4 input-design" 
                        required={true}
                        />}
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {sameAddress
                        ?
                        <input 
                        value={Shippingaddress?.[Shippingaddressindex ?? 0].contact_name}
                        name='contact_name'
                        type="text" 
                        placeholder="Contact name" 
                        className="form-control mb-4 input-design" 
                        required={true}
                        disabled
                        />
                      :
                      <input 
                        name='contact_name'
                        type="text" 
                        onChange={handleChange}
                        value={contact_name}
                        placeholder="Contact name" 
                        className="form-control mb-4 input-design" 
                        required={true}
                        />}
                      </div>
                    </div>
                    <div className="row">
                      
                      <div className="col-lg-6 col-md-6">
                        {sameAddress
                        ?
                        <input 
                        value={Shippingaddress?.[Shippingaddressindex ?? 0].address}
                        name='address'
                        type="text" 
                        placeholder="Address 1" 
                        maxLength="40"
                        className="form-control mb-4 input-design" 
                        required={true}
                        disabled
                        />
                      :
                      <input 
                        name='address'
                        type="text"
                        onChange={handleChange}
                        value={address}
                        placeholder="Address 1" 
                        maxLength="40"
                        className="form-control mb-4 input-design" 
                        required={true}
                        />}
                      </div>

                      <div className="col-lg-6 col-md-6">
                        {sameAddress
                        ?
                        <input 
                        value={Shippingaddress?.[Shippingaddressindex ?? 0].street}
                        name='street'
                        type="text" 
                        placeholder="Address 2" 
                        className="form-control mb-4 input-design" 
                        // required={true}
                        disabled
                        />
                      :
                      <input 
                        name='street'
                        type="text" 
                        onChange={handleChange}
                        value={street}
                        placeholder="Address 2" 
                        className="form-control mb-4 input-design" 
                        // required={true}
                        />}
                      </div>
                    </div>
                    <div className="row">
                      
                      <div className="col-lg-6 col-md-6">
                        {sameAddress
                        ?
                        <input 
                        value={Shippingaddress?.[Shippingaddressindex ?? 0].city}
                        name='city'
                        type="text" 
                        placeholder="City" 
                        className="form-control mb-4 input-design" 
                        required={true}
                        disabled
                        />
                      :
                      <input 
                        name='city'
                        type="text" 
                        onChange={handleChange}
                        value={city}
                        placeholder="City" 
                        className="form-control mb-4 input-design" 
                        required={true}
                        />}
                      </div>

                      <div className="col-lg-6 col-md-6">
                        {sameAddress
                        ?
                        // <input 
                        // value={Shippingaddress?.[Shippingaddressindex ?? 0].state}
                        // name='state'
                        // type="text" 
                        // placeholder="State" 
                        // className="form-control mb-4 input-design" 
                        // required={true}
                        // disabled
                        // />

                        <select disabled name='state' required={true} value={Shippingaddress?.[Shippingaddressindex ?? 0].state} className="form-control mb-4 input-design" >
                          {/* <option className='d-none' value={Shippingaddress?.[Shippingaddressindex ?? 0].state}>State</option> */}
                          {stateList?.map((data,index) => (
                            <option value={data?.abbreviation} key={index}>
                              {data?.name}
                            </option>
                          ))}
                        </select>
                      :
                      // <input 
                      //   name='state'
                      //   type="text"
                      //   onChange={handleChange}
                      //   value={state} 
                      //   placeholder="State" 
                      //   className="form-control mb-4 input-design" 
                      //   required={true}
                      //   />

                      <select name='state' required={true} defaultValue="" className="form-control mb-4 input-design" >
                      <option className='d-none' value="">State</option>
                      {stateList?.map((data,index) => (
                        <option value={data?.abbreviation} key={index}>
                          {data?.name}
                        </option>
                      ))}
                    </select>
                        
                        }
                      </div>

                    </div>
                    <div className="row">
                      
                      <div className="col-lg-6 col-md-6">
                        {sameAddress
                        ?
                        <input 
                        value={Shippingaddress?.[Shippingaddressindex ?? 0].postal_code}
                        name='postal_code'
                        type="text" 
                        placeholder="ZipCode" 
                        className="form-control mb-4 input-design" 
                        required={true}
                        disabled
                        />
                      :
                      <input 
                        name='postal_code'
                        type="text" 
                        onChange={handleChange}
                        value={postal_code}
                        placeholder="ZipCode" 
                        className="form-control mb-4 input-design" 
                        required={true}
                        />}
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <input 
                        name='country'
                        type="text" 
                        placeholder="Country" 
                        className="form-control mb-4 input-design" 
                        required={true}
                        disabled
                        value={'United States'}
                        />
                      </div>
                    </div>
                    <div className="row">
                      
                      <div className="col-lg-6 col-md-6">
                        {sameAddress
                        ?
                        <input 
                        value={Shippingaddress?.[Shippingaddressindex ?? 0].phone}
                        name='phone'
                        type="text" 
                        placeholder="Phone" 
                        className="form-control mb-4 input-design" 
                        required={true}
                        disabled
                        />
                      :
                      <Input 
                        name='phone'
                        type="text"
                        minLength={14}
                        maxLength={14} 
                        defaultCountry="US"
                        value={''}
                        onChange={() => {}}
                        placeholder="Phone" 
                        className="form-control mb-4 input-design" 
                        required={true}
                        />}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mb-5 mt-3 d-flex align-items-center justify-content-between">
                        {Billingbtn
                        ?
                        <button onClick={(e) => {setBilling(!Billing)}} type="button" className="btn btn-primary-outline  btn-max-width-134">Back</button>
                        :
                        <button onClick={(e) => {setBillingmain(!Billingmain);setShippingmain(true)}} type="button" className="btn btn-primary-outline  btn-max-width-134">Back</button>
                      }
                        
                        <button type="submit" className="btn btn-primary-orange  btn-max-width-134">Next</button>
                      </div>
                    </div>
                    </form>
                      </>
                  :
                  <>
                  <form>
                  <div className="row">
                  <div className="col-lg-6 col-md-6">
                     <h4 className="mb-4 mt-5">Billing Address</h4>
                  </div>
                  <div className="col-lg-6 col-md-6 text-end">
                    <button 
                    className='border-0 p-0 mb-4 mt-5' 
                    onClick={(e) => setBilling(!Billing)}>
                    <ins>Add new billing Address</ins>
                    </button>
                  </div>
                  
                    {Billingaddress?.map((items,index) => (
                    <div key={index} className="col-lg-6 col-md-6 mb-3">
                      <div className='border p-3'>
                      <div className='d-flex align-items-center'>
                      <input
                      defaultChecked={(BillinggId == items.id) ? true : false} 
                      checked={(BillinggId == items.id) ? true : false} 
                      onChange={(e) => {setBillinggId(e.target.value)}} 
                      // required={true} 
                      value={items.id}
                      defaultValue={items.id}
                      type="radio" 
                      name="billingid" 
                      id={`billing${items.id}`}
                      />
                      <p className='mb-0 ms-2'><strong>{items.address_name}</strong></p>
                      </div>
                      <p className='mb-1'>{items.contact_name}</p>
                      <p className='mb-1'>{items.address} {items?.street ?? ''},{items.city}</p>
                      <p className='mb-0'>{items.state}</p>
                      <p className='mb-0'>{items.postal_code}</p>
                      </div>
                  </div>
                  ))}
    
                </div>
                <div className="row">
                  <div className="col-lg-12 mb-5 mt-3 d-flex align-items-center justify-content-between">

                    <button 
                    onClick={() => {setBillingmain(!Billingmain);setShippingmain(true)}} 
                    type="button" 
                    className="btn btn-primary-outline  btn-max-width-134">Back</button>

                    <button
                    onClick={() => {billingidform()}}
                    type="button" 
                    className="btn btn-primary-orange  btn-max-width-134">Next</button>

                  </div>
                </div>
                  </form>
                  </>)

                  :
                  <>
                  <form onSubmit={(e) => {e.preventDefault()}}>
                  <div className="row">
                  <div className="col-lg-12 col-md-12">
                     <h4 className="mb-4 mt-5">Shipping & Delivery</h4>
                  </div>
                  {ShippingCost?.map((items,index) => (
                    <div key={index} className="col-lg-6 col-md-6 mb-3">
                      <div className='border p-3'>
                      
                      <p className='mb-1 d-flex justify-content-start align-items-center'>
                      <input 
                      defaultValue={items.id}
                      onChange={(e) => {setShippingindex(index)}} 
                      defaultChecked={(Shippingindex == index) ? true : false}
                      required={true} 
                      type="radio" 
                      id={index}
                      name="shipping_delivery" 
                      /> <strong className='ms-2'><label for={index}>{ShippingCost ? items.service : ''}</label></strong></p>
                      <p className='mb-1 d-flex justify-content-between align-items-center'><strong>Estimate Delivery:</strong> {ShippingCost ? items.estimated_delivery_date.split("-").join("-").replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3") : ''}</p>
                      <p className='mb-0 d-flex justify-content-between align-items-center'><strong>Shipping Fees:</strong> {`$ ${ShippingCost ? items.total_shipping_amount : ''}`}</p>
                      </div>
                  </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-lg-12 mb-5 mt-3 d-flex align-items-center justify-content-between">
                    <button 
                    onClick={() => {setBillingmain(true)}} 
                    type="button" 
                    className="btn btn-primary-outline  btn-max-width-134">Back</button>
                  </div>
                </div>
                  </form>
                  </>
                  ) 
              }
              </div>
            </div>
          </div>
        </section>
        </React.Fragment>
    )
}

export default Checkout;