import React, { useState, useEffect,useLayoutEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getMethodData, postMethodData } from "../../adminApi/api";
// ck editor
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//jodit-editor
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import { useCallback } from "react";
const editorConfig = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: "en",
  toolbarButtonSize: "medium",
  toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,
  //defaultActionOnPaste: "insert_clear_html",
  // buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true,
    imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
  },
};

const AddNewCms = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  let  showDataToUser =false; 
  const [redirection,setRedirection] = useState(false);
  useLayoutEffect(() => {
      const ls=JSON.parse(localStorage.getItem("adminDetails"));
  
    // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
     
      for(let i=0; i<=ls?.my_permissions?.length;i++){
          if(ls?.my_permissions[i]?.name==="CMS"){
              
              for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                  if(ls?.my_permissions[i]?.permissions[j]?.name==="Edit "){
                      // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      showDataToUser = true;
                      i=ls?.my_permissions?.length;
                      break;
                  }
              }
          }
      }
      // console.log(showDataToUser);
      if(showDataToUser===false){
        // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
  }, []);

  const navigate = useNavigate();
  if (redirection) navigate('/admin/cms');

 const [cmsData, setCmsData] = useState([]);

  // form validation rules
  const validationSchema = Yup.object().shape({
  });

  const [content,setContent] = useState('')


  useEffect(() => {
    getMethodData(`/cms-pages/edit/${id}`, Token).then((res) => {
      let data = res.data.result.data.content.replace(/&quot;/g, '\"');
      // data = data.replace("&#039;", '');
      // data = res.data.result.data.content.replace("&quot;", null);
      // console.log(data)
       //console.log('here',res.data.result.data.content.replace(`"`, "`"))
       setContent(data.replace(/&quot;/g, '\"'))
      
      // console.log(data)
      // console.log(data.substring(0, data.length-1))
      // const completeResponse = {  };
      setCmsData({...res.data.result.data, content: data});
      
    });
  }, []);
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset,} =
    useForm(formOptions);
 

  // const dispatch = useDispatch()
  // const PORT = PORTNUMBER.PORTNUMBER

  const Token = localStorage.getItem("token");
  const { id } = useParams();
  const onSubmit = (data) => {
    const fs = new FormData();
    var inputs = document.getElementsByClassName('form-control');
    for (let i = 0; i < inputs.length; i++) {
      fs.append(inputs[i].name, inputs[i].value);
    }
   
    // console.log("=>",data);
    data.content=content.replace(/"/g, "&quot;");
     // console.log(data.content)
     // console.log("reverted",data.content.replace(/&quot;/g, '\"'))
    fs.append("content",data.content);
    
    postMethodData(`/cms-pages/update/${id}`, fs, Token)
      .then((res) => {
        if (res?.data?.status === true) {
          toast(res?.data?.message);
           navigate("/admin/cms");
          reset();
        } else {
          toast.error("Somthing Went Wrong!");
        } 
      })
      .catch((error) => {
        // console.log(error);
      });
      return false;
  };

  // ck editor
  const onChange = useCallback((newContent) => {
    setEditorData(newContent);
  }, []);
  const [editorData, setEditorData] = useState();
  // const onBlur = useCallback(
  //   (newContent) => {
  //     // appendLog(`onBlur triggered with ${newContent}`);
  //     setCmsData(cmsData?.content);
  //   },
  //   [setCmsData]
  // );
  // const [content, setContent] = useState("");
  // const config = useMemo(
	// 	{
	// 		readonly: false, // all options from https://xdsoft.net/jodit/doc/,
	// 		placeholder: 'Start typings...'
	// 	}
	// );
  const editorRef = useRef(null);
  const handleEditorInit = editor => {
    editorRef.current?.events?.fire("focus");
    // Get the editor instance
    const editorInstance = editor.editor;

    // Add a listener for the `change` event to update the `content` state
    editorInstance.events.on('change', () => {
      const data = editorInstance.value;
      setContent(data);
    });

    // Destroy the editor instance when the component unmounts
    return () => {
      editorInstance.destruct();
    };
  };

  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="main-head">
                <h4>Edit CMS Page </h4>
              </div>
            </div>
            <div className="col-6">
              <div className="theme-btn text-end top-head">
                <Link to="/admin/cms" className="btn">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="project-table">
        <div className="col-12 table-order-box shadow">
          <div className="tab-head">
            <small className=" form-text d-block text-center mb-1 text-muted"></small>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="needs-validation row"
            >
              <div className="mb-3 col-lg-6">
                <div className="mb-2">Title</div>
                <input
                  type="text"
                  {...register("title", { required: true })}
                  className="form-control col-md-6"
                  placeholder="Title"
                  required
                  defaultValue={cmsData?.title}
                />
              </div>
              <div className="mb-3 col-lg-6">
                <div className="mb-2">Keyword</div>
                <input
                  type="text"
                  {...register("meta_keyword", { required: true })}
                  className="form-control"
                  placeholder="Meta Keyword"
                  required
                  defaultValue={cmsData?.meta_keyword}
                />
              </div>
              <div className="mb-3 col-lg-6">
                <div className="mb-2">Description</div>
                <input
                  type="text"
                  {...register("meta_description", { required: true })}
                  className="form-control"
                  placeholder="Description"
                  required
                  defaultValue={cmsData?.meta_description}
                />
              </div>
              <div className="mb-3 col-lg-6">
                <div className="mb-2">Status</div>
                <select
                  className="form-select form-control"
                  {...register("status", { required: false })}
                  id="validationCustom12"
                  aria-label="Default select example"
                  required
                //  defaultValue={cmsData?.status === "1" ? "1" : "0"}
                >
                  <option selected className="disabled-value d-none" value={cmsData?.status === "active" ? "1" : "0" }>
                    {cmsData?.status === "active" ? "Active" : "Inactive"}
                  </option>
                  <option  value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <div className="mb-3">
                <div className="mb-2">Content</div>
                {/* <CKEditor
                  editor={ClassicEditor}
                   onChange={(event, editor) => {
                     setEditorData(editor.getData());
                   }}
                 data={cmsData?.content}

                /> */}
                 <JoditEditor
                 ref={editorRef}
                //  style={{ maxWidth: editorConfig.width, margin: "0 auto" }}
                  value={content}

                  onInit={handleEditorInit}
                  // config={editorConfig}
                  config={{
                    // readonly: false,
                    // toolbarAdaptive: false,
                    // showCharsCounter: true,
                    // showWordsCounter: true,
                    // showXPathInStatusbar: false,
                    // askBeforePasteHTML: true,
                    // askBeforePasteFromWord: true,
                    triggerChangeEvent: true,
                    language: "en-us",
                    toolbarButtonSize: "large",
                    uploader: {
                      url: `${BASE_URL}/upload-editor-files`, //your upload api url
                      insertImageAsBase64URI: false, //not inster base64
                      // imagesExtensions: ["jpg", "png", "jpeg", "gif"],
                      headers: {
                        authorization: `Bearer  ${localStorage.getItem(
                          "token"
                        )}`,
                      },
                      filesVariableName: function (t) {
                        // return 'files[' + t + ']';
                        // console.log("t",t)
                        return "file";
                      }, //"files",
                      withCredentials: false,
                      pathVariableName: "path",
                      format: "json",
                      method: "POST",
                      prepareData: function (formdata) {
                        // debugger;
                        // console.log(formdata)
                        return formdata;
                      },
                      isSuccess:async function (e) {
                        // debugger;
                        // console.log(e,"isSuccess")
                        return await e.status;
                      },
                      getMessage: function (e) {
                        // debugger;
                        // console.log("getMessage")
                        return [""];
                      },
                      
                      process: function (resp) {
                        //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                        // debugger;
                        // console.log(resp);
                        // console.log(resp.result.data.file_url);
                        // let files = [];
                        // files.unshift(resp.data);
                        return {
                          files: resp && [resp?.result?.data?.file_url],
                          error: resp?.msg,
                          msg: [""],
                        };
                      },
                   
                      defaultHandlerSuccess: async function (data) {
                        await setContent(content+`<img src="${data.files[0]}"/>`);
                        // console.log(content)
                      },
                    },
                  }}
                  // defaultValue={cmsData?.content}
                  tabIndex={1}
                  // onBlur={(data) => data.insertHTML('<a href="">Anchor Tag</a>')}
                  // onChange={onChange}
                  onBlur={newContent => setContent(newContent)}
                  onChange={newContent => {}}
                  
                />
              </div>
              <div className="theme-btn btn-group-right text-end mt-3">
                <button type="submit" className="btn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default AddNewCms;
