import React from 'react'
import { ImageObj } from '../../../../../../assets/images/imgeObject';



const SupplierRegister = () => {
    
    return (

        <section className="pt-5 mt-3 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="create-account supplier-white-bg">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="text-center m-0 py-3">
                                        Create an account
                                    </h3>
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <input type="text" placeholder="First name" className="form-control mb-4 input-design" />
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <input type="text" placeholder="Last Name" className="form-control mb-4 input-design" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <input type="text" placeholder="Company Name" className="form-control mb-4 input-design" />
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <input type="text" placeholder="ZipCode" className="form-control mb-4 input-design" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <input type="text" placeholder="Email" className="form-control mb-4 input-design" />
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <input type="text" placeholder="Primary Phone" className="form-control mb-4 input-design" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <input type="text" placeholder="Primary Phone" className="form-control mb-4 input-design" />
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-upload-box"><label htmlFor="upload-file"><img src={ImageObj.FolderIcon} /></label><input type="file" id="upload-file" name="upload-file" />
                                                    <input type="text" placeholder="Uplaod W-9 Document" className="form-control mb-4 input-design" /></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-upload-box"><label><img src={ImageObj.EyeIcon} /></label><input type="password" placeholder="Password" className="form-control mb-4 input-design" /></div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-upload-box"><label><img src={ImageObj.EyeIcon} /></label><input type="password" placeholder="Confirm password" className="form-control mb-4 input-design" /></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-2"><div className="text-light-gray font-size-14px">By joining the MACH 2 Manufacturing Network, you agree to our Partner Terms and Conditions, Privacy Policy, and Non-Disclosure Agreement:</div></div>
                                            <div className="col-md-12 text-center">
                                                <div className="mb-3 form-check d-flex justify-content-between">
                                                    <div className="text-light-gray font-size-14px">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                                        I agree to Mach2 <a href="#">Terms of Service</a> and
                                                        <a href="#">Privacy Policy</a>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-primary-orange m-auto w-50 mt-3">
                                                    Create An Account
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SupplierRegister;