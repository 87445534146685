import React, { useEffect,useState } from 'react'
import axios from 'axios';
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom'
import { GiAutoRepair } from "react-icons/gi";
const Services = () => {
  const {page}=useParams();
  const [loading, setLoading] = useState(false);
  const [cmsdata, setcmsdata] = useState();
  useEffect(()=>{
    const BASE_URL = process.env.REACT_APP_BASE_URL_USER;
    axios.get(`${BASE_URL}/user/get-cms-page/${page}`).then(req=>{
      // console.log(req);
      if(req?.data?.result?.data?.length===0){
        document.getElementById("cmsdata").innerHTML="<h1 class='text-center'>NO CMS DATA FOUND</h1>";
      }
      else{
      setcmsdata(req?.data?.result?.data)
      let data = req.data.result.data.content.replace(/&quot;/g, '\"');
      document.getElementById("cmsdata").innerHTML=data
      }
      setLoading(true)
    })
  },[page])

   return (
        <React.Fragment>
          <Helmet>
              <meta charSet="utf-8" />
              <title>{cmsdata?.title ?? "MACH2MFG"}</title>
              <link rel="canonical" href={window.location.href} />
              <meta name="description" content={cmsdata?.meta_description ?? "MACH2MFG"}></meta>
              <meta name="keywords" content={cmsdata?.meta_keyword ?? "MACH2MFG"}></meta>
          </Helmet>
          <div id="cmsdata"></div>
              {!loading ? (
                        <div className="loader-wrapper">
                          <div className="loader">
                            <div className="loading-svg">
                              <GiAutoRepair />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
        </React.Fragment>
    )
}

export default Services;
