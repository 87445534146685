import React, { useState, useEffect } from "react";
import { ImageObj } from "../../../../../assets/images/imgeObject";
import { getMethodDataUser } from "../../../../admin/adminApi/api";
import QuoteCollapse from "../../../common/quoteCollapse/quoteCollapse";
import moment from "moment";
import { GiAutoRepair } from "react-icons/gi";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { FaCube, FaRegFilePdf, FaTrashAlt } from "react-icons/fa";
import axios from "axios";
import DeleteId from "../../../common/deleteId/deleteId";
import fileDownload from 'react-file-download';

const MyParts = () => {
  const USERTOKEN = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const [indexPage, setIndexPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [Count, setCount] = useState(1);
  const [filterVisibility, setFilterVisibility] = useState(true);


  const [indexDelete, setIndexDelete] = useState();
  const [openDelete, setOpenDelete] = useState();

  const [perPage, setPerPage] = useState();

  const [myparts, setMyparts] = useState([]);

  const getPartdetails = () => {
    setLoading(false);
    getMethodDataUser(`/get-my-parts`, USERTOKEN).then((res) => {
      // setLoading(true)
      setLoading(true);

      setMyparts(res.data.result.data.data);
      setCount(res.data.result.data.total);
      setPerPage(res.data.result.data.per_page);
      setFilterVisibility(true);
    });
  };

  useEffect(() => {
    getPartdetails();
  }, []);
  const sendPage = (pageNumber) => {
    getMethodDataUser(`/get-my-parts?page=${pageNumber}`, USERTOKEN).then(
      (res) => {
        setMyparts(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);

        setLoading(true);
      }
    );
  };

  const handlePageChange = (pageNumber) => {
    // sendPage(pageNumber)
    // LoadTableData(pageNumber)

    sendPage(pageNumber);
    setActivePage(pageNumber);
    setIndexPage(pageNumber);
    setLoading(false);
  };
  const [modifyData, setModifyData] = useState();
  const BASE_URL = process.env.REACT_APP_BASE_URL_USER;
  useEffect(() => {
    axios.get(`${BASE_URL}/user/get-globle-config/QUOTE_MODIFY`).then((req) => {
      setModifyData(req?.data?.result?.data?.value);
      // console.log(req?.data?.result?.data?.value);
    });
  }, []);

  const popUpDetele = (index) => {
    setIndexDelete(index);
    setOpenDelete(true);
  };

  const handleDownload = (data,fileurl) => {
    const fileUrl = data;
    const fileName = fileurl;
    fileDownload(fileUrl, fileName);
  };


  return (
    <React.Fragment>
      <QuoteCollapse />
      <section className="pt-5 mt-3">
        <div className="container dashboard-heading-block py-3">
          <div className="row align-items-center">
            <div className="col-lg-12 dashboard-headline text-start">
              <h2>My Parts</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-4 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 position-relative">
              {myparts && myparts !== undefined ? (
                myparts.map((res, i) => {
                  return (
                    <div className="my-cart-items mb-3" key={i + 1}>
                      <div className="row">
                        {/* <div className="col-lg-3">
                          <div className="box-round rounded-circle overflow-hidden border ">
                            <img
                              className="h-100 w-100"
                              src={res?.auto_cad_file_path}
                            />
                          </div>
                        </div> */}
                        <div className="col-lg-12">
                          <div className="row mt-3">
                            <div className="col-lg-12 relative-box">
                              <h5>{res.file_name}</h5>

                            <div className="icon-set">
                            <a
                              // target={'_blank'}
                              download
                              // onClick={()=> handleDownload(res?.auto_cad_file_path,res?.file_name)}
                              title="Download 3d File"
                              href={res?.auto_cad_file_path}
                              className="icons-action" 
                              // rel="noreferrer" 
                            >
                              
                              <FaCube className="text-primary"/>
                              </a>

                            {(res?.is_manual_quote === 2 || res?.is_manual_quote === 0) &&  
                            <div>
                            {res.status === "open" && (
                            <>
                              {modifyData === "Yes" && (res?.is_manual_quote === 2) ?
                                <span
                                  onClick={() => popUpDetele(res.id)}
                                  className="ms-3 pointer opacity-50"
                                >
                                  <FaTrashAlt />
                                </span>
                                :
                                <React.Fragment>
                                    {res.is_manual_converted !== 1 && (
                                      <Link 
                                      title="Update Quote"
                                        to={`/customer/quotes/editdetails${res.id}`}
                                      >
                                        <img src={ImageObj.PenIcon} />
                                      </Link>
                                      
                                    )}

                                <span
                                  onClick={() => popUpDetele(res.id)}
                                  className="ms-3 pointer opacity-50"
                                >
                                  <FaTrashAlt />
                                </span>
                                     
                                  {/* {modifyData === "Yes" && (res.is_manual_quote === 2 ) && (res.status)} */}

                                </React.Fragment>
                              }
                            </>
                            )}
                            {res?.status === "expired" && (res?.is_manual_quote != 1) && (
                              <span
                                onClick={() => popUpDetele(res.id)}
                                className="ms-3 pointer opacity-50"
                              >
                                <FaTrashAlt />
                              </span>
                            )}
                            {/* <a href="#"><img src={ImageObj.ClockIcon} /></a> */}
                          </div>
                              // :  modifyData ===
                              // "No" ? (

                              // <div className="icon-set">
                              //   {items.status === "open" && (
                              //     <React.Fragment>
                              //       {items.is_manual_converted !== 1 && (
                              //         <Link
                              //           to={`../quotes/editdetails${items.id}`}
                              //         >
                              //           <img src={ImageObj.PenIcon} />
                              //         </Link>
                              //       )}
                              //       <span
                              //         onClick={() => popUpDetele(items.id)}
                              //         className="ms-3 pointer opacity-50"
                              //       >
                              //         <FaTrashAlt />
                              //       </span>
                              //     </React.Fragment>
                              //   )}
                              //   {items.status === "expired" && (
                              //     <span
                              //       onClick={() => popUpDetele(items.id)}
                              //       className="ms-3 pointer opacity-50"
                              //     >
                              //       <FaTrashAlt />
                              //     </span>
                              //   )}
                              // </div>
                              // ) : null
                              }
                            </div>

                             

                            





                              {/* {modifyData==="Yes"?null:modifyData==="No"?
                             <div className="icon-set">
                             {res.status === "open" && (
                               <Link
                                 title="Edit Part"
                                 to={`/customer/quotes/editdetails${res.id}`}
                               >
                                 <img src={ImageObj.PenIcon} alt="..."/>
                               </Link>
                             )}
                             <a
                               className="text-dark"
                               href={res.part_details_pdf}
                               title={
                                 res.part_details_pdf !== null
                                   ? "Download Part pdf"
                                   : ""
                               }
                               target="_blank"
                               rel="noreferrer"
                             >
                               {res.part_details_pdf !== null && (
                                 <FaRegFilePdf className="fs-5 fw-normal ms-2 pointer" />
                               )}
                             </a>
                           </div>
                           :null 
                             } */}
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-lg-9 col-md-9"
                            >
                              <div className="row">
                                <div className="col-3">
                                  <small>Qty</small>
                                  <p>{res.qty}</p>
                                </div>
                                  <div className="col-3">
                                    <small>Date</small>
                                    <p>
                                      {moment(res.updated_at).format(
                                        "MM/DD/yyyy"
                                      )}
                                    </p>
                                  </div>
                                <div className="col-3">
                                  <small>Status</small>
                                  <p className="text-capitalize">{res.is_proposal_send === 1 ? (res.status === "proposal submitted" ? "proposal received": res.status) :res.status}</p>
                                </div>
                               
                                  <div className="col-3">
                                    <small>Unit Cost</small>
                                    <p>{res.is_proposal_send === 1 ? (res.status === "proposal submitted" ? <Link to={`/customer/quotes/quotedetails${res.id}`} className='text-black'>View Proposal</Link>: <>${res.unit_price}</>) :<>${res.unit_price}</>}</p>
                                  </div>
                              </div>
                              <ul className="m-0 p-0 d-flex align-items-center flex-wrap">
                                {res.manufacturing_process && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">
                                      Manufacturing Process
                                    </small>
                                    :
                                    <p className="mb-0 ms-1 me-3 new text-capitalize">
                                      {res.manufacturing_process}
                                    </p>
                                  </li>
                                )}
                                {res.custom_manufacturing_process && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">
                                      Custom Manufacturing Process
                                    </small>
                                    :
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.custom_manufacturing_process}
                                    </p>
                                  </li>
                                )}
                                {res.material && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">Material </small> :
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.material}
                                    </p>
                                  </li>
                                )}
                                {res.custom_material && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">Custom Material Value </small> :
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.custom_material}
                                    </p>
                                  </li>
                                )}
                                {res.finish && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">Coating </small> :
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.finish}
                                    </p>
                                  </li>
                                )}
                                {res.custom_finish && (
                                  <li className="d-flex align-items-center">
                                    <small className="mb-0">
                                      Custom Coating
                                    </small>
                                    :
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.custom_finish}
                                    </p>
                                  </li>
                                )}
                                {res.threads && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">Threads </small> :
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.threads}
                                    </p>
                                  </li>
                                )}
                                {res.tolerance && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">Tolerance </small> :
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.tolerance}
                                    </p>
                                  </li>
                                )}
                                {res.surface_roughness && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">
                                      Surface Roughness
                                    </small>
                                    :
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.surface_roughness}
                                    </p>
                                  </li>
                                )}
                                {res.part_marking && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">Part Marking</small>
                                    :
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.part_marking}
                                    </p>
                                  </li>
                                )}
                                {res.inspection && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">Inspection </small>:
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.inspection}
                                    </p>
                                  </li>
                                )}
                                {res.custom_inspection && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">Custom Inspection </small>:
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.custom_inspection}
                                    </p>
                                  </li>
                                )}
                                {res.part_qualifications_certifications && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">
                                      Part Quality Certifications
                                    </small>
                                    :
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.part_qualifications_certifications}
                                    </p>
                                  </li>
                                )}
                                {res.material_certifications && (
                                  <li className="d-flex align-items-center">
                                    <small className="me-1">
                                      Material Certifications
                                    </small>
                                    :
                                    <p className="mb-0 ms-1 me-3 new">
                                      {res.material_certifications}
                                    </p>
                                  </li>
                                )}
                              </ul>
                              <div className="row item-notes mt-4">
                                <div className="col-lg-12">
                                
                                    <p className="nots">
                                      <span className="grey-color">
                                        Notes :
                                      </span>
                                      {res.notes}
                                    </p>

                               
                                    <p>
                                      <span className="grey-color">
                                        Last edited :
                                      </span>
                                      {moment(res.last_edited).format(
                                        "MM/DD/yyyy"
                                      )}
                                    </p>
                                </div>
                              </div>
                            </div>
                            
                              <div className="col-lg-3 col-md-3">
                                {/* <p className="mb-1">Unit price</p>
                                <div className="dolar-imput-box">
                                  <span className="dolar-sign">$</span>
                                  <input
                                    disabled
                                    className="ps-3 bg-light"
                                    value={res.unit_price}
                                    type="text"
                                  />
                                </div> */}
                                <h5 className="mt-4">Total Price</h5>
                                <div className="dolar-imput-box">
                                  {res.total_price != 0 && <span className="dolar-sign">$</span>}
                                  <input
                                    disabled
                                    className="ps-3 bg-light"
                                    value={(res.total_price === 0 ? "TBA" : res.total_price)}
                                    type="text"
                                  />
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className="no-data fs-1 fw-bolder text-uppercase opacity-25 text-dark">
                  No Parts Found
                </span>
              )}
              {!loading ? (
                <div className="loader-wrapper">
                  <div className="loader">
                    <div className="loading-svg">
                      <GiAutoRepair />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {Count > perPage && (
              <div className="container pe-0 ps-0">
                <div className="pagination">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={Number(Count)}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <DeleteId
        index={indexDelete}
        api={"/quotes/delete/"}
        toggel={openDelete}
        setopen={setOpenDelete}
        renderapi={getPartdetails}
      />
    </React.Fragment>
  );
};

export default MyParts;
