
import React from 'react'
import { Spinner, Modal, Button } from 'react-bootstrap'


const Conditions = (props) => {


    return (

        <div className="modal-edit">
            <Modal  {...props} size="xl" className="edit-content">

                <div className="">
                    <div className="main-theme bg-theme border shadow">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <h1 className='fs-1 mb-2 text-dark mt-sm-4 mt-4'>General Terms and Conditions</h1>
            <h2 className='fs-3 mb-2 text-dark'>Mach 2 Tool and Manufacturing Inc. </h2>
            <h3 className='fs-4 text-dark'>Last Updated: 6/24/2022</h3>
                            <div className='mt-sm-5 mt-2 pb-sm-5 mb-2'>

                                <h4 className='mt-2  user-text'>Introduction </h4>
                                <p>Welcome to <a target="_blank" >Mach2mfg.com</a> (site) the official website for Mach 2 Tool and Manufacturing Inc.
                                    (company, Mach2, we or us).
                                </p>

                                <p>These Website Standard Terms and Conditions written on this webpage shall manage your use
                                    of our website, MACH 2 Tool and Manufacturing Inc. (company) accessible at <a target="_blank" >Mach2mfg.com</a>
                                    ( site) as a visitor and or a member of the MACH2 manufacturing community and AI digital
                                    platform. </p>


                                <p>These Terms will be applied fully and will govern your use of this website and all content
                                    generated from it as well as the content entered into it. By using this Website, you agree to
                                    accept all terms and conditions written in this document and are bound to these terms and
                                    conditions. You must not use this Website if you disagree with any of the standard Terms and
                                    Conditions outlined in this document.</p>


                                <p>Registration to the site as a customer or vendor and any other information submitted by you are
                                    subject to our applicable Privacy Policy, the terms and conditions of which are expressly
                                    incorporated herein by this reference.</p>

                                <p>By using this Site you agree to be bound by these Terms of Use and to use the Site in
                                    accordance with these Terms of Use, our Privacy Policy, and any additional terms and
                                    conditions that are referenced herein or that otherwise may apply to specific sections of the Site,
                                    or to products and services that are made available to you through the Site (all of which are
                                    deemed part of these Terms of Use). </p>


                                <p>Accessing the Site, in any manner, whether automated or otherwise constitutes use of the Site
                                    and your agreement to be bound by these Terms of Use. </p>


                                <p> As a requirement to order manufacturing services (the "Services") from <a target="_blank" >Mach2mfg.com</a>, you
                                    will be required to open an account and to agree to the Customer Service Agreement. You
                                    understand that through your use of this Site as well as any Services that you consent to the
                                    collection and use of information (as set forth in the applicable Privacy Policy), including the
                                    transfer of this information for storage, processing, and use by Mach 2 and its affiliates. </p>


                                <h4 className='mt-2 mt-sm-5 user-text'> Age Requirements</h4>


                                <p>Minors or people below 18 years old are prohibited from using this Website. By using this site,
                                    you are certifying that you are 18 years of age or older. If you are under the age of 18 you are
                                    prohibited from uploading photos or accessing any information pertaining to the site</p>


                                <h4 className='mt-2 mt-sm-5 user-text'>Intellectual Property Rights </h4>


                                <p>Other than the content you upload, under these Terms, Mach2 Tool and Manufacturing
                                    Inc. and/or its licensors own all the intellectual property rights, website quoting features, AI
                                    algorithms, customer and vendor portal features and materials contained in this Website. Each
                                    time you enter the site, new features may be available and for that reason you are required to
                                    review the terms prior to each use. </p>

                                <p>You are granted limited license only for purposes of viewing the material contained on this
                                    Website. Site content including but not limited to the layout, URL’s videos, drawings, photos,
                                    PDF documents, word documents, Excel documents, CAD drawings, trademarks, content and
                                    logos are the sole property of Mach 2 Tool and Manufacturing or owned by other licensed to us. </p>

                                <h4 className='mt-2 mt-sm-5 user-text'>Restrictions </h4>
                                <p>You are specifically restricted from all of the following</p>
                                <ul>
                                    <li>Publishing any Website material in any other media. </li>
                                    <li>Using, selling, sublicensing and/or otherwise commercializing any Website material.</li>
                                    <li>Publicly performing and/or showing any Website material.</li>
                                    <li>Using this Website in any way that is or may be damaging to this Website.</li>
                                    <li>Using this Website in any way that impacts user access to this Website.</li>
                                    <li>Using this Website contrary to applicable laws and regulations, or in any way may cause
                                        harm to the Website, or to any person or business entity.</li>
                                    <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity
                                        in relation to this Website.</li>
                                    <li>Using this Website to engage in any advertising or marketing.
                                    </li>
                                    <li>Directly connecting or communicating with any customer or vendor related to any
                                        projects or services pertaining to the website.</li>
                                    <li> Using the website for any political, unlawful or inappropriate purposes.
                                    </li>
                                    <li>Use <a target="_blank" >Mach2mfg.com</a> or its logos as an advertising tool on your own website for the
                                        purpose of generating business or leads.</li>


                                </ul>
                                <p>Certain areas of this website are restricted from being accessed by you and any outside
                                    business entity. We reserve the right to further restrict your access to any areas of this website,
                                    at any time, in absolute discretion. Any user ID and password you may have for this Website are
                                    confidential and you must maintain confidentiality as well. </p>
                                <h4 className='mt-2 mt-sm-5 user-text'>Your Content </h4>
                                <p>In these Website Standard Terms and Conditions, Your Content shall mean any audio, video
                                    text, images, CAD or other material you choose to display or upload onto this Website. By
                                    uploading your content, you grant Mach 2 Tool and Manufacturing(<a target="_blank" >Mach2mfg.com</a>) a nonexclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish,
                                    translate and distribute it in any and all media.</p>
                                <p>Your Content must be your own and must not be invading any third-party's rights. Mach 2
                                    reserves the right to remove any of Your Content from this Website at any time without not</p>
                                <h5 className='mt-2 mt-sm-5 user-text'>No warranties </h5>
                                <h4 className='user-text'>Limitation of liability</h4>
                                <p>In no event shall Mach 2 Tool and Manufacturing, nor any of its officers, directors and
                                    employees, shall be held liable for anything arising out of or in any way connected with your use
                                    of this Website whether such liability is under contract. Mach 2 Tool and Manufacturing Inc.,
                                    including its officers, directors and employees shall not be held liable for any indirect,
                                    consequential or special liability arising out of or in any way related to your use of this Website. </p>
                                <h4 className='mt-2 mt-sm-5 user-text'>Indemnification </h4>
                                <p>You hereby indemnify to the fullest extent Mach 2 Tool and Manufacturing Inc. from and against
                                    any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in
                                    any way related to your breach of any of the provisions of these Terms. Many types of disputes
                                    that may arise in connection with your access to and use of the Site may only be resolved by
                                    arbitration, which includes your waiver of a right to a jury trial</p>
                                <h4 className='mt-2 mt-sm-5 user-text'>Severability </h4>
                                <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions
                                    shall be deleted without affecting the remaining provisions herein. We also reserve the right to
                                    deny access to the Site or any features of the Site to anyone who violates these Terms of Use
                                    or who, in our sole judgment, interferes with the ability of others to enjoy our Site or infringes the
                                    rights of Mach 2 Tool and Manufacturing and others.</p>
                                <h4 className='mt-2 mt-sm-5 user-text'>Variation of Terms
                                </h4>
                                <p>Mach 2 Tool and Manufacturing is permitted to revise these Terms, products or services at any
                                    time as it sees fit, and by using this Website you are expected to review these Terms on a
                                    regular basis.</p>
                                <h4 className='mt-2 mt-sm-5 user-text'>Assignment</h4>
                                <p>Mach 2 Tool and Manufacturing Inc. is allowed to assign, transfer, and subcontract its rights
                                    and/or obligations under these Terms without any notification. However, you are not allowed to
                                    assign, transfer, or subcontract any of your rights and/or obligations under these Terms. </p>
                                <h4 className='mt-2 mt-sm-5 user-text'>Entire Agreement </h4>
                                <p>These Terms constitute the entire agreement between Mach 2 Tool and Manufacturing and you,
                                    the customer or vendor in relation to your use of this Website, and supersede all prior
                                    agreements and understandings. </p>
                                <h4 className='mt-2 mt-sm-5 user-text'>Governing Law & Jurisdiction</h4>
                                <p>These Terms will be governed by and interpreted in accordance with state laws, and you submit
                                    to the non-exclusive jurisdiction of the state and federal courts located in your specific state for
                                    the resolution of any disputes.</p>
                            </div>
                       
                    </div>
                </div>

            </Modal>

        </div>
    )
}

export default Conditions