import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import FormValidation from "../../../../formValidation/formValidation";
import { postMethodDataUser } from "../../../admin/adminApi/api";

const CancelOrder = (props) => {
  const USERTOKEN = localStorage.getItem("userToken");
  const SUPPLIERTOKEN = localStorage.getItem("supplierToken");
  const [loading, setLoading] = useState(false);

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset } = useForm();

  const FormValidationNew = FormValidation;

  const onSubmit = (data) => {
    setLoading(true);
    postMethodDataUser(
      `/orders/cancel`,
      {
        order_id: props.orderid,
        part_id: props.part_id,
        cancel_reason: data.cancel_reason,
      },
      SUPPLIERTOKEN || USERTOKEN
    ).then((res) => {
      if (res.data.status !== false) {
        props.onHide();
        toast.success(res.data.message);
        props.orderlist();
        reset();
        setLoading(false);
      } else {
        toast.error(res.data.message);
        setLoading(false);
      }
    });
  };
  const closeModal = useCallback(() => {
    props.onHide();
    reset();
  }, []);

  useEffect(() => {
    FormValidationNew();
    reset();
  }, [FormValidationNew, props]);

  return (
    <div className="modal-edit">
      <Modal {...props} className="edit-content">
        <div className="max-new-second w-100">
          <div className="main-theme bg-theme border shadow p-4">
            <Modal.Header
              className="fit-content"
              closeButton
              onClick={() => closeModal()}
            ></Modal.Header>
            <div className="select-steps-box">
              <div className="input-head mb-4">
                <h5>Are you sure want to cancel this part?</h5>
              </div>
            </div>
            <section className="select-state">
              <div className="state-bor">
                <div className="log-in otp-num">
                  <div className="select-box-top pr-0 ">
                    <div className="input-box">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="needs-validation"
                        noValidate
                      >
                        <div className="col-12 p-0">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group icon-position">
                                <textarea
                                  maxLength="300"
                                  className="form-control"
                                  id="validation3"
                                  {...register("cancel_reason", {
                                    required: false,
                                  })}
                                  placeholder="Please specify reason"
                                  required
                                />

                                <div className="invalid-feedback">
                                  Please Enter Reason.
                                </div>
                              </div>
                            </div>

                            <div className=" btn-group-right">
                              {loading === false ? (
                                <button
                                  type="submit"
                                  className="btn btn-primary-orange m-0 min-hp-40 me-3"
                                  variant="secondary"
                                >
                                  Yes
                                </button>
                              ) : (
                                <Button
                                  className="btn  spinner btn-primary-orange  m-0 min-hp-40 me-3 pe-3 ps-3"
                                  variant="dark"
                                  disabled
                                >
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                  />
                                  Please Wait...
                                </Button>
                              )}
                              <Button
                                type="reset"
                                className="btn btn-primary-outline m-0 min-hp-40"
                                variant="primary"
                                onClick={() => closeModal()}
                              >
                                No
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancelOrder;
