import React,{ useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga";
import logo from '../logo.svg';
import '../App.scss';
import Routes from '../requireAuth/routes/routes';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import '../assets/scss/css/temphide.css';

function App() {
  // useEffect(()=> {
  //   const axiosInstance = axios.create({
  //     baseURL: process.env.REACT_APP_BASE_URL,
  //   });
  //   axiosInstance.interceptors.response.use(
  //     response => response,
  //     error => {
  //       if (error.response.status === 401) {
  //           // window.location.href = '/'; // replace with your login page URL
  //           localStorage.clear();
  //           // toast('Unauthorize.');
  //       }
  //       return Promise.reject(error);
  //     }
  //   );
  // },[])
  // useEffect(()=>{
  //    // Add a request interceptor
  // axios.interceptors.request.use(function (config) {
  //   // Do something before request is sent
  //   return config;
  // }, function (error) {
  //   // Do something with request error
  //   return Promise.reject(error);
  // });

  // // Add a response interceptor
  // axios.interceptors.response.use(function (response) {

  //   switch (response.data.status) {
  //     case 400:
  //       toast('Bad Request.');
        
  //       break;
  //     case 401:
         
  //         localStorage.clear();
  //         toast('Unauthorize.');
         
  //       break;
  //     case 500:
  //       toast.error('Seems like something went wrong!', 'Error');
  //       break;
  //     case 504:
  //       toast.error('Sorry, could not access the external resource to refine the data for your request, please try again later!', 'Error');
  //       break;
  //     default:
        
  //   }
  //   // Any status code that lie within the range of 2xx cause this function to trigger
  //   // Do something with response data
  //   return response;
  // }, function (error) {
 
  //   if(error.message === "Network Error"){

  //     toast("Network Error");
  //   }

   
   
  //   return Promise.reject(error);
  // });
  // },[])
 

  return (
    <div className="App new-home">
    <Helmet>
        <meta charSet="utf-8" />
        <title>MACH2MFG</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="MACH2MFG"></meta>
        <meta name="keywords" content="MACH2MFG"></meta>
    </Helmet>
    <Routes/>
    <ToastContainer />
    </div>
  );
}


export default App;
