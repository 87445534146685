/* eslint-disable import/no-anonymous-default-export */
import React, {  useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import RequireAuth from "../requireAuth";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../components/user/common/header/header";
// import Home from "../../components/user/components/pages/home/home";
import ResetMail from "../../components/user/components/pages/auth/resetMail/resetMail";
// import ResetPassword from "../../components/user/components/pages/auth/resetPassword/resetPassword";
import ResetPasswordLink from "../../components/user/components/pages/auth/resetPwLink/resetPasswordLink";
import CustomerDashboard from "../../components/user/components/pages/customerPages/customerDashboard/customerDashboard";
import EditProfile from "../../components/user/components/pages/customerPages/editProfile/editProfile";
import OrdersList from "../../components/user/components/pages/customerPages/ordersList/ordersList";
import QoutesList from "../../components/user/components/pages/customerPages/quotesList/quotesList";
import CreateAQuote from "../../components/user/components/pages/customerPages/createAquoute/createAquoute";
import CreateAQuoteProcess from "../../components/user/components/pages/customerPages/createAquouteProcess/createAquouteProcess";
// import Create3d from "../../components/user/components/pages/customerPages/createAquoute/createAquoute";
import Checkout from "../../components/user/components/pages/checkout/checkout";
import Payment from "../../components/user/components/pages/Payment/Payment";
import MyParts from "../../components/user/components/pages/myParts/myParts";
import Quotedetails from "../../components/user/components/pages/quotedetails/Quotedetails";
import SupplierDashboard from "../../components/user/components/pages/supplierPages/supplierDashboard/supplierDashboard";
import OrderDetails from "../../components/user/components/pages/customerPages/ordersDetails/ordersDetails";
import SuppOrderDetails from "../../components/user/components/pages/supplierPages/ordersDetails/ordersDetails";
import SupplierRegister from "../../components/user/components/pages/supplierPages/supplierRegister/supplierRegister";
import SuppOrdersList from "../../components/user/components/pages/supplierPages/ordersList/ordersList";
import NotificationLists from "../../components/user/components/pages/supplierPages/NotificationListsss/NotificationLists";
import EditProfileSupplier from "../../components/user/components/pages/supplierPages/editProfile/editProfile";
import BidsList from "../../components/user/components/pages/supplierPages/bidsList/bidsList";
import TermAndServices from "../../components/user/common/termAndService/termAndService";
import PrivacyPolicy from "../../components/user/common/privacyPolicy/privacyPolicy";
import Home2 from "../../components/user/components/pages/home2/home2";
import CreateAQuoteProcessEdit from "../../components/user/components/pages/customerPages/createAquouteProcess/createQuotesEdit";
import Bidapply from "../../components/user/components/pages/supplierPages/bidsList/bidapply";
import MyEarningList from "../../components/user/components/pages/supplierPages/myEarningList/myEarningList";
 import Transactions from "../../components/user/components/pages/supplierPages/transactions/transactions";
import Services from "../../components/user/components/pages/services/Services";

export default () => {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <Router>
      <Wrapper>
        <RequireAuth>
          <Route path="/" element={<Header />}>
            <Route index element={<Home2 />} />

            <Route path="resetpassword/:id" element={<Home2 show={true} />} />
            <Route
              path="verifylink/:id"
              element={<Home2 showMessage={true} />}
            />

            <Route path="resetmail" element={<ResetMail />} />
            <Route path="sent-link-mail" element={<ResetPasswordLink />} />
            {/* <Route path="conditions" element={<TermAndServices />} />
            <Route path="policy" element={<PrivacyPolicy />} /> */}

            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          {/* <Route path="/capabilities" element={<Header />}>
            <Route index element={<Capabilities />} /> 
          </Route> */}
          {/* <Route path="/industries" element={<Header />}>
            <Route index element={<Industries />} /> 
          </Route> */}
          <Route path="/:page" element={<Header />}>
            <Route index element={<Services />} /> 
          </Route>
          
          {/* <Route path="/resources" element={<Header />}>
            <Route index element={<Resources />} /> 
          </Route>
          <Route path="/expertise" element={<Header />}>
            <Route index element={<Resources />} /> 
          </Route> */}

          <Route
            path="/supplier"
            element={
              <Header
                issupplier={true}
                path1={"bids"}
                logopath={"/supplier/dashboard"}
              />
            }
          >
            <Route index element={<Home2 />} />
            <Route path="dashboard" element={<SupplierDashboard />} />

            <Route path="orders" element={<SuppOrdersList />} />
            <Route path="notiticationlist" element={<NotificationLists />} />
            <Route path="orders/:id" element={<SuppOrderDetails />} />

            <Route path="earning" element={<MyEarningList />} />
             <Route path="trransactions" element={<Transactions />} />  
             <Route  path=":page" element={<Services />} />
            <Route path="bids" element={<BidsList />} />
            <Route path="bids/bidapply:id" element={<Bidapply />} />

            <Route path="register" element={<SupplierRegister />} />
            <Route path="editprofile" element={<EditProfileSupplier />} />
            {/* <Route path="conditions" element={<TermAndServices />} />
            <Route path="policy" element={<PrivacyPolicy />} /> */}
            <Route path="*" element={<Navigate to="/supplier/dashboard" />} />
          </Route>
          <Route
            path="/customer"
            element={
              <Header path1={"quotes"} logopath={"/customer/dashboard"} />
            }
          >
            <Route index element={<Home2 />} />
            <Route path="dashboard" element={<CustomerDashboard />} />
            <Route path="quote/:id" element={<CreateAQuoteProcess />} />
           
            <Route  path=":page" element={<Services />} /> 
        
            <Route path="editprofile" element={<EditProfile />} />
             <Route path="orders" element={<OrdersList />} /> 
             <Route path="orders/:id" element={<OrderDetails />} /> 
            <Route path="quotes" element={<QoutesList />} />
            <Route path="quote-process" element={<CreateAQuoteProcess />} />
            <Route path="create-quote" element={<CreateAQuote />} />
            <Route path="checkout:id" element={<Checkout />} />
            <Route path="payment:id" element={<Payment />} />
            <Route path="myparts" element={<MyParts />} />

            <Route path="quotes/quotedetails:id" element={<Quotedetails />} />
            <Route
              path="quotes/editdetails:id"
              element={<CreateAQuoteProcessEdit />}
            />
            {/* <Route path="conditions" element={<TermAndServices />} />
            <Route path="policy" element={<PrivacyPolicy />} /> */}
            <Route path="*" element={<Navigate to="/customer/dashboard" />} />
          </Route>

          {/* <Route path="/:id" element={<Home />} /> */}
        </RequireAuth>
      </Wrapper>
    </Router>
  );
};
