import React, { useCallback, useEffect, useState } from 'react';

import { getMethodDataUser } from '../../../../../admin/adminApi/api';

import FormValidation from '../../../../../../formValidation/formValidation';
import {FaEdit, FaTrashAlt} from 'react-icons/fa'

import { useDispatch } from 'react-redux'
import AddAddress from './addAdress';
import UpdateProfile from './updateProfile';
import ChangePassword from './changePassword';
import QuoteCollapse from '../../../../common/quoteCollapse/quoteCollapse';
import { toast } from 'react-toastify';


const EditProfile = () => {

  const USERTOKEN = localStorage.getItem("userToken")
  const [addressValue, setAddressValue] = useState()
  const [addressValueEdit, setAddressValueEdit] = useState()
  const [loading, setLoading] = useState(false)
  const [addressData, setAddressData] = useState([])
  const [addressDataNew, setAddressDataNew] = useState([])
  const [openDelete, setOpenDelete] = useState()
  const [indexDelete, setIndexDelete] = useState()
  const [indexAddress, setIndexAddress] = useState()
  const [openConditinaly, setOpenConditinaly] = useState()
  const [addressId, setAddressId] = useState()
  const [apiProps, setApiProps] = useState()
  const [apiTitle, setApiTitle] = useState()
  const FormValidationNew = FormValidation
  const dispatch = useDispatch()
  const editAddressFunc = useCallback((id, api) => {
  
    setAddressId(id)
    setApiProps(api)
    setApiTitle(api === "/shipping-addres" ? "Shipping" : "Billing")
    setAddressValueEdit(true)

  }, [addressId,addressValueEdit])

  const setAddressValueFun = useCallback((api) => {
    setApiProps(api)
    setApiTitle(api === "/shipping-addres" ? "Shipping" : "Billing")
    setAddressValue(true)
  }, [addressId,addressValueEdit])

  
  const popUpDetele = (index, address, con) => {
    setIndexDelete(index)
    setIndexAddress(address)
    setOpenDelete(true)
    setOpenConditinaly(con)
  }

  const billingAddressFun=()=>{
    getMethodDataUser("/billing-addres-list",USERTOKEN).then((res) => {
      setAddressData(res.data.result.data)
   
    })
  }

  const shippingAddressFun=()=>{
    getMethodDataUser("/shipping-addres-list",USERTOKEN).then((res) => {
      setAddressDataNew(res.data.result.data)
      
    })

  }


  const deleteAddress = () => {

    getMethodDataUser(`${indexAddress}/${indexDelete}`,USERTOKEN).then((res)=>{
      setOpenDelete(false)
      toast.success(res.data.message)
      billingAddressFun()
    shippingAddressFun()
    })

  }
  


  useEffect(() => {
    billingAddressFun()
    shippingAddressFun()
  
  }, [])




useEffect(() => {
    FormValidationNew()
}, [FormValidationNew])

  return (
    <React.Fragment>
      <QuoteCollapse />
      <section className="pt-5 mt-3">
        <div className="container dashboard-heading-block py-3">
          <div className="row align-items-center">
            <div className="col-lg-12 dashboard-headline text-start">
              <h2>My Profile</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5 pt-3 mt-5 edit-profile-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7 pe-sm-5">
              <div className="row align-items-center mb-4">
                <div className="col-lg-12 text-start d-flex justify-content-between align-items-center">
                  <h4 className="pull-left">Profile Information</h4>
                  {/* <button type="button" className="btn btn-primary-transparent ms-2 pull-right none-float custom-padding">
                    Edit
                  </button> */}
                </div>
              </div>
              <UpdateProfile token={USERTOKEN}/>
          
              <div className='row'>
                <div className="col-lg-12">
                  <div className="row align-items-center mb-4">
                    <div className="col-lg-12 text-start d-flex justify-content-between align-items-center">
                      <h4 className="pull-left">Change Password</h4>
                    </div>
                  </div>
                 <ChangePassword token={USERTOKEN}/>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="row align-items-center">
                <div className="col-lg-12 text-start">
                  <div className="sidebar-border-bottom d-flex justify-content-between align-items-center">
                    <h4 className="pull-left">Shipping Address</h4>
                    <button type="button" onClick={() => setAddressValueFun("/shipping-addres")} className="btn btn-primary-transparent ms-2 pull-right none-float custom-padding">
                      Add New
                    </button>
                  </div>
                  <p>You can have multiple shipping addresses for delivery.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 position-relative">
                  <table className='w-100 table table-bordered table-Light'>
                    <thead>
                      <tr className=''>
                        <th scope="col">Sr. No.</th>
                        <th scope="col">Address Name</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {
                     addressDataNew?.length  ?  addressDataNew?.map((res, i) => {
                        return (
                   
                            <tbody className='border' key={i+1}>
                              <tr  className='bg-transparent'>
                              <td className='px-2 border' scope='row'>{i + 1}</td>
                              <td className='px-2 border'>{res.address_name}</td>
                              <td className='px-2 border action'>
                                <span className='pointer me-2' onClick={() => editAddressFunc(res.id, "/shipping-addres")} >
                                 < FaEdit/>
                                </span>
                                <span className='pointer' onClick={() => popUpDetele(res.id,"/shipping-addres/delete",0)} >
                                < FaTrashAlt/>
                                </span>
                              </td>
                              </tr>
                           
                            </tbody>
                        

                        )
                      }):
                      <tbody>
                        <tr  className='bg-transparent'>
                          <td className='border-0'>
                          <span className='no-data fs-5 mt-2 fw-bolder text-uppercase opacity-25 text-dark'>No Address Found</span>
                          </td>
                        </tr>
                      </tbody>
                    }
                  </table>
                  <span className={openDelete === true && openConditinaly === 0 ? "deleteBox selected new" : "deleteBox new"}>
                  <p className='text-light mt-2 mb-2'>Are you sure you want to delete?</p>
                  <span className="cancel" onClick={() => setOpenDelete(false)}>Cancel</span>
                  <span className="confirm" onClick={() => deleteAddress()}>Yes</span>
                </span>
                </div>
              </div>
              <div className="row align-items-center mt-5">
                <div className="col-lg-12 text-start">
                  <div className="sidebar-border-bottom d-flex justify-content-between align-items-center">
                    <h4 className="pull-left">Billing Address</h4>
                    <button type="button" onClick={() => setAddressValueFun("/billing-addres")} className="btn btn-primary-transparent ms-2 pull-right none-float custom-padding">
                      Add New
                    </button>
                  </div>
                  <p>You can have multiple billing addresses for delivery.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 position-relative">
                  <table className='w-100 table table-bordered table-Light'>
                    <thead>
                      <tr className=''>
                        <th scope="col">Sr. No.</th>
                        <th scope="col">Address Name</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {
                      addressData?.length ? addressData?.map((res, i) => {
                        return (
                         
                            <tbody className='border' key={i+1}>
                              <tr className='bg-transparent'>
                              <td className='px-2 border' scope='row'>{i + 1}</td>
                              <td className='px-2 border'>{res.address_name}</td>
                              <td className='px-2 border action'>
                                <span className='pointer me-2' onClick={() => editAddressFunc(res.id, "/billing-addres")} >
                                < FaEdit/>
                                </span>
                                <span className='pointer' onClick={() => popUpDetele(res.id, "/billing-addres/delete",1)} >
                                < FaTrashAlt/>
                                </span>
                              </td>
                              </tr>
                            </tbody>

                        )
                      }):
                      <tbody>
                      <tr  className='bg-transparent'>
                        <td  className=' border-0'>
                        <span className='no-data fs-5 mt-2 fw-bolder text-uppercase opacity-25 text-dark'>No Address Found</span>
                        </td>
                      </tr>
                    </tbody>
                    }
                  </table>
                  <span className={openDelete === true && openConditinaly === 1  ? "deleteBox selected new" : "deleteBox new"}>
                  <p className='text-light mt-2 mb-2'>Are you sure you want to delete?</p>
                  <span className="cancel" onClick={() => setOpenDelete(false)}>Cancel</span>
                  <span className="confirm" onClick={() => deleteAddress()}>Yes</span>
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddAddress show={addressValue} buttontitle={"Save"} apititle={`Add Your ${String(apiTitle)}`} shipping={ shippingAddressFun} billing={ billingAddressFun} address={`${apiProps}/store`}
        onHide={() => setAddressValue(false)} />
      <AddAddress addressedit={addressValueEdit?"true":""} show={addressValueEdit} buttontitle={"Update"}  apititle={`Edit Your ${String(apiTitle)}`}  address={`${apiProps}/update/${addressId}`}
        editaddress={`${apiProps}/edit/${addressId}`}
        onHide={() => setAddressValueEdit(false)} />
    </React.Fragment>

  )
}

export default EditProfile;