import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { getMethodDataUser } from '../../../admin/adminApi/api'
import { Button, Spinner } from 'react-bootstrap';



const DeleteId = (props) => {
  const USERTOKEN = localStorage.getItem("userToken")
  const [loading, setLoading] = useState(false)


  const deletePart = () => {
    setLoading(true)
    getMethodDataUser(`${props.api}${props.index}`, USERTOKEN).then((res) => {
  
      props.setopen(false)
      props.renderapi()
      if (res.data.status === true) {
        setLoading(false)

        toast.success(res.data.message);
        document.getElementById('manualQuote').setAttribute('disabled','true');
      } else {
        setLoading(false)

        toast.error(res.data.message);
        document.getElementById('manualQuote').setAttribute('disabled','false');
      }
    })
  }
  return (
    <span className={props.toggel === true ? "deleteBox selected new fixed" : "deleteBox new fixed"}>
      <p className='text-light mt-2 mb-2'>{props.title ? props.title : "Are you sure you want to delete?"}</p>
      <span className="cancel" onClick={() => props.setopen(false)}>Cancel</span>
      {
        loading === false ?
          <span className="confirm" onClick={() => deletePart()}>Yes</span> :
          <span className='confirm' variant='dark' disabled>
            wait...
          </span>
      }
    </span>
  )
}

export default DeleteId;