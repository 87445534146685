import React, { useEffect, useState } from 'react'

import { useNavigate, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import axios from 'axios'
import { Button, Spinner, Modal } from 'react-bootstrap'
import { postMethodDataUser } from '../../../../../admin/adminApi/api';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
// import { ImageObj } from '../../../assets/images/imgeObject'
// import SENDFORGOTPASSMAIL_API from '../../../views/api/api'
import { modalToggelValue,modalResetValue,modalResetMsgValue } from '../../../../../../redux/actions/action';

const ResetMail = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const Token = localStorage.getItem("token")
    const [loading, setLoading] = useState(false)
    const [mailErr, setMailErr] = useState()
    const USERTOKEN = localStorage.getItem("userToken")

    const { register, handleSubmit, reset } = useForm();



    useEffect(() => {

        reset()
    }, [props])


    const dispatchToggle = () => {
        dispatch(modalToggelValue(true))
        dispatch(modalResetValue(false))


    }



    const resetLink = (data) => {
        setLoading(true)

        postMethodDataUser("/get-reset-password-link", data, USERTOKEN)
            .then((res) => {
                
                if (res.data.status === true) {
                    toast(res.data.message)
                    setLoading(false)
                    // navigate("/sent-link-mail")
                    dispatch(modalResetMsgValue(true))
                    dispatch(modalResetValue(false))
                } else {
                    setMailErr(res.data.message)
                    setLoading(false)

                }


            })
    }


    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <div className="modal-content rounded-0 position-relative">
            <button
                                    onClick={() => props.onHide()}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                <div className="sign-in">
                   
                    <div className="input-head">
                        <h3>Reset Password</h3>
                        <p>Please enter your registered email address and we would send you the link to reset your password.</p>
                    </div>
              
                <div className="select-box-top pr-0">
                    <div className="input-box">
                        <form onSubmit={handleSubmit(resetLink)}>
                            <div className="col-12 p-0">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <input type="email" className="form-control" name="email" {...register('email', { required: true })} placeholder="Enter your Email" />
                                            <small className=" form-text text-muted">{mailErr}</small>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {
                                loading === false ?
                                    <button type="submit" className="btn btn-primary-orange m-auto mb-3">Submit </button> :

                                    <Button className='btn btn-primary-orange m-auto  spinner mb-3' variant='dark' disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className='me-2'
                                        />
                                        Please wait...
                                    </Button>
                            }




                            <p className="resend new">Have an Account? <span><Link className='user-text' onClick={() => dispatchToggle()} to="/">Login <i className="fas fa-angle-right " aria-hidden="true" /></Link></span></p>

                        </form>
                    </div>
                </div>
            </div>
            
            </div>
        </Modal>


    )
}

export default ResetMail;