import React, { useCallback, useEffect, useState } from 'react'
// import { ImageObj } from '../../../assets/images/imgeObject'
import { useNavigate, useLocation, Navigate, Link } from 'react-router-dom'
// import loginAPI from "../../api/api";

import { useForm } from 'react-hook-form';
import axios from 'axios'
import { Button, Spinner, Modal } from 'react-bootstrap';

// import { toasterValue } from '../../../redux/actions/action'
// import { useDispatch } from 'react-redux';

import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { postMethodDataUser } from '../../../../../admin/adminApi/api';

import {modalBecomeValue, designResPathValue, modalRgisterlValue, modalResetValue, modalToggelValue, modalNoFilesValue, modalNvigateValue, designPathValue, authHocTrueValue } from '../../../../../../redux/actions/action';
import { useDispatch, useSelector } from 'react-redux';
import { ImageObj } from '../../../../../../assets/images/imgeObject';


const LoginUser = (props) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const USERTOKEN = localStorage.getItem("userToken")
    const ModalValue = useSelector((state) => state.modalValueReducer)

    const navigateValue = useSelector((state) => state.modalNavigateReducer)
    const designRes = useSelector((state) => state.designResPathValueReducer)
    const BecomeValue = useSelector((state) => state.modalBecomeReducer);

    const dispatch = useDispatch()
    const [errLogin, setErrLogin] = useState();
    const [errPass, setErrPass] = useState();
    const [visibility, setVisibility] = useState(true)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    const location = useLocation();
    const { register, handleSubmit, reset } = useForm();
    const [modalShow, setModalShow] = React.useState(false);


    const dispatchToggle = () => {
        dispatch(modalRgisterlValue(true))
        dispatch(modalToggelValue(false))


    }

    const pwVisibility = (value) => {
        setVisibility(value)
    }

    // useEffect(() => {

    //     const Auth1 = localStorage.getItem("firtName");
    //     const Auth2 = localStorage.getItem("token");


    //     if (Auth1 !== null && Auth2 !== null) {
    //         navigate("/admin/dashboard")
    //     }
    // }, [])


    useEffect(() => {
        document.body.classList.add("remove-header");
        return () => {
            document.body.classList.remove("remove-header");

        }
    }, [])



    useEffect(() => {

        setErrLogin("")
        setErrPass("")

    }, [props])

    useEffect(() => {

        reset()

    }, [ModalValue])


    const dispatchToggleForgot = () => {
        dispatch(modalToggelValue(false))
        dispatch(modalResetValue(true))
    }

    const designPatFunc = useCallback(() => {
        if (designRes === true) {
            dispatch(designPathValue(true))

        }
    }, [designRes])


    const onSubmit = (data) => {
        setLoading(true)
        dispatch(modalNvigateValue(false))

        postMethodDataUser("/login", data, USERTOKEN)
            .then((res) => {
        
                if (res.data.status !== true) {
                    setLoading(false)
                    setErrPass(res.data.message)


                } else {
                    // navigate("/customer/dashboard")
                    // if (res.data.result.data.user.is_admin_approved === 0 || res.data.result.data.user.is_supplier_active === "inactive") {
                    //     localStorage.setItem("supplierToken", null)
                    // }
                    dispatch(authHocTrueValue(false))
                    setLoading(false)
                    localStorage.setItem("roleUser", res.data.result.data.user.role_id)

                    document.body.classList.add("grey-bg", "top-user-dash");

                    setErrLogin("")
                    setErrPass("")
                    toast("You have logged in successfully.")
                    props.onHide()
                    if (res.data.result.data.user.role_id === 1) {
                        localStorage.setItem("userToken", res.data.result.data.token)
                        localStorage.setItem("username", res.data.result.data.user.first_name)
                    navigate("/customer/dashboard")

                        designPatFunc()

                        dispatch(designResPathValue(false))


                        if (navigateValue === true) {
                            dispatch(modalNoFilesValue(true))
                            navigate("/")

                        } else {
                            if (navigateValue === false) {
                                if (props.jumpdashboard === true) {
                                    navigate("/customer/dashboard")
                                    props.setjump(false)
                                } else {
                                    navigate("/")

                                }

                            }

                        }
                        if (res.data.result.data.user.is_supplier === 1) {
                            localStorage.setItem("is_supplier", "supplier")
                        }

                    } else {
                        localStorage.setItem("supplierToken", res.data.result.data.token)
                        // localStorage.setItem("userToken", res.data.result.data.token)
                        localStorage.setItem("username", res.data.result.data.user.first_name)

                        if (res.data.result.data.user.is_supplier === 1) {
                            localStorage.setItem("is_supplier", "supplier")
                        }
                        navigate("/supplier/dashboard")
                    }




                }
            })


    }



    return (
        <React.Fragment>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <div className="modal-content rounded-0">
                    <div className="sign-in">
                        <div className="row">
                            {/* <div className="col-md-6 sign-in-left gradient-bg2">
                                <h3 className="pt-5 pb-4 mt-3">Benefits of Login</h3>
                                <ul className="list-style-1">
                                
                                    <li>Dummy text of the printing</li>
                                    <li>Typesetting industry.</li>
                     
                                    <li>Dummy text ever</li>
                                </ul>
                            </div> */}
                            <div className="col-md-12 sign-in-right position-relative">
                                <button
                                    onClick={() => props.onHide()}
                                    type="button"
                                    className="btn-close newbtn-cls"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                                <img src={ImageObj.LoginImg} alt="Sign In" className="pb-4" />
                                <h3 className="pb-4">Sign In</h3>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="col-12 p-0">
                                        <div className="row">
                                            <div className="col-sm-12">

                                                <div className="form-group">
                                                    <input type="email" className="form-control"  {...register('email', { required: true })} placeholder="Enter email" required />
                                                    <small id="emailHelp" className=" form-text text-muted">{errLogin}</small>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group icon-position">
                                                    <input type={visibility ? "password" : "text"} autoComplete="on" className="form-control"  {...register('password', { required: true })} placeholder="Enter password" required />
                                                    {
                                                        visibility ?
                                                            <span className="icon" onClick={() => pwVisibility(false)}>
                                                                <FaEyeSlash />
                                                            </span> :
                                                            <span className="icon" onClick={() => pwVisibility(true)}>
                                                                <FaEye />
                                                            </span>
                                                    }
                                                    <small id="emailHelp" className="  form-text text-muted">{errPass}</small>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" form-check d-flex justify-content-between align-items-center">
                                        <div>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="exampleCheck1"
                                            />
                                            Remember me
                                        </div>
                                        <div>
                                            <p className="resend new mt-2 pointer"><span onClick={() => dispatchToggleForgot()}>Forgot Password?</span></p>
                                        </div>
                                    </div>



                                    {
                                        loading === false ?
                                            <button className="btn btn-primary-orange m-auto w-100 " type="submit">LOGIN <span></span></button> :
                                            <Button className=' btn btn-primary-orange m-auto w-100  spinner' variant='dark' disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className='me-2'
                                                />
                                                Sign in...
                                            </Button>

                                    }


                                    <p className="pt-3 ">
                                        <span
                                            className='pointer user-text'
                                            onClick={() =>  {
                                                if(BecomeValue.role === "supplier"){
                                                    dispatch(modalBecomeValue(true,"supplier"))
                                                    dispatch(modalToggelValue(false))
                                                }
                                                else{
                                                    dispatchToggle()
                                                }
                                            }
                                            }
                                        >
                                            Create a new account
                                        </span>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>






                {/* 
            <div className="main-theme bg-theme border otp-pad new shadow">
                <section className="select-state">
                    <div className="state-bor">
                        <div className="log-in otp-num">
                            <div className="select-steps-box">
                                <div className="input-head">
                                    <h2>Welcome Back</h2>
                                </div>
                            </div>
                            <div className="select-box-top pr-0">
                                <div className="input-box">
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className="col-12 p-0">
                                            <div className="row">
                                                <div className="col-sm-12">

                                                    <div className="form-group">
                                                        <input type="email" className="form-control"  {...register('email', { required: true })} placeholder="Enter email" required />
                                                        <small id="emailHelp" className=" form-text text-muted">{errLogin}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-group icon-position">
                                                        <input type={visibility ? "password" : "text"} className="form-control"  {...register('password', { required: true })} placeholder="Enter password" required />
                                                        {
                                                            visibility ?
                                                                <span className="icon" onClick={() => pwVisibility(false)}>
                                                                    <FaEyeSlash />
                                                                </span> :
                                                                <span className="icon" onClick={() => pwVisibility(true)}>
                                                                    <FaEye />
                                                                </span>
                                                        }
                                                        <small id="emailHelp" className="  form-text text-muted">{errPass}</small>
                                                        <p className="resend new mt-2"><Link to="resetmail">Forgot Password?</Link></p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="theme-btn select-m">
                                            {
                                                loading === false ?
                                                    <button className="btn verfy-btn" type="submit">LOGIN <span></span></button> :
                                                    <Button className='verfy-btn spinner' variant='dark' disabled>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        Sign in...
                                                    </Button>

                                            }

                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div> */}
            </Modal>

        </React.Fragment>
    )
}

export default LoginUser;
