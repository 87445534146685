import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { GiAutoRepair } from "react-icons/gi";
import { Button, Spinner, Modal } from "react-bootstrap";
import { ImageObj } from "../../../../assets/images/imgeObject";
// icons
import { FaEye, FaFilePdf, FaSearch } from "react-icons/fa";

import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";

// react bootstrap

import ChangePassword from "../../common/changePassword/changePassword";
import { getMethodData, getSubmitData } from "../../adminApi/api";
import SendEmail from "../../common/sendEmail/sendEmail";
import { addPendingValue } from "../../../../redux/actions/action";
import { useSelector, useDispatch } from "react-redux";

const BidManagement = (props) => {
  let showDataToUser = false;
  const [redirection,setRedirection] = useState(false);
  useLayoutEffect(() => {
    const ls = JSON.parse(localStorage.getItem("adminDetails"));
    // console.log("uselayout", JSON.parse(localStorage.getItem("adminDetails")));

    for (let i = 0; i <= ls?.my_permissions?.length; i++) {
      if (ls?.my_permissions[i]?.name === "Bid Management") {
        for (let j = 0; j <= ls?.my_permissions[i]?.permissions?.length; j++) {
          if (
            ls?.my_permissions[i]?.permissions[j]?.name ==="List"
          ) {
            // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
            showDataToUser = true;
            i = ls?.my_permissions?.length;
            break;
          }
        }
      }
    }
    // console.log(showDataToUser);
    if(showDataToUser===false){
      // console.log("showDataToUser ",showDataToUser)
      setRedirection(true);
    }
  }, []);
  const navigate = useNavigate();
    if (redirection) navigate('/admin/dashboard');

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();

  // const PORT = PORTNUMBER.PORTNUMBER
  const Token = localStorage.getItem("token");
  const [showPassword, setShowPassword] = useState(false);
  // modal active
  const [activeShow, setActiveShow] = useState(false);
  const [statusId, setStatusId] = useState();
  const [customerId, setCustomerId] = useState();
  const [quoteMail, setQuoteMail] = useState();

  const [loading, setLoading] = useState(false);

  const [statusType, setStatusType] = useState();

  // const [Loading, setLoading] = useState(false);

  const [resData, setResData] = useState([]);
  const [Count, setCount] = useState(1);
  const [perPage, setPerPage] = useState();

  const [indexPage, setIndexPage] = useState(1);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const [activePage, setActivePage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  // const [filterData, setFilterData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [filterVisibility, setFilterVisibility] = useState(true);

  const { register, control, handleSubmit, reset } = useForm();

  const dNone = "d-none";

  const activeHandleClose = () => setActiveShow(false);


  const activeUser = async () => {
    getMethodData(`/bids/change-status/${statusId}`, Token).then((res) => {
      if (res.data.status !== false) {
        LoadTableData();
        toast.success(res.data.message);
        activeHandleClose();
      } else {
        toast.error(res.data.message);
        activeHandleClose();
      }
    });
  };

  useEffect(() => {
    LoadTableData();
    return () => {
      LoadTableData();
    };
  }, []);

  const LoadTableData = useCallback(
    async (pageNumber) => {
      // if(id?.length){
      //     navigate("/admin/bid-management")
      // }
      setIndexPage(1);

      setLoading(false);
      await axios
        .get(`${BASE_URL}/bids?page=${pageNumber === 1 ? 1 : pageNumber}`, {
          headers: { authorization: `Bearer ${Token}` },
        })
        .then((res) => {
          setResData(res.data.result.data.data);
          setCount(res.data.result.data.total);
          setPerPage(res.data.result.data.per_page);

          setLoading(true);
          setFilterVisibility(true);
        });

      reset();
    },
    [BASE_URL, Token, reset],
    [resData, Count]
  );

  const sendPage = (pageNumber) => {
    axios
      .get(
        `${BASE_URL}/bids?page=${pageNumber}&${new URLSearchParams(
          filterData
        ).toString()}`,
        { headers: { authorization: `Bearer ${Token}` } }
      )
      .then((res) => {
        setResData(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);

        setLoading(true);
      });
  };

  const handlePageChange = (pageNumber) => {
    if (filterVisibility === true) {
      LoadTableData(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    } else {
      sendPage(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    }
  };
  useEffect(() => {
    LoadTableData();
  }, []);

  const onSubmit = async (data) => {
    dispatch(addPendingValue(false));

    setLoadingButton(true);
    setIndexPage(1);

    setFilterData(data);

    data.from_date =
      !data.from_date || data.from_date === null || data.from_date === undefined
        ? ""
        : moment(data.from_date).format("YYYY/MM/DD");
    data.to_date =
      !data.to_date || data.to_date === null || data.to_date === undefined
        ? ""
        : moment(data.to_date).format("YYYY/MM/DD");

    setLoading(false);
    // sendPage()
    await axios
      .get(`${BASE_URL}/bids?${new URLSearchParams(data).toString()}`, {
        headers: { authorization: `Bearer ${Token}` },
      })
      .then((res) => {
        setLoadingButton(false);

        setResData(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setLoading(true);
        setFilterVisibility(false);
      });
    setLoadingButton(false);
  };
  const [todayDate, setTodayDate] = useState(null);
  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="main-head">
                <h4>Bid List</h4>
              </div>
            </div>
            {/* <div className="col-6">
                            <div className="theme-btn top-head text-end">
                                <Link to="#" className="btn">Add New resData</Link>
                            </div>
                        </div> */}
          </div>
        </div>
        <div className="table-order-box shadow rounded overflow-hidden">
          <div className="tab-head">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      {...register("order_id", { required: false })}
                      className="form-control"
                      placeholder="Order Id"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      {...register("bid_id", { required: false })}
                      className="form-control"
                      placeholder="Bid Id"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group before-con">
                    <Controller
                      control={control}
                      name="from_date"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Click to select a date"
                          onChange={(date) => {
                            field.onChange(date);
                            setTodayDate(date);
                          }}
                          selected={field.value}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group before-con after">
                    <Controller
                      control={control}
                      name="to_date"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Click to select a date"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          selected={field.value}
                          minDate={todayDate == null ? new Date() : todayDate}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <select
                      className="form-select form-control"
                      {...register("status", { required: false })}
                      aria-label="Default select example"
                    >
                      <option className="disabled-value " value="">
                        All
                      </option>
                      <option value="Upcoming">Upcoming</option>
                      <option value="Open">Open</option>
                      <option value="Expired">Expired</option>
                      <option value="Closed">Closed</option>
                      <option value="Awarded">Awarded</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="theme-btn btn-group-right text-end">
                    {loadingButton === false ? (
                      <button type="submit" className="btn">
                        Search
                      </button>
                    ) : (
                      <Button className="btn  spinner" variant="dark" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                        />
                        Searching...
                      </Button>
                    )}
                    <button
                      type="reset"
                      onClick={() => LoadTableData()}
                      className="btn"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="project-table">
        <div className="form-search">
          <form>
            <div className="form-group icon-input top-search">
              <input
                type="text"
                id="searchForm"
                className="form-control"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                placeholder="Search"
              />
              <FaSearch />
            </div>
            <input type="btn" className="d-none" value="Submit" />
          </form>
        </div>
        <div className="table-order shadow">
          <table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th className="text-nowrap">Bid ID</th>
                {/* <th className="text-nowrap">Image</th> */}
                <th className="text-nowrap">Date</th>
                <th className="text-nowrap">Status</th>
                <th className="text-nowrap">Order ID</th>
                <th className="text-nowrap">Customer Name</th>
                <th className="text-nowrap">No. Of Offers</th>
                <th className="text-nowrap">Awarded To</th>
                <th className="text-nowrap">Starts On </th>
                <th className="text-nowrap">Ends On</th>
                <th className="text-nowrap">Order Cost</th>
                <th className="text-nowrap">Parts Cost</th>
                <th className="text-nowrap">Discount</th>
                <th className="text-nowrap">Min Bid</th>
                <th className="text-nowrap">Current bid</th>
                <th className="text-nowrap">Awarded Cost</th>
                <th className="text-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
             
              {resData && resData.length ? (
                resData
                  .filter((val) => {
                    const date = val?.created_at;
                    const date2 = val?.open_date_time;
                    const date3 = val?.expiry_date_time;
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val?.bid_id !== null && val?.bid_id !== undefined &&
                      val.bid_id
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val?.order?.customer?.first_name !== null && val?.order?.customer?.first_name !== undefined &&
                      val?.order?.customer?.first_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val?.order?.order_id !== null && val?.order?.order_id !== undefined &&
                      val?.order?.order_id.toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val?.order?.customer?.last_name !== null && val?.order?.customer?.last_name !== undefined &&
                      val?.order?.customer?.last_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      date !== null && date !== undefined &&
                      moment(date)
                        .format("MM/DD/yyyy")
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    }
                    else if (
                      date2 !== null && date2 !== undefined &&
                      moment(date2)
                        .format("MM/DD/yyyy")
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    }
                    else if (
                      date3 !== null && date3 !== undefined &&
                      moment(date3)
                        .format("MM/DD/yyyy")
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else {
                      return false;
                    }
                  })
                  .map((res, index) => (
                    <tr key={res.id}>
                      <td className="s-no bg-transparent" scope="row">
                        {perPage * (indexPage - 1) + (index + 1)}
                      </td>
                      <td className="bg-transparent">#{res.bid_id}</td>
                      {/* <td className="bg-transparent">
                        {" "}
                        <img
                          className="h-100 w-100"
                          src={ImageObj.ProductBig}
                          alt="..."
                        />
                      </td> */}

                      <td className="bg-transparent">
                        {moment(res.created_at).format("MM/DD/yyyy")}
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            res.status === "Upcoming" ? "text-dark" : ""
                          }  ${
                            res.status === "Upcoming"
                              ? "bg-warning text-light"
                              : res.status === "Expired"
                              ? "bg-danger text-light"
                              : res.status === "Awarded"
                              ? "bg-success text-light"
                              : res.status === "Open"
                              ? "text-dark"
                              : "bg-light  "
                          }`}
                        >
                          {res.status}
                        </span>
                      </td>
                      <td className="bg-transparent">#{res.order?.order_id}</td>
                      <td className="bg-transparent">
                        {res?.order?.customer?.first_name}{" "}
                        {res?.order?.customer?.last_name}
                      </td>
                      <td className="bg-transparent">
                        {res?.bid_response_count}
                      </td>
                      <td className="bg-transparent">
                        {res?.awarded_to
                          ? (
                              <>
                                {res?.awarded_to.first_name}{" "}
                                {res?.awarded_to?.last_name}
                              </>
                            ) ?? "-"
                          : "-"}
                      </td>
                      <td className="bg-transparent">
                        {moment(res.open_date_time).format("MM/DD/yyyy")}
                        <br />
                        {moment(res.open_date_time).format("hh:mm a")}
                      </td>
                      <td className="bg-transparent">
                        {moment(res.expiry_date_time).format("MM/DD/yyyy")}
                        <br />
                        {moment(res.expiry_date_time).format("hh:mm a")}
                      </td>
                      <td className="bg-transparent">$ {res?.order_amount}</td>
                      <td className="bg-transparent">
                        $ {res?.bid_part?.total_price}
                      </td>

                      <td className="bg-transparent">
                        $ {res?.part_discount_amount}
                      </td>
                      <td className="bg-transparent">$ {res?.offer_amount}</td>
                      <td className="bg-transparent">$ {res?.bid_min_proposal_amount}</td>
                      <td className="bg-transparent">$ {res?.awarded_cost}</td>

                      <td className="action-top bg-transparent">
                        <Link
                          title="View Bid"
                          to={`${res.id}`}
                          className="icons-action"
                        >
                          <FaEye />
                        </Link>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td className="no-data text-uppercase">NO Bid FOUND</td>
                </tr>
              )}
            </tbody>
          </table>
          {!loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
        </div>
        <div className="pagination">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={perPage}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        </div>
      </div>

      {/* modals */}

      {/* active modal */}
      <Modal
        className="status-modal"
        animation={false}
        show={activeShow}
        onHide={activeHandleClose}
      >
        <Modal.Body>
          Are you sure you want to{" "}
          {statusType === "active" ? "in-activate" : "activate"} this Customer?
        </Modal.Body>
        <Modal.Footer>
          <div className="theme-btn btn-group-right text-end">
            <Button variant="secondary" onClick={activeUser}>
              Yes
            </Button>
            <Button variant="primary" onClick={activeHandleClose}>
              No
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* active modal end */}

      <ToastContainer />
      <ChangePassword
        api={`/customers/change-password/${customerId}`}
        show={showPassword}
        dNone={dNone}
        onHide={() => setShowPassword(false)}
      />
      <SendEmail
        api={"/suppliers/send-contect-email"}
        show={showEmail}
        quoteMail={quoteMail}
        dNone={dNone}
        onHide={() => setShowEmail(false)}
      />
    </div>
  );
};
export default BidManagement;
