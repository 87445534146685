
import React, { useCallback, useEffect, useState,useLayoutEffect } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom"
import { useForm, Controller } from 'react-hook-form';
import Multiselect from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Button, Spinner } from 'react-bootstrap'

import FormValidation from '../../../../formValidation/formValidation'
import { getMethodData, getMethodDataSupplier, postMethodData } from '../../adminApi/api';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'react-phone-number-input/input'
import * as Yup from "yup";



const EditSupplier = () => {
  let  showDataToUser =false; 
    //redirection permission
    const [redirection,setRedirection] = useState(false);
    useLayoutEffect(() => {
        const ls=JSON.parse(localStorage.getItem("adminDetails"));
     // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
       
        for(let i=0; i<=ls?.my_permissions?.length;i++){
            if(ls?.my_permissions[i]?.name==="Supplier Management"){
                
                for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                    if(ls?.my_permissions[i]?.permissions[j]?.name==='Edit'){
                        // console.log(ls?.my_permissions[i]?.permissions[j].name);
                        showDataToUser = true; 
                    }
                }
            }
        }
        // console.log(showDataToUser);
      if(showDataToUser===false){
        // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
    }, []);
    const navigate = useNavigate();
   if (redirection) navigate('/admin/supplier-management');
  // \\permission redirection
  const [tolleranceData,setTolleranceData]=useState();
  const [surfaceRoughness ,setSurfaceRoughness]=useState();
    const { id } = useParams()
    // const PORT = PORTNUMBER.PORTNUMBER
  const Token = localStorage.getItem("token")
  const [editData, setEditData] = useState(["not"])
  const [mobileErr, setMobileErr] = useState([""])
  const [emailErr, setEmailErr] = useState()
  const [loading, setLoading] = useState(false)
  const FormValidationNew = FormValidation
  const [value, setValue] = useState()


  const [capabilityData, setCapabilityData] = useState([])
  // const [manufacturing_process, setmanufacturing_process] = useState();
  // const [tolerance, setolerence] = useState();
  // const [Surface_roughness, setSurface_roughness] = useState();
  // const [Inspection, setInspection] = useState();

  const [inspection, setinspection] = useState([])
  const [manufacturing, setmanufacturing2] = useState([])
  const [material, setmaterial] = useState([])
  const [part_qualifications, setpart_qualifications] = useState([])
  const [tolerance_new, settolerance  ] = useState([])
  const [surface_roughness , setsurface_roughness  ] = useState([])
  const [Coating, setCoating] = useState([]);



  const [inspectionerr, setinspectionerr] = useState("")
  const [manufacturingerr, setmanufacturing2err] = useState("")
  const [materialerr, setmaterialerr] = useState("")
  const [part_qualificationserr, setpart_qualificationserr] = useState("")
  const [surface_roughnesserr , setsurface_roughnesserr  ] = useState("")
  const [tolerance_newerr    , settoleranceerr  ] = useState("")
  const [coatingerr, setcoatingerr] = useState("")



  const [qualifications, setqualifications] = useState(); 
  
  
  
  
  
  
    // form validation rules
    const validationSchema = Yup.object().shape({

        email: Yup.string().email('The email must be a valid email. '),
        phone: Yup.string().min(14).required('A phone number is required'),

        // image: Yup.mixed().required('Image file is required')
        //     .test("type", "Only .pdf Formate is accepted", (value) => { 
        //         return value && (
        //         value[0].type === 'application/pdf'
        //         );
        //     })
      

    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const editSupplier = useCallback(() => {
        getMethodData(`/suppliers/edit/${id}`,Token)
            .then((res) => {
                setEditData(res.data.result.data)
                setinspection(res.data.result.data.technical_capabilities.inspection)
                setmanufacturing2(res.data.result.data.technical_capabilities.manufacturing_process)
                setmaterial(res.data.result.data.technical_capabilities.material_certifications)
                setpart_qualifications(res.data.result.data.technical_capabilities.part_qualifications_certifications)
                setsurface_roughness(res.data.result.data.technical_capabilities.surface_roughness)
                settolerance(res.data.result.data.technical_capabilities.tolerance)
                setCoating(res.data.result.data.technical_capabilities.coatings)
                getMethodDataSupplier(`/get-technical-capabilities`, Token).then((res)=>{
                  setCapabilityData(res.data.result.data)
                })
                setValue("+1" + res.data.result.data.phone.replace(/[()\ \s-]+/g, ''))
            })
    },[id])
    
    useEffect(() => {
        editSupplier()
    }, [getMethodData, id])

    // console.log(manufacturing);
    // const TechnicalCapabilities = (e) => {
    //     // setCapabilityData()
    //     getMethodDataSupplier(`/get-technical-capabilities?manufacturing_process`, Token).then((res)=>{
    //         setCapabilityData(res.data.result.data)
    //       })
    //   }


    const onSubmit = (data) => {

      if (manufacturing.length === 0) {
        // alert("Please select values");
        setmanufacturing2err("Please select Manufacturing Process")
        // return;
      }
      if(surface_roughness.length === 0){
        setsurface_roughnesserr("Please select Surface Roughness")
        // alert("Please select values");
        // return;
      }
      if(tolerance_new.length === 0){
        settoleranceerr("Please select Tolerance")
        // alert("Please select values");
        // return;
      }
      if(inspection.length === 0){
        setinspectionerr("Please select Inspection")
        // alert("Please select values");
        // return;
      }
      if(part_qualifications.length === 0){
        setpart_qualificationserr("Please select Part Quality Certifications")
        // alert("Please select values");
        // return;
      }
      if(material.length === 0){
        setmaterialerr("Please select Part Material Certifications")
        // alert("Please select values");
        // return;
      }
      if(Coating.length === 0){
        setcoatingerr("Please select Coating")
        // alert("Please select values");
        // return;
      }
      if( manufacturing.length === 0 || surface_roughness.length === 0 || tolerance_new.length === 0 || inspection.length === 0 || part_qualifications.length === 0 || material.length === 0 || Coating.length === 0){
        return;
      }

        
        setLoading(true)
        var inputs = document.getElementsByClassName('form-control');

        const imageFiles = document.getElementById('document').files.length;

        const fd = new FormData();
    
    // console.log("inputs",inputs)
        for (let i = 0; i < inputs.length; i++) {
    
            fd.append(inputs[i].name, inputs[i].value);
            // // console.log("gfggg",inputs[i].name, inputs[i].value)
    
        }
    
        if (imageFiles > 0) {
            const File = document.getElementById('document').files[0];
            fd.append("image", File);
        }
        fd.append("manufacturing_process",manufacturing);
        fd.append("surface_roughness", surface_roughness);
        fd.append("tolerance", tolerance_new);
        fd.append("inspection", inspection);
        fd.append("part_qualifications_certifications",part_qualifications);
        fd.append("material_certifications",material);
        fd.append("coatings", Coating);

        postMethodData(`/suppliers/update/${id}`, fd, Token)
            .then((res) => {

                if (res?.data?.status === true) {
                    setLoading(false)
                    toast(res?.data?.message)
                    navigate("/admin/supplier-management")
                    reset()
                } else {
                    setLoading(false)
                    setMobileErr(res?.data?.result?.errors?.phone)
                    setEmailErr(res?.data?.result?.errors?.email)
                    toast.error("Somthing Went Wrong!")
                }
                setLoading(false)
            }).catch((error) => {
                // console.log(error);
            })

    };


    useEffect(() => {
        FormValidationNew()
        // console.log("manufacturing",manufacturing);
    }, [FormValidationNew])
  
    const removeItems = async (arr, element) => {
      for (let i = 0; i < arr.length; i++) {
          if(arr[i] == element) {
              arr.splice(i,1);
          }
          else{
            arr.splice(i , 0);
          }
      }
  }
    return (
        <div className="tables-field">
            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="main-head">
                                <h4>Edit Supplier</h4>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="theme-btn text-end top-head">
                                <Link to="/admin/supplier-management" className="btn">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" maxLength="15" defaultValue={editData.first_name} className="form-control" id="validationCustom01"  {...register('first_name', { required: false })} placeholder="First Name" required />
                                        <div className="invalid-feedback">
                                            Please Enter Supplier First Name.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" maxLength="15" defaultValue={editData.last_name} className="form-control" id="validationCustom01"  {...register('last_name', { required: false })} placeholder="Last Name" required />
                                        <div className="invalid-feedback">
                                            Please Enter Supplier Last Name.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" maxLength="100" defaultValue={editData.email} className="form-control" id="validationCustom02" {...register('email', { required: false })} placeholder="Email" required />
                                        <small className='text-danger'> {errors.email?.message}</small>
                                        <small className="form-text text-muted d-block">{emailErr}</small>
                                        <div className="invalid-feedback">
                                            Please use @ for a valid email.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Mobile</label>
                                        {/* <input type="text" maxLength="15" defaultValue={editData.phone} className="form-control" id="validationCustom03" {...register('phone', { required: false })} placeholder="Mobile" required /> */}
                                        <Input
                                        className="form-control"
                                                minLength={14}
                                                maxLength={14}
                                                value={value}
                                                onChange={setValue}
                                                name="phone"
                                                autoFocus
                                                defaultCountry="US"
                                                placeholder="Enter phone number"
                                                {...register('phone', { required: true })}
                                                id="validationCustom03"
                                                required
                                        />
                                        <small className='font-12 d-block'>E.g (xxx) xxx-xxxx , No Need for Country Code</small>
                                        <small className='text-danger'> {errors.phone?.message}</small>

                                        <small className="form-text text-muted">{mobileErr}</small>
                                        <div className="invalid-feedback">
                                            Please enter mobile number.
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Zipcode</label>
                                        <input type="number" maxLength="6" minLength={1} min={0} defaultValue={editData.zipcode} className="form-control" id="validationCustomnew2" {...register('zipcode', { required: false })} placeholder="Zipcode" required/>
                                        <div className="invalid-feedback">
                                            Please enter zipcode.
                                        </div>
                                        <small className='text-danger'> {errors.zipcode?.message}</small>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Company</label>
                                        <input type="text" maxLength="70" defaultValue={editData.company_name} className="form-control" id="validationCustomnew4" {...register('company_name', { required: false })} placeholder="Company" required />
                                        <div className="invalid-feedback">
                                            Please enter company name.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className='row'>
                                        <div className={editData.aws_file_path !== null ? 'col-10 ' : 'col-12'}>
                                            <div className="form-group">
                                                <label>W-9</label>
                                                <div className='file-upload-div' >
                                                  <input 
                                                  accept="application/pdf" 
                                                  className="form-control" 
                                                  id="document"
                                                  type="file" 
                                                  {...register('image', { required: true })} 
                                                  name="image"
                                                   />
                                                  <span>W-9 File</span>
                                                  </div>
                                                  <small className='text-danger'> {errors.image?.message}</small>
                                                  <div className="invalid-feedback">
                                                      Please upload document.
                                                  </div>
                                            </div>
                                        </div>
                                        <div className={editData.aws_file_path !== null ? 'col-2' : 'd-none'}>
                                            {
                                                editData.aws_file_path !== null && 
                                                <a className='mt-4 pt-2 d-block edit-file' href={editData.aws_file_path} target="_blank">View Document</a>
                                                

                                            }
                                           
                                        </div>
                                    </div>

                                </div>
                              
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <select  defaultValue={editData.is_supplier_active === "active" ? 1 : 0} className="form-select form-control" {...register('status', { required: false })} id="validationCustom04" aria-label="Default select example" required>
                                            <option className="disabled-value d-none" value={editData.is_supplier_active === "active" ? 1 : 0}  >{editData.is_supplier_active}</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                        <div className="invalid-feedback">
                                            Please select status.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Select Verification Status</label>
                                        <select className="form-select form-control" {...register('is_admin_approved', { required: false })} id="validationCustom04" aria-label="Default select example" required>
                                            <option className="disabled-value d-none" value={editData.is_admin_approved === 1 ? 1 : 0}  >{editData.is_admin_approved === 1 ? "Approved" : "Pending"}</option>
                                            <option value="1">Approved</option>
                                            <option value="0">Pending</option>
                                        </select>
                                        <div className="invalid-feedback">
                                            Please select status.
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                      <div className="form-group">
                        <label>Manufacturing Process*</label>
                        <Multiselect
                        className="cust-select"
                        selectedValues={manufacturing}
                          options={capabilityData?.manufacturing_process} // Options to display in the dropdown
                          // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                          onSelect={(selectedList, selectedItem) => {
                            setmanufacturing2err("");
                            setmanufacturing2([...selectedList]);
                          }} // Function will trigger on select event
                          onRemove={(selectedList,selectedItem) => {
                            removeItems(manufacturing,selectedItem);
                        }}
                         
                          // displayValue="name" // Property name to display in the dropdown options
                          showCheckbox={true}
                          isObject={false}
                        />
                        {manufacturingerr != "" &&
                        <div className='invalid-feedback d-block'>
                          {manufacturingerr}
                        </div>
                        }
                      </div>
                    </div>
                    {capabilityData?.tolerance &&
                    capabilityData?.tolerance.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tolerance*</label>
                          <Multiselect
                          className="cust-select"
                            options={capabilityData?.tolerance} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            selectedValues= {tolerance_new }
                            
                            // onSelect={(selectedList, selectedItem) => {
                            //   settoleranceerr("");
                            //   settolerance([...selectedList]);
                            //   // console.log("demo",tolerance);
                            //   if(selectedItem===capabilityData?.tolerance[capabilityData?.tolerance.length-1]){
                            //     selectedList=capabilityData?.tolerance;
                               
                            //     settolerance([...capabilityData?.tolerance])
                            //     // document.getElementById("pepega").children[1].childNodes[0].childNodes.forEach(i=>i.setAttribute('checked','true'));
                            //   }
                            //   if(selectedItem!==capabilityData?.tolerance[capabilityData?.tolerance.length-1]){ 
                            //     settolerance([...selectedList]);
                               
                            //     }
                            // }} 


                            onSelect={(selectedList, selectedItem) => {
                              settoleranceerr("");
                              const selectedIndex = capabilityData?.tolerance.findIndex(
                                (option) => option === selectedItem
                              );
                              if (selectedIndex !== -1) {
                                // Down option selected
                                selectedList = capabilityData?.tolerance.slice(0, selectedIndex + 1);
                                settolerance([...selectedList]);
                              } else {
                                settolerance([...selectedList]);
                              }
                            }}
                            
                            
                            
                            // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removeItems(tolerance_new,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {tolerance_newerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {tolerance_newerr}
                          </div>
                          }
                        </div>
                      </div>
                    )}
                    {capabilityData?.surface_roughness &&
                    capabilityData?.surface_roughness.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                   <div className="form-group">
                
                          <label>Surface roughness*</label>
                          <Multiselect
                          className="cust-select"
                            options={ capabilityData?.surface_roughness} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            selectedValues={surface_roughness}
                           
                            // onSelect={(selectedList, selectedItem) => {
                            //   setsurface_roughnesserr("")
                            //   setsurface_roughness([...selectedList]);
                            //   if(selectedItem===capabilityData?.surface_roughness[capabilityData?.surface_roughness.length-1]){
                            //     selectedList=capabilityData?.surface_roughness;
                               
                            //     setsurface_roughness([...capabilityData?.surface_roughness])
                            //     // document.getElementById("pepega").children[1].childNodes[0].childNodes.forEach(i=>i.setAttribute('checked','true'));
                            //   }
                            //   if(selectedItem!==capabilityData?.surface_roughness[capabilityData?.surface_roughness.length-1]){ 
                            //     setsurface_roughness([...selectedList]);
                               
                            //     }

                            // }} 

                            onSelect={(selectedList, selectedItem) => {
                              setsurface_roughnesserr("");
                              const selectedIndex = capabilityData?.surface_roughness.findIndex(
                                (option) => option === selectedItem
                              );
                              if (selectedIndex !== -1) {
                                // Down option selected
                                selectedList = capabilityData?.surface_roughness.slice(0, selectedIndex + 1);
                                setsurface_roughness([...selectedList]);
                              } else {
                                setsurface_roughness([...selectedList]);
                              }
                            }}
                            
                            
                            
                            // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removeItems(surface_roughness,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {surface_roughnesserr != "" &&
                          <div className='invalid-feedback d-block'>
                            {surface_roughnesserr}
                          </div>
                          }
                        </div>
                      </div>
                    )}
                    {capabilityData?.inspection &&
                    capabilityData?.inspection.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                           <div className="form-group">
                          <label>Inspection*</label>
                        
                          <Multiselect
                           selectedValues={inspection}
                          className="cust-select"
                            options={capabilityData?.inspection}
                          // Options to display in the dropdown
                            // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setinspectionerr("");
                              setinspection([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removeItems(inspection,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                         {inspectionerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {inspectionerr}
                          </div>
                          }
                        </div>
                      </div>
                    )}

                    {capabilityData?.part_qualifications_certifications &&
                    capabilityData?.part_qualifications_certifications.length ==
                      0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                   <div className="form-group">
                          <label>Part Quality Certifications*</label>
                          <Multiselect
                          className="cust-select"
                          selectedValues={part_qualifications}
                            options={ capabilityData?.part_qualifications_certifications } // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setpart_qualificationserr("");
                              setpart_qualifications([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removeItems(part_qualifications,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                         {part_qualificationserr != "" &&
                          <div className='invalid-feedback d-block'>
                            {part_qualificationserr}
                          </div>
                          }
                        </div>
                      </div>
                    )}

                    {capabilityData?.material_certifications &&
                    capabilityData?.material_certifications.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                                <div className="form-group">
                          <label>Material certifications*</label>
                          <Multiselect
                          className="cust-select"
                          selectedValues={material}
                            options={  capabilityData?.material_certifications } // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setmaterialerr("")
                              setmaterial([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removeItems(material,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                         {materialerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {materialerr}
                          </div>
                          }
                        </div>
                      </div>
                    )}  

                  {capabilityData?.coatings &&
                    capabilityData?.coatings.length === 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Coating*</label>
                          <Multiselect
                            className="cust-select"
                            selectedValues={Coating}
                            options={capabilityData?.coatings} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setcoatingerr("")
                              setCoating([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removeItems(Coating,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {coatingerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {coatingerr}
                          </div>
                          }
                        </div>
                      </div>
                    )}          


                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-end">
                                        {
                                            loading === false ?
                                                <button type="submit" className="btn">Update</button> :
                                                <Button className='btn  spinner' variant='dark' disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"

                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    Updating...
                                                </Button>
                                        }
                                        <Link to="/admin/supplier-management" className="btn">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
export default EditSupplier;