import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import logo from "../../../../assets/images/logo.png";

// import PORTNUMBER from '../../views/api/api'

import { getMethodData } from "../../adminApi/api";
// import { ImageObj } from '../../assets/images/imgeObject'
import { Dropdown, Modal, Button } from "react-bootstrap";
// import CHANGEPASSWORD_API from '../../views/api/api'
// import { addToggle, removeToggle, toasterValue } from '../../redux/actions/action'
// import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate, NavLink } from "react-router-dom";

import { MdAccountCircle } from "react-icons/md";
import { toast } from "react-toastify";
import ChangePassword from "../changePassword/changePassword";
import UpdateProfile from "../updateProfile/updateProfile";
import { FaRegBell } from "react-icons/fa";

const Header = ({ unAuth }) => {
  // const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const Token = localStorage.getItem("token");
  const AdminName = localStorage.getItem("firtName");

  // const PORT = PORTNUMBER.PORTNUMBER
  const [show, setShow] = useState(false);
  const [count, setcount] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [passErr, setPassErr] = useState();
  const [currentpPassErr, setCurrentPassErr] = useState();

  const [settingsLogo, setSettings] = useState();

  const [visibilityNew, setVisibilityNew] = useState(true);
  const [visibility, setVisibility] = useState(true);
  const [visibilityConfirm, setVisibilityConfirm] = useState(true);

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClosePw = () => {
    setShowPassword(false);
    setVisibilityNew(true);
    setVisibility(true);
    setVisibilityConfirm(true);
    reset();
  };
  const handleShowPw = () => setShowPassword(true);

  // const toggle = useSelector((state) => state);

  const { register, handleSubmit, reset } = useForm();

  const pwVisibility = (value) => {
    setVisibility(value);
  };
  const pwVisibilityConfirm = (value) => {
    setVisibilityConfirm(value);
  };

  const pwVisibilityNew = (value) => {
    setVisibilityNew(value);
  };
  const getProfile = useCallback(() => {
    reset();
    if(Token){
      getMethodData("/get-profile", Token).then((res) => {
        setcount(res.data.result.un_read_notification_count);
      });
    }
  }, [Token]);

  useEffect(() => {
    getProfile();
  }, [Token]);

  const LogOut = () => {
    unAuth();
    toast("You have logged out successfully.");
    navigate("/admin");
  };

  const nameObj = {
    KeyTwo: "old_password",
    KeyThree: "new_password",
  };

  // useEffect(() => {
  //     axios.post(`${BASE_URL}/setting/detail`, { key: "SITE_LOGO" }, { headers: { authorization: `Bearer ${Token}` } })
  //         .then((res) => {
  //             setSettings(res.data.data.content)
  //         })
  //     return () => {

  //     }
  // }, [BASE_URL, Token])

  document.body.classList.remove("grey-bg", "top-user-dash");
  document.body.classList.add("bg-white");
  function notification_fun(){
    setcount(0)
  }
  return (
    <React.Fragment>
      <header className="top-header">
        <div className="container-fluid">
          <div className="col-md-12 p-0">
            <div className="row">
              <div className="col-sm-4">
                <div className="top-search d-flex main-slide main-tog">
                  <div className="logo-image">
                    {/* <h2 className='text-uppercase'>mach2mfg</h2> */}
                    <img width={250} src={logo} />
                    {/* {
                                        settingsLogo === undefined?
                                        (  <div className="image-in"><img src={settingsLogo} alt="logo" /></div>):
                                        (  <h2 className='text-uppercase'>mach2mfg</h2>)
                                    }
                                   */}
                  </div>
                  {true ? (
                    <div
                      className="toggle-top"
                      // onClick={() => dispatch(addToggle(false))}
                    >
                      <div id="toggle">
                        <div className="one" />
                        <div className="two" />
                        <div className="three" />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="toggle-top"
                      // onClick={() => dispatch(removeToggle(true))}
                    >
                      <div id="toggle" className="on">
                        <div className="one" />
                        <div className="two" />
                        <div className="three" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-4 d-flex align-items-center justify-content-center">
                <div className="">
                  <h3 className="text-white">Admin Panel</h3>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="d-flex user-top">
                  <p className="hover-white">Welcome {AdminName}</p>

                  <Dropdown className="theme-btn">
                    <Dropdown.Toggle
                      className="btn m-0 p-0 fs-3"
                      id="dropdown-basic"
                    >
                      <MdAccountCircle className="circle-avtar" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleShow}>
                        Update Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleShowPw}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => LogOut()}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <NavLink to="admin/notification-list">
                    {count === 0 || null ? (
                      <>
                        {" "}
                        <span className="circle-avtar ms-3  fs-3 position-relative" >
                          <FaRegBell />
                          <span className="badge-tp">{count}</span>
                        </span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <span className="circle-avtar ms-3  fs-3 position-relative" onClick={notification_fun}>
                          <FaRegBell />
                          <span className="animate-ping  badge-tp"></span>
                          <span className=" badge-tp">{count}</span>
                        </span>
                      </>
                    )}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <ChangePassword
            api={"/update-password"}
            KeyThree={"new_password"}
            KeyTwo={"old_password"}
            unAuth={unAuth}
            show={showPassword}
            onHide={handleClosePw}
          />
          <UpdateProfile show={show} onHide={handleClose} />
        </div>
      </header>
      <Outlet />
    </React.Fragment>
  );
};

export default Header;
