import {combineReducers} from 'redux'
import pendingValueReducers from './pendingValueReducer'
import {modalValueReducer,modalRegisterReducer,modalResetReducer,modalResetMsgReducer,modalAddressReducer,modalRegisterSuppReducer,modalConditionReducer,modalPolicyReducer,modalBecomeReducer,modalNoFilesReducer,modalNavigateReducer,dropDownValueReducer,designPathValueReducer,designResPathValueReducer,authHocValueReducer,caddRegisterReducer,idUploadReducer,userDataObjReducer,AdminDataObjReducer,contactUsForm, customerLoader,adminManualQuote} from './modalValueReducer'





  const rootReducer = combineReducers({
    contactUsForm,
    pendingValueReducers,
    modalValueReducer,
    modalRegisterReducer,
    modalResetReducer,
    modalResetMsgReducer,
    modalAddressReducer,
    modalRegisterSuppReducer,
    modalConditionReducer,
    modalPolicyReducer,
    modalBecomeReducer,
    modalNoFilesReducer,
    modalNavigateReducer,
    dropDownValueReducer,
    designPathValueReducer,
    designResPathValueReducer,
    authHocValueReducer,
    caddRegisterReducer,
    idUploadReducer,
    userDataObjReducer,
    AdminDataObjReducer,
    customerLoader,
    adminManualQuote
    
})

export default rootReducer