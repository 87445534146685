import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { postMethodDataUser } from '../../../../../admin/adminApi/api';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import FormValidation from '../../../../../../formValidation/formValidation';


import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";



const ChangePassword = (props) => {

    const [disabledPassword, setDisabledPassword] = useState(true)
    const [visibilityNew, setVisibilityNew] = useState(true)
    const [visibility, setVisibility] = useState(true)
    const [visibilityConfirm, setVisibilityConfirm] = useState(true)
    const [currentpPassErr, setCurrentPassErr] = useState();
    const USERTOKEN = localStorage.getItem("userToken")



    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }

    const pwVisibilityNew = (value) => {
        setVisibilityNew(value)
    }

    const validationSchema = Yup.object().shape({
        new_password: Yup.string().required("Password is required").min(6, 'Password is too short - should be 6 chars minimum.').max(18, 'Password is too long - should be 18 chars minimum.').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
            "Please Check Password Format"
        ),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("new_password"), null], "Passwords must match").min(6, 'Confirm password is too short - should be 6 chars minimum.').max(18, 'Password is too long - should be 18 chars minimum.')
            .required("Confirm Password is required"),
        email: Yup.string().email('The email must be a valid email. '),
        old_password: Yup.string().required("Old password is required")


    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const FormValidationNew = FormValidation




    
 

    const onSubmit = (data) => {


        postMethodDataUser("/update-password", data, props.token)
            .then((res) => {
                
                if (res.data.status !== false) {
                    setDisabledPassword(true)
                    toast.success(res.data.message)
                    // LogOut()
                    setCurrentPassErr("")
                    reset();



                } else {
                    toast.error(res.data.message)
                    setCurrentPassErr(res.data.message)

                }
            })


    }


    useEffect(() => {
        FormValidationNew()
    }, [FormValidationNew,disabledPassword])

    return (
        <React.Fragment>

            <form className="needs-validation" onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group icon-position">
                            <input autoComplete="on" type={visibility ? "password" : "text"} maxLength="18" className="form-control form-password" id="validation1"  {...register('old_password', { required: false })} placeholder="Current Password"  />
                            {
                                visibility ?
                                    <span className="icon" onClick={() => pwVisibility(false)}>
                                        <FaEyeSlash />
                                    </span> :
                                    <span className="icon" onClick={() => pwVisibility(true)}>
                                        <FaEye />
                                    </span>
                            }
                            <small id="emailHelp" className=" form-text text-muted">{currentpPassErr}</small>
                            <small className='text-danger'> {errors.old_password?.message}</small>
                            <div className="invalid-feedback">
                                Please Enter Old Password.
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group icon-position">
                            <input autoComplete="on" type={visibilityNew ? "password" : "text"} maxLength="18" className="form-control form-password" id="validation2"  {...register('new_password', { required: false })} placeholder="New Password"  />
                            {
                                visibilityNew ?
                                    <span className="icon" onClick={() => pwVisibilityNew(false)}>
                                        <FaEyeSlash />
                                    </span> :
                                    <span className="icon" onClick={() => pwVisibilityNew(true)}>
                                        <FaEye />
                                    </span>
                            }
                            <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>
                            <small className='text-danger'> {errors.new_password?.message}</small>

                            <div className="invalid-feedback">
                                Please Enter New Password.
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group icon-position">
                            <input autoComplete="on" type={visibilityConfirm ? "password" : "text"} maxLength="18" className="form-control form-password" id="validation3" {...register('confirm_password', { required: false })} placeholder="Confirm Password"  />
                            {
                                visibilityConfirm ?
                                    <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                        <FaEyeSlash />
                                    </span> :
                                    <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                        <FaEye />
                                    </span>
                            }
                            <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>
                            <small className='text-danger'> {errors.confirm_password?.message}</small>
                            <div className="invalid-feedback">
                                Please Enter Confirm Password.
                            </div>
                         
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mb-5">
                       
                           <button type="submit" className="btn btn-primary-orange m-0">Update</button>
                        
                    </div>
                </div>
            </form>
        </React.Fragment>

    )
}

export default ChangePassword;