

import axios from 'axios'
// import axios from 'axios'
import {axiosInstance,axiosInstanceuser,axiosInstancesupplier} from '../../../axiosInstance'
const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_USER = process.env.REACT_APP_BASE_URL_USER+"/user";
const BASE_URL_SUPPLIER = process.env.REACT_APP_BASE_URL_USER+"/supplier";





// get and post method api admin

export const getMethodData = async (API_URL,TokenNew) => {
    return(
       await axiosInstance.get(`${BASE_URL}${API_URL}`,{ headers: { authorization: `Bearer ${TokenNew}` } })
    )
}

export const postMethodData = async (API_URL,data,Token) => {
    return(
        await axiosInstance.post(`${BASE_URL}${API_URL}`,data,{ headers: { authorization: `Bearer ${Token}` } })
    )
}


export const getSubmitData = async (API_URL,TokenGet) => {
    return(
       await axiosInstance.get(`${BASE_URL}${API_URL}`,{ headers: { authorization: `Bearer ${TokenGet}` } })
    )
}
// get and post method api user

export const getMethodDataUser = async (API_URL,USERTOKEN2) => {
    return(
       await axiosInstanceuser.get(`${BASE_URL_USER}${API_URL}`,{ headers: { authorization: `Bearer ${USERTOKEN2}` } })
    )
}


export const postMethodDataUser = async (API_URL,data,USERTOKEN3) => {
    return(
        await axiosInstanceuser.post(`${BASE_URL_USER}${API_URL}`,data,{ headers: { authorization: `Bearer ${USERTOKEN3}` } })
    )
}





// get and post method api Supplier

export const getMethodDataSupplier = async (API_URL,SUPPLIERTOKEN2) => {
    return(
       await axiosInstancesupplier.get(`${BASE_URL_SUPPLIER}${API_URL}`,{ headers: { authorization: `Bearer ${SUPPLIERTOKEN2}` } })
    )
}


export const postMethodDataSupplier = async (API_URL,data,SUPPLIERTOKEN3) => {
    return(
        await axiosInstancesupplier.post(`${BASE_URL_SUPPLIER}${API_URL}`,data,{ headers: { authorization: `Bearer ${SUPPLIERTOKEN3}` } })
    )
}



