import React from 'react'



const Footer =()=>{
    return(
        <footer className='footer-admin'>
            <div className='container-fluid'>
          <a href="#" className='text-light p-3 d-block'>Copyright - All Rights Reserved.</a>
          </div>
        </footer>
    )
}

export default Footer