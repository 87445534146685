/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { Accordion } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import Dashboard from "./menu/dashboard";
import Dropdown from "react-bootstrap/Dropdown";
import Init_Orders from "./menu/Init_Orders";
import CustomerManage from "./menu/customerManage";
import SupplierOrders from "./menu/SupplierOrders";
import AdminOrder from "./menu/AdminOrder";
import SupplierManagement from "./menu/supplierManage";
import Coupen_code from "./menu/Coupen_Code";
import QuoteManagement from "./menu/quoteManage";
import OrderManagement from "./menu/orderManage";
import MetalPriceManagement from "./menu/metalPriceManage";
import Settings from "./menu/settings";
import RoleMangement from "./menu/roleMangement";
import PaymentManagement from "./menu/paymentManager";
import SupplierTransaction from "./menu/supplieTransaction";
import BidManagement from "./menu/bidManagement";
import NeedAttentionOrder from "./menu/needAttentionOrder";
import CmsNav from "./menu/CmsNav";
import SubAdmin from "./menu/subAdmin";
import { useSelector } from "react-redux";

import { NavLink } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
const SideNav = () => {
  // const adminData = useSelector((state) => state.AdminDataObjReducer);
  // // console.log("adminData in sidena", adminData);
  const adminDetails = JSON.parse(localStorage.getItem("adminDetails"));
  // // console.log("adminData in sidena", adminDetails);
    return (
    <>
      <aside className="property side-nav pt-0">
        <div className="side-nav-menu">
          <div className="sec">
            <Accordion defaultActiveKey="0">
              {adminDetails?.my_permissions.map((item, index) => {
                if (item?.url !== null) {
                  return (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>
                        <ul>
                          <li className="deactive">
                            <NavLink to={"/admin/" + item.url}>
                              <span className="image-nav">
                                <MdDashboard />
                              </span>
                              <span className="img-active">
                                <MdDashboard />
                              </span>
                              {item.name}
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                              />
                            </NavLink>
                          </li>
                        </ul>
                      </Accordion.Header>
                    </Accordion.Item>
                  );
                } else {
                  return (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>
                        <ul key={index}>
                          <li className="deactive">
                            <Link>
                              <span className="image-nav">
                                <MdDashboard />
                              </span>
                              <span className="img-active">
                                <MdDashboard />
                              </span>
                              {item.name} <FaChevronDown />
                            </Link>
                          </li>
                        </ul>
                      </Accordion.Header>
                      {
                        item?.permissions.map((subitem,subindex)=>{
                          if(subitem?.show_in_sidebar===1 && subitem?.url !==null){
                            return(<Accordion.Body className="py-0 pe-0" key={subindex}>
                              <ul>
                                <li className="deactive">
                                  <NavLink to={subitem.url}>
                                    <span className="image-nav">
                                      <MdDashboard />
                                    </span>
                                    <span className="img-active">
                                      <MdDashboard />
                                    </span>
                                     {subitem?.name}
                                    <i
                                      className="fa fa-angle-right"
                                      aria-hidden="true"
                                    />
                                  </NavLink>
                                </li>
                              </ul>
                          </Accordion.Body>)
                          }
                        })
                      }
                    </Accordion.Item>
                  );
                }
              })}
              {/* <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <Dashboard />
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <OrderManagement />
                </Accordion.Header>
                <Accordion.Body className="py-0 pe-0">
                  <AdminOrder />
                </Accordion.Body>
                <Accordion.Body className="py-0 pe-0">
                  <SupplierOrders />
                </Accordion.Body>

                <Accordion.Body className="py-0 pe-0">
                  <Init_Orders />
                </Accordion.Body>
                <Accordion.Body className="py-0 pe-0">
                  <NeedAttentionOrder />
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
          </div>
        </div>
      </aside>
      <Outlet />
    </>
  );
};

export default SideNav;
