import React, { useCallback, useEffect, useState ,useLayoutEffect} from 'react'
import { Link } from 'react-router-dom'
import { getMethodData, postMethodData } from '../../adminApi/api';
import { useForm } from 'react-hook-form';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import {useNavigate} from "react-router-dom";

const AdminSettings = () => {
    let  showDataToUser =false; 
 
  const [editpermission,setEditPermission] = useState(false);
  const [redirection,setRedirection] = useState(false);
  useLayoutEffect(() => {
      const ls=JSON.parse(localStorage.getItem("adminDetails"));
    
    // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
     
      for(let i=0; i<=ls?.my_permissions?.length;i++){
          if(ls?.my_permissions[i]?.name==="Settings"){
              
              for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                  if(ls?.my_permissions[i]?.permissions[j]?.name==="View"){
                      // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      showDataToUser = true;
                      
                  }
                  if(ls?.my_permissions[i]?.permissions[j]?.name==="Edit "){
                    // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                    setEditPermission(true)
                }
                 
              }
          }
      }
      // console.log(showDataToUser);
      if(showDataToUser===false){
        // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
  }, []);     
  const navigate = useNavigate();
  if (redirection) navigate('/admin/cms');
    const { handleSubmit } = useForm();
    const Token = localStorage.getItem("token")
    
    const [AllSettings, setSettings] = useState(["not"])
    const [editVlue, setEditVlue] = useState(true)
   
    const editHandel=(e)=>{
        if(editpermission===true){
            setEditVlue(e)

        }
        else{
            alert("permission not allowed")
        }
        

    }
   
    const LoadSettings = useCallback(() => {
            getMethodData(`/settings`,Token)
                .then((res)=>{
                    setSettings(res.data.result.data)
                })
    },[Token])
     
    useEffect(()=>{
        LoadSettings()

    },[LoadSettings])

    const onSubmit = async (data) => {

        const fd = new FormData();
        const getUpdateInputs = document.getElementsByClassName('settingUpdateInput');
        for (let i = 0; i < getUpdateInputs.length; i++) {
            
            if ( getUpdateInputs[i].type === "file" ) 
            {
                if(getUpdateInputs[i].files[0])
                {
                    const getFile = getUpdateInputs[i].files[0]
                    fd.append(getUpdateInputs[i].id, getFile)
                }
            }
            else 
            {
                fd.append(getUpdateInputs[i].id, getUpdateInputs[i].value)
            }
        }
        
        postMethodData(`/settings/update`, fd, Token)
            .then((res) => {
                if(res?.data?.status)
                {
                   
                    setEditVlue(true);
                    LoadSettings();
                    toast.success("Settings are updated successfully.");
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                }
                else {
                
                    toast.error(res?.data?.message)
                }
            })
            .catch((err)=>{
                // console.log("Error is",err)
            })
    };
   
  return (<>
    
            <div>
            <div className="tables-field">
                <div className="project-table">
                  <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <div className="main-head">
                                    <h4>Settings</h4>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="theme-btn top-head text-end">
                                    <Link to="/" className="btn">Go back</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-order shadow">
                        <div className="tab-head">
                            <div className="row">
                                
                            {
                                editVlue ?  <React.Fragment>
                                <div className="row">
                                    {
                                        AllSettings.map((setting)=>(
                                            <div className="col-lg-6" key={setting.id}>
                                            <div className="form-group ">
                                                    <label>{setting.label}</label>
                                                    <input type="text" value={setting.value}  className="form-control" disabled/>
                                                </div>
                                            </div>
                                        ))
                                    }
        
                                       </div>
                                       <div className="col-lg-12">
                                            <div className="theme-btn btn-group-right text-right">
                                         
                                                <button type="button" onClick={()=> editHandel(false)} className="btn">Edit</button> 
                                            </div>
                                        </div>
                                </React.Fragment>:
                                 <form onSubmit={handleSubmit(onSubmit)}>
                                 <div className="row">
                                     {
                                         AllSettings.map((settings,i)=>(
                                             <div className="col-lg-6" key={settings.id}>
                                            {
                                                settings.input_type === 'file' 
                                                ? 
                                                <div className="form-group icon-position">
                                                    <label> {settings.label}</label>
                                                    <input  type="file"  id={settings.key} className="form-control settingUpdateInput"  />
                                                </div>
                                                 :
                                                 (settings.input_type === 'select'
                                                    ?
                                                <div className="form-group settingUpdateInput">
                                                    <label> {settings.label}</label>
                                                    <select className='form-control settingUpdateInput' defaultValue={settings.value} id={settings.key}>
                                                        <option className='d-none' value={settings.value} disabled selected>{settings.value}</option>
                                                        {settings?.input_options?.map((items,index)=> (
                                                            <option key={index} value={items}>{items}</option>
                                                        ))}
                                                        {/* <option value="Yes">Yes</option>
                                                        <option value="No">No</option> */}
                                                    </select>
                                                </div>
                                                  :
                                                  <div className="form-group icon-position">
                                                      <label> {settings.label}</label>
                                                      <input 
                                                      required 
                                                      type="text" 
                                                      disabled={false} 
                                                      defaultValue={settings.value} 
                                                      id={settings.key} 
                                                      className="form-control settingUpdateInput"  />
                                                  </div> )
                                            }
                                          
                                         </div>
                                         ))
                                     }
         
                                        </div>
                                        <div className="col-lg-12">
                                             <div className="theme-btn btn-group-right text-right">
                                                 <button type="submit" className="btn">Update</button>
                                                 <button type="button"  onClick={()=> editHandel(true)} className="btn">cancel</button>
    
                                             </div>
                                         </div>
                                 </form>
                            }                                   
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
          </div>
     
      </>
  )
}

export default AdminSettings