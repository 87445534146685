import React, { useState, useEffect, useCallback } from 'react'
import { FaSearch, FaRegCalendarAlt, FaEye } from 'react-icons/fa'
import { ImageObj } from '../../../../../../assets/images/imgeObject';
import { Link } from 'react-router-dom';
import { getMethodDataSupplier, getMethodDataUser } from '../../../../../admin/adminApi/api';
import { GiAutoRepair } from 'react-icons/gi';
import DatePicker from "react-datepicker";
import { useForm, Controller } from 'react-hook-form';

import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment'
import Pagination from 'react-js-pagination';
const SuppOrdersList = () => {
  const [tableListData, setTableListData] = useState([])
  const [Count, setCount] = useState(1);
  const [perPage, setPerPage] = useState();
  const [loading, setLoading] = useState(false)
  const [filterVisibility, setFilterVisibility] = useState(true);
  const [indexPage, setIndexPage] = useState(1);
  const [activePage, setActivePage] = useState(1)
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterData, setFilterData] = useState("");
  const USERTOKEN = localStorage.getItem("userToken")
  const SUPPLIERTOKEN = localStorage.getItem("supplierToken")

  const { register, control, handleSubmit, reset } = useForm();



  const LoadTableData = useCallback(async (pageNumber) => {
    setIndexPage(1)


    setLoading(false);
    getMethodDataSupplier("/get-my-assigned-orders",USERTOKEN || SUPPLIERTOKEN)
      .then((res) => {
      
        setTableListData(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);

        setLoading(true);
        setFilterVisibility(true)
      })



  }, [])

  const resetTable =()=>{
    reset()
    LoadTableData()
  }

  const sendPage = (pageNumber) => {

    getMethodDataSupplier(`/get-my-assigned-orders?page=${String(pageNumber < 1 ? 1 : pageNumber)}&${filterData}`,USERTOKEN || SUPPLIERTOKEN)
      .then((res) => {
      
        setTableListData(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);

        setLoading(true);


      })
  }

  const handlePageChange = (pageNumber) => {


    if (filterVisibility === true) {
      LoadTableData(pageNumber)
      setActivePage(pageNumber);
      setIndexPage(pageNumber)
      setLoading(false);
    } else {
      sendPage(pageNumber)
      setActivePage(pageNumber);
      setIndexPage(pageNumber)
      setLoading(false);


    }

  }

  const onSubmit = useCallback( (data) => {
    data.from_date = (!data.from_date || data.from_date === null || data.from_date === undefined) ? "" : moment(data.from_date).format("YYYY-MM-DD")
    data.to_date = (!data.to_date || data.to_date === null || data.to_date === undefined) ? "" : moment(data.to_date).format("YYYY-MM-DD")

  
    setIndexPage(1)
    setFilterData(data)

    setLoading(false);
    
    getMethodDataSupplier(`/get-my-assigned-orders?page=1&${new URLSearchParams(data).toString()}`,USERTOKEN || SUPPLIERTOKEN)
      .then((res) => {
        setTableListData(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setLoading(true);
        setFilterVisibility(false)

      })



  },[register]);

  useEffect(() => {

    // onSubmit()
  }, [searchValue, startDate])


  useEffect(() => {
    LoadTableData()
  }, [])
  return (
    <React.Fragment>
      <section className="pt-5 mt-3">
        <div className="container dashboard-heading-block py-3">
          <div className="row align-items-center">
            <div className="col-lg-12 dashboard-headline text-start max-width-500">
              <h2>Orders</h2>
   
              <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-3">
                  <div className="rounded rounded-pill mb-3 border border-dark search-style1 ">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <button id="button-addon2" className="btn btn-link">
                          <i><FaSearch /></i>
                        </button>
                      </div>
                      <input type="search" {...register('order_id', { required: false })} name="order_id" placeholder="Order ID" aria-describedby="button-addon2" className="form-control border-0 ps-2" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="rounded rounded-pill mb-3 border border-dark search-style1 form-search-new">
                    <div className="input-group">
                      <div className="input-group-prepend">
                      
                          <i><FaRegCalendarAlt /></i>
                       
                      </div>
                      <div className="form-group before-con">

                        <Controller
                          control={control}
                          name="from_date"

                          render={({ field }) => (
                            <DatePicker
                              dateFormat="MM/dd/yyyy"
                              onChange={(date) => field.onChange(date)}
                              selected={field.value}
                              placeholderText="From Date"
                              maxDate={new Date()}

                            />
                          )}
                        />

                      </div>                              </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="rounded rounded-pill mb-3 border border-dark search-style1 form-search-new">
                    <div className="input-group">
                      <div className="input-group-prepend">
                      
                          <i><FaRegCalendarAlt /></i>

                      
                      </div>
                      <div className="form-group before-con after">


                        <Controller
                          control={control}
                          name="to_date"

                          render={({ field }) => (

                            <DatePicker
                              dateFormat="MM/dd/yyyy"
                              placeholderText="To Date"

                           
                              onChange={(date) => field.onChange(date)}
                              selected={field.value}
                              maxDate={new Date()}





                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <button className='btn btn-primary-orange m-0 min-hp-40' type='submit'>Submit</button>
                  <button type='button' onClick={() => resetTable()} className="btn btn-primary-outline ms-2  min-hp-40">reset</button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5 pt-3">
        <div className="container gx-0 grid-data position-relative">
          <div className="row gx-0 grid-head-row">
            <div className="col"><span className="data-value">Sr. No.</span></div>
            {/* <div className="col"><span className="data-value">Image</span></div> */}
            <div className="col"><span className="data-value">Order ID</span></div>
            <div className="col"><span className="data-value">Name</span></div>
            <div className="col"><span className="data-value">Date</span></div>
            <div className="col"><span className="data-value">Final Amount</span></div>
            <div className="col"><span className="data-value">Status</span></div>
            <div className="col"><span className="data-value">Action</span></div>
          </div>
          {
            tableListData !== null && tableListData !== undefined ? tableListData.filter((val) => {

              if (searchTerm === "") {
                return val
              } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                return val
              } else {
                return false
              }


            }).map((res, i) => {
              return (
                <div className="row gx-0 grid-data-row">
                  <div className="col" data-label="Sr. No.: ">
                    <span className="data-value">{i + 1}</span>
                  </div>
                  {/* <div  className="col" data-label="Image: ">
                  <img className='h-100 w-100' src={ImageObj.ProductBig} />
                                                     
                                                    </div> */}
                  <div className="col" data-label="ID: ">
                    <span className="data-value">#{res?.bid_id}</span>
                  </div>
                  <div className="col" data-label="Name: ">
                    <span className="data-value">{res?.order?.customer?.first_name} {res?.order?.customer?.last_name}</span>
                  </div>
                  <div className="col" data-label="Date: ">
                    <span className="data-value">{moment(res?.order_date).format("MM/DD/yyyy")}</span>
                  </div>
                  <div className="col" data-label="Amount: ">
                    <span className="data-value">${res.supplier_bid_response?.proposal_amount}</span>
                  </div>
                  <div className="col" data-label="Status: ">
                    <span className="data-value">{res.bid_order_status ?? '-'}</span>
                  </div>
                  <div className="col action" data-label="Action">
                    
                      <Link title='view details' to={`${res.id}`}><FaEye className='fs-5 fw-normal ms-2 pointer' /></Link>
                  </div>
                </div>
              )
            }) 
            : 
            <div className="no-data py-4 fs-1 fw-bolder text-center text-uppercase opacity-25 text-dark">No Orders Found</div>
          }
          {
            !loading ?
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiAutoRepair />
                  </div>
                </div>
              </div> : ""
          }

        </div>
        <div className='container pe-0 ps-0'>
          <div className='pagination'>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={perPage}
              totalItemsCount={Number(Count)}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default SuppOrdersList;