export const  PENDING_VALUE = "PENDING_VALUE";
export const  MODAL_VALUE = "MODAL_VALUE";
export const  MODAL_REGISTER_VALUE = "MODAL_REGISTER_VALUE";
export const  MODAL_RESET_VALUE = "MODAL_RESET_VALUE";
export const  MODAL_RESET_MSG_VALUE = "MODAL_RESET_MSG_VALUE";
export const  MODAL_ADDRESS_VALUE = "MODAL_ADDRESS_VALUE";
export const  MODAL_REGISTER_SUPP_VALUE = "MODAL_REGISTER_SUPP_VALUE";
export const  MODAL_CONDITION_VALUE = "MODAL_CONDITION_VALUE";
export const  MODAL_POLICY_VALUE = "MODAL_POLICY_VALUE";
export const  MODAL_BECOME_VALUE = "MODAL_BECOME_VALUE";
export const  MODAL_NO_FILES_VALUE = "MODAL_NO_FILES_VALUE";
export const  MODAL_NAVIGATE_VALUE = "MODAL_NAVIGATE_VALUE";
export const  DROPDOWN_SELECT_VALUE = "DROPDOWN_SELECT_VALUE";
export const  DESIGN_PATH_FILE = "DESIGN_PATH_FILE";
export const  DESIGN_RES_PATH_FILE = "DESIGN_RES_PATH_FILE";
export const  AUTH_HOC = "AUTH_HOC";
export const  CADD_REGISTER = "CADD_REGISTER";
export const  ID_UPLOAD = "ID_UPLOAD";
export const  USER_DATA = "USER_DATA";
export const  ADMIN_DATA = "ADMIN_DATA";
export const  CONTACTUS_FORM = "CONTACTUS_FORM";
export const  CUSTOMERLOADER = "CUSTOMERLOADER";
export const  ADMINMANUALQUOTE = "ADMINMANUALQUOTE";
