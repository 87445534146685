import React, { useState, useEffect, useCallback } from "react";
import { GiAutoRepair } from "react-icons/gi";
import { ImageObj } from "../../../../../../assets/images/imgeObject";
import { Link, useLocation } from "react-router-dom";
import { getMethodDataSupplier } from "../../../../../admin/adminApi/api";
import moment from "moment";
import { bounce } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { FaCube, FaEye, FaGavel, FaRegFilePdf } from "react-icons/fa";
import fileDownload from 'react-file-download';

const SupplierDashboard = () => {
  const [SupplierData, setSupplierData] = useState();
  const [resentQuotes, setResentQuotes] = useState([]);
  const [resentParts, setResentParts] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [NotificationDatadData, setNotificationData] = useState([]);
  const [userdData, setUserdData] = useState([]);
  const SUPPLIERTOKEN = localStorage.getItem("supplierToken");
  const USERTOKEN = localStorage.getItem("userToken");

  const [loading, setLoading] = useState(false);

  const getDashboardData = useCallback(() => {
    getMethodDataSupplier(
      "/get-dashboard-data",
      SUPPLIERTOKEN || USERTOKEN
    ).then((res) => {
      setLoading(true);
      setSupplierData(res.data.result.data);
      setResentQuotes(res.data.result.data.resent_quotes);
      setResentParts(res.data.result.data.resent_parts);
      setDashboardData(res.data.result.data);
      setNotificationData(res.data.result.data.resent_notifications);
      setUserdData(res.data.result.data.user);
    });
  }, []);

  const styles = {
    bounce: {
      animation: "x 1s",
      animationName: Radium.keyframes(bounce, "bounce"),
    },
  };

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);
  const location = useLocation();
  const adminPath = location.pathname.split("/")[1];

  const handleDownload = (data,fileurl) => {
    const fileUrl = data;
    const fileName = fileurl;
    fileDownload(fileUrl, fileName);
  };


  return (
    <React.Fragment>
      <StyleRoot>
        <section className="three-boxes pt-5 mt-3 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-8 mx-auto">
                
        {String(adminPath) === "supplier" && (
          <div className="mt-4 w-100">
            <h3 className="text-center">Supplier Panel</h3>
          </div>
        )}
              </div>
            </div>
            <div className="row">
              <section className="py-5">
                <div className="container dashboard-heading-block mb-3 pb-3">
                  <div className="row align-items-center">
                    <div className="col-lg-12 d-flex justify-content-center justify-content-lg-start">
                      <div>
                        <div className="dashboard-headline">
                          <h2>
                            Welcome, {userdData?.first_name} {userdData?.last_name}
                            <br/><span className="fs-5 d-inline-flex">
                              (member since {moment(userdData?.created_at).format("MMM YYYY")}
                              )
                            </span>
                          </h2>
                        
                        </div>

                        <h2 className="text-center text-lg-start">
                          Recent Update
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-12 container gx-10 grid-data">
                  <div
                    className="row gx-10 grid-head-row"
                    style={{ paddingLeft: "15px" }}
                  >
                    <div className="col-1">
                      <span className="data-value">
                        <strong>Sr. No.</strong>
                      </span>
                    </div>
                    <div className="col-10">
                      <span className="data-value">
                        <strong>Message</strong>
                      </span>
                    </div>

                    <div className="col-1">
                      <span className="data-value">
                        <strong>Action</strong>
                      </span>
                    </div>
                  </div>
                  
                  {NotificationDatadData.length > 0 ? NotificationDatadData.map((data, i) => {
                    return (
                        <div
                          className="row gx-10 grid-data-row"
                          style={{ padding: "10px" }}
                          key={i}
                        >
                          <div className="col-1" data-label="Sr. No.: ">
                            <span className="data-value">{i + 1}</span>
                          </div>
                          <div
                            className="col-10 row align-items-center justify-content-between"
                            data-label="ID: "
                          >
                            <span className="data-value w-auto">
                              {data.title}
                            </span>
                            {/* <br /> */}
                            <span
                              className="data-value w-auto"
                              style={{ fontSize: "10px", color: "gray" }}
                            >
                              {data.notification_age}
                            </span>
                          </div>

                          <div
                            className="col-1 ms-auto d-flex align-items-center justify-content-center action"
                            data-label="Action"
                          >
                            {data.type === "bid" ? (
                              <Link
                                className="text-dark"
                                to={`/supplier/bids/bidapply${
                                  JSON.parse(data?.parameters)?.id
                                }`}
                                alt="Bid Details"
                                title="Bid Details"
                              >
                                
                                <FaEye className="fs-5 fw-normal ms-2 pointer" />
                              </Link>
                            ) : data.type === "order" ? (
                              <>
                                <Link
                                  className="text-dark"
                                  to={`/supplier/orders/${
                                    parseInt(data?.parameters.replace(/\[|\]/g, ''))
                                  }`}
                                  alt="Bid Details"
                                  title="Bid Details"
                                >
                                  <FaEye className="fs-5 fw-normal ms-2 pointer" />
                                </Link>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                    );
                  })
                  :
                  <div className="no-data py-4 fs-1 fw-bolder text-center text-uppercase opacity-25 text-dark">
                    No Updates Found
                  </div>
                  }

                  {NotificationDatadData.length > 2 ? (
                    <>
                      <br />
                      <Link
                        style={{ float: "right" }}
                        className="btn btn-primary-orange m-0 min-hp-40 dashboard-headline"
                        to={"/supplier/notiticationlist"}
                      >
                        Show All
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </div>
          </div>
          <div className="container">
            <div className="row ">
              <Link
                to="/supplier/orders"
                className="col-lg-4 pointer text-decoration-none"
              >
                <div className="supplier-box-1 mt-3">
                  <div className="row">
                    <div className="col-lg-12">
                      <img
                        src={ImageObj.RecentOrders}
                        alt="total orders"
                        height="48px"
                        width="48px"
                      />
                    </div>
                  </div>
                  <div className="row  flex-row-reverse">
                    <div className="col-md-4">
                      <h2 style={styles.bounce} className="font-size-40">
                        {dashboardData.total_orders}
                      </h2>
                    </div>
                    <div className="col-md-8">
                      <h5 className="set-margin-top">My Order</h5>
                     
                    </div>
                  </div>
                </div>
              </Link>
              <div className="col-lg-4">
                <Link
                  to="/supplier/bids"
                  className="col-lg-4 pointer text-decoration-none"
                >
                  <div className="supplier-box-2 mt-3">
                    <div className="row">
                      <div className="col-lg-12">
                        <img src={ImageObj.AuctionIcon} alt="total quotes" />
                      </div>
                    </div>
                    <div className="row flex-row-reverse">
                      <div className="col-md-4">
                        <h2 style={styles.bounce} className="font-size-40">
                          {dashboardData.total_bids}
                        </h2>
                      </div>
                      <div className="col-md-8">
                        <h5 className="set-margin-top">My Bids</h5>
                       
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4">
                <Link
                  to="/supplier/earning"
                  className="col-lg-4 pointer text-decoration-none"
                >
                  <div className="supplier-box-3 mt-3">
                    <div className="row">
                      <div className="col-lg-12">
                        <img src={ImageObj.EarningIcon} alt="total parts" />
                      </div>
                    </div>
                    <div className="row flex-row-reverse">
                      <div className="col-md-4">
                        <h2 style={styles.bounce} className="font-size-40">
                          ${dashboardData.my_earnings}
                        </h2>
                      </div>
                      <div className="col-md-8">
                        <h5 className="set-margin-top">My Earnings</h5>
                      
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="container dashboard-heading-block mb-3 pb-3">
            <div className="row align-items-center">
              <div className="col-lg-12 d-flex justify-content-center justify-content-lg-start">
                <img
                  src={ImageObj.RecentBids}
                  width="56px"
                  height="56px"
                  className="mt-2 me-4 d-none d-lg-block"
                  alt="..."
                />
                <div>
                  <h2 className="text-center text-lg-start">Recent Bids</h2>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="container gx-0 grid-data">
            <div className="row gx-0 grid-head-row">
              <div className="col">
                <span className="data-value">Sr. No.</span>
              </div>
              {/* <div className="col">
                <span className="data-value">Image</span>
              </div> */}
              {/* <div className="col-8" style={{ padding: " 0px 13px" }}> */}
                {/* <div className="row" style={{ flexWrap: "nowrap" }}> */}
                  <div className="col text-center">
                    <span className="data-value">Bid ID</span>
                  </div>
                  <div className="col">
                    <span className="data-value">Name</span>
                  </div>
                  <div className="col">
                    <span className="data-value">Qty</span>
                  </div>

                  <div className="col">
                    <span className="data-value">Bid Offer</span>
                  </div>
                  <div className="col">
                    <span className="data-value">My Offer</span>
                  </div>
                  <div className="col">
                    <span className="data-value">Start On</span>
                  </div>
                  <div className="col">
                    <span className="data-value">Expiring On</span>
                  </div>
                  <div className="col text-center">
                    <span className="data-value">Status</span>
                  </div>
                {/* </div> */}
              {/* </div> */}
              <div className="col">
                <span className="data-value">Action</span>
              </div>
            </div>
            {SupplierData?.resent_bids?.length &&
            SupplierData?.resent_bids !== null &&
            SupplierData?.resent_bids !== undefined ? (
              SupplierData?.resent_bids.map((res, i) => {
                return (
                  <div className="row gx-0 grid-data-row" key={i}>
                    <div className="col" data-label="Sr. No.: ">
                      <span className="data-value">{i + 1}</span>
                    </div>
                    {/* <div className="col" data-label="Image: ">
                      <img className="w-100" src={ImageObj.ProductBig} />
                    </div> */}
                    {/* <div className="col-8"> */}
                      {/* <div className="row" style={{ padding: "0 14px" }}> */}
                        <div className="col text-center" data-label="ID: ">
                          <span className="data-value">#{res.bid_id}</span>
                        </div>
                        <div
                          className="col text-center"
                          data-label="customer name: "
                        >
                          <span className="data-value">
                            {res.order.customer?.first_name}{" "}
                            {res.order.customer?.last_name}
                          </span>
                        </div>
                        <div className="col" data-label="Name: ">
                          <span className="data-value">{res.bid_part.qty}</span>
                        </div>
                        <div className="col" data-label="Amount: ">
                          <span className="data-value">
                            ${res.offer_amount}
                          </span>
                        </div>
                        <div className="col" data-label="Amount: ">
                          <span className="data-value">
                            {res.supplier_bid_response?.proposal_amount != 0
                            ?
                            <>
                              ${res.supplier_bid_response?.proposal_amount}
                            </>
                            :
                            "N/A"
                            }
                           
                          </span>
                        </div>
                        <div className="col" data-label="Date: ">
                          <span className="data-value">
                            {moment(res.open_date_time).format("MM/DD/yyyy")}
                            <br />
                            {moment(res.open_date_time).format("hh:mm a")}
                          </span>
                        </div>
                        <div className="col" data-label="Status: ">
                          <span className="data-value">
                            {moment(res.expiry_date_time).format("MM/DD/yyyy")}
                            <br />
                            {moment(res.expiry_date_time).format("hh:mm a")}
                          </span>
                        </div>
                        <div className="col" data-label="Status: ">
                          <span className="data-value">{res.supplier_bid_response?.status}</span>
                        </div>
                      {/* </div> */}
                      {/*  */}
                      {/* <div
                        className="col-12 flex px-3 py-4 grey-box"
                        data-label="Details:"
                      >
                        <div className="col-12">
                          <h6>Details</h6>
                        </div>
                        <span>
                          {res.bid_part?.manufacturing_process && (
                            <span className="data-value">
                              <strong className="me-1">
                                Manufacturing Process
                              </strong>
                              :
                              <span className="mb-0 ms-1 me-3 new">
                                {res.bid_part?.manufacturing_process}
                              </span>
                            </span>
                          )}

                          {res.bid_part?.material && (
                            <span className="data-value">
                              <strong className="me-1">Material </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                {res.bid_part?.material}
                              </span>
                            </span>
                          )}
                          {res.bid_part?.finish && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">Coating </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                {res.finish}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.custom_finish && (
                            <li className="d-flex align-items-center">
                              <strong className="mb-0">Custom Coating </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                {res.custom_finish}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.threads && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">Threads </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                {res.threads}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.tolerance && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">Tolerance </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                {res.bid_part?.tolerance}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.surface_roughness && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">
                                Surface Roughness
                              </strong>
                              :
                              <span className="mb-0 ms-1 me-3 new">
                                {res.bid_part?.surface_roughness}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.part_marking && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">Part Marking </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                {res.bid_part?.part_marking}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.inspection && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">Inspection </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                {res.bid_part?.inspection}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.custom_inspection && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">Inspection </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                {res.bid_part?.custom_inspection}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.part_qualifications_certifications && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">
                                Part Quality Certifications
                              </strong>
                              :
                              <span className="mb-0 ms-1 me-3 new">
                                {
                                  res.bid_part?.part_qualifications_certifications
                                }
                              </span>
                            </li>
                          )}
                          {res.bid_part?.material_certifications && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">
                                Material Certifications
                              </strong>
                              :
                              <span className="mb-0 ms-1 me-3 new">
                                {res.bid_part?.material_certifications}
                              </span>
                            </li>
                          )}
                        </span>
                      </div> */}
                      {/*  */}
                    {/* </div> */}
                    <div
                      className="col d-flex align-items-center justify-content-center action"
                      data-label="Action"
                    >

                      <a
                        // target={'_blank'}
                        // onClick={()=> handleDownload(res?.bid_part?.auto_cad_file_path,res?.bid_part?.file_name)}
                        // rel="noreferrer"
                        download
                        title="Download 3d File"
                        href={res?.bid_part?.auto_cad_file_path}
                        >
                        <FaCube className="text-primary fs-5 fw-normal ms-2 pointer" title="Download 3D File" />
                      </a>

                      {res.status === "Open" ? (
                        <Link
                          className="text-dark"
                          to={`../bids/bidapply${res.id}`}
                          alt="Place a Bid"
                          title="Place a Bid"
                        >
                          <FaGavel className="fs-5 fw-normal ms-2 pointer" />
                        </Link>
                        // <></>
                      ) : (
                        ""
                      )}
                      {res.status !== "Open" 
                      &&
                      <Link
                        className="text-dark"
                        to={`../bids/bidapply${res.id}`}
                        alt="Bid Details"
                        title="Bid Details"
                      >
                        <FaEye className="fs-5 fw-normal ms-2 pointer" />
                      </Link>
                      }
                      
                      {/*  */}
                      <a
                        href={res.bid_pdf_path}
                        title={
                          res.bid_pdf_path !== null
                            ? "Download Quote"
                            : ""
                        }
                        target="_blank"
                      >
                        {res.bid_pdf_path !== null && (
                          <FaRegFilePdf className="fs-5 fw-normal ms-2 pointer" />
                        )}
                      </a>
                      {/*  */}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="no-data py-4 fs-1 fw-bolder text-center text-uppercase opacity-25 text-dark">
                No Bids Found
              </div>
            )}
            {SupplierData?.resent_bids !== null &&
            SupplierData?.resent_bids !== undefined &&
            SupplierData?.resent_bids?.length === 5 ? (
              <div className="text-center my-4 mt-0">
                <Link to="/supplier/bids">
                  <button type="submit" className="btn btn-primary-orange m-0">
                    View More
                  </button>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
        <section className="py-5">
          <div className="container dashboard-heading-block mb-3 pb-3">
            <div className="row align-items-center">
              <div className="col-lg-12 d-flex justify-content-center justify-content-lg-start">
                <img
                  src={ImageObj.RecentOrders}
                  width="56px"
                  height="56px"
                  className="mt-2 me-4 d-none d-lg-block"
                />
                <div>
                  <h2 className="text-center text-lg-start">Recent Orders</h2>
                
                </div>
              </div>
            </div>
          </div>
          <div className="container gx-0 grid-data position-relative">
            <div className="row gx-0 grid-head-row">
              <div className="col">
                <span className="data-value">Sr. No.</span>
              </div>
              {/* <div className="col">
              <span className="data-value">Image</span>
            </div> */}
              <div className="col">
                <span className="data-value">Order ID</span>
              </div>
              <div className="col">
                <span className="data-value">Name</span>
              </div>
              <div className="col">
                <span className="data-value">Date</span>
              </div>
              <div className="col">
                <span className="data-value">Final Amount</span>
              </div>
              <div className="col">
                <span className="data-value">Status</span>
              </div>
              <div className="col">
                <span className="data-value">Action</span>
              </div>
            </div>
            {SupplierData?.resent_orders.length &&
            SupplierData?.resent_orders !== null &&
            SupplierData?.resent_orders !== undefined ? (
              SupplierData?.resent_orders.map((res, i) => {
                return (
                  <div key={i} className="row gx-0 grid-data-row">
                    <div className="col" data-label="Sr. No.: ">
                      <span className="data-value">{i + 1}</span>
                    </div>
                    {/* <div className="col" data-label="Image: ">
                      <img className="w-100" src={ImageObj.ProductBig} alt="..." />
                    </div> */}
                    <div className="col" data-label="ID: ">
                      <span className="data-value">#{res?.bid_id}</span>
                    </div>
                    <div className="col" data-label="Name: ">
                      <span className="data-value">
                        {res?.order?.customer?.first_name}{" "}
                        {res?.order?.customer?.last_name}
                      </span>
                    </div>
                    <div className="col" data-label="Date: ">
                      <span className="data-value">
                        {moment(res?.order?.date).format("MM/DD/yyyy")}
                      </span>
                    </div>
                    <div className="col" data-label="Amount: ">
                      <span className="data-value">
                        ${res.supplier_bid_response?.proposal_amount}
                      </span>
                    </div>
                    <div className="col" data-label="Status: ">
                      <span className="data-value">{res.bid_order_status}</span>
                    </div>
                    <div className="col action" data-label="Action">
                      <Link title="view details" to={`../orders/${res.id}`}>
                        <FaEye className="fs-5 fw-normal ms-2 pointer" />
                      </Link>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="no-data py-4 fs-1 fw-bolder text-center text-uppercase opacity-25 text-dark">
                No Orders Found
              </div>
            )}
            {SupplierData?.resent_bids !== null &&
            SupplierData?.resent_bids !== undefined &&
            SupplierData?.resent_bids?.length === 5 ? (
              <div className="text-center my-4 mt-0">
                <Link to="/supplier/orders">
                  <button type="submit" className="btn btn-primary-orange m-0">
                    View More
                  </button>
                </Link>
              </div>
            ) : (
              ""
            )}
            {!loading ? (
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiAutoRepair />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
        <section className="py-5 mb-5">
          <div className="container dashboard-heading-block mb-3 pb-3">
            <div className="row align-items-center">
              <div className="col-lg-12 d-flex justify-content-center justify-content-lg-start">
                <img
                  src={ImageObj.RecentPayments}
                  width="56px"
                  height="56px"
                  className="mt-2 me-4 d-none d-lg-block"
                />
                <div>
                  <h2 className="text-center text-lg-start">Recent Earnings</h2>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="container gx-0 grid-data position-relative">
            <div className="row gx-0 grid-head-row">
              <div className="col">
                <span className="data-value">Sr. No.</span>
              </div>
              {/* <div className="col">
              <span className="data-value">Image</span>
            </div> */}
              <div className="col">
                <span className="data-value">Bid ID</span>
              </div>
              <div className="col">
                <span className="data-value">Order Amount</span>
              </div>
              <div className="col">
                <span className="data-value">Order Date</span>
              </div>
              <div className="col">
                <span className="data-value">Delivered Date</span>
              </div> 
              <div className="col">
                <span className="data-value">Due Date</span>
              </div>
              
              <div className="col">
                <span className="data-value">Status</span>
              </div>
            </div>
            {SupplierData?.resent_payments.length &&
            SupplierData?.resent_payments !== null &&
            SupplierData?.resent_payments !== undefined ? (
              SupplierData?.resent_payments.map((res, i) => {
                return (
                  <div key={i} className="row gx-0 grid-data-row">
                    <div className="col" data-label="Sr. No.: ">
                      <span className="data-value">{i + 1}</span>
                    </div>
                    {/* <div className="col" data-label="Image: ">
                      <img className="w-100" src={ImageObj.ProductBig} alt="..." />
                    </div> */}
                    <div className="col" data-label="ID: ">
                      <Link
                        className="text-dark"
                        to={`../bids/bidapply${res?.bid_id}`}
                      >
                        <span className="data-value">
                          #{res?.order_bid?.bid_id}
                        </span>
                      </Link>
                    </div>
                    <div className="col" data-label="ID: ">
                      <span className="data-value">${res.supplier_price}</span>
                    </div>
                    <div className="col" data-label="Date: ">
                      <span className="data-value">
                        {moment(res.order?.date).format("MM/DD/yyyy")}
                      </span>
                    </div>
                    
                    <div className="col" data-label="Date: ">
                      <span className="data-value">
                        {res.order_bid?.order_delivered_date !== null
                          ? moment(res.order_bid?.order_delivered_date).format(
                              "MM/DD/yyyy"
                            )
                          : "-"}
                      </span>
                    </div>
                    <div className="col" data-label="Date: ">
                      <span className="data-value">
                        {res.payment_due_date !== null
                          ? moment(res.payment_due_date).format("MM/DD/yyyy")
                          : "-"}
                      </span>
                    </div>
                    <div className="col" data-label="Status: ">
                      <span className="data-value">
                        {res?.supplier_paid === 0 ? "Unpaid" : "Paid"}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="no-data py-4 fs-1 fw-bolder text-center text-uppercase opacity-25 text-dark">
                No Earnings Found
              </div>
            )}
            {!loading ? (
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiAutoRepair />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
      </StyleRoot>
    </React.Fragment>
  );
};

export default SupplierDashboard;
