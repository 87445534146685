import React, { useEffect, useState, useLayoutEffect } from "react";
import { MdSave, MdDone, MdRefresh } from "react-icons/md";
import { AiOutlinePercentage } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import {
  getMethodData,
  getSubmitData,
  postMethodData,
} from "../../adminApi/api";
import { toast, ToastContainer } from "react-toastify";
const MetalPriceManage = () => {
    let  showDataToUser =false; 
    const [rprice, setRprice] = useState(false);
    const [editpeermission, setEditpeermission] = useState(false);
    const [redirection,setRedirection] = useState(false);
    useLayoutEffect(() => {
        const ls=JSON.parse(localStorage.getItem("adminDetails"));
     // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
       
        for(let i=0; i<=ls?.my_permissions?.length;i++){
            if(ls?.my_permissions[i]?.name==="Metal Price Management"){
                
                for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                    if(ls?.my_permissions[i]?.permissions[j]?.name==='List'){
                        // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                        showDataToUser = true;
                      
                    }
                    if(ls?.my_permissions[i]?.permissions[j]?.name==="Refresh Price"){
                      // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      setRprice(true)
                    
                  }
                  if(ls?.my_permissions[i]?.permissions[j]?.name==="Edit  Price"){
                    // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                    setEditpeermission(true)
                  
                }
                }
            }
        }
        // console.log(showDataToUser);
      if(showDataToUser===false){
        // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
    }, []);
    const navigate = useNavigate();
    if (redirection) navigate('/admin/dashboard');
  const [tableData, setTableData] = useState([]);
  const [checkArray, setCheckArray] = useState([]);
  const [updatedArray, setUpdatedArray] = useState([]);
  const Token = localStorage.getItem("token");

  useEffect(() => {
    getSubmitData(`/metals`, Token).then((res) => {
      setTableData(res.data.result.data.data);
    });
  }, []);

  const refreshHandler = () => {
    if(rprice===true){
      document.getElementById("cronRefresh").className = "cronRefresh";
      getMethodData(`/reset-current-metal-market-price`, Token)
        .then((res) => {
          if (res?.data?.status) {
            toast(res?.data?.message);
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log("Error while Cron api hit", err);
        });
    }else{
      alert("permission not allowed")
    }
   
  };

  const updateGradeHandler = (grade_id, metal_id) => {
    if(editpeermission===true){
      const temp = tableData;
      const whichMetal = temp?.find((st) => st.id === metal_id);
      const whichGrade = whichMetal.metal_grades.find((st) => st.id === grade_id);
      postMethodData(
        `/metal-grades/update/${grade_id}`,
        {
          commission: whichGrade.commission,
          final_price: whichGrade.final_price,
        },
        Token
      )
        .then((res) => {
          if (res?.data?.status) {
            toast(res?.data?.message);
            setUpdatedArray([...updatedArray, grade_id]);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) =>  
          {}
        );
    }else{
      alert("permission not allowed")
    }
   
  };

  const gradeCommissionChanger = (e, grade_id, metal_id) => {
    const temp = tableData;
    const whichMetal = temp?.find((st) => st.id === metal_id);
    const metalMarketPrice = whichMetal.market_price;
    const whichGrade = whichMetal.metal_grades.find((st) => st.id === grade_id);
    const commission = e.target.value;
    whichGrade.final_price =
      Number(metalMarketPrice) +
      (Number(commission) * Number(metalMarketPrice)) / 100;
    if (whichGrade.commission !== e.target.value) {
      setCheckArray([...new Set([...checkArray, whichGrade.id])]);
    }
    whichGrade.commission = commission;
    setTableData([...temp]);
  };

  return (
    <>
      <div>
        <div className="tables-field">
          <div className="project-table">
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <div className="main-head">
                    <h4>Metal Price List</h4>
                  </div>
                </div>
                <div className="col-6">
                  <div className="theme-btn top-head text-end">
                    <Link to="/" className="btn">
                      Go back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-order shadow">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "15%" }}>Metal Name</th>
                    <th style={{ width: "65%" }}>Market Price</th>
                    <th style={{ width: "20%" }}>
                      <button
                        id="cronRefresh"
                        style={{ border: "none", background: "transparent" }}
                        onClick={() => refreshHandler()}
                      >
                        <MdRefresh color="#00F700" fontSize={"x-large"} />
                      </button>
                      <span>Refresh Price</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((item) => (
                    <>
                      <tr key={item?.id}>
                        <td>
                          <strong>{item?.name}</strong>
                        </td>
                        <td>
                          <strong>
                            $ {Number(item?.market_price).toFixed(4)}
                          </strong>
                        </td>
                      </tr>
                      <tr className="nested-table-tr">
                        <td></td>
                        <td>
                          <table>
                            <tr>
                              <th style={{ width: "10%" }}>Grade</th>
                              <th style={{ width: "15%" }}>Volume</th>
                              <th style={{ width: "15%" }}>Density</th>
                              <th style={{ width: "20%" }}>Multiplier</th>
                              <th style={{ width: "20%" }}>Price </th>
                              <th style={{ width: "10%" }}>Actions </th>
                            </tr>

                            {item.metal_grades.map((st) => (
                              <tr key={st?.id} className="table-order shadow">
                                <td>{st?.grade}</td>
                                <td>{st?.dimension || 0}</td>
                                <td>{st?.density}</td>
                                <td>
                                  <div style={{ display: "flex" }}>
                                    <div>
                                      <input
                                        id="dollar-input"
                                        type={"number"}
                                        style={{ width: "60%" }}
                                        defaultValue={st?.commission}
                                        min={0}
                                        onChange={(event) =>
                                          gradeCommissionChanger(
                                            event,
                                            st?.id,
                                            st?.metal_id
                                          )
                                        }
                                      ></input>
                                      <span>
                                        {" "}
                                        <AiOutlinePercentage />
                                      </span>
                                    </div>
                                    <span
                                      style={{
                                        display: updatedArray.includes(st?.id)
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      <MdDone />
                                    </span>
                                  </div>
                                </td>
                                <td>$ {Number(st.final_price).toFixed(4)}</td>
                                <td>
                                  <button
                                    disabled={!checkArray.includes(st?.id)}
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                    }}
                                    onClick={() =>
                                      updateGradeHandler(st?.id, st?.metal_id)
                                    }
                                  >
                                    <MdSave />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </table>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default MetalPriceManage;
