 import User from './user.svg';
 import MaleDoc from './male-doc.svg';
 import ArrowWhite from './arrow-white.svg';
 import Logo from './mach2-logo.png';
 import HighPrecision from './high-precision-capabilities-icon.png';
 import CalendarIcon from './calendar-icon.png';
 import ToolsIn from './tools-in-the-cloud-image.png';
 import HpLogo from './hp-logo.png';
 import StairLogo from './satair-logo.png';
 import BmwLogo from './bmw-logo.png';
 import Bosch from './bosch.png';
 import WhyChoose from './why-you-choose-image.png';
 import ClockIcon from './clock-icon.png';
 import RoboticIcon from './robotic-arm-icon.png';
 import LocationIcon from './location-icon.png';
 import ElectronIcon from './electron-icon.png';
 import VideoPlaceholder from './video-placeholder.png';
 import PlayButton from './play-button.png';
 import PartsMade from './parts-made-real-img.png';
 import Capabilities from './capabilities-pic1.png';
 import Capabilities2 from './capabilities-pic2.png';
 import Capabilities3 from './capabilities-pic3.png';
 import Capabilities4 from './capabilities-pic4.png';
 import Capabilities5 from './capabilities-pic5.png';
 import Capabilities6 from './capabilities-pic6.png';
 import Capabilities7 from './capabilities-pic7.png';
 import Capabilities8 from './capabilities-pic8.png';
 import Delivery1 from './delivery-img1.png';
 import Delivery2 from './delivery-img2.png';
 import Delivery3 from './delivery-img3.png';
 import FooterMap from './footer-map.png';
 import FooterMach from './footer-mach-logo.png';
 import LoginImg from './log-in-icon.png';
 import VideoPlaceNew from './video-placeholder.png';
 import TotalOrders from './total-orders.png';
 import TotalQuotes from './total-quotes.png';
 import CheckList from './checklist.png';
 import EditGrid from './edit-grid-icon.png';
 import CheckListGrid from './checklist-grid-icon.png';
 import ToatalParts from './total-parts.png';
 import ProfileImage from './profile-image.png';
 import CreateAquote from './create-quote-hero-image.png';
 import FolderIcon from './folder-dark-form.png';
 import Item1 from './item-1.png';
 import PenIcon from './pen-icon.png';
 import CloseIcon from './close-icon.png';
 import ProductBig from './product-image-big.png';
 import CardPayment from './card-payments.png';
 import RecentOrders from './recent-orders.png';
 import AuctionIcon from './auction_icon.png';
 import EarningIcon from './earning-icon.png';
 import RecentBids from './recent-bids.png';
 import RecentPayments from './recent-payments.png';
 import EyeIcon from './eye-icon.png';
 import Modeling3d from './3d-modeling.png';
 import FacMatchine from './factory-machine.png';
 import LeaserCutting from './laser-cutting-machine.png';
 import WhyChoose2 from './why-choose-us.png';
 import PartDetail from './part-details.png';
 import ISO from './iso.png';
 import Trademark from './trademark.png';
 import FabricationIcon from "./fabrication.png";

 export const ImageObj ={
    User:User,
    MaleDoc:MaleDoc,
    ArrowWhite:ArrowWhite,
    Logo:Logo,
    HighPrecision:HighPrecision,
    CalendarIcon:CalendarIcon,
    ToolsIn:ToolsIn,
    HpLogo:HpLogo,
    StairLogo:StairLogo,
    BmwLogo:BmwLogo,
    Bosch:Bosch,
    WhyChoose:WhyChoose,
    ClockIcon:ClockIcon,
    RoboticIcon:RoboticIcon,
    LocationIcon:LocationIcon,
    ElectronIcon:ElectronIcon,
    VideoPlaceholder:VideoPlaceholder,
    PlayButton:PlayButton,
    PartsMade:PartsMade,
    Capabilities:Capabilities,
    Capabilities2:Capabilities2,
    Capabilities3:Capabilities3,
    Capabilities4:Capabilities4,
    Capabilities5:Capabilities5,
    Capabilities6:Capabilities6,
    Capabilities7:Capabilities7,
    Capabilities8:Capabilities8,
    Delivery1:Delivery1,
    Delivery2:Delivery2,
    Delivery3:Delivery3,
    FooterMap:FooterMap,
    FooterMach:FooterMach,
    LoginImg:LoginImg,
    VideoPlaceNew:VideoPlaceNew,
    TotalOrders:TotalOrders,
    CheckList:CheckList,
    EditGrid:EditGrid,
    CheckListGrid:CheckListGrid,
    TotalQuotes:TotalQuotes,
    ToatalParts:ToatalParts,
    ProfileImage:ProfileImage,
    CreateAquote:CreateAquote,
    FolderIcon:FolderIcon,
    Item1:Item1,
    PenIcon:PenIcon,
    CloseIcon:CloseIcon,
    ProductBig:ProductBig,
    CardPayment:CardPayment,
    RecentOrders:RecentOrders,
    AuctionIcon:AuctionIcon,
    EarningIcon:EarningIcon,
    RecentBids:RecentBids,
    RecentPayments:RecentPayments,
    EyeIcon:EyeIcon,
    Modeling3d:Modeling3d,
    FacMatchine:FacMatchine,
    LeaserCutting:LeaserCutting,
    WhyChoose2:WhyChoose2,
    PartDetail:PartDetail,
    ISO:ISO,
    Trademark:Trademark,
    FabricationIcon:FabricationIcon,
 }

