import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { modalToggelValue, modalResetMsgValue } from '../../../../../../redux/actions/action'
import { Modal } from 'react-bootstrap'
const ResetPasswordLink = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const dispatchToggle = () => {
        dispatch(modalToggelValue(true))
        dispatch(modalResetMsgValue(false))

        // navigate("/")


    }


    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <div className="modal-content rounded-0">
                <div className="sign-in">
                    <div className="input-head">
                        <h3>Check Your Email</h3>
                        <p>We have sent an email to your registered email address. Please check that to continue.</p>
                    </div>
                
                <div className="select-box-top pr-0">


                    <button className="btn btn-primary-orange m-auto  spinner mb-3" onClick={() => dispatchToggle()}>Go Back To Login </button>

                </div>
            </div>
            </div>

        </Modal>
    )
}

export default ResetPasswordLink;