import React, { useCallback, useEffect, useState, useRef } from "react";
import {FaPlusCircle, FaTimesCircle } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import {  useNavigate, useParams } from "react-router-dom";
import {
  getMethodDataUser,
  postMethodDataUser,
} from "../../../../../admin/adminApi/api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormValidation from "../../../../../../formValidation/formValidation";
import axios from "axios";
import { contactUsForm, customerLoader } from "../../../../../../redux/actions/action";
// import { StlViewer } from "./StlViewer";

const CreateAQuoteProcess = (props) => {
  let sumToatalValue = 0;
const dispatch = useDispatch();


  const navigate = useNavigate();
  const { id } = useParams();
  const [responseData, setResponseData] = useState([""]);
  const [responseCadd, setResponseCadd] = useState([""]);
  const [responseManufac, setResponseManufac] = useState([]);
  const [isCustom, setIsCustom] = useState(0);
  // console.log(isCustom);
  const [funcValue, setFuncValue] = useState(false);
  const [lengthValue, setLengthValue] = useState();
  const [isPlaceKey, setIsPlaceKey] = useState();
  const [designFile, setDesignFile] = useState([]);
  const [appendData, setAppendData] = useState([]);
  const [openDelete, setOpenDelete] = useState();
  const [indexDelete, setIndexDelete] = useState();
  const [loading, setLoading] = useState(false);
  const [disabledValue, setDisabledValue] = useState(true);
  const [incrementQty, setIncrementQty] = React.useState();
  // // console.log("incrementQty", incrementQty)

  const FormValidationNew = FormValidation;

  // useEffect(()=>{
  //   setIncrementQty(sumToatalValue)
  // },[])

  const [names, setNames] = useState([]);
  const USERTOKEN = localStorage.getItem("userToken");
  const pathname = window.location.pathname.split("/")[1];
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm();
  const getResponseData = useCallback(() => {
    getMethodDataUser(`/quotes/edit/${id}`, USERTOKEN).then((res) => {
       setResponseData(res.data.result.data);
      setResponseCadd(res.data.result.manufacturing_process); 
    });
  }, [pathname, USERTOKEN, id]);
  useEffect(()=>{
    if(responseData?.manufacturing_process !==null || responseData?.manufacturing_process!==undefined){
      getFactorial(0,"manufacturing_process",responseData?.manufacturing_process)
    }
  },[responseData])
  const popUpDetele = (index) => {
    setIndexDelete(index);
    setOpenDelete(true);
  };

  useEffect(() => {
    setDesignFile([]);
    for (let i = 0; i < appendData.length; i++) {
      setDesignFile((designFile) => [
        ...designFile,
        {
          file: URL.createObjectURL(appendData[i]),
          name:appendData[i].name
        },
      ]);
    }
  }, [appendData]);

  const uploadSingleFile = (e) => {
   
    //
    var fileName = e.target.value;
    var dot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(dot, fileName.length).toLowerCase();
    // if (
    //   extFile === "jpg" ||
    //   extFile === "svg" ||
    //   extFile === "png" ||
    //   extFile === "jpeg" ||
    //   extFile === "avif" ||
    //   extFile === "gif" ||
    //   extFile === "webp" ||
    //   extFile === "bmp" ||
    //   extFile === "ico" ||
    //   extFile === "tif" ||
    //   extFile === "tiff"
    // ) {
      if (e.target.files) {
        const imageFiles = document.getElementById("attachments").files;
        if (appendData.length < 0) {
          console.log(appendData)
          setAppendData(appendData);
        } else {
          for (let index = 0; index < imageFiles.length; index++) {
            
            setAppendData((appendData) => [...appendData, imageFiles[index]]);
          }
        }
      }
    // } else {
    //   toast.error("File Not Supported");
    // }
  };
  const deleteFile = (delete_index) => {
    var temp_update_array = [];

    if (appendData.length === 1) {
      temp_update_array = [];
      setAppendData(temp_update_array);
      setDesignFile([]);
    } else {
      for (let index = 0; index < appendData.length; index++) {
        if (index != delete_index) {
          temp_update_array.push(appendData[index]);
        }
      }
      setAppendData(temp_update_array);
    }
    setOpenDelete(false);
    toast.success("Quote Attachment Is Deleted Successfully.");
  };
  const onSubmit = async (data) => {
    setLoading(true);
    const fd = new FormData();
    const getUpdateInputs =
      document.getElementsByClassName("settingUpdateInput");
    fd.append("dimensions", responseData?.dimensions);
    fd.append("file_units", responseData?.file_units);
    fd.append("volume", responseData?.volume);
    fd.append("is_custom_part", isCustom);
    fd.append("is_place_order", isPlaceKey);
    fd.append("attachments", appendData);

    fd.append("price", responseData.price);

    fd.append("qty", responseData.qty);

    for (let i = 0; i < appendData.length; i++) {
      fd.append(`attachments[${i}]`, appendData[i]);
    }
    for (let i = 0; i < getUpdateInputs.length; i++) {
      fd.append(getUpdateInputs[i].id, getUpdateInputs[i].value);
    }

    postMethodDataUser(`/quotes/update/${id}`, fd, USERTOKEN)
      .then((res) => {
        if (res?.data?.status === true) {
          setLoading(false);
          if(modifyData==="Yes"){
            toast.success("We have received your quote request and Mach2Mfg team is working on presenting you a good deal. Please give us sometime.");
          }
          else if(modifyData==="No") {
            toast.success(res.data.message);
          }

          navigate(`/customer/quotes/quotedetails${responseData.quote_id}`);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        // console.log("Error is", err);
      });
  };

  const mapData = names.map((res) => {
    return res.name;
  });

  useEffect(() => {
    const lengthClass = document.getElementsByClassName("length");

    if (lengthClass?.length === 0) {
      setIsCustom(0);
    }
  });
  const removecCustomField = useCallback((val) => {
    const element = document.getElementById(`custom_${val}`);
    if (element) {
      element.remove();
    }
  }, []);

  const addTextInput = useCallback((val) => {
    setFuncValue(true);
    setIsCustom(1);

    var custom = document.createElement("INPUT");

    custom.setAttribute("type", "text");
    custom.setAttribute("name", `custom_${val}`);
    custom.setAttribute("Placeholder", "Custom Value");
    custom.setAttribute("required", "true");
    custom.setAttribute("id", `custom_${val}`);
    custom.setAttribute(
      "class",
      "select-box form-control mt-4 settingUpdateInput border-primary length"
    );

    document.getElementById(`${val}1`).appendChild(custom);

    const elementClass = document.getElementsByClassName("length");
  }, []);

  const getValueEvent = (value) => {
    if (value) {
      setDisabledValue(false);
    } else {
      setDisabledValue(true);
    }
  };

  const getFactorial = useCallback(
    (x, val, value) => {
       // console.log("==>",x, val, value)
      if (val === "manufacturing_process") {
        getMethodDataUser(
          `/quotes/get-manufacturing-process-data?${val}=${value}`,
          USERTOKEN
        ).then((res) => {
          setResponseManufac(res.data.result.data);
        });
      }
      if (value) {
        setDisabledValue(false);
      } else {
        setDisabledValue(true);
      }
      if (value === "Custom") {
        const lengthClass = document.getElementsByClassName("length");

        if (lengthClass?.length === 0) {
          setIsCustom(0);
        }

        setLengthValue(1);

        addTextInput(val);
      } else {
        if (
          val === "manufacturing_process" ||
          val === "material" ||
          val === "finish" ||
          val === "inspection"
        ) {
          if (funcValue === true) {
            removecCustomField(val);
          }
        }
      }

      if (x !== undefined && val !== undefined) {
        // setNames(names => [...names, { 'name': val, 'value': x }])
        const result = mapData.find((element) => {
          return element == val;
        });

        if (result == val) {
          names.filter((element) => {
            const isDuplicate = mapData.includes(element.name);

            if (!isDuplicate) {
              setNames((names) => [...names, { name: element.name, value: x }]);

              return true;
            } else {
              var aquaticCreatures = names.filter(function (creature) {
                return creature.name !== val;
              });

              setNames(aquaticCreatures);

              setNames((names) => [...names, { name: val, value: x }]);
            }

            return false;
          });
        } else {
          setNames((names) => [...names, { name: val, value: x }]);
        }
      }
    },
    [names]
  );

  var msgTotal = names.map(function (val) {
    return val.value;
  }, 0);

  for (let i = 0; i < names.length; i++) {
    sumToatalValue += msgTotal[i];
  }

  useEffect(() => {
    getResponseData();
  }, [pathname, USERTOKEN, id]);

  useEffect(() => {
    FormValidationNew();
  }, [FormValidationNew]);

  const getQuantity = useCallback(
    (x) => {
      setIncrementQty(x);
    },
    [sumToatalValue, incrementQty]
  );
  const BASE_URL = process.env.REACT_APP_BASE_URL_USER;
  const [modifyData,setModifyData]=useState();
  useEffect(() => {

    axios.get(`${BASE_URL}/user/get-globle-config/QUOTE_MODIFY`).then(req=>{
      setModifyData(req?.data?.result?.data?.value)
      // console.log(req?.data?.result?.data?.value)
    })
}, [])
// customer dashboard loader
dispatch(customerLoader(false));

  return (
    <React.Fragment>
      <section className="pt-5 mt-3">
        <div className="container dashboard-heading-block py-3">
          <div className="row align-items-center">
            <div className="col-lg-12 dashboard-headline text-start">
              <h2>Request Quote</h2>

           
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 white-quote-box pb-5">
              <div className="row bottom-border-quote">
                <div className={modifyData==="Yes"? "col-lg-12 col-md-12 col-sm-12 col-12 left-border bottom-border-mobile" : modifyData==="No"?"col-lg-3 col-md-3 col-sm-3 col-6 left-border bottom-border-mobile":null}>
                  <div className={modifyData==="Yes"? "py-4 px-2 d-flex" : modifyData==="No"? "quote-details-box":null} >
                    <p className="semi-bold-font">File Name</p>
                    <p className={modifyData==="Yes"? "small-paragraph-text ms-1" : modifyData==="No"? "small-paragraph-text":null}  >
                      {responseData.file_name}
                    </p>
                  </div>
                </div>
                {modifyData==="Yes"?null:modifyData==="No"?<>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6 left-border bottom-border-mobile">
                  <div className="quote-details-box">
                    <p className="semi-bold-font">Dimensions</p>
                    <p className="small-paragraph-text">
                      {/* {responseData.dimensions} */}
                      ----
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6 left-border">
                  <div className="quote-details-box">
                    <p className="semi-bold-font">Volume</p>
                    <p className="small-paragraph-text">
                      {/* {responseData.volume} */}
                      ----
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6 left-border">
                  <div className="quote-details-box">
                    <p className="semi-bold-font">File Units</p>
                    <p className="small-paragraph-text">
                      {/* {responseData.file_units} */}
                      ----
                    </p>
                  </div>
                </div>
                </>:''}
               
              </div>
              <div className="row ">
                {/* <div className="col-lg-12 text-center">
                  {modifyData==="No"?<iframe
                    src={`//sharecad.org/cadframe/load?url=${responseData.auto_cad_file_path}`}
                    scrolling="no"
                    style={{minHeight:"500px",width:"100%"}}
                    title="...">
                  </iframe>:null} */}
                  {/* {responseData?.auto_cad_file_path !== null && responseData?.auto_cad_file_path !== undefined ? 
                  <StlViewer pathtothreed={responseData?.auto_cad_file_path}/>:null} */}
                {/* </div> */}
              </div>
              <div className="row mb-2 mb-sm-5 pe-3 ps-3 pe-sm-5 ps-sm-5  position-relative">
                {designFile &&
                  designFile.map((file, index) => {
                    return (
                      <div
                        className={modifyData==="Yes"?"col-sm-8":"col-sm-3"}
                        id={`preview_image_${index}`}
                        key={index + 1}
                      >
                        <div className="border position-relative">
                          {modifyData==="No"?<img
                            className="mw-100 h-100 w-100 min-90-px"
                            src={file.file}
                            alt="..."
                          /> :null}
                           {modifyData==="Yes"? <p className="mw-100 h-100 w-100 py-3 px-2 mb-0" >{file.name}</p> :null}
                       
                          <button
                            className={"delete selected"}
                            type="button"
                            onClick={() => popUpDetele(index)}
                          >
                            <MdDelete />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                <span
                  className={
                    openDelete === true ? "deleteBox selected" : "deleteBox"
                  }
                >
                  <p className="text-light mt-2 mb-2">
                    Are you sure you want to delete?
                  </p>
                  <span className="cancel" onClick={() => setOpenDelete(false)}>
                    Cancel
                  </span>
                  <span
                    className="confirm"
                    onClick={() => deleteFile(indexDelete)}
                  >
                    Yes
                  </span>
                </span>
              </div>
              <div className="row">
                {isCustom === 1 && modifyData==="No" && (
                  <div className="col-lg-12">
                    <div className="create-quote-help d-flex ">
                      <FaTimesCircle
                        className="me-3"
                        style={{ fontSize: "70px", marginTop: "-14px" }}
                      />
                      <div className="need-help-inner-new">
                        <h5 className="text-theme">Manual quote required</h5>
                        <p className="text-theme">
                          Please proceed with a manual quote request. You can
                          also split parts to different quotes for faster
                          quoting.
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-lg-12">
                  <div className="create-quote-help  mt-3 mt-sm-4 d-flex form-upload-box">
                    <FaPlusCircle
                      className="me-3"
                      style={{ fontSize: "40px" }}
                    />
                    <div className="need-help-inner-new">
                      <h5>Add More Images For This Product</h5>
                     
                    </div>
                    <input
                      // accept="image/*"
                      type="file"
                      className="settingUpdateInput"
                      id="attachments"
                      multiple
                      onChange={uploadSingleFile}
                      name="attachments"
                    />
                  </div>
                </div>
                <div className="col-lg-12 cursor-pointer">
                  <div className="create-quote-help mt-3 mt-sm-4 d-flex" onClick={()=>dispatch(contactUsForm(true))}>
                    <div className="need-help-inner">
                      <h5>Need help?</h5>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 dark-form p-0">
              {modifyData==="Yes"?null:modifyData==="No"?<div className="dark-form-header">
                <h5 className="m-0">DFM Checks</h5>
              </div>:null}
              <div className="dark-form-sub-header">
                <h5 className="m-0">Instant Quote </h5>
              </div>
              <div className="dark-form-block">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="needs-validation"
                  noValidate
                >
                  <div className="row">
                    {responseCadd?.map((res, i) => {
                      return res.type !== "dropdown" ? (
                        <div className="col-lg-12" key={i + 1}>
                          <label
                            className="text-light mb-1"
                            style={{ fontSize: "14px" }}
                          >
                            {res.label}
                          </label>
                          <input
                            onChange={(e) => getValueEvent(e.target.value)}
                            className="select-box form-control mb-4 settingUpdateInput"
                            id={res.input_name}
                            type={res.type}
                            placeholder={res.label}
                            min="0"
                          />
                        </div>
                      ) : (
                        <div
                          className={`col-lg-12  mb-4 ${res.input_name}`}
                          key={i + 1}
                          id={res.input_name + 1}
                        >
                          <label
                            className="text-light mb-1"
                            style={{ fontSize: "14px" }}
                          >
                            {res.label}
                          </label>
                          <select
                            defaultValue={responseData?.manufacturing_process}
                            className="select-box form-control settingUpdateInput text-capitalize"
                            id={res.input_name}
                            onChange={(e) =>
                              getFactorial(
                                Number(
                                  e.target.selectedOptions[0].attributes.attr
                                    .value
                                ),
                                res.input_name,
                                e.target.value
                              )
                            }
                            required={res.required}
                          >
                            <option className="disabled-value d-none " value="">
                              {res.label}
                             </option>
                            {res?.data?.length !== undefined &&
                            res !== undefined &&
                            res?.data !== undefined
                              ? res?.data?.map((res2, i) => {
                                  return (
                                    <option
                                      key={i + 1}
                                      attr={res2?.price}
                                      value={res2?.name}
                                      className="text-capitalize"
                                    >
                                      {res2?.name}
                                    </option>
                                  );
                                })
                              : "N/A"}
                          </select>
                          <div className="invalid-feedback">
                            {res.label} is required
                          </div>
                        </div>
                      );
                    })}
                    {responseManufac?.length &&
                      responseManufac?.map((res, i) => {
                        return (
                          <div
                            className={`col-lg-12  mb-4 ${res.input_name}`}
                            key={i + 1}
                            id={res.input_name + 1}
                          >
                            <label
                              className="text-light mb-1"
                              style={{ fontSize: "14px" }}
                            >
                              {res.label}
                            </label>
                            <select
                              className="select-box form-control settingUpdateInput"
                              id={res.input_name}
                              onChange={(e) =>
                                getFactorial(
                                  Number(
                                    e.target.selectedOptions[0].attributes.attr
                                      .value
                                  ),
                                  res.input_name,
                                  e.target.value
                                )
                              }
                              required={res.required}
                            >
                              <option
                                className="disabled-value d-none "
                                value=""
                              >
                                {res.label}
                              </option>
                              {res?.data?.length !== undefined &&
                              res !== undefined &&
                              res?.data !== undefined
                                ? res?.data?.map((res2, i) => {
                                    return (
                                      <option
                                        key={i + 1}
                                        attr={res2?.price}
                                        value={res2?.name}
                                      >
                                        {res2?.name}
                                      </option>
                                    );
                                  })
                                : "N/A"}
                            </select>
                            <div className="invalid-feedback">
                              {res.label} is required
                            </div>
                          </div>
                        );
                      })}

                    <div className="col-lg-12 mb-4">
                      <label
                        className="text-light mb-1"
                        style={{ fontSize: "14px" }}
                      >
                        Quantity
                      </label>
                      <input
                        onWheel={(event) => event.currentTarget.blur()}
                        min="1"
                        defaultValue={1}
                        onChange={(e) => getQuantity(e.target.value)}
                        className="select-box form-control noscroll  settingUpdateInput"
                        id={"qty"}
                        type="number"
                        placeholder={"Quantity"}
                        required
                      />
                      <div className="invalid-feedback">
                        Quantity is required
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <textarea
                        onChange={(e) => getValueEvent(e.target.value)}
                        placeholder="Notes"
                        className="form-control custom-scroll mb-4 textarea-design settingUpdateInput"
                        id="notes"
                      />
                    </div>
                    {/* <div className="col-lg-12">
                      <div className="form-upload-box"><label htmlFor="upload-file"><img src={ImageObj.FolderIcon} /></label><input type="file" className='settingUpdateInput' id="attachments" multiple onChange={uploadSingleFile} name="attachments" />
                        <input type="text" placeholder="uploadfile" className="form-control mb-4 input-design" /></div>
                    </div> */}
                  </div>
                  {isCustom === 1 && modifyData==="No" ? (
                    <div className="col-lg-12">
                      <div className="create-quote-help d-flex ">
                        <div className="need-help-inner-new">
                          <h6
                            className="text-theme"
                            style={{ fontSize: "14px" }}
                          >
                            Manual quote required
                          </h6>
                          <p
                            className="text-theme"
                            style={{ fontSize: "12px!important" }}
                          >
                            Please proceed with a manual quote request. You can
                            also split parts to different quotes for faster
                            quoting.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className={modifyData==="Yes"?"row mt-2 hideme":"row mt-2"}>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <p>Unit Price</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="dolar-imput-box">
                            <span className="dolar-sign">$</span>
                            <input
                              className="settingUpdateInput text-light ps-4"
                              id="unit_price"
                              onChange={() => {}}
                              value={
                                isCustom === 1
                                  ? 0
                                  : Number(sumToatalValue).toFixed(2)
                              }
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className={modifyData==="Yes"?"row mt-2 hideme mobile-top-margin":"row mt-2 mobile-top-margin"}>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <h5 className="mb-0">Total Price</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="dolar-imput-box">
                            <span className="dolar-sign">$</span>
                            <input
                              className="settingUpdateInput text-light ps-4"
                              value={
                                isCustom === 1
                                  ? 0
                                  : incrementQty
                                  ? Number(
                                      sumToatalValue * incrementQty
                                    ).toFixed(2)
                                  : Number(sumToatalValue).toFixed(2)
                              }
                              id="price"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  <div className="row mt-4">
                    <div className="col-lg-12">
                      {/* <button type="submit" onClick={()=>setIsPlaceKey(0)} className="btn btn-primary-orange m-0">Save</button> */}

                      {loading === false ? (<>
                        {modifyData === "Yes"?
                        <button
                        disabled={disabledValue}
                        onClick={() => setIsPlaceKey(1)}
                        type="submit"
                        className="btn ms-2 no-bg"
                      >
                        Place Quote
                      </button>:<button
                      disabled={disabledValue}
                      onClick={() => setIsPlaceKey(1)}
                      type="submit"
                      className="btn ms-2 no-bg"
                    >
                      Proceed
                    </button>}</>
                      ) : (
                        <Button
                          className="btn  btn-primary-orange m-auto  spinner"
                          variant="dark"
                          disabled
                        >
                          <Spinner
                            as="span"
                            animation="grow"
                            className="me-2"
                            role="status"
                            size="sm"
                            aria-hidden="true"
                          />
                          Please wait...
                        </Button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default CreateAQuoteProcess;
