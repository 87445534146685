import React, { useEffect, useState } from 'react'

import { useNavigate, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import axios from 'axios'
import {Button, Spinner} from 'react-bootstrap'


// import { ImageObj } from '../../../assets/images/imgeObject'
// import SENDFORGOTPASSMAIL_API from '../../../views/api/api'


const ResetMail = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate()
    const Token = localStorage.getItem("token")
    const [loading,setLoading] = useState(false)
    const [mailErr, setMailErr] = useState()

    const { register, handleSubmit } = useForm();

    useEffect(() => {
        document.body.classList.add("remove-header");
        return () => {
            document.body.classList.remove("remove-header");
            setMailErr("")

        }
    }, [])

    useEffect(() => {
        document.body.classList.add("remove-user");


        return () => {
            document.body.classList.remove("remove-user");
        }
    },[])

    // useEffect(() => {
    //     const Auth2 = localStorage.getItem("token");


    //     if ( Auth2 !== null) {
    //        navigate("/admin/dashboard")
    //     } 
    // }, [])



    const resetLink = (data) => {
        setLoading(true)

        axios.post(`${BASE_URL}/get-reset-password-link`, { email: data.email })
            .then((res) => {
        
                if (res.data.status === true) {
                    setLoading(false)
                    navigate("/admin/sent-link-mail")
                } else {
                    setMailErr(res.data.message)
                    setLoading(false)

                }


            })
    }


    return (
        <div className="container">
            <div className="max-new-second login">
                <div className="main-theme bg-theme border otp-pad new shadow">
                    <section className="select-state">
                        <div className="state-bor">
                            <div className="log-in otp-num">
                                <div className="select-steps-box">
                                    <div className="input-head">
                                        <h2>Reset Password</h2>
                                        <p>Please enter your registered email address and we would send you the link to reset your password.</p>
                                    </div>
                                </div>
                                <div className="select-box-top pr-0">
                                    <div className="input-box">
                                        <form onSubmit={handleSubmit(resetLink)}>
                                            <div className="col-12 p-0">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <input type="email" className="form-control" name="email" {...register('email', { required: true })} placeholder="Enter your Email" />
                                                            <small className=" form-text text-muted">{mailErr}</small>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="theme-btn">
                                              {
                                                loading === false ?
                                                <button type="submit" className="btn verfy-btn ">Submit </button>:
                                                 
                                                <Button className='verfy-btn spinner' variant='dark' disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Sending...
                                            </Button>
                                              }
                                                   
                                                

                                               
                                                <p className="resend new">Have an Account? <span><Link to="/admin">Login <i className="fas fa-angle-right " aria-hidden="true" /></Link></span></p>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default ResetMail;