import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { GiAutoRepair } from "react-icons/gi";
import { Button, Spinner, Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

// icons
import { FaDownload, FaSearch, FaEye, FaFileExcel } from "react-icons/fa";

import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";

// react bootstrap

import ChangePassword from "../../common/changePassword/changePassword";
import {
  getMethodData,
  postMethodData,
  getSubmitData,
} from "../../adminApi/api";
import SendEmail from "../../common/sendEmail/sendEmail";
import { addPendingValue } from "../../../../redux/actions/action";
import { useDispatch } from "react-redux";

const AttentionOrder = (props) => {
    let  showDataToUser =false; 
    const [redirection,setRedirection] = useState(false);
    const [excelpermission, setExcelpermission] = useState(false)
    useLayoutEffect(() => {
        const ls=JSON.parse(localStorage.getItem("adminDetails"));
      // // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
        for(let i=0; i<=ls?.my_permissions?.length;i++){
            if(ls?.my_permissions[i]?.name==="Order Management"){
                
                for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                    if(ls?.my_permissions[i]?.permissions[j]?.name==='Unattended Orders List'){
                        // // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                        showDataToUser = true;
                       
                    }
                    if(ls?.my_permissions[i]?.permissions[j]?.name==="Orders Excel Export"){
                      // // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      setExcelpermission(true)
                     
                  }
                }
            }
        }
        // // console.log(showDataToUser);
        if(showDataToUser===false){
          // // console.log("showDataToUser ",showDataToUser)
          setRedirection(true);
        }
    }, []);
    const navigate = useNavigate();
    if (redirection) navigate('/admin/dashboard');
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const dispatch = useDispatch();

  // const PORT = PORTNUMBER.PORTNUMBER
  const Token = localStorage.getItem("token");
  const [showPassword, setShowPassword] = useState(false);

  // modal active
  const [activeShow, setActiveShow] = useState(false);

  const [activeShow3, setActiveShow3] = useState(false);
  const [statusId, setStatusId] = useState();
  const [newdate, setnewdate] = useState();

  const [customerId, setCustomerId] = useState();
  const [orderMail, setOrderMail] = useState();

  const [loading, setLoading] = useState(false);

  const [statusType, setStatusType] = useState();

  // const [Loading, setLoading] = useState(false);

  const [order, setOrder] = useState([]);
  const [Count, setCount] = useState(1);
  const [perPage, setPerPage] = useState();

  const [indexPage, setIndexPage] = useState(1);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const [activePage, setActivePage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");
  const [ExlFilter, setExlFilter] = useState({
    page: "",
    order_id: "",
    quote_id: "",
    customer_name: "",
    supplier_name: "",
    status: "",
    from_date: "",
    to_date: "",
    payment_status: "",
    export_in_excel: "1",
  });

  // const [filterData, setFilterData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [filterVisibility, setFilterVisibility] = useState(true);

  const { register, control, handleSubmit, reset } = useForm();

  const {
    register: register2,
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm();
  const {
    register: register3,
    control: control3,
    handleSubmit: handleSubmit3,
    reset: reset3,
  } = useForm();

  const dNone = "d-none";
  const [supplername, setsupplername] = useState([]);
  const [supplier_name, setsupplier_name] = useState();
  const supplier_namelist = () => {
    axios
      .get(`${BASE_URL}/get-suppliers-name-list?`, {
        headers: { authorization: `Bearer ${Token}` },
      })
      .then((res) => {
        setsupplername(res?.data?.result.data);
      });
  };

  const activeHandleClose = () => {
    reset2();
    setActiveShow(false);
  };

  const activeHandleClose3 = () => {
    reset3();
    setActiveShow3(false);
  };

  const [paymentdta, setpaymentdta] = useState({
    quote_id: "",
    payment_method: "",
    amount: "",
  });

  const activeUser = (data) => {
    if (data.status == undefined) {
      // toast.warning("Select Other Order Status Option")
      return;
    } else if (data.status != statusType) {
      setLoading(false);
      postMethodData(`/orders/change-status/${statusId}`, data, Token).then(
        (res) => {
          // // console.log(res)
          if (res.data.status == true) {
            activeHandleClose();
            toast.success(res.data.message);
            LoadTableData();
            setLoading(true);
          } else {
            activeHandleClose();
            setLoading(true);
            toast.warning(res.data.message);
          }
        }
      );
    } else {
      toast.warning("Select Other Order Status Option");
      setLoading(true);
      return;
    }
  };
  const sendPageFilter = (pageNumber) => {
    // // console.log("filterData", filterData)
    // alert(1)
    getSubmitData(
      `${props.filterApi}?page=${pageNumber}&${props.searchId}=${id}`,
      Token
    ).then((res) => {
      // // console.log("redsd", res)
      setOrder(res.data.result.data.data);
      setCount(res.data.result.data.total);
      setLoading(true);
    });
  };

  const LoadTableData = useCallback(
    async (pageNumber) => {
      // if(id?.length){
      //     navigate("/admin/order-management")

      // }
      setIndexPage(1);

      setLoading(false);
      await axios
        .get(
          `${BASE_URL}/need-attention-orders?page=${String(
            pageNumber === 1 ? 1 : pageNumber
          )}`,
          { headers: { authorization: `Bearer ${Token}` } }
        )
        .then((res) => {
          // // console.log("orders", res)
          setOrder(res.data.result.data.data);
          setCount(res.data.result.data.total);
          setPerPage(res.data.result.data.per_page);
          setLoading(true);
          setFilterVisibility(true);
        });

      reset();
    },
    [BASE_URL, Token, reset],
    [order, Count]
  );

  useEffect(() => {
    LoadTableData();
    supplier_namelist();
  }, []);

  const sendPage = (pageNumber) => {
    // // console.log("filterData", filterData)
    axios
      .get(
        `${BASE_URL}/need-attention-orders?page=${pageNumber}&${new URLSearchParams(
          filterData
        ).toString()}`,
        { headers: { authorization: `Bearer ${Token}` } }
      )
      .then((res) => {
        // // console.log("redsd", res)
        setOrder(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);
        setLoading(true);
      });
  };

  const handlePageChange = (pageNumber) => {
    // sendPage(pageNumber)
    // LoadTableData(pageNumber)
    if (id?.length) {
      sendPageFilter(pageNumber);
      // // console.log("1")
    } else {
      if (filterVisibility === true) {
        LoadTableData(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
        setLoading(false);
        // // console.log("2")
      } else {
        sendPage(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
        setLoading(false);
        // // console.log("3")
      }
    }
  };

  useEffect(() => {
    sendPageFilter();

    if (id) {
      sendPageFilter();
    } else {
      sendPage();
      LoadTableData();
    }
  }, [paymentdta]);

  const onSubmit = async (data) => {
    setExlFilter({
      page: 1,
      order_id: data.order_id,
      quote_id: data.quote_id,
      customer_name: data.customer_name,
      supplier_name: supplier_name,
      status: data.status,
      from_date: data.from_date,
      to_date: data.to_date,
      payment_status: data.payment_status,
      export_in_excel: "1",
    });
    const obj = {
      customer_name: data.customer_name,
      supplier_name: (supplier_name===null || supplier_name===undefined ? "" :supplier_name),
      order_id: data.order_id,
      quote_id: data.quote_id,
      status: (data.status === null || data.status === undefined ? '' : data.status),
      payment_status: data.payment_status,
      from_date: (data.from_date =
        !data.from_date ||
        data.from_date === null ||
        data.from_date === undefined
          ? ""
          : moment(data.from_date).format("YYYY/MM/DD")),
      to_date: (data.to_date =
        !data.to_date || data.to_date === null || data.to_date === undefined
          ? ""
          : moment(data.to_date).format("YYYY/MM/DD")),
    };
    dispatch(addPendingValue(false));
    if (id?.length) {
      navigate("/admin/init-order");
    }
    // // console.log("filterdata")
    setLoadingButton(true);
    setIndexPage(1);

    setFilterData(data);

    // // console.log("DATA", data)

    data.from_date =
      !data.from_date || data.from_date === null || data.from_date === undefined
        ? ""
        : moment(data.from_date).format("YYYY/MM/DD");
    data.to_date =
      !data.to_date || data.to_date === null || data.to_date === undefined
        ? ""
        : moment(data.to_date).format("YYYY/MM/DD");

    setLoading(false);
    // sendPage()
    await axios
      .get(
        `${BASE_URL}/need-attention-orders?page=${1}&${new URLSearchParams(
          obj
        ).toString()}`,
        { headers: { authorization: `Bearer ${Token}` } }
      )
      .then((res) => {
        // // console.log("filtwrorders", res)
        setLoadingButton(false);

        setOrder(res.data.result.data.data);
        setCount(res.data.result.data.total);

        // setCount(res.data.data.userCount)
        // setFilterData(res.data.data.userData)
        setLoading(true);
        setFilterVisibility(false);
      });
    setLoadingButton(false);
  };

  // download excel
  const downloadXLSFile = async (pageNumber) => {
    if(excelpermission===true){
      axios({
        url: `${BASE_URL}/need-attention-orders?page=${String(
          pageNumber === 1 ? 1 : pageNumber
        )}&order_id=${ExlFilter.order_id ?? ""}&quote_id=${
          ExlFilter.quote_id ?? ""
        }&customer_name=${ExlFilter.customer_name ?? ""}&supplier_name=${
          ExlFilter.supplier_name ?? ""
        }&status=${ExlFilter.status ?? 1}&from_date=${
          ExlFilter.from_date ?? 1
        }&to_date=${ExlFilter.to_date ?? ""}&payment_status=${
          ExlFilter.payment_status ?? ""
        }&export_in_excel=${ExlFilter.export_in_excel ?? "1"}`,
        method: "GET",
        responseType: "blob", // important
        headers: { authorization: `Bearer ${Token}` },
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
  
        // create "a" HTLM element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `Orders_${moment(new Date()).format("MMDDYYYY")}.xls`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
  
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        // URL.revokeObjectURL(url);
      });
    }else{
      alert("permission not allowed")
    }
    
  };
  const [todayDate,setTodayDate]=useState(null);
  return (
    <>    
        <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="main-head">
                <h4>Unattended Orders</h4>
              </div>
            </div>
            {/* <div className="col-6">
                            <div className="theme-btn top-head text-end">
                                <Link to="#" className="btn">Add New Order</Link>
                            </div>
                        </div> */}
          </div>
        </div>
        <div className="table-order-box shadow rounded overflow-hidden">
          <div className="tab-head">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      maxLength="15"
                      {...register("customer_name", { required: false })}
                      className="form-control"
                      placeholder="Customer Name"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    {/* <input type="text" maxLength="15" {...register('supplier_name', { required: false })} className="form-control" placeholder="Supplier First Name" /> */}
                    <Multiselect
                      // {...register("supplier_name", { required: false })}
                      name="supplier_name"
                      className=""
                      options={supplername}
                      singleSelect={false}
                      selectionLimit={1}
                      placeholder={"Supplier Name"}
                      isObject={true}
                      // onKeyPressFn={function noRefCheck(){}}
                      // onSearch={function noRefCheck(){}}
                      onRemove={(selectedList, selectedItem) => {
                        setsupplier_name([]);
                      }}
                      onSelect={(selectedList, selectedItem) => {
                        setsupplier_name(selectedItem.name);
                      }}
                      displayValue={"name"}
                      emptyRecordMsg={"No Supplier Found"}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      maxLength="15"
                      {...register("order_id", { required: false })}
                      className="form-control"
                      placeholder="Order Id"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      maxLength="15"
                      {...register("quote_id", { required: false })}
                      className="form-control"
                      placeholder="Quote Id"
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group before-con">
                    <Controller
                      control={control}
                      name="from_date"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Click to select a date"
                          onChange={(date) => {
                            field.onChange(date);
                            setTodayDate(date);}}
                          selected={field.value}
                          maxDate={new Date()}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group before-con after">
                    <Controller
                      control={control}
                      name="to_date"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Click to select a date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          minDate={todayDate==null? new Date() : todayDate}
                          maxDate={new Date()}
                        />
                      )}
                    />
                  </div>
                </div>

                {/* <div className="col-lg-6">
                                    <div className="form-group">
                                        <select className="form-select form-control" {...register('status', { required: false })} aria-label="Default select example">
                                            <option className='d-none' selected value="">Order Status</option>
                                            <option value="">All</option>

                                            <option value="Order Received">Order Received</option>
                                            <option value="Material Ordered">Material Ordered</option>
                                            <option value="Work In Progress">Work In Progress</option>
                                            <option value="Under Inspection">Quality Inspection</option>
                                            <option value="Order Shipped">Order Shipped</option>
                                            <option value="Delivered">Delivered</option>
                                            <option value="Cancelled">Cancelled</option>
                                            <option value="Returned">Returned</option>
                                            <option value="Refunded">Refunded</option>
                                            <option value="Cancellation Requested">Cancellation Requested</option>
                                            <option value="Return Requested">Return Requested</option>
                                        </select>
                                    </div>
                                </div> */}

                <div className="col-lg-6">
                  <div className="form-group">
                    <select
                      className="form-select form-control"
                      {...register("payment_status", { required: false })}
                      aria-label="Default select example"
                    >
                      <option value="" className="d-none" selected>
                        Payment Status
                      </option>
                      <option value="">All</option>
                      <option value="Pending">Pending</option>
                      <option value="Failed">Failed</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="theme-btn btn-group-right text-end">
                    {loadingButton === false ? (
                      <button type="submit" className="btn">
                        Search
                      </button>
                    ) : (
                      <Button className="btn  spinner" variant="dark" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                        />
                        Searching...
                      </Button>
                    )}
                    <button
                      // to="/admin/order-management"
                      type="reset"
                      onClick={() => {
                        reset();
                        LoadTableData();
                      }}
                      className="btn"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="project-table theme-btn">
        <button className="btn" onClick={() => downloadXLSFile()}>
          Export to Excel <FaFileExcel />
        </button>
        <div className="form-search float-end">
          <form>
            <div className="form-group icon-input top-search">
              <input
                type="text"
                id="searchForm"
                className="form-control"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                placeholder="Search"
              />
              <FaSearch />
            </div>
            <input type="btn" className="d-none" value="Submit" />
          </form>
        </div>
        <div className="table-order shadow">
          <table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th className="text-nowrap">Order ID</th>
                <th className="text-nowrap">Quote ID</th>
                <th className="text-nowrap">Need Attention</th>
                <th className="text-nowrap">No. of Parts</th>
                <th className="text-nowrap">Order Date</th>
                <th className="text-nowrap">Amount</th>
                <th className="text-nowrap">Discount</th>
                <th className="text-nowrap">Shipping Price</th>
                <th className="text-nowrap">Total Amount</th>
                <th className="text-nowrap">Min Bid</th>
                <th className="text-nowrap">Customer Name</th>
                <th className="text-nowrap">Status</th>
                <th className="text-nowrap">Payment Status</th>
                <th className="text-nowrap">Payment Mode</th>
                <th className="text-nowrap">Actions </th>
              </tr>
            </thead>
            <tbody>
              {order && order.length ? (
                order
                  .filter((val) => {
                    const date = val.created_at;
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.quote.quote_id
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.order_id
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.customer.first_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.customer.last_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      moment(date)
                        .format("MM/DD/yyyy")
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else {
                      return false;
                    }
                  })
                  .map((order, index) => (
                    <tr key={order.id}>
                      <td className="s-no" scope="row">
                        {perPage * (indexPage - 1) + (index + 1)}
                      </td>
                      <td>#{order.order_id}</td>
                      <td>#{order?.quote?.quote_id}</td>
                      <td>{order?.need_attestation ?? "-"}</td>
                      <td>{order?.order_parts_count ?? "0"}</td>
                      <td>{moment(order.created_at).format("MM/DD/yyyy")}</td>

                      <td>$ {order?.product_price}</td>
                      <td>${order?.discounted_amt}</td>
                      <td>$ {order?.shipping_price}</td>
                      <td>$ {order?.final_amt}</td>
                      <td>${order?.bid_amount}</td>
                      <td>
                        {order?.customer?.first_name ?? "-"}{" "}
                        {order?.customer?.last_name}
                      </td>

                      <td className="">
                        <span
                          // onClick={() => activeHandleShow(order.id, order.status)}
                          className={`badge bg-success ${order.status}`}
                        >
                          {order.status}
                        </span>
                      </td>
                      <td>{order?.payment_status ?? "-"}</td>
                      <td>{order?.payment_method ?? "-"}</td>

                      <td className="action-top">
                        <Link
                          title="View Order"
                          to={`../order-management/${order.id}`}
                          className="icons-action"
                        >
                          <FaEye />
                        </Link>
                        {order.invoice_path ? (
                          <a
                            target={"_blank"}
                            title="Download pdf"
                            href={`${order.invoice_path ?? ""}`}
                            className="icons-action"
                          >
                            <FaDownload />
                          </a>
                        ) : (
                          <span title="Download pdf" className="icons-action">
                            <FaDownload className="text-secondary" />
                          </span>
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td className="no-data">NO ORDER FOUND</td>
                </tr>
              )}
            </tbody>
          </table>
          {!loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
        </div>
        <div className="pagination">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={perPage}
            totalItemsCount={Count}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        </div>
      </div>

      {/* modals */}

      {/* active modal */}
      <Modal
        className="status-modal"
        animation={false}
        show={activeShow}
        onHide={activeHandleClose}
      >
        <form
          className="position-relative"
          onSubmit={handleSubmit2(activeUser)}
        >
          {!loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <Modal.Body>
            Are you sure you want to Change Order Status?
            <select
              className="form-select form-control mt-4"
              // onChange={(e) => activeUser(e.target.value)}
              {...register2("status", { required: false })}
              aria-label="Default select example"
            >
              <option
                disabled
                selected
                className="disabled-value"
                value={statusType}
              >
                {statusType}
              </option>
              <option value="Order Received">Order Received</option>
              <option value="Material Ordered">Material Ordered</option>
              <option value="Work In Progress">Work In Progress</option>
              <option value="Under Inspection">Quality Inspection</option>
              <option value="Order Shipped">Order Shipped</option>
              <option value="Delivered">Delivered</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Returned">Returned</option>
              <option value="Refunded">Refunded</option>
              <option value="Return Requested">Return Requested</option>
              <option value="Cancellation Requested">
                Cancellation Requested
              </option>
            </select>
          </Modal.Body>
          <Modal.Footer>
            <div className="theme-btn btn-group-right text-end">
              <Button type="submit" variant="secondary" onClick={activeUser}>
                Yes
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={activeHandleClose}
              >
                No
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {/* active modal end */}

      <ToastContainer />
      <ChangePassword
        api={`/customers/change-password/${customerId}`}
        show={showPassword}
        dNone={dNone}
        onHide={() => setShowPassword(false)}
      />
      <SendEmail
        api={"/suppliers/send-contect-email"}
        show={showEmail}
        orderMail={orderMail}
        dNone={dNone}
        onHide={() => setShowEmail(false)}
      />
    </div>
    </>
  );
};
export default AttentionOrder;
