import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
// import CreateQuote2 from '../../components/pages/customerPages/createAquoute/createAquoute2';
import CreateQuote2new from '../../components/pages/customerPages/createAquoute/createAquoute2new';

const QuoteCollapse = ({quotetitle}) => {
    return(
        <div className="container mt-5">
            <div className='row'>
                <div className='col-md-12 quote-css'>
                    <Accordion preExpanded={[0]}  allowZeroExpanded={true}>
                        <AccordionItem uuid={0}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {
                                       quotetitle ? quotetitle : 
                                       " Got a part to manufacture?"
                                    }
                              
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <CreateQuote2new  uploadid={"upload2"}/>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}
export default QuoteCollapse;