/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useMemo } from "react";
import { Rating } from "react-simple-star-rating";
import {
  FaSearch,
  FaRegCalendarAlt,
  FaStar,
  FaRedoAlt,
  FaRegTimesCircle,
  FaSearchLocation,
  FaLocationArrow,
  FaMapMarkedAlt,
  FaCube,
  FaTractor,
  FaTruckMoving,
  FaTruck,
  FaEye,
  FaDownload,
} from "react-icons/fa";
import { ImageObj } from "../../../../../../assets/images/imgeObject";
import {
  getMethodDataUser,
  postMethodDataUser,
} from "../../../../../admin/adminApi/api";
import { useParams, Link, Navigate } from "react-router-dom";
import moment from "moment";
import { MdFeedback, MdGpsFixed } from "react-icons/md";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import CancelOrder from "../../../../common/cancelOrder/cancelOrder";
import { useForm } from "react-hook-form";
import ReturnOrder from "../../../../common/returnOrder/returnOrder";
import axios from "axios";
import fileDownload from 'react-file-download';

const OrderDetails = () => {
  const { id } = useParams();
  const USERTOKEN = localStorage.getItem("userToken");
  const SUPPLIERTOKEN = localStorage.getItem("supplierToken");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalCancelShow, setModalCancelShow] = React.useState(false);
  const [modalReturnShow, setModalReturnShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [orderDetail, setOrderDetail] = useState();
  const [orderDetail_id, setOrderDetail_id] = useState();
  const [orderId, setOrderId] = useState();
  const [partid, setpartid] = useState();
  const [partid1, setpartid1] = useState();
  const { register, control, handleSubmit, reset } = useForm();
  
  const [qulity, setqulity] = useState(0);
  const [on_time_delivery, seton_time_delivery] = useState(0);
  const [communication, setcommunication] = useState(0);
  const [engagement, setengagement] = useState(0);
  const [trackingShow,setTrackingShow]=useState(false);
  const [partname, setpartname] = useState('');

  const [reviewdata, setreviewdata] = useState();
  const [review, setreview] = useState({
    order_id: "",
    part_id: "",
    qulity: "",
    on_time_delivery: "",
    communication: "",
    engagement: "",
    reviews: "",
  });

  const [mydata, setmydata] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);

  const handleviewattachment=(data)=>{
    setmydata(!mydata);
    setAttachmentData(
      JSON.parse(data)
    );
}


  const getOrderdetails = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL_USER;
    axios.get(`${BASE_URL}/user/get-globle-config/QUOTE_MODIFY`).then((req) => {
      // if(req?.data?.result?.data?.value==="Yes"){
      //   <Navigate to="/customer/dashboard"/>
      // }
      // else{
        getMethodDataUser(`/orders/details/${id}`, USERTOKEN || SUPPLIERTOKEN).then(
          (res) => {
            setOrderDetail(res.data.result.data);
            setOrderDetail_id(res.data.result.data.id);
          }
        );
      // }
    });
   
  };
  const cancelModalFunc = (id) => {
    setModalCancelShow(true);
    setOrderId(id);
  };

  const AddReview = (data) => {
    if (qulity === 0) {
      toast.warning("Please Select Quality Stars.");
    }
    else if (on_time_delivery === 0) {
      toast.warning("Please Select On time delivery Stars.");
    } 
    else if (communication === 0) {
      toast.warning("Please Select On time Communication Stars.");
    }
    else if (communication === 0) {
      toast.warning("Please Select On time Communication Stars.");
    }
    else if (engagement === 0) {
      toast.warning("Please Select On time Engagement Stars.");
    }
    
    
    else {
      postMethodDataUser(
        `/order-reviews/store`,
        {
          order_id: review.order_id,
          part_id: review.part_id,
          qulity: qulity,
          on_time_delivery: on_time_delivery,
          communication: communication,
          engagement: engagement,
          reviews: data.reviews,
        },
        USERTOKEN || SUPPLIERTOKEN
      ).then((res) => {
        // // console.log(res)
        if (res.data.status == true) {
          handleClose();
          getOrderdetails();
          toast.success(res.data.message);
          setLoading(true);
        } else {
          handleClose();
          setLoading(true);
          toast.warning(res.data.error);
        }
      });
    }
  };

  useEffect(() => {
    getOrderdetails();

  }, []);

  const stars = (length) => {
    var stars_array = [];
    for (let index = 1; index <= 5; index++) {
      if (index <= length) {
        stars_array.push(<FaStar className="text-warning" />);
      } else {
        stars_array.push(<FaStar className="text-secondary" />);
      }
    }
    return stars_array;
  };
  const returnModalFunc = (id) => {
    setModalReturnShow(true);
    setOrderId(id);
  };

  const [trackingDetails,setTrackingDetails]=useState(null);

const getTrackingData=(data)=>{
  setTrackingShow(!trackingShow);
  //https://mach2mfg.testingdemo.net/backend/public/api/V1/user/shipment-tracking?tracking_number=794660785481
  // getMethodDataUser(`/shipment-tracking?tracking_number=${}`, USERTOKEN || SUPPLIERTOKEN).then(
  // getMethodDataUser(`/shipment-tracking?tracking_number=794660785481`, USERTOKEN ).then(
 
  getMethodDataUser(`/shipment-tracking?tracking_number=${data.shipment_tracking_number}`, USERTOKEN ).then(
     (res) => {
      // console.log('shipping res status',res.status)
      // console.log('shipping res.data ',res.data)
      if(res.data.events.length===0){
        toast.warning('no data found');
      }
      else{
        setTrackingDetails(res.data.events);
      }
     }
   );
  }

  const handleDownload = (data,fileurl) => {
    const fileUrl = data;
    const fileName = fileurl;
    fileDownload(fileUrl, fileName);
  };

  return (
    <React.Fragment>
      <section className="pt-5 mt-3 pb-5">
        <div className="container">
          <Link
            className="text-decoration-none text-dark mb-sm-4 mb-3 d-block"
            to="../orders"
          >
            {"< Back"}
          </Link>
          <div className="row">
            <div className="col-lg-12 dashboard-headline">
              <h2>Order Details - #{orderDetail?.order_id}</h2>
             
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-6">
                  <p>Customer Name</p>
                </div>
                <div className="col-6">
                  <p>
                    <span className="bold-text">
                      {orderDetail?.delivery_name}
                    </span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>Order Id</p>
                </div>
                <div className="col-6">
                  <p>
                    <span className="bold-text">#{orderDetail?.order_id}</span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>Order Date</p>
                </div>
                <div className="col-6">
                  <span className="bold-text">
                    {moment(orderDetail?.date).format("MM/DD/yyyy")}
                  </span>
                  <p />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>Order Amount</p>
                </div>
                <div className="col-6">
                  <span className="bold-text">${orderDetail?.final_amt}</span>
                  <p />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>Shipping Amount</p>
                </div>
                <div className="col-6">
                  <p>
                    <span className="bold-text">
                      ${orderDetail?.shipping_price}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-6">
                  <p>Quantity</p>
                </div>
                <div className="col-6">
                  <span className="bold-text">{orderDetail?.qty}</span>
                  <p />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>Payment Method</p>
                </div>
                <div className="col-6">
                  <p>
                    <span className="bold-text">
                      {orderDetail?.payment_method ?? "Initiate"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>Payment Status</p>
                </div>
                <div className="col-6">
                  <span className="bold-text">
                    {orderDetail?.payment_status ?? "Not Initiated"}
                  </span>
                  <p />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>Order Status</p>
                </div>
                <div className="col-6">
                  <span className="bold-text">{orderDetail?.status}</span>
                  <p />
                </div>
              </div>
              {/* {orderDetail?.cancel_reason && (
                <div className="row">
                  <div className="col-6">
                    <p>Cancel Reason</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      {orderDetail?.cancel_reason}
                    </span>
                    <p />
                  </div>
                </div>
              )} */}
            </div>
          </div>

          <div className="row">
            <div className=" col-md-6">
              <div className="grey-box mt-md-5 mt-2">
                <h5>Billing Address</h5>
                <ul className="address-list">
                  <li>Name </li>
                  <li>{orderDetail?.billing_name}</li>
                  <li>Address </li>
                  <li>{orderDetail?.biling_address}</li>
                  <li>City </li>
                  <li>{orderDetail?.billing_city}</li>
                  <li>State </li>
                  <li>{orderDetail?.billing_state}</li>
                  <li>Country </li>
                  <li> {orderDetail?.billing_country}</li>
                  <li>Zipcode </li>
                  <li> {orderDetail?.billing_ziocode}</li>
                </ul>
              </div>
            </div>
            <div className=" col-md-6">
              <div className="grey-box mt-md-5 mt-2">
                <h5>Shipping Address</h5>
                <ul className="address-list">
                  <li>Name </li>
                  <li> {orderDetail?.delivery_name}</li>
                  <li>Address </li>
                  <li> {orderDetail?.delievry_address}</li>
                  <li>City </li>
                  <li> {orderDetail?.delivery_city}</li>
                  <li>State </li>
                  <li> {orderDetail?.delivery_state}</li>
                  <li>Country </li>
                  <li>{orderDetail?.deivery_country}</li>
                  <li>Zipcode </li>
                  <li> {orderDetail?.delivery_zipcode}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 mb-5">
        <div className="container mb-3 pb-3">
          <div className="row align-items-center">
            <div className="col-lg-12 d-flex justify-content-center justify-content-lg-start dashboard-heading-block">
              <img
                src={ImageObj.PartDetail}
                width="56px"
                height="56px"
                className="mt-2 me-4 d-none d-lg-block"
              />
              <div>
                <h2 className="text-center text-lg-start">Part Details</h2>
               
              </div>
            </div>
          </div>
        </div>
        <div className="container gx-0 grid-data">
          <div className="row">
            <div className="col-lg-12">
              <div className="row gx-0 grid-head-row">
                <div className="col">
                  <span className="data-value">Sr. No.</span>
                </div>
                <div className="col">
                  <span className="data-value">Part Name</span>
                </div>
                <div className="col">
                  <span className="data-value">Unit Cost</span>
                </div>
                <div className="col">
                  <span className="data-value">Quantity</span>
                </div>
                {/* <div className="col">
                  <span className="data-value">Price</span>
                </div> */}

                <div className="col">
                  <span className="data-value">Total Price</span>
                </div>
                <div className="col">
                  <span className="data-value">Status</span>
                </div>
                <div className="col">
                  <span className="data-value">Action</span>
                </div>
              </div>
              {[orderDetail]?.order_product !== null &&
              orderDetail?.order_product?.length ? (
                orderDetail?.order_product.map((res, i) => {
                  return (
                    <React.Fragment>
                      <div className="row gx-0 grid-data-row">
                        <div className="col" data-label="Sr. No.: ">
                          <span className="data-value">{i + 1}</span>
                        </div>
                        <div className="col" data-label="Part Name: ">
                          <span className="data-value">{res.file_name}</span>
                        </div>
                        <div className="col" data-label="Unit Cost: ">
                          <span className="data-value">${res.unit_price}</span>
                        </div>
                        <div className="col" data-label="Quantity: ">
                          <span className="data-value">{res.qty}</span>
                        </div>
                        {/* <div className="col" data-label="Price: ">
                          <span className="data-value">${res.price}</span>
                        </div> */}
                        <div className="col" data-label="Total: ">
                          <span className="data-value">${res.total_price}</span>
                        </div>
                        <div className="col" data-label="Status: ">
                          <span className="data-value">{res.order_status}</span>
                        </div>
                        
                        {/* {// console.log(orderDetail_id)} */}

                        <div className="col" data-label="Action: ">


                        <FaEye
                          className="fs-5 fw-normal ms-2 pointer"
                          onClick={() => {handleviewattachment(res?.attachments)}}
                          title="Attachment Data"
                        />


                        <a
                        //  target={'_blank'}
                        download
                         title="Download 3d File"
                         href={res?.auto_cad_file_path}
                        // onClick={()=> handleDownload(res?.auto_cad_file_path,res?.file_name)}
                         className="icons-action" 
                        //  rel="noreferrer" 
                       >
                         
                         <FaCube className="text-primary fs-5 fw-normal ms-2 pointer"/>
                       </a>

                        {res.order_status === "Delivered" && res?.is_review_exist !== 1 ? <span className="data-value pointer"
                           onClick={()=>{handleShow();setreview({order_id:orderDetail.id,part_id:res.id});setpartname(res.file_name)}}>
                            <MdFeedback className='fs-5 fw-normal ms-2 pointer' /></span> : ''
                        }

                        {res.order_status === "Order Received" && (
                            <span
                              title="Cancel Order"
                              onClick={() => {
                                cancelModalFunc(orderDetail_id);
                                setpartid(res?.id);
                              }}
                            >
                              <FaRegTimesCircle className="fs-5 fw-normal ms-2 pointer" />
                            </span>
                        )}
                          
                          {res.order_status === "Delivered" && (
                            <span
                              title="Return Order"
                              onClick={() => {returnModalFunc(res.id);
                                setpartid(res?.id);}}
                            >
                              <FaRedoAlt className="fs-5 fw-normal ms-2 pointer" />
                            </span>
                          )}

                          {res?.order_status_history?.find(state => state?.status === "Order Shipped" && state?.is_complete === 1) && (res?.shipment_tracking_number != null) ? 
                          <FaTruck className="fs-5 fw-normal ms-2 pointer"  
                          onClick={()=>{getTrackingData({
                            shipment_tracking_number: orderDetail.order_product[i].shipment_tracking_number
                          });}} 

                          title="Track Your Product"/> 
                          :''
                          }


                        </div>
                      </div>

                      <ul className="m-0 p-0 d-flex align-items-center flex-wrap bg-white p-4 mt-3 mb-3 grey-box">
                        {res?.manufacturing_process && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">
                              Manufacturing Process
                            </small>
                            :
                            <p className="mb-0 ms-1 me-3 new">
                              
                              {res?.manufacturing_process}
                            </p>
                          </li>
                        )}
                        {res?.custom_manufacturing_process && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">
                              Custom Manufacturing Process
                            </small>
                            :
                            <p className="mb-0 ms-1 me-3 new">
                              
                              {res?.custom_manufacturing_process}
                            </p>
                          </li>
                        )}
                        {res?.material && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Material </small> :
                            <p className="mb-0 ms-1 me-3 new">
                              
                              {res?.material}
                            </p>
                          </li>
                        )}
                        {res?.custom_material && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Custom Material Value </small> :
                            <p className="mb-0 ms-1 me-3 new">
                              
                              {res?.custom_material}
                            </p>
                          </li>
                        )}
                        {res?.finish && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Coating </small> :
                            <p className="mb-0 ms-1 me-3 new"> {res?.finish}</p>
                          </li>
                        )}
                        {res?.custom_finish && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="mb-0">Custom Coating </small> :
                            <p className="mb-0 ms-1 me-3 new">
                              
                              {res?.custom_finish}
                            </p>
                          </li>
                        )}
                        {res?.threads && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Threads </small> :
                            <p className="mb-0 ms-1 me-3 new">
                              
                              {res?.threads}
                            </p>
                          </li>
                        )}
                        {res?.tolerance && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Tolerance </small> :
                            <p className="mb-0 ms-1 me-3 new">
                              
                              {res?.tolerance}
                            </p>
                          </li>
                        )}
                        {res?.surface_roughness && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Surface Roughness </small> :
                            <p className="mb-0 ms-1 me-3 new">
                              
                              {res?.surface_roughness}
                            </p>
                          </li>
                        )}
                        {res?.part_marking && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Part Marking </small> :
                            <p className="mb-0 ms-1 me-3 new">
                              
                              {res?.part_marking}
                            </p>
                          </li>
                        )}
                        {res?.inspection && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Inspection </small> :
                            <p className="mb-0 ms-1 me-3 new">
                              
                              {res?.inspection}
                            </p>
                          </li>
                        )}
                        {res?.custom_inspection && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Custom Inspection </small> :
                            <p className="mb-0 ms-1 me-3 new">
                              {res?.custom_inspection}
                            </p>
                          </li>
                        )}
                        {res?.part_qualifications_certifications && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">
                              Part Quality Certifications
                            </small>
                            :
                            <p className="mb-0 ms-1 me-3 new">
                              
                              {res?.part_qualifications_certifications}
                            </p>
                          </li>
                        )}
                        {res?.material_certifications && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">
                              Material Certifications
                            </small>
                            :
                            <p className="mb-0 ms-1 me-3 new">
                              {res?.material_certifications}
                            </p>
                          </li>
                        )}
                        {res?.cancel_reason && (
                          <div className="w-100 grey-box">
                            <p className="small">
                              <strong>{res?.order_status === "Cancellation Requested" ? "Cancel Reason:" : "Return Reason:"} </strong>
                               {res?.cancel_reason}
                            </p>
                          </div>
                        )}

                        {/* <div <class>Name="steps step_customer">
                          {res?.order_status_history.map((resss, index) => (
                            <div className="step mb-md-0 mb-3  ">
                              <div
                                className={`step__title ${
                                  resss?.is_complete === 1 ? "active" : ""
                                } `}
                              >
                                {resss?.status}
                              </div>
                            </div>
                          ))}
                        </div> */}
                        
                        { ['Cancellation Requested','Cancelled'].includes(res?.order_status) 
                        ?
                        <div className="steps cancle-steps step_customer">
                          {res?.order_status_history.map((resss, index) => (
                            <>
                              {index === 0 || index === 6 || index === 7 ?
                              <div className="step mb-md-0 mb-3  ">
                              <div
                                className={`step__title tooltipzzz ${
                                  resss?.is_complete === 1 ? "active" : ""
                                } `}
                              >
                               <div className="tooltipzzz">
                               {resss?.status}
                               
                               {resss?.is_complete === 1
                               ?
                                  <span className="tooltiptext">
                                  Date: {moment(resss?.date).format("MM/DD/yyyy")}
                                  <br />
                                  Time:{moment(resss?.date).format("HH:mm")}
                                </span>
                                :
                                null
                              }
                               </div>
                              </div>
                            </div>
                            : 
                            null
                            }
                            </>
                          ))}
                        </div>
                        :
                        (
                          ['Return Requested','Returned'].includes(res?.order_status) 
                          ?
                          <div className="steps cancle-steps step_customer">
                          {res?.order_status_history.map((resss, index) => (
                            <>
                              {index === 0 || index === 8 || index === 9 ?
                              <div className="step mb-md-0 mb-3  ">
                              <div
                                className={`step__title tooltipzzz ${
                                  resss?.is_complete === 1 ? "active" : ""
                                } `}
                              >
                               <div className="tooltipzzz">
                               {resss?.status}
                               
                               {resss?.is_complete === 1
                               ?
                                  <span className="tooltiptext">
                                  Date: {moment(resss?.date).format("MM/DD/yyyy")}
                                  <br />
                                  Time:{moment(resss?.date).format("HH:mm")}
                                </span>
                                :
                                null
                              }
                               </div>
                              </div>
                            </div>
                            : 
                            null
                            }
                            </>
                          ))}
                        </div>
                          :
                          <div className="steps step_customer">
                          {res?.order_status_history.map((resss, index) => (
                            <div>
                              {index < 6 ?
                              <div className="step mb-md-0 mb-3  ">
                              <div
                                className={`step__title tooltipzzz ${
                                  resss?.is_complete === 1 ? "active" : ""
                                } `}
                              >
                               <div className="tooltipzzz">
                               {resss?.status}
                               
                               {resss?.is_complete === 1
                               ?
                                  <span className="tooltiptext">
                                  Date: {moment(resss?.date).format("MM/DD/yyyy")}
                                  <br />
                                  Time:{moment(resss?.date).format("HH:mm")}
                                </span>
                                :
                                null
                                }
                               </div>
                              </div>
                            </div>
                            : 
                            null
                            }
                            </div>
                          ))}
                        </div>
                        )
                        }
                        
                      </ul>

                      {res.order_status === "Delivered" &&
                      res?.review !== null  && res?.is_review_exist === 1 ? (
                        <div className="m-0 p-0 bg-white p-4 mt-3 mb-3 grey-box">
                         <div className="row">
                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-6">
                              <h5>Your Ratings: </h5>
                              </div>
                              <div className="col-md-6">
                                <small>
                                  {moment(
                                    res?.review?.review_date
                                  ).format("MM/DD/yyyy")}
                                </small>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <p>
                                  <b>Quality:</b>
                                </p>
                              </div>
                              <div className="col-md-6">
                                <span> {stars(res?.review?.qulity)} </span>
                              </div>

                              <div className="col-md-6">
                                <p>
                                  <b>On Time Delivery:</b>
                                </p>
                              </div>
                              <div className="col-md-6">
                                <span> {stars(res?.review?.on_time_delivery)} </span>
                              </div>

                              <div className="col-md-6">
                                <p>
                                  <b>Communication:</b>
                                </p>
                              </div>
                              <div className="col-md-6">
                                <span> {stars(res?.review?.communication)} </span>
                              </div>

                              <div className="col-md-6">
                                <p>
                                  <b>Engagement:</b>
                                </p>
                              </div>
                              <div className="col-md-6">
                                <span> {stars(res?.review?.engagement)} </span>
                              </div>
                            </div>
                          </div>
                         </div>
                          <div className="mt-3">
                            <h5>Reviews: </h5>
                            <p style={{ textAlign: "justify" }}>
                              {res?.review?.reviews}
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <span className="no-data fs-1 fw-bolder text-uppercase opacity-25 text-dark">
                  No Parts Found
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="container gx-0 grid-data">
          <div className="row">
            <div className="col-lg-12">
              <div className="total-box">
                <div className="row gx-0">
                  <div className="col-6 new-grid pe-sm-4 ps-sm-4 pe-2 ps-2">
                    <span className="data-value">Sub Total</span>
                  </div>
                  <div className="col-6 new-grid pe-sm-4 ps-sm-4 pe-2 ps-2 text-end">
                    <span className="data-value d-block w-100">
                      ${orderDetail?.product_price}
                    </span>
                  </div>
                </div>
                <div className="row gx-0">
                  <div className="col-6 new-grid pe-sm-4 ps-sm-4 pe-2 ps-2">
                    Shipping
                  </div>
                  <div className="col-6 new-grid pe-sm-4 ps-sm-4 pe-2 ps-2 text-end">
                    <span className="d-block w-100">
                      ${orderDetail?.shipping_price}
                    </span>
                  </div>
                </div>
                <div className="row gx-0">
                  <div className="col-6 new-grid pe-sm-4 ps-sm-4 pe-2 ps-2">
                    Discount
                  </div>
                  <div className="col-6 new-grid pe-sm-4 ps-sm-4 pe-2 ps-2 text-end">
                    <span className="d-block w-100">
                      
                      ${orderDetail?.discounted_amt}
                    </span>
                  </div>
                </div>
                <div className="row gx-0">
                  <div className="col-6 new-grid pe-sm-4 ps-sm-4 pe-2 ps-2">
                    <span className="bold-text">Total</span>
                  </div>
                  <div className="col-6 new-grid pe-sm-4 ps-sm-4 pe-2 ps-2 text-end">
                    <span className="bold-text d-block w-100">
                      ${orderDetail?.final_amt}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
{/* modal for tracking no  */}
      <Modal show={trackingShow} onHide={()=>setTrackingShow(!trackingShow)}>
        <Modal.Header closeButton className="border-0 pb-0  pt-3">
            <Modal.Title className="fs-5 pe-0 ps-0 pe-sm-4 ps-sm-2 ">
              Track Your Request 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0 pe-0 ps-0 pe-sm-4 ps-sm-4 ">
          {trackingDetails?.length===0 || trackingDetails=== null ? 'No updated tracking information available. Please check back.':'No updated tracking information available. Please check back.'}
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0 pb-3 pe-0 ps-0 pe-sm-4 ps-sm-4 ">
            <button
              type="button"
              className="normal-height btn btn-primary-orange border-radius-0"
              onClick={()=>setTrackingShow(!trackingShow)}>
              Close
            </button>
          </Modal.Footer>
      </Modal>
      {/* send feedback */}
      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit(AddReview)}>
          <Modal.Header closeButton className="border-0 pb-0  pt-3">
            <Modal.Title className="fs-5 pe-0 ps-0 pe-sm-4 ps-sm-2 ">
              Your Rating For Part - {partname}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0 pe-0 ps-0 pe-sm-4 ps-sm-4 ">
            <div className="form-group">
              <div className="row">
                <div className="col-md-4">
                  <label>Quality</label>
                </div>
                <div className="col-md-8">
                    <Rating
                      onClick={(e) => {setqulity(e)}}
                      showTooltip
                      allowHalfIcon={false} /* Available Props */
                      tooltipDefaultText='0'
                    />
                </div>
                <div className="col-md-4">
                    <label>On Time Delivery</label>
                </div>
                <div className="col-md-8">
                    <Rating
                      onClick={(e) => {seton_time_delivery(e)}}
                      showTooltip
                      allowHalfIcon={false} /* Available Props */
                      tooltipDefaultText='0'
                    />
                </div>
                <div className="col-md-4">
                    <label>Communication</label>
                </div>
                <div className="col-md-8">
                    <Rating
                      onClick={(e) => {setcommunication(e)}}
                      showTooltip
                      allowHalfIcon={false} /* Available Props */
                      tooltipDefaultText='0'
                    />
                </div>
                <div className="col-md-4">
                    <label>Engagement</label>
                </div>
                <div className="col-md-8">
                    <Rating
                      onClick={(e) => {setengagement(e)}}
                      showTooltip
                      allowHalfIcon={false} /* Available Props */
                      tooltipDefaultText='0'
                    />
                </div>
              </div>
            </div>
            <div className="form-group">
              <textarea
                {...register("reviews", { required: true })}
                required
                className="form-control"
                placeholder="Please Enter Your Feedback"
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0 pb-3 pe-0 ps-0 pe-sm-4 ps-sm-4 ">
            <button
              type="button"
              className="normal-height btn btn-primary-orange border-radius-0"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              type="submit"
              className="normal-height btn btn-primary-orange border-radius-0"
            >
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* part attachment model */}

      <Modal
        className="status-modal"
        animation={false}
        show={mydata}
        onHide={() => {
          setmydata(!mydata);
          setAttachmentData([]);
        }}
      >
        <div className="position-relative">
          <Modal.Body>
            <div className="row text-start">
              <div className="col-12">
                <div className="row gx-0 grid-head-row mb-3">
                  <div className="col-3">
                    <span className="data-value">Sr. No.</span>
                  </div>
                  <div className="col-6">
                    <span className="data-value">File Name</span>
                  </div>
                  <div className="col-3 text-center">
                    <span className="data-value">Action</span>
                  </div>
                </div>
                <hr />
                {attachmentData.length > 0 ? (
                  attachmentData.map((item, i) => (
                    <div className="row p-0 mb-3 flex align-items-center text-start">
                      <div className="col-3" data-label="Sr. No.: ">
                        <span className="data-value">{i + 1}</span>
                      </div>
                      <div className="col-6" data-label="Image">
                        <span
                          className="data-value"
                          style={{ textAlign: "justify" }}
                        >
                          {item.split("/uploads/quote_attachments/")[1]}
                        </span>
                      </div>
                      <div className="col-3 text-center" data-label="Sr. No.: ">
                        <span className="data-value" title="Download Image">
                          <a target={"_blank"} href={item} download={item}>
                            <FaDownload />
                          </a>
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">No Other Attachments available for this Part.</div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="theme-btn btn-group-right text-end">
              <Button
                variant="primary"
                onClick={() => {
                  setmydata(!mydata);
                  setAttachmentData([]);
                }}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>

      <CancelOrder
        show={modalCancelShow}
        onHide={() => setModalCancelShow(false)}
        orderlist={getOrderdetails}
        orderid={orderDetail_id}
        part_id={partid}
      />
      <ReturnOrder
        show={modalReturnShow}
        onHide={() => setModalReturnShow(false)}
        orderlist={getOrderdetails}
        orderid={orderDetail_id}
        part_id={partid}
      />
    </React.Fragment>
  );
};

export default OrderDetails;
