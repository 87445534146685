
import React, { useState, useEffect, useCallback } from 'react'
import { Link } from "react-router-dom"
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from 'react-phone-number-input/input'
import { Button, Spinner, Modal } from 'react-bootstrap'


import { Form } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";

import { getMethodDataUser, postMethodDataUser } from '../../../../../admin/adminApi/api';

import { modalToggelValue, modalRgisterlValue } from '../../../../../../redux/actions/action';
import { useDispatch } from 'react-redux';

import FormValidation from '../../../../../../formValidation/formValidation';

const AddAddress = (props) => {

    const stateList = [
        { name: "Alabama", abbreviation: "AL" },
        { name: "Alaska", abbreviation: "AK" },
        { name: "Arizona", abbreviation: "AZ" },
        { name: "Arkansas", abbreviation: "AR" },
        { name: "California", abbreviation: "CA" },
        { name: "Colorado", abbreviation: "CO" },
        { name: "Connecticut", abbreviation: "CT" },
        { name: "Delaware", abbreviation: "DE" },
        { name: "Florida", abbreviation: "FL" },
        { name: "Georgia", abbreviation: "GA" },
        { name: "Hawaii", abbreviation: "HI" },
        { name: "Idaho", abbreviation: "ID" },
        { name: "Illinois", abbreviation: "IL" },
        { name: "Indiana", abbreviation: "IN" },
        { name: "Iowa", abbreviation: "IA" },
        { name: "Kansas", abbreviation: "KS" },
        { name: "Kentucky", abbreviation: "KY" },
        { name: "Louisiana", abbreviation: "LA" },
        { name: "Maine", abbreviation: "ME" },
        { name: "Maryland", abbreviation: "MD" },
        { name: "Massachusetts", abbreviation: "MA" },
        { name: "Michigan", abbreviation: "MI" },
        { name: "Minnesota", abbreviation: "MN" },
        { name: "Mississippi", abbreviation: "MS" },
        { name: "Missouri", abbreviation: "MO" },
        { name: "Montana", abbreviation: "MT" },
        { name: "Nebraska", abbreviation: "NE" },
        { name: "Nevada", abbreviation: "NV" },
        { name: "New Hampshire", abbreviation: "NH" },
        { name: "New Jersey", abbreviation: "NJ" },
        { name: "New Mexico", abbreviation: "NM" },
        { name: "New York", abbreviation: "NY" },
        { name: "North Carolina", abbreviation: "NC" },
        { name: "North Dakota", abbreviation: "ND" },
        { name: "Ohio", abbreviation: "OH" },
        { name: "Oklahoma", abbreviation: "OK" },
        { name: "Oregon", abbreviation: "OR" },
        { name: "Pennsylvania", abbreviation: "PA" },
        { name: "Rhode Island", abbreviation: "RI" },
        { name: "South Carolina", abbreviation: "SC" },
        { name: "South Dakota", abbreviation: "SD" },
        { name: "Tennessee", abbreviation: "TN" },
        { name: "Texas", abbreviation: "TX" },
        { name: "Utah", abbreviation: "UT" },
        { name: "Vermont", abbreviation: "VT" },
        { name: "Virginia", abbreviation: "VA" },
        { name: "Washington", abbreviation: "WA" },
        { name: "West Virginia", abbreviation: "WV" },
        { name: "Wisconsin", abbreviation: "WI" },
        { name: "Wyoming", abbreviation: "WY" },
      ];


    const dispatch = useDispatch()

    const [mobileErr, setMobileErr] = useState([""])
    const [emailErr, setEmailErr] = useState([""])
    const [modalLoginShow, setModalLoginShow] = React.useState(false);
    const [value, setValue] = useState()



    // form validation rules
    const validationSchema = Yup.object().shape({
        postal_code: Yup.string()
        .min(5)
        .required('A Postal Code is required')
        .max(5)
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    // const dispatch = useDispatch()
    // const PORT = PORTNUMBER.PORTNUMBER

    const USERTOKEN = localStorage.getItem("userToken")

    const navigate = useNavigate()

    const [passwordErr, setPassErr] = useState()

    const [checkedValue, setCheckedValue] = useState(true)

    const [visibility, setVisibility] = useState(true)
    const [loading, setLoading] = useState(false)
    const [addressDataEdit, setAddressDataEdit] = useState()




    const [visibilityConfirm, setVisibilityConfirm] = useState(true)
    const FormValidationNew = FormValidation

    const [formValidation, setFormValidation] = useState({
        Email: "",
        Mobile: "",
        default: "",
        Phone: "",
        setPassErrFormat: "",
        Success: null
    })


    useEffect(() => {
        setValue("+1" + String(addressDataEdit?.phone).replace(/[()\ \s-]+/g, ''))
    }, [addressDataEdit])


    const dataUser =  async()=>{
       
            await getMethodDataUser(props.editaddress, USERTOKEN).then((res) => {
    
                setAddressDataEdit(res.data.result.data)
    
            })
        

     
    }



    useEffect(() => {
        if(
            props.addressedit
        ){
        dataUser()  }
       
    }, [props.addressedit])



    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }


    useEffect(() => {
        reset()
    }, [props])



    const onSubmit = (data) => {

        setLoading(true)


        var fd = new FormData();

        var inputs = document.getElementsByClassName('form-control');
        const mob = parseInt(data.mobile)

        for (let i = 0; i < inputs.length; i++) {

            fd.append(inputs[i].name, inputs[i].value);
        }

        postMethodDataUser(props.address, data?.length ? data : fd, USERTOKEN)
            .then((res) => {
            
                if (res?.data?.status === true) {
                    setLoading(false)
                    toast(res?.data?.message)
                    props.onHide()
                    props.shipping()
                    props.billing()
                    navigate("/customer/editprofile")
                    reset()
                    dispatchToggle()
                } else {
                    setLoading(false)
                    setMobileErr(res?.data?.result?.errors?.phone)
                    setEmailErr(res?.data?.result?.errors?.email)
                    
                    res?.data?.result?.errors?.map((res)=>{
                        return(
                            toast.error(res.message)
                        )
                    })




                }

           

            }).catch((error) => {
                // console.log(error);
            })





        return false;

    };



    useEffect(() => {
        FormValidationNew()
    }, [FormValidationNew, formOptions])

    const dispatchToggle = () => {
        dispatch(modalToggelValue(false))
        dispatch(modalRgisterlValue(false))
    }

    return (
        <React.Fragment>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <div className="modal-content">
                    <div className="create-account">
                        <div className="row">
                            <div className="col-md-12 position-relative;">
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => props.onHide()}
                                />
                                <h3 className="text-center m-0 py-3">
                                    {props.apititle}  Address
                                </h3>

                                <small className=" form-text d-block text-center  text-muted">{formValidation.default}</small>
                                <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Address Name</label>
                                                <input type="text" defaultValue={addressDataEdit?.address_name !== undefined ? addressDataEdit?.address_name : ""} maxLength="200" className="form-control" id="validationCustom01"  {...register('address_name', { required: false })} placeholder="Address Name" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Customer Address Name.
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Contact Name</label>
                                                <input type="text" defaultValue={addressDataEdit?.contact_name !== undefined ? addressDataEdit?.contact_name : ""} maxLength="100" className="form-control" id="validationCustomNew02"  {...register('contact_name', { required: false })} placeholder="Contact Name" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Contact Name.
                                                </div>

                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Address 1</label>
                                                <input type="text" defaultValue={addressDataEdit?.address !== undefined ? addressDataEdit?.address : ""} maxLength="40" className="form-control" id="validationCustomNew04"  {...register('address', { required: false })} placeholder="Address 1" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Address 1.
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Address 2</label>
                                                <input type="text" defaultValue={addressDataEdit?.street !== undefined ? addressDataEdit?.street : ""} className="form-control" id="validationCustomNew05"  {...register('street', { required: false })} placeholder="Address 2" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Address 2.
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>City</label>
                                                <input type="text" defaultValue={addressDataEdit?.city !== undefined ? addressDataEdit?.city : ""} maxLength="100" className="form-control" id="validationCustomNew10"  {...register('city', { required: false })} placeholder="City" required />
                                                <div className="invalid-feedback">
                                                    Please Enter City.
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>State</label>

                                                {/* <input 
                                                type="text" 
                                                defaultValue={addressDataEdit?.state !== undefined ? addressDataEdit?.state : ""} 
                                                maxLength="100" 
                                                className="form-control" 
                                                id="validationCustomNew06"  
                                                {...register('state', { required: false })}
                                                 placeholder="State" required /> */}
                                                <select 
                                                id="validationCustomNew06" 
                                                {...register('state', { required: false })} 
                                                name='state' 
                                                required={true} 
                                                defaultValue={addressDataEdit?.state !== undefined ? addressDataEdit?.state : ""} 
                                                className="form-control" 
                                                >
                                                  <option className='d-none' value={addressDataEdit?.state !== undefined ? addressDataEdit?.state : ""}>
                                                    {addressDataEdit?.state ? 
                                                    stateList.find(state => state.abbreviation === addressDataEdit.state)?.name : 
                                                    "State"
                                                    }
                                                </option>
                                                {stateList?.map((data,index) => (
                                                    <option value={data?.abbreviation} key={index}>
                                                    {data?.name}
                                                    </option>
                                                ))}
                                                
                                                </select>

                                                <div className="invalid-feedback">
                                                    Please Enter state.
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Zipcode</label>
                                                <input type="text" defaultValue={addressDataEdit?.postal_code !== undefined ? addressDataEdit?.postal_code : ""} maxLength="15" className="form-control" id="validationCustomNew07"  {...register('postal_code', { required: false })} placeholder="Zipcode" required />
                                        <small className='text-danger'> {errors.postal_code?.message}</small>

                                                <div className="invalid-feedback">
                                                    Please Enter Zipcode.
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Country</label>
                                                <input type="text" defaultValue="United States" maxLength="100" className="form-control" id="validationCustomNew08"  {...register('country', { required: false })} placeholder="country" disabled />
                                                <div className="invalid-feedback">
                                                    Please Enter Country.
                                                </div>

                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Fax</label>
                                                <input type="text" defaultValue={addressDataEdit?.fax !== undefined ? addressDataEdit?.fax : ""} maxLength="100" className="form-control" id="validationCustomNew09"  {...register('fax', { required: false })} placeholder="fax" />

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Phone</label>

                                                <Input

                                                    className="form-control"
                                                    minLength={14}
                                                    maxLength={14}
                                                    value={value}
                                        
                                                    defaultCountry="US"
                                                    placeholder="Enter phone number"
                                                    {...register('phone', { required: false })}
                                                    required
                                                />
                                                <small className='font-12 d-block'>E.g (xxx) xxx-xxxx , No Need for Country Code</small>
                                                <small className='text-danger'> {errors.phone?.message}</small>
                                                <small className="form-text text-muted">{mobileErr}</small>

                                                <div className="invalid-feedback">
                                                    Please enter mobile number.
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-12 text-center">

                                            {

                                                loading === false ?
                                                    <button type="submit" className="btn btn-primary-orange m-auto  mt-3">{props.buttontitle}</button>:

                                                    <Button className='btn  spinner btn-primary-orange m-auto  mt-3' variant='dark' disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className='me-2'
                                                    />
                                                    Please Wait...
                                                </Button>
                                        }

                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>



            </Modal>

        </React.Fragment>
    )
}
export default AddAddress;