
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { useForm, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap'
import {Button, Spinner} from 'react-bootstrap'

import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import Multiselect from "multiselect-react-dropdown";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Input from 'react-phone-number-input/input'

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import FormValidation from '../../../../formValidation/formValidation'
import { getMethodDataSupplier, postMethodData } from '../../adminApi/api';




const AddNewSupplier = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;


    const [value, setValue] = useState()
    const [capabilityData, setCapabilityData] = useState([])

    // form validation rules
    const validationSchema = Yup.object().shape({
        password: Yup.string().required("Password is required")
        .min(6, 'Password is too short - should be 6 chars minimum.')
        .max(18, 'Password is too long - should be 18 chars minimum.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
            "Please Check Password Format"
        ),

        confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match").min(6, 'Confirm password is too short - should be 6 chars minimum.').max(18, 'Password is too long - should be 18 chars minimum.')
            .required("Confirm Password is required"),
            email: Yup.string().email('The email must be a valid email. '),
            phone: Yup.string()
            .min(14)
            .required('A phone number is required'),
            zipcode: Yup.string()
            .min(5)
            .required('A zipcode is required')
            .max(5),
            image: Yup.mixed().required('Image file is required')
            .test("type", "Only .pdf Formate is accepted", (value) => { 
                return value && (
                value[0].type === 'application/pdf'
                );
            })
    }
    );

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    // const dispatch = useDispatch()
    // const PORT = PORTNUMBER.PORTNUMBER

    const Token = localStorage.getItem("token")
    const navigate = useNavigate()


    const [passwordErr, setPassErr] = useState()
    const [checkedValue, setCheckedValue] = useState(true)
    const [mobileErr, setMobileErr] = useState([""])
    const [emailErr, setEmailErr] = useState([""])
    const [loading, setLoading] = useState(false)
    const [manufacturing_process, setmanufacturing_process] = useState();
    const [tolerance, setolerence] = useState();
    const [Surface_roughness, setSurface_roughness] = useState();
    const [Inspection, setInspection] = useState();
    const [qualifications, setqualifications] = useState();
    const [Material , setMaterial ] = useState();

    const [visibility, setVisibility] = useState(true)

    const [visibilityConfirm, setVisibilityConfirm] = useState(true)
    const FormValidationNew = FormValidation

    const [formValidation, setFormValidation] = useState({
        Email: "",
        Mobile: "",
        default: "",
        Phone: "",
        setPassErrFormat: "",
        Success: null
    })







    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }


    const TechnicalCapabilities = (e) => {
        getMethodDataSupplier(`/get-technical-capabilities`, Token).then((res)=>{
            setCapabilityData(res.data.result.data)
        })
    }

    useEffect(()=>{
        TechnicalCapabilities()
    },[])

    const onSubmit = (data) => {

        // // console.log("=====.>>>>>",data)
     
            setLoading(true)

            const imageFiles = document.getElementById('document').files[0];

            // // console.log(imageFiles)



            const fs = new FormData();
            for (var key in data) {
                fs.append(key, data[key]);
            }
            fs.append("image", imageFiles);
            fs.append("type", "member");
            fs.append("manufacturing_process", manufacturing_process);
            fs.append("Surface_roughness", Surface_roughness);
            fs.append("tolerance", tolerance);
            fs.append("Inspection", Inspection);
            fs.append("part_qualifications_certifications", qualifications);
            fs.append("material_certifications", Material);
            fs.append("manufacturing_process", manufacturing_process);

            postMethodData("/suppliers/store", fs, Token)
                .then((res) => {
        
                    if (res?.data?.status === true) {
                        setLoading(false)
                        toast(res?.data?.message)
                        navigate("/admin/supplier-management")
                        reset()
                    } else {
                        setLoading(false)
                        setMobileErr(res?.data?.result?.errors?.phone)
                        setEmailErr(res?.data?.result?.errors?.email)
                        toast.error("Somthing Went Wrong!")


                    }

                    setLoading(false)


                }).catch((error) => {
                    // console.log(error);
                })

 


        return false;

    };


    useEffect(() => {
        FormValidationNew()

    }, [FormValidationNew])
    const removePlayer = async (arr, element) => {
        for (let i = 0; i < arr.length; i++) {
            if(arr[i] == element) {
                arr.splice(i, 1);
                // console.log(arr)
                // setmanufacturing_process(arr);      
                break;
            }
        }
    }
    
    return (
        <div className="tables-field">
            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="main-head">
                                <h4>Add New Supplier</h4>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="theme-btn text-end top-head">
                                <Link to="/admin/supplier-management" className="btn">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                        <small className=" form-text d-block text-center mb-4 text-muted">{formValidation.default}</small>
                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" maxLength="15" className="form-control" id="validationCustom01"  {...register('first_name', { required: true })} placeholder="First Name" required />
                                        <div className="invalid-feedback">
                                            Please Enter Supplier First Name.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" maxLength="15" className="form-control" id="validationCustom01"  {...register('last_name', { required: true })} placeholder="Last Name" required />
                                        <div className="invalid-feedback">
                                            Please Enter Supplier Last Name.

                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="email" maxLength="100" className="form-control" id="validationCustom02" {...register('email', { required: true })} placeholder="Email" required />
                                        <small className='text-danger'> {errors.email?.message}</small>
                                        <small className="form-text text-muted">{emailErr}</small>
                                        <div className="invalid-feedback">
                                            Please use @ for a valid email.
                                        </div>
                            
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <Input
                                            minLength={14}
                                            maxLength={14}
                                            defaultCountry="US"
                                            placeholder="Enter phone number"
                                            {...register('phone', { required: true })}
                                            value={value}
                                            onChange={setValue}
                                            required />

                                              {/* <input type="number" maxLength="14" className="form-control" name="phone" id="validationCustomNew1"  {...register('phone', { required: true })} placeholder="Mobile" required /> */}
                                      
                                        <small className='font-12 d-block'>E.g (xxx) xxx-xxxx , No Need for Country Code</small>
                                        <small className='text-danger'> {errors.phone?.message}</small>
                                        <small className="form-text text-muted">{mobileErr}</small>
                                        <div className="invalid-feedback">
                                            Please enter mobile number.
                                        </div>
                                        

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="number" maxLength="6" className="form-control" id="validationCustomnew2" {...register('zipcode', { required: true })} placeholder="Zipcode"  required/>
                                        <small className='text-danger'> {errors.zipcode?.message}</small>
                                        <div className="invalid-feedback">
                                            Please enter zipcode.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" maxLength="40" className="form-control" id="validationCustomnew4" {...register('company_name', { required: true })} placeholder="Company" required />
                                        <div className="invalid-feedback">
                                            Please enter company.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group icon-position">
                                        <input type={visibility ? "password" : "text"} className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="password" name="password" minLength="6" maxLength="18" {...register('password', { required: true }
                                        )} placeholder="password" required />
                                        {
                                            visibility ?
                                                <span className="icon" onClick={() => pwVisibility(false)}>
                                                    <FaEyeSlash />
                                                </span> :
                                                <span className="icon" onClick={() => pwVisibility(true)}>
                                                    <FaEye />
                                                </span>
                                        }
                                        <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>
                                        <small className='text-danger'> {errors.password?.message}</small>
                                        {/* {errors.password?.message} */}
                                        <div className="invalid-feedback">
                                            Please enter valid password.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group icon-position password-field">
                                        <input type={visibilityConfirm ? "password" : "text"} name="confirm_password" minLength="6" maxLength="18" className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`} id="validationCustom05" {...register('confirm_password', { required: true })} placeholder="Confirm password" required />
                                        {
                                            visibilityConfirm ?
                                                <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                    <FaEyeSlash />
                                                </span> :
                                                <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                    <FaEye />
                                                </span>
                                        }
                                        <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>
                                        <small className='text-danger'> {errors.confirm_password?.message}</small>
                                        {/* <div className="invalid-feedback">{errors.confirmPassword?.message}</div> */}
                                        <div className="invalid-feedback">
                                            Please enter valid confirm password.
                                        </div>
                                        <small className=" form-text text-muted">{passwordErr}</small>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group w-9">
                                        <div className='file-upload-div' >
                                        <input 
                                        accept="application/pdf" 
                                        className="form-control" 
                                        id="document"
                                        type="file" 
                                        {...register('image', { required: true })} 
                                        name="image"
                                        required />
                                        <span>W-9 File</span>
                                        </div>
                                        <small className='text-danger'> {errors.image?.message}</small>
                                        <div className="invalid-feedback">
                                            Please upload document.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <select className="form-select form-control" {...register('status', { required: false })} id="validationCustom04" aria-label="Default select example" required>
                                            <option className="disabled-value d-none" value=""  >Select status</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                        <div className="invalid-feedback">
                                            Please select status.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <select className="form-select form-control" {...register('is_admin_approved', { required: false })} id="validationCustom04" aria-label="Default select example" required>
                                            <option className="disabled-value d-none" value=""  >Select verification status</option>
                                            <option value="1">Approved</option>
                                            <option value="0">Pending</option>
                                        </select>
                                        <div className="invalid-feedback">
                                            Please select status.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                      <div className="form-group">
                        <label>Manufacturing Process</label>
                        <Multiselect
                        className="cust-select"
                          options={capabilityData?.manufacturing_process} // Options to display in the dropdown
                          // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                          onSelect={(selectedList, selectedItem) => {
                            setmanufacturing_process([selectedList]);
                          }} // Function will trigger on select event
                          onRemove={(selectedList, selectedItem) => {
                            removePlayer();
                          }} // Function will trigger on remove event
                          // displayValue="name" // Property name to display in the dropdown options
                          showCheckbox={true}
                          isObject={false}
                        />
                      </div>
                    </div>
                    {capabilityData?.tolerance &&
                    capabilityData?.tolerance.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tolerance</label>
                          <Multiselect
                          className="cust-select"
                            options={capabilityData?.tolerance} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setolerence([selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer();
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                        </div>
                      </div>
                    )}
                    {capabilityData?.surface_roughness &&
                    capabilityData?.surface_roughness.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Surface roughness</label>
                          <Multiselect
                          className="cust-select"
                            options={ capabilityData?.surface_roughness} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setSurface_roughness([selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer();
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                        </div>
                      </div>
                    )}
                    {capabilityData?.inspection &&
                    capabilityData?.inspection.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Inspection</label>
                          <Multiselect
                          className="cust-select"
                            options={capabilityData?.inspection} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setInspection([selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer();
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                         
                        </div>
                      </div>
                    )}

                    {capabilityData?.part_qualifications_certifications &&
                    capabilityData?.part_qualifications_certifications.length ==
                      0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Part Quality Certifications</label>
                          <Multiselect
                          className="cust-select"
                            options={ capabilityData?.part_qualifications_certifications } // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setqualifications([selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer();
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                        
                        </div>
                      </div>
                    )}

                    {capabilityData?.material_certifications &&
                    capabilityData?.material_certifications.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Material certifications</label>
                          <Multiselect
                          className="cust-select"
                            options={  capabilityData?.material_certifications } // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setMaterial([selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer();
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                         
                        </div>
                      </div>
                    )}
                               
                             

                                {/* <div className="col-md-6">
                                    <div className="form-group before-con no-from">

                                        <Controller
                                            control={control}
                                            name="startDate"

                                            render={({ field }) => (
                                                <DatePicker


                                                    placeholderText='Click to select a date'
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}



                                                />
                                            )}
                                        />

                                    </div>
                                </div> */}

                                {['checkbox'].map((type) => (
                                    <div key={`default-${type}`} className="mb-3">
                                        <Form.Check
                                            checked={checkedValue}
                                            onClick={() => setCheckedValue(!checkedValue)}
                                            {...register('send_welcome_email', { required: false })}
                                            type={type}
                                            id={`default-${type}`}
                                            label={"Send Welcome Email To Supplier With Login Credentials"}

                                        />


                                    </div>
                                ))}

                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-end">
                                        {

                                            loading === false ?
                                                <button type="submit" className="btn">Save</button> :
                                                <Button className='btn  spinner' variant='dark' disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"

                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    Please Wait...
                                                </Button>
                                        }

                                        <Link to="/admin/supplier-management" className="btn">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
export default AddNewSupplier;