import React, { useEffect, useState } from 'react'
import { ImageObj } from '../../../../../../assets/images/imgeObject';
import {  Tab, Col, Nav, Row } from 'react-bootstrap'
import { modalNoFilesValue, modalToggelValue, modalNvigateValue, dropDownValue } from '../../../../../../redux/actions/action';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Upload3dnew from './uploadFile3dnew';

const CreateQuote2new = () => {
  const dispatch = useDispatch()
  const Auth1 = localStorage.getItem("userToken");
  useEffect(()=>{
    dispatch(dropDownValue(""))
  },[])

  const modalOpenNavigate = () => {
    dispatch(modalNvigateValue(true))
    localStorage.setItem("navigateValue", "true")
    dispatch(modalToggelValue(true))
  }
  const BASE_URL = process.env.REACT_APP_BASE_URL_USER;
  // condition in header
  const [modifyData, setModifyData] = useState();
  useEffect(() => {
    axios.get(`${BASE_URL}/user/get-globle-config/QUOTE_MODIFY`).then((req) => {
      setModifyData(req?.data?.result?.data?.value);
      // console.log(req?.data?.result?.data?.value);
    });
  }, []);

  const handleClickScroll = () => {
    const element = document.getElementById('first');
    if (element) {
      element.scrollIntoView({  top: 0, behavior: 'smooth' });
    }
  };

  return (
    <React.Fragment>

      <Tab.Container id="" >
        <Row>
          {!Auth1 &&
            <Col sm={12} className="pt-4 pb-md-5 mb-md-5">
            <Nav variant="pills" className="row justify-content-center">
           
               <Nav.Item className='col-md-6 col-lg-3 pb-lg-0 pb-4' >
                <Link to={window.location.pathname.split("/")[1]==="customer"? "/customer/fabrication":window.location.pathname.split("/")[1]==="supplier"?"/supplier/fabrication":"/fabrication"} className="three-white-box-2 h-100 feature-col d-block pointer text-decoration-none">
                  <div className=" p-3 mb-3" onClick={()=> dispatch(dropDownValue("Fabrication"))}>
                    <img src={ImageObj.FabricationIcon} alt="clock" height={60} />
                    <h4 className="py-2">Fabrication</h4>
                    <p>
                      Welding, forming, and Joining capabilities.
                    </p>
                  </div>
                </Link >
              </Nav.Item>
              {/* {modifyData==="Yes"?null:modifyData==="No"?
              <Nav.Item className='col-md-6 col-lg-3 pb-lg-0 pb-4'>
                <Nav.Link eventKey="first" className="three-white-box-2 h-100 feature-col d-block pointer">

                  <div className=" p-3 mb-3" onClick={()=> dispatch(dropDownValue("3d painting"))}>
                    <img src={ImageObj.Modeling3d} alt="clock" height={60} />
                    <h4 className="py-2">3d painting</h4>
                   
                  </div>
                </Nav.Link>
              </Nav.Item>:null} */}
            
              <Nav.Item className='col-md-6 col-lg-3 pb-lg-0 pb-4' >
                <Link to={window.location.pathname.split("/")[1]==="customer"? "/customer/cnc-machining":window.location.pathname.split("/")[1]==="supplier"?"/supplier/cnc-machining":"/cnc-machining"}  className="three-white-box-2 h-100 feature-col d-block pointer text-decoration-none">

                  <div className=" p-3 mb-3"  onClick={()=> dispatch(dropDownValue("CNC Machining"))}>
                    <img src={ImageObj.FacMatchine} alt="robotic-arm" height={60} />
                    <h4 className="py-2">CNC Machining</h4>
                    <p>
                    3,4, and 5 Axis Milling and Turning, Tight Tolerance machining,Competitive quoting, Parts in as little as 3 days
                    </p>
                  </div>

                </Link>
              </Nav.Item>
              <Nav.Item className='col-md-6 col-lg-3 pb-lg-0 pb-4' >
                <Link to={window.location.pathname.split("/")[1]==="customer"? "/customer/metal-cutting":window.location.pathname.split("/")[1]==="supplier"?"/supplier/metal-cutting":"/metal-cutting"} className="three-white-box-2 h-100 feature-col d-block pointer text-decoration-none">
                  <div className=" p-3 mb-3" onClick={()=> dispatch(dropDownValue("Metal cut & Forming"))}>
                    <img src={ImageObj.LeaserCutting} alt="Location" height={60} />
                    <h4 className="py-2">Metal Cutting</h4>
                    <p>
                    Plasma cutting, Waterjet, Laser cutting
                    </p>
                  </div>
                </Link>
              </Nav.Item>
              {/* {modifyData==="Yes"?null:modifyData==="No"?
              <Nav.Item className='col-md-6 col-lg-3 pb-lg-0 pb-4 pt-md-4' onClick={() => Auth1 !== null ? dispatch(modalNoFilesValue(true)) : modalOpenNavigate()}>
                <Nav.Link eventKey="fourth" className="three-white-box-2 feature-col d-block pointer p-3 d-flex justify-content-center align-items-center mb-3">
                  <h4 className="py-2">Manual quote</h4>
                </Nav.Link>
              </Nav.Item>
              : null} */}
            </Nav>
          </Col>
          }
          <Col sm={12} id="first">
            <Tab.Pane className='show'>
              {/* <p>Please Upload file for CNC Machining Manufacturing Process</p> */}
                <Upload3dnew uploadid={"upload3"} textprop={"CNC Machining"}/>
                <div className="container pe-0 ps-0">
                  <div className="row">
                    
                    {/* <div className="col-lg-12 mt-5 bottom-border pb-4 mb-4">
                      <p className="font-15-3d-page">Supported file types <i className="fa fa-angle-up" aria-hidden="true" /></p>
                      <p className="mb-0 font-14-3d-page">For 3dm, asm, cam360, CATPart, CATProduct, dwg, dxf, f3d, fbx, g, iam, ige, iges, igs, ipt,
                          neu, obj, prt, sab, sat, skp, sldasm, sldprt, smb, smt, ste, step, stl, stp, wire, x_b, x_t, 123dx</p>
                    </div> */}
                  </div>

                </div>
              </Tab.Pane>
          </Col>
        </Row>

      </Tab.Container>
    </React.Fragment>
  )
}

export default CreateQuote2new;