import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { getMethodDataSupplier, getMethodDataUser, postMethodDataUser } from '../../../../../admin/adminApi/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import FormValidation from '../../../../../../formValidation/formValidation';
import Multiselect from 'multiselect-react-dropdown';
import { Button, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import Input from 'react-phone-number-input/input'



const UpdateProfile = (props) => {
  const location = useLocation();

  // const [tolleranceData,setTolleranceData]=useState([]);
  // const [surfaceRoughness ,setSurfaceRoughness]=useState([]);

  const USERTOKEN = localStorage.getItem("userToken")
  const SUPPLIERTOKEN = localStorage.getItem("supplierToken")

  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState([])
  // console.log("==>", userData)
  const [value, setValue] = useState()
  const [capabilityData, setCapabilityData] = useState([])


  const [inspection, setinspection] = useState([])
  const [manufacturing, setmanufacturing2] = useState([])
  const [material, setmaterial] = useState([])
  const [part_qualifications, setpart_qualifications] = useState([])
  const [surface_roughness , setsurface_roughness  ] = useState([])
  const [tolerance_new    , settolerance  ] = useState([])
  const [Coating, setCoating] = useState([]);


  const [inspectionerr, setinspectionerr] = useState("")
  const [manufacturingerr, setmanufacturing2err] = useState("")
  const [materialerr, setmaterialerr] = useState("")
  const [part_qualificationserr, setpart_qualificationserr] = useState("")
  const [surface_roughnesserr , setsurface_roughnesserr  ] = useState("")
  const [tolerance_newerr    , settoleranceerr  ] = useState("")
  const [coatingerr, setcoatingerr] = useState("")

  const adminPath = location.pathname.split("/")[1];


  useEffect(() => {
    setValue("+1" + String(userData.phone).replace(/[()\ \s-]+/g, ''))
  }, [userData])

  const [mobileErr, setMobileErr] = useState([""])
  const [emailErr, setEmailErr] = useState([""])


  // const [manufacturing_process, setmanufacturing_process] = useState();
  // const [tolerance, setolerence] = useState();
  // const [Surface_roughness, setSurface_roughness] = useState();
  // const [Inspection, setInspection] = useState();
  // const [qualifications, setqualifications] = useState();
  // const [Material , setMaterial ] = useState();




  const validationSchema = Yup.object().shape({

    email: Yup.string().email('The email must be a valid email. ')

  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions, { mode: "onChange" });
  const { errors } = formState;
  const { isSubmitting, isDirty, isValid } = formState;

  const FormValidationNew = FormValidation





  const getProfileDetails = useCallback(() => {
    if(props.token){
      getMethodDataUser("/get-profile", props.token).then((res) => {
        // console.log("edit", res)
        setUserData(res?.data?.result?.data)
  
        setinspection(res.data.result.data.technical_capabilities.inspection)
        setmanufacturing2(res.data.result.data.technical_capabilities.manufacturing_process)
        setmaterial(res.data.result.data.technical_capabilities.material_certifications)
        setpart_qualifications(res.data.result.data.technical_capabilities.part_qualifications_certifications)
        setsurface_roughness(res?.data?.result?.data?.technical_capabilities?.surface_roughness)
        settolerance(res?.data?.result?.data?.technical_capabilities?.tolerance)
        setCoating(res.data?.result?.data?.technical_capabilities?.coatings)
     
        
        getMethodDataSupplier(`/get-technical-capabilities`, USERTOKEN || SUPPLIERTOKEN).then((res)=>{
          setCapabilityData(res.data.result.data)
          // console.log('=>>>>>',surface_roughness);
        })
      })
    }
  }, [props.token])

  useEffect(() => {
    getProfileDetails()
  }, [props.token])




  const onSubmit = () => {
    if (manufacturing.length === 0) {
      // alert("Please select values");
      setmanufacturing2err("Please select Manufacturing Process")
      // return;
    }
    if(surface_roughness.length === 0){
      setsurface_roughnesserr("Please select Surface Roughness")
      // alert("Please select values");
      // return;
    }
    if(tolerance_new.length === 0){
      settoleranceerr("Please select Tolerance")
      // alert("Please select values");
      // return;
    }
    if(inspection.length === 0){
      setinspectionerr("Please select Inspection")
      // alert("Please select values");
      // return;
    }
    if(part_qualifications.length === 0){
      setpart_qualificationserr("Please select Part Quality Certifications")
      // alert("Please select values");
      // return;
    }
    if(material.length === 0){
      setmaterialerr("Please select Part Material Certifications")
      // alert("Please select values");
      // return;
    }
    if(Coating.length === 0){
      setcoatingerr("Please select Coating")
      // alert("Please select values");
      // return;
    }

    if(adminPath != "customer"){
      if( manufacturing.length === 0 || surface_roughness.length === 0 || tolerance_new.length === 0 || inspection.length === 0 || part_qualifications.length === 0 || material.length === 0 || Coating.length === 0){
        return;
      }
    }
    
    setLoading(true)
    var fd = new FormData();

    var inputs = document.getElementsByClassName('form-control');

    for (let i = 0; i < inputs.length; i++) {

      fd.append(inputs[i].name, inputs[i].value);
    
      // // console.log("gfggg", inputs[i].name, inputs[i].value)

    }

    


    fd.append("manufacturing_process", manufacturing);
    fd.append("surface_roughness", surface_roughness );
    fd.append("tolerance",  tolerance_new);
    fd.append("inspection", inspection);
    fd.append("part_qualifications_certifications",part_qualifications);
    fd.append("material_certifications",material);
    fd.append("coatings", Coating);

// console.log("===>>>",tolerance);

    

    postMethodDataUser(`/update-profile`, fd, props.token)
      .then((res) => {

        if (res?.data?.status === true) {
          setLoading(false)
          getProfileDetails()
          toast(res?.data?.message)
          reset()
        } else {
          setLoading(false)

          setMobileErr(res?.data?.result?.errors?.phone)
          setEmailErr(res?.data?.result?.errors?.email)

          toast.error("Somthing Went Wrong!")


        }
        setLoading(false)



      }).catch((error) => {
        // console.log(error);
      })



    return false;

  };




  useEffect(() => {
    FormValidationNew()
  }, [FormValidationNew, value])
  const removeItems = async (arr, element) => {
    for (let i = 0; i < arr.length; i++) {
        if(arr[i] === element) {
            arr.splice(i, 1);
            // console.log("arr",arr)
          
            
            break;
        }
    }
}


  return (
    <React.Fragment>



      <form className="needs-validation" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>First Name*</label>
              <input type="text" defaultValue={userData.first_name} maxLength="15" id="validationCustom01" className={"form-control"} name="first_name"  {...register('first_name', { required: false })} placeholder="First Name" required />
              <div className="invalid-feedback">
                Please Enter  First Name.
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Last Name*</label>
              <input type="text" defaultValue={userData?.last_name} maxLength="15" className={"form-control"} id="validationCustomNew"   {...register('last_name', { required: false })} placeholder="Last Name" required />
              <div className="invalid-feedback">
                Please Enter  Last Name.
              </div>

            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Email*</label>
              <input type="email" defaultValue={userData.email} disabled={true} maxLength="40" id="validationCustom02" className={"form-control"} name="email" {...register('email', { required: false })} placeholder="Email" required />
              <small className='text-danger'> {errors.email?.message}</small>

              <div className="invalid-feedback">
                Please Enter Valid Email Address.
              </div>

              <small className="form-text text-muted">{emailErr}</small>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <label>Company Name</label>
              <input type="text" defaultValue={userData.company_name} className={"form-control"} id="validationCustomNew3"   {...register('company_name', { required: false })} placeholder="Company Name" />
              <div className="invalid-feedback">
                Please Enter Company Name.
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <label>Mobile*</label>

              {/* <input type="text"  className={ "form-control" } name="phone" defaultValue={editData.phone}   placeholder="Enter phone number" id="validation34"   {...register('phone', { required: false })} required/> */}
              <Input

                className={"form-control"}
                minLength={14}
                maxLength={14}
                value={value}
                onChange={setValue}


                name="phone"
                defaultCountry="US"
                placeholder="Enter phone number"
                {...register('phone', { required: true })}
                required
              />


              <small className='font-12 d-block'>E.g (xxx) xxx-xxxx , No Need for Country Code</small>
              <small className='text-danger'> {errors.phone?.message}</small>
              <div className="invalid-feedback">
                Please enter mobile number.
              </div>

              <small className="form-text text-muted">{mobileErr}</small>
            </div>
          </div>
          {
            userData.technical_capabilities && (adminPath != "customer") &&
            <React.Fragment>
              <div className='col-12'>
                <h4 className='mb-sm-4 mt-sm-3 mb-2 mt-2'>Technical Capabilities</h4>
              </div>
              
              <div className="col-md-6">
                      <div className="form-group">
                        <label>Manufacturing Process*</label>
                        <Multiselect
                        className="cust-select"
                        selectedValues={manufacturing}
                          options={capabilityData?.manufacturing_process} // Options to display in the dropdown
                          // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                          onSelect={(selectedList, selectedItem) => {
                            setmanufacturing2err("");
                            setmanufacturing2([...selectedList]);
                          }} // Function will trigger on select event
                          onRemove={(selectedList,selectedItem) => {
                            removeItems(manufacturing,selectedItem);
                        }}
                          // displayValue="name" // Property name to display in the dropdown options
                          showCheckbox={true}
                          isObject={false}
                        />
                        {manufacturingerr != "" &&
                        <div className='invalid-feedback d-block'>
                          {manufacturingerr}
                        </div>
                        }
                        

                      </div>
                    </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tolerance*</label>
                          <Multiselect
                          className="cust-select"
                            options={capabilityData?.tolerance} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            selectedValues={tolerance_new}
                            
                            // onSelect={(selectedList, selectedItem) => {
                            //   settoleranceerr("");
                            //   settolerance([...selectedList]);
                            //   // console.log("demo",tolerance);
                            //   // console.log("demo",selectedItem);
                            //   if(selectedItem===capabilityData?.tolerance[capabilityData?.tolerance.length-1]){
                            //     selectedList=capabilityData?.tolerance;
                               
                            //     settolerance([...capabilityData?.tolerance])
                            //     // document.getElementById("pepega").children[1].childNodes[0].childNodes.forEach(i=>i.setAttribute('checked','true'));
                            //   }
                            //   if(selectedItem!==capabilityData?.tolerance[capabilityData?.tolerance.length-1]){ 


                            //     settolerance([...selectedList]);
                               
                            //     }
                            // }} 
                            onSelect={(selectedList, selectedItem) => {
                              settoleranceerr("");
                              const selectedIndex = capabilityData?.tolerance.findIndex(
                                (option) => option === selectedItem
                              );
                              if (selectedIndex !== -1) {
                                // Down option selected
                                selectedList = capabilityData?.tolerance.slice(0, selectedIndex + 1);
                                settolerance([...selectedList]);
                              } else {
                                settolerance([...selectedList]);
                              }
                            }}
                          
                            // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removeItems(tolerance_new,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {tolerance_newerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {tolerance_newerr}
                          </div>
                          }
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Surface Roughness*</label>
                          <Multiselect
                          className="cust-select"
                            options={capabilityData?.surface_roughness} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            selectedValues={surface_roughness}

                            // onSelect={(selectedList,selectedItem) => {
                            //   setsurface_roughnesserr("")
                            //   setsurface_roughness([...selectedList]);
                            //   // console.log("demo",selectedItem);
                            //   if(selectedItem===capabilityData?.surface_roughness[capabilityData?.surface_roughness.length-1]){
                            //     selectedList=capabilityData?.surface_roughness;
                               
                            //     setsurface_roughness([...capabilityData?.surface_roughness])
                            //     // document.getElementById("pepega").children[1].childNodes[0].childNodes.forEach(i=>i.setAttribute('checked','true'));
                            //   }
                            //   if(selectedItem!==capabilityData?.surface_roughness[capabilityData?.surface_roughness.length-1]){ 
                            //     setsurface_roughness([...selectedList]);
                               
                            //     }
                            // }} 

                            onSelect={(selectedList, selectedItem) => {
                              setsurface_roughnesserr("");
                              const selectedIndex = capabilityData?.surface_roughness.findIndex(
                                (option) => option === selectedItem
                              );
                              if (selectedIndex !== -1) {
                                // Down option selected
                                selectedList = capabilityData?.surface_roughness.slice(0, selectedIndex + 1);
                                setsurface_roughness([...selectedList]);
                              } else {
                                setsurface_roughness([...selectedList]);
                              }
                            }}
                            
                            
                            
                            // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removeItems(surface_roughness,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {surface_roughnesserr != "" &&
                          <div className='invalid-feedback d-block'>
                            {surface_roughnesserr}
                          </div>
                          }
                        </div>
                      </div>
                 
               
                  
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Inspection*</label>
                        
                          <Multiselect
                           selectedValues={inspection}
                          className="cust-select"
                            options={capabilityData?.inspection}
                          // Options to display in the dropdown
                            // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setinspectionerr("");
                              setinspection([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removeItems(inspection,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                         {inspectionerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {inspectionerr}
                          </div>
                          }
                        </div>
                      </div>
                 

                
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Part Quality Certifications*</label>
                          <Multiselect
                          className="cust-select"
                          selectedValues={part_qualifications}
                            options={ capabilityData?.part_qualifications_certifications } // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setpart_qualificationserr("");
                              setpart_qualifications([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removeItems(part_qualifications,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {part_qualificationserr != "" &&
                          <div className='invalid-feedback d-block'>
                            {part_qualificationserr}
                          </div>
                          }
                        </div>
                      </div>
             

                
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Material Certifications*</label>
                          <Multiselect
                          className="cust-select"
                          selectedValues={material}
                            options={  capabilityData?.material_certifications } // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setmaterialerr("")
                              setmaterial([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removeItems(material,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                         {materialerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {materialerr}
                          </div>
                          }
                        </div>
                      </div>


                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Coating*</label>
                          <Multiselect
                            className="cust-select"
                            selectedValues={Coating}
                            options={capabilityData?.coatings} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setcoatingerr("")
                              setCoating([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removeItems(Coating,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {coatingerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {coatingerr}
                          </div>
                          }
                        </div>
                      </div> 
                
            </React.Fragment>
          }

          <div className="col-lg-12  mb-5">

            {
              loading === false ?
                <button type="submit" className="btn btn-primary-orange m-0" >Update</button> :
                <Button className='btn btn-primary-orange m-0   spinner' variant='dark' disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    className='me-2'
                    role="status"
                    aria-hidden="true"
                  />
                  Please wait...
                </Button>
            }




          </div>
        </div>
      </form>


    </React.Fragment>

  )
}

export default UpdateProfile;