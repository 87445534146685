import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { GiAutoRepair } from "react-icons/gi";
import Input from "react-phone-number-input/input";
import SendEmail from "../../common/sendEmail/sendEmail";
import Chart from "../../Chart";

// icons
import { FaSearch, FaFileImage, FaRegFile, FaStar } from "react-icons/fa";
import {
  FaCog,
  FaEdit,
  FaTelegramPlane,
} from "react-icons/fa";

import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";

// react bootstrap
import { Modal, Button, Spinner } from "react-bootstrap";
import ChangePassword from "../../common/changePassword/changePassword";
import { getMethodData, getSubmitData } from "../../adminApi/api";
import { useSelector, useDispatch } from "react-redux";
import { addPendingValue } from "../../../../redux/actions/action";
const SupplierManagement = () => {
  let showDataToUser = false;
  const [changestatus, setchangestatus] = useState(false);
  const [changeapprove, setchangeapprove] = useState(false);
  const [changepassword, setchangePassword] = useState(false);
  const [changeemail, setchangeemail] = useState(false);
  const [changerating, setChangerating] = useState(false);
  const [changew9, setchangeW9] = useState(false);
  //redirection permission
  const [redirection,setRedirection] = useState(false);
  useLayoutEffect(() => {
    const ls = JSON.parse(localStorage.getItem("adminDetails"));
    // console.log("uselayout", JSON.parse(localStorage.getItem("adminDetails")));

    for (let i = 0; i <= ls?.my_permissions?.length; i++) {
      if (ls?.my_permissions[i]?.name === "Supplier Management") {
        for (let j = 0; j <= ls?.my_permissions[i]?.permissions?.length; j++) {
          if (ls?.my_permissions[i]?.permissions[j]?.name === "List") {
            // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
            showDataToUser = true;
          }
          if (
            ls?.my_permissions[i]?.permissions[j]?.name === "Change  Status"
          ) {
            // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
            setchangestatus(true);
          }
          if (
            ls?.my_permissions[i]?.permissions[j]?.name ===
            "Approve -UnApprove "
          ) {
            // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
            setchangeapprove(true);
          }
          if (
            ls?.my_permissions[i]?.permissions[j]?.name === "Change  Password"
          ) {
            // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
            setchangePassword(true);
          }
          if (ls?.my_permissions[i]?.permissions[j]?.name === "Send  Mail") {
            // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
            setchangeemail(true);
          }
          if (ls?.my_permissions[i]?.permissions[j]?.name === "View Ratings") {
            // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
            setChangerating(true);
          }
          if (ls?.my_permissions[i]?.permissions[j]?.name === "View W9-file") {
            // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
            setchangeW9(true);
          }
        }
      }
    }
    // console.log(showDataToUser);
      if(showDataToUser===false){
        // // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
  }, []);
  const navigate = useNavigate();
  if (redirection) navigate('/admin/dashboard');
  // \\permission redirection
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const pendingValue = useSelector((state) => state.pendingValueReducers);
  const dispatch = useDispatch();

  // const PORT = PORTNUMBER.PORTNUMBER
  const Token = localStorage.getItem("token");
  const [showPassword, setShowPassword] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showrating, setShowrating] = useState(false);

  const [value, setValue] = useState();
  // modal active
  const [activeShow, setActiveShow] = useState(false);
  const [approveShow, setApproveShow] = useState(false);

  const [statusId, setStatusId] = useState();
  const [approveId, setApproveId] = useState();

  const [customerId, setCustomerId] = useState();
  const [userMail, setUserMail] = useState();

  const [statusType, setStatusType] = useState();
  const [statusApprover, setStatusApprover] = useState();

  // const [Loading, setLoading] = useState(false);

  const [users, setUser] = useState([]);
  // const [SupplierId, setSuplierId] = useState()
  // // console.log(SupplierId)
  const [Count, setCount] = useState(1);
  const [perPage, setPerPage] = useState();

  const [indexPage, setIndexPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [activePage, setActivePage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");
  const [reviewdata, setreiviewdata] = useState("");
  const [ontime_delivery, setontimeDelivery] = useState("");
  const [communication, setcommunication] = useState("");
  const [engagement, setengagement] = useState("");
  const [quality, setquality] = useState("");

  // const [filterData, setFilterData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [filterVisibility, setFilterVisibility] = useState(true);

  const { register, control, handleSubmit, reset } = useForm();

  const inputRef = useRef();

  const activeHandleClose = () => setActiveShow(false);
  const activeHandleShow = (id, status) => {
    if (changestatus === true) {
      setStatusType(status);
      setStatusId(id);
      setActiveShow(true);
    } else {
      alert("permission not allowed");
    }
  };

  const activeUser = async (Api) => {
    setLoading(false);
    getMethodData(`${Api}${statusId}`, Token).then((res) => {
      // // console.log(res, "active")

      if (res.data.status !== false) {
        LoadTableData();
        toast.success(res.data.message);
        activeHandleClose();
        setLoading(true);
      } else {
        toast.error(res.data.message);
        activeHandleClose();
        setLoading(true);
      }
    });
  };
  const Rating_System = (data) => {
    getMethodData(
      `${`/suppliers/get-supplier-over-all-reciew/${data}`}`,
      Token
    ).then((res) => {
      setreiviewdata(res.data.result.data.over_all_review);
      setquality(res.data.result.data.qulity);
      setontimeDelivery(res.data.result.data.on_time_delivery);
      setcommunication(res.data.result.data.communication);
      setengagement(res.data.result.data.engagement);
    });
  };

  // console.log(reviewdata);

  const activeApproveClose = () => setApproveShow(false);
  const activeApproveShow = (id, status) => {
    if (changeapprove === true) {
      setStatusApprover(status);
      setApproveId(id);
      setApproveShow(true);
    } else {
      alert("permission not allowed");
    }
  };

  const approveUser = async (Api) => {
    setLoading(false);
    getMethodData(`${Api}${approveId}`, Token).then((res) => {
      // // console.log(res, "active")

      if (res.data.status !== false) {
        LoadTableData();
        toast.success(res.data.message);
        activeApproveClose();
        setLoading(true);
      } else {
        toast.error(res.data.message);
        activeApproveClose();
        setLoading(true);
      }
    });
  };

  const LoadTableData = async (pageNumber) => {
    dispatch(addPendingValue(false));

    setIndexPage(1);

    setLoading(false);
    await axios
      .get(`${BASE_URL}/suppliers?page=${pageNumber === 1 ? 1 : pageNumber===undefined? 1 : pageNumber}`, {
        headers: { authorization: `Bearer ${Token}` },
      })
      .then((res) => {
        setUser(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);

        setLoading(true);
        setFilterVisibility(true);
      });

    reset();
    setLoadingButton(false);
  };

  const sendPageFilter = (pageNumber) => {
    getSubmitData(
      `/suppliers?page=${pageNumber}&is_admin_approved=${"pending"}`,
      Token
    ).then((res) => {
      setUser(res.data.result.data.data);
      setCount(res.data.result.data.total);
      setLoading(true);
    });
  };

  const sendPage = (pageNumber) => {
    dispatch(addPendingValue(false));
    filterData.length>0 && axios
      .get(
        `${BASE_URL}/suppliers?page=${pageNumber}&${new URLSearchParams(
          filterData
        ).toString()}`,
        { headers: { authorization: `Bearer ${Token}` } }
      )
      .then((res) => {
        setUser(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setLoading(true);
      }) 
  };

  useEffect(() => {
    if (pendingValue === true) {
      sendPageFilter();
    } else {
      sendPage();
      LoadTableData();
    }
  }, [pendingValue, quality]);

  const handleClosePw = (userId) => {
    if (changepassword === true) {
      setShowPassword(true);
      setCustomerId(userId);
    } else {
      alert("permission not allowed");
    }
  };

  const handleShowEmail = (userMail) => {
    if (changeemail === true) {
      setShowEmail(true);
      setUserMail(userMail);
    } else {
      alert("permission not allowed");
    }
  };

  const handleShowRating = (user) => {
    if (changerating === true) {
      setShowrating(true);
      Rating_System(user);
    } else {
      alert("permission not allowed");
    }
  };

  const handlePageChange = (pageNumber) => {
    // sendPage(pageNumber)
    // LoadTableData(pageNumber)
    if (pendingValue === true) {
      sendPageFilter(pageNumber);
      // LoadTableData(pageNumber)
    } else {
      if (filterVisibility === true) {
        LoadTableData(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
        setLoading(false);
      } else {
        sendPage(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
        setLoading(false);
      }
    }
  };

  const onSubmit = (data) => {
    dispatch(addPendingValue(false));

    setLoadingButton(true);
    setIndexPage(1);

    setFilterData(data);

    data.from_date =
      !data.from_date || data.from_date === null || data.from_date === undefined
        ? ""
        : moment(data.from_date).format("YYYY/MM/DD");
    data.to_date =
      !data.to_date || data.to_date === null || data.to_date === undefined
        ? ""
        : moment(data.to_date).format("YYYY/MM/DD");

    // data.is_admin_approved = "pending"
    setLoading(false);
    // sendPage()
    getSubmitData(
      `/suppliers?${new URLSearchParams({ ...data }).toString()}`,
      Token
    ).then((res) => {
      setLoadingButton(false);

      setUser(res.data.result.data.data);
      setCount(res.data.result.data.total);

      // setCount(res.data.data.userCount)
      // setFilterData(res.data.data.userData)
      setLoading(true);
      setFilterVisibility(false);
    });
    setLoadingButton(false);
  };
  const [todayDate,setTodayDate]=useState(null);
  return (
    <>
      <div className="tables-field">
        <div className="project-table">
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <div className="main-head">
                  <h4>Supplier List</h4>
                </div>
              </div>
              {/* <div className="col-6">
                            <div className="theme-btn top-head text-end">
                                <Link to="add-new-supplier" className="btn">Add New Supplier</Link>
                            </div>
                        </div> */}
            </div>
          </div>
          <div className="table-order-box shadow rounded overflow-hidden">
            <div className="tab-head">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        maxLength="15"
                        className="form-control"
                        id="validationCustom01"
                        {...register("first_name", { required: false })}
                        placeholder="First Name"
                      />
                      <div className="invalid-feedback">
                        Supplier name required.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        maxLength="15"
                        className="form-control"
                        id="validationCustom01"
                        {...register("last_name", { required: false })}
                        placeholder="Last Name"
                      />
                      <div className="invalid-feedback">
                        Supplier last name required.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        maxLength="40"
                        className="form-control"
                        id="validationCustom02"
                        {...register("email", { required: false })}
                        placeholder="Email"
                      />
                      <div className="invalid-feedback">
                        Please use @ for a valid email.
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <Input
                        minLength={14}
                        maxLength={14}
                        defaultCountry="US"
                        placeholder="Enter phone number"
                        {...register("phone", { required: false })}
                        value={value}
                        onChange={setValue}
                      />
                      {/* <input type="number" maxLength="15" className="form-control" id="validationCustom03" {...register('phone', { required: false })} placeholder="Mobile" /> */}
                      <div className="invalid-feedback">
                        Please enter mobile number.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group before-con">
                      <Controller
                        control={control}
                        name="from_date"
                        render={({ field }) => (
                          <DatePicker
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Click to select a date"
                            onChange={(date) => {
                              field.onChange(date);
                              setTodayDate(date);}}
                            selected={field.value}
                            maxDate={new Date()}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group before-con after">
                      <Controller
                        control={control}
                        name="to_date"
                        render={({ field }) => (
                          <DatePicker
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Click to select a date"
                            onChange={(date) => field.onChange(date)}
                            maxDate={new Date()}
                            minDate={todayDate==null? new Date() : todayDate}
                            selected={field.value}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="number"
                        id="flight_number"
                        maxLength="4"
                        className="form-control"
                        {...register("zipcode", { required: false })}
                        placeholder="Zipcode"
                        minLength={1}
                        min={0}
                      />
                      <div className="invalid-feedback">
                        Please enter zipcode.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control"
                        id="validationCustomnew4"
                        {...register("company_name", { required: false })}
                        placeholder="Company"
                      />
                      <div className="invalid-feedback">
                        Please enter company.
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        className="form-select form-control"
                        {...register("status", { required: false })}
                        id="validationCustom04"
                        aria-label="Default select example"
                      >
                        <option className="disabled-value d-none" value="">
                          All status
                        </option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                      <div className="invalid-feedback">
                        Please select status.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        className="form-select form-control"
                        {...register("is_admin_approved", { required: false })}
                        id="validationCustom04"
                        aria-label="Default select example"
                      >
                        <option className="disabled-value" value="">
                          All Verification
                        </option>
                        <option value="approved">Approved</option>
                        <option ref={inputRef} value="pending">
                          Pending
                        </option>
                      </select>
                      <div className="invalid-feedback">
                        Please select status verification.
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-6">
                                    <div className="form-group before-con no-from">

                                        <Controller
                                            control={control}
                                            name="startDate"

                                            render={({ field }) => (
                                                <DatePicker


                                                    placeholderText='Click to select a date'
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}



                                                />
                                            )}
                                        />

                                    </div>
                                </div> */}

                  <div className="col-lg-12">
                    <div className="theme-btn btn-group-right text-end">
                      {loadingButton === false ? (
                        <button type="submit" className="btn">
                          Search
                        </button>
                      ) : (
                        <Button
                          className="btn  spinner"
                          variant="dark"
                          disabled
                        >
                          <Spinner
                            as="span"
                            animation="grow"
                            role="status"
                            aria-hidden="true"
                          />
                          Searching...
                        </Button>
                      )}
                      <button
                        type="reset"
                        onClick={() => LoadTableData()}
                        className="btn"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="project-table">
          <div className="form-search">
            <form>
              <div className="form-group icon-input top-search">
                <input
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                  placeholder="Search"
                />
                <FaSearch />
              </div>
              <input type="btn" className="d-none" value="Submit" />
            </form>
          </div>
          <div className="table-order shadow">
            <table>
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th className="text-nowrap">Company Name</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Order</th>
                  <th>W-9</th>
                  <th>Verification</th>
                  <th>Added On</th>
                  <th>Status</th>
                  <th>Actions </th>
                </tr>
              </thead>
              <tbody>
                {users && users.length ? (
                  users
                    .filter((val) => {
                      const date = val.suppllier_signup_date;
                      if (searchTerm === "") {
                        return val;
                      } else if (
                        val.first_name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      }
                      else if (val?.company_name !== null && val?.company_name !== undefined &&
                        val.company_name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.last_name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.zipcode
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.email
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.phone
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        moment(date)
                          .format("MM/DD/yyyy")
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.status
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      } else {
                        return false;
                      }
                    })
                    .map((user, index) => (
                      <tr key={index}>
                        <td className="s-no" scope="row">
                          {50 * (indexPage - 1) + (index + 1)}
                        </td>
                        <td>{user?.company_name}</td>
                        <td className="text-nowrap">
                          {user.first_name} {user.last_name}
                        </td>

                       

                        <td>{user.email}</td>
                        <td className="text-nowrap">{user.phone}</td>
                        <td>
                          {user.orders_count > 0 ? (
                            <Link to={`/admin/supplier-order/${user.id}`}>
                              
                              {user.orders_count}
                            </Link>
                          ) : (
                            user.orders_count
                          )}
                        </td>
                        <td className=" text-center">
                          {user.aws_file_path !== null ? (
                            <a
                              href={
                                changew9 === false ? "" : user.aws_file_path
                              }
                              target="_blank"
                              className=" text-decoration-none text-center"
                              rel="noreferrer"
                            >
                              <FaFileImage className="fs-5 m-auto" />
                              <span
                                className="d-block"
                                style={{ fontSize: "0.9rem" }}
                              >
                                View
                              </span>
                            </a>
                          ) : (
                            <FaRegFile className="fs-5 m-auto" />
                          )}
                        </td>

                        <td className="">
                          <span
                            onClick={() =>
                              activeApproveShow(user.id, user.is_admin_approved)
                            }
                            className={
                              user.is_admin_approved === 0
                                ? "badge  bg-warning text-light"
                                : "badge bg-success text-light"
                            }
                          >
                            {user.is_admin_approved === 0
                              ? "Pending"
                              : "Approved"}
                          </span>
                        </td>
                        <td>
                          {moment(user.suppllier_signup_date).format(
                            "MM/DD/yyyy"
                          )}
                        </td>

                        <td className="">
                          <span
                            onClick={() =>
                              activeHandleShow(user.id, user.is_supplier_active)
                            }
                            className={`badge bg-success ${user.is_supplier_active}`}
                          >
                            {user.is_supplier_active}
                          </span>
                        </td>
                        <td className="action-top">
                          <Link
                            title="Edit Supplier"
                            to={`edit/${user.id}`}
                            className="icons-action"
                          >
                            <FaEdit />
                          </Link>
                          {/* <Link to={`/userWineList/${user._id}`} className="icons-action"><FaEye /></Link> */}
                          <span
                            onClick={() => handleClosePw(user.id)}
                            title="Change Password"
                            className="icons-action"
                          >
                            <FaCog />
                          </span>
                          <span
                            onClick={() => handleShowEmail(user.email)}
                            title="Send Mail"
                            className="icons-action"
                          >
                            <FaTelegramPlane />
                          </span>

                          <span
                            onClick={() => {
                              handleShowRating(user.id);
                            }}
                            title="View Ratings"
                            className="icons-action"
                          >
                            <FaStar />
                          </span>
                          {/* <span onClick={() => activeHandleShow(user.id, user.status)} className="icons-action">{user.status === "inactive" ? <FaRegCheckCircle /> : <FaBan />}</span> */}

                          {/* <span onClick={() => activeApproveShow(user.id, user.is_admin_approved)} className="icons-action"><FaUserCheck /></span> */}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td className="no-data text-uppercase">
                      NO supplier FOUND
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {!loading ? (
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiAutoRepair />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
          </div>
          <div className="pagination">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={50}
              totalItemsCount={Number(Count)}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          </div>
        </div>

        {/* modals */}

        {/* active modal */}
        <Modal
          className="status-modal"
          animation={false}
          show={activeShow}
          onHide={activeHandleClose}
        >
          <div className="position-relative">
            {!loading ? (
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiAutoRepair />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <Modal.Body>
              Are you sure you want to {statusType === "active" ? "in-activate" : "activate"} this
              supplier?
            </Modal.Body>
            :
            <Modal.Footer>
              <div className="theme-btn btn-group-right text-end">
                <Button
                  variant="secondary"
                  onClick={() => activeUser("/suppliers/change-status/")}
                >
                  Yes
                </Button>
                :
                <Button variant="primary" onClick={activeHandleClose}>
                  No
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
        {/* active modal end */}

        {/* active modal */}
        <Modal
          className="status-modal"
          animation={false}
          show={approveShow}
          onHide={activeApproveClose}
        >
          <div className="position-relative">
            {!loading ? (
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiAutoRepair />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <Modal.Body>
              Are you sure you want to {statusApprover === 1 ? "pending" : "approve"} this supplier?
            </Modal.Body>
            :
            <Modal.Footer>
              <div className="theme-btn btn-group-right text-end">
                <Button
                  variant="secondary"
                  onClick={() =>
                    approveUser("/suppliers/change-approved-status/")
                  }
                >
                  Yes
                </Button>
                <Button variant="primary" onClick={activeApproveClose}>
                  No
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
        {/* active modal end */}
        <Chart
          quality={quality}
          total_Reiview={reviewdata}
          communication={communication}
          engagement={engagement}
          ontime_delivery={ontime_delivery}
          show={showrating}
          onHide={() => setShowrating(false)}
        />
        <ToastContainer />
        <ChangePassword
          api={`/customers/change-password/${customerId}`}
          show={showPassword}
          dnone={"d-none"}
          onHide={() => setShowPassword(false)}
        />
        <SendEmail
          api={"/suppliers/send-contect-email"}
          show={showEmail}
          usermail={userMail}
          dnone={"d-none"}
          onHide={() => setShowEmail(false)}
        />
      </div>
    </>
  );
};
export default SupplierManagement;
