import React, { useState, useEffect, useCallback } from "react";
import  ProgressBar from "react-animated-progress-bar";
import FusionCharts from "fusioncharts";
import { Modal, Button, Spinner } from "react-bootstrap";
import charts from "fusioncharts/fusioncharts.charts";
import GaugeChart from "react-gauge-chart";


// Resolves charts dependancy

const chartStyle = {
  height: "90%",
  width: "90%",
  padding: "30px",
};

const Chart = (props) => {
  const closeModal = () => {
    props.onHide();
  };

  useEffect(() => {
    
  },[props])
// // console.log(props);
  return (
    <>
      <div className="modal-edit">
        <Modal {...props} size="md" className="edit-content">
          <div className="">
            <div className="main-theme bg-theme border shadow ps-5 pe-5">
              <Modal.Header closeButton onClick={() => closeModal()}>
                <div className="select-steps-box">
                  <div className="input-head">
                    <h3>Supplier Ratings</h3>
                  </div>
                </div>
              </Modal.Header>
              <section className="select-state">
                <div className="state-bor">
                  <div className="log-in">
                    <div className="select-box-top pr-0">
                      <div className="input-box">
                        <GaugeChart
                          id="gauge-chart"
                          textColor="#333"
                          style={chartStyle}
                          nrOfLevels={4}
                          arcsLength={[0.5, 0.15, 0.05, 0.05]}
                          colors={["red", "orange", "yellow", "green"]}
                          percent={props.total_Reiview / 100}
                          arcPadding={0.05}
                          text
                        />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            postion: "relative",
                          }}
                        >
                          <span>
                            Quality:{" "}
                            <ProgressBar
                              width="400px"
                              height="10px"
                              rect
                              fontColor="gray"
                              scrollArea="100"
                              percentage={props.quality - 1}
                              trackPathColor="transparent"
                              bgColor="#333333"
                              trackBorderColor="grey"
                            />
                          </span>
                          <span>
                            On-time Delivery:{" "}
                            <ProgressBar
                              width="400px"
                              height="10px"
                              rect
                              scrollArea="100"
                              fontColor="gray"
                              percentage={props.ontime_delivery - 1}
                              trackPathColor="transparent"
                              bgColor="#333333"
                              trackBorderColor="grey"
                            />
                          </span>
                          <span>
                            Engagement:{" "}
                            <ProgressBar
                              width="400px"
                              height="10px"
                              rect
                              scrollArea="100"
                              fontColor="gray"
                              percentage={props.engagement - 1}
                              trackPathColor="transparent"
                              bgColor="#333333"
                              trackBorderColor="grey"
                            />
                          </span>
                          <span>
                            Communication:{" "}
                            <ProgressBar
                              width="400px"
                              height="10px"
                              rect
                              scrollArea="100"
                              fontColor="gray"
                              percentage={props.communication - 1}
                              trackPathColor="transparent"
                              bgColor="#333333"
                              trackBorderColor="grey"
                            />
                          </span>
                        </div>
                        {/* On-time Dilvery: <ProgressBar now={now} label={`${now}%`} />
                       Communitcation: <ProgressBar now={now} label={`${now}%`} />
                       Engagement: <ProgressBar now={now} label={`${now}%`} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default Chart;
