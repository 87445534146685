import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import Input from "react-phone-number-input/input";
import { Button, Spinner, Modal } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  getMethodDataSupplier,
  postMethodDataUser,
} from "../../../../../admin/adminApi/api";
import {
  modalToggelValue,
  modalRgisterlValue,
  modalBecomeValue,
  modalConditionValue,
  modalPolicyValue,
} from "../../../../../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";

import FormValidation from "../../../../../../formValidation/formValidation";

const BecomeAsupplier = (props) => {
  const [tolleranceData, setTolleranceData] = useState();
  const [surfaceRoughness, setSurfaceRoughness] = useState();
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [mobileErr, setMobileErr] = useState([""]);
  const [capabilityData, setCapabilityData] = useState([]);
  const [emailErr, setEmailErr] = useState([""]);
  const USERTOKEN = localStorage.getItem("userToken");
  const BecomeValue = useSelector((state) => state.modalBecomeReducer);

  // form validation rules
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum.")
      .max(18, "Password is too long - should be 18 chars minimum.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
        "Please Check Password Format"
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .min(6, "Confirm password is too short - should be 6 chars minimum.")
      .max(18, "Password is too long - should be 18 chars minimum.")
      .required("Confirm Password is required"),
    email: Yup.string().email("The email must be a valid email. "),

    phone: Yup.string().min(14).required("A phone number is required"),
    zipcode: Yup.string().min(5).required("A zipcode is required").max(5),

    image: Yup.mixed()
      .required("Image file is required")
      .test("type", "Only .pdf Formate is accepted", (value) => {
        return value && value[0].type === "application/pdf";
      }),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  // const dispatch = useDispatch()
  // const PORT = PORTNUMBER.PORTNUMBER

  const [passwordErr, setPassErr] = useState();

  const [checkedValue, setCheckedValue] = useState(true);
  const [manufacturing_process, setmanufacturing_process] = useState([]);
  const [tolerance, setolerence] = useState([]);
  const [Surface_roughness, setSurface_roughness] = useState([]);
  const [Inspection, setInspection] = useState([]);
  const [qualifications, setqualifications] = useState([]);
  const [Material, setMaterial] = useState([]);
  const [Coating, setCoating] = useState([]);
  const [visibility, setVisibility] = useState(true);
  const [loading, setLoading] = useState(false);


  const [inspectionerr, setinspectionerr] = useState("")
  const [manufacturingerr, setmanufacturing2err] = useState("")
  const [materialerr, setmaterialerr] = useState("")
  const [coatingerr, setcoatingerr] = useState("")
  const [part_qualificationserr, setpart_qualificationserr] = useState("")
  const [surface_roughnesserr , setsurface_roughnesserr  ] = useState("")
  const [tolerance_newerr    , settoleranceerr  ] = useState("")


  const [visibilityConfirm, setVisibilityConfirm] = useState(true);
  const FormValidationNew = FormValidation;

  const [formValidation, setFormValidation] = useState({
    Email: "",
    Mobile: "",
    default: "",
    Phone: "",
    setPassErrFormat: "",
    Success: null,
  });
  // console.log("==>",manufacturing_process);
  const TechnicalCapabilities = (e) => {
    getMethodDataSupplier(
      `/get-technical-capabilities`,
      USERTOKEN
    ).then((res) => {
      setCapabilityData(res.data.result.data);
      setInspection(...res?.data?.result?.data?.inspection[0])
    });
  };

  useEffect(() => {
    TechnicalCapabilities();
  }, []);

  const pwVisibility = (value) => {
    setVisibility(value);
  };
  const pwVisibilityConfirm = (value) => {
    setVisibilityConfirm(value);
  };

  useEffect(() => {
    setMobileErr("");
    setEmailErr("");
  }, [props]);

  useEffect(() => {
    reset();
    setValue("");
  }, [BecomeValue]);


  const [techError,setTechError]=useState({});


  const onSubmit = (data) => {

    
    if (manufacturing_process.length === 0) {
      // alert("Please select values");
      setmanufacturing2err("Please select Manufacturing Process")
      // return;
    }
    if(Surface_roughness.length === 0){
      setsurface_roughnesserr("Please select Surface Roughness")
      // alert("Please select values");
      // return;
    }
    if(tolerance.length === 0){
      settoleranceerr("Please select Tolerance")
      // alert("Please select values");
      // return;
    }
    if(Inspection.length === 0){
      setinspectionerr("Please select Inspection")
      // alert("Please select values");
      // return;
    }
    if(qualifications.length === 0){
      setpart_qualificationserr("Please select Part Quality Certifications")
      // alert("Please select values");
      // return;
    }
    if(Material.length === 0){
      setmaterialerr("Please select Part Material Certifications")
      // alert("Please select values");
      // return;
    }
    if(Coating.length === 0){
      setcoatingerr("Please select Coating")
      // alert("Please select values");
      // return;
    }
    if(manufacturing_process.length === 0 || Surface_roughness.length === 0 || tolerance.length === 0 || Inspection.length === 0 || qualifications.length === 0 || Material.length === 0 || Coating.length === 0){
      return;
    }

    
    setLoading(true);
    const imageFiles = document.getElementById("document").files[0];

    const fd = new FormData();
    for (var key in data) {
      fd.append(key, data[key]);
    }
    fd.append("w9document", imageFiles);

    fd.append("manufacturing_process", manufacturing_process);
    fd.append("surface_roughness", Surface_roughness);
    fd.append("tolerance", tolerance);
    fd.append("inspection", Inspection);
    fd.append("part_qualifications_certifications", qualifications);
    fd.append("material_certifications", Material);
    fd.append("coatings", Coating);
    postMethodDataUser("/supplier-register", fd, USERTOKEN)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(modalBecomeValue(false,"supplier"))
          setLoading(false);
          toast(res.data.message);
          dispatch(modalToggelValue(true));
          reset();
          setmanufacturing_process([]);
          setSurface_roughness([]);
          setolerence([]);
          setInspection([]);
          setqualifications([]);
          setMaterial([]);
          setCoating([]);
          props.onHide();
          dispatch(modalBecomeValue(false,"supplier"))
          // navigate("/admin/supplier-management")
        } else {
          dispatch(modalBecomeValue(false,"supplier"))
          setLoading(false);
          setMobileErr(res?.data?.result?.errors?.phone);
          setEmailErr(res?.data?.result?.errors?.email);
        }
        dispatch(modalBecomeValue(false,"supplier"))
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        reset();
          setmanufacturing_process([]);
          setSurface_roughness([]);
          setolerence([]);
          setInspection([]);
          setqualifications([]);
          setMaterial([]);
          setCoating([]);
      });

  };

  useEffect(() => {
    FormValidationNew();
  }, [FormValidationNew, formOptions]);

  const dispatchToggle = () => {
    dispatch(modalToggelValue(true));
    dispatch(modalRgisterlValue(false));
    dispatch(modalBecomeValue(!BecomeValue.madalBecomeValue,"supplier"))
  };
  const removePlayer = async (arr, element) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] == element) {
        arr.splice(i, 1);
        // console.log(arr)
        // setmanufacturing_process(arr);
        break;
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content">
          <div className="create-account">
            <div className="row">
              <div className="col-md-12 position-relative;">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => props.onHide()}
                />
                <h3 className="text-center m-0 py-3">
                  Please Sign In or Create an Account
                </h3>
                <h6 className="text-center m-0 pb-5">
                  Have an Account?
                  <a
                    className="pt-3 ms-1"
                    data-bs-toggle="modal"
                    data-bs-target="#signinmodal"
                    type="button"
                    onClick={() => {
                      props.onHide();
                      dispatchToggle();
                    }}
                  >
                    Sign In
                  </a>
                </h6>
                <small className=" form-text d-block text-center  text-muted">
                  {formValidation.default}
                </small>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="needs-validation"
                  noValidate
                >
                  <div>
                    <p>
                      <strong>Personal Details</strong>
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 ">
                      <div className="form-group">
                        <input
                          type="text"
                          maxLength="15"
                          className="form-control"
                          id="validationCustom01"
                          {...register("first_name", { required: true })}
                          placeholder="First Name*"
                          required
                        />
                        <div className="invalid-feedback">
                          Please Enter Customer First Name.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="form-group">
                        <input
                          type="text"
                          maxLength="15"
                          className="form-control"
                          id="validationCustomNew"
                          {...register("last_name", { required: true })}
                          placeholder="Last Name*"
                          required
                        />
                        <div className="invalid-feedback">
                          Please Enter Customer Last Name.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="form-group">
                        <input
                          type="email"
                          maxLength="100"
                          className="form-control"
                          name="email"
                          id="validationCustomNew1"
                          {...register("email", { required: true })}
                          placeholder="Work Email*"
                          required
                        />

                        <small className="text-danger">
                          {" "}
                          {errors.email?.message}
                        </small>
                        <div className="invalid-feedback">
                          Please Enter Valid Email Address.
                        </div>
                        <small className="form-text text-muted">
                          {emailErr}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="form-group">
                        <Input
                          className="form-control"
                          minLength={14}
                          maxLength={14}
                          name="phone"
                          defaultCountry="US"
                          placeholder="Phone*"
                          {...register("phone", { required: true })}
                          value={value}
                          onChange={setValue}
                          required
                        />

                        <small className="font-12 d-block">
                          E.g (xxx) xxx-xxxx , No Need for Country Code
                        </small>
                        <small className="text-danger">
                          {" "}
                          {errors.phone?.message}
                        </small>
                        <small className="form-text text-muted">
                          {mobileErr}
                        </small>
                        <div className="invalid-feedback">
                          Please enter mobile number.
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="number"
                          maxLength="6"
                          className="form-control"
                          id="validationCustomnew2"
                          {...register("zipcode", { required: true })}
                          placeholder="Zipcode*"
                          required
                          min={0}
                          minLength={6}
                        />
                        <small className="text-danger">
                          {errors.zipcode?.message}
                        </small>
                        <div className="invalid-feedback">
                          Please enter zipcode.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          maxLength="40"
                          className="form-control"
                          id="validationCustomnew4"
                          {...register("company_name", { required: true })}
                          placeholder="Company*"
                          required
                        />
                        <div className="invalid-feedback">
                          Please enter company.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6  ">
                      <div className="form-group icon-position">
                        <input
                          autoComplete="on"
                          type={visibility ? "password" : "text"}
                          className={`form-control ${
                            errors.password ? "is-invalid" : ""
                          }`}
                          id="password"
                          name="password"
                          minLength="6"
                          maxLength="18"
                          {...register("password", { required: true })}
                          placeholder="password*"
                          required
                        />
                        {visibility ? (
                          <span
                            className="icon"
                            onClick={() => pwVisibility(false)}
                          >
                            <FaEyeSlash />
                          </span>
                        ) : (
                          <span
                            className="icon"
                            onClick={() => pwVisibility(true)}
                          >
                            <FaEye />
                          </span>
                        )}
                        <small className="font-12 d-block">
                          ( 6 Digit, Alphanumeric with 1 Special Char and 1
                          Capital Letter )
                        </small>
                        <small className="text-danger">
                          {" "}
                          {errors.password?.message}
                        </small>
                        {/* {errors.password?.message} */}
                        <div className="invalid-feedback">
                          Please enter valid password.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="form-group icon-position password-field">
                        <input
                          autoComplete="on"
                          type={visibilityConfirm ? "password" : "text"}
                          name="confirm_password"
                          minLength="6"
                          maxLength="18"
                          className={`form-control ${
                            errors.confirm_password ? "is-invalid" : ""
                          }`}
                          id="validationCustom05"
                          {...register("confirm_password", { required: true })}
                          placeholder="Confirm password*"
                          required
                        />
                        {visibilityConfirm ? (
                          <span
                            className="icon"
                            onClick={() => pwVisibilityConfirm(false)}
                          >
                            <FaEyeSlash />
                          </span>
                        ) : (
                          <span
                            className="icon"
                            onClick={() => pwVisibilityConfirm(true)}
                          >
                            <FaEye />
                          </span>
                        )}
                        <small className="font-12 d-block">
                          ( 6 Digit, Alphanumeric with 1 Special Char and 1
                          Capital Letter )
                        </small>
                        <small className="text-danger">
                          {" "}
                          {errors.confirm_password?.message}
                        </small>
                        {/* <div className="invalid-feedback">{errors.confirmPassword?.message}</div> */}
                        <div className="invalid-feedback">
                          Please enter valid confirm password.
                        </div>
                        <small className=" form-text text-muted">
                          {passwordErr}
                        </small>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group w-9">
                        <div className="file-upload-div">
                          <input
                            accept="application/pdf"
                            className="form-control"
                            id="document"
                            type="file"
                            {...register("image", { required: true })}
                            name="image"
                            required
                          />
                          <span>W-9 File</span>
                        </div>
                        <small className="text-danger">
                          {" "}
                          {errors.image?.message}
                        </small>
                        <div className="invalid-feedback">
                          Please upload document.
                        </div>
                      </div>
                    </div>

                    <div>
                      <p>
                        <strong>Technical Capabilities</strong>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Manufacturing Process*</label>
                        <Multiselect
                          className={techError?.manufacturing_process===true?"cust-select border border-danger rounded":"cust-select"}
                          options={capabilityData?.manufacturing_process} // Options to display in the dropdown
                          // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                          onSelect={(selectedList, selectedItem) => {
                            setmanufacturing2err("");
                            setmanufacturing_process([...selectedList]);
                          }} // Function will trigger on select event
                          onRemove={(selectedList, selectedItem) => {
                            removePlayer(manufacturing_process,selectedItem);
                          }} // Function will trigger on remove event
                          // displayValue="name" // Property name to display in the dropdown options
                          showCheckbox={true}
                          isObject={false}
                        />
                        {manufacturingerr != "" &&
                        <div className='invalid-feedback d-block'>
                          {manufacturingerr}
                        </div>
                        }
                      </div>
                    </div>
                    {capabilityData?.tolerance &&
                    capabilityData?.tolerance.length === 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tolerance*</label>

                          <Multiselect
                            className={techError?.tolerance===true?"cust-select border border-danger rounded":"cust-select"}
                            options={capabilityData?.tolerance} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            selectedValues={tolerance}

                            // onSelect={(selectedList, selectedItem) => {
                            //   settoleranceerr("");
                            //   setolerence([...selectedList]);
                            //   if (
                            //     selectedItem ===
                            //     capabilityData?.tolerance[
                            //       capabilityData?.tolerance.length - 1
                            //     ]
                            //   ) {
                            //     selectedList = capabilityData?.tolerance;

                            //     setolerence([...capabilityData?.tolerance]);
                            //     // document.getElementById("pepega").children[1].childNodes[0].childNodes.forEach(i=>i.setAttribute('checked','true'));
                            //   }
                            //   if (
                            //     selectedItem !==
                            //     capabilityData?.tolerance[
                            //       capabilityData?.tolerance.length - 1
                            //     ]
                            //   ) {
                            //     setolerence([...selectedList]);
                            //   }
                            // }} 

                            onSelect={(selectedList, selectedItem) => {
                              settoleranceerr("");
                              const selectedIndex = capabilityData?.tolerance.findIndex(
                                (option) => option === selectedItem
                              );
                              if (selectedIndex !== -1) {
                                // Down option selected
                                selectedList = capabilityData?.tolerance.slice(0, selectedIndex + 1);
                                setolerence([...selectedList]);
                              } else {
                                setolerence([...selectedList]);
                              }
                            }}
                            
                            
                            // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer(tolerance,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {tolerance_newerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {tolerance_newerr}
                          </div>
                          }
                        </div>
                      </div>
                    )}
                    {capabilityData?.surface_roughness &&
                    capabilityData?.surface_roughness.length === 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Surface roughness*</label>
                          <Multiselect
                            className={techError?.surface_roughness===true?"cust-select border border-danger rounded":"cust-select"}
                            options={capabilityData?.surface_roughness} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            selectedValues={Surface_roughness}
                            // onSelect={(selectedList, selectedItem) => {
                            //   setsurface_roughnesserr("")
                            //   setSurface_roughness([...selectedList]);
                            //   if (
                            //     selectedItem ===
                            //     capabilityData?.surface_roughness[
                            //       capabilityData?.surface_roughness.length - 1
                            //     ]
                            //   ) {
                            //     selectedList =
                            //       capabilityData?.surface_roughness;

                            //       setSurface_roughness(
                            //       [...capabilityData?.surface_roughness]
                            //     );
                            //     // document.getElementById("pepega").children[1].childNodes[0].childNodes.forEach(i=>i.setAttribute('checked','true'));
                            //   }
                            //   if (
                            //     selectedItem !==
                            //     capabilityData?.surface_roughness[
                            //       capabilityData?.surface_roughness.length - 1
                            //     ]
                            //   ) {
                            //     setSurface_roughness([...selectedList]);
                            //   }
                            // }} 
                            
                            onSelect={(selectedList, selectedItem) => {
                              setsurface_roughnesserr("");
                              const selectedIndex = capabilityData?.surface_roughness.findIndex(
                                (option) => option === selectedItem
                              );
                              if (selectedIndex !== -1) {
                                // Down option selected
                                selectedList = capabilityData?.surface_roughness.slice(0, selectedIndex + 1);
                                setSurface_roughness([...selectedList]);
                              } else {
                                setSurface_roughness([...selectedList]);
                              }
                            }}
                            
                            // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer(Surface_roughness,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {surface_roughnesserr != "" &&
                          <div className='invalid-feedback d-block'>
                            {surface_roughnesserr}
                          </div>
                          }
                        </div>
                      </div>
                    )}
                    {capabilityData?.inspection &&
                    capabilityData?.inspection.length === 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Inspection*</label>
                          <Multiselect
                            selectedValues={[capabilityData?.inspection && capabilityData?.inspection[0]]}
                            className={techError?.Inspection?"cust-select border border-danger rounded":"cust-select"}
                            options={capabilityData?.inspection} // Options to display in the dropdown
                          
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setinspectionerr("");
                              setInspection([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer(Inspection,selectedItem);
                              console.log(Inspection.length)
                              if(Inspection.length === 1){
                                setInspection([])
                              }
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {inspectionerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {inspectionerr}
                          </div>
                          }
                        </div>
                      </div>
                    )}

                    {capabilityData?.part_qualifications_certifications &&
                    capabilityData?.part_qualifications_certifications
                      .length === 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Part Quality Certifications*</label>
                          <Multiselect
                            className={techError?.part_qualifications_certifications===true?"cust-select border border-danger rounded":"cust-select"}
                            options={
                              capabilityData?.part_qualifications_certifications
                            } // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setpart_qualificationserr("");
                              setqualifications([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer(qualifications,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                           {part_qualificationserr != "" &&
                          <div className='invalid-feedback d-block'>
                            {part_qualificationserr}
                          </div>
                          }
                        </div>
                      </div>
                    )}

                    {capabilityData?.material_certifications &&
                    capabilityData?.material_certifications.length === 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Material certifications*</label>
                          <Multiselect
                            className={techError?.material_certifications===true?"cust-select border border-danger rounded":"cust-select"}
                            options={capabilityData?.material_certifications} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setmaterialerr("")
                              setMaterial([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer(Material,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {materialerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {materialerr}
                          </div>
                          }
                        </div>
                      </div>
                    )}

                  {capabilityData?.coatings &&
                    capabilityData?.coatings.length === 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Coating*</label>
                          <Multiselect
                            className={techError?.coatings===true?"cust-select border border-danger rounded":"cust-select"}
                            options={capabilityData?.coatings} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setcoatingerr("")
                              setCoating([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer(Coating,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {coatingerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {coatingerr}
                          </div>
                          }
                        </div>
                      </div>
                    )}


                    <div className="col-12">
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="invalidCheck2"
                          onChange={() => setCheckedValue(!checkedValue)}
                          required
                          checked={checkedValue}
                        />
                        <label className="form-check-label">
                          <span className="text-light-gray  ms-2">
                            I have read and accept the
                            <span
                              onClick={() =>
                                dispatch(modalConditionValue(true))
                              }
                              className="text-decoration-none text-primary pointer"
                            >
                              Terms of Service
                            </span>{" "} and {" "} <span
                              onClick={() => dispatch(modalPolicyValue(true))}
                              className="text-decoration-none pointer text-primary"
                            >
                              Privacy Policy
                            </span>
                          </span>
                        </label>
                        <div
                          id="invalidCheck3Feedback"
                          className="invalid-feedback"
                        >
                          You must agree before submitting.
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 text-center">
                      <div className=" text-center justify-content-center">
                        {loading === false ? (
                          <button
                            type="submit"
                            className="btn btn-primary-orange m-auto ms-2 me-2  mt-3"
                          >
                            Sign Up
                          </button>
                        ) : (
                          <Button
                            className="btn  btn-primary-orange m-auto  mt-3 spinner"
                            variant="dark"
                            disabled
                          >
                            <Spinner
                              as="span"
                              animation="grow"
                              className="me-2"
                              role="status"
                              size="sm"
                              aria-hidden="true"
                            />
                            Please wait...
                          </Button>
                        )}
                        <button
                          type="reset"
                          onClick={() => props.onHide()}
                          className="btn btn-primary-outline m-auto  ms-2 me-2 mt-3"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="tables-field">
                    <div className="project-table mb-0">
                        <div className="table-order-box shadow">
                            <div className="tab-head">
                                <small className=" form-text d-block text-center mb-4 text-muted">{formValidation.default}</small>
                                <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="text" maxLength="15" className="form-control" id="validationCustom01"  {...register('first_name', { required: true })} placeholder="First Name" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Customer First Name.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="text" maxLength="15" className="form-control" id="validationCustomNew"  {...register('last_name', { required: true })} placeholder="Last Name" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Customer Last Name.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">

                                            <div className="form-group">
                                                <input type="email" maxLength="100" className="form-control" name="email" id="validationCustomNew1"  {...register('email', { required: true })} placeholder="Email" required />

                                                <small className='text-danger'> {errors.email?.message}</small>
                                                <div className="invalid-feedback">
                                                    Please Enter Valid Email Address.
                                                </div>
                                                <small className="form-text text-muted">{emailErr}</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <Input
                                                    minLength={14}
                                                    maxLength={14}
                                                    name="phone"
                                                    defaultCountry="US"
                                                    placeholder="Enter phone number"
                                                    {...register('phone', { required: true })}
                                                    value={value}
                                                    onChange={setValue} />
                                                <small className='font-12 d-block'>E.g (xxx) xxx-xxxx , No Need for Country Code</small>
                                                <small className="form-text text-muted">{mobileErr}</small>
                                                <div className="invalid-feedback">
                                                    Please enter mobile number.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group icon-position">
                                                <input type={visibility ? "password" : "text"} maxLength="18" className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="password" name="password" minLength="6" {...register('password', { required: true }
                                                )} placeholder="password" required />
                                                {
                                                    visibility ?
                                                        <span className="icon" onClick={() => pwVisibility(false)}>
                                                            <FaEyeSlash />
                                                        </span> :
                                                        <span className="icon" onClick={() => pwVisibility(true)}>
                                                            <FaEye />
                                                        </span>
                                                }
                                                <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>

                                                <small className='text-danger'> {errors.password?.message}</small>

                                                <div className="invalid-feedback">
                                                    Please enter valid password.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group icon-position password-field">
                                                <input type={visibilityConfirm ? "password" : "text"} maxLength="18" name="confirm_password" minLength="6" className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`} id="validationCustom05" {...register('confirm_password', { required: true })} placeholder="Confirm password" required />
                                                {
                                                    visibilityConfirm ?
                                                        <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                            <FaEyeSlash />
                                                        </span> :
                                                        <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                            <FaEye />
                                                        </span>
                                                }
                                                <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>
                                                <small className='text-danger'> {errors.confirm_password?.message}</small>




                                                <div className="invalid-feedback">
                                                    Please enter valid confirm password.
                                                </div>
                                                <small className=" form-text text-muted">{passwordErr}</small>
                                            </div>
                                        </div>

                                        <small className='font-12 d-block mb-2'>By joining the MACH 2 Manufacturing Network, you agree to our Partner Terms and Conditions, Privacy Policy, and Non-Disclosure Agreement:</small>
                                        {['checkbox'].map((type) => (
                                            <div key={`default-${type}`} className="mb-3">
                                                <Form.Check
                                                    checked={checkedValue}
                                                    onClick={() => setCheckedValue(!checkedValue)}
                                                    {...register('send_welcome_email', { required: false })}
                                                    type={type}
                                                    id={`default-${type}`}
                                                    label={<span>I reviewed and agree to Mach 2's <a href="#">Terms of Service</a> and  <a href="#">Privacy Policy</a></span>}

                                                />


                                            </div>
                                        ))}
                                        <div className="col-lg-12">
                                            <div className="theme-btn btn-group-right text-right">
                                                {

                                                    loading === false ?
                                                        <button type="submit" className="btn">Create An Account</button> :
                                                        <Button className='btn  spinner' variant='dark' disabled>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"

                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            Adding...
                                                        </Button>
                                                }


                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div> */}
      </Modal>
    </React.Fragment>
  );
};
export default BecomeAsupplier;
