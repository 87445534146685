import React, { useState, useEffect, useCallback,  useLayoutEffect } from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { GiAutoRepair } from "react-icons/gi";
import { Button, Spinner, Modal } from "react-bootstrap";

// icons
import { FaEdit} from "react-icons/fa";

import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link,useNavigate} from "react-router-dom";

// react bootstrap


import { getSubmitData } from "../../adminApi/api";

import { addPendingValue } from "../../../../redux/actions/action";
import { useDispatch } from "react-redux";

const CmsHome = () => {
  
  let  showDataToUser =false; 
  const [redirection,setRedirection] = useState(false);
  useLayoutEffect(() => {
      const ls=JSON.parse(localStorage.getItem("adminDetails"));
   
    // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
     
      for(let i=0; i<=ls?.my_permissions?.length;i++){
          if(ls?.my_permissions[i]?.name==="CMS"){
              
              for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                  if(ls?.my_permissions[i]?.permissions[j]?.name==="List"){
                      // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      showDataToUser = true;
                      i=ls?.my_permissions?.length;
                      break;
                  }
              }
          }
      }
      // console.log(showDataToUser);
      if(showDataToUser===false){
        // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
  }, []);
  const navigate = useNavigate();
  if (redirection) navigate('/admin/dashboard');

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();

  // const PORT = PORTNUMBER.PORTNUMBER
  const Token = localStorage.getItem("token");
  // modal active
  const [activeShow, setActiveShow] = useState(false);
  const [statusId, setStatusId] = useState();

  const [loading, setLoading] = useState(false);

  const [statusType, setStatusType] = useState();

  // const [Loading, setLoading] = useState(false);

  const [resData, setResData] = useState([]);
  const [Count, setCount] = useState(1);
  const [perPage, setPerPage] = useState();

  const [indexPage, setIndexPage] = useState(1);
  const [loadingButton, setLoadingButton] = useState(false);

  const [activePage, setActivePage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  // const [filterData, setFilterData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [filterVisibility, setFilterVisibility] = useState(true);

  const { register, control, handleSubmit, reset } = useForm();


  const activeHandleShow = (id, status) => {
    setStatusType(status);
    setStatusId(id);
    setActiveShow(true);
  };


  useEffect(() => {
    LoadTableData();
    return () => {
      LoadTableData();
    };
  }, []);

  const LoadTableData = useCallback(
    async (pageNumber) => {
      // if(id?.length){
      //     navigate("/admin/bid-management")
      // }
      setIndexPage(1);

      setLoading(false);
      await axios.get(`${BASE_URL}/cms-pages?page=${pageNumber === 1 ? 1 : pageNumber===undefined ? 1 :pageNumber}`, { headers: { authorization: `Bearer ${Token}` } })
        .then((res) => {
           setResData(res.data.result.data.data);
          // console.log(res.data.result.data.data);

          setLoading(true);
          setFilterVisibility(true);
        });

      reset();
    },
    [BASE_URL, Token, reset],
    [resData, Count]
  );

  const sendPage = (pageNumber) => {
    axios
      .get(
        `${BASE_URL}/couponcodes?page=${pageNumber}&${new URLSearchParams(filterData
        ).toString()}`,
        { headers: { authorization: `Bearer ${Token}` } }
      )
      .then((res) => {
        setResData(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);

        setLoading(true);
      });
  };
    const handlePageChange = (pageNumber) => {
    if (filterVisibility === true) {
      LoadTableData(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    } else {
      sendPage(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    }
  };
  useEffect(() => {
    LoadTableData();
  }, []);

  const onSubmit = async (data) => {
    dispatch(addPendingValue(false));
// // console.log("new",data);
  

    setLoadingButton(true);
    setIndexPage(1);

    setFilterData(data);

    data.from =
      !data.from || data.from === null || data.from === undefined
        ? ""
        : moment(data.from).format("YYYY/MM/DD");
    data.to =
      !data.to || data.to === null || data.to === undefined
        ? ""
        : moment(data.to).format("YYYY/MM/DD");

    setLoading(false);
    // sendPage()
    await axios
        getSubmitData(`/cms-pages?${new URLSearchParams(data).toString()}`,Token)
      .then((res) => {
        setLoadingButton(false);

        setResData(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);

        // setCount(res.data.data.userCount)
        // setFilterData(res.data.data.userData)
        setLoading(true);
        setFilterVisibility(false);
      });
    setLoadingButton(false);
  };
  const [todayDate,setTodayDate]=useState(null);
  return (<>
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="main-head">
                <h4>CMS</h4>
              </div>
            </div>
            {/* <div className="col-6">
                            <div className="theme-btn top-head text-end">
                                <Link to="add-new-cms" className="btn">Add CMS</Link>
                            </div>
            </div> */}
          </div>
        </div>
        <div className="table-order-box shadow rounded overflow-hidden">
          <div className="tab-head">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      {...register("title", { required: false })}
                      className="form-control"
                      placeholder="CMS Title"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                <div className="form-group">
                    <input
                      type="text"
                      {...register("meta_keyword", { required: false })}
                      className="form-control"
                      placeholder="Meta Keyword"
                    />
                  </div>
               
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <select
                      className="form-select form-control"
                      {...register("status", { required: false })}
                      aria-label="Default select example"
                    >
                      <option value="" className="d-none" selected>
                      Publish Status
                      </option>
                      <option value="">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group before-con">
                    <Controller
                      control={control}
                      name="from"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Click to select a date"
                          {...register("from", { required: false })}
                          onChange={(date) => {
                            field.onChange(date);
                            setTodayDate(date);}}
                          selected={field.value}
                          maxDate={new Date()}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group before-con after">
                    <Controller
                      control={control}
                      name="to"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          {...register("to", { required: false })}
                          placeholderText="Click to select a date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          minDate={todayDate==null? new Date() : todayDate}
                          maxDate={new Date()}
                        />
                      )}
                    />
                  </div>
                </div>
                
                <div className="col-lg-12">
                  <div className="theme-btn btn-group-right text-end">
                    {loadingButton === false ? (
                      <button type="submit" className="btn">
                        Search
                      </button>
                    ) : (
                      <Button className="btn  spinner" variant="dark" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                        />
                        Searching...
                      </Button>
                    )}
                    <p
                      type="reset"
                      onClick={() => LoadTableData()}
                      className="btn"
                    >
                      Reset
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="project-table">
        <div className="table-order shadow">
          <table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th className="text-nowrap">Title</th>
                <th className="text-nowrap">Created At</th>
                <th className="text-nowrap">Updated At</th>
                <th className="text-nowrap">Status</th>
                <th className="text-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {resData && resData.length ? (
                resData.filter((val) => {
                    const date = val.from;
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.coupon_name
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    }
                else if (val.discount_type.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
                    return val;
                } else if (val.discount){
                    return val;
                } else if (val.Coupen_Status.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return val;
                } else if (moment(date).format("MM/DD/yyyy").toLowerCase().includes(searchTerm.toLowerCase())) {
                    return val
                }
                      else {
                      return false;
                    }
                  })
                  .map((res, index) => (
                    <tr key={res.id}>
                      <td className="s-no bg-transparent" scope="row">
                        {50 * (indexPage - 1) + (index + 1)}
                      </td>
                      <td className="bg-transparent">{res.title}</td>
                      
                      <td>{moment(res.created_at).format("MM/DD/yyyy")}</td>
                      <td>{moment(res.updated_at).format("MM/DD/yyyy")}</td>
                      <td>
                        <span
                          onClick={() => activeHandleShow(res.id, res.status)}
                          className={` badge bg-success ${res.status}`}
                          style={{cursor:"default"}}
                        >
                          {res.status}
                        </span>
                      </td>
                    
                      <td className=" bg-transparent">
                        <Link
                          title="View CMS"
                          to={`edit/${res.id}`}
                          className="icons-action"
                        >
                          <FaEdit />
                         
                        </Link>
                        
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td className="no-data text-uppercase">NO CMS DATA   FOUND</td>
                </tr>
              )}
            </tbody>
          </table>
          {!loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
        </div>
        <div className="pagination">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={perPage}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        </div>
      </div>

     

      <ToastContainer />
     
    </div>


</>
  );
};
export default CmsHome;
