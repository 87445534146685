import React ,{useState}from 'react';


import ChangePassword from '../../customerPages/editProfile/changePassword';
import UpdateProfile from '../../customerPages/editProfile/updateProfile';


const EditProfileSupplier = () => {


  const USERTOKEN = localStorage.getItem("userToken")
  const SUPPLIERTOKEN = localStorage.getItem("supplierToken")


  return (
    <React.Fragment>
      <section className="pt-5 mt-3">
        <div className="container dashboard-heading-block py-3">
          <div className="row align-items-center">
            <div className="col-lg-12 dashboard-headline text-start">
              <h2>My Profile</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5 pt-3 mt-5 edit-profile-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 ">
              <div className="row align-items-center mb-4">
                <div className="col-lg-12 text-start d-flex justify-content-between align-items-center">
                  <h4 className="pull-left">Profile Information</h4>
                  {/* <button type="button" className="btn btn-primary-transparent ms-2 pull-right none-float custom-padding">
                    Edit
                  </button> */}
                </div>
              </div>
              <UpdateProfile token={USERTOKEN || SUPPLIERTOKEN}/>
          
              <div className='row'>
                <div className="col-lg-12">
                  <div className="row align-items-center mb-4">
                    <div className="col-lg-12 text-start d-flex justify-content-between align-items-center">
                      <h4 className="pull-left">Change Password</h4>
                    </div>
                  </div>
                 <ChangePassword token={USERTOKEN || SUPPLIERTOKEN}/>
                  
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section>
     
    </React.Fragment>

  )
}

export default EditProfileSupplier;