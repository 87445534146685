import {MODAL_VALUE, MODAL_REGISTER_VALUE,MODAL_RESET_VALUE,MODAL_RESET_MSG_VALUE,MODAL_ADDRESS_VALUE,MODAL_REGISTER_SUPP_VALUE,MODAL_CONDITION_VALUE,MODAL_POLICY_VALUE,MODAL_BECOME_VALUE,MODAL_NO_FILES_VALUE,MODAL_NAVIGATE_VALUE,DROPDOWN_SELECT_VALUE,DESIGN_PATH_FILE,DESIGN_RES_PATH_FILE, AUTH_HOC, CADD_REGISTER,ID_UPLOAD,USER_DATA, ADMIN_DATA, CONTACTUS_FORM, CUSTOMERLOADER,ADMINMANUALQUOTE} from '../types/type'

const intialArray  = [];
const intialState  = false;
const intialStringState  = "";

export const  adminManualQuote = (state = intialState, action)=>{
  switch(action.type){
    case ADMINMANUALQUOTE: return state = action.payload
      default:
      return state;
  }
}; 
 export const  modalValueReducer = (state = intialState, action)=>{
    switch(action.type){
      case MODAL_VALUE: return state = action.payload
        default:
        return state;
    }
  };

  export const  modalRegisterReducer = (state = intialState, action)=>{
    switch(action.type){
      case MODAL_REGISTER_VALUE: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  modalResetReducer = (state = intialState, action)=>{
    switch(action.type){
      case MODAL_RESET_VALUE: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  modalResetMsgReducer = (state = intialState, action)=>{
    switch(action.type){
      case MODAL_RESET_MSG_VALUE: return state = action.payload
        default:
        return state;
    }
    
  };


  export const  modalAddressReducer = (state = intialState, action)=>{
    switch(action.type){
      case MODAL_ADDRESS_VALUE: return state = action.payload
        default:
        return state;
    }
    
  };


  export const  modalRegisterSuppReducer = (state = intialState, action)=>{
    switch(action.type){
      case MODAL_REGISTER_SUPP_VALUE: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  modalConditionReducer = (state = intialState, action)=>{
    switch(action.type){
      case MODAL_CONDITION_VALUE: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  modalPolicyReducer = (state = intialState, action)=>{
    switch(action.type){
      case MODAL_POLICY_VALUE: return state = action.payload
        default:
        return state;
    }
    
  };

  
  export const  modalBecomeReducer = (state = intialState, action)=>{
    switch(action.type){
      case MODAL_BECOME_VALUE: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  modalNoFilesReducer = (state = intialState, action)=>{
    switch(action.type){
      case MODAL_NO_FILES_VALUE: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  modalNavigateReducer = (state = intialState, action)=>{
    switch(action.type){
      case MODAL_NAVIGATE_VALUE: return state = action.payload
        default:
        return state;
    }
    
  };


  export const  dropDownValueReducer = (state = intialStringState, action)=>{
    switch(action.type){
      case DROPDOWN_SELECT_VALUE: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  designPathValueReducer = (state = intialState, action)=>{
    switch(action.type){
      case DESIGN_PATH_FILE: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  designResPathValueReducer = (state = intialState, action)=>{
    switch(action.type){
      case DESIGN_RES_PATH_FILE: return state = action.payload
        default:
        return state;
    }
    
  };


  export const  authHocValueReducer = (state = intialState, action)=>{
    switch(action.type){
      case AUTH_HOC: return state = action.payload
        default:
        return state;
    }
    
  };


  export const  caddRegisterReducer = (state = intialState, action)=>{
    switch(action.type){
      case CADD_REGISTER: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  idUploadReducer = (state = intialStringState, action)=>{
    switch(action.type){
      case ID_UPLOAD: return state = action.payload
        default:
        return state;
    }
    
  };


  
  export const  userDataObjReducer = (state = intialArray, action)=>{
    switch(action.type){
      case USER_DATA: return state = action.payload
        default:
        return state;
    }
    
  };
  export const  AdminDataObjReducer = (state = intialArray, action)=>{
    switch(action.type){
      case ADMIN_DATA: return state = action.payload
        default:
        return state;
    }
    
  };
  export const  contactUsForm = (state = intialState, action)=>{
    switch(action.type){
      case CONTACTUS_FORM: return state = action.payload
        default:
        return state;
    }    
  };
  export const  customerLoader = (state = intialState, action)=>{
    switch(action.type){
      case CUSTOMERLOADER: return state = action.payload
        default:
        return state;
    }    
  };



