import axios from 'axios';

import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";



const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});


axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
        // window.location.href = '/'; // replace with your login page URL
        // localStorage.clear();
        localStorage.removeItem("firtName");
        localStorage.removeItem("token");
        localStorage.removeItem("adminDetails");
        document.body.classList.remove("admin-part");
        toast('Unauthorize.');
    }
    return Promise.reject(error);
  }
);


const axiosInstanceuser = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_USER+"/user",
  });
  
  
  axiosInstanceuser.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        //   window.location.href = '/'; // replace with your login page URL
          // localStorage.clear();
          localStorage.removeItem("roleUser");
          localStorage.removeItem("userToken");
          localStorage.removeItem("username");
          localStorage.removeItem("is_supplier");
          localStorage.removeItem("supplierToken");
          localStorage.removeItem("is_admin_approved");
          document.body.classList.remove("grey-bg", "top-user-dash");
          toast('Unauthorize.');
      }
      return Promise.reject(error);
    }
  );

  const axiosInstancesupplier = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_USER+"/supplier",
  });
  
  
  axiosInstancesupplier.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        //   window.location.href = '/'; // replace with your login page URL
        localStorage.removeItem("roleUser");
        localStorage.removeItem("userToken");
        localStorage.removeItem("username");
        localStorage.removeItem("is_supplier");
        localStorage.removeItem("supplierToken");
        localStorage.removeItem("is_admin_approved");
        document.body.classList.remove("grey-bg", "top-user-dash");
        toast('Unauthorize.');
      }
      return Promise.reject(error);
    }
  );

export {axiosInstance,axiosInstanceuser,axiosInstancesupplier};
