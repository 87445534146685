import {PENDING_VALUE, 
  MODAL_VALUE, 
  MODAL_REGISTER_VALUE, 
  MODAL_RESET_VALUE,
  MODAL_RESET_MSG_VALUE,
  MODAL_ADDRESS_VALUE,
  MODAL_REGISTER_SUPP_VALUE,
  MODAL_CONDITION_VALUE,
  MODAL_POLICY_VALUE,
  MODAL_BECOME_VALUE,
  MODAL_NO_FILES_VALUE,
  MODAL_NAVIGATE_VALUE,
  DROPDOWN_SELECT_VALUE,
  DESIGN_PATH_FILE,
  DESIGN_RES_PATH_FILE,
  AUTH_HOC,
  CADD_REGISTER,
  ID_UPLOAD,
  USER_DATA,
  ADMIN_DATA,
  CONTACTUS_FORM,
  CUSTOMERLOADER,
  ADMINMANUALQUOTE
} from '../types/type';



export const adminManualQuote = (quotevalue) =>{
    return{
      type: ADMINMANUALQUOTE,
      payload: quotevalue,
    }
    
  }
export const addPendingValue = (pendingValue) =>{
    return{
      type: PENDING_VALUE,
      payload: pendingValue,
    }
    
  }

  export const modalToggelValue = (madalValue) =>{
    return{
      type: MODAL_VALUE,
      payload: madalValue,
    }
    
  }

  export const modalRgisterlValue = (madalValue) =>{
    return{
      type: MODAL_REGISTER_VALUE,
      payload: madalValue,
    }
    
  }

  export const modalResetValue = (madalResetValue) =>{
    return{
      type: MODAL_RESET_VALUE,
      payload: madalResetValue,
    }
    
  }

  export const modalResetMsgValue = (madalResetValue) =>{
    return{
      type: MODAL_RESET_MSG_VALUE,
      payload: madalResetValue,
    }
    
  }


  export const modalAddressValue = (madalAddressValue) =>{
    return{
      type: MODAL_ADDRESS_VALUE,
      payload: madalAddressValue,
    }
    
  }

  export const modalRegisterlSuppValue = (madalSupplierValue) =>{
    return{
      type: MODAL_REGISTER_SUPP_VALUE,
      payload: madalSupplierValue,
    }
    
  }


  export const modalConditionValue = (madalConditionValue) =>{
    return{
      type: MODAL_CONDITION_VALUE,
      payload: madalConditionValue,
    }
    
  }

  export const modalPolicyValue = (madalPolicyValue) =>{
    return{
      type: MODAL_POLICY_VALUE,
      payload: madalPolicyValue,
    }
    
  }

  export const modalBecomeValue = (madalBecomeValue,role) =>{
    return{
      type: MODAL_BECOME_VALUE,
      payload: {madalBecomeValue,role},
    }    
  }
  export const contactUsForm = (contactUsForm) =>{
    return{
      type: CONTACTUS_FORM,
      payload: contactUsForm,
    }    
  }

  export const modalNoFilesValue = (madalNoFilesValue) =>{
    return{
      type: MODAL_NO_FILES_VALUE,
      payload: madalNoFilesValue,
    }
    
  }

  export const modalNvigateValue = (madalNavigateValue) =>{
    return{
      type: MODAL_NAVIGATE_VALUE,
      payload: madalNavigateValue,
    }
    
  }

  export const dropDownValue = (dropSelectValue) =>{
    return{
      type: DROPDOWN_SELECT_VALUE,
      payload: dropSelectValue,
    }
    
  }

  export const designPathValue = (design) =>{
    return{
      type: DESIGN_PATH_FILE,
      payload: design,
    }
    
  }

  export const designResPathValue = (designRes) =>{
    return{
      type: DESIGN_RES_PATH_FILE,
      payload: designRes,
    }
    
  }

  
  export const authHocTrueValue = (authHoc) =>{
    return{
      type: AUTH_HOC,
      payload: authHoc,
    }
    
  }

  export const caddRegisterValue = (caddRegister) =>{
    return{
      type: CADD_REGISTER,
      payload: caddRegister,
    }
    
  }


  export const idUploadValue = (idUpload) =>{
    return{
      type: ID_UPLOAD,
      payload: idUpload,
    }
    
  }

  export const userDataValue = (userData) =>{
    return{
      type: USER_DATA,
      payload: userData,
    }    
  }
  export const adminDataValue = (adminData) =>{
    return{
      type: ADMIN_DATA,
      payload: adminData,
    }    
  }
  export const customerLoader = (customerLoader) =>{
    return{
      type: CUSTOMERLOADER,
      payload: customerLoader,
    }    
  }