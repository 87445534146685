import React, { useCallback, useEffect, useState, useLayoutEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Multiselect from "multiselect-react-dropdown";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Spinner } from "react-bootstrap";

import FormValidation from "../../../../formValidation/formValidation";
import {
  getMethodData,
  getMethodDataSupplier,
  postMethodData,
} from "../../adminApi/api";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "react-phone-number-input/input";
import * as Yup from "yup";

const EditSubAdmin = () => {

  let  showDataToUser =false; 
  const [redirection,setRedirection] = useState(false);
  useLayoutEffect(() => {
      const ls=JSON.parse(localStorage.getItem("adminDetails"));
    // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
     
      for(let i=0; i<=ls?.my_permissions?.length;i++){
          if(ls?.my_permissions[i]?.name==="Sub Admin"){
              
              for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                  if(ls?.my_permissions[i]?.permissions[j]?.name==="Edit "){
                      // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      showDataToUser = true;
                      i=ls?.my_permissions?.length;
                      break;
                  }
              }
          }
      }
      // console.log(showDataToUser);
      if(showDataToUser===false){
        // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
  }, []);
  const navigate = useNavigate();
  if (redirection) navigate('/admin/sub-admin');
  const { id } = useParams();
  // const PORT = PORTNUMBER.PORTNUMBER
  const Token = localStorage.getItem("token");

  const [editData, setEditData] = useState(["not"]);
  const [mobileErr, setMobileErr] = useState([""]);
  const [emailErr, setEmailErr] = useState();
  const [loading, setLoading] = useState(false);
  const FormValidationNew = FormValidation;
  const [value, setValue] = useState();
  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("The email must be a valid email. "),
    mobile: Yup.string().min(14).required("A phone number is required"),

    // image: Yup.mixed().required('Image file is required')
    //     .test("type", "Only .pdf Formate is accepted", (value) => {
    //         return value && (
    //         value[0].type === 'application/pdf'
    //         );
    //     })
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const editSupplier = useCallback(() => {
    getMethodData(`/sub-admins/edit/${id}`, Token).then((res) => {
      setEditData(res.data.result.data);

      setValue("+1" + res.data.result.data.mobile.replace(/[()\ \s-]+/g, ""));
    });
  }, [id]);

  useEffect(() => {
    editSupplier();
  }, [getMethodData, id]);

  // // console.log(manufacturing);

  const onSubmit = (data) => {
    setLoading(true);
    var inputs = document.getElementsByClassName("form-control");
    const fd = new FormData();
    // console.log("inputs", inputs);
    for (let i = 0; i < inputs.length; i++) {
      fd.append(inputs[i].name, inputs[i].value);
      // // console.log("gfggg",inputs[i].name, inputs[i].value)
    }

    // if (imageFiles > 0) {
    //   const File = document.getElementById("document").files[0];
    //   fd.append("image", File);
    // }
  // return;
    postMethodData(`/sub-admins/update/${id}`, fd, Token)
      .then((res) => {
        if (res?.data?.status === true) {
          setLoading(false);
          toast(res?.data?.message);
          navigate("/admin/sub-admin");
          reset();
        } else {
          setLoading(false);
          setMobileErr(res?.data?.result?.errors?.phone);
          setEmailErr(res?.data?.result?.errors?.email);
          toast.error("Somthing Went Wrong!");
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  useEffect(() => {
    FormValidationNew();
  }, [FormValidationNew]);

 
  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-sm-6">
              <div className="main-head">
                <h4>Edit Sub Admin</h4>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="theme-btn text-end top-head">
                <Link to="/admin/sub-admin" className="btn">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="table-order-box shadow">
          <div className="tab-head">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="needs-validation"
              noValidate
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      maxLength="15"
                      defaultValue={editData.first_name}
                      className="form-control"
                      id="validationCustom01"
                      {...register("first_name", { required: false })}
                      placeholder="First Name"
                      required
                    />
                    <div className="invalid-feedback">
                      Please Enter Sub admin First Name.
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      maxLength="15"
                      defaultValue={editData.last_name}
                      className="form-control"
                      id="validationCustom01"
                      {...register("last_name", { required: false })}
                      placeholder="Last Name"
                      required
                    />
                    <div className="invalid-feedback">
                      Please Enter Sub admin Last Name.
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      maxLength="100"
                      defaultValue={editData.email}
                      className="form-control"
                      id="validationCustom02"
                      {...register("email", { required: false })}
                      placeholder="Email"
                      required
                    />
                    <small className="text-danger">
                      
                      {errors.email?.message}
                    </small>
                    <small className="form-text text-muted d-block">
                      {emailErr}
                    </small>
                    <div className="invalid-feedback">
                      Please use @ for a valid email.
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Mobile</label>
                    {/* <input type="text" maxLength="15" defaultValue={editData.phone} className="form-control" id="validationCustom03" {...register('phone', { required: false })} placeholder="Mobile" required /> */}
                    <Input
                      className="form-control"
                      minLength={14}
                      maxLength={14}
                      value={value}
                      type="tel"
                      onChange={setValue}
                      name="mobile"
                      autoFocus
                      defaultCountry="US"
                      placeholder="Enter phone number"
                      {...register("mobile", { required: true })}
                      id="validationCustom03"
                      required
                    />
                    <small className="font-12 d-block">
                      E.g (xxx) xxx-xxxx , No Need for Country Code
                    </small>
                    <small className="text-danger">
                      
                      {errors.mobile?.message}
                    </small>

                    <small className="form-text text-muted">{mobileErr}</small>
                    <div className="invalid-feedback">
                      Please enter mobile number.
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-select form-control"
                      {...register("status", { required: false })}
                      id="validationCustom04"
                      aria-label="Default select example"
                      required
                    >
                      <option
                        className="disabled-value d-none"
                        value={editData.status === "active" ? 1 : 0}
                      >
                        {editData.status}
                      </option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                    <div className="invalid-feedback">
                      Please select status.
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Select Role</label>
                    <select
                      className="form-select form-control"
                      {...register("role_id", { required: false })}
                      id="validationCustom04"
                      aria-label="Default select example"
                      required
                    >
                      <option
                        className="disabled-value d-none"
                        value={editData.role_id === 2 ? 2 : editData.role_id === 3 ? 3 : ''}
                      >
                        {editData.role_id === 2
                          ? "Sales"
                          : editData.role_id === 3
                         ?
                          "Finance"
                          :
                          ""
                          }
                      </option>
                      <option value="3">Finance</option>
                      <option value="2">Sales</option>
                    </select>
                    <div className="invalid-feedback">
                      Please select Role.
                    </div>
                  </div>
                </div>


                <div className="col-lg-12">
                  <div className="theme-btn btn-group-right text-end">
                    {loading === false ? (
                      <button type="submit" className="btn">
                        Update
                      </button>
                    ) : (
                      <Button className="btn  spinner" variant="dark" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                        />
                        Updating...
                      </Button>
                    )}
                    <Link to="/admin/sub-admin" className="btn">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};
export default EditSubAdmin;
