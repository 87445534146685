import React from 'react'
import {Link} from 'react-router-dom'


const PrivacyPolicy = () => {
    return (
        <React.Fragment>
            <section className="banner-section mh-auto pt-2 pb-2 pt-sm-5 pb-sm-5">
                <div className="container">
                    <h1 className='fs-1 mb-2 text-white'>Privacy Policy</h1>
                    <h2 className='fs-3 mb-2 text-white'>Privacy Policy</h2>
                    <h3 className='fs-4 text-white'>Last Updated: 7/14/2022</h3>
                </div>
            </section>
            <div className='container'>
                <div className='pt-2 pt-sm-5 pb-2 pb-sm-5 mb-2'>


                    <p>This privacy policy (policy) will help you understand how Mach 2 Tool and Manufacturing (Mach2,us,
                        we, our) uses and protects the data you provide to us when you visit and use <Link to="https://mach2mfg.testingdemo.net/">mach2mfg.com</Link>
                        (website, service).

                    </p>


                    <h4 className='mt-5 user-text'>When visiting mach2mfg.com, below is a list of data we may collect:  </h4>

                    <ul>
                        <li>Your IP addresses </li>
                        <li>As a user, when creating an account, we may collect personal information, such as your full name,
                            phone number, email address, company name, job title and password to log-in to the website. To
                            process your order and any future orders we may securely retain your credit card or banking
                            information to provide faster service for future orders and deliver services</li>
                        <li>As a supplier, and when you use support features or access mach2mfg.com, information used to
                            complete the transaction may include your name, e-mail address, bank account information,
                            social security number and/or tax identification number, billing information and shipping
                            information</li>
                        <li>Photocopies of your identification and other support related documents may be requested to
                            verify your identity and your company information.
                        </li>
                        <li>Data collected via the text portal or by email may be stored and used for contact and support
                            purposes</li>


                    </ul>
                    <h4 className='mt-5 user-text'>Why We Collect Your Data </h4>
                    <p>Why We Collect Your Data </p>
                    <ul>
                        <li>To better understand your needs. </li>
                        <li>To optimize the ordering, manufacturing and shipping processes </li>
                        <li>To improve our services and products.
                        </li>
                        <li>To send you promotional emails containing the information we think you will find interesting.
                        </li>
                        <li>To contact you to fill out surveys and participate in other types of market research.

                        </li>
                        <li>To customize our website according to your online behavior and personal preferences.
                        </li>

                    </ul>
                    <p><span className='fw-bold'>Safeguarding and Securing the Data-</span> Mach 2 Tool and Manufacturing is committed to securing your data
                        and keeping it confidential. Mach2mfg.com has done all in its power to prevent data theft, unauthorized
                        access, and disclosure by implementing the latest technologies and software, which help us safeguard all
                        the information we collect online.
                    </p>
                    <h4 className='mt-5 user-text'>Our Cookie Policy.</h4>
                    <p>Once you agree to allow our website to use cookies, you also agree to use the data it collects regarding
                        your online behavior (analyze web traffic, web pages you spend the most time on, and websites you visit).</p>
                    <p>The data we collect by using cookies is used to customize our website to your needs. After we use the data
                        for statistical analysis, the data is completely removed from our systems.
                    </p>
                    <p>Please note that cookies don't allow us to gain control of your computer in any way. They are strictly used
                        to monitor which pages you find useful and which you do not so that we can provide a better experience
                        for you.</p>
                    <p>If you want to disable cookies, you can do it by accessing the settings of your internet browser. You can
                        visit <Link to="www.internetcookies.com">www.internetcookies.com</Link>, which contains comprehensive information on how to do this on a wide
                        variety of browsers and devices. </p>
                    <h4 className='mt-5 user-text'>Links to Other Websites
                    </h4>

                    <p>Our website contains links that lead to other websites. If you click on these links Mach 2 Tool and
                        Manufacturing is not held responsible for your data and privacy protection. Visiting those websites is not
                        governed by this privacy policy agreement. Make sure to read the privacy policy documentation of the
                        website you go to from our website. </p>
                    <h4 className='mt-5 user-text'>Restricting the Collection of your Personal Data
                    </h4>
                    <p>At some point, you might wish to restrict the use and collection of your personal data. You can achieve this
                        by doing the following:</p>
                    <p>When you are filling the forms on the website, make sure to check if there is a box which you can leave
                        unchecked, if you don't want to disclose your personal information. </p>
                    <p>If you have already agreed to share your information with us, feel free to contact us via email and we will
                        be more than happy to change this for you. </p>
                    <p>Mach 2 Tool and Manufacturing will not lease, sell or distribute your personal information to any third
                        parties, unless we have your permission. We might do so if the law forces us. Your personal information will
                        be used when we need to send you promotional materials if you agree to this privacy policy.</p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PrivacyPolicy;