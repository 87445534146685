import React, { useEffect, useState, useCallback} from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios'

import { Modal, Button } from 'react-bootstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import FormValidation from '../../../../formValidation/formValidation';
import { postMethodData } from '../../adminApi/api';
import {useNavigate } from 'react-router-dom';




const ChangePassword = (props) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [visibilityNew, setVisibilityNew] = useState(true)
    const [visibility, setVisibility] = useState(true)
    const [visibilityConfirm, setVisibilityConfirm] = useState(true)
    const [passErr, setPassErr] = useState();
    const [currentpPassErr, setCurrentPassErr] = useState();
    const Token = localStorage.getItem("token")
    const navigate = useNavigate()

    const validationSchema = Yup.object().shape({
        new_password: Yup.string().required("Password is required").min(6, 'Password is too short - should be 6 chars minimum.').max(18, 'Password is too long - should be 18 chars minimum.').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
            "Please Check Password Format"
        ),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("new_password"), null], "Passwords must match").min(6, 'Confirm password is too short - should be 6 chars minimum.').max(18, 'Password is too long - should be 18 chars minimum.')
            .required("Confirm Password is required"),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    
    const FormValidationNew = FormValidation

    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }

    const pwVisibilityNew = (value) => {
        setVisibilityNew(value)
    }

    // const LogOut = () => {
    //    localStorage.clear()
    //     toast("You have logged out successfully.")
    //     navigate("/admin")


    // }



    const onSubmit = (data) => {
    
        postMethodData(props.api, data, Token)
                .then((res) => {

                    if (res.data.status !== false) {
                        reset();

                        toast.success(res.data.message)
                        // LogOut()
                        setCurrentPassErr("")
                        props.onHide()



                    } else {
                        toast.success(res.data.message)
                        setCurrentPassErr(res.data.message)
                        
                    }
                })
       

    }
    const closeModal = useCallback(()=>{
        props.onHide()
        reset()
    },[])

    useEffect(() => {
        FormValidationNew()
    }, [FormValidationNew])


    return (
        <div className="modal-edit">
            <Modal {...props} className="edit-content">

                <div className="max-new-second">
                    <div className="main-theme bg-theme border shadow">
                        <Modal.Header closeButton onClick={()=> closeModal()}>
                            <div className="select-steps-box">
                                <div className="input-head">
                                    <h4>Change Password</h4>
                                </div>
                            </div>
                        </Modal.Header>
                        <section className="select-state">
                            <div className="state-bor">
                                <div className="log-in otp-num">

                                    <div className="select-box-top pr-0">
                                        <div className="input-box">
                                            <form onSubmit={handleSubmit(onSubmit)} >
                                                <div className="col-12 p-0">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className={`${props.dnone} form-group icon-position`}>
                                                                <input type={visibility ? "password" : "text"} maxLength="18" className="form-control" id="validation1"  {...register('old_password', { required: false })} placeholder="Current Password"  />
                                                                {
                                                                    visibility ?
                                                                        <span className="icon" onClick={() => pwVisibility(false)}>
                                                                            <FaEyeSlash />
                                                                        </span> :
                                                                        <span className="icon" onClick={() => pwVisibility(true)}>
                                                                            <FaEye />
                                                                        </span>
                                                                }
                                                                <small id="emailHelp" className=" form-text text-muted">{currentpPassErr}</small>
                                                                <div className="invalid-feedback">
                                                                    Please Enter Old Password.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group icon-position">
                                                                <input type={visibilityNew ? "password" : "text"} maxLength="18" className="form-control" id="validation2"  {...register('new_password', { required: false })} placeholder="New Password"  />
                                                                {
                                                                    visibilityNew ?
                                                                        <span className="icon" onClick={() => pwVisibilityNew(false)}>
                                                                            <FaEyeSlash />
                                                                        </span> :
                                                                        <span className="icon" onClick={() => pwVisibilityNew(true)}>
                                                                            <FaEye />
                                                                        </span>
                                                                }
                                                                   <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>
                                                                 <small className='text-danger'> {errors.new_password?.message}</small>

                                                                <div className="invalid-feedback">
                                                                    Please Enter New Password.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group icon-position">
                                                                <input type={visibilityConfirm ? "password" : "text"} maxLength="18" className="form-control" id="validation3" {...register('confirm_password', { required: false })} placeholder="Confirm Password"  />
                                                                {
                                                                    visibilityConfirm ?
                                                                        <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                                            <FaEyeSlash />
                                                                        </span> :
                                                                        <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                                            <FaEye />
                                                                        </span>
                                                                }
                                                                   <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>
                                                                 <small className='text-danger'> {errors.confirm_password?.message}</small>
                                                                <div className="invalid-feedback">
                                                                    Please Enter Confirm Password.
                                                                </div>
                                                                <small id="emailHelp" className=" form-text text-muted">{passErr}</small>
                                                            </div>
                                                        </div>

                                                      
                                                            <div className="theme-btn btn-group-right">
                                                                <button type="submit" className="btn">
                                                                    Update
                                                                </button>
                                                                <Button type="reset" className="btn" variant="primary" onClick={()=> closeModal()}>
                                                                    Cancel
                                                                </Button>
                                                            </div>
                                                        
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </Modal>
        </div>
    )



}

export default ChangePassword;