/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState,useLayoutEffect } from "react";
import { Rating } from "react-simple-star-rating";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { FaCube, FaDownload, FaEdit, FaEye, FaShippingFast, FaTruck } from "react-icons/fa";
import { ImageObj } from "../../../../assets/images/imgeObject";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Spinner, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { getMethodData, postMethodData } from "../../adminApi/api";
import * as Yup from "yup";
import moment from "moment";
import { GiAutoRepair } from "react-icons/gi";
import { FaFilePdf, FaStar } from "react-icons/fa";
import { MdFeedback } from "react-icons/md";
import fileDownload from 'react-file-download';

const EditOrderManagement = () => {
  let  showDataToUser =false; 
  const [downloadcadfile,setdownloadcadfile]=useState(false)
  const [ViewAttachments, setViewAttachments] = useState(false)
  const [CreateShipment, setCreateShipment] = useState(false)
  const [ChangeOrderStatus, setChangeOrderStatus] = useState(false)
  const [unattendorder, setUnattendorder] = useState(false)
  const [redirection,setRedirection] = useState(false);
  useLayoutEffect(() => {
    const ls=JSON.parse(localStorage.getItem("adminDetails"));
 // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
    for(let i=0; i<=ls?.my_permissions?.length;i++){
        if(ls?.my_permissions[i]?.name==="Order Management"){
            
            for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                if(ls?.my_permissions[i]?.permissions[j]?.name==='Supplier Orders List' || 
                ls?.my_permissions[i]?.permissions[j]?.name==='Supplier Orders List' || ls?.my_permissions[i]?.permissions[j]?.name==="Opened Bid Orders List" || ls?.my_permissions[i]?.permissions[j]?.name==='Unattended Orders List'){
                    // console.log(ls?.my_permissions[i]?.permissions[j].name);
                    showDataToUser = true;
                }
               
              if(ls?.my_permissions[i]?.permissions[j]?.name==="View Attachments"){
                // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                setViewAttachments(true);
              }
              if (
                ls?.my_permissions[i]?.permissions[j]?.name === "Download  Auto Cad  File"
              ) {
                // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                setdownloadcadfile(true);
              }
              if (
                ls?.my_permissions[i]?.permissions[j]?.name === "Create Shipment"
              ) {
                // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                setCreateShipment(true);
              }
              if (
                ls?.my_permissions[i]?.permissions[j]?.name === "Change  Order Status"
              ) {
                // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                setChangeOrderStatus(true);
              }
              if (
                ls?.my_permissions[i]?.permissions[j]?.name === "Unattended Order Asign to Admin"
              ) {
                // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                setUnattendorder(true);
              }
            }
        }
    }
    // console.log(showDataToUser);
    if(showDataToUser===false){
      // console.log("showDataToUser ",showDataToUser)
      setRedirection(true);
    }
}, []);
    const navigate = useNavigate();
    if (redirection) navigate('/admin/dashboard');
  //attachment state open close
  const [mydata, setmydata] = useState(false);
  //attachment data state
  const [attachmentData, setAttachmentData] = useState([]);

  const { id } = useParams();
  // const PORT = PORTNUMBER.PORTNUMBER
  const Token = localStorage.getItem("token");
  const [editData, setEditData] = useState(["not"]);
  const [loading, setLoading] = useState(false);
  const [partid, setpartid] = useState();
  const [status, setstatus] = useState();
  const [currentstate, setcurrentstate] = useState("0");
  const [activeShow2, setActiveShow2] = useState(false);
  const [activeShow3, setActiveShow3] = useState(false);
  const [show, setShow] = useState(false);
  //shipping modal and state
  const [shipData, setShipData] = useState({});
  const [shippingModal, setShippingModal] = useState(false);
  const handleClose = () => {
    setShow(false);
    setreview({});
  };
  const handleShow = () => setShow(true);

  const [qulity, setqulity] = useState(0);
  const [on_time_delivery, seton_time_delivery] = useState(0);
  const [communication, setcommunication] = useState(0);
  const [engagement, setengagement] = useState(0);

  const [review, setreview] = useState({
    order_id: "",
    part_id: "",
    qulity: "",
    on_time_delivery: "",
    communication: "",
    engagement: "",
  });
  const { register, control, handleSubmit, reset } = useForm();
  const {
    register: register2,
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm();

  const getdetails = () => {
    getMethodData(`/orders/details/${id}`, Token).then((res) => {
      setEditData(res.data.result.data);
    });
  };
  const ChnageStatus = () => {
    setLoading(true);
    postMethodData(
      `/orders/change-status`,
      {
        status: status,
        order_id: id,
        part_id: partid,
      },
      Token
    ).then((res) => {
      if (res.data.status == true) {
        activeHandleClose2();
        activeHandleClose3();
        getdetails();
        toast.success(res.data.message);
        setLoading(false);
      } else {
        activeHandleClose3();
        activeHandleClose2();
        setLoading(false);
        toast.warning(res.data.message);
      }
    });
  };
const checkDownload=()=>{
  if(downloadcadfile === false ){ 
    alert('Permission not Allowed')
  };
}
  const unattended = (data) => {
    if(unattendorder===true){
      getMethodData(`/assign-unattended-order-to-admin/${data}`, Token).then(
        (res) => {
          if (res.data.status === true) {
            getdetails();
            toast.success("Part Order Assign to Admin Succesfully");
          }
        }
      );
    }else{
      alert("permission not allowed")
    }
  
  };

  const activeHandleClose2 = () => {
    setActiveShow2(false);
  };

  const activeHandleShow2 = () => {
    setActiveShow2(true);
  };
  const activeHandleClose3 = () => {
    setActiveShow3(false);
  };
  const activeShippingModal = () => {
    setShippingModal(!shippingModal);
  };

  const activeHandleShow3 = () => {
    setActiveShow3(true);
  };
  useEffect(() => {
    getdetails();
  }, []);

  const stars = (length) => {
    var stars_array = [];
    for (let index = 1; index <= 5; index++) {
      if (index <= length) {
        stars_array.push(<FaStar className="text-warning" />);
      } else {
        stars_array.push(<FaStar className="text-secondary" />);
      }
    }
    return stars_array;
  };

  const AddReview = (data) => {
    if (qulity === 0) {
      toast.warning("Please Select Quality Stars.");
    } else if (on_time_delivery === 0) {
      toast.warning("Please Select On time delivery Stars.");
    } else if (communication === 0) {
      toast.warning("Please Select On time Communication Stars.");
    } else if (communication === 0) {
      toast.warning("Please Select On time Communication Stars.");
    } else if (engagement === 0) {
      toast.warning("Please Select On time Engagement Stars.");
    } else {
      postMethodData(
        `/order-reviews/store`,
        {
          order_id: review.order_id,
          part_id: review.part_id,
          qulity: qulity,
          on_time_delivery: on_time_delivery,
          communication: communication,
          engagement: engagement,
        },
        Token
      ).then((res) => {
        // // console.log(res)
        if (res.data.status == true) {
          handleClose();
          getdetails();
          toast.success(res.data.message);
          setLoading(true);
        } else {
          handleClose();
          setLoading(true);
          toast.warning(res.data.message);
        }
      });
    }
  };
  const changeShipmentStatus = (data) => {
    //// console.log('changing shipping status',data);
    postMethodData(`/orders/create-order-part-shipment`, data, Token).then(
      (res) => {
        setLoading(true);
        // // console.log(res)
        if (res.data.status === true) {
          // console.log("response status", res.status);
          // console.log("response data", res.data);
          getdetails();
          toast.success(res.data.message);
          setLoading(false);

        } else {
          // console.log("response data", res);
          setLoading(false);
          toast.warning(res.data.message);
        }
      }
    );

    setShippingModal(!shippingModal);
  };
const handleviewattachment=(data)=>{
  if(ViewAttachments===true){
    setmydata(!mydata);
    setAttachmentData(
      JSON.parse(data)
    );
  }
  else{
    alert('permission not allowed')
  }
}
const handlechangestatus=(sub_order_status,id)=>{

  if(ChangeOrderStatus===true){
    if(editData?.payment_method !=="Bank Transfer" || editData?.payment_status !== "Pending"){
      activeHandleShow2();
      setstatus(sub_order_status);
      setpartid(id);
    }
    else{
      window.alert("Payment is Pending");
    }
 }
 else{
  alert('permission not allowed')
 }
}
const handleShipment=(id,bid)=>{
  if(CreateShipment===true){
    if(editData?.payment_method !=="Bank Transfer" || editData?.payment_status !== "Pending"){
    activeShippingModal();
    setShipData({
      order_id: id,
      sub_order_id:bid,
    });
  }
  else{
    window.alert("Payment is Pending");
  }
 }
 else{
  alert('permission not allowed')
 }
}
const handleStatus=(status,currstat,pid)=>{
 if(ChangeOrderStatus===true){
  activeHandleShow3();
  setstatus(
    status
  );
  setcurrentstate(currstat);
  setpartid(pid);
 }
 else{
  alert('permission not allowed')
 }
}


const [trackingShow,setTrackingShow]=useState(false);

const [trackingDetails,setTrackingDetails]=useState(null);

const getTrackingData=(data)=>{
  setTrackingShow(!trackingShow);
 
  getMethodData(`/shipment-tracking?tracking_number=${data.shipment_tracking_number}`, Token ).then(
     (res) => {
      // console.log('shipping res status',res.status)
      // console.log('shipping res.data ',res.data)
      if(res.data.events.length===0){
        toast.warning('no data found');
      }
      else{
        setTrackingDetails(res.data.events);
      }
     }
   );
  }


  
  const handleDownload = (data,fileurl) => {
    const fileUrl = data;
    const fileName = fileurl;
    fileDownload(fileUrl, fileName);
  };


  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="main-head">
                <h4>Order Details -#{editData?.order_id ?? 0}</h4>
              </div>
            </div>
            <div className="col-6">
              <div className="theme-btn text-end top-head">
                <button className="btn" onClick={() => navigate(-2)}>
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-order-box shadow">
          <div className="tab-head">
            <div className="row">
              <div className="col-lg-7 position-relative">
                {/* {
                                    loading ?
                                        <div className="loader-wrapper">
                                            <div className="loader">
                                                <div className="loading-svg">
                                                    <GiAutoRepair />
                                                </div>
                                            </div>
                                        </div> : ""
                                } */}
                <div className="row">
                  <div className="col-6">
                    <p>Customer Name</p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span className="bold-text">
                        {editData?.customer?.first_name}{" "}
                        {editData?.customer?.last_name}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Order ID</p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span className="bold-text">#{editData?.order_id}</span>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Order Date</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      {moment(editData?.date).format("MM/DD/yyyy")}
                    </span>
                    <p />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Order Amount</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">${editData?.final_amt}</span>
                    <p />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Discount Amount</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      ${editData?.discounted_amt ?? 0}
                    </span>
                    <p />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Shipping Fees</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      ${editData?.shipping_price}
                    </span>
                    <p />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Quantity</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">{editData?.qty}</span>
                    <p />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Order Status</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">{editData?.status}</span>
                    <p />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Payment Method</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      {editData?.payment_method}
                    </span>
                    <p />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Payment Status</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      {editData?.payment_status}
                    </span>
                    <p />
                  </div>
                </div>
                {editData?.cancel_reason && (
                  <div className="row">
                    <div className="col-6">
                      <p>Cancel Reason</p>
                    </div>
                    <div className="col-6">
                      <span className="bold-text">
                        {editData?.cancel_reason}
                      </span>
                      <p />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-5">
                <div className="grey-box mb-3">
                  <h5>Billing Address</h5>
                  <ul className="address-list">
                    <li>Name </li>
                    <li>{editData?.billing_name}</li>
                    <li>Address </li>
                    <li>{editData?.biling_address}</li>
                    <li>City </li>
                    <li>{editData?.billing_city}</li>
                    <li>State </li>
                    <li>{editData?.billing_state}</li>
                    <li>Country </li>
                    <li> {editData?.billing_country}</li>
                    <li>Zip code </li>
                    <li> {editData?.billing_ziocode}</li>
                    {/* <li>Cancel Reason </li>
                    <li> {editData?.cancel_reason}</li> */}
                  </ul>
                </div>

                <div className="grey-box">
                  <h5>Shipping Address</h5>
                  <ul className="address-list">
                    <li>Name </li>
                    <li> {editData?.delivery_name}</li>
                    <li>Address </li>
                    <li> {editData?.delievry_address}</li>
                    <li>City </li>
                    <li> {editData?.delivery_city}</li>
                    <li>State </li>
                    <li> {editData?.delivery_state}</li>
                    <li>Country </li>
                    <li>{editData?.deivery_country}</li>
                    <li>Zip code </li>
                    <li> {editData?.delivery_zipcode}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-12 d-flex justify-content-center justify-content-lg-start dashboard-heading-block">
                {/* <img src={ImageObj.PartDetail} width="56px" height="56px" className="mt-2 me-4 d-none d-lg-block" /> */}
                <div>
                  <h2 className="text-center text-lg-start">Part Details</h2>
                </div>
              </div>
              <div className="col-lg-3" />
            </div>

            <div className="gx-0 grid-data">
              <div className="row">
                <div className="col-lg-12">
                  {editData &&
                  editData !== undefined &&
                  editData.order_product !== undefined ? (
                    editData?.order_product?.map((res, i) => {
                      return (
                        <>
                          <div className="row gx-0 grid-head-row">
                            <div className="col">
                              <span className="data-value">Sr. No.</span>
                            </div>
                            <div className="col">
                              <span className="data-value">Sub Order Id</span>
                            </div>
                            <div className="col">
                              <span className="data-value">Part Name</span>
                            </div>
                            <div className="col">
                              <span className="data-value">Unit Cost</span>
                            </div>
                            <div className="col">
                              <span className="data-value">Quantity</span>
                            </div>
                            {/* <div className="col">
                              <span className="data-value">Price</span>
                            </div> */}

                            <div className="col">
                              <span className="data-value">Total Price</span>
                            </div>
                            <div className="col">
                              <span className="data-value">Action</span>
                            </div>
                          </div>
                          <div className="row px-0 gx-0 grid-data-row grey-box">
                            <div className="row p-0 m-0">
                              <div className="col" data-label="Sr. No.: ">
                                <span className="data-value">{i + 1}</span>
                              </div>
                              <div className="col" data-label="Part Id:  ">
                                <span className="data-value">
                                  #{res?.bid_part?.bid_id}
                                </span>
                              </div>
                              <div className="col" data-label="Part Name: ">
                                <span className="data-value">
                                  {res.file_name}
                                </span>
                              </div>
                              <div className="col" data-label="Unit Cost: ">
                                <span className="data-value">
                                  ${res.unit_price}
                                </span>
                              </div>
                              <div className="col" data-label="Quantity: ">
                                <span className="data-value">{res.qty}</span>
                              </div>
                              {/* <div className="col" data-label="Price: ">
                                <span className="data-value">${res.price}</span>
                              </div> */}
                              <div className="col" data-label="Total: ">
                                <span className="data-value">
                                  ${res.total_price}
                                </span>
                              </div>

                              <div style={{ fontSize: "16px"}} className="col" data-label="Action: ">
                                {res?.sub_order_status === "Delivered" &&
                                res?.is_admin_review_exist !== 1 &&
                                res.bid_part?.is_admin_sub_order === 0 ? (
                                  <MdFeedback
                                    onClick={() => {
                                      handleShow();
                                      setreview({
                                        order_id: editData.id,
                                        part_id: res.id,
                                      });
                                    }}
                                    className="me-3"
                                    title=""
                                  />
                                ) : (
                                  ""
                                )}
                                <FaEye
                                  className="text-black me-3"
                                  onClick={() => {handleviewattachment(res?.attachments)}}
                                  title="Attachment Data"
                                />
                               <a
                                download
                                // target={downloadcadfile ===true ? "_blank":""}
                                href={res.auto_cad_file_path}
                                className="icons-action"
                                title="Download 3D File"
                                // onClick={()=> {handleDownload(res.auto_cad_file_path,res?.file_name);checkDownload();}}  
                              >
                                <FaCube className="text-primary"/> 
                              </a>
                                {res.bid_part?.is_admin_sub_order === 0 &&
                                (res.bid_part?.status === "Open" ||
                                  res.bid_part?.status === "Upcoming" ||
                                  res.bid_part?.status === "Expired") ? (
                                  ""
                                ) : (
                                  <FaEdit
                                    className="ms-3"
                                    onClick={() => {
                                      handlechangestatus(res.sub_order_status,res.id)
                                     
                                    }}
                                    title="Edit"
                                  />
                                )}
                                {editData.order_product[i].sub_order_status !==
                                  "Cancellation Requested" ||
                                editData.order_product[i].sub_order_status !==
                                  "Return Requested" ? (
                                  editData.order_product[i].bid_part
                                    .shipment_id == null ||
                                  editData.order_product[i].bid_part
                                    .shipment_tracking_number == null ? (
                                    <>
                                    {res?.order_status_history[3].is_complete === 1 && <FaShippingFast
                                      className="ms-3"
                                      title="Create Shipment"
                                      onClick={() => {
                                       handleShipment( editData.id, editData.order_product[i].bid_part?.id)
                                      }}
                                    />}
                                      
                                    </>
                                  ) : editData.order_product[i].bid_part
                                      .shipment_labels !== null ? (
                                    <a
                                      target={"_blank"}
                                      href={
                                        JSON.parse(
                                          editData.order_product[i].bid_part
                                            .shipment_labels
                                        ).pdf
                                      }
                                      className="ms-2"
                                    >
                                      <FaFilePdf title="Download Shipment Details" />
                                    </a>
                                  ) : null
                                ) : null}

                        {res?.order_status_history?.find(state => state?.status === "Order Shipped" && state?.is_complete === 1) ? 
                          <FaTruck className="fs-5 fw-normal ms-2 pointer"  
                          onClick={()=>{getTrackingData({
                            shipment_tracking_number: editData.order_product[i].shipment_tracking_number
                          });}} 

                          title="Track Your Product"/> 
                          :''
                          }
                              </div>
                            </div>

                            <div className="d-flex flex-wrap">
                              <div className="col" data-label="Sr. No.: ">
                                {/* <img
                                  className="h-115 w-100"
                                  src={ImageObj.ProductBig}
                                /> */}
                              </div>
                              <div
                                style={{ marginRight: "71px" }}
                                className="col"
                                data-label="Sr. No.: "
                              >
                                <span className="data-value me-3">
                                  <strong>Dimensions:-</strong> {res.dimensions}
                                </span>
                                <span className="data-value me-3">
                                  <strong>Volume:-</strong> {res.volume}
                                </span>
                                <span className="data-value me-3">
                                  <strong>File Units:-</strong> {res.file_units}
                                </span>
                                <span className="data-value me-3">
                                  <strong>Manufacturing Process:-</strong>{" "}
                                  {res.manufacturing_process}{" "}
                                  {res.custom_manufacturing_process ?? ""}
                                </span>
                                <span className="data-value me-3">
                                <strong>Material:-</strong> {res.material}{" "}
                              </span>
                              <span className="data-value me-3">
                                  <strong>Custom Material Value:-</strong> {res.custom_material ?? ""}
                                </span>
                                <span className="data-value me-3">
                                <strong>Coating:-</strong> {res.finish ?? ""}
                              </span>
                              <span className="data-value me-3">
                                <strong>Custom Coating:-</strong>
                                {res.custom_finish ?? ""}
                              </span>
                                {/* <span className="data-value me-3">
                                  <strong>Threads:-</strong> {res.threads}
                                </span> */}
                                <span className="data-value me-3">
                                  <strong>Tolerance:-</strong> {res.tolerance}
                                </span>
                                <span className="data-value me-3">
                                  <strong>Surface Roughness:-</strong>{" "}
                                  {res.surface_roughness}
                                </span>
                                <span className="data-value me-3">
                                  <strong>Part Marking:-</strong>{" "}
                                  {res.part_marking}
                                </span>
                                <span className="data-value me-3">
                                <strong>Inspection:-</strong> {res.inspection}{" "}
                              </span>
                              <span className="data-value me-3">
                                <strong>Custom Inspection:-</strong>
                                {res.custom_inspection ?? ""}
                              </span>
                                <span className="data-value me-3">
                                  <strong>
                                    Part Quality Certifications:-
                                  </strong>{" "}
                                  {res.part_qualifications_certifications}
                                </span>
                                <span className="data-value me-3">
                                  <strong>Material Certifications:-</strong>{" "}
                                  {res.material_certifications}
                                </span>
                                <span className="data-value me-3">
                                  <strong>Notes:-</strong> {res.notes ?? ""}
                                </span>
                              </div>

                              {res.bid_part?.is_admin_sub_order === 1 && (
                                <div
                                  className={`col-2 me-4 p-2 d-flex align-items-center justify-content-center rounded ${
                                    res.bid_part?.is_admin_sub_order === 1
                                      ? "bg-success"
                                      : ""
                                  }`}
                                  data-label="Sr. No.: "
                                >
                                  <span className={`data-value text-white`}>
                                    <p className="text-white text-center">
                                      Awarded To:
                                    </p>
                                    <h5 className="text-white text-center">
                                      Admin
                                    </h5>
                                    {/* <p className="text-white">Status: {res.bid_part?.status}</p> */}
                                  </span>
                                </div>
                              )}
                              {res.bid_part?.is_admin_sub_order === 0 &&
                                res.bid_part?.status === "Awarded" && (
                                  <div
                                    className={`col-2 me-4 p-2 d-flex align-items-center justify-content-center rounded ${
                                      res.bid_part?.is_admin_sub_order === 0
                                        ? "bg-success"
                                        : ""
                                    }`}
                                    data-label="Sr. No.: "
                                  >
                                    <span className={`data-value text-white`}>
                                      <p className="text-white text-center">
                                        Awarded To:
                                      </p>
                                      <h5 className="text-white text-center">
                                        {res.bid_part?.awarded_to.first_name ??
                                          ""}{" "}
                                        {res.bid_part?.awarded_to.last_name ??
                                          ""}
                                      </h5>

                                      {/* <p className="text-white">Status: {res.bid_part?.status}</p> */}
                                    </span>
                                  </div>
                                )}

                              {res.bid_part?.is_admin_sub_order === 0 &&
                                (res.bid_part?.status === "Open" ||
                                  res.bid_part?.status === "Upcoming") && (
                                  <div
                                    className={`col-2 me-4 p-2 d-flex align-items-center justify-content-center rounded ${
                                      res.bid_part?.is_admin_sub_order === 0
                                        ? "bg-warning"
                                        : ""
                                    }`}
                                    data-label="Sr. No.: "
                                  >
                                    <span className={`data-value text-white`}>
                                      <p className="text-dark text-center">
                                        Awarded To:
                                      </p>
                                      <h5 className="text-dark text-center">
                                        Waiting...
                                      </h5>
                                      {/* <p className="text-white">Status: {res.bid_part?.status}</p> */}
                                    </span>
                                  </div>
                                )}

                              {res.bid_part?.is_admin_sub_order === 0 &&
                                res.bid_part?.status === "Expired" && (
                                  <div
                                    className={`col-2 me-4 p-2 d-flex align-items-center justify-content-center rounded ${
                                      res.bid_part?.is_admin_sub_order === 0
                                        ? "bg-danger"
                                        : ""
                                    }`}
                                    data-label="Sr. No.: "
                                  >
                                    <span
                                      className={`data-value text-white w-100`}
                                    >
                                      <h5 className="text-white text-center">
                                        Need Attention
                                      </h5>
                                      <p className="text-white text-center">
                                        Status: {res.bid_part?.status}
                                      </p>
                                      <button
                                        className="btn btn-white m-0 mt-3 min-hp-40 w-100 font-weight-bold"
                                        onClick={() => {
                                          unattended(res.bid_part?.id);
                                        }}
                                      >
                                        Assign To Admin
                                      </button>
                                    </span>
                                  </div>
                                )}
                            </div>
                            <div className="d-flex flex-wrap">
                              <div className="col-12" data-label="Sr. No.: ">
                                {res?.bid_part?.cancel_reason && (
                                  <div className="px-3 mt-3">
                                    <div className="w-100 grey-box bg-white">
                                      <p className="small">
                                        <strong>
                                          {res?.order_status ===
                                          "Cancellation Requested"
                                            ? "Cancel Reason:"
                                            : "Return Reason:"}
                                        </strong>
                                        {res?.bid_part?.cancel_reason}
                                      </p>
                                    </div>
                                  </div>
                                )}

                                {[
                                  "Cancellation Requested",
                                  "Cancelled",
                                ].includes(res?.sub_order_status) ? (
                                  <div className="steps steps_admin cancle-steps">
                                    {res?.order_status_history.map(
                                      (resss, index) => (
                                        <>
                                          {index === 0 ||
                                          index === 8 ||
                                          index === 9 ? (
                                            <div className="step  mb-md-0 mb-3 step_newadmin">
                                              <div
                                               onClick={() => {
                                                if(resss?.is_complete != 1){
                                                  if(res.bid_part?.is_admin_sub_order === 0 && (res.bid_part?.status === "Open" ||
                                                  res.bid_part?.status === "Upcoming") ){
                                                  return
                                                }
                                                else{
                                                  handleStatus(resss?.status,"1",res?.id)
                                                }
                                                }
                                              }}
                                                className={`step__title step_admin tooltipzzz ${
                                                  resss?.is_complete === 1
                                                    ? "active"
                                                    : ""
                                                } `}
                                              >
                                                <div className="tooltipzzz">
                                                  {resss?.status}

                                                  {resss?.is_complete === 1 ? (
                                                    <span className="tooltiptext">
                                                      Date:{" "}
                                                      {moment(
                                                        resss?.date
                                                      ).format("MM/DD/yyyy")}
                                                      <br />
                                                      Time:
                                                      {moment(
                                                        resss?.date
                                                      ).format("HH:mm")}
                                                    </span>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      )
                                    )}
                                  </div>
                                ) : ["Return Requested", "Returned"].includes(
                                    res?.sub_order_status
                                  ) ? (
                                  <div className="steps steps_admin cancle-steps">
                                    {res?.order_status_history.map(
                                      (resss, index) => (
                                        <>
                                          {index === 0 ||
                                          index === 10 ||
                                          index === 11 ? (
                                            <div className="step  mb-md-0 mb-3 step_newadmin">
                                              <div
                                                  onClick={() => {
                                                    if(resss?.is_complete != 1){
                                                      if(res.bid_part?.is_admin_sub_order === 0 && (res.bid_part?.status === "Open" ||
                                                      res.bid_part?.status === "Upcoming") ){
                                                      return
                                                    }
                                                    else{
                                                      handleStatus(resss?.status,"1",res?.id)
                                                    }
                                                    }
                                                  }}

                                                className={`step__title step_admin tooltipzzz ${
                                                  resss?.is_complete === 1
                                                    ? "active"
                                                    : ""
                                                } `}
                                              >
                                                <div className="tooltipzzz">
                                                  {resss?.status}

                                                  {resss?.is_complete === 1 ? (
                                                    <span className="tooltiptext">
                                                      Date:{" "}
                                                      {moment(
                                                        resss?.date
                                                      ).format("MM/DD/yyyy")}
                                                      <br />
                                                      Time:
                                                      {moment(
                                                        resss?.date
                                                      ).format("HH:mm")}
                                                    </span>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div className="steps steps_admin">
                                    {res?.order_status_history.map(
                                      (resss, index) => (
                                        <div className="mb-3">
                                          {index < 8 ? (
                                            <div className="step mb-md-0 mb-3">
                                              {resss.is_complete === 1 ? (
                                                <div className="step__title step_admin active tooltipzzz">
                                                  <div className="tooltipzzz">
                                                    {resss?.status}
                                                    {editData?.payment_method ===
                                                    "Bank Transfer" && editData?.payment_status != "Completed" ? (
                                                      <>
                                                        <span className="tooltiptext">
                                                          Payment Pending
                                                        </span>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {" "}
                                                        {resss?.is_complete ===
                                                        1 ? (
                                                          <span className="tooltiptext">
                                                            Date:{" "}
                                                            {moment(
                                                              resss?.date
                                                            ).format(
                                                              "MM/DD/yyyy"
                                                            )}
                                                            <br />
                                                            Time:
                                                            {moment(
                                                              resss?.date
                                                            ).format("HH:mm")}
                                                          </span>
                                                        ) : null}
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              ) : res?.order_status_history[
                                                  index - 1
                                                ].is_complete === 1 ? (
                                                res.bid_part?.status ===
                                                  "Expired" ||
                                                (editData?.payment_method ===
                                                  "Bank Transfer" &&
                                                  editData?.payment_status ===
                                                    "Pending") ? (
                                                  <>
                                                    <div className="step__title step_admin">
                                                      {resss?.status}
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div
                                                      className="step__title step_admin"
                                                      onClick={() => {
                                                        if(resss?.status === "Prepare Shipment"){
                                                          handleShipment( editData.id, editData.order_product[i].bid_part?.id)
                                                        }else{
                                                          if(res.bid_part?.is_admin_sub_order === 0 && (res.bid_part?.status === "Open" ||
                                                          res.bid_part?.status === "Upcoming") ){
                                                          return
                                                        }
                                                        else{
                                                          handleStatus(resss?.status,"1",res?.id)
                                                        }
                                                        }
                                                        
                                                      }}
                                                    >
                                                      {resss?.status}
                                                    </div>
                                                  </>
                                                )
                                              ) : (
                                                <div className="step__title step_admin">
                                                  {resss?.status}
                                                </div>
                                              )}
                                            </div>
                                          ) : null}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>

                              {editData.order_product[i].bid_part.shipment_labels !== null && 
                                <a
                                  style={{cursor: "pointer"}}
                                  target={"_blank"}
                                  className="ms-auto text-decoration-underline me-4 w-auto text-end"
                                  title="Download Shipment Details"
                                  href={
                                    JSON.parse(
                                      editData.order_product[i].bid_part
                                        .shipment_labels
                                    ).pdf
                                  }
                                >
                                  Print Shipping Labels
                                </a>
                               }
                            </div>
                          </div>
                          {res.sub_order_status === "Delivered" &&
                          res?.review !== null ? (
                            <div className="m-0 p-0 bg-white p-4 mt-3 mb-3 grey-box">
                              <div className="row">
                                {res.is_review_exist === 1 ? (
                                  <div className="col-md-4">
                                    <div className="d-flex justify-content-between">
                                      <h5>Customer Ratings: </h5>
                                      <small>
                                        {moment(
                                          res?.review?.review_date
                                        ).format("MM/DD/yyyy")}
                                      </small>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <p>Quality:</p>
                                      </div>
                                      <div className="col-md-6 d-flex">
                                        <span className="ms-auto">
                                          {" "}
                                          {stars(res?.review?.qulity)}{" "}
                                        </span>
                                      </div>

                                      <div className="col-md-6">
                                        <p>On Time Delivery:</p>
                                      </div>
                                      <div className="col-md-6 d-flex">
                                        <span className="ms-auto">
                                          {" "}
                                          {stars(
                                            res?.review?.on_time_delivery
                                          )}{" "}
                                        </span>
                                      </div>

                                      <div className="col-md-6">
                                        <p>Communication:</p>
                                      </div>
                                      <div className="col-md-6 d-flex">
                                        <span className="ms-auto">
                                          {" "}
                                          {stars(
                                            res?.review?.communication
                                          )}{" "}
                                        </span>
                                      </div>

                                      <div className="col-md-6">
                                        <p>Engagement:</p>
                                      </div>
                                      <div className="col-md-6 d-flex">
                                        <span className="ms-auto">
                                          {" "}
                                          {stars(res?.review?.engagement)}{" "}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                {res.is_admin_review_exist === 1 ? (
                                  <div className="col-md-4 ms-auto">
                                    <div className="d-flex justify-content-between">
                                      <h5>Admin Ratings: </h5>
                                      <small>
                                        {moment(
                                          res?.review?.suppllier_review_date
                                        ).format("MM/DD/yyyy")}
                                      </small>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <p>Quality:</p>
                                      </div>
                                      <div className="col-md-6 d-flex">
                                        <span className="ms-auto">
                                          {" "}
                                          {stars(
                                            res?.review
                                              ?.suppllier_qulity_reating
                                          )}{" "}
                                        </span>
                                      </div>

                                      <div className="col-md-6">
                                        <p>On Time Delivery:</p>
                                      </div>
                                      <div className="col-md-6 d-flex">
                                        <span className="ms-auto">
                                          {" "}
                                          {stars(
                                            res?.review
                                              ?.suppllier_on_time_delivery_reating
                                          )}{" "}
                                        </span>
                                      </div>

                                      <div className="col-md-6">
                                        <p>Communication:</p>
                                      </div>
                                      <div className="col-md-6 d-flex">
                                        <span className="ms-auto">
                                          {stars(
                                            res?.review
                                              ?.suppllier_communication_reating
                                          )}
                                        </span>
                                      </div>

                                      <div className="col-md-6">
                                        <p>Engagement:</p>
                                      </div>
                                      <div className="col-md-6 d-flex">
                                        <span className="ms-auto">
                                          {stars(
                                            res?.review
                                              ?.suppllier_engagemen_reatingt
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                              </div>
                              <div className="mt-3">
                                <h5>Customer Reviews: </h5>
                                <p style={{ textAlign: "justify" }}>
                                  {res?.review?.reviews}
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })
                  ) : (
                    <span className="no-data fs-1 fw-bolder text-uppercase opacity-25 text-dark">
                      No Parts Found
                    </span>
                  )}

                  
                </div>
                <div className="col-lg-3" />
              </div>
            </div>
            <div className=" gx-0 grid-data">
              <div className="row">
                <div className="col-lg-12">
                  <div className="total-box">
                    <div className="row gx-0">
                      <div className="col-6 py-2  pe-sm-4 ps-sm-4 pe-2 ps-2">
                        <span className="data-value">Sub Total</span>
                      </div>
                      <div className="col-6 py-2  pe-sm-4 ps-sm-4 pe-2 ps-2 text-end d-block">
                        <span className="data-value">
                          ${editData?.product_price}
                        </span>
                      </div>
                    </div>
                    <div className="row gx-0">
                      <div className="col-6 py-2  pe-sm-4 ps-sm-4 pe-2 ps-2">
                        Shipping
                      </div>
                      <div className="col-6 py-2  pe-sm-4 ps-sm-4 pe-2 ps-2 text-end d-block">
                        ${editData?.shipping_price}
                      </div>
                    </div>
                    <div className="row gx-0">
                      <div className="col-6 py-2  pe-sm-4 ps-sm-4 pe-2 ps-2">
                        Discount
                      </div>
                      <div className="col-6 py-2  pe-sm-4 ps-sm-4 pe-2 ps-2 text-end d-block">
                        ${editData?.discounted_amt ?? 0}
                      </div>
                    </div>
                    <div className="row gx-0">
                      <div className="col-6 py-2  pe-sm-4 ps-sm-4 pe-2 ps-2">
                        <span className="bold-text">Total</span>
                      </div>
                      <div className="col-6 py-2  pe-sm-4 ps-sm-4 pe-2 ps-2 text-end d-block">
                        <span className="bold-text">
                          ${editData?.final_amt}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* send feedback */}
      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit(AddReview)}>
          <Modal.Header closeButton className="border-0 pb-0  pt-3">
            <Modal.Title className="fs-5 pe-0 ps-0 pe-sm-4 ps-sm-2 ">
              Your Rating For Supplier
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0 pe-0 ps-0 pe-sm-4 ps-sm-4 ">
            <div className="form-group">
              <div className="row">
                <div className="col-md-4">
                  <label>Quality</label>
                </div>
                <div className="col-md-8">
                  <Rating
                    onClick={(e) => {
                      setqulity(e);
                    }}
                    showTooltip
                    allowHalfIcon={false} /* Available Props */
                    tooltipDefaultText="0"
                  />
                </div>
                <div className="col-md-4">
                  <label>On Time Delivery</label>
                </div>
                <div className="col-md-8">
                  <Rating
                    onClick={(e) => {
                      seton_time_delivery(e);
                    }}
                    showTooltip
                    allowHalfIcon={false} /* Available Props */
                    tooltipDefaultText="0"
                  />
                </div>
                <div className="col-md-4">
                  <label>Communication</label>
                </div>
                <div className="col-md-8">
                  <Rating
                    onClick={(e) => {
                      setcommunication(e);
                    }}
                    showTooltip
                    allowHalfIcon={false} /* Available Props */
                    tooltipDefaultText="0"
                  />
                </div>
                <div className="col-md-4">
                  <label>Engagement</label>
                </div>
                <div className="col-md-8">
                  <Rating
                    onClick={(e) => {
                      setengagement(e);
                    }}
                    showTooltip
                    allowHalfIcon={false} /* Available Props */
                    tooltipDefaultText="0"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0 pb-3 pe-0 ps-0 pe-sm-4 ps-sm-4 ">
            <button
              type="button"
              className="normal-height btn btn-primary-orange border-radius-0"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              type="submit"
              className="normal-height btn btn-primary-orange border-radius-0"
            >
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* active modal */}
      <Modal
        className="monday-modal"
        animation={false}
        show={activeShow2}
        onHide={activeHandleClose2}
      >
        <form
          className="position-relative"
          onSubmit={handleSubmit2(ChnageStatus)}
        >
          {loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <Modal.Body>Are you sure you want to Change Order Status?</Modal.Body>
          <Modal.Body>
            <div className="col-12">
              <form>
                <div className="form-group">
                  <select
                    className="form-select form-control"
                    onChange={(e) => {
                      setstatus(e.target.value);
                      activeHandleShow2();
                    }}
                    // {...register('status', { required: false })}
                    aria-label="Default select example"
                  >
                    <option
                      disabled
                      selected
                      className="disabled-value"
                      defaultValue={status}
                    >
                      {status}
                    </option>
                    <option
                      style={
                        status === "Cancelled" ||
                        status === "Cancellation Requested" ||
                        status === "Return Requested"
                          ? { display: "none" }
                          : null
                      }
                      value="Order Received"
                    >
                      Order Received
                    </option>
                    <option
                      style={
                        status === "Cancelled" ||
                        status === "Cancellation Requested" ||
                        status === "Return Requested"
                          ? { display: "none" }
                          : null
                      }
                      value="Material Ordered"
                    >
                      Material Ordered
                    </option>
                    <option
                      style={
                        status === "Cancelled" ||
                        status === "Cancellation Requested" ||
                        status === "Return Requested"
                          ? { display: "none" }
                          : null
                      }
                      value="Work In Progress"
                    >
                      Work In Progress
                    </option>
                    <option
                      style={
                        status === "Cancelled" ||
                        status === "Cancellation Requested" ||
                        status === "Return Requested"
                          ? { display: "none" }
                          : null
                      }
                      value="Under Inspection"
                    >
                      Quality Inspection
                    </option>
                    <option
                      style={
                        status === "Cancelled" ||
                        status === "Cancellation Requested" ||
                        status === "Return Requested"
                          ? { display: "none" }
                          : null
                      }
                      value="Order Shipped"
                    >
                      Order Shipped
                    </option>
                    <option
                      style={
                        status === "Cancelled" ||
                        status === "Cancellation Requested" ||
                        status === "Return Requested"
                          ? { display: "none" }
                          : null
                      }
                      value="Delivered"
                    >
                      Delivered
                    </option>
                    <option
                      style={
                        status === "Return Requested"
                          ? { display: "none" }
                          : null
                      }
                      value="Cancelled"
                    >
                      Cancelled
                    </option>
                    <option
                      style={
                        status === "Cancelled" ||
                        status === "Cancellation Requested"
                          ? { display: "none" }
                          : null
                      }
                      value="Returned"
                    >
                      Returned
                    </option>
                    <option
                      style={
                        status === "Cancelled" ||
                        status === "Cancellation Requested"
                          ? { display: "none" }
                          : null
                      }
                      value="Refunded"
                    >
                      Refunded
                    </option>
                  </select>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="theme-btn btn-group-right text-end">
              <Button type="submit" variant="secondary">
                Yes
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={activeHandleClose2}
              >
                No
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {/* active modal end */}
      {/* active modal_2 */}
      <Modal
        className="monday-modal"
        animation={false}
        show={activeShow3}
        onHide={activeHandleClose3}
      >
        <form
          className="position-relative"
          onSubmit={handleSubmit2(ChnageStatus)}
        >
          {loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <Modal.Body>Are you sure you want to Change Order Status?</Modal.Body>
          <Modal.Footer>
            <div className="theme-btn btn-group-right text-end">
              <Button type="submit" variant="secondary">
                Yes
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={activeHandleClose3}
              >
                No
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {/* active modal_2 end */}
      {/*shipping modal */}
      <Modal
        className="shippingModal"
        animation={false}
        show={shippingModal}
        onHide={activeShippingModal}
      >
        {loading ? (
          <div className="loader-wrapper">
            <div className="loader">
              <div className="loading-svg">
                <GiAutoRepair />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <Modal.Body>
          Are you sure you want to Change Shipping Status?
        </Modal.Body>
        <Modal.Footer>
          <div className="theme-btn btn-group-right text-end">
            <Button
              type="submit"
              variant="secondary"
              onClick={() => changeShipmentStatus(shipData)}
            >
              Yes
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={() => activeShippingModal(shipData)}
            >
              No
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* active modal_2 end */}
      {/* modal for showing attachment images */}
      {/* active modal */}
      <Modal
        className="status-modal"
        animation={false}
        show={mydata}
        onHide={() => {
          setmydata(!mydata);
          setAttachmentData([]);
        }}
      >
        <div className="position-relative">
          <Modal.Body>
            <div className="row text-start">
              <div className="col-12">
                <div className="row gx-0 grid-head-row mb-3">
                  <div className="col-3">
                    <span className="data-value">Sr. No.</span>
                  </div>
                  <div className="col-6">
                    <span className="data-value">File Name</span>
                  </div>
                  <div className="col-3 text-center">
                    <span className="data-value">Action</span>
                  </div>
                </div>
                <hr />
                {attachmentData.length > 0 ? (
                  attachmentData.map((item, i) => (
                    <div className="row p-0 mb-3 flex align-items-center text-start">
                      <div className="col-3" data-label="Sr. No.: ">
                        <span className="data-value">{i + 1}</span>
                      </div>
                      <div className="col-6" data-label="Image">
                        <span
                          className="data-value"
                          style={{ textAlign: "justify" }}
                        >
                          {item.split("/uploads/quote_attachments/")[1]}
                        </span>
                      </div>
                      <div className="col-3 text-center" data-label="Sr. No.: ">
                        <span className="data-value" title="Download Image">
                          <a target={"_blank"} href={item} download={item}>
                            <FaDownload />
                          </a>
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">No Other Attachments available for this Part.</div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="theme-btn btn-group-right text-end">
              <Button
                variant="primary"
                onClick={() => {
                  setmydata(!mydata);
                  setAttachmentData([]);
                }}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
      {/* active modal end */}

            {/* modal for tracking no  */}
            <Modal show={trackingShow} onHide={()=>setTrackingShow(!trackingShow)}>
        <Modal.Header closeButton className="border-0 pb-0  pt-3">
            <Modal.Title className="fs-5 pe-0 ps-0 pe-sm-4 ps-sm-2 ">
              Track Your Request 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0 pe-0 ps-0 pe-sm-4 ps-sm-4 ">
          {trackingDetails?.length===0 || trackingDetails=== null ? 'No updated tracking information available. Please check back.':'No updated tracking information available. Please check back.'}
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0 pb-3 pe-0 ps-0 pe-sm-4 ps-sm-4 ">
            <button
              type="button"
              className="normal-height btn btn-primary-orange border-radius-0"
              onClick={()=>setTrackingShow(!trackingShow)}>
              Close
            </button>
          </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};
export default EditOrderManagement;
