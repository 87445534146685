import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { GiAutoRepair } from "react-icons/gi";
import { Button, Spinner, Modal } from "react-bootstrap";

// icons
import { FaEdit} from "react-icons/fa";

import Pagination from "react-js-pagination";

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate  } from "react-router-dom";

// react bootstrap

import ChangePassword from "../../common/changePassword/changePassword";
import { getMethodData, getSubmitData } from "../../adminApi/api";
import SendEmail from "../../common/sendEmail/sendEmail";
import { addPendingValue } from "../../../../redux/actions/action";
import {useDispatch } from "react-redux";

const Coupen_mangement = (props) => {
  const [changestatus,setchangestatus] = useState(false);
  let  showDataToUser =false; 

  const [redirection,setRedirection] = useState(false);
  useLayoutEffect(() => {
      const ls=JSON.parse(localStorage.getItem("adminDetails"));
    // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
     
      for(let i=0; i<=ls?.my_permissions?.length;i++){
          if(ls?.my_permissions[i]?.name==="Coupon code"){
              
              for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                  if(ls?.my_permissions[i]?.permissions[j]?.name==='List'){
                      // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      showDataToUser = true;
                     
                  }
                  if(ls?.my_permissions[i]?.permissions[j]?.name==="Change Status"){
                    // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                    setchangestatus(true);
                }
              }
          }
      }
      // console.log(showDataToUser);
      if(showDataToUser===false){
        // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
  }, []);
  const navigate = useNavigate();
    if (redirection) navigate('/admin/dashboard');
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
    // const PORT = PORTNUMBER.PORTNUMBER
  const Token = localStorage.getItem("token");
  const [showPassword, setShowPassword] = useState(false);
  
  // modal active
  const [activeShow, setActiveShow] = useState(false);
  const [statusId, setStatusId] = useState();
  const [customerId, setCustomerId] = useState();
  const [quoteMail, setQuoteMail] = useState();

  const [loading, setLoading] = useState(false);

  const [statusType, setStatusType] = useState();

  // const [Loading, setLoading] = useState(false);

  const [resData, setResData] = useState([]);
  const [Count, setCount] = useState(1);
  const [perPage, setPerPage] = useState();

  const [indexPage, setIndexPage] = useState(1);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const [activePage, setActivePage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  // const [filterData, setFilterData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [filterVisibility, setFilterVisibility] = useState(true);

  const { register, control, handleSubmit, reset } = useForm();

  const dNone = "d-none";

  const activeHandleClose = () => setActiveShow(false);
  const activeHandleShow = (id, status) => {
    if(changestatus===true){
      setStatusType(status);
      setStatusId(id);
      setActiveShow(true);
    }else{
      alert("permission not allowed")
  }
  };

  const activeUser = async () => {
    getMethodData(`/couponcodes/change-status/${statusId}`, Token).then((res) => {
      if (res.data.status !== false) {
        LoadTableData();
        toast.success(res.data.message);
        activeHandleClose();
      } else {
        toast.error(res.data.message);
        activeHandleClose();
      }
    });
  };

  useEffect(() => {
    LoadTableData();
    return () => {
      LoadTableData();
    };
  }, []);

  const LoadTableData = useCallback(
    async (pageNumber) => {
      // if(id?.length){
      //     navigate("/admin/bid-management")
      // }
      setIndexPage(1);

      setLoading(false);
      await axios.get(`${BASE_URL}/couponcodes?page=${pageNumber === 1 ? 1 : pageNumber}`, { headers: { authorization: `Bearer ${Token}` } })
        .then((res) => {
          setResData(res.data.result.data.data);
          // console.log(res.data.result.data.data);

          setLoading(true);
          setFilterVisibility(true);
        });

      reset();
    },
    [BASE_URL, Token, reset],
    [resData, Count]
  );

  const sendPage = (pageNumber) => {
    axios
      .get(
        `${BASE_URL}/couponcodes?page=${pageNumber}&${new URLSearchParams(filterData
        ).toString()}`,
        { headers: { authorization: `Bearer ${Token}` } }
      )
      .then((res) => {
        setResData(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);

        setLoading(true);
      });
  };

  const handlePageChange = (pageNumber) => {
    if (filterVisibility === true) {
      LoadTableData(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    } else {
      sendPage(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    }
  };
  useEffect(() => {
    LoadTableData();
  }, []);

  const onSubmit = async (data) => {
    dispatch(addPendingValue(false));
// console.log("new",data);
    // if(id?.length){
    //     navigate("/admin/bid-management")
    // }

    setLoadingButton(true);
    setIndexPage(1);

    setFilterData(data);

    data.from =
      !data.from || data.from === null || data.from === undefined
        ? ""
        : moment(data.from).format("YYYY/MM/DD");
    data.to =
      !data.to || data.to === null || data.to === undefined
        ? ""
        : moment(data.to).format("YYYY/MM/DD");

    setLoading(false);
    // sendPage()
    await axios
        getSubmitData(`/couponcodes?${new URLSearchParams({ ...data }).toString()}`,Token)
      .then((res) => {
        setLoadingButton(false);

        setResData(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);

        // setCount(res.data.data.userCount)
        // setFilterData(res.data.data.userData)
        setLoading(true);
        setFilterVisibility(false);
      });
    setLoadingButton(false);
  };
  const [todayDate,setTodayDate]=useState(null);
  return (<> 
  
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="main-head">
                <h4>Coupon List</h4>
              </div>
            </div>
            <div className="col-6">
                            <div className="theme-btn top-head text-end">
                                <Link to="add-new-coupen" className="btn">Add Coupon</Link>
                            </div>
                        </div>
          </div>
        </div>
        <div className="table-order-box shadow rounded overflow-hidden">
          <div className="tab-head">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      {...register("coupon_name", { required: false })}
                      className="form-control"
                      placeholder="Coupon Name"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                <div className="form-group">
                    <select className="form-select "     {...register("discount_type", { required: false })} id="validationCustom12" aria-label="Default select example" >
                      <option className="disabled-value d-none" value=""  >Discount Type</option>
                      <option value="">All</option>
                      <option value="percentage">percentage</option>
                      <option value="flat">flat</option>
                    </select>
                                     
                  </div>
               
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      min={1}
                      type="number"
                      {...register("discount", { required: false })}
                      className="form-control"
                      placeholder="Discount Amount"
                     
                      
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <select
                      className="form-select form-control"
                      {...register("status", { required: false })}
                      aria-label="Default select example"
                    >
                      <option value="" className="d-none" selected>
                      Coupon Status
                      </option>
                      <option value="">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group before-con">
                    <Controller
                      control={control}
                      name="from"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Click to select a date"
                          {...register("from", { required: false })}
                          onChange={(date) => {field.onChange(date);setTodayDate(date);}}
                          selected={field.value}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group before-con after">
                    <Controller
                      control={control}
                      name="to"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          {...register("to", { required: false })}
                          placeholderText="Click to select a date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          minDate={todayDate == null? new Date() : todayDate}
                        />
                      )}
                    />
                  </div>
                </div>
                
                <div className="col-lg-12">
                  <div className="theme-btn btn-group-right text-end">
                    {loadingButton === false ? (
                      <button type="submit" className="btn">
                        Search
                      </button>
                    ) : (
                      <Button className="btn  spinner" variant="dark" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                        />
                        Searching...
                      </Button>
                    )}
                    <p
                      type="reset"
                      onClick={() => LoadTableData()}
                      className="btn"
                    >
                      Reset
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="project-table">
        <div className="table-order shadow">
          <table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th className="text-nowrap">Coupon Name</th>
                <th className="text-nowrap">Discount type</th>
                <th className="text-nowrap">Discount</th>
                <th className="text-nowrap">From Date</th>
                <th className="text-nowrap">To Date</th>
                <th className="text-nowrap">Status</th>
                <th className="text-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {resData && resData.length ? (
                resData.filter((val) => {
                    const date = val.from;
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.coupon_name
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                        
                    ) {
                        
                      return val;
                    }
                else if (val.discount_type.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
                    return val;
                } else if (val.discount){
                  
                    return val;
                } else if (val.Coupen_Status.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return val;
                } else if (moment(date).format("MM/DD/yyyy").toLowerCase().includes(searchTerm.toLowerCase())) {
                    return val
                }
                      else {
                      return false;
                    }
                  })
                  .map((res, index) => (
                    <tr key={res.id}>
                      <td className="s-no bg-transparent" scope="row">
                        {50 * (indexPage - 1) + (index + 1)}
                      </td>
                      <td className="bg-transparent">{res.coupon_name}</td>
                      <td className="bg-transparent">
                        <td className="bg-transparent">{res.discount_type}</td>
                      </td>
                      <td className="bg-transparent">{res.discount}</td>
                      <td>{moment(res.from).format("MM/DD/yyyy")}</td>
                      <td>{moment(res.to).format("MM/DD/yyyy")}</td>
                      <td className="">
                        <span
                          onClick={() => activeHandleShow(res.id, res.status)}
                          className={`badge bg-success ${res.status}`}
                        >
                          {res.status}
                        </span>
                      </td>
                    
                      <td className=" bg-transparent">
                        <Link
                          title="Edit Coupon"
                          to={`edit/${res.id}`}
                          className="icons-action"
                        >
                          <FaEdit />
                         
                        </Link>
                        
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td className="no-data text-uppercase">NO Coupon FOUND</td>
                </tr>
              )}
            </tbody>
          </table>
          {!loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
        </div>
        <div className="pagination">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={perPage}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        </div>
      </div>

      {/* modals */}

      {/* active modal */}
      <Modal
        className="status-modal"
        animation={false}
        show={activeShow}
        onHide={activeHandleClose}
      >
        <Modal.Body>
          Are you sure you want to{" "}
          {statusType === "active" ? "in-activate" : "activate"} this Coupon?
        </Modal.Body>
        <Modal.Footer>
          <div className="theme-btn btn-group-right text-end">
            <Button variant="secondary" onClick={activeUser}>
              Yes
            </Button>
            <Button variant="primary" onClick={activeHandleClose}>
              No
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* active modal end */}

      <ToastContainer />
      <ChangePassword
        api={`/customers/change-password/${customerId}`}
        show={showPassword}
        dNone={dNone}
        onHide={() => setShowPassword(false)}
      />
      <SendEmail
        api={"/suppliers/send-contect-email"}
        show={showEmail}
        quoteMail={quoteMail}
        dNone={dNone}
        onHide={() => setShowEmail(false)}
      />
    </div>
  
   </>
  );
};
export default Coupen_mangement;
