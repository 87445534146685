import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import axios from 'axios';
import moment from 'moment'
import { ToastContainer, toast, } from "react-toastify";
import { useForm, Controller } from 'react-hook-form';
import { GiAutoRepair } from 'react-icons/gi';
import { Button, Spinner, Modal } from 'react-bootstrap'


// icons
import {FaFileExcel, FaSearch } from 'react-icons/fa';
import Pagination from "react-js-pagination";

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from 'react-router-dom'

// react bootstrap

import ChangePassword from '../../common/changePassword/changePassword';
import {getSubmitData } from '../../adminApi/api';
import SendEmail from '../../common/sendEmail/sendEmail';
import { addPendingValue } from '../../../../redux/actions/action';
import { useDispatch } from 'react-redux';


const Payments = (props) => {
    let showDataToUser = false;
    const [showexcel, setshowExcel] = useState(false);
    const [redirection,setRedirection] = useState(false);
    useLayoutEffect(() => {
      const ls = JSON.parse(localStorage.getItem("adminDetails"));
      // console.log("uselayout", JSON.parse(localStorage.getItem("adminDetails")));
  
      for (let i = 0; i <= ls?.my_permissions?.length; i++) {
        if (ls?.my_permissions[i]?.name === "Transactions") {
          for (let j = 0; j <= ls?.my_permissions[i]?.permissions?.length; j++) {
            if (
              ls?.my_permissions[i]?.permissions[j]?.name === 'List'
            ) {
              // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
              showDataToUser = true;
             
            }
            if (
                ls?.my_permissions[i]?.permissions[j]?.name === "Transactions Excel Export"
              ) {
                // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                setshowExcel(true)
               
              }
          }
        }
      }
      // console.log(showDataToUser);
      if(showDataToUser===false){
        // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
    }, []);
    const navigate = useNavigate();
    if (redirection) navigate('/admin/dashboard');
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const { id } = useParams();
    const dispatch = useDispatch();

    // const PORT = PORTNUMBER.PORTNUMBER
    const Token = localStorage.getItem("token");
    const [showPassword, setShowPassword] = useState(false);
    // modal active

    // // console.log(OrderIdnew)
    const [customerId, setCustomerId] = useState();
    const [orderMail, setOrderMail] = useState();
    
    const [loading, setLoading] = useState(false)

    const [ExlFilter, setExlFilter] = useState(
        {
            page: '',
            transaction_id: '',
            from_date: '',
            payment_status: '',
            payment_method: '',
            export_in_excel: '1',
        }
    );

    // const [Loading, setLoading] = useState(false);

    const [order, setOrder] = useState([]);
    const [Count, setCount] = useState(1);
    const [perPage, setPerPage] = useState();

    const [indexPage, setIndexPage] = useState(1);
    const [loadingButton, setLoadingButton] = useState(false);
    const [showEmail, setShowEmail] = useState(false);


    const [activePage, setActivePage] = useState(1)

    const [searchTerm, setSearchTerm] = useState("");


    // const [filterData, setFilterData] = useState([]);
    const [filterData, setFilterData] = useState([]);



    const [filterVisibility, setFilterVisibility] = useState(true);



    const { register, control, handleSubmit, reset } = useForm();

    const { register:register2, control:control2, handleSubmit: handleSubmit2, reset:reset2 } = useForm();

    const dNone = "d-none"


  
    const sendPageFilter = (pageNumber) => {
        // // console.log("filterData", filterData)
        // alert(1)
        getSubmitData(`${props.filterApi}?page=${pageNumber}&${props.searchId}=${id}`,Token)

            .then((res) => {
                // // console.log("redsd", res)
                setOrder(res.data.result.data.data);
                setCount(res.data.result.data.total);
                setLoading(true);


            })
    }

    const LoadTableData = useCallback(async (pageNumber) => {
        setIndexPage(1)
        setLoading(false);
        await axios.get(`${BASE_URL}/transacton?page=${String(pageNumber === 1 ? 1 : pageNumber)}`, { headers: { authorization: `Bearer ${Token}` } })
            .then((res) => {
                // // console.log("transacton", res)
                setOrder(res.data.result.data.data);
                setCount(res.data.result.data.total);
                setPerPage(res.data.result.data.per_page);

                setLoading(true);
                setFilterVisibility(true)
            })


        reset()

    }, [BASE_URL, Token, reset,], [order, Count])


    const sendPage = (pageNumber) => {
        // // console.log("filterData", filterData)
        axios.get(`${BASE_URL}/transacton?page=${pageNumber}&${new URLSearchParams(filterData).toString()}`, { headers: { authorization: `Bearer ${Token}` } })
            .then((res) => {
                // // console.log("redsd", res)
                setOrder(res.data.result.data.data);
                setCount(res.data.result.data.total);
                setPerPage(res.data.result.data.per_page);

                setLoading(true);
            })
    }


    const handlePageChange = (pageNumber) => {
        // sendPage(pageNumber)
        // LoadTableData(pageNumber)
        if (id?.length) {
            sendPageFilter(pageNumber)
            // // console.log("1")
        } else {

            if (filterVisibility === true) {
                LoadTableData(pageNumber)
                setActivePage(pageNumber);
                setIndexPage(pageNumber)
                setLoading(false);
            // // console.log("2")

            } else {
                sendPage(pageNumber)
                setActivePage(pageNumber);
                setIndexPage(pageNumber)
                setLoading(false);
            // // console.log("3")
            }
        }

    }

    useEffect(() => {
        sendPageFilter()

        if (id) {
            sendPageFilter()
        } else {
            sendPage()
            LoadTableData()
        }
    }, [])


    const onSubmit = async (data) => {
        setExlFilter({
            page: 1,
            transaction_id: data.transaction_id,
            from_date: data.from_date,
            payment_status: data.payment_status,
            payment_method:data.payment_method,
            export_in_excel: '1',
        })
        dispatch(addPendingValue(false))
        if(id?.length){
            navigate("/admin/payment-management")
        }
        // console.log("filterdata")
        setLoadingButton(true)
        setIndexPage(1)
        setFilterData(data)
        // // console.log("DATA", data)
        data.from_date = (!data.from_date || data.from_date === null || data.from_date === undefined) ? "" : moment(data.from_date).format("YYYY/MM/DD")
        data.to_date = (!data.to_date || data.to_date === null || data.to_date === undefined) ? "" : moment(data.to_date).format("YYYY/MM/DD")
        setLoading(false);
        // sendPage()
        await axios.get(`${BASE_URL}/transacton?page=${1}&${new URLSearchParams(data).toString()}`, { headers: { authorization: `Bearer ${Token}` } })
            .then((res) => {
                setLoadingButton(false)
                setOrder(res.data.result.data.data);
                setCount(res.data.result.data.total);
                setLoading(true);
                setFilterVisibility(false)

            })
        setLoadingButton(false)
    }

    useEffect(() => {
        LoadTableData()
    }, [])

      // download excel 
    const downloadXLSFile = async (pageNumber) => {
        if(showexcel===true){
            axios({
                url: `${BASE_URL}/transacton?page=${String(pageNumber === 1 ? 1 : pageNumber)}&transaction_id=${ExlFilter.transaction_id ?? ''}&transactons_date=${ExlFilter.from_date ?? ''}&status=${ExlFilter.payment_status ?? ''}&payment_method=${ExlFilter.payment_method ?? ''}&export_in_excel=${ExlFilter.export_in_excel ?? '1'}`,
                method: 'GET',
                responseType: 'blob', // important
                headers: { authorization: `Bearer ${Token}` },
                
            }).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);
            
                // create "a" HTLM element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `Transactions_${moment(new Date()).format("MMDDYYYY")}.xls`); //or any other extension
                document.body.appendChild(link);
                link.click();
            
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                // URL.revokeObjectURL(url);
            });

        }
        else{
            alert("permission not allowed")
        }
       
}
const [todayDate,setTodayDate]=useState(null);
    return (
        <>
      
        <div className="tables-field">
            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="main-head">
                                <h4>Transaction List</h4>
                            </div>
                        </div>
                        {/* <div className="col-6">
                            <div className="theme-btn top-head text-end">
                                <Link to="#" className="btn">Add New Order</Link>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="table-order-box shadow rounded overflow-hidden">
                    <div className="tab-head">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" {...register('transaction_id', { required: false })} className="form-control" placeholder="Transaction ID" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" {...register('order_id', { required: false })} className="form-control" placeholder="Order ID" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" {...register('amount', { required: false })} className="form-control" placeholder="Transaction Amount" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="email" {...register('email', { required: false })} className="form-control" placeholder="Customer Email" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group before-con">

                                        <Controller
                                            control={control}
                                            name="from_date"

                                            render={({ field }) => (
                                                <DatePicker

                                                dateFormat="MM/dd/yyyy"
                                                    placeholderText='Click to select a date'
                                                    onChange={(date) => {
                                                    field.onChange(date);
                                                    setTodayDate(date);}}
                                                    selected={field.value}

                                                    maxDate={new Date()}

                                                />
                                            )}
                                        />

                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group before-con after">
                                        <Controller
                                            control={control}
                                            name="to_date"

                                            render={({ field }) => (

                                                <DatePicker

                                                dateFormat="MM/dd/yyyy"
                                                    placeholderText='Click to select a date'
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                    minDate={todayDate==null? new Date() : todayDate}
                                                    maxDate={new Date()}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <select className="form-select form-control" {...register('payment_status', { required: false })} aria-label="Default select example">
                                            <option value="" className='d-none' selected>Payment Status</option>
                                            <option value="">All</option>
                                            <option value="Pending">Pending</option>
                                            <option value="Failed">Failed</option>
                                            <option value="Cancelled">Cancelled</option>
                                            <option value="Complete">Completed</option>
                                          

                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <select className="form-select form-control" {...register('payment_method', { required: false })} aria-label="Default select example">
                                            <option value="" className='d-none' selected>Payment Mode</option>
                                            <option value="">All</option>
                                            <option value="Credit card">Credit Card</option>
                                            <option value="Bank Transfer">Bank Transfer</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-end">
                                        {loadingButton === false ?
                                            <button type="submit" className="btn">Search</button> :
                                            <Button className='btn  spinner' variant='dark' disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"

                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Searching...
                                            </Button>
                                        }
                                        <p type="reset" onClick={() => LoadTableData()} className="btn">Reset</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="project-table theme-btn">
            <button className='btn' onClick={() => downloadXLSFile()}>Export to Excel <FaFileExcel/></button>
            <div className="form-search float-end">
                    <form>
                        <div className="form-group icon-input top-search">
                            <input type="text" id="searchForm2" className="form-control" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search" />
                            <FaSearch />
                        </div>
                        <input type="btn" className='d-none' value="Submit" />
                    </form>
                </div>
                <div className="table-order shadow">

                    <table>
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th>Order ID</th>
                                <th>Transaction ID</th>
                                <th>product amount </th>
                                <th> shipping amount</th>
                                <th>Transaction Amount</th>
                                <th>Order Date</th>
                                <th>Transaction Date</th>
                                <th>Customer Email</th>
                                <th>Payment Mode</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>


                            {order && order.length ? order.filter((val) => {
                                const date = val.created_at
                                const datetrans = val.transactons_date
                                if (searchTerm === "") {
                                    return val
                                } 
                                else if (val.order?.order_id.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } 
                                else if (val.transaction_id.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } 
                                else if (val.order?.customer?.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } 
                                else if (val.order?.customer?.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                }
                                else if (val.payment_method?.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } 
                                else if (val.payment_status?.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                }
                                else if (moment(date).format("MM/DD/yyyy").toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } 
                                else if (moment(datetrans).format("MM/DD/yyyy").toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } 
                                else {
                                    return false
                                }


                            }).map((order, index) => (
                                <tr key={order.id}>
                                    <td className="s-no" scope="row">{perPage * (indexPage - 1) + (index + 1)}</td>
                                    <td >
                                        #{order?.order?.order_id}
                                    </td>
                                    <td >
                                        #{order?.transaction_id}
                                    </td>
                                    <td >
                                    ${order?.order?.product_price}
                                    </td>
                                    <td >
                                    ${order?.order?.shipping_price}
                                    </td>
                                    <td >
                                        $ {order?.amount}
                                    </td>
                                    <td>{moment(order?.order?.date).format("MM/DD/yyyy")}</td>

                                    <td>{moment(order.transactons_date).format("MM/DD/yyyy")}</td>
                                    <td >
                                        {order?.order?.customer?.email ?? ''}
                                    </td>
                                    <td >
                                        {order?.payment_method}
                                    </td>
                                    <td className="">
                                        <span 
                                        // onClick={() => activeHandleShow(order.id, order.status)} 
                                        className={`badge bg-success ${order.payment_status}`}>{order.payment_status}
                                    </span>

                                    </td>
                                    {/* <td className="action-top">
                                        <Link title='View Order' to={`${order.id}`} className="icons-action"><FaEye /></Link>
                                        <span title='Add Payment' 
                                        onClick={() => {
                                            activeHandleShow(order.id, order.status);
                                            setStatusType(order.status);
                                            setStatusId(order.id)}
                                            } 
                                            className={`text-success ${order.status}`}><FaDollarSign />
                                        </span>
                                    </td> */}

                                </tr>

                            )) : <tr><td className="no-data">NO TRANSACTION FOUND</td></tr>
                            }



                        </tbody>
                    </table  >
                    {
                        !loading ?
                            <div className="loader-wrapper">
                                <div className="loader">
                                    <div className="loading-svg">
                                        <GiAutoRepair />
                                    </div>
                                </div>
                            </div> : ""
                    }


                    {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
                </div>
                <div className='pagination'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={Number(Count)}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                    />
                </div>

            </div>

            <ToastContainer />
            <ChangePassword api={`/customers/change-password/${customerId}`} show={showPassword} dNone={dNone} onHide={() => setShowPassword(false)} />
            <SendEmail api={"/suppliers/send-contect-email"} show={showEmail} orderMail={orderMail} dNone={dNone} onHide={() => setShowEmail(false)} />


        </div>
       
</>
    )
    
}
export default Payments;
