import {createStore, applyMiddleware } from "redux"
import rootReducer from '../redux/reducers/index'
import thunk from 'redux-thunk'


const middleware = [thunk]


  const store = createStore(rootReducer);


  export default store;