import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { FaAngleLeft } from 'react-icons/fa'
import { ImageObj } from '../../../../../../assets/images/imgeObject';
import { Link } from 'react-router-dom'
import { Tabs, Tab, Col, Nav, Row } from 'react-bootstrap'
import { modalNoFilesValue, modalNvigateValue, modalToggelValue,idUploadValue, contactUsForm } from '../../../../../../redux/actions/action';
import { useDispatch, useSelector } from 'react-redux';
const Create3d = (props) => {
  const { id } = useParams()
  const [progress, setProgress] = useState()
  const [progressVisiblity, setProgressVisiblity] = useState(null)
  const [extentionError, setExtentionError] = useState('');
  const USERTOKEN = localStorage.getItem("userToken")
  const pathname = window.location.pathname.split('/')[1]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const Auth1 = localStorage.getItem("userToken");
  const modalOpenNavigate=(nofile)=>{
    dispatch(modalNvigateValue(nofile))
    localStorage.setItem("navigateValue", "true")
    dispatch(modalToggelValue(true))
  }
  const BASE_URL_USER = process.env.REACT_APP_BASE_URL_USER + "/user";

  const handleImageChange = (e) => {
    var fileName = e.target.value;
    var dot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(dot, fileName.length).toLowerCase();
    if (extFile == "3dm" || extFile == "asm" || extFile == "cam360" || extFile == "CATPart" || extFile == "CATProduct" || extFile == "dwg" || extFile == "dxf" || extFile == "f3d" || extFile == "fbx" || extFile == "g" || extFile == "iam" || extFile == "ige" || extFile == "iges" || extFile == "igs" || extFile == "ipt" || extFile == "neu" || extFile == "obj" || extFile == "prt" || extFile == "sab" || extFile == "sat" || extFile == "skp" || extFile == "sldasm" || extFile == "sldprt" || extFile == "smb" || extFile == "smt" || extFile == "ste" || extFile == "step" || extFile == "stl" || extFile == "stp" || extFile == "wire" || extFile == "x_b" || extFile == "x_t" || extFile == "123dx") {
        if (e.target.files) {
            // setButtonVisible(true)
            Auth1 !== null ?
                onSubmit() :
                modalOpenNavigate(false)
        }
    } else {
        setExtentionError("This file type is not allowed.")
    }
};
const onSubmit = async () => {

    const imageFiles = document.getElementById("upload-files").files[0];
    const fd = new FormData();
    fd.append("auto_cad_file", imageFiles);
    fd.append("quote_id", id ? id : 0)
    const headerConfig = {
        headers: {
            'authorization': `Bearer ${USERTOKEN}`,
            'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
        },
        onUploadProgress: fd => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * fd.loaded) / fd.total))
        },
    }
    axios.post(`${BASE_URL_USER}/quotes/upload-2d-3d-file`, fd, headerConfig)
        .then((res) => {
            // console.log("res file cadd", res)
            // LoadImages()
            if (res.data.status === true) {
                toast.success("Design file are uploaded successfully.");
                setProgressVisiblity(false)
                setProgress(null)
                navigate(`/customer/quote/${res.data.result.data.id}`, { replace: true })
                // dispatch(dropDownValue(""))
            } else {
                toast.error(res.data.message);
            }
        })
}
const [modifyData,setModifyData]=useState();
const BASE_URL = process.env.REACT_APP_BASE_URL_USER;
  useEffect(() => {
    axios.get(`${BASE_URL}/user/get-globle-config/QUOTE_MODIFY`).then(req=>{
      setModifyData(req?.data?.result?.data?.value)
      // console.log(req?.data?.result?.data?.value)
    })
}, [])
  return (
    <React.Fragment>
      <section className="pt-5 mt-3">
        <div className="container dashboard-heading-block py-3">
          <div className="row align-items-center">
            <div className="col-lg-12 dashboard-headline text-start">
              <Link className="text-decoration-none pointer text-dark mb-3 d-block" to={USERTOKEN !== null ? `/${pathname}/dashboard` : "/"}><i  ><FaAngleLeft/></i> Back</Link>
              <h2>Create Quote</h2>
              
            </div>
          </div>
        </div>
      </section>
      <section className="three-boxes ">
        <div className="container">
          <Tab.Container id="" defaultActiveKey="first">
            <Row>
              <Col sm={12} className="pt-4 pb-5">
                <Nav variant="pills" className="row">
                  <Nav.Item className='col-sm-4'>
                    <Nav.Link eventKey="first" className="three-white-box d-block pointer">

                      <div className="three-d-box common-padding-box">
                        <h5>I have 3D files</h5>
                        
                        {modifyData==="Yes"?<p><a href="#">Request a quote</a></p>:modifyData==="No"?<p><a href="#">I need instant quote</a></p>:null}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className='col-sm-4'>
                    <Nav.Link eventKey="second" className="three-white-box d-block pointer">

                      <div className="two-d-box common-padding-box">
                        <h5>I have 2D files</h5>
                       
                        {modifyData==="Yes"?<p><a href="#">Request a quote</a></p>:modifyData==="No"?<p><a href="#">I need instant quote</a></p>:null}
                      </div>

                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className='col-sm-4' onClick={()=>Auth1!== null ? dispatch(modalNoFilesValue(true)):modalOpenNavigate(true)}>
                    <Nav.Link eventKey="thired" className="three-white-box d-block pointer ">

                      <div className="no-files-box common-padding-box">
                        <h5>I have NO files</h5>
                        
                        {modifyData==="Yes"?<p><a href="#">Request a quote</a></p>:modifyData==="No"?<p><a href="#">I need manual quote</a></p>:null}
                      </div>

                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="col-lg-12">
                      <div className="upload-outter">
                        <div className="upload-part-inner">
                          <div className="upload-part-content">
                            {/* <h5 className="mb-4">Simple drag and drop or upload </h5> */}
                            <div className='text-white'>
                                <p>Click below to upload a 3D model to see instant pricing, lead time, and DFM feedback</p>
                                <p>STEP | STP | SLDPRT | JT | STL | IPT | 3MF | 3DXML | PRT | SAT files</p>
                            </div>
                            <div className='position-relative d-flex align-items-center justify-content-center'>
                            <div className='d-flex align-items-center justify-content-center gradient-newtab'>
                                <div className='file-wraper'>
                                    <label htmlFor="upload-file">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="25" viewBox="0 0 37 25" fill="none">
                                        <path d="M29.8312 9.47835C29.3134 6.854 27.9005 4.49084 25.8338 2.79252C23.7671 1.0942 21.175 0.166082 18.5 0.166687C14.0446 0.166687 10.175 2.69502 8.24792 6.39502C5.98203 6.63989 3.88655 7.71347 2.36413 9.40947C0.841705 11.1055 -0.000264707 13.3043 6.24263e-08 15.5834C6.24263e-08 20.6863 4.14708 24.8334 9.25 24.8334H29.2917C33.5467 24.8334 37 21.38 37 17.125C37 13.055 33.8396 9.75585 29.8312 9.47835ZM29.2917 21.75H9.25C5.84292 21.75 3.08333 18.9904 3.08333 15.5834C3.08333 12.4229 5.44208 9.78669 8.57167 9.46294L10.2212 9.29335L10.9921 7.82877C11.7011 6.44864 12.7771 5.2909 14.1018 4.48304C15.4265 3.67517 16.9484 3.24851 18.5 3.25002C22.5392 3.25002 26.0233 6.11752 26.8096 10.0796L27.2721 12.3921L29.6308 12.5617C30.7898 12.6396 31.8761 13.1537 32.6713 14.0003C33.4665 14.847 33.9115 15.9635 33.9167 17.125C33.9167 19.6688 31.8354 21.75 29.2917 21.75ZM12.3333 14.0417H16.2646V18.6667H20.7354V14.0417H24.6667L18.5 7.87502L12.3333 14.0417Z" fill="white"/>
                                        </svg>
                                    </label>
                                </div>
                                <div className=''>
                                    <div className='d-flex  align-items-center justify-content-start '>
                                        {/* <span className="units elsetext">Or</span> */}

                                        {/* <select>
                                        <option>MM</option><option>MM</option>
                                        <option>MM</option>
                                        </select> */}
                                        <h5 className="mb-0 hometext mx-3">Get your AutoMach quote started now</h5>
                                        <h5 className="mb-0 elsetext mx-3">Get your AutoMach quote started now</h5>
                                    </div>
                                </div>
                            </div>
                            { Auth1!== null ? 
                            <p>
                              {/* <label htmlFor="upload-files">+</label> */}
                              <input className="drag-drop" type="file" id="upload-files"  accept=".stl , .stp , .OBJ , .SAT , .3DM , .IGS , .STP , .DLS , .FDM  , .MJF , .SLA , .step , .stl , .x_t , .3mf , .sldprt , .iges "
                            onChange={(e)=>handleImageChange(e)}
                            onClick={() => dispatch(idUploadValue("create-Quote"))}/>
                            </p>:
                            <p>
                              {/* <label htmlFor="upload-file">+</label> */}
                              <input className='drag-drop' onClick={()=>modalOpenNavigate(false)} id="upload-file" accept=".stl , .stp , .OBJ , .SAT , .3DM , .IGS , .STP , .DLS , .FDM  , .MJF , .SLA , .step , .stl , .x_t , .3mf , .sldprt , .iges " />
                              {/* <span className="units">Units</span> */}
                            </p>
                            }
                            </div>
                            <div className='text-white d-flex items-center justify-content-center mt-3'>
                                <svg className='mr-3' xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 16 21" fill="none">
                                <path d="M0 21V7H3V5C3 3.61667 3.48767 2.43733 4.463 1.462C5.43767 0.487333 6.61667 0 8 0C9.38333 0 10.5627 0.487333 11.538 1.462C12.5127 2.43733 13 3.61667 13 5V7H16V21H0ZM5 7H11V5C11 4.16667 10.7083 3.45833 10.125 2.875C9.54167 2.29167 8.83333 2 8 2C7.16667 2 6.45833 2.29167 5.875 2.875C5.29167 3.45833 5 4.16667 5 5V7ZM8 16C8.55 16 9.021 15.8043 9.413 15.413C9.80433 15.021 10 14.55 10 14C10 13.45 9.80433 12.979 9.413 12.587C9.021 12.1957 8.55 12 8 12C7.45 12 6.97933 12.1957 6.588 12.587C6.196 12.979 6 13.45 6 14C6 14.55 6.196 15.021 6.588 15.413C6.97933 15.8043 7.45 16 8 16Z" fill="black"/>
                                </svg>
                                <p>All uploads are secure and confidential.</p>
                            </div>
                            {/* <select>
                              <option>MM</option>
                              <option>MM</option>
                              <option>MM</option>
                            </select> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container pe-0 ps-0">
                      <div className="row">

                        <div className="col-lg-12 mt-5 bottom-border pb-4 mb-4">
                          <p className="font-15-3d-page">Supported file types <i className="fa fa-angle-up" aria-hidden="true" /></p>
                          <p className="mb-0 font-14-3d-page">For 3dm, asm, cam360, CATPart, CATProduct, dwg, dxf, f3d, fbx, g, iam, ige, iges, igs, ipt,
                          neu, obj, prt, sab, sat, skp, sldasm, sldprt, smb, smt, ste, step, stl, stp, wire, x_b, x_t, 123dx</p>
                          {/* <p className="font-14-3d-page">For Cast Urethane, CNC, and Injection Molding: .step, .sldprt, .x_t, .iges</p> */}
                        </div>
                      </div>
                      <div className="row pb-5 cursor-pointer">
                        <div className="col-lg-6 col-md-6">
                          <div className="need-help">
                            <h5>Need help?</h5>
                            <p>We're available Monday-Friday 8:00am-6:00pm Central Time.</p>
                            <p><a href="#" onClick={()=>dispatch(contactUsForm(true))}>Contact Us</a></p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="capabilities-box">
                            <h5>Capabilities</h5>
                            <p>Learn more about our materials, finishing services, and production options.</p>
                            <p><Link to="/capabilities">See More</Link></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                  <div className="col-lg-12">
                      <div className="upload-outter">
                        <div className="upload-part-inner">
                          <div className="upload-part-content">
                            {/* <h5 className="mb-4">Simple drag and drop or upload </h5> */}
                            <div className='text-white'>
                                <p>Click below to upload a 3D model to see instant pricing, lead time, and DFM feedback</p>
                                <p>STEP | STP | SLDPRT | JT | STL | IPT | 3MF | 3DXML | PRT | SAT files</p>
                            </div>
                            <div className=' position-relative d-flex align-items-center justify-content-center'>
                            <div className='d-flex align-items-center justify-content-center gradient-newtab'>
                                <div className='file-wraper'>
                                    <label htmlFor="upload-file">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="25" viewBox="0 0 37 25" fill="none">
                                        <path d="M29.8312 9.47835C29.3134 6.854 27.9005 4.49084 25.8338 2.79252C23.7671 1.0942 21.175 0.166082 18.5 0.166687C14.0446 0.166687 10.175 2.69502 8.24792 6.39502C5.98203 6.63989 3.88655 7.71347 2.36413 9.40947C0.841705 11.1055 -0.000264707 13.3043 6.24263e-08 15.5834C6.24263e-08 20.6863 4.14708 24.8334 9.25 24.8334H29.2917C33.5467 24.8334 37 21.38 37 17.125C37 13.055 33.8396 9.75585 29.8312 9.47835ZM29.2917 21.75H9.25C5.84292 21.75 3.08333 18.9904 3.08333 15.5834C3.08333 12.4229 5.44208 9.78669 8.57167 9.46294L10.2212 9.29335L10.9921 7.82877C11.7011 6.44864 12.7771 5.2909 14.1018 4.48304C15.4265 3.67517 16.9484 3.24851 18.5 3.25002C22.5392 3.25002 26.0233 6.11752 26.8096 10.0796L27.2721 12.3921L29.6308 12.5617C30.7898 12.6396 31.8761 13.1537 32.6713 14.0003C33.4665 14.847 33.9115 15.9635 33.9167 17.125C33.9167 19.6688 31.8354 21.75 29.2917 21.75ZM12.3333 14.0417H16.2646V18.6667H20.7354V14.0417H24.6667L18.5 7.87502L12.3333 14.0417Z" fill="white"/>
                                        </svg>
                                    </label>
                                </div>
                                <div className=''>
                                    <div className='d-flex  align-items-center justify-content-start '>
                                        {/* <span className="units elsetext">Or</span> */}

                                        {/* <select>
                                        <option>MM</option><option>MM</option>
                                        <option>MM</option>
                                        </select> */}
                                        <h5 className="mb-0 hometext mx-3">Get your AutoMach quote started now</h5>
                                        <h5 className="mb-0 elsetext mx-3">Get your AutoMach quote started now</h5>
                                    </div>
                                </div>
                            </div>
                            <p>
                              {/* <label htmlFor="upload-files">+</label> */}
                              <input className="drag-drop" type="file" id="upload-files" />
                              {/* <span className="units">Units</span>
                              <select>
                                <option>MM</option>
                                <option>MM</option>
                                <option>MM</option>
                              </select> */}
                            </p>
                          </div>
                          <div className='text-white d-flex items-center justify-content-center mt-3'>
                                <svg className='mr-3' xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 16 21" fill="none">
                                <path d="M0 21V7H3V5C3 3.61667 3.48767 2.43733 4.463 1.462C5.43767 0.487333 6.61667 0 8 0C9.38333 0 10.5627 0.487333 11.538 1.462C12.5127 2.43733 13 3.61667 13 5V7H16V21H0ZM5 7H11V5C11 4.16667 10.7083 3.45833 10.125 2.875C9.54167 2.29167 8.83333 2 8 2C7.16667 2 6.45833 2.29167 5.875 2.875C5.29167 3.45833 5 4.16667 5 5V7ZM8 16C8.55 16 9.021 15.8043 9.413 15.413C9.80433 15.021 10 14.55 10 14C10 13.45 9.80433 12.979 9.413 12.587C9.021 12.1957 8.55 12 8 12C7.45 12 6.97933 12.1957 6.588 12.587C6.196 12.979 6 13.45 6 14C6 14.55 6.196 15.021 6.588 15.413C6.97933 15.8043 7.45 16 8 16Z" fill="black"/>
                                </svg>
                                <p>All uploads are secure and confidential.</p>
                            </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div className="container pe-0 ps-0">
                      <div className="row">

                        <div className="col-lg-12 mt-5 bottom-border pb-4 mb-4">
                          <p className="font-15-3d-page">Supported file types <i className="fa fa-angle-up" aria-hidden="true" /></p>
                          <p className="mb-0 font-14-3d-page">For 3dm, asm, cam360, CATPart, CATProduct, dwg, dxf, f3d, fbx, g, iam, ige, iges, igs, ipt,
                          neu, obj, prt, sab, sat, skp, sldasm, sldprt, smb, smt, ste, step, stl, stp, wire, x_b, x_t, 123dx</p>
                          {/* <p className="font-14-3d-page">For Cast Urethane, CNC, and Injection Molding: .step, .sldprt, .x_t, .iges</p> */}
                        </div>
                      </div>
                      <div className="row pb-5">
                        <div className="col-lg-6 col-md-6">
                          <div className="need-help" onClick={()=>dispatch(contactUsForm(true))}>
                            <h5>Need help?</h5>
                            <p>We're available Monday-Friday 8:00am-6:00pm Central Time.</p>
                            <p><a href="#">Contact Us</a></p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="capabilities-box">
                            <h5>Capabilities</h5>
                            <p>Learn more about our materials, finishing services, and production options.</p>
                            <p><a href="#">Contact Us</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                 
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

        </div>
      </section>

    </React.Fragment>
  )
}

export default Create3d;