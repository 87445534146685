
import React, { useCallback, useEffect, useState } from 'react'
import { Spinner, Modal, Button } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import axios from 'axios'
import MobileInput from '../mobileInput/mobileInput';
import Input from 'react-phone-number-input/input'

import FormValidation from '../../../../formValidation/formValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { getMethodData, getMethodDataUser } from '../../adminApi/api';


const UpdateProfile = (props) => {


    const { id } = useParams()
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const Token = localStorage.getItem("token")
    const AdminEmail = localStorage.getItem("email")
    const AdminPass = localStorage.getItem("password")
    const [value, setValue] = useState()
    const [loading, setLoading] = useState(false)


    const navigate = useNavigate()
    const [allUsers, setAllUsers] = useState(["not"])
    // form validation rules
    const validationSchema = Yup.object().shape({

        email: Yup.string().email('The email must be a valid email.').required("Email is a required field")

    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const FormValidationNew = FormValidation



    const closeModal = useCallback(() => {
        reset()
        props.onHide()
    }, [])

    const getProfile = useCallback( () => {
        reset()
        if(Token){
            getMethodData("/get-profile",Token)
            .then((res) => {
                setAllUsers(res.data.result.data);
                setValue("+1" + res.data.result.data.mobile.replace(/[()\ \s-]+/g, ''))
            })
        }
    }, [Token])

    useEffect(() => {
        getProfile()
    }, [Token ])

    const onSubmit = (data) => {
        setLoading(true)
        var fd = new FormData();

        var inputs = document.getElementsByClassName('form-control');
        const mob = parseInt(data.mobile)
        fd.append("mobile", mob);


        for (let i = 0; i < inputs.length; i++) {

            fd.append(inputs[i].name, inputs[i].value);
        }

        axios.post(`${BASE_URL}/update-profile`, fd, { headers: { authorization: `Bearer ${Token}` } })
            .then((res) => {
                
                if (res.status === 200) {
                    setLoading(false)
                    getProfile()
                    toast.success(res.data.message);
                    closeModal()


                } else {
                    setLoading(false)
                    toast.error(res.data.message);

                }

            })
    };

    useEffect(() => {
        FormValidationNew()
    }, [FormValidationNew])
    return (

        <div className="modal-edit">
            <Modal  {...props} className="edit-content">

                <div className="max-new-second">
                    <div className="main-theme bg-theme border shadow">
                        <Modal.Header closeButton onClick={() => closeModal()}>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <section className="select-state">
                            <div className="state-bor">
                                <div className="log-in otp-num">
                                    <div className="select-steps-box">
                                        <div className="input-head">
                                            <h2>Update Profile</h2>
                                        </div>
                                    </div>
                                    <div className="select-box-top pr-0">
                                        <div className="input-box">
                                            <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                                                <div className="col-12 p-0">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <input type="text" className="form-control" id="validation1" maxLength="15" defaultValue={allUsers.first_name} placeholder="First Name" {...register('first_name', { required: true })} required />
                                                                <div className="invalid-feedback">
                                                                    Please Enter First Name.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <input type="text" className="form-control" id="validation2" maxLength="15" defaultValue={allUsers.last_name} placeholder="Last Name" {...register('last_name', { required: true })} required />
                                                                <div className="invalid-feedback">
                                                                    Please Enter Last Name.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <input type="email" maxLength="100" className="form-control" id="validation3" defaultValue={allUsers.email} placeholder="Email" {...register('email', { required: true })} required />
                                                                <small className='text-danger'> {errors.email?.message}</small>
                                                                <div className="invalid-feedback">
                                                                Please Enter Valid Email Address.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <Input
                                                                    name="mobile"
                                                                    {...register("mobile", { required: true })}
                                                                    value={value}
                                                                    onChange={setValue}
                                                                    placeholder="Number"
                                                                    defaultCountry="US"
                                                                    minLength={14}
                                                                    maxLength={14}
                                                                    className="form-control"
                                                                />
                                                                <div className="invalid-feedback">
                                                                    Please Enter Mobile Number.
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="theme-btn btn-group-right">
                                                            {loading === false ?
                                                                <button type='submit' className="btn" variant="secondary" >
                                                                    Update
                                                                </button> :
                                                                <Button className='btn  spinner' variant='dark' disabled>
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="grow"

                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                                                                    Updating...
                                                                </Button>}
                                                            <button type="reset" className="btn" variant="primary" onClick={() => closeModal()}>
                                                                Cancel
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </Modal>

        </div>
    )
}

export default UpdateProfile