import React, { useState, useEffect, useCallback } from "react";
import {
  Outlet,
  useNavigate,
  Link,
  NavLink,
  useLocation,
} from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import UpdateProfile from "../updateProfile/updateProfile";
import ChangePassword from "../changePassword/changePassword";
import { ImageObj } from "../../../../assets/images/imgeObject";
import LoginUser from "../../components/pages/auth/loginUser/loginUser";
import { useDispatch, useSelector } from "react-redux";
import {
  modalToggelValue,
  modalRgisterlValue,
  modalRegisterlSuppValue,
  modalResetValue,
  modalResetMsgValue,
  modalConditionValue,
  modalPolicyValue,
  modalBecomeValue,
  modalNoFilesValue,
  userDataValue,
  contactUsForm,
  customerLoader,
} from "../../../../redux/actions/action";
import RegisterUser from "../../components/pages/auth/registerUser/registerUser";
import ResetMail from "../../components/pages/auth/resetMail/resetMail";
import ResetPasswordLink from "../../components/pages/auth/resetPwLink/resetPasswordLink";

import { MdManageAccounts, MdLogout } from "react-icons/md";
import { getMethodDataUser } from "../../../admin/adminApi/api";
import RegisterSupplier from "../../components/pages/auth/registerSupplier/registerSupplier";
import Conditions from "../conditions/conditions";
import Policy from "../policy/policy";
import BecomeAsupplier from "../../components/pages/auth/becomeAsupplier/becomeAsupplier";
import NoFileQoute from "../noFilesQoute/noFilesQoute";
import { toast } from "react-toastify";
import { FaRegBell } from "react-icons/fa";
import axios from "axios";
import Contactus from "../contactus/Contactus";

const Header = (props) => {
  const location = useLocation();
  const adminPath = location.pathname.split("/")[1];
  const USERTOKEN = localStorage.getItem("userToken");
  const SUPPLIERTOKEN = localStorage.getItem("supplierToken");
  const ModalValue = useSelector((state) => state.modalValueReducer);
  const ModalResetValue = useSelector((state) => state.modalResetReducer);
  const ModalResetMsgValue = useSelector((state) => state.modalResetMsgReducer);
  const ModalRegisterValue = useSelector((state) => state.modalRegisterReducer);
  const SupplierValue = useSelector((state) => state.modalRegisterSuppReducer);
  const ConditionValue = useSelector((state) => state.modalConditionReducer);
  const PolicyValue = useSelector((state) => state.modalPolicyReducer);
  const BecomeValue = useSelector((state) => state.modalBecomeReducer);
  const NoFilesValue = useSelector((state) => state.modalNoFilesReducer);

  console.log("==========>>>>>>>>>",BecomeValue)
  const navigateValueLocal = localStorage.getItem("navigateValue");

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [modalProfileShow, setModalProfileShow] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState(["Not"]);
  const [count, setcount] = useState();
  const [jumpDashboard, setJumpDashboard] = useState(false);

  const [isToggled, setIsToggled] = useState(false);
  const [isToggled2, setIsToggled2] = useState(false);


  const handleClosePw = () => {
    setShowPassword(false);
  };
  const getProfileDetails = useCallback(() => {
    if (USERTOKEN || SUPPLIERTOKEN) {
      getMethodDataUser("/get-profile", USERTOKEN || SUPPLIERTOKEN).then(
        (res) => {
          setUserData(res.data.result.data);
          setcount(res.data.result.un_read_notification_count);
          dispatch(userDataValue(res.data.result.data));
        }
      );
    }
  }, [props, SupplierValue]);

  useEffect(() => {
    getProfileDetails();
  }, [props, SupplierValue]);

  document.body.classList.add("grey-bg", "top-user-dash");

  const handleShowPw = () => setShowPassword(true);

  const unAuth = () => {
    localStorage.removeItem("roleUser");
    localStorage.removeItem("userToken");
    localStorage.removeItem("username");
    localStorage.removeItem("is_supplier");
    localStorage.removeItem("supplierToken");
    localStorage.removeItem("is_admin_approved");

    navigate("/");
  };

  const logOutDash = () => {
    document.body.classList.remove("grey-bg", "top-user-dash");
    unAuth();
    dispatch(customerLoader(false));
  };

  const dispatchToggle = () => {
    setJumpDashboard(true);

    dispatch(modalRgisterlValue(false));
    dispatch(modalToggelValue(true));
  };

  useEffect(() => {
    document.body.classList.add("home");
    document.body.classList.remove("remove-header");

    return () => {
      document.body.classList.remove("home");
    };
  }, []);
  const BASE_URL = process.env.REACT_APP_BASE_URL_USER;
  // condition in header

  const scrolltosmartmanufacture=()=>{
    const element = document.getElementById("scrollhere");
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  //condition in header
  const headerCondition1 = () => {
    return (
      <header className="navbar navbar-sticky flex-wrap navbar-expand-lg bg-white py-1 user-header">
        <div className="container-fluid col-md-11 mx-auto">
          <Link className="navbar-brand me-4 order-lg-1" to="/">
            <img src={ImageObj.Logo} width={187} alt="Cartzilla" />
          </Link>
          <div className="navbar-toolbar d-flex align-items-center order-lg-3">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}
            >
              <span className="navbar-toggler-icon" />
            </button>
          </div>
          <div
            className="collapse navbar-collapse me-auto order-lg-2"
            id="navbarCollapse"
          >
            <ul className="navbar-nav navbar-mega-nav ms-lg-auto pe-lg-2 me-lg-auto pt-3 pt-lg-0">
              <li className="nav-item">
                <Link to="/" className="text-decoration-none"  onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}>
                  <span className="nav-link ps-lg-0">Home</span>
                </Link>
              </li>
              <li className="nav-item drop-header position-relative">
                <Link to="#" className="text-decoration-none" onClick={()=>{
                  if (window.innerWidth < 768) {
                    setIsToggled(!isToggled)
                  }
                }}>
                  <span className="nav-link ps-lg-0">Capabilities</span>
                </Link>
                <ul className={`hover-menu ${isToggled ? "d-block" : "d-none"}`}>
                  <li>
                    <Link to="/fabrication" className="text-decoration-none"  onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}>
                      <span>Fabrication</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/cnc-machining" className="text-decoration-none"  onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}>
                      <span>CNC Machining</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/metal-cutting" className="text-decoration-none"  onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}>
                      <span>Metal Cutting</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link to="/mach2mfg-industry" className="text-decoration-none"  onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}>
                  <span className="nav-link ps-lg-0">Industries</span>
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/services" className="text-decoration-none">
                  <span className="nav-link ps-lg-0">Services</span>
                </Link>
              </li> */}
              {/* <li className="nav-item drop-header position-relative">
                <Link to="#" className="text-decoration-none" onClick={()=>
                  {
                    if (window.innerWidth < 768) {
                      setIsToggled2(!isToggled2)
                    }
                  }}>
                  <span className="nav-link ps-lg-0">Resources</span>
                </Link>
                <ul className={`hover-menu ${isToggled2 ? "d-block" : "d-none"}`}>
                  <li>
                    <Link to="/quoting-process" className="text-decoration-none">
                      <span>Quoting Process</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/order-tracking" className="text-decoration-none">
                      <span>Order Tracking</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/supplier-process" className="text-decoration-none">
                      <span>Supplier Process</span>
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item">
                <Link to="/why-us" className="text-decoration-none"  onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}>
                  <span className="nav-link ps-lg-0">Why Us?</span>
                </Link>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link ps-lg-0 pe-lg-0 pointer"
                  onClick={() => {dispatch(modalBecomeValue(true,"supplier"));document.getElementById("navbarCollapse").classList.toggle("show");}}
                >
                  Supplier Sign in
                </span>
              </li>
            </ul>
            <div className="navbar-tool ms-1 me-n1">
              {/* <button onClick={scrolltosmartmanufacture} className="btn btn-primary-orange">
                Get a Quote
              </button> */}
              {/* <a href="/#scrollhere" className="btn btn-primary-orange" onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}>
                Get a Quote
              </a> */}
              <a
                target="_blank"
                type="button"
                className="btn btn-primary-outline ms-2"
                href={process.env.REACT_APP_DIGIFABSTER_LOGIN_URL}
                // onClick={() => {dispatchToggle(); document.getElementById("navbarCollapse").classList.toggle("show")}}
                onClick={() => {document.getElementById("navbarCollapse").classList.toggle("show")}}
              >
                Customer Sign in
              </a>
            </div>
          </div>
        </div>
      </header>
    );
  };

  const headerCondition = () => {
    return (
      <header className="navbar navbar-sticky navbar-expand-lg bg-white py-1 user-dash">
        <div className="container">
          {navigateValueLocal === "true" ? (
            <Link className="navbar-brand me-4 order-lg-1" to={"/"}>
              <img src={ImageObj.Logo} width={187} alt="..." />
            </Link>
          ) : (
            <Link
              className="navbar-brand me-4 order-lg-1"
              to={String(props.logopath)}
            >
              <img src={ImageObj.Logo} width={187} alt="..." />
            </Link>
          )}

          <div className="navbar-toolbar d-flex align-items-center order-lg-3">
            <button
               className="navbar-toggler"
               type="button"
               data-bs-toggle="collapse"
               data-bs-target="#navbarCollapse"
               onClick={()=>{
                 document.getElementById("navbarCollapse").classList.toggle("show");
               }}
            >
              <span className="navbar-toggler-icon" />
            </button>
          </div>
          <div
            className="collapse navbar-collapse me-auto order-lg-2"
            id="navbarCollapse"
          >
            <ul className="navbar-nav navbar-mega-nav ms-lg-auto pe-lg-2 me-lg-auto pt-3 pt-lg-0">
              <li className="nav-item ">
                <NavLink to="dashboard" className="nav-link" onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}>
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink to={"editprofile"} className="nav-link" onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}>
                  My Profile
                </NavLink>
              </li>
             {String(adminPath) !== "supplier" && (
                <li className="nav-item">
                  <NavLink to="myparts" className="nav-link" onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}>
                    My parts
                  </NavLink>
                </li>
              )}
              {/* <li className="nav-item">
                <NavLink to="register" className="nav-link ps-lg-0">Account</NavLink>
              </li> */}
              {props.path1 !== undefined || props.path1 !== null ?<li className="nav-item">
                <NavLink
                onClick={()=>{
                  document.getElementById("navbarCollapse").classList.toggle("show");
                }}
                  to={String(props.path1)}
                  className="nav-link"
                >{`My ${props.path1}`}</NavLink>
              </li> :null}
               
              <li className="nav-item">
                <NavLink to={"orders"} className="nav-link" onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}>
                  My Orders
                </NavLink>
              </li>
              
              {String(adminPath) === "supplier" && (
                <li className="nav-item">
                  <NavLink to="earning" className="nav-link" onClick={()=>{
                document.getElementById("navbarCollapse").classList.toggle("show");
              }}>
                    My Earnings
                  </NavLink>
                </li>
              )}
              <li className="nav-item">
                {/* <a href="#" className="nav-link" onClick={()=>setShowContactus(!showContactus)} rel="nofollow">
                  Contact Us
                </a> */}
                <a href="#" className="nav-link" onClick={()=>{dispatch(contactUsForm(true));document.getElementById("navbarCollapse").classList.toggle("show")}} rel="nofollow">
                  Contact Us
                </a>
              </li>
            </ul>
            <div className="navbar-tool ms-1 me-n1 d-flex">
              {userData.role_id !== 2 ? (
                userData.role_id === 1 && userData.is_supplier === 0 ? (
                  <button
                    type="button"
                    onClick={() => {dispatch(modalRegisterlSuppValue(true));document.getElementById("navbarCollapse").classList.toggle("show")}}
                    className="btn btn-primary-orange m-0"
                  >
                    Supplier Sign in
                  </button>
                ) : String(adminPath) == "customer" ? (
                  userData?.is_admin_approved !== 0 &&
                  userData?.is_supplier_active !== "inactive" ? (
                    <Link
                    onClick={()=>{
                      document.getElementById("navbarCollapse").classList.toggle("show");
                    }}
                      to="/supplier/dashboard"
                      className={"btn btn-primary-orange m-0 "}
                      style={{ fontSize: "12px" }}
                    >
                      Go To Supplier Panel
                    </Link>
                  ) : (
                    <button
                      style={{ fontSize: "12px" }}
                      className="btn btn-primary-orange m-0"
                      onClick={() =>
                        
                        {document.getElementById("navbarCollapse").classList.toggle("show");
                        toast.warning(
                          userData?.is_admin_approved === 0
                            ? "Your supplier registration request is not approved yet. Please try after some time."
                            : "Your account has been inactivated any the Mach2Mfg admin. Please get in touch with them.",
                          { autoClose: 15000 }
                        )}
                      }
                    >
                      Go To Supplier Panel
                    </button>
                  )
                ) : (
                  <Link
                  onClick={()=>{
                    document.getElementById("navbarCollapse").classList.toggle("show");
                  }}
                    to="/customer/dashboard"
                    className="btn btn-primary-orange m-0"
                    style={{ fontSize: "12px" }}
                  >
                    Go To Customer Panel
                  </Link>
                )
              ) : (
                ""
              )}

              <Dropdown
                className="user-dropdown"
                align="end"
                id="dropdown-menu-align-end"
              >
                <Dropdown.Toggle
                  className="p-0 m-0 bg-transparent border-0 ps-3"
                  id="dropdown-basic"
                >
                  <span className="user-name">
                    {localStorage.getItem("username")[0]}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="triangle">
                  <Dropdown.Item as="button" className="drop-content">
                    {" "}
                    <Link to="editprofile" className="text-decoration-none" onClick={()=>{
                    document.getElementById("navbarCollapse").classList.toggle("show");
                  }}>
                      <MdManageAccounts />
                      Edit Profile
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    className="drop-content"
                    onClick={() => {logOutDash();document.getElementById("navbarCollapse").classList.toggle("show")}}
                  >
                    <MdLogout />
                    Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {String(adminPath) === "supplier" && (
                <Link to="/supplier/notiticationlist" onClick={()=>{
                  document.getElementById("navbarCollapse").classList.toggle("show");
                }}>
                  <span className="circle-avtar ms-3  fs-4 position-relative">
                    <FaRegBell />
                    <span>
                      <span className="badge-tp">{count}</span>
                    </span>
                  </span>
                </Link>
              )}

              {/* <img src={ImageObj.ProfileImage} alt="user profile" /> */}
            </div>
          </div>
        </div>

      </header>
    );
  };
  return (
    <React.Fragment>
      {/* <div className='theme-btn'>
                <button className='btn w-100' onClick={() => setModalProfileShow(true)}>
                    Update Profile
                </button>
            </div>
            <button onClick={() => handleShowPw()}>Change Password</button> */}

      {/* Navbar*/}
      {/* Remove "navbar-sticky" class to make navigation bar scrollable with the page.*/}

      {USERTOKEN?.length || SUPPLIERTOKEN?.length
        ? headerCondition()
        : headerCondition1()}

      <UpdateProfile
        show={modalProfileShow}
        onHide={() => setModalProfileShow(false)}
        userdata={userData}
      />

      <ChangePassword
        api={"/update-password"}
        unAuth={unAuth}
        onHide={handleClosePw}
      />

      <RegisterUser
        show={ModalRegisterValue}
        onHide={() => dispatch(modalRgisterlValue(false))}
      />
      <LoginUser
        jumpdashboard={jumpDashboard.toString()}
        setjump={setJumpDashboard.toString()}
        show={ModalValue}
        onHide={() => dispatch(modalToggelValue(false))}
      />
      <ResetMail
        show={ModalResetValue}
        onHide={() => dispatch(modalResetValue(false))}
      />

      <ResetPasswordLink
        show={ModalResetMsgValue}
        onHide={() => dispatch(modalResetMsgValue(false))}
      />

      <RegisterSupplier
        show={SupplierValue}
        onHide={() => dispatch(modalRegisterlSuppValue(false))}
        userdata={userData}
      />

      <Conditions
        show={ConditionValue}
        onHide={() => dispatch(modalConditionValue(false))}
      />

      <Policy
        show={PolicyValue}
        onHide={() => dispatch(modalPolicyValue(false))}
      />
      <BecomeAsupplier
        show={BecomeValue.madalBecomeValue}
        onHide={() => dispatch(modalBecomeValue(false))}
      />

      <NoFileQoute
        show={NoFilesValue}
        userdata={userData}
        onHide={() => dispatch(modalNoFilesValue(false))}
      />
      <Contactus/>
      <Outlet />
    </React.Fragment>
  );
};

export default Header;
