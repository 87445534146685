import React, { useState, useEffect, useCallback } from "react";
import {
  FaSearch,
  FaRegCalendarAlt,
  FaEye,
  FaFileDownload,
  FaRegTimesCircle,
  FaRedoAlt,
  FaTruckMoving,
} from "react-icons/fa";

import { Link, Navigate, useNavigate } from "react-router-dom";
import { getMethodDataUser } from "../../../../../admin/adminApi/api";
import { GiAutoRepair } from "react-icons/gi";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import Pagination from "react-js-pagination";

import QuoteCollapse from "../../../../common/quoteCollapse/quoteCollapse";
import CancelOrder from "../../../../common/cancelOrder/cancelOrder";
import ReturnOrder from "../../../../common/returnOrder/returnOrder";
import { useLayoutEffect } from "react";
import axios from "axios";
const OrdersList = () => {
  // const [modifyData, setModifyData] = useState();
  // useLayoutEffect(() => {
  //   const BASE_URL = process.env.REACT_APP_BASE_URL_USER;
  //   axios.get(`${BASE_URL}/user/get-globle-config/QUOTE_MODIFY`).then((req) => {
  //     setModifyData(req?.data?.result?.data?.value);
  //     // console.log(req?.data?.result?.data?.value);
  //   });
  // }, []); 
  const navigate = useNavigate();
  
  const [tableListData, setTableListData] = useState([]);
  const [Count, setCount] = useState(1);
  const [perPage, setPerPage] = useState();
  const [loading, setLoading] = useState(false);
  const [filterVisibility, setFilterVisibility] = useState(true);
  const [indexPage, setIndexPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const { register, control, handleSubmit, reset } = useForm();
  const USERTOKEN = localStorage.getItem("userToken");
  const [modalCancelShow, setModalCancelShow] = React.useState(false);
  const [modalReturnShow, setModalReturnShow] = React.useState(false);

  const [orderId, setOrderId] = useState();

  const cancelModalFunc = (id) => {
    setModalCancelShow(true);
    setOrderId(id);
  };

  const returnModalFunc = (id) => {
    setModalReturnShow(true);
    setOrderId(id);
  };

  const LoadTableData = useCallback(async (pageNumber) => {
    setIndexPage(1);

    setLoading(false);
    getMethodDataUser("/orders", USERTOKEN).then((res) => {
      setTableListData(res.data.result.data.data);
      setCount(res.data.result.data.total);
      setPerPage(res.data.result.data.per_page);

      setLoading(true);
      setFilterVisibility(true);
    });
  }, []);

  const resetTable = () => {
    reset();
    LoadTableData();
  };

  const sendPage = (pageNumber) => {
    getMethodDataUser(`/orders?page=${pageNumber}`, USERTOKEN).then((res) => {
      setTableListData(res.data.result.data.data);
      setCount(res.data.result.data.total);
      setPerPage(res.data.result.data.per_page);

      setLoading(true);
    });
  };

  const handlePageChange = (pageNumber) => {
    // sendPage(pageNumber)
    // LoadTableData(pageNumber)
    if (filterVisibility === true) {
      LoadTableData(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    } else {
      sendPage(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    }
  };

  const onSubmit = useCallback(
    (data) => {
      data.from_date =
        !data.from_date ||
        data.from_date === null ||
        data.from_date === undefined
          ? ""
          : moment(data.from_date).format("YYYY-MM-DD");
      data.to_date =
        !data.to_date || data.to_date === null || data.to_date === undefined
          ? ""
          : moment(data.to_date).format("YYYY-MM-DD");

      setIndexPage(1);

      setLoading(false);
      data.payment_status = "";

      getMethodDataUser(
        `/orders?${new URLSearchParams(data).toString()}`,
        USERTOKEN
      ).then((res) => {
        setTableListData(res.data.result.data.data);
        setCount(res.data.result.data.total);

        setLoading(true);
        setFilterVisibility(false);
      });
    },
    [register]
  );


  useEffect(() => {
    LoadTableData();
  }, []);


  // if(modifyData==="Yes"){
  //   return navigate('/customer/dashboard')
  //  }
  // else{
    return (
      <React.Fragment>
        <QuoteCollapse />
        <section className="pt-5 mt-3">
          <div className="container dashboard-heading-block py-3">
            <div className="row align-items-center">
              <div className="col-lg-12 dashboard-headline text-start max-width-500">
                <h2>My Orders</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-3 col-sm-6">
                      <div className="rounded rounded-pill mb-3 border border-dark search-style1 ">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <button id="button-addon2" className="btn btn-link">
                              <i>
                                <FaSearch />
                              </i>
                            </button>
                          </div>
                          <input
                            type="search"
                            {...register("order_id", { required: false })}
                            name="order_id"
                            placeholder="Order ID"
                            aria-describedby="button-addon2"
                            className="form-control border-0 ps-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                      <div className="form-group ">
                        <select
                          className="form-select form-control rounded rounded-pill mb-3 border border-dark search-style1 "
                          {...register("status", { required: false })}
                          id="validationCustom12"
                          aria-label="Default select example"
                        >
                          <option className="disabled-value " value="">
                            All
                          </option>
                          <option value="Order Received">Order Received</option>
                          <option value="Material Ordered">
                            Material Ordered
                          </option>
                          <option value="Work In Progress">
                            Work In Progress
                          </option>
                          <option value="Quality Inspection">
                            Quality Inspection
                          </option>
                          <option value="Order Shipped">Order Shipped</option>
                          <option value="Delivered">Delivered</option>
                          <option value="Cancelled">Cancelled</option>
                          <option value="Returned">Returned</option>
                          <option value="Refunded">Refunded</option>
                          <option value="Cancellation Requested">
                            Cancellation Requested
                          </option>
                          <option value="Return Requested">
                            Return Requested
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                      <div className="rounded rounded-pill mb-3 border border-dark search-style1 form-search-new">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <i>
                              <FaRegCalendarAlt />
                            </i>
                          </div>
                          <div className="form-group before-con">
                            <Controller
                              control={control}
                              name="from_date"
                              render={({ field }) => (
                                <DatePicker
                                  dateFormat="MM/dd/yyyy"
                                  onChange={(date) => field.onChange(date)}
                                  selected={field.value}
                                  placeholderText="From Date"
                                  maxDate={new Date()}
                                />
                              )}
                            />
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                      <div className="rounded rounded-pill mb-3 border border-dark search-style1 form-search-new">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <i>
                              <FaRegCalendarAlt />
                            </i>
                          </div>
                          <div className="form-group before-con after">
                            <Controller
                              control={control}
                              name="to_date"
                              render={({ field }) => (
                                <DatePicker
                                  dateFormat="MM/dd/yyyy"
                                  placeholderText="To Date"
                                  onChange={(date) => field.onChange(date)}
                                  selected={field.value}
                                  maxDate={new Date()}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 d-flex align-items-center justify-content-center">
                      <button
                        className="w-50 btn btn-primary-orange mb-3 m-0 min-hp-40"
                        type="submit"
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        onClick={() => resetTable()}
                        className="btn w-50 mb-3 btn-primary-outline ms-2  min-hp-40"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-5 pt-3">
          <div className="container gx-0 grid-data position-relative">
            <div className="row gx-0 grid-head-row">
              <div className="col pe-0">
                <span className="data-value">Sr. No.</span>
              </div>
              <div className="col">
                <span className="data-value">Order ID</span>
              </div>
              <div className="col pe-0">
                <span className="data-value text-nowrap">Transaction ID</span>
              </div>
              <div className="col">
                <span className="data-value">Date</span>
              </div>
              <div className="col">
                <span className="data-value">Amount</span>
              </div>
              <div className="col">
                <span className="data-value">Status</span>
              </div>
              <div className="col">
                <span className="data-value">Payment By</span>
              </div>
              <div className="col">
                <span className="data-value">Payment Status</span>
              </div>
              <div className="col col-1">
                <span className="data-value">Action</span>
              </div>
            </div>
            {tableListData !== null && tableListData !== undefined ? (
              tableListData
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    val.name.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else {
                    return false;
                  }
                })
                .map((res, i) => {
                  return (
                    <div className="row gx-0 grid-data-row">
                      <div className="col pe-0" data-label="Sr. No.: ">
                        <span className="data-value">{i + 1}</span>
                      </div>
                      <div className="col" data-label="ID: ">
                        <span className="data-value">#{res.order_id}</span>
                      </div>
                      <div className="col" data-label="Transation ID: ">
                        <span className="data-value">#{res.transction_id}</span>
                      </div>
                      <div className="col" data-label="Date: ">
                        <span className="data-value">
                          {moment(res.date).format("MM/DD/yyyy")}
                        </span>
                      </div>
                      <div className="col" data-label="Amount: ">
                        <span className="data-value">${res.final_amt}</span>
                      </div>
                      <div className="col" data-label="Status: ">
                        <span className="data-value">{res.status}</span>
                      </div>
                      <div className="col" data-label="payment_status: ">
                        <span className="data-value">
                          {res.payment_method ?? "Not Initiated"}
                        </span>
                      </div>
                      <div className="col" data-label="Name: ">
                        <span className="data-value">
                          {res.payment_status ?? "Not Initiated"}
                        </span>
                      </div>
                      <div
                        className="col-1 d-flex flex-wrap align-items-center action"
                        data-label="Action"
                      >
                        <Link title="view details" to={`${res.id}`}>
                          <FaEye className="fs-5 fw-normal ms-2 pointer" />
                        </Link>
                        <a
                          title={
                            res.invoice_path !== null
                              ? "download invoice pdf"
                              : ""
                          }
                          href={res.invoice_path}
                          target="_blank"
                        >
                          {res.invoice_path !== null && (
                            <FaFileDownload className="fs-5 fw-normal ms-2 pointer" />
                          )}
                        </a>
                        {/* <FaTruckMoving onClick={() => toast.warning("No updated tracking information available. Please check back.")}  title='track order' className='fs-5 fw-normal ms-2 pointer' /> */}
                        {/* {
                        res.status === "Delivered" &&
                        <span title='Return Order' onClick={() => returnModalFunc(res.id)}><FaRedoAlt className='fs-5 fw-normal ms-2 pointer' /></span>
                      } */}
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="no-data fs-1 fw-bolder text-center text-uppercase opacity-25 text-dark">
                No Orders Found
              </div>
            )}
            {!loading ? (
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiAutoRepair />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {Count > perPage && (
            <div className="container pe-0 ps-0">
              <div className="pagination">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={Number(Count)}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          )}
        </section>
        <CancelOrder
          show={modalCancelShow}
          onHide={() => setModalCancelShow(false)}
          orderlist={LoadTableData}
          orderid={orderId}
        />
        <ReturnOrder
          show={modalReturnShow}
          onHide={() => setModalReturnShow(false)}
          orderlist={LoadTableData}
          orderid={orderId}
        />
      </React.Fragment>
    );
  };

export default OrdersList;
