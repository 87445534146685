import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from "react-phone-number-input/input";
import { Button, Spinner, Modal } from "react-bootstrap";

import { Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  modalToggelValue,
  modalRgisterlValue,
  modalConditionValue,
  modalPolicyValue,
} from "../../../../redux/actions/action";
import { useDispatch } from "react-redux";

import {
  postMethodDataUser,
} from "../../../admin/adminApi/api";
import FormValidation from "../../../../formValidation/formValidation";

const NoFileQoute = (props) => {
  // console.log(props)
  const [value, setValue] = useState();
  const [mobileErr, setMobileErr] = useState([""]);

  const [emailErr, setEmailErr] = useState([""]);
  const USERTOKEN = localStorage.getItem("userToken");

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("The email must be a valid email. "),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const [loading, setLoading] = useState(false);

  const FormValidationNew = FormValidation;

  const [formValidation, setFormValidation] = useState({
    Email: "",
    Mobile: "",
    default: "",
    Phone: "",
    setPassErrFormat: "",
    Success: null,
  });
  useEffect(() => {
    reset();

    setMobileErr("");
    setEmailErr("");
    setValue("");
  }, [props]);
  const onSubmit = (data) => {
    setLoading(true);
    const imageFiles = document.getElementById("document").files[0];
    const fd = new FormData();
    for (var key in data) {
      fd.append(key, data[key]);
    }
    fd.append("quote_file", imageFiles);
    postMethodDataUser("/quotes/store-no-files", fd, USERTOKEN)
      .then((res) => {
        if (res?.data?.status === true) {
          setLoading(false);
          toast(res?.data?.message, { autoClose: 15000 });
          props.onHide();
          // navigate("/admin/supplier-management")
          reset();
        } else {
          setLoading(false);
          setMobileErr(res?.data?.result?.errors?.phone);
          setEmailErr(res?.data?.result?.errors?.email);
          // toast.error(res?.data?.result?.errors?.email)
          // toast.error(res?.data?.result?.errors?.phone)
          // toast.error(res?.data?.message)
        }

        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
    return false;
  };

  useEffect(() => {
    setValue("+1" + String(props.userdata.phone).replace(/[()\ \s-]+/g, ""));
  }, [props]);

  useEffect(() => {
    FormValidationNew();
  }, [FormValidationNew, formOptions]);

  

  return (
    <React.Fragment>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content">
          <div className="create-account">
            <div className="row">
              <div className="col-md-12 position-relative;">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => props.onHide()}
                />
                <h3 className=" m-0 pb-4 py-3">Request For Manual Quote</h3>

                <small className=" form-text d-block text-center  text-muted">
                  {formValidation.default}
                </small>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="needs-validation"
                  noValidate
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          maxLength="15"
                          defaultValue={`${props.userdata.first_name}${props.userdata?.last_name}`}
                          className="form-control"
                          id="validationCustom01"
                          {...register("name", { required: true })}
                          placeholder="Full Name"
                          required
                        />
                        <div className="invalid-feedback">
                          Please Enter Customer Full Name.
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 ">
                      <div className="form-group">
                        <input
                          type="email"
                          maxLength="100"
                          defaultValue={props.userdata.email}
                          className="form-control"
                          name="email"
                          id="validationCustomNew1"
                          {...register("email", { required: true })}
                          placeholder="Work Email"
                          required
                        />

                        <small className="text-danger">
                          {" "}
                          {errors.email?.message}
                        </small>
                        <div className="invalid-feedback">
                          Please Enter Valid Email Address.
                        </div>
                        <small className="form-text text-muted">
                          {emailErr}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <Input
                          className="form-control"
                          minLength={14}
                          maxLength={14}
                          name="phone"
                          defaultCountry="US"
                          placeholder="Phone"
                          {...register("phone", { required: true })}
                          value={value}
                          onChange={setValue}
                          required
                        />

                        <small className="font-12 d-block">
                          E.g (xxx) xxx-xxxx , No Need for Country Code
                        </small>
                        <small className="text-danger">
                          {" "}
                          {errors.phone?.message}
                        </small>
                        <small className="form-text text-muted">
                          {mobileErr}
                        </small>
                        <div className="invalid-feedback">
                          Please enter mobile number.
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          maxLength="40"
                          className="form-control"
                          id="validationCustomnew4"
                          {...register("company_name", { required: true })}
                          placeholder="Company"
                          required
                        />
                        <div className="invalid-feedback">
                          Please enter company.
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group w-9">
                        <div className="file-upload-div">
                          <input
                            className="form-control"
                            type="file"
                            id="document"
                            multiple
                            required
                          />
                          <span>Design File </span>
                        </div>

                        <div className="invalid-feedback">
                          Please upload document.
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                          type="text"
                          className="form-control"
                          id="validationCustomnew48"
                          {...register("message", { required: true })}
                          placeholder="Message"
                          required
                        />
                        <div className="invalid-feedback">
                          Please enter Your Message.
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 text-center">
                      {loading === false ? (
                        <button
                          type="submit"
                          className="btn btn-primary-orange m-auto  mt-3"
                        >
                          Submit
                        </button>
                      ) : (
                        <Button
                          className="btn  btn-primary-orange m-auto  mt-3 spinner"
                          variant="dark"
                          disabled
                        >
                          <Spinner
                            as="span"
                            animation="grow"
                            className="me-2"
                            role="status"
                            size="sm"
                            aria-hidden="true"
                          />
                          Please wait...
                        </Button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="tables-field">
                    <div className="project-table mb-0">

                        <div className="table-order-box shadow">
                            <div className="tab-head">
                                <small className=" form-text d-block text-center mb-4 text-muted">{formValidation.default}</small>
                                <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="text" maxLength="15" className="form-control" id="validationCustom01"  {...register('first_name', { required: true })} placeholder="First Name" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Customer First Name.
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="text" maxLength="15" className="form-control" id="validationCustomNew"  {...register('last_name', { required: true })} placeholder="Last Name" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Customer Last Name.
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6">

                                            <div className="form-group">
                                                <input type="email" maxLength="100" className="form-control" name="email" id="validationCustomNew1"  {...register('email', { required: true })} placeholder="Email" required />

                                                <small className='text-danger'> {errors.email?.message}</small>
                                                <div className="invalid-feedback">
                                                    Please Enter Valid Email Address.
                                                </div>
                                                <small className="form-text text-muted">{emailErr}</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <Input
                                                    minLength={14}
                                                    maxLength={14}
                                                    name="phone"
                                                    defaultCountry="US"
                                                    placeholder="Enter phone number"
                                                    {...register('phone', { required: true })}
                                                    value={value}
                                                    onChange={setValue} />


                                                <small className='font-12 d-block'>E.g (xxx) xxx-xxxx , No Need for Country Code</small>
                                                <small className="form-text text-muted">{mobileErr}</small>
                                                <div className="invalid-feedback">
                                                    Please enter mobile number.
                                                </div>

                                            </div>
                                        </div>




                                        <div className="col-lg-6">
                                            <div className="form-group icon-position">
                                                <input type={visibility ? "password" : "text"} maxLength="18" className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="password" name="password" minLength="6" {...register('password', { required: true }
                                                )} placeholder="password" required />
                                                {
                                                    visibility ?
                                                        <span className="icon" onClick={() => pwVisibility(false)}>
                                                           <FaEyeSlash />
                                                        </span> :
                                                        <span className="icon" onClick={() => pwVisibility(true)}>
                                                             <FaEye />
                                                        </span>
                                                }
                                                <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>

                                                <small className='text-danger'> {errors.password?.message}</small>

                                                <div className="invalid-feedback">
                                                    Please enter valid password.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group icon-position password-field">
                                                <input type={visibilityConfirm ? "password" : "text"} maxLength="18" name="confirm_password" minLength="6" className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`} id="validationCustom05" {...register('confirm_password', { required: true })} placeholder="Confirm password" required />
                                                {
                                                    visibilityConfirm ?
                                                        <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                            <FaEyeSlash />
                                                        </span> :
                                                        <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                            <FaEye />
                                                        </span>
                                                }
                                                <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>
                                                <small className='text-danger'> {errors.confirm_password?.message}</small>




                                                <div className="invalid-feedback">
                                                    Please enter valid confirm password.
                                                </div>
                                                <small className=" form-text text-muted">{passwordErr}</small>
                                            </div>
                                        </div>

                                        <small className='font-12 d-block mb-2'>By joining the MACH 2 Manufacturing Network, you agree to our Partner Terms and Conditions, Privacy Policy, and Non-Disclosure Agreement:</small>
                                        {['checkbox'].map((type) => (
                                            <div key={`default-${type}`} className="mb-3">
                                                <Form.Check
                                                    checked={checkedValue}
                                                    onClick={() => setCheckedValue(!checkedValue)}
                                                    {...register('send_welcome_email', { required: false })}
                                                    type={type}
                                                    id={`default-${type}`}
                                                    label={<span>I reviewed and agree to Mach 2's <a href="#">Terms of Service</a> and  <a href="#">Privacy Policy</a></span>}

                                                />


                                            </div>
                                        ))}
                                        <div className="col-lg-12">
                                            <div className="theme-btn btn-group-right text-right">
                                                {

                                                    loading === false ?
                                                        <button type="submit" className="btn">Create An Account</button> :
                                                        <Button className='btn  spinner' variant='dark' disabled>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"

                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            Adding...
                                                        </Button>
                                                }


                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div> */}
      </Modal>
    </React.Fragment>
  );
};
export default NoFileQoute;
