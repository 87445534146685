import React, { useState, useEffect } from "react";
import {
  FaSearch,
  FaRegCalendarAlt,
  FaGavel,
  FaEye,
  FaFilePdf,
  FaCube,
} from "react-icons/fa";
import { getMethodDataSupplier } from "../../../../../admin/adminApi/api";
import { GiAutoRepair } from "react-icons/gi";
import { ImageObj } from "../../../../../../assets/images/imgeObject";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import Pagination from "react-js-pagination";
import Placebid from "../../../../common/placebid/Placebid";
import { Link } from "react-router-dom";
import fileDownload from 'react-file-download';


const BidsList = () => {
  const [tableListData, setTableListData] = useState([]);
  const [Count, setCount] = useState(1);
  const [perPage, setPerPage] = useState();
  const [loading, setLoading] = useState(false);
  const [filterVisibility, setFilterVisibility] = useState(true);
  const [indexPage, setIndexPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterData, setFilterData] = useState("");
  const [openDelete, setOpenDelete] = useState();
  const [Popupbidid, setPopupbidid] = useState();
  const [Popupbidamount, setPopupbidamount] = useState();
  const USERTOKEN = localStorage.getItem("userToken");
  const SUPPLIERTOKEN = localStorage.getItem("supplierToken");
  const { register, control, handleSubmit, reset } = useForm();
  const [bidstatus, setBidstatus] = useState("");



  const popUpBidId = (index) => {
    setPopupbidid(index);
    setOpenDelete(true);
  };
  const LoadTableData = async (data) => {
    setIndexPage(1);
    // setBidstatus(data)
    setLoading(false);
    getMethodDataSupplier(
      `/bids?page=${activePage}&status=${data || bidstatus}`,
      USERTOKEN || SUPPLIERTOKEN
    ).then((res) => {
      setTableListData(res.data.result.data.data);
      setCount(res.data.result.data.total);
      setPerPage(res.data.result.data.per_page);
      setLoading(true);
      setFilterVisibility(true);
    });
  };

  const resetTable = () => {
    reset();
    LoadTableData();
  };

  const sendPage = (pageNumber) => {
    getMethodDataSupplier(
      `/bids?page=${String(pageNumber < 1 ? 1 : pageNumber)}&${filterData}`,
      USERTOKEN || SUPPLIERTOKEN
    ).then((res) => {
      setTableListData(res.data.result.data.data);
      setCount(res.data.result.data.total);
      setPerPage(res.data.result.data.per_page);
      setLoading(true);
    });
  };

  const handlePageChange = (pageNumber) => {
    if (filterVisibility === true) {
      LoadTableData();
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    } else {
      sendPage(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    data.from_date =
      !data.from_date || data.from_date === null || data.from_date === undefined
        ? ""
        : moment(data.from_date).format("YYYY-MM-DD");
    data.to_date =
      !data.to_date || data.to_date === null || data.to_date === undefined
        ? ""
        : moment(data.to_date).format("YYYY-MM-DD");

    setIndexPage(1);
    setFilterData(data);

    setLoading(false);
    getMethodDataSupplier(
      `/bids?page=1&${new URLSearchParams(
        data
      ).toString()}&status=${bidstatus}`,
      USERTOKEN || SUPPLIERTOKEN
    ).then((res) => {
      setTableListData(res.data.result.data.data);
      setCount(res.data.result.data.total);
      setLoading(true);
      setFilterVisibility(false);
    });
  };

  useEffect(() => {
    LoadTableData();
  }, [bidstatus]);


  const handleDownload = (data,fileurl) => {
    const fileUrl = data;
    const fileName = fileurl;
    fileDownload(fileUrl, fileName);
  };

  return (
    <React.Fragment>
      <section className="pt-5 mt-3">
        <div className="container dashboard-heading-block py-3">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h2>Bids</h2>
        
            </div>
            <div className="col-lg-12 dashboard-headline text-start max-width-500">
              <Tabs
                defaultActiveKey={bidstatus}
                onSelect={(e) => {
                  LoadTableData(e);
                  setBidstatus(e);
                }}
                id="justify-tab-example"
                className="mb-3"
                justify
              >
                <Tab eventKey="" title="All"></Tab>
                <Tab eventKey="Upcoming" title="Upcoming"></Tab>
                <Tab eventKey="Open" title="Open"></Tab>
                <Tab eventKey="Awarded" title="My Won"></Tab>
                <Tab eventKey="Closed" title="Closed"></Tab>
                <Tab eventKey="Expired" title="Expired"></Tab>
              </Tabs>
              <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="rounded rounded-pill mb-3 border border-dark search-style1 ">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <button id="button-addon2" className="btn btn-link">
                            <i>
                              <FaSearch />
                            </i>
                          </button>
                        </div>
                        <input
                          type="search"
                          {...register("bid_id", { required: false })}
                          name="bid_id"
                          placeholder="Bid ID"
                          aria-describedby="button-addon2"
                          className="form-control border-0 ps-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="rounded rounded-pill mb-3 border border-dark search-style1 form-search-new">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <i>
                            <FaRegCalendarAlt />
                          </i>
                        </div>
                        <div className="form-group before-con">
                          <Controller
                            control={control}
                            name="from_date"
                            render={({ field }) => (
                              <DatePicker
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => field.onChange(date)}
                                selected={field.value}
                                placeholderText="From Date"
                                maxDate={new Date()}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="rounded rounded-pill mb-3 border border-dark search-style1 form-search-new">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <i>
                            <FaRegCalendarAlt />
                          </i>
                        </div>
                        <div className="form-group before-con after">
                          <Controller
                            control={control}
                            name="to_date"
                            render={({ field }) => (
                              <DatePicker
                                dateFormat="MM/dd/yyyy"
                                placeholderText="To Date"
                                onChange={(date) => field.onChange(date)}
                                selected={field.value}
                                maxDate={new Date()}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <button
                      className="btn btn-primary-orange m-0 min-hp-40"
                      type="submit"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={() => resetTable()}
                      className="btn btn-primary-outline ms-2  min-hp-40"
                    >
                      reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5 pt-3">
        <div className="container gx-0 grid-data position-relative">
          <div className="row gx-0 grid-head-row">
            <div className="col">
              <span className="data-value">Sr. No.</span>
            </div>
            {/* <div className="col">
              <span className="data-value">Image</span>
            </div> */}
            <div className="col-8" style={{ padding: " 0px 13px" }}>
              <div className="row" style={{ flexWrap: "nowrap" }}>
                <div className="col text-center">
                  <span className="data-value">Bid ID</span>
                </div>
                <div className="col">
                  <span className="data-value">Name</span>
                </div>
                <div className="col">
                  <span className="data-value">Qty</span>
                </div>

                <div className="col">
                  <span className="data-value">Bid Offer</span>
                </div>
                <div className="col">
                  <span className="data-value">Min Bid</span>
                </div>
                <div className="col">
                  <span className="data-value">Start On</span>
                </div>
                <div className="col">
                  <span className="data-value">Expiring On</span>
                </div>
                <div className="col">
                  <span className="data-value">Status</span>
                </div>
              </div>
            </div>
            <div className="col">
              <span className="data-value">Action</span>
            </div>
          </div>
          {tableListData !== null && tableListData !== undefined ? (
            tableListData
              .filter((val) => {
                if (searchTerm === "") {
                  return val;
                } else if (
                  val.name.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return val;
                } else {
                  return false;
                }
              })
              .map((res, i) => {
                return (
                  <div className="row gx-0 grid-data-row">
                    <div className="col" data-label="Sr. No.: ">
                      <span className="data-value">{i + 1}</span>
                    </div>
                    {/* <div className="col" data-label="Image: ">
                      <img className="w-100" src={ImageObj.ProductBig} />
                    </div> */}
                    <div className="col-8" style={{ padding: " 0px 13px" }}>
                      <div className="row" style={{ flexWrap: "nowrap" }}>
                        <div className="col" data-label="ID: ">
                          <span className="data-value">#{res.bid_id}</span>
                        </div>
                        <div className="col" data-label="customer name: ">
                          <span className="data-value">
                            {res.order.customer?.first_name}{" "}
                            {res.order.customer?.last_name}
                          </span>
                        </div>
                        <div className="col" data-label="Name: ">
                          <span className="data-value">
                            {res.bid_part?.qty}
                          </span>
                        </div>
                        <div className="col" data-label="Amount: ">
                          <span className="data-value">
                            ${res.offer_amount}
                          </span>
                        </div>
                        <div className="col" data-label="Amount: ">
                          <span className="data-value">
                          {res?.bid_min_proposal_amount != 0
                            ?
                            <>
                              ${res?.bid_min_proposal_amount}
                            </>
                            :
                            "N/A"
                            }
                          </span>
                        </div>
                        <div className="col" data-label="Date: ">
                          <span className="data-value">
                            {moment(res.open_date_time).format("MM/DD/yyyy")}
                            <br />
                            {moment(res.open_date_time).format("hh:mm a")}
                          </span>
                        </div>
                        <div className="col" data-label="Status: ">
                          <span className="data-value">
                            {moment(res.expiry_date_time).format("MM/DD/yyyy")}
                            <br />
                            {moment(res.expiry_date_time).format("hh:mm a")}
                          </span>
                        </div>
                        <div className="col" data-label="Status: ">
                          <span className="data-value">
                            {res?.supplier_bid_response?.status}
                          </span>
                        </div>
                      </div>
                      <div
                        className="col-12 flex px-3 py-4 grey-box"
                        data-label="Details:"
                      >
                        <div className="col-12">
                          <h6>Details</h6>
                        </div>
                        <span>
                          {res.bid_part?.manufacturing_process && (
                            <span className="data-value">
                              
                              <strong className="me-1">
                                Manufacturing Process
                              </strong>
                              :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.manufacturing_process}
                              </span>
                            </span>
                          )}

                        {res.bid_part?.custom_manufacturing_process && (
                            <span className="data-value">
                              
                              <strong className="me-1">
                                Custom Manufacturing Process
                              </strong>
                              :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.custom_manufacturing_process}
                              </span>
                            </span>
                          )}

                          {res.bid_part?.material && (
                            <span className="data-value">
                              
                              <strong className="me-1">Material </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.material}
                              </span>
                            </span>
                          )}
                          {res.bid_part?.custom_material && (
                            <span className="data-value">
                              
                              <strong className="me-1">Custom Material Value </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.custom_material}
                              </span>
                            </span>
                          )}
                          {res?.bid_part?.finish && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">Coating </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.finish}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.custom_finish && (
                            <li className="d-flex align-items-center">
                              <strong className="mb-0">Custom Coating </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.custom_finish}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.threads && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">Threads </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.threads}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.tolerance && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">Tolerance </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.tolerance}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.surface_roughness && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">
                                Surface Roughness
                              </strong>
                              :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.surface_roughness}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.part_marking && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">Part Marking </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.part_marking}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.inspection && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">Inspection </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.inspection}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.custom_inspection && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">Custom Inspection Value </strong> :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.custom_inspection}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.part_qualifications_certifications && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">
                                Part Quality Certifications
                              </strong>
                              :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.part_qualifications_certifications}
                              </span>
                            </li>
                          )}
                          {res.bid_part?.material_certifications && (
                            <li className="d-flex align-items-center">
                              <strong className="me-1">
                                Material Certifications
                              </strong>
                              :
                              <span className="mb-0 ms-1 me-3 new">
                                
                                {res.bid_part?.material_certifications}
                              </span>
                            </li>
                          )}
                        </span>
                      </div>
                    </div>

                    <div
                      className="col d-flex align-items-center justify-content-center action"
                      data-label="Action"
                    >
                      <a
                        // target={'_blank'}
                        // onClick={()=> handleDownload(res?.bid_part?.auto_cad_file_path,res?.bid_part?.file_name)}
                        // rel="noreferrer"
                        download
                        title="Download 3d File"
                        href={res?.bid_part?.auto_cad_file_path}
                        >
                        <FaCube className="text-primary fs-5 fw-normal ms-2 pointer" title="Download 3D File" />
                      </a>
                      {res.status === "Open" ? (
                        <Link
                          className="text-dark"
                          to={`bidapply${res.id}`}
                          // alt="Place a Bid"
                          title="Place a Bid"
                          // title={res.supplier_bid_response?.proposal_amount === 0 ? "Place a Bid" : (res.supplier_bid_response?.proposal_amount > 0  && res.supplier_bid_response?.proposal_amount < res?.bid_min_proposal_amount) ? "You are the current leader." : "Bid Again To Win."}
                        >
                          <FaGavel className="fs-5 fw-normal ms-2 pointer" />
                        </Link>
                      ) : (
                        ""
                      )}
                      {res.status !== "Open" 
                      && 
                      <Link
                      className="text-dark"
                      to={`bidapply${res.id}`}
                      alt="Bid Details"
                      title="Bid Details"
                    >
                      <FaEye className="fs-5 fw-normal ms-2 pointer" />
                    </Link>
                    }
                      
                      {res?.bid_pdf_path ? (
                        <a
                          target={"_blank"}
                          title="Download pdf"
                          href={`${res?.bid_pdf_path}`}
                          className="icons-action fs-5 fw-normal ms-2 pointer"
                        >
                          <FaFilePdf />
                        </a>
                      ) : (
                        // <Link
                        //   title="Download pdf"
                        //   to=""
                        //   className="icons-action fs-5 fw-normal ms-2 pointer"
                        // >
                        //   <FaFilePdf className="text-secondary" />
                        // </Link>
                        null
                      )}
                     
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="no-data py-4 fs-1 fw-bolder text-center text-uppercase opacity-25 text-dark">
              No Bids Found
            </div>
          )}
          {!loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="container pe-0 ps-0">
          <div className="pagination">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={perPage}
              totalItemsCount={Number(Count) ?? ""}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default BidsList;
