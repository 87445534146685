import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useForm} from "react-hook-form";
import { Button, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormValidation from "../../../../formValidation/formValidation";
import { getMethodData, postMethodData } from "../../adminApi/api";
import { useNavigate} from "react-router-dom";

const RoleMangementEdit = () => {

  let  showDataToUser =false; 
  const [redirection,setRedirection] = useState(false);
  useLayoutEffect(() => {
      const ls=JSON.parse(localStorage.getItem("adminDetails"));

    // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
     
      for(let i=0; i<=ls?.my_permissions?.length;i++){
          if(ls?.my_permissions[i]?.name==="Role Management"){
              
              for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                  if(ls?.my_permissions[i]?.permissions[j]?.name==="Edit"){
                      // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      showDataToUser = true;
                      i=ls?.my_permissions?.length;
                      break;
                  }
              }
          }
      }
      // console.log(showDataToUser);
      if(showDataToUser===false){
        // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
  }, []);
  const navigate = useNavigate();
  if (redirection) navigate('/admin/role-mangement');
  // form validation rules
  const validationSchema = Yup.object().shape({
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, control, formState,watch } =
    useForm(formOptions);

  // const dispatch = useDispatch()
  // const PORT = PORTNUMBER.PORTNUMBER
  const { id } = useParams();
  
  const Token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  const FormValidationNew = FormValidation;

  

  const [formValidation, setFormValidation] = useState({
    Email: "",
    Mobile: "",
    default: "",
    Phone: "",
    setPassErrFormat: "",
    Success: null,
  });

  const [editData, setEditData] = useState([]);

  

  const [realdata,setRealdata] = useState([])
  // console.log(realdata)


  // const updateState = (id,role) => {
  //   let newrole = []
  //   role?.map((roleitem,index) => {
  //     newrole.push(roleitem.id)
  //   })
  //   setRealdata(
  //     current => [
  //       ...current, { 
  //         "module_id":id,
  //         "permissions":newrole
  //       }
  //     ]
  //   )
  // }
  var result = [];
  let mindata = []

  const editfunctionmodule = (checked,id,role) => {
    let found = false;
    if(checked){
        // let mindata = []
        // for (let i = 0; i < realdata.length; i++) {
        //     mindata.push(realdata[i].permissions[i].id);
        //     found = true;
        //     break;
        // }

        mindata = []
        editData?.permissions?.forEach(permission => {
          permission.permissions.forEach(subPermission => {
            if(subPermission.module_id == id){
              mindata.push(subPermission.id);
            }
          })
        })

        setRealdata(
          current => [
            ...current, { 
              "module_id":id,
              "permissions":mindata
            }
          ]
        )

        if(role==="module_id"){
          let checkboxes = document.getElementsByName(id);
          checkboxes.forEach(item=>item.checked=true)
          // value true
          if(checked===true){
           if(checkboxes.length >= 1 ){
           checkboxes.forEach(item=>item.checked=true)
          }
          }else{
           if(checkboxes.length >= 1 ){
           checkboxes.forEach(item=>item.checked=false)
          }
          }
        }
        
        
    }
    else if(!checked){
      for (let i = 0; i < realdata.length; i++) {
        if (realdata[i].module_id == id) {
          const index = realdata.indexOf(realdata[i]);
          if (index > -1) {
          realdata.splice(index, 1);
          found = true;
          break;
        }
        }
      }
      if(role==="module_id"){
        let checkboxes = document.getElementsByName(id);
        checkboxes.forEach(item=>item.checked=false)
        // value true
        // if(checked===true){
        //  if(checkboxes.length >= 1 ){
        //  checkboxes.forEach(item=>item.checked=true)
        // }
        // }else{
        //  if(checkboxes.length >= 1 ){
        //  checkboxes.forEach(item=>item.checked=false)
        // }
        // }
      }
    }
    // console.log(realdata)
  }

  const editfunctionpermissions = (checked, moduleid,id,role) => {
    let found = false;
    if(checked){
      for (let i = 0; i < realdata.length; i++) {
        if (realdata[i].module_id == moduleid) {
          realdata[i].permissions.push(id);
          found = true;
          break;
        }
      }
    
      if (!found) {
        realdata.push({module_id: moduleid, permissions: [id]});
      }

      if(role==="permission"){
        if(document.getElementsByName("module_name_"+moduleid)[0].checked ===  false){
          document.getElementsByName("module_name_"+moduleid)[0].checked=true
        }
      }
    }
    else if(!checked){
      for (let i = 0; i < realdata.length; i++) {
        if (realdata[i].module_id == moduleid) {
          const index = realdata[i].permissions.indexOf(id);
          if (index > -1) {
          realdata[i].permissions.splice(index, 1);
          found = true;
          if(realdata[i].permissions.length === 0){
            document.getElementsByName("module_name_"+moduleid)[0].checked=false
            if (realdata[i].module_id == moduleid) {
              const index = realdata.indexOf(realdata[i]);
              if (index > -1) {
              realdata.splice(index, 1);
              found = true;
              break;
            }
            }
          }
          break;
        }
        }
      }
    
      if (!found) {
        realdata.pop({module_id: moduleid, permissions: [id]});
      }
    }
    
    // console.log(realdata)
  }
  
  const permision = () => {
    getMethodData(`/roles/edit/${id}`, Token).then((res) => {
      setEditData(res?.data?.result);
      // // console.log(editData.permissions)
      const myobject=[];
      editData?.permissions?.filter(item=> item.selected===1?myobject.push(item):null)  
      // // console.log(myobject);
        result = [];
        res?.data?.result.permissions.forEach(permission => {
          // if (permission.selected === 1) {
          //   result.push({ module_id: permission.id, permissions: [permission.id] });
          // } else {
            if ('permissions' in permission && permission.selected === 1) {
              permission.permissions.forEach(subPermission => {
                if (subPermission.selected === 1) {
                  let found = false;
                  result.forEach(r => {
                    if (r.module_id === subPermission.module_id) {
                      r.permissions.push(subPermission.id);
                      found = true;
                    }
                  });
                  if (!found) {
                    result.push({ module_id: subPermission.module_id, permissions: [subPermission.id] });
                  }
                }
              });
            }
          // }
        });
        setRealdata(result)
      // console.log("====>>>>",result)
    })
  };

  useEffect(() => {
    permision();
  }, []); 

  


  const onSubmit = () => {
    // // console.log(data);

    if(realdata.length===0){
      toast.warning("Please Select Permission")
    }
    else{
      setLoading(true);
    
    postMethodData(`/asign/role-permissions`,{
      "role_id":id,
      "permissions":JSON.stringify(realdata)
    }, Token)
      .then((res) => {
        if(res.data.status === true){
          setLoading(false);
          toast.success(res.data.message)
          navigate("/admin/role-mangement")
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.success(error.data.message)
        // console.log(error);
      });
    }
  };


  // select all checkbox
  function Toggle(event_value, module_id, permission_id, event_type) {
  if(event_type==="module"){
    let checkboxes = document.getElementsByName(module_id);
    // value true
    if(event_value===true){
     if(checkboxes.length >= 1 ){
     checkboxes.forEach(item=>item.checked=true)
    }
    }else{
     if(checkboxes.length >= 1 ){
     checkboxes.forEach(item=>item.checked=false)
    }
    }
  }
 
  if(event_type==="permission"){
   // console.log('permissions id',permission_id)
   // console.log("event_value",event_value)
   // console.log("module_id",module_id)
   // console.log(" event_type", event_type)
  if(event_value===true){
     if(document.getElementsByName("module_name_"+module_id)[0].checked ===  false){
        document.getElementsByName("module_name_"+module_id)[0].checked=true
      }
    }else{
       // console.log("create unselect event")
       let checkboxes = document.getElementsByName(module_id);
       let module_permission_checked = false;
        for(let i= 1; i <= checkboxes.length; i++){
        if(checkboxes[i]?.checked === false){
          module_permission_checked = false;
        }
        if(checkboxes[i]?.checked === true){
            module_permission_checked = true
          }
        }
       if(module_permission_checked === false){
        document.getElementsByName("module_name_"+module_id)[0].checked = false;
        }
        if(module_permission_checked === true){
         document.getElementsByName("module_name_"+module_id)[0].checked = true;
        }
    }
   }
  }

  
  
  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="main-head">
                <h4>Edit {editData?.role_data?.name} Management </h4>
              </div>
            </div>
            <div className="col-6">
              <div className="theme-btn text-end top-head">
                <Link to="/admin/role-mangement" className="btn">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="table-order-box shadow" style={{ minHeight: "80px" }}>
          <div className="tab-head">
            <small className=" form-text d-block text-center mb-4 text-muted">
              {formValidation.default}
            </small>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="needs-validation"
            >
              <div className="row">
                
                {editData?.permissions?.map((item,index) => (
                  <div className="table-order shadow p-0 mt-3" key={index}>
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={item.permissions.length}>
                            <input
                            id={item.id}
                             type="checkbox"
                             className="me-3"
                             name={`module_name_${item.id}`}
                            //  onClick={(e) => {Toggle(e.target.checked,item.id,0,"module");}}
                             onClick={(e) => {editfunctionmodule(e.target.checked,item.id,"module_id");
                              // Toggle(e,item?.id,item?.permissions)
                            }}
                             defaultValue={item?.id}
                            //  {...register(item?.id, { required: false })}
                             disabled={item?.permissions.length===0 ? true: false }
                             defaultChecked={item?.selected === 1 ? true : false }
                            />
                            {item.name}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {dummy=0} */}
                        <tr>
                          {item?.permissions?.map((permissionItem,index) => (
                            <>
                              <td className="bg-transparent" key={index}>
                                  <>
                                   <label htmlFor={permissionItem.id} style={{whiteSpace:"nowrap"}}>
                                    <input
                                    disabled={true}
                                    type="checkbox"
                                    className="me-2"
                                    name={permissionItem?.module_id}
                                    id={permissionItem.id}
                                    defaultValue={permissionItem?.id}
                                    // {...register(`permissions.${permissionItem?.id}`, { required: true })}
                                    // event_value, module_id, permission_id, event_type
                                    onChange={(e) => {editfunctionpermissions(e.target.checked,permissionItem.module_id,permissionItem.id,"permission");
                                        // Toggle(e.target.checked,permissionItem.module_id,permissionItem.id,"permission");
                                        }
                                      }
                                    defaultChecked={permissionItem?.selected === 1 ? true : false }
                                    />
                                    {permissionItem?.name}
                                  </label>
                                  </>
                                </td>
                            </>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
                 
                
                <div className="col-lg-12 mt-5">
                  <div className="theme-btn btn-group-right text-end">
                    {loading === false ? (
                      <button type="submit" className="btn">
                        Save
                      </button>
                    ) : (
                      <Button className="btn spinner" variant="dark" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                        />
                        Please Wait...
                      </Button>
                    )}

                    <Link to="/admin/role-mangement" className="btn">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};
export default RoleMangementEdit;
