/* eslint-disable react/jsx-pascal-case */

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import Header from "../../components/admin/common/header/header";
import SideNav from "../../components/admin/common/sidenav/sidenav";
import Dashboard from "../../components/admin/pages/dashboard/dashboard";
import Login from "../../components/admin/pages/auth/login/login";
import CustomerManagement from "../../components/admin/pages/customerManagement/customerManagement";
import AddNewCustomer from "../../components/admin/pages/customerManagement/addNewCustomer";
import EditCustomer from "../../components/admin/pages/customerManagement/editCustomer";
import AddNewSupplier from "../../components/admin/pages/supplierManagement/addNewSupplier";
import AddNewcoupen from "../../components/admin/pages/Coupen_code/AddnewCoupen";
import SupplierManagement from "../../components/admin/pages/supplierManagement/supplierManagement";
import EditSupplier from "../../components/admin/pages/supplierManagement/editSupplier";
import EditCoupen from "../../components/admin/pages/Coupen_code/Coupen_edit";
import QuoteManagement from "../../components/admin/pages/quoteManagement/quoteManagement";
import ResetMail from "../../components/admin/pages/auth/resetMail/resetMail";
import ResetPassword from "../../components/admin/pages/auth/resetPassword/resetPassword";
import ResetPasswordUser from "../../components/user/components/pages/auth/resetPassword/resetPassword";
import AdminOrder from "../../components/admin/pages/Admin_Order/AdminOrder";
import Coupen_mangement from "../../components/admin/pages/Coupen_code/CoupenCodes";
import ResetPasswordLink from "../../components/admin/pages/auth/resetPwLink/resetPasswordLink";
import Home from "../../components/user/components/pages/home/home";
import OrderManagement from "../../components/admin/pages/orderManagement/orderManagement";
import InitOrder from "../../components/admin/pages/init_order/InitOrder";
import SupplierOrder from "../../components/admin/pages/Supplier___Orders/SupplierOrder";
import NotificationBell from "../../components/admin/pages/Notification/NotificationBell";
import MetalPriceManage from "../../components/admin/pages/metalPriceManagement/metalPriceManage";
import AdminSettings from "../../components/admin/pages/setting/adminSettings";
import TermAndServices from "../../components/user/common/termAndService/termAndService";
import PrivacyPolicy from "../../components/user/common/privacyPolicy/privacyPolicy";
import EditOrderManagement from "../../components/admin/pages/orderManagement/editorderManagement";
import Payment from "../../components/admin/pages/payments/Payments";
import SupplierTransaction from "../../components/admin/pages/supplierTransaction/supplier_Trans";
import BidManagement from "../../components/admin/pages/bidManagement/bidManagement";
import BidDetails from "../../components/admin/pages/bidManagement/bidDetails";
import AttentionOrder from "../../components/admin/pages/attentionOrder/AttentionOrder";
import CmsHome from "../../components/admin/pages/cms/cms";
import AddNewCms from "../../components/admin/pages/cms/AddNewCms";
import EditCms from "../../components/admin/pages/cms/CmsEdit";
import RoleMangement from "../../components/admin/pages/roleManagement/roleMangement";
import RoleMangementEdit from "../../components/admin/pages/roleManagement/roleMangementEdit";
import SubAdmin from "../../components/admin/pages/subAdmin/SubAdmin";
import EditSubAdmin from "../../components/admin/pages/subAdmin/editSubAdmin";
import AddSubAdmin from "../../components/admin/pages/subAdmin/addSubAdmin";

const AdminRoutes = ({ isAuthedAdmin, setauth }) => {
  // useEffect(() => {
  //     const Auth2 = localStorage.getItem("token");

  //     if ( Auth2 !== null) {
  //         setauth(false)
  //         document.body.classList.add('admin-part');
  //     } else {
  //         setauth(true)

  //     }
  // }, [])
  return (
    <>
      <div className="parent">
        <Routes>
          <Route path="/admin" element={<SideNav />}>
            <Route index element={<Login isAuthedAdmin={isAuthedAdmin} />} />
            <Route path="resetmail" element={<ResetMail />} />
            <Route
              path="/admin/reset-passwordlink/:id"
              element={<ResetPassword />}
            />
            <Route path="sent-link-mail" element={<ResetPasswordLink />} />

            <Route path="dashboard" element={<Dashboard />} />
            <Route
              path="customer-management"
              element={<CustomerManagement />}
            />
            <Route path="payment-management" element={<Payment />} />
            <Route
              path="supplier-transaction"
              element={<SupplierTransaction />}
            />
            <Route
              path="customer-management/:id"
              element={<AddNewCustomer />}
            />
            <Route
              path="customer-management/edit/:id"
              element={<EditCustomer />}
            />
            <Route
              path="supplier-management"
              element={<SupplierManagement />}
            />
            <Route path="order-management" element={<OrderManagement />} />
            <Route path="order-management/:id" element={<EditOrderManagement />}/>
            <Route path="supplier-order/:id" element={
            <SupplierOrder
              idProps={true}
              filterTrue={true}
              searchId={"supplier_id"}
              filterApi={"/supplier-orders"} />
            } />
            <Route path="notification-list" element={<NotificationBell />} />
            <Route path="init-order" element={<InitOrder />} />
            <Route path="attention-order" element={<AttentionOrder />} />
            <Route path="admin-order" element={<AdminOrder />} />
            

            <Route
              path="supplier-management/:id"
              element={<AddNewSupplier />}
            />
              <Route
              path="coupen-management/:id"
              element={<AddNewcoupen />}
            />
            <Route
              path="supplier-management/edit/:id"
              element={<EditSupplier />}
            />
              <Route
              path="coupen-management/edit/:id"
              element={<EditCoupen />}
            />
             <Route
              path="role-mangement/edit/:id"
              element={<RoleMangementEdit />}
            />
            
            {/*  */}
            <Route path="cms"  element={<CmsHome/>}/>
           
            <Route path="cms/add-new-cms" element={<AddNewCms />} />
            <Route path="cms/edit/:id" element={<EditCms />} />
           
           
            {/*  */}
            <Route path="quote-management" element={<QuoteManagement />} />
            <Route
              path="quote-management/filterQuote/:id"
              element={
                <QuoteManagement
                  idProps={true}
                  filterTrue={true}
                  searchId={"customer_id"}
                  filterApi={"/quotes"}
                />
              }
            />
            <Route
              path="order-management/filterOrder/:id"
              element={
                <OrderManagement
                  idProps={true}
                  filterTrue={true}
                  searchId={"customer_id"}
                  filterApi={"/customer-orders"}
                />
              }
            />
            {/* <Route
              path="order-management/filterSupplier/:id"
              element={
                <OrderManagement
                  idProps={true}
                  filterTrue={true}
                  searchId={"supplier_id"}
                  filterApi={"/supplier-orders"}
                />
              }
            /> */}
          
            <Route path="bid-management" element={<BidManagement />} />
            <Route path="bid-management/:id" element={<BidDetails />} />

            <Route
              path="metal-price-management"
              element={<MetalPriceManage />}
            />
               <Route
              path="coupen-management"
              element={<Coupen_mangement/>}
            />
            <Route path="admin-settings" element={<AdminSettings />} />
            
            <Route path="role-mangement" element={<RoleMangement />} />
            <Route path="sub-admin" element={<SubAdmin />} />
            <Route path="sub-admin/add-new-subadmin" element={<AddSubAdmin />} />
            <Route path="sub-admin/edit/:id" element={<EditSubAdmin />} />
            
          </Route>
          <Route path="/resetpassword/:id" element={<Home show={true} />} />
          <Route path="/verifylink/:id" element={<Home showMessage={true} />} />
          <Route path="/conditions" element={<TermAndServices />} />
          <Route path="/policy" element={<PrivacyPolicy />} />

          {/* <Route path="/:id" element={<Home />} /> */}

          <Route path="*" element={<Navigate to="/admin/dashboard" />} />
          {/* <Route path="/" element={<Home />} /> */}
        </Routes>
      </div>
    </>
  );
};

export default AdminRoutes;
