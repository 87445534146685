import React, { useState, useEffect, useRef } from 'react'
import { ImageObj } from '../../../../../../assets/images/imgeObject'
import { postMethodDataSupplier,getMethodDataSupplier } from '../../../../../admin/adminApi/api';
import moment from 'moment'
import { toast } from 'react-toastify'
import { GiAutoRepair } from 'react-icons/gi';
import { Link,useNavigate,useParams } from 'react-router-dom'
import { FaClock, FaCube, FaRegFilePdf } from 'react-icons/fa';
import fileDownload from 'react-file-download';

let USERTOKEN = localStorage.getItem("userToken");
let SUPPLIERTOKEN = localStorage.getItem("supplierToken");
const Bidapply = () => {

    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [myparts, setMyparts] = useState([])
    const [bididdata, setbididdata] = useState('')
    const [bidAmount, setbidAmount] = useState('')
    const [expirytime,setexpirytime] = useState()
    const [opentime,setopentime] = useState()
    const [servertime,setservertime] = useState()
    const [currentTime, setCurrentTime] = useState();


    const navigate = useNavigate()

    const postBiddetails = (data) => {
        if(bididdata?.offer_amount < bidAmount){
            toast.warn('Please Enter Less than or equal to Bid Amount')
        }   
        else if(bidAmount < 1){
            toast.warn('Please Enter Amount Greater than 0')
        }
        else{
            setLoading(false);
            postMethodDataSupplier(`/bids/send-proposal`,{
                bid_id:bididdata?.id,
                proposal_amount:bidAmount,
            },
            SUPPLIERTOKEN || USERTOKEN ).then((res) => {
                setLoading(true);
                toast.success(res.data.message)
                navigate('../bids')
            })
        }
    }

    const getPartdetails = () => {
        setLoading(false);
        getMethodDataSupplier(`/bid-details/${id}`,
        SUPPLIERTOKEN || USERTOKEN ).then((res) => {
            // setLoading(true)
            setLoading(true);
            setMyparts(res.data.result.data.order.order_product)
            setbididdata(res.data.result.data)
            setexpirytime(res?.data?.result?.data?.expiry_date_time)
            setservertime(res?.data?.result?.data?.server_time)
            setCurrentTime(res?.data?.result?.data?.server_time)
            setopentime(res?.data?.result?.data?.open_date_time)
        })
    }

    useEffect(() => {
        USERTOKEN = localStorage.getItem("userToken");
        SUPPLIERTOKEN = localStorage.getItem("supplierToken");
        getPartdetails()
    }, [])

    const handleDownload = (data) => {
        const fileUrl = data;
        const fileName = data;
        fileDownload(fileUrl, fileName);
      };


      

      const [timerDay, setTimerDay] = useState("00");
      const [timerHour, setTimerHour] = useState("00");
      const [timerMinute, setTimerMinute] = useState("00");
      const [timerSecond, setTimerSecond] = useState("00");
    
      let interval = useRef();
    
      const startTimer = (expire,server) => {
        const countDowndate = new Date(expire).getTime();
    
        interval.current = setInterval(() => {
          // const now = new Date(servertime).getTime();
          let distance = countDowndate - new Date(server).getTime();
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + (days * 24));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
          if (distance < 0) {
            clearInterval(interval.current);
          } else {
            setTimerDay(days.toLocaleString().length <= 1 ? "0" + days : days);
            setTimerHour(hours.toLocaleString().length <= 1 ? "0" + hours : hours);
            setTimerMinute(minutes.toLocaleString().length <= 1 ? "0" + minutes : minutes);
            setTimerSecond(seconds.toLocaleString().length <= 1 ? "0" + seconds : seconds);
            setCurrentTime(new Date(currentTime).getTime() + 1000); 
          }
        }, 1000);
      };
    
      useEffect(() => {
        if(bididdata?.status === "Open"){
          startTimer(expirytime,currentTime);
          console.log("Open")
        }
        if(bididdata?.status === "Upcoming"){
          startTimer(opentime,currentTime);
          console.log("Upcoming")
        }
        return () => {
          clearInterval(interval.current);
        };
        // return () => clearInterval(intervalId);
      },[expirytime,currentTime,opentime]);



    return (
        <React.Fragment>
            <section className="pt-5 mt-3">
                <div className="container dashboard-heading-block py-3">
                <Link className='text-decoration-none text-dark mb-sm-4 mb-3 d-block' to="../bids">{"< Back"}</Link>
                    <div className="row align-items-center">
                        <div className="col-lg-6 dashboard-headline text-start">
                            <h2>{bididdata?.supplier_bid_response?.status} Bid - #{bididdata.bid_id}</h2>
                        </div>
                        <div className="col-lg-6 dashboard-headline d-flex justify-content-between">
                            <div>
                            <p>
                                <strong>Start Date:</strong> 
                                {moment(bididdata.open_date_time).format("MM/DD/yyyy")}
                            </p>
                            <p>
                                <strong>Start Time:</strong> 
                                {moment(bididdata.open_date_time).format("hh:mm a")}
                            </p>
                            </div>
                            <div className='text-end'>
                                <p>
                                    <strong>End Date:</strong> 
                                    {moment(bididdata.expiry_date_time).format("MM/DD/yyyy")}
                                </p>
                                <p>
                                    <strong>End Time:</strong> 
                                    {moment(bididdata.expiry_date_time).format("hh:mm a")}
                                    {/* {timerMinute}:{timerSecond} */}
                                </p>
                                {bididdata?.supplier_bid_response?.status === "Open" 
                                && 
                                <p className='text-danger'>
                                    <strong>Ending In:</strong> 
                                    {/* {moment(bididdata.expiry_date_time).format("HH:mm")} */}
                                    {timerHour || "00"}:{timerMinute || "00"}:{timerSecond || "00"} <FaClock/>
                                </p> 
                                }
                                {bididdata?.supplier_bid_response?.status === "Upcoming" 
                                && 
                                <p className=''>
                                    <strong>Opening In:</strong> 
                                    {/* {moment(bididdata.expiry_date_time).format("HH:mm")} */}
                                    {timerHour || "00"}:{timerMinute || "00"}:{timerSecond || "00"} <FaClock/>
                                </p> 
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-4 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 position-relative">
                            {
                                myparts && myparts !== undefined ? myparts.map((res) => {
                                    return (
                                        <div className="my-cart-items mb-3">
                                            <div className="row">
                                                {/* <div className="col-lg-2">
                                                    <div style={{minHeight: "100px"}} className='rounded-circle overflow-hidden border '>
                                                        <img className='h-100 w-100' src={res?.auto_cad_file_path} />
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12">
                                                            <div className="row">
                                                                <div className="col-12 position-relative">
                                                                    <p><small>Bid Name: </small> {res.file_name}</p>
                                                                    <div className='icon-set'>
                                                                        <a
                                                                            download
                                                                            // target={downloadcadfile ===true ? "_blank":""}
                                                                            href={res.auto_cad_file_path}
                                                                            className="icons-action"
                                                                            title="Download 3D File"
                                                                            // onClick={()=> handleDownload(res.auto_cad_file_path,res?.file_name)}  
                                                                        >
                                                                            <FaCube className="text-primary"/> 
                                                                        </a>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                            <ul className='m-0 p-0 d-flex align-items-center flex-wrap'>
                                                        {res.manufacturing_process &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Manufacturing Process </small> : <p className='mb-0 ms-1 me-3 new text-capitalize'> {res.manufacturing_process}</p></li>
                                                        }
                                                        {res.custom_manufacturing_process &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Custom Manufacturing Process </small> : <p className='mb-0 ms-1 me-3 new text-capitalize'> {res.custom_manufacturing_process}</p></li>
                                                        }
                                                        {res.material &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Material </small> : <p className='mb-0 ms-1 me-3 new'> {res.material}</p></li>
                                                        }
                                                        {res.custom_material &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Custom Material Value </small> : <p className='mb-0 ms-1 me-3 new'> {res.custom_material}</p></li>
                                                        }
                                                        {res.finish &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Coating </small> : <p className='mb-0 ms-1 me-3 new'> {res.finish}</p></li>
                                                        }
                                                        {res.custom_finish &&
                                                            <li className='d-flex align-items-center'><small className='mb-0'>Custom Coating </small> : <p className='mb-0 ms-1 me-3 new'> {res.custom_finish}</p></li>
                                                        }
                                                        {res.threads &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Threads </small> : <p className='mb-0 ms-1 me-3 new'> {res.threads}</p></li>
                                                        }
                                                        {res.tolerance &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Tolerance </small> : <p className='mb-0 ms-1 me-3 new'> {res.tolerance}</p></li>
                                                        }
                                                        {res.surface_roughness &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Surface Roughness </small> : <p className='mb-0 ms-1 me-3 new'> {res.surface_roughness}</p></li>
                                                        }
                                                        {res.part_marking &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Part Marking </small> : <p className='mb-0 ms-1 me-3 new'> {res.part_marking}</p></li>
                                                        }
                                                        {res.inspection &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Inspection </small> : <p className='mb-0 ms-1 me-3 new'> {res.inspection}</p></li>
                                                        }
                                                        {res.custom_inspection &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Custom Inspection Value </small> : <p className='mb-0 ms-1 me-3 new'> {res.custom_inspection}</p></li>
                                                        }
                                                        {res.part_qualifications_certifications &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Part Quality Certifications </small> : <p className='mb-0 ms-1 me-3 new'> {res.part_qualifications_certifications}</p></li>
                                                        }
                                                        {res.material_certifications &&
                                                            <li className='d-flex align-items-center'><small className='me-1'>Material Certifications </small> : <p className='mb-0 ms-1 me-3 new'> {res.material_certifications}</p></li>
                                                        }

                                                    </ul>
                                                            <div className="row item-notes mt-4">
                                                                <div className="col-lg-12">
                                                                    <p className="nots"><span className="grey-color">Notes :</span > {res.notes}</p>
                                                                    <p><span className="grey-color">Last edited :</span>{moment(res.last_edited).format("MM/DD/yyyy")}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : <span className='no-data fs-1 fw-bolder text-uppercase opacity-25 text-dark'>No Parts Found</span>
                            }
                            {
                                !loading ?
                                    <div className="loader-wrapper">
                                        <div className="loader">
                                            <div className="loading-svg">
                                                <GiAutoRepair />
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                        <div className="col-lg-4 col-md-4  my-cart-side-bar">
                            {/* <div className="row">
                                <div className="col-lg-6 col-md-6 col-6"><p><strong>Bid Offered Price</strong></p></div>
                                <div className="col-lg-6 col-md-6 col-6 text-end"><p>${bididdata.min_proposal_amount}</p></div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-6"><p><strong>Min Bid Received</strong></p></div>
                                <div className="col-lg-6 col-md-6 col-6 text-end"><p>${bididdata?.min_proposal_amount}</p></div>
                            </div>
                            {bididdata?.supplier_bid_response?.is_proposal_resived == 1 
                            ?
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-6"><p><strong>Your Last Bid Price</strong></p></div>
                                <div className="col-lg-6 col-md-6 col-6 text-end"><p>${bididdata?.supplier_bid_response?.proposal_amount}</p></div>
                            </div>
                            :
                            ''
                            }
                            
                                {bididdata?.status == "Open" 
                                ?
                                <div className='border-top-total-price mt-3 pt-3'>
                                <p><strong>Enter Your Min Bid Offer</strong></p>
                                <form onSubmit={(e) => {postBiddetails();e.preventDefault()}}>
                                    <div className="dolar-imput-box d-flex align-items-center">
                                    <span style={{top: "auto"}} className="dolar-sign">$</span>
                                    <input
                                    min={1}
                                    onChange={(e) => setbidAmount(e.target.value)} 
                                    required 
                                    className='ps-3 bg-light w-100 form-control' 
                                    type="number" />
                                    </div>
                                    <button type='submit' to='' className="btn btn-primary-orange m-0 w-100 mt-4">
                                        Place a Bid
                                    </button>
                                </form>
                                </div>
                                :
                                ''
                                }
                            </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
}


export default Bidapply;