import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";

import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { GiAutoRepair } from "react-icons/gi";
import { Button, Spinner, Modal } from "react-bootstrap";
import Input from "react-phone-number-input/input";

// icons
import { FaSearch } from "react-icons/fa";
import { FaEdit, FaCog, FaTelegramPlane } from "react-icons/fa";

import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";

// react bootstrap

import ChangePassword from "../../common/changePassword/changePassword";
import { getMethodData } from "../../adminApi/api";
import SendEmail from "../../common/sendEmail/sendEmail";
import { addPendingValue } from "../../../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";

const CustomerManagement = () => {
  let  showDataToUser =false; 
const [changestatus,setchangestatus] = useState(false);
const [sendmailtousr,setsendmailtousr] = useState(false);
const [userpassword,setuserpassword] = useState(false);
// const [ordercheck,setordercheck] = useState(false);
// const [quotecheck,setquotecheck] = useState(false);
const [redirection,setRedirection] = useState(false);
  useLayoutEffect(() => {
      const ls=JSON.parse(localStorage.getItem("adminDetails"));
   // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
      for(let i=0; i<=ls?.my_permissions?.length;i++){
          if(ls?.my_permissions[i]?.name==="Customer Management"){
              
              for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                  if(ls?.my_permissions[i]?.permissions[j]?.name==='List'){
                      // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      showDataToUser = true;
                  }
                  if(ls?.my_permissions[i]?.permissions[j]?.name==='Change Status'){
                      // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      setchangestatus(true);
                  }
                  if(ls?.my_permissions[i]?.permissions[j]?.name==='Send  Mail'){
                      // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      setsendmailtousr(true);
                  }
                  if(ls?.my_permissions[i]?.permissions[j]?.name==='Change Password'){
                      // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      setuserpassword(true);
                  }
              }
          }
          
      }
      // console.log(showDataToUser);
      if(showDataToUser===false){
        // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
  }, []);

  //permission redirection
  const navigate = useNavigate();
  if (redirection) navigate('/admin/dashboard');
  // \\permission redirection
  
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const Token = localStorage.getItem("token");
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState();
  // modal active
  const [activeShow, setActiveShow] = useState(false);
  const [statusId, setStatusId] = useState();
  const [customerId, setCustomerId] = useState();
  const [userMail, setUserMail] = useState();

  const [loading, setLoading] = useState(false);

  const [statusType, setStatusType] = useState();

  // const [Loading, setLoading] = useState(false);

  const [users, setUser] = useState([]);
  const [Count, setCount] = useState(1);
  const [perPage, setPerPage] = useState();

  const [indexPage, setIndexPage] = useState(1);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const [activePage, setActivePage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  // const [filterData, setFilterData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [filterVisibility, setFilterVisibility] = useState(true);

  const { register, control, handleSubmit, reset } = useForm();

  const dnone = "d-none";

  const activeHandleClose = () => setActiveShow(false);
  const activeHandleShow = (id, status) => {
    if(changestatus===true){
      setStatusType(status);
      setStatusId(id);
      setActiveShow(true);
    }
    else{
      alert("permission not allowed")
    }
  };

  const activeUser = async () => {
    getMethodData(`/customers/change-status/${statusId}`, Token).then((res) => {
      if (res.data.status !== false) {
        LoadTableData();
        toast.success(res.data.message);
        activeHandleClose();
      } else {
        toast.error(res.data.message);
        activeHandleClose();
      }
    });
  };

  const LoadTableData = useCallback(
    async (pageNumber) => {
      setIndexPage(1);

      setLoading(false);
      await axios
        .get(
          `${BASE_URL}/customers?page=${pageNumber === 1 ? 1 : pageNumber}`,
          { headers: { authorization: `Bearer ${Token}` } }
        )
        .then((res) => {
          setUser(res?.data?.result?.data?.data);
          setCount(res.data.result.data.total);
          setPerPage(res.data.result.data.per_page);

          setLoading(true);
          setFilterVisibility(true);
        });

      reset();
    },
    [BASE_URL, Token, reset],
    [users, Count]
  );

  const sendPage = (pageNumber) => {
    axios
      .get(
        `${BASE_URL}/customers?page=${pageNumber}&${new URLSearchParams(
          filterData
        ).toString()}`,
        { headers: { authorization: `Bearer ${Token}` } }
      )
      .then((res) => {
        setUser(res?.data?.result?.data?.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);

        setLoading(true);
      });
  };

  useEffect(() => {
    LoadTableData();
  }, []);

  const handleClosePw = (userId) => {
    if(userpassword===true){
       setShowPassword(true);
      setCustomerId(userId);
  }
  else{
    alert('Permission Not Allowed');
    }
  };

  const handleShowEmail = (userMail) => {
    if(sendmailtousr===true){
      setShowEmail(true);
      setUserMail(userMail);
    }
    else{
    alert('Permission Not Allowed');
    }
  };

  // if (filterVisibility === true) {
  //     setCount(Count)
  // } else {
  //     setCount(filterCount)
  // }

  // Change page''

  const handlePageChange = (pageNumber) => {
    // sendPage(pageNumber)
    // LoadTableData(pageNumber)
    if (filterVisibility === true) {
      LoadTableData(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    } else {
      sendPage(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoadingButton(true);
    setIndexPage(1);

    setFilterData(data);

    data.from_date =
      !data.from_date || data.from_date === null || data.from_date === undefined
        ? ""
        : moment(data.from_date).format("YYYY/MM/DD");
    data.to_date =
      !data.to_date || data.to_date === null || data.to_date === undefined
        ? ""
        : moment(data.to_date).format("YYYY/MM/DD");

    setLoading(false);
    // sendPage()
    await axios
      .get(`${BASE_URL}/customers?${new URLSearchParams(data).toString()}`, {
        headers: { authorization: `Bearer ${Token}` },
      })
      .then((res) => {
        setLoadingButton(false);
        setUser(res?.data?.result?.data?.data);
        setCount(res.data.result.data.total);
        // setCount(res.data.data.userCount)
        // setFilterData(res.data.data.userData)
        setLoading(true);
        setFilterVisibility(false);
      });
    setLoadingButton(false);
  };
  const [todayDate,setTodayDate]=useState(null);
  return (
    <>
     
                <div className="tables-field">
                  <div className="project-table">
                    <div className="col-12">
                      <div className="row">
                        {/* <div className="col-6"> */}
                        <div className="col-12">
                          <div className="main-head">
                            <h4>Customer List</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-order-box shadow rounded overflow-hidden">
                      <div className="tab-head">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  maxLength="15"
                                  {...register("first_name", {
                                    required: false,
                                  })}
                                  className="form-control"
                                  placeholder="First Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  maxLength="15"
                                  {...register("last_name", {
                                    required: false,
                                  })}
                                  className="form-control"
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  maxLength="40"
                                  {...register("email", { required: false })}
                                  className="form-control"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <Input
                                  minLength={14}
                                  maxLength={14}
                                  defaultCountry="US"
                                  placeholder="Enter phone number"
                                  {...register("phone", { required: false })}
                                  value={value}
                                  onChange={setValue}
                                />
                                {/* <input type="text" maxLength="15" {...register('phone', { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Mobile" /> */}
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group before-con">
                                <Controller
                                  control={control}
                                  name="from_date"
                                  render={({ field }) => (
                                    <DatePicker
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="Click to select a date"
                                      onChange={(date) => {
                                        field.onChange(date);
                                        setTodayDate(date);}}
                                      selected={field.value}
                                      maxDate={new Date()}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group before-con after">
                                <Controller
                                  control={control}
                                  name="to_date"
                                  render={({ field }) => (
                                    <DatePicker
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="Click to select a date"
                                      onChange={(date) => field.onChange(date)}
                                      selected={field.value}
                                      minDate={todayDate==null? new Date() : todayDate}
                                      maxDate={new Date()}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  maxLength="10"
                                  {...register("order_id", { required: false })}
                                  className="form-control"
                                  id="exampleInputEmail123"
                                  aria-describedby="emailHelp"
                                  placeholder="Order ID"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  maxLength="10"
                                  {...register("quote_id", { required: false })}
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Quote ID"
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group">
                                <select
                                  className="form-select form-control"
                                  {...register("status", { required: false })}
                                  aria-label="Default select example"
                                >
                                  <option className="disabled-value " value="">
                                    All
                                  </option>

                                  <option value="active">Active</option>
                                  <option value="inactive">Inactive</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="theme-btn btn-group-right text-end">
                                {loadingButton === false ? (
                                  <button type="submit" className="btn">
                                    Search
                                  </button>
                                ) : (
                                  <Button
                                    className="btn  spinner"
                                    variant="dark"
                                    disabled
                                  >
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    Searching...
                                  </Button>
                                )}
                                <button
                                  type="reset"
                                  onClick={() => LoadTableData()}
                                  className="btn"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="project-table">
                    <div className="form-search">
                      <form>
                        <div className="form-group icon-input top-search">
                          <input
                            type="text"
                            id="searchForm"
                            className="form-control"
                            onChange={(event) => {
                              setSearchTerm(event.target.value);
                            }}
                            placeholder="Search"
                          />
                          <FaSearch />
                        </div>
                        <input type="btn" className="d-none" value="Submit" />
                      </form>
                    </div>
                    <div className="table-order shadow">
                      <table>
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Company Name</th>
                            <th>Orders</th>
                            <th>Quotes</th>
                            <th>Added On</th>
                            <th>Status</th>
                            <th>Actions </th>
                          </tr>
                        </thead>
                        <tbody>
                          {users && users.length ? (
                            users
                              .filter((val) => {
                                const date = val?.created_at;
                                if (searchTerm === "") {
                                  return val;
                                } else if (
                                  (val?.first_name+" "+val?.last_name)
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                ) {
                                  return val;
                                } else if (
                                  val?.last_name
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                ) {
                                  return val;
                                } else if (
                                  val?.email
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                ) {
                                  return val;
                                } else if ( val?.company_name !== null && val?.company_name !== undefined &&
                                    val?.company_name
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                ) {
                                  return val;
                                } else if (
                                  val?.phone
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                ) {
                                  return val;
                                } else if (
                                  moment(date)
                                    .format("MM/DD/yyyy")
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                ) {
                                  return val;
                                } else if (
                                  val?.status
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                ) {
                                  return val;
                                } else {
                                  return false;
                                }
                              })
                              .map((user, index) => (
                                <tr key={user.id}>
                                  <td className="s-no" scope="row">
                                    {perPage * (indexPage - 1) + (index + 1)}
                                  </td>
                                  <td>
                                    {user?.first_name} {user?.last_name}
                                  </td>

                                  <td>{user?.email}</td>
                                  <td className="text-nowrap">{user?.phone}</td>
                                  <td className="text-nowrap">
                                    {user?.company_name ?? "-"}
                                  </td>
                                  <td>
                                    {user?.orders_count > 0 ? (
                                      <Link
                                        to={`/admin/order-management/filterOrder/${user?.id}`}
                                      >
                                        {user?.orders_count}
                                      </Link>
                                    ) : (
                                      user?.orders_count
                                    )}
                                  </td>
                                  <td>
                                    {user?.quotes_count > 0 ? (
                                      <Link
                                        to={`/admin/quote-management/filterQuote/${user?.id}`}
                                        className="text-primary pointer d-block"
                                      >
                                        {user?.quotes_count}
                                      </Link>
                                    ) : (
                                      user?.quotes_count
                                    )}
                                  </td>
                                  <td>
                                    {moment(user?.created_at).format(
                                      "MM/DD/yyyy"
                                    )}
                                  </td>
                                  <td className="">
                                    <span
                                      onClick={() =>
                                        activeHandleShow(user?.id, user?.status)
                                      }
                                      className={`badge bg-success ${user?.status}`}
                                    >
                                      {user?.status}
                                    </span>
                                  </td>
                                  <td className="action-top">
                                    <Link
                                      title="Edit Customer"
                                      to={`edit/${user?.id}`}
                                      className="icons-action"
                                    >
                                      <FaEdit />
                                    </Link>

                                    <span
                                      onClick={() => handleClosePw(user?.id)}
                                      title="Change Password"
                                      className="icons-action"
                                    >
                                      <FaCog />
                                    </span>
                                    <span
                                      onClick={() =>
                                        handleShowEmail(user?.email)
                                      }
                                      title="Send Mail"
                                      className="icons-action"
                                    >
                                      <FaTelegramPlane />
                                    </span>
                                    {/* <Link to={`/userWineList/${user._id}`} className="icons-action"><FaEye /></Link> */}

                                    {/* <span onClick={() => activeHandleShow(user.id,user.status)} className="icons-action">{user.status === "inactive" ?  <FaRegCheckCircle/> :  <FaBan />}</span> */}
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td className="no-data">NO CUSTOMER FOUND</td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      {!loading ? (
                        <div className="loader-wrapper">
                          <div className="loader">
                            <div className="loading-svg">
                              <GiAutoRepair />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
                    </div>
                    <div className="pagination">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={Number(Count)}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>

                  {/* modals */}

                  {/* active modal */}
                  <Modal
                    className="status-modal"
                    animation={false}
                    show={activeShow}
                    onHide={activeHandleClose}
                  >
                    <Modal.Body>
                      Are you sure you want to &nbsp;
                      {statusType === "active"
                        ? "in-activate"
                        : "activate"}{" "}
                      this Customer?
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="theme-btn btn-group-right text-end">
                        <Button variant="secondary" onClick={activeUser}>
                          Yes
                        </Button>
                        <Button variant="primary" onClick={activeHandleClose}>
                          No
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {/* active modal end */}

                  <ChangePassword
                    api={`/customers/change-password/${customerId}`}
                    show={showPassword}
                    dnone={"d-none"}
                    onHide={() => setShowPassword(false)}
                  />
                  <SendEmail
                    api={"/suppliers/send-contect-email"}
                    show={showEmail}
                    usermail={userMail}
                    dnone={"d-none"}
                    onHide={() => setShowEmail(false)}
                  />
                </div>
             
    </>
  );
};
export default CustomerManagement;
