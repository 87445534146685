import React, { useEffect, useState } from 'react'
import { useNavigate,Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { modalToggelValue, modalResetMsgValue } from '../../../../../../redux/actions/action'
import { Modal } from 'react-bootstrap'
import {FaCheckDouble} from 'react-icons/fa'
const VerificationMsg = (props) => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const dispatchToggle = () => {
        dispatch(modalToggelValue(true))


        // navigate("/")


    }


    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <div className="modal-content rounded-0">
                <div className="sign-in">
                    <div className="input-head text-center">
                        <div className=''>
                            <FaCheckDouble className='text-success fs-1'/>
                        </div>
                        <p className='text-success fs-4 mt-3'>{id}</p>
                    </div>
                
                <div className="select-box-top pr-0 text-center">


                    <Link to="/" onClick={()=>dispatchToggle()} className="btn btn-primary-orange  m-auto text-decoration-none  spinner mb-3">Go Back To Login </Link>

                </div>
            </div>
            </div>

        </Modal>
    )
}

export default VerificationMsg;