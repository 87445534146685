import React, { useState, useEffect, useLayoutEffect} from 'react'
import { GiAutoRepair } from 'react-icons/gi';
import { ImageObj } from '../../../../../../assets/images/imgeObject'
import { Link  } from 'react-router-dom'
import { getMethodDataUser } from '../../../../../admin/adminApi/api'
import moment from 'moment'

import { FaRegFilePdf, FaEye, FaFileDownload,FaTrashAlt, FaTruckMoving, FaMapMarkedAlt, FaRegTimesCircle , FaRedoAlt, FaEdit, FaPen } from 'react-icons/fa'
import { bounce } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import QuoteCollapse from '../../../../common/quoteCollapse/quoteCollapse';
import CancelOrder from '../../../../common/cancelOrder/cancelOrder';
import ReturnOrder from '../../../../common/returnOrder/returnOrder';
import axios from 'axios';
import { useSelector } from 'react-redux';
import DeleteId from '../../../../common/deleteId/deleteId';
import { toast } from 'react-toastify';

const CustomerDashboard = () => {
  
  const [resentOrders, setResentOrders] = useState([])
  const [resentQuotes, setResentQuotes] = useState([])
  const [resentParts, setResentParts] = useState([])
  const [dashboardData, setDashboardData] = useState([])
  const [userdData, setUserdData] = useState([])
  const [modalCancelShow, setModalCancelShow] = React.useState(false);

  const [modalReturnShow, setModalReturnShow] = React.useState(false);

  const [orderId, setOrderId] = useState();

  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)

  const [indexDelete, setIndexDelete] = useState();
  const [openDelete, setOpenDelete] = useState();
  const [modifyData, setModifyData] = useState();

  const state=useSelector(state=>state.customerLoader)
  // console.log("custoemrdashboard=>",state)
  useEffect(()=>{
    if(state===true){
      setLoading2(true)
    }else if(state===false){
      setLoading2(false)
    }
  },[state])

  const BASE_URL = process.env.REACT_APP_BASE_URL_USER;

  useEffect(() => {
    axios.get(`${BASE_URL}/user/get-globle-config/QUOTE_MODIFY`).then((req) => {
      setModifyData(req?.data?.result?.data?.value);
      // console.log(req?.data?.result?.data?.value);
    });
  }, []);

  const USERTOKEN = localStorage.getItem("userToken")
  const styles = {
    bounce: {
      animation: 'x 1s',
      animationName: Radium.keyframes(bounce, 'bounce')
    }
  }
  const getDashboardData = () => {
    getMethodDataUser('/get-dashboard-data', USERTOKEN).then((res) => {
      setLoading(true)
      setResentOrders(res?.data?.result?.data?.resent_orders)
      setResentQuotes(res.data.result.data.resent_quotes)
      setResentParts(res.data?.result?.data?.resent_parts)
      setDashboardData(res.data.result.data)
      setUserdData(res.data.result.data.user)

    })
  }

 
  useEffect(() => {
    getDashboardData()
}, [])



const popUpDetele = (index) => {
  setIndexDelete(index);
  setOpenDelete(true);
};

  return (
    <React.Fragment>
        <StyleRoot>
      <QuoteCollapse />
      <section className="three-boxes pt-5 mt-3 pb-5">
        <div className="container">
          <div className="row">
            <div className={"col-lg-12 dashboard-headline"}>
              <h2>Welcome, {userdData?.first_name} {userdData?.last_name} <span className='fs-5 d-inline-block'> (member since {moment(userdData?.created_at).format("MMM YYYY")})</span> </h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
           
            <div className="col-lg-4">
            <Link to="/customer/orders" className='text-decoration-none'>
              <div className="green-box mt-3">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={ImageObj.TotalOrders} alt="total orders" />
                  </div>
                </div>
                <div className="row flex-row-reverse">
                  <div className="col-md-4">
                 
                    <h2 style={styles.bounce} className="font-size-50">{dashboardData.total_orders}</h2>
                   
                    </div>
                  <div className="col-md-8">
                    <h5 className="set-margin-top">Total Orders</h5>
                 
                  </div>
                </div>
              </div>
            </Link>
          </div>

            <div className="col-lg-4">
              <Link to="/customer/quotes" className='text-decoration-none'>
                <div className="pink-box mt-3">
                  <div className="row">
                    <div className="col-lg-12">
                      <img src={ImageObj.TotalQuotes} alt="total quotes" />
                    </div>
                  </div>
                  <div className="row flex-row-reverse">
                    <div className="col-md-4"><h2 style={styles.bounce} className="font-size-50">{dashboardData.total_quotes}</h2></div>
                    <div className="col-md-8">
                      <h5 className="set-margin-top">Total Quotes</h5>
                     
                    </div>
                  </div>
                </div>
              </Link>
            </div>
         
            <div className="col-lg-4">
              <Link to={"/customer/myparts"}className='text-decoration-none'>
                <div className="blue-box mt-3">
                  <div className="row">
                    <div className="col-lg-12">
                      <img src={ImageObj.ToatalParts} alt="total parts" />
                    </div>
                  </div>
                  <div className="row flex-row-reverse">
                    <div className="col-md-4"><h2 style={styles.bounce} className="font-size-50">{dashboardData.total_parts}</h2></div>
                    <div className="col-md-8">
                      <h5 className="set-margin-top">Total Parts</h5>
                      
                    </div>
                  </div>
                </div>
              </Link>
            </div>

          </div>
        </div>
      </section>
      <section className="py-5 ">
        <div className="container  col-12 ">
          <div className="row align-items-center dashboard-heading-block mb-3 pb-3 m-0">
            <div className="col-lg-8 d-flex align-items-center  align-items-center">
              <img src={ImageObj.CheckList} width="56px" height="56px" className="mt-2 me-4" alt="..."/>
              <div className='h-100 '>
                <h2 className='mb-0'>Recent Quotes</h2>
              </div>
            </div>
            {/* {modifyData==="Yes"?null:modifyData==="No"?<div className="col-lg-4 btn-align-right">
              <Link to="/customer/create-quote" type="button" className="btn btn-primary-transparent ms-2">
                Request A Quote
              </Link>
            </div>:null} */}
          </div>
        </div>
        <div className="container col-12 gx-0 grid-data position-relative">
          <div className="row gx-0 grid-head-row">
            <div className="col">Sr. No.</div>
            <div className="col">Quote ID</div>
            <div className="col">No. Of Parts</div>
            <div className="col">Date</div>
            <div className="col"> Amount</div>
            <div className="col">Status</div>
            <div className="col">Action</div>
          </div>
          {
            resentQuotes?.length && resentQuotes !== null && resentQuotes !== undefined ? resentQuotes.map((res, i) => {
              return (
                <div className="row gx-0 grid-data-row" key={i+1}>
                  <div className="col" data-label="Sr. No.: ">
                    <span className="data-value">{i + 1}</span>
                  </div>
                  <div className="col" data-label="ID: ">
                    <span className="data-value">#{res.quote_id}</span>
                  </div>
                  <div className="col" data-label="quote_parts_count: ">
                  <span className="data-value">{res?.quote_parts_count}</span>
                  </div>
                  <div className="col" data-label="Date: ">
                    <span className="data-value">{moment(res.date).format("MM/DD/yyyy")}</span>
                  </div>
                   <div className="col" data-label="Amount: ">
                   {res.is_proposal_send === 1 ? (res.status === "proposal submitted" ? <Link to={`/customer/quotes/quotedetails${res.id}`} className='text-black'>View Proposal</Link>: <>${res.product_price}</>) :<>${res.product_price}</>}
                    {/* <span className="data-value">${res.product_price}</span> */}
                   </div>
                  
                  <div className="col" data-label="Status: ">
                    <span className="data-value text-capitalize">
                      {res.is_proposal_send === 1 ? (res.status === "proposal submitted" ? "proposal received": res.status) :res.status}
                    </span>
                    {/* {
                        res?.is_manual_quote===0 ? "Pending" : 
                        res?.is_manual_quote===1 ? "Request Submitted" 
                        : res.status } */}

                  </div>
                  <div className="col d-flex align-items-center action justify-content-center" data-label="Action">
                      <Link
                        title="View Quote"
                        className="text-dark"
                        to={`/customer/quotes/quotedetails${res.id}`}
                      >
                        <FaEye className="fs-5 fw-normal ms-2 pointer" />
                      </Link>

                      {res.status === "open" && (
                        <React.Fragment>
                          {res.product_price != 0 && 
                          <Link
                            title="Place Order"
                            className="text-dark"
                            to={`/customer/checkout${res.id}`}
                          >
                            <FaMapMarkedAlt className="fs-5 fw-normal ms-2 pointer" />
                          </Link>
                          }
                          <span onClick={() => popUpDetele(res.id)}>
                            <FaTrashAlt className="fs-5 fw-normal ms-2 pointer" />
                          </span> 
                        </React.Fragment>
                      )}
                      {res.status === "proposal accepted" && 
                       <React.Fragment>
                       {res.product_price != 0 && 
                       <Link
                         title="Place Order"
                         className="text-dark"
                         to={`/customer/checkout${res.id}`}
                       >
                         <FaMapMarkedAlt className="fs-5 fw-normal ms-2 pointer" />
                       </Link>
                       }
                     </React.Fragment>
                     }
                      {res.status === "expired" && (res?.is_manual_quote != 1) && (
                        <React.Fragment>
                          {/* <Link
                            title="View Quote"
                            className="text-dark"
                            to={`/customer/quotes/quotedetails${res.id}`}
                          >
                            <FaEye className="fs-5 fw-normal ms-2 pointer" />
                          </Link> */}
                          <span onClick={() => popUpDetele(res.id)}>
                            <FaTrashAlt className="fs-5 fw-normal ms-2 pointer" />
                          </span>
                        </React.Fragment>
                      )}
                      {/* {res.status === "closed" && (
                        <Link
                          title="View Quote"
                          className="text-dark"
                          to={`/customer/quotes/quotedetails${res.id}`}
                        >
                          <FaEye className="fs-5 fw-normal ms-2 pointer" />
                        </Link>
                      )} */}

                      {res.details_pdf !== null && (
                        <a
                          href={res.details_pdf}
                          title={res.details_pdf !== null ? "Download Quote" : ""}
                          target="_blank"
                        >
                            <FaRegFilePdf className="fs-5 fw-normal ms-2 pointer" />
                        </a>
                      )}

                    </div>
                </div>
              )
            }) : <div className='no-data fs-1 fw-bolder text-center text-uppercase opacity-25 text-dark'>No Quotes Found</div>
          }

        
          {resentQuotes?.length >=5 && resentQuotes !== null && resentQuotes !== undefined? 
          <div className="container  col-12 " data-radium="true" style={{textAlign:"right"}}><Link to="/customer/quotes/"><button type="submit" className="btn btn-primary-orange mt-2">View More</button></Link></div>
          :""
          }
          {
            !loading ?
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiAutoRepair />
                  </div>
                </div>
              </div> : ""
          }

        </div>

      </section>
     
        <section className="py-5 ">
        <div className="container col-12">
          <div className="row align-items-center dashboard-heading-block mb-3 pb-3 m-0"> 
            <div className="col-lg-8 d-flex">
              <img src={ImageObj.CheckList} width="56px" height="56px" className="mt-2 me-4" alt="..."/>
              <div>
                <h2 className='mb-0 h-100 d-flex justify-content-center align-items-center'>Recent Orders</h2>
              </div>
            </div>
            {/* <div className="col-lg-4 btn-align-right">
              <Link to="/customer/create-quote" type="button" className="btn btn-primary-transparent ms-2">
                Request A Quote
              </Link>
            </div> */}
          </div>
        </div>
        <div className="container  col-12 gx-0 grid-data position-relative">
          <div className="row gx-0 grid-head-row">
            <div className="col">Sr. No.</div>
            <div className="col">Order ID</div>
            <div className="col">Name</div>
            <div className="col">Date</div>
            <div className="col">Final Amount</div>
            <div className="col">Status</div>
            <div className="col">Action</div>
          </div>
          {
            resentOrders?.length && resentOrders !== null && resentOrders !== undefined ? resentOrders.map((res, i) => {
              return (
                <div className="row gx-0 grid-data-row" key={i+1}>
                  <div className="col" data-label="Sr. No.: ">
                    <span className="data-value">{i + 1}</span>
                  </div>
                  <div className="col" data-label="ID: ">
                    <span className="data-value">#{res.order_id}</span>
                  </div>
                  <div className="col" data-label="Name: ">
                    <span className="data-value">{res?.customer?.first_name} {res?.customer?.last_name}</span>
                  </div>
                  <div className="col" data-label="Date: ">
                    <span className="data-value">{moment(res.date).format("MM/DD/yyyy")}</span>
                  </div>
                  <div className="col" data-label="Amount: ">
                    <span className="data-value">${res.final_amt}</span>
                  </div>
                  <div className="col" data-label="Status: ">
                    <span className="data-value">{res.status}</span>
                  </div>
                  <div className="col action" data-label="Action">
                  <Link title='view details' to={`/customer/orders/${res.id}`}><FaEye className='fs-5 fw-normal ms-2 pointer' /></Link>
                    <a title={res.invoice_path !== null ? 'download invoice pdf' : ""} href={res.invoice_path} target="_blank" rel="noreferrer">{res.invoice_path !== null && <FaFileDownload className='fs-5 fw-normal ms-2 pointer' /> }</a>
                    {/* {
                      res.status == "Order Received" &&
                    <span title='Cancel Order' onClick={() => cancelModalFunc(res.id)}><FaRegTimesCircle className='fs-5 fw-normal ms-2 pointer' /></span>
                  } */}
                    {/* <FaTruckMoving onClick={() => toast.warning("No updated tracking information available. Please check back.")}  title='track order' className='fs-5 fw-normal ms-2 pointer' /> */}
                    {/* {
                      res.status === "Delivered" &&
                      <span title='Return Order' onClick={() => returnModalFunc(res.id)}><FaRedoAlt className='fs-5 fw-normal ms-2 pointer'/></span>
                    } */}
                   
                  </div>
                </div>
              )
            }) : <div className='no-data fs-1 fw-bolder text-center text-uppercase opacity-25 text-dark'>No Orders Found</div>
          }
          {resentOrders?.length >=5 && resentOrders !== null && resentOrders !== undefined? 
            <div className="container  col-12 " data-radium="true" style={{textAlign:"right"}}><Link to="/customer/orders/"><button type="submit" className="btn btn-primary-orange mt-2">View More</button></Link></div>
          :""
          }
          {
            !loading ?
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiAutoRepair />
                  </div>
                </div>
              </div> : ""
          }

        </div>
      </section>
  
      <section className="py-5 mb-5 ">
        <div className="container col-12 ">
          <div className="row align-items-center dashboard-heading-block mb-3 pb-3 m-0">
            <div className="col-lg-8 d-flex">
              <img src={ImageObj.CheckList} width="56px" height="56px" className="mt-2 me-4" alt="..."/>
              <div>
                <h2 className='mb-0 h-100 d-flex justify-content-center align-items-center'>Recent Parts</h2>
              </div>
            </div>
            {/* <div className="col-lg-4 btn-align-right">
              <Link to="/customer/create-quote" type="button" className="btn btn-primary-transparent ms-2">
                Request A Quote
              </Link>
            </div> */}
          </div>
        </div>
        <div className="container col-12 gx-0 grid-data">
          <div className="row gx-0 grid-head-row">
            <div className="col">Sr. No.</div>
            {/* <div className="col">Image</div> */}
            <div className="col">Part ID</div>
            <div className="col">Name</div>
            <div className="col">Date</div>
            {/* <div className="col"> Amount</div> */}
            <div className="col">Action</div>
          </div>
          {
            resentParts?.length && resentParts !== null && resentParts !== undefined ? resentParts.map((res, i) => {
              return (
                <div className="row gx-0 grid-data-row d-flex justify-content-center align-items-center" key={i+1}>
                  <div className="col " data-label="Sr. No.: ">
                    <span className="data-value">{i + 1}</span>
                  </div>
                  {/* <div className="col" data-label="Image">
                    <span className="data-value"><img className='w-100' src={ImageObj.ProductBig} alt="..." /></span>
                  </div> */}
                  <div className="col" data-label="ID: ">
                    <span className="data-value">#{res?.id}</span>
                  </div>
                  <div className="col" data-label="Name: ">
                    <span className="data-value">{res?.file_name}</span>
                  </div>
                  <div className="col" data-label="Date: ">
                    <span className="data-value">{moment(res?.updated_at).format("MM/DD/yyyy")}</span>
                  </div>
                 
                 
                
                   {/* <div className="col" data-label="Amount: ">
                    <span className="data-value">${res?.total_price}</span>
                   </div> */}


                  <div className="col action" data-label="Edit">

                    {/* user can edit when manual quote is no */}
                    
                  {modifyData === "Yes" && (res.is_manual_quote === 2) ?
                  null:
                  <>
                    {res?.status==="open"  ? 

                      <Link title='Edit Part' className='text-dark' to={`/customer/quotes/editdetails${res?.id}`}>
                        <FaEdit className='fs-5 fw-normal ms-2 pointer' />
                      </Link> 
                      : 
                      null
                      }
                  </>
                }

                  


                    <a title={res.part_details_pdf !== null ? 'Download Part pdf' : ""} href={res.part_details_pdf} rel="noreferrer" target="_blank">{res.part_details_pdf !== null && <FaFileDownload className='fs-5 fw-normal ms-2 pointer' /> }</a>
                  </div>

                  
                </div>
              )
            }) : <div className='no-data fs-1 fw-bolder text-center text-uppercase opacity-25 text-dark'>No Parts Found</div>
          }
           {resentParts?.length >=5 && resentParts !== null && resentParts !== undefined? 
          <div className="container  col-12 " data-radium="true" style={{textAlign:"right"}}><Link to="/customer/myparts/"><button type="submit" className="btn btn-primary-orange mt-2">View More</button></Link></div>:""
          }
          {
            !loading ?
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiAutoRepair />
                  </div>
                </div>
              </div> : ""
          }
        </div>
      </section>

      <DeleteId
        index={indexDelete}
        api={"/quotes/delete/"}
        toggel={openDelete}
        setopen={setOpenDelete}
        renderapi={getDashboardData}
      />

          {
            loading2===true ?
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiAutoRepair />
                  </div>
                </div>
              </div> : ""
          }
       <CancelOrder show={modalCancelShow}
        onHide={() => setModalCancelShow(false)} orderlist={getDashboardData} orderid={orderId}/>
        <ReturnOrder show={modalReturnShow}
        onHide={() => setModalReturnShow(false)} orderlist={getDashboardData} orderid={orderId}/>



      </StyleRoot>

    </React.Fragment>
  )
}

export default CustomerDashboard