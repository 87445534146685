import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form';
import { Modal, Button, Spinner} from 'react-bootstrap'
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import FormValidation from '../../../../formValidation/formValidation';
import { postMethodData } from '../../adminApi/api';
import { useNavigate } from 'react-router-dom';




const SendEmail = (props) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [visibilityNew, setVisibilityNew] = useState(true)
    const [visibility, setVisibility] = useState(true)
    const [visibilityConfirm, setVisibilityConfirm] = useState(true)
    const [passErr, setPassErr] = useState();
    const [currentpPassErr, setCurrentPassErr] = useState();
    const Token = localStorage.getItem("token")
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const validationSchema = Yup.object().shape({
        to_email: Yup.string().email('The email must be a valid email. ')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const FormValidationNew = FormValidation

    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }

    const pwVisibilityNew = (value) => {
        setVisibilityNew(value)
    }

    const LogOut = () => {
        props.unAuth()
        toast("You have logged out successfully.")
        navigate("/admin")


    }



    const onSubmit = (data) => {
        setLoading(true)
        postMethodData(props.api, data, Token)
            .then((res) => {

                if (res.data.status !== false) {
                    setLoading(false)
                    props.onHide()
                    toast.success(res.data.message)
                    LogOut()
                    setCurrentPassErr("")
                    reset();



                } else {
                    setLoading(false)
                    toast.success(res.data.message)
                    setCurrentPassErr(res.data.message)

                }
            })
          


    }
    const closeModal = useCallback(() => {
        props.onHide()
        reset()
    }, [])

    useEffect(() => {
        reset()
        FormValidationNew()
    }, [FormValidationNew, props])


    return (
        <div className="modal-edit">
            <Modal {...props} size="lg" className="edit-content"  >

                <div className="">
                    <div className="main-theme bg-theme border shadow ps-5 pe-5">
                        <Modal.Header closeButton onClick={() => closeModal()}>
                            <div className="select-steps-box">
                                <div className="input-head">
                                    <h3>Send Message</h3>
                                </div>
                            </div>
                        </Modal.Header>
                        <section className="select-state">
                            <div className="state-bor">
                                <div className="log-in">

                                    <div className="select-box-top pr-0">
                                        <div className="input-box">
                                            <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                                                <div className="col-12 p-0">
                                                    <div className="row">

                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <label>To</label>
                                                                <input type="text" maxLength="40" value={props.usermail} className="form-control" id="validationCustom01"  {...register('to_email', { required: true })} required />
                                                                <small className='text-danger'> {errors.to_email?.message}</small>
                                                                <div className="invalid-feedback">
                                                                    Please Enter Email.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <label>Subject</label>
                                                                <input type="text" maxLength="100" className="form-control" id="validationCustom01"  {...register('subject', { required: true })} required />
                                                                <div className="invalid-feedback">
                                                                    Please Enter Subject.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <label>Message</label>
                                                                <textarea type="text"  className="form-control" id="validationCustom01"  {...register('message', { required: true })} required />
                                                                <div className="invalid-feedback">
                                                                    Please Enter Subject.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="theme-btn btn-group-right">
                                                            {

                                                                loading === false ?
                                                                    <button type="submit" className="btn">Send</button> :
                                                                    <Button className='btn  spinner' variant='dark' disabled>
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="grow"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                            className='me-2'
                                                                        />
                                                                        Sending...
                                                                    </Button>
                                                            }
                                                            <Button type="reset" className="btn" variant="primary" onClick={() => closeModal()}>
                                                                Cancel
                                                            </Button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </Modal>
        </div>
    )



}

export default SendEmail;