import React, { useState, useEffect } from "react";
import { FaSearch, FaRegCalendarAlt, FaStar, FaFilePdf, FaBox, FaCube, FaDownload, FaEye, FaTruck } from "react-icons/fa";
import { ImageObj } from "../../../../../../assets/images/imgeObject";
import {
  getMethodDataSupplier,
  getMethodDataUser,
} from "../../../../../admin/adminApi/api";
import { postMethodDataSupplier } from "../../../../../admin/adminApi/api";
import { useForm, Controller } from "react-hook-form";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import { MdFeedback, MdStar } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { GiAutoRepair } from "react-icons/gi";
import Chart from "../../../../../admin/Chart";
import fileDownload from 'react-file-download';


const OrderDetails = () => {
  const { id } = useParams();
  const USERTOKEN = localStorage.getItem("userToken");
  const {
    register: register2,
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm();
  const SUPPLIERTOKEN = localStorage.getItem("supplierToken");
  const [show, setShow] = useState(false);
  const [activeShow2, setActiveShow2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setstatus] = useState();
  const handleClose = () => setShow(false);
  const [orderDetail, setOrderDetail] = useState();
  const [CreateShipment, setCreateShipment] = useState(false);
  // const [editData, setEditData] = useState(["not"]);
  const [shippingModal, setShippingModal] = useState(false);
  const [shipData, setShipData] = useState({});


  const [showrating, setShowrating] = useState(false);
  const [reviewdata, setreiviewdata] = useState("");
  const [ontime_delivery, setontimeDelivery] = useState("");
  const [communication, setcommunication] = useState("");
  const [engagement, setengagement] = useState("");
  const [quality, setquality] = useState("");

  const [ViewAttachments, setViewAttachments] = useState(false)
  const [mydata, setmydata] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);

  const [trackingShow,setTrackingShow]=useState(false);

  const [trackingDetails,setTrackingDetails]=useState(null);


  const handleviewattachment=(data)=>{
      setmydata(!mydata);
      setAttachmentData(
        JSON.parse(data)
      );
  }


  const getOrderdetails = () => {
    getMethodDataSupplier(
      `/assigned-order-details/${id}`,
      USERTOKEN || SUPPLIERTOKEN
    ).then((res) => {
      setOrderDetail(res.data.result.data);
    });
  };
  const activeHandleShow = () => {
    setActiveShow2(true);
  };
  const activeHandleClose3 = () => {
    setActiveShow2(false);
  };

  const activeShippingModal = () => {
    setShippingModal(!shippingModal);
  };

  const handleShipment=(id,bid)=>{
    // if(CreateShipment===true){
      // if(orderDetail?.payment_method !=="Bank Transfer" || orderDetail?.payment_status !== "Pending"){
      activeShippingModal();
      setShipData({
        order_id: id,
        sub_order_id:bid,
      });
    // }
    // else{
    //   window.alert("Payment is Pending");
    // }
  //  }
  //  else{
  //   alert('permission not allowed')
  //  }
  }



  const ChnageStatus = () => {
    setLoading(true);
    postMethodDataSupplier(
      `/update-order-status`,
      {
        status: status,
        order_id: id,
      },
      USERTOKEN || SUPPLIERTOKEN
    ).then((res) => {
      if (res.data.status == true) {
        activeHandleClose3();
        getOrderdetails();
        toast.success(res.data.message);
        setLoading(false);
      } else {
        activeHandleClose3();
        setLoading(false);
        // toast.warning(res.data.message)
      }
    });
  };


  const changeShipmentStatus = (data) => {
    //// console.log('changing shipping status',data);
    postMethodDataSupplier(`/orders/create-order-shipment`, data, USERTOKEN || SUPPLIERTOKEN).then(
      (res) => {
        setLoading(true);
        // // console.log(res)
        if (res.data.status === true) {
          // console.log("response status", res.status);
          // console.log("response data", res.data);
          getOrderdetails();
          toast.success(res.data.message);
          setLoading(false);

        } else {
          // console.log("response data", res);
          setLoading(false);
          toast.warning(res.data.message);
        }
      }
    );

    setShippingModal(!shippingModal);
  };



  useEffect(() => {
    getOrderdetails();
  }, []);

  // const stars = (length) => {
  //   var stars_array = [];
  //   for (let index = 1; index <= 5; index++) {
  //     if (index <= length) {
  //       stars_array.push(<FaStar className="text-warning" />);
  //     } else {
  //       stars_array.push(<FaStar className="text-secondary" />);
  //     }
  //   }
  //   return stars_array;
  // };

  const handleRating = () => {
    setShowrating(true)
  }
  const Rating_System =() => {
       
    getMethodDataSupplier(`${`/get-my-over-all-reciew`}`,USERTOKEN || SUPPLIERTOKEN)
        .then((res) => {
            setreiviewdata(res?.data?.result?.data?.over_all_review)
            setquality(res?.data?.result?.data?.qulity)
            setontimeDelivery(res?.data?.result?.data?.on_time_delivery)
            setcommunication(res?.data?.result?.data?.communication)
            setengagement(res?.data?.result?.data?.engagement)
        })
}


const getTrackingData=(data)=>{
  setTrackingShow(!trackingShow);
  //https://mach2mfg.testingdemo.net/backend/public/api/V1/user/shipment-tracking?tracking_number=794660785481
  // getMethodDataUser(`/shipment-tracking?tracking_number=${}`, USERTOKEN || SUPPLIERTOKEN).then(
  // getMethodDataUser(`/shipment-tracking?tracking_number=794660785481`, USERTOKEN ).then(
 
  getMethodDataUser(`/shipment-tracking?tracking_number=${data.shipment_tracking_number}`, USERTOKEN || SUPPLIERTOKEN ).then(
     (res) => {
      // console.log('shipping res status',res.status)
      // console.log('shipping res.data ',res.data)
      if(res.data.events.length===0){
        toast.warning('no data found');
      }
      else{
        setTrackingDetails(res.data.events);
      }
     }
   );
  }

  const handleDownload = (data,fileurl) => {
    const fileUrl = data;
    const fileName = fileurl;
    fileDownload(fileUrl, fileName);
  };

  return (
    <React.Fragment>
      <section className="pt-5 mt-3 pb-5">
        <div className="container">
          <Link
            className="text-decoration-none text-dark mb-sm-4 mb-3 d-block"
            to="../orders"
          >
            {"< Back"}
          </Link>
          <div className="row">
            <div className="col-lg-12 dashboard-headline">
              <h2>Order Details - #{orderDetail?.bid_id}</h2>
            
              {[orderDetail]?.order_product !== null &&
              orderDetail?.order_product?.length ? (
                orderDetail?.order_product.map((res, i) => {
                  return (
                    <React.Fragment>
                   

                      {['Cancellation Requested','Cancelled'].includes(orderDetail?.bid_order_status)
                      ?
                      <div className="steps cancle-steps t">
                        {res?.order_status_history.map((resss, index) => (
                          <>
                          {index === 0 || index === 8 || index === 9 ?
                          <div className="step mb-md-0 mb-3  ">
                          <div
                            className={`step__title tooltipzzz ${
                              resss?.is_complete === 1 ? "active" : ""
                            } `}
                          >
                           <div className="tooltipzzz">
                           {resss?.status}
                           
                           {resss?.is_complete === 1
                           ?
                              <span className="tooltiptext">
                              Date: {moment(resss?.date).format("MM/DD/yyyy")}
                              <br />
                              Time:{moment(resss?.date).format("HH:mm")}
                            </span>
                            :
                            null
                          }
                           </div>
                          </div>
                        </div>
                          :
                          null
                          }
                          </>
                        ))}
                      </div>
                        :
                        (['Return Requested','Returned'].includes(orderDetail?.bid_order_status)
                        ?
                        <div className="steps cancle-steps">
                        {res?.order_status_history.map((resss, index) => (
                          <>
                          {index === 0 || index === 10 || index === 11 ?
                          <div className="step mb-md-0 mb-3  ">
                          <div
                            className={`step__title tooltipzzz ${
                              resss?.is_complete === 1 ? "active" : ""
                            } `}
                          >
                           <div className="tooltipzzz">
                           {resss?.status}
                           
                           {resss?.is_complete === 1
                           ?
                              <span className="tooltiptext">
                              Date: {moment(resss?.date).format("MM/DD/yyyy")}
                              <br />
                              Time:{moment(resss?.date).format("HH:mm")}
                            </span>
                            :
                            null
                          }
                           </div>
                          </div>
                        </div>
                          :
                          null
                          }
                          </>
                        ))}
                      </div>
                        :
                        <div className="steps">
                        {res?.order_status_history.map((resss, index) => (
                          <div className="mb-3">
                            {index < 8 ?
                            <div className="step mb-md-0 mb-3">
                            {resss.is_complete === 1 ? (
                              <div
                                className="step__title active tooltipzzz">
                                <div className="tooltipzzz">
                                    {resss?.status}
                                    
                                    {resss?.is_complete === 1
                                    ?
                                        <span className="tooltiptext">
                                        Date: {moment(resss?.date).format("MM/DD/yyyy")}
                                        <br />
                                        Time:{moment(resss?.date).format("HH:mm")}
                                      </span>
                                      :
                                      null
                                    }
                                  </div>
                              </div>
                            ) 
                            : 
                         
                            res?.order_status_history[6].is_complete === 1 ? 
                          <>
                          
                            {  res?.order_status_history[index-1].is_complete === 1 ?

                             
                              <div className="step__title"
                              >
                                {resss?.status}
                              </div>
                              :
                              <div
                                className="step__title">
                                {resss?.status}
                              </div>}
                              
                          </> :  
                              
                              
                              res?.order_status_history[index-1].is_complete === 1
                              ?

                             
                              <div
                              className="step__title"
                              onClick={() => {
                                if(resss?.status === "Prepare Shipment"){
                                  handleShipment( orderDetail?.order_id, orderDetail?.id)
                                }
                                else{
                                  if(res.bid_part?.is_admin_sub_order === 0 && (res.bid_part?.status === "Open" ||
                                  res.bid_part?.status === "Upcoming") ){
                                  return
                                }
                                else{
                                  activeHandleShow();
                                  setstatus(resss?.status);
                                }
                                }
                                
                              }}
                            >
                              {resss?.status}
                            </div>
                              :
                              <div
                                className="step__title">
                                {resss?.status}
                              </div>
                              
                           
                              
                          }
                          </div>
                            :
                            null
                            }
                          </div>
                        ))}
                      </div>
                        
                        )
                        
                      }
                      
                      <br />
          
              
                    </React.Fragment>
                  );
                })
              ) : (
                <span className="no-data fs-1 fw-bolder text-uppercase opacity-25 text-dark">
                  No Parts Found
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-6">
                  <p>Customer Name</p>
                </div>
                <div className="col-6">
                  <p>
                    <span className="bold-text">
                      {orderDetail?.order?.customer?.first_name}{" "}
                      {orderDetail?.order?.customer?.last_name}
                    </span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>Order Id</p>
                </div>
                <div className="col-6">
                  <p>
                    <span className="bold-text">
                      #{orderDetail?.bid_id}
                    </span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>Order Date</p>
                </div>
                <div className="col-6">
                  <span className="bold-text">
                    {moment(orderDetail?.order_date).format("MM/DD/yyyy")}
                  </span>
                  <p />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-6">
                  <p>Order Amount</p>
                </div>
                <div className="col-6">
                  <span className="bold-text">
                    ${orderDetail?.supplier_bid_response?.proposal_amount}
                  </span>
                  <p />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>Order Status</p>
                </div>
                <div className="col-6">
                  <span className="bold-text">
                    {orderDetail?.bid_order_status}
                  </span>
                  <p />
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </section>
      <section className="py-5 mb-5">
        <div className="container mb-3 pb-3">
          <div className="row align-items-center">
            <div className="col-lg-12 d-flex justify-content-center justify-content-lg-start dashboard-heading-block">
              {/* <img
                src={ImageObj.PartDetail}
                width="56px"
                height="56px"
                className="mt-2 me-4 d-none d-lg-block"
              /> */}
              <div>
                <h2 className="text-center text-lg-start">Part Details</h2>
              
              </div>

           
              
            </div>
          </div>
        </div>
        <div className="container gx-0 grid-data">
          <div className="row">
            <div className="col-lg-12">
              <div className="row gx-0 grid-head-row">
                <div className="col">
                  <span className="data-value">Sr. No.</span>
                </div>
                <div className="col">
                  <span className="data-value">Part Name</span>
                </div>
                {/* <div className="col">
                  <span className="data-value">Unit Cost</span>
                </div> */}
                <div className="col">
                  <span className="data-value">Quantity</span>
                </div>
                <div className="col">
                  <span className="data-value">Action</span>
                </div>
                {/* <div className="col">
                  <span className="data-value">Price</span>
                </div> */}
                {/* <div className="col">
                  <span className="data-value">Total</span>
                </div> */}
              </div>
              {[orderDetail]?.order_product !== null &&
              orderDetail?.order_product?.length ? (
                orderDetail?.order_product.map((res, i) => {
                  return (
                    <React.Fragment>
                      <div className="row gx-0 grid-data-row">
                        <div className="col" data-label="Sr. No.: ">
                          <span className="data-value">{i + 1}</span>
                        </div>
                        <div className="col" data-label="Part Name: ">
                          <span className="data-value">{res.file_name}</span>
                        </div>
                        
                        {/* <div className="col" data-label="Unit Cost: ">
                          <span className="data-value">${res.unit_price}</span>
                        </div> */}
                        <div className="col" data-label="Quantity: ">
                          <span className="data-value">{res.qty}</span>
                        </div>
                        <div className="col" data-label="Part Name: ">

                        <FaEye
                          className="fs-5 fw-normal ms-2 pointer"
                          onClick={() => {handleviewattachment(res?.attachments)}}
                          title="Attachment Data"
                        />
                          <a
                          // target={'_blank'}
                          download
                          // onClick={()=> handleDownload(res?.auto_cad_file_path,res?.file_name)}
                          title="Download 3d File"
                          href={res?.auto_cad_file_path}
                          >
                            <FaCube className="text-primary fs-5 fw-normal ms-2 pointer" title="Download 3D File" />
                          </a>
                          

                          <FaStar className="fs-5 fw-normal ms-2 pointer" onClick={() => {handleRating(); Rating_System();}} title="View Ratings" />

                        {orderDetail.shipment_labels !== null && <a
                            target={"_blank"}
                            href={
                              JSON.parse(orderDetail?.shipment_labels).pdf
                            }
                            className="fs-5 fw-normal ms-2 pointer"
                          >
                            <FaFilePdf title="Download Shipment Details" />
                          </a>
                        }

                        {/* {orderDetail?.shipment_tracking_number != null ? 
                          <FaTruck className="fs-5 fw-normal ms-2 pointer"  
                          onClick={()=>{getTrackingData({
                            shipment_tracking_number: orderDetail?.shipment_tracking_number
                          });}} 
                          title="Track Your Order"/> :''
                        } */}

                        {res?.order_status_history?.find(state => state?.status === "Order Shipped" && state?.is_complete === 1) && (orderDetail?.shipment_tracking_number != null) ? 
                          <FaTruck className="fs-5 fw-normal ms-2 pointer"  
                          onClick={()=>{getTrackingData({
                            shipment_tracking_number: orderDetail?.shipment_tracking_number
                          });}}

                          title="Track Your Order"/> 
                          :''
                        }


                          
                        </div>
                        {/* <div className="col" data-label="Price: ">
                          <span className="data-value">${res.price}</span>
                        </div> */}
                        {/* <div className="col" data-label="Total: ">
                          <span className="data-value">${res.total_price}</span>
                        </div> */}
                      </div>
                      <br />

                      {['Cancellation Requested','Cancelled'].includes(orderDetail?.bid_order_status)
                      ?
                      <div className="steps cancle-steps">
                        {res?.order_status_history.map((resss, index) => (
                          <>
                          {index === 0 || index === 8 || index === 9 ?
                          <div className="step mb-md-0 mb-3  ">
                          <div
                            className={`step__title tooltipzzz ${
                              resss?.is_complete === 1 ? "active" : ""
                            } `}
                          >
                           <div className="tooltipzzz">
                           {resss?.status}
                           
                           {resss?.is_complete === 1
                           ?
                              <span className="tooltiptext">
                              Date: {moment(resss?.date).format("MM/DD/yyyy")}
                              <br />
                              Time:{moment(resss?.date).format("HH:mm")}
                            </span>
                            :
                            null
                          }
                           </div>
                          </div>
                        </div>
                          :
                          null
                          }
                          </>
                        ))}
                      </div>
                        :
                        (['Return Requested','Returned'].includes(orderDetail?.bid_order_status)
                        ?
                        <div className="steps cancle-steps">
                        {res?.order_status_history.map((resss, index) => (
                          <>
                          {index === 0 || index === 10 || index === 11 ?
                          <div className="step mb-md-0 mb-3  ">
                          <div
                            className={`step__title tooltipzzz ${
                              resss?.is_complete === 1 ? "active" : ""
                            } `}
                          >
                           <div className="tooltipzzz">
                           {resss?.status}
                           
                           {resss?.is_complete === 1
                           ?
                              <span className="tooltiptext">
                              Date: {moment(resss?.date).format("MM/DD/yyyy")}
                              <br />
                              Time:{moment(resss?.date).format("HH:mm")}
                            </span>
                            :
                            null
                          }
                           </div>
                          </div>
                        </div>
                          :
                          null
                          }
                          </>
                        ))}
                      </div>
                        :
                        <div className="steps">
                        {res?.order_status_history.map((resss, index) => (
                          <div className="mb-3">
                            {index < 8 ?
                            <div className="step mb-md-0 mb-3">
                            {resss.is_complete === 1 ? (
                              <div
                                className="step__title active tooltipzzz">
                                <div className="tooltipzzz">
                                    {resss?.status}
                                    
                                    {resss?.is_complete === 1
                                    ?
                                        <span className="tooltiptext">
                                        Date: {moment(resss?.date).format("MM/DD/yyyy")}
                                        <br />
                                        Time:{moment(resss?.date).format("HH:mm")}
                                      </span>
                                      :
                                      null
                                    }
                                  </div>
                              </div>
                            ) 
                            : 
                         
                          res?.order_status_history[6].is_complete === 1 ? 
                          <>
                            {  res?.order_status_history[index-1].is_complete === 1 ?
                              <div className="step__title"
                              >
                                {resss?.status}
                              </div>
                              :
                              <div
                                className="step__title">
                                {resss?.status}
                              </div>
                          }
                          
                          </> :  
                              res?.order_status_history[index-1].is_complete === 1
                              ?
                              <div
                                className="step__title"
                                onClick={() => {
                                  if(resss?.status === "Prepare Shipment"){
                                    handleShipment( orderDetail?.order_id, orderDetail?.id)
                                  }
                                  else{
                                    if(res.bid_part?.is_admin_sub_order === 0 && (res.bid_part?.status === "Open" ||
                                    res.bid_part?.status === "Upcoming") ){
                                    return
                                  }
                                  else{
                                    activeHandleShow();
                                    setstatus(resss?.status);
                                  }
                                  }
                                  
                                }}
                              >
                                {resss?.status}
                              </div>
                              :
                              <div
                                className="step__title">
                                {resss?.status}
                              </div>
                          }
                          </div>
                            :
                            null
                            }
                          </div>
                        ))}
                      </div>
                        
                        )
                        
                      }
                      
                      <br />
                      <ul className="m-0 p-0 d-flex align-items-center flex-wrap bg-white p-4 mt-3 mb-3 grey-box">
                        {res?.manufacturing_process && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">
                              Manufacturing Process{" "}
                            </small>{" "}
                            :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.manufacturing_process}
                            </p>
                          </li>
                        )}
                        {res?.custom_manufacturing_process && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">
                              Custom Manufacturing Process{" "}
                            </small>{" "}
                            :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.custom_manufacturing_process}
                            </p>
                          </li>
                        )}
                        {res?.material && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Material </small> :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.material}
                            </p>
                          </li>
                        )}
                        {res?.custom_material && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Custom Material Value </small> :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.custom_material}
                            </p>
                          </li>
                        )}
                        {res?.finish && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Coating </small> :{" "}
                            <p className="mb-0 ms-1 me-3 new"> {res?.finish}</p>
                          </li>
                        )}
                        {res?.custom_finish && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="mb-0">Custom Coating Value </small> :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.custom_finish}
                            </p>
                          </li>
                        )}
                        {res?.threads && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Threads </small> :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.threads}
                            </p>
                          </li>
                        )}
                        {res?.tolerance && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Tolerance </small> :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.tolerance}
                            </p>
                          </li>
                        )}
                        {res?.surface_roughness && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Surface Roughness </small> :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.surface_roughness}
                            </p>
                          </li>
                        )}
                        {res?.part_marking && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Part Marking </small> :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.part_marking}
                            </p>
                          </li>
                        )}
                        {res?.inspection && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Inspection </small> :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.inspection}
                            </p>
                          </li>
                        )}
                        {res?.custom_inspection && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">Custom Inspection Value </small> :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.custom_inspection}
                            </p>
                          </li>
                        )}
                        {res?.part_qualifications_certifications && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">
                              Part Quality Certifications{" "}
                            </small>{" "}
                            :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.part_qualifications_certifications}
                            </p>
                          </li>
                        )}
                        {res?.material_certifications && (
                          <li className="d-flex align-items-center me-2 mb-2">
                            <small className="me-1">
                              Material Certifications{" "}
                            </small>{" "}
                            :{" "}
                            <p className="mb-0 ms-1 me-3 new">
                              {" "}
                              {res?.material_certifications}
                            </p>
                          </li>
                        )}
                      </ul>
                      {/* <div className="m-0 p-0 bg-white p-4 mt-3 mb-3 grey-box">
                        <h5>
                          User Rating:{" "}
                          <span> {stars(res?.review?.reating)} </span>
                        </h5>
                        <div className="mt-3">
                          <h5>Reviews: </h5>
                          <p style={{ textAlign: "justify" }}>
                            {res?.review?.reviews}
                          </p>
                        </div>
                      </div> */}
                    </React.Fragment>
                  );
                })
              ) : (
                <span className="no-data fs-1 fw-bolder text-uppercase opacity-25 text-dark">
                  No Parts Found
                </span>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* send feedback */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0 pb-0  pt-3">
          <Modal.Title className="fs-4 pe-0 ps-0 pe-sm-4 ps-sm-2 ">
            Your Feedback
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0 pe-0 ps-0 pe-sm-4 ps-sm-4 ">
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Please Enter Your Feedback"
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0 pb-3 pe-0 ps-0 pe-sm-4 ps-sm-4 ">
          <button
            className="normal-height btn btn-primary-orange border-radius-0"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="normal-height btn btn-primary-orange border-radius-0"
            onClick={handleClose}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="monday-modal"
        animation={false}
        show={activeShow2}
        onHide={activeHandleClose3}
      >
        <form
          className="position-relative"
          onSubmit={handleSubmit2(ChnageStatus)}
        >
          {loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <Modal.Body>Are you sure you want to Change Order Status?</Modal.Body>
          <Modal.Footer>
            <div className="theme-btn btn-group-right text-end">
              <Button type="submit" variant="secondary">
                Yes
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={activeHandleClose3}
              >
                No
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

            {/*shipping modal */}
            <Modal
        className="shippingModal"
        animation={false}
        show={shippingModal}
        onHide={activeShippingModal}
      >
        {loading ? (
          <div className="loader-wrapper">
            <div className="loader">
              <div className="loading-svg">
                <GiAutoRepair />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <Modal.Body>
          Are you sure you want to Change Shipping Status?
        </Modal.Body>
        <Modal.Footer>
          <div className="theme-btn btn-group-right text-end">
            <Button
              type="submit"
              variant="secondary"
              onClick={() => changeShipmentStatus(shipData)}
            >
              Yes
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={() => activeShippingModal(shipData)}
            >
              No
            </Button>
          </div>
        </Modal.Footer>
      </Modal>


      {/* part attachment model */}

      <Modal
        className="status-modal"
        animation={false}
        show={mydata}
        onHide={() => {
          setmydata(!mydata);
          setAttachmentData([]);
        }}
      >
        <div className="position-relative">
          <Modal.Body>
            <div className="row text-start">
              <div className="col-12">
                <div className="row gx-0 grid-head-row mb-3">
                  <div className="col-3">
                    <span className="data-value">Sr. No.</span>
                  </div>
                  <div className="col-6">
                    <span className="data-value">File Name</span>
                  </div>
                  <div className="col-3 text-center">
                    <span className="data-value">Action</span>
                  </div>
                </div>
                <hr />
                {attachmentData.length > 0 ? (
                  attachmentData.map((item, i) => (
                    <div className="row p-0 mb-3 flex align-items-center text-start">
                      <div className="col-3" data-label="Sr. No.: ">
                        <span className="data-value">{i + 1}</span>
                      </div>
                      <div className="col-6" data-label="Image">
                        <span
                          className="data-value"
                          style={{ textAlign: "justify" }}
                        >
                          {item.split("/uploads/quote_attachments/")[1]}
                        </span>
                      </div>
                      <div className="col-3 text-center" data-label="Sr. No.: ">
                        <span className="data-value" title="Download Image">
                          <a target={"_blank"} href={item} download={item}>
                            <FaDownload />
                          </a>
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">No Other Attachments available for this Part.</div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="theme-btn btn-group-right text-end">
              <Button
                variant="primary"
                onClick={() => {
                  setmydata(!mydata);
                  setAttachmentData([]);
                }}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>


      {/* modal for tracking no  */}
      <Modal show={trackingShow} onHide={()=>setTrackingShow(!trackingShow)}>
        <Modal.Header closeButton className="border-0 pb-0  pt-3">
            <Modal.Title className="fs-5 pe-0 ps-0 pe-sm-4 ps-sm-2 ">
              Track Your Request 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0 pe-0 ps-0 pe-sm-4 ps-sm-4 ">
          {trackingDetails?.length===0 || trackingDetails=== null ? 'No updated tracking information available. Please check back.':'No updated tracking information available. Please check back.'}
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0 pb-3 pe-0 ps-0 pe-sm-4 ps-sm-4 ">
            <button
              type="button"
              className="normal-height btn btn-primary-orange border-radius-0"
              onClick={()=>setTrackingShow(!trackingShow)}>
              Close
            </button>
          </Modal.Footer>
      </Modal>


      <ToastContainer />

      <Chart 
      total_Reiview = {reviewdata} 
      communication={communication} 
      engagement={engagement}   
      quality = {quality} 
      ontime_delivery={ontime_delivery} 
      show={showrating}  
      onHide={() => setShowrating(false)} 
      />

    </React.Fragment>
  );
};

export default OrderDetails;
