
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from 'react-phone-number-input/input'
import { Button, Spinner } from 'react-bootstrap'
import FormValidation from '../../../../formValidation/formValidation'
import { Form } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { postMethodData } from '../../adminApi/api';


const AddNewCustomer = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const [value, setValue] = useState()
    const [mobileErr, setMobileErr] = useState([""])
    const [emailErr, setEmailErr] = useState([""])

    // form validation rules
    const validationSchema = Yup.object().shape({
        password: Yup.string().required("Password is required").min(6, 'Password is too short - should be 6 chars minimum.').max(18, 'Password is too long - should be 18 chars minimum.').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
            "Please Check Password Format"
        ),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match").min(6, 'Confirm password is too short - should be 6 chars minimum.').max(18, 'Password is too long - should be 18 chars minimum.')
            .required("Confirm Password is required"),
        email: Yup.string().email('The email must be a valid email. '),
        phone: Yup.string()
        .min(14)
        .required('A phone number is required'),


    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    // const dispatch = useDispatch()
    // const PORT = PORTNUMBER.PORTNUMBER

    const Token = localStorage.getItem("token")
    const navigate = useNavigate()

    const [passwordErr, setPassErr] = useState()

    const [checkedValue, setCheckedValue] = useState(true)

    const [visibility, setVisibility] = useState(true)
    const [loading, setLoading] = useState(false)


    const [visibilityConfirm, setVisibilityConfirm] = useState(true)
    const FormValidationNew = FormValidation

    const [formValidation, setFormValidation] = useState({
        Email: "",
        Mobile: "",
        default: "",
        Phone: "",
        setPassErrFormat: "",
        Success: null
    })






    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }




    const onSubmit = (data) => {
        setLoading(true)
        postMethodData("/customers/store", data, Token)
            .then((res) => {
            
                if (res?.data?.status === true) {
                    setLoading(false)
                    toast(res?.data?.message)
                    navigate("/admin/customer-management")
                    reset()
                } else {
                    setLoading(false)
                    setMobileErr(res?.data?.result?.errors?.phone)
                    setEmailErr(res?.data?.result?.errors?.email)
                    toast.error("Somthing Went Wrong!")




                }

                setLoading(false)

            }).catch((error) => {
                // console.log(error);
            })





        return false;

    };



    useEffect(() => {
        FormValidationNew()

    }, [FormValidationNew])


    return (
        <div className="tables-field">
            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="main-head">
                                <h4>Add New Customer</h4>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="theme-btn text-end top-head">
                                <Link to="/admin/customer-management" className="btn">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                        <small className=" form-text d-block text-center mb-4 text-muted">{formValidation.default}</small>
                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" maxLength="15" className="form-control" id="validationCustom01"  {...register('first_name', { required: true })} placeholder="First Name" required />
                                        <div className="invalid-feedback">
                                            Please Enter Customer First Name.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" maxLength="15" className="form-control" id="validationCustomNew"  {...register('last_name', { required: true })} placeholder="Last Name" required />
                                        <div className="invalid-feedback">
                                            Please Enter Customer Last Name.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">

                                    <div className="form-group">
                                        <input type="email" maxLength="100" className="form-control" name="email" id="validationCustomNew1"  {...register('email', { required: true })} placeholder="Email" required />
                                        {/* <p>{errors.email?.message}</p> */}
                                        <small className='text-danger'> {errors.email?.message}</small>
                                        <div className="invalid-feedback">
                                            Please Enter Valid Email Address.
                                        </div>
                                        <small className="form-text text-muted">{emailErr}</small>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <Input
                                            minLength={14}
                                            maxLength={14}
                                            name="phone"
                                            defaultCountry="US"
                                            placeholder="Enter phone number"
                                            {...register('phone', { required: true })}
                                            value={value}
                                            onChange={setValue} />
                                        {/* <input type="number" maxLength="14" className="form-control" name="phone" id="validationCustomNew1"  {...register('phone', { required: true })} placeholder="Mobile" required /> */}
                                       
                                        <small className='font-12 d-block'>E.g (xxx) xxx-xxxx , No Need for Country Code</small>
                                        <small className='text-danger'> {errors.phone?.message}</small>
                                        <small className="form-text text-muted">{mobileErr}</small>
                                        <div className="invalid-feedback">
                                            Please enter mobile number.
                                        </div>

                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <select className="form-select form-control" {...register('status', { required: false })} id="validationCustom12" aria-label="Default select example" required>
                                            <option className="disabled-value d-none" value=""  >Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                        <div className="invalid-feedback">
                                            Please select status.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" maxLength="15" className="form-control" id="validationCustomNew"  {...register('company_name', { required: true })} placeholder="Company Name" required />
                                        <div className="invalid-feedback">
                                            Please Enter Company Name.
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group icon-position">
                                        <input type={visibility ? "password" : "text"} maxLength="18" className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="password" name="password" minLength="6" {...register('password', { required: true }
                                        )} placeholder="password" required />
                                        {
                                            visibility ?
                                                <span className="icon" onClick={() => pwVisibility(false)}>
                                                    <FaEyeSlash />
                                                </span> :
                                                <span className="icon" onClick={() => pwVisibility(true)}>
                                                    <FaEye />
                                                </span>
                                        }
                                        <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>
                                        {/* <small className='text-danger'> {formValidation.setPassErrFormat}</small> */}
                                        <small className='text-danger'> {errors.password?.message}</small>
                                        {/* {errors.password?.message} */}
                                        <div className="invalid-feedback">
                                            Please enter valid password.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group icon-position password-field">
                                        <input type={visibilityConfirm ? "password" : "text"} maxLength="18" name="confirm_password" minLength="6" className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`} id="validationCustom05" {...register('confirm_password', { required: true })} placeholder="Confirm password" required />
                                        {
                                            visibilityConfirm ?
                                                <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                    <FaEyeSlash />
                                                </span> :
                                                <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                    <FaEye />
                                                </span>
                                        }
                                        <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>
                                        <small className='text-danger'> {errors.confirm_password?.message}</small>



                                        {/* <div className="invalid-feedback">{errors.confirmPassword?.message}</div> */}
                                        <div className="invalid-feedback">
                                            Please enter valid confirm password.
                                        </div>
                                        <small className=" form-text text-muted">{passwordErr}</small>
                                    </div>
                                </div>
                                
                                {['checkbox'].map((type) => (
                                    <div key={`default-${type}`} className="mb-3">
                                        <Form.Check
                                            checked={checkedValue}
                                            onClick={() => setCheckedValue(!checkedValue)}
                                            {...register('send_welcome_email', { required: false })}
                                            type={type}
                                            id={`default-${type}`}
                                            label={"Send Welcome Email To Customer With Login Credentials."}

                                        />


                                    </div>
                                ))}

                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-right">
                                        {

                                            loading === false ?
                                                <button type="submit" className="btn">Save</button> :
                                                <Button className='btn  spinner' variant='dark' disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"

                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    Please Wait...
                                                </Button>
                                        }

                                        <Link to="/admin/customer-management" className="btn">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
export default AddNewCustomer;