import React, { useState, useEffect, useCallback } from "react";
import {
  FaSearch,
  FaRegFilePdf,
  FaEye,
  FaTrashAlt,
  FaRegCalendarAlt,
  FaMapMarkedAlt,
  FaPen,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { getMethodDataUser } from "../../../../../admin/adminApi/api";
import { GiAutoRepair } from "react-icons/gi";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";

import moment from "moment";
import Pagination from "react-js-pagination";

import QuoteCollapse from "../../../../common/quoteCollapse/quoteCollapse";
import DeleteId from "../../../../common/deleteId/deleteId";
import axios from "axios";
const QoutesList = () => {
  const { register, control, handleSubmit, reset } = useForm();

  const [tableListData, setTableListData] = useState([]);
  const [Count, setCount] = useState(1);
  const [perPage, setPerPage] = useState();
  const [loading, setLoading] = useState(false);
  const [filterVisibility, setFilterVisibility] = useState(true);
  const [indexPage, setIndexPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openDelete, setOpenDelete] = useState();
  const [indexDelete, setIndexDelete] = useState();
  const USERTOKEN = localStorage.getItem("userToken");

  const popUpDetele = (index) => {
    setIndexDelete(index);
    setOpenDelete(true);
  };

  const resetTable = () => {
    reset();
    LoadTableData();
  };
  const LoadTableData = useCallback(async (pageNumber) => {
    setIndexPage(1);
    setLoading(false);
    getMethodDataUser(
      `/quotes?page=${pageNumber === 1 ? 1 : pageNumber===undefined?1:pageNumber}`,
      USERTOKEN
    ).then((res) => {
      setTableListData(res.data.result.data.data);
      setCount(res.data.result.data.total);
      setPerPage(res.data.result.data.per_page);

      setLoading(true);
      setFilterVisibility(true);
    });
  }, []);

  const sendPage = (pageNumber) => {
    getMethodDataUser(`/quotes?page=${pageNumber}`, USERTOKEN).then((res) => {
      setTableListData(res.data.result.data.data);
      setCount(res.data.result.data.total);
      setPerPage(res.data.result.data.per_page);

      setLoading(true);
    });
  };

  const handlePageChange = (pageNumber) => {
    // sendPage(pageNumber)
    // LoadTableData(pageNumber)
    if (filterVisibility === true) {
      LoadTableData(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    } else {
      sendPage(pageNumber);
      setActivePage(pageNumber);
      setIndexPage(pageNumber);
      setLoading(false);
    }
  };
  const onSubmit = useCallback(
    (data) => {
      data.from_date =
        !data.from_date ||
        data.from_date === null ||
        data.from_date === undefined
          ? ""
          : moment(data.from_date).format("DD-MM-yyyy");
      data.to_date =
        !data.to_date || data.to_date === null || data.to_date === undefined
          ? ""
          : moment(data.to_date).format("DD-MM-yyyy");

      setIndexPage(1);

      setLoading(false);

      getMethodDataUser(
        `/quotes?${new URLSearchParams(data).toString()}`,
        USERTOKEN
      ).then((res) => {
        setTableListData(res.data.result.data.data);
        setCount(res.data.result.data.total);

        // setCount(res.data.data.userCount)
        // setFilterData(res.data.data.userData)
        setLoading(true);
        setFilterVisibility(false);
      });
    },
    [register]
  );

  useEffect(() => {
    LoadTableData();
  }, []);

  const filterDataSearch = useCallback(() => {
    setIndexPage(1);

    setLoading(false);
    // sendPage()
    getMethodDataUser(`/quotes?page=1&name=${searchValue}`, USERTOKEN).then(
      (res) => {
        setTableListData(res.data.result.data.data);
        setCount(res.data.result.data.total);

        // setCount(res.data.data.userCount)
        // setFilterData(res.data.data.userData)
        setLoading(true);
        setFilterVisibility(false);
      }
    );
  }, [searchValue]);

  useEffect(() => {
    filterDataSearch();
  }, [searchValue]);
  const [modifyData, setModifyData] = useState();
  const BASE_URL = process.env.REACT_APP_BASE_URL_USER;
  useEffect(() => {
    axios.get(`${BASE_URL}/user/get-globle-config/QUOTE_MODIFY`).then((req) => {
      setModifyData(req?.data?.result?.data?.value);
      // console.log(req?.data?.result?.data?.value);
    });
  }, []);
  return (
    <React.Fragment>
      <QuoteCollapse />
      <section className="pt-5 mt-3 ">
        <div className="container dashboard-heading-block py-3">
          <div className="row align-items-center">
            <div className="col-lg-12 dashboard-headline">
              <h2>My Quotes</h2>
              <div className="row">
                <div className="col-lg-12 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-3 col-sm-6">
                        <div className="rounded rounded-pill mb-3 border border-dark search-style1 ">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <button
                                id="button-addon2"
                                className="btn btn-link"
                              >
                                <i>
                                  <FaSearch />
                                </i>
                              </button>
                            </div>
                            <input
                              type="search"
                              {...register("name", { required: false })}
                              name="name"
                              placeholder="Search Part Name"
                              aria-describedby="button-addon2"
                              className="form-control border-0 ps-2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6">
                        <div className="form-group ">
                          <select
                            className="form-select form-control rounded rounded-pill mb-3 border border-dark search-style1 "
                            {...register("status", { required: false })}
                            id="validationCustom12"
                            aria-label="Default select example"
                          >
                            <option className="disabled-value " value="">
                              All
                            </option>
                            <option value="open">Open</option>
                            <option value="closed">Closed</option>
                            <option value="expired">Expired</option>
                            <option value="request submitted">Request Submitted</option>
                            <option value="proposal submitted">Proposal Received</option>
                            <option value="proposal accepted">Proposal Accepted</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6">
                        <div className="rounded rounded-pill mb-3 border border-dark search-style1 form-search-new">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <i>
                                <FaRegCalendarAlt />
                              </i>
                            </div>
                            <div className="form-group before-con">
                              <Controller
                                control={control}
                                name="from_date"
                                render={({ field }) => (
                                  <DatePicker
                                    dateFormat="MM/dd/yyyy"
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    placeholderText="From Date"
                                    maxDate={new Date()}
                                  />
                                )}
                              />
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6">
                        <div className="rounded rounded-pill mb-3 border border-dark search-style1 form-search-new">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <i>
                                <FaRegCalendarAlt />
                              </i>
                            </div>
                            <div className="form-group before-con after">
                              <Controller
                                control={control}
                                name="to_date"
                                render={({ field }) => (
                                  <DatePicker
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="To Date"
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    maxDate={new Date()}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className=" w-50 btn btn-primary-orange m-0 min-hp-40"
                            type="submit"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            onClick={() => resetTable()}
                            className=" w-50 btn btn-primary-outline ms-2  min-hp-40"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-2 btn-align-right">
              <Link to="/create-quote" className="btn btn-primary-orange m-0">
                Request A Quote
              </Link>
            </div> */}
          </div>
        </div>
      </section>
      <section className="pb-5 pt-3 ">
        <div className="container gx-0 grid-data position-relative">
          <div className="row gx-0 grid-head-row">
            <div className="col">
              <span className="data-value">Sr. No.</span>
            </div>
            <div className="col">
              <span className="data-value">Quote ID</span>
            </div>
            <div className="col">
              <span className="data-value">No. of Parts</span>
            </div>
            <div className="col">
              <span className="data-value">Date</span>
            </div>
            {/* {modifyData === "Yes" ? null : modifyData === "No" ? ( */}
              <div className="col">
                <span className="data-value">Amount</span>
              </div>
            {/* ) : null} */}
            <div className="col">
              <span className="data-value">Status</span>
            </div>
            <div className="col">
              <span className="data-value">Action</span>
            </div>
          </div>
          {tableListData !== null && tableListData !== undefined ? (
            tableListData
              .filter((val) => {
                if (searchTerm === "") {
                  return val;
                } else if (val.status) {
                  return val;
                } else {
                  return false;
                }
              })
              .map((res, i) => {
                return (
                  <div key={i + 1} className="row gx-0 grid-data-row">
                    <div className="col" data-label="Sr. No.: ">
                      <span className="data-value">
                        {perPage * (indexPage - 1) + (i + 1)}
                      </span>
                    </div>
                    <div className="col" data-label="ID: ">
                      <span className="data-value">#{res.quote_id}</span>
                    </div>
                    <div className="col" data-label="Name: ">
                      <span className="data-value">
                        {res?.quote_parts_count}
                      </span>
                    </div>
                    <div className="col" data-label="Date: ">
                      <span className="data-value">
                        {moment(res.date).format("MM/DD/yyyy")}
                      </span>
                    </div>
                    {/* {modifyData === "Yes" ? null : modifyData === "No" ? ( */}
                      <div className="col" data-label="Amount: ">
                      {res.is_proposal_send === 1 ? (res.status === "proposal submitted" ? <Link to={`quotedetails${res.id}`} className='text-black'>View Proposal</Link>: <>${res.product_price}</>) :<>${res.product_price}</>}
                        {/* <span className="data-value">${res.product_price}</span> */}
                      </div>
                    {/* ) : null} */}
                    <div className="col" data-label="Status: ">
                      <span className="data-value text-capitalize">
                      {res.is_proposal_send === 1 ? (res.status === "proposal submitted" ? "proposal received": res.status) :res.status}
                        {/* {res.status} */}
                        {/* {
                        res?.is_manual_quote===0 ? "Pending" : 
                        res?.is_manual_quote===1 ? "Request Submitted" 
                        : res.status } */}
                      </span>
                    </div>

                    <div className="col d-flex align-items-center action justify-content-center" data-label="Action">
                      <Link
                        title="View Quote"
                        className="text-dark"
                        to={`quotedetails${res.id}`}
                      >
                        <FaEye className="fs-5 fw-normal ms-2 pointer" />
                      </Link>

                      {res.status === "open" && (
                        <React.Fragment>
                          {res.product_price != 0 && 
                          <Link
                            title="Place Order"
                            className="text-dark"
                            to={`/customer/checkout${res.id}`}
                          >
                            <FaMapMarkedAlt className="fs-5 fw-normal ms-2 pointer" />
                          </Link>
                          }
                          <span onClick={() => popUpDetele(res.id)}>
                            <FaTrashAlt className="fs-5 fw-normal ms-2 pointer" />
                          </span> 
                        </React.Fragment>
                      )}
                      {res.status === "proposal accepted" && 
                       <React.Fragment>
                       {res.product_price != 0 && 
                       <Link
                         title="Place Order"
                         className="text-dark"
                         to={`/customer/checkout${res.id}`}
                       >
                         <FaMapMarkedAlt className="fs-5 fw-normal ms-2 pointer" />
                       </Link>
                       }
                     </React.Fragment>
                     }

                      {/* <FaMapMarkedAlt className="fs-5 fw-normal ms-2 pointer" /> */}
                      {res.status === "expired" && (res?.is_manual_quote != 1) && (
                        <React.Fragment>
                          {/* <Link
                            title="View Quote"
                            className="text-dark"
                            to={`quotedetails${res.id}`}
                          >
                            <FaEye className="fs-5 fw-normal ms-2 pointer" />
                          </Link> */}
                          <span onClick={() => popUpDetele(res.id)}>
                            <FaTrashAlt className="fs-5 fw-normal ms-2 pointer" />
                          </span>
                        </React.Fragment>
                      )}
                      {/* {res.status === "closed" && (
                        <Link
                          title="View Quote"
                          className="text-dark"
                          to={`quotedetails${res.id}`}
                        >
                          <FaEye className="fs-5 fw-normal ms-2 pointer" />
                        </Link>
                      )} */}

                      {res.details_pdf !== null && (
                        <a
                          href={res.details_pdf}
                          title={res.details_pdf !== null ? "Download Quote" : ""}
                          target="_blank"
                        >
                            <FaRegFilePdf className="fs-5 fw-normal ms-2 pointer" />
                        </a>
                      )}

                    </div>
                  </div>
                );
              })
          ) : (
            <div className="no-data fs-1 fw-bolder text-center text-uppercase opacity-25 text-dark">
              No Quotes Found
            </div>
          )}
          {!loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {Count > perPage && (
          <div className="container pe-0 ps-0">
            <div className="pagination">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={perPage}
                totalItemsCount={Number(Count)}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </section>
      <DeleteId
        index={indexDelete}
        api={"/quotes/delete/"}
        toggel={openDelete}
        setopen={setOpenDelete}
        renderapi={LoadTableData}
      />
    </React.Fragment>
  );
};

export default QoutesList;
