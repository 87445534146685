import {PENDING_VALUE} from '../types/type'





const intialState  = null

 const pendingValueReducers = (state = intialState, action)=>{
    switch(action.type){
      case PENDING_VALUE: return state = action.payload
        default:
        return state;
    }
    
  };


  export default pendingValueReducers

