import React from 'react'
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

const BarChart =({chartData})=>{
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' ,
          },
          title: {
            display: true,
            text: chartData?.option_text,
          },
        },
      };

    const labels = chartData?.labels;
    const data = {
      labels: labels,
      datasets: [{
        label: chartData?.label,
        data:chartData?.data,
        backgroundColor: chartData?.backgroundColor,
  
        borderWidth: 1
      }]
    };
    return(
        <Bar options={options} data={data} />
    )
}

export default BarChart