import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { GiAutoRepair } from "react-icons/gi";
import { Button, Spinner, Modal } from "react-bootstrap";

// icons
import { FaFileExcel, FaSearch , FaDollarSign } from "react-icons/fa";
import Pagination from "react-js-pagination";

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";

// react bootstrap

import ChangePassword from "../../common/changePassword/changePassword";
import { getMethodData, getSubmitData } from "../../adminApi/api";
import SendEmail from "../../common/sendEmail/sendEmail";
import { addPendingValue } from "../../../../redux/actions/action";
import { useDispatch } from "react-redux";

const SupplierTransaction = (props) => {
  let showDataToUser = false;
  const [markpermission, setmarkpermission] = useState(false);
  const [redirection,setRedirection] = useState(false);
    useLayoutEffect(() => {
      const ls = JSON.parse(localStorage.getItem("adminDetails"));
      // console.log("uselayout", JSON.parse(localStorage.getItem("adminDetails")));
  
      for (let i = 0; i <= ls?.my_permissions?.length; i++) {
        if (ls?.my_permissions[i]?.name === "Supplier Transaction") {
          for (let j = 0; j <= ls?.my_permissions[i]?.permissions?.length; j++) {
            if (
              ls?.my_permissions[i]?.permissions[j]?.name === 'List'
            ) {
              // console.log(ls?.my_permissions[i]?.permissions[j].name);
              showDataToUser = true;
             
            }
            if (
              ls?.my_permissions[i]?.permissions[j]?.name === "Mark Paid"
            ) {
              // console.log(ls?.my_permissions[i]?.permissions[j].name);
              setmarkpermission(true)
            }
          }
        }
      }
      // console.log(showDataToUser);
      if(showDataToUser===false){
        // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
    }, []);
    const navigate = useNavigate();
    if (redirection) navigate('/admin/dashboard');
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const dispatch = useDispatch();

  // const PORT = PORTNUMBER.PORTNUMBER
  const Token = localStorage.getItem("token");
  const [showPassword, setShowPassword] = useState(false);
  // modal active

  // // console.log(OrderIdnew)
  const [customerId, setCustomerId] = useState();

  const [loading, setLoading] = useState(false);

  const [ExlFilter, setExlFilter] = useState({
    page: "",
    transaction_id: "",
    from_date: "",
    payment_status: "",
    payment_method: "",
    export_in_excel: "1",
  });

  // const [Loading, setLoading] = useState(false);

  const [order, setOrder] = useState([]);
  const [Count, setCount] = useState(1);
  const [perPage, setPerPage] = useState();

  const [indexPage, setIndexPage] = useState(1);
  const [loadingButton, setLoadingButton] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [activeShow3, setActiveShow3] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // const [filterData, setFilterData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [filterVisibility, setFilterVisibility] = useState(true);

  const { register, control, handleSubmit, reset } = useForm();
  
  const { register:register3, control:control3, handleSubmit: handleSubmit3, reset:reset3 } = useForm();

  const {
    register: register2,
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm();
 
  const [paymentdta,setpaymentdta] = useState({
    order_id:'',
  })

  const dNone = "d-none";

  const activeHandleClose3 = () => {
    reset3()
    setActiveShow3(false)
    
};
const activeHandleShow3 = () => {
  if(markpermission === true){
    setActiveShow3(true);
  }else{
    alert('permission not allowed')
  }  
}

  const sendPageFilter = (pageNumber) => {
    // // console.log("filterData", filterData)
    // alert(1)
    getSubmitData(
      `${props.filterApi}?page=${pageNumber}&${props.searchId}=${id}`,
      Token
    ).then((res) => {
      // // console.log("redsd", res)
      setOrder(res.data.result.data.data);
      setCount(res.data.result.data.total);
      setLoading(true);
    });
  };
  const LoadTableData = useCallback(
    async (pageNumber) => {
      setIndexPage(1);
      setLoading(false);
      await axios
        .get(
          `${BASE_URL}/payments?page=${String(
            pageNumber === 1 ? 1 : pageNumber
          )}`,
          { headers: { authorization: `Bearer ${Token}` } }
        )
        .then((res) => {
          // console.log("payments", res);
          setOrder(res.data.result.data.data);
          setCount(res.data.result.data.total);
          setPerPage(res.data.result.data.per_page);

          setLoading(true);
          setFilterVisibility(true);
        });

      reset();
    },
    [BASE_URL, Token, reset],
    [order, Count]
  );

  const sendPage = (pageNumber) => {
    // // console.log("filterData", filterData)
    axios
      .get(
        `${BASE_URL}/payments?page=${pageNumber}&${new URLSearchParams(
          filterData
        ).toString()}`,
        { headers: { authorization: `Bearer ${Token}` } }
      )
      .then((res) => {
        // // console.log("redsd", res)
        setOrder(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);

        setLoading(true);
      });
  };

  const handlePageChange = (pageNumber) => {
    // sendPage(pageNumber)
    // LoadTableData(pageNumber)
    if (id?.length) {
      sendPageFilter(pageNumber);
      // // console.log("1")
    } else {
      if (filterVisibility === true) {
        LoadTableData(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
        setLoading(false);
        // // console.log("2")
      } else {
        sendPage(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
        setLoading(false);
        // // console.log("3")
      }
    }
  };

  useEffect(() => {
    sendPageFilter();

    if (id) {
      sendPageFilter();
    } else {
      sendPage();
      LoadTableData();
    }
  }, []);

  const onSubmit = async (data) => {
    setExlFilter({
      page: 1,
      transaction_id: data.transaction_id,
      from_date: data.from_date,
      payment_status: data.payment_status,
      payment_method: data.payment_method,
      export_in_excel: "1",
    });
    dispatch(addPendingValue(false));
    if (id?.length) {
      navigate("/admin/supplier-transaction");
    }
    // console.log("filterdata");
    setLoadingButton(true);
    setIndexPage(1);
    setFilterData(data);
    // // console.log("DATA", data)
    data.from_date =
      !data.from_date || data.from_date === null || data.from_date === undefined
        ? ""
        : moment(data.from_date).format("YYYY/MM/DD");
    data.to_date =
      !data.to_date || data.to_date === null || data.to_date === undefined
        ? ""
        : moment(data.to_date).format("YYYY/MM/DD");
    setLoading(false);
    // sendPage()
    await axios
      .get(
        `${BASE_URL}/payments?page=${1}&${new URLSearchParams(
          data
        ).toString()}`,
        { headers: { authorization: `Bearer ${Token}` } }
      )
      .then((res) => {
        setLoadingButton(false);
        setOrder(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setLoading(true);
        setFilterVisibility(false);
      });
    setLoadingButton(false);
  };

  useEffect(() => {
    LoadTableData();
  }, []);


  // download excel
  // const downloadXLSFile = async (pageNumber) => {
  //   axios({
  //     url: `${BASE_URL}/payments?page=${String(
  //       pageNumber === 1 ? 1 : pageNumber
  //     )}&transaction_id=${ExlFilter.transaction_id ?? ""}&transactons_date=${
  //       ExlFilter.from_date ?? ""
  //     }&status=${ExlFilter.payment_status ?? ""}&payment_method=${
  //       ExlFilter.payment_method ?? ""
  //     }&export_in_excel=${ExlFilter.export_in_excel ?? "1"}`,
  //     method: "GET",
  //     responseType: "blob", // important
  //     headers: { authorization: `Bearer ${Token}` },
  //   }).then((response) => {
  //     // create file link in browser's memory
  //     const href = URL.createObjectURL(response.data);

  //     // create "a" HTLM element with href to file & click
  //     const link = document.createElement("a");
  //     link.href = href;
  //     link.setAttribute(
  //       "download",
  //       `Transactions_${moment(new Date()).format("MMDDYYYY")}.xls`
  //     ); //or any other extension
  //     document.body.appendChild(link);
  //     link.click();

  //     // clean up "a" element & remove ObjectURL
  //     document.body.removeChild(link);
  //     // URL.revokeObjectURL(url);
  //   });
  // };



  const AddPayment = () => {
    setLoading(false)
    getMethodData(`/payments/mark-to-paid/${paymentdta.order_id}`,Token)
    .then((res) => {
        // // console.log(res)
        if(res.data.status == true){
            LoadTableData()
            activeHandleClose3()
            toast.success(res.data.message)
            setLoading(true)
        }
        else{
            activeHandleClose3()
            setLoading(true)
            toast.warning(res.data.message)
        }
    })
}
const [todayDate,setTodayDate]=useState(null);
  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="main-head">
                <h4>Supplier Transaction</h4>
              </div>
            </div>
            {/* <div className="col-6">
                            <div className="theme-btn top-head text-end">
                                <Link to="#" className="btn">Add New Order</Link>
                            </div>
                        </div> */}
          </div>
        </div>
        <div className="table-order-box shadow rounded overflow-hidden">
          <div className="tab-head">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      {...register("order_id", { required: false })}
                      className="form-control"
                      placeholder="Order ID"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      {...register("bid_id", { required: false })}
                      className="form-control"
                      placeholder="Bid ID"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      {...register("supplier_name", { required: false })}
                      className="form-control"
                      placeholder="Supplier Name"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <select
                      className="form-select form-control"
                      {...register("supplier_paid", { required: false })}
                      aria-label="Default select example"
                    >
                      <option value="" className="d-none" selected>
                        Payment Status
                      </option>
                      <option value="">All</option>
                      <option value="Paid">Paid</option>
                      <option value="Unpaid">Unpaid</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group before-con">
                    <Controller
                      control={control}
                      name="from_date"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Click to select a date"
                          onChange={(date) => {
                            field.onChange(date);
                            setTodayDate(date);}}
                          selected={field.value}
                          maxDate={new Date()}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group before-con after">
                    <Controller
                      control={control}
                      name="to_date"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Click to select a date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          minDate={todayDate==null? new Date() : todayDate}
                          maxDate={new Date()}
                        />
                      )}
                    />
                  </div>
                </div>
                
                <div className="col-lg-12">
                  <div className="theme-btn btn-group-right text-end">
                    {loadingButton === false ? (
                      <button type="submit" className="btn">
                        Search
                      </button>
                    ) : (
                      <Button className="btn  spinner" variant="dark" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                        />
                        Searching...
                      </Button>
                    )}
                    <p
                      type="reset"
                      onClick={() => LoadTableData()}
                      className="btn"
                    >
                      Reset
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="project-table theme-btn">
        {/* <button className="btn" onClick={() => downloadXLSFile()}>
          Export to Excel <FaFileExcel />
        </button> */}
        <div className="form-search float-end">
          <form>
            <div className="form-group icon-input top-search">
              <input
                type="text"
                id="searchForm2"
                className="form-control"
                onChange={(event) => { setSearchTerm(event.target.value) }}
                placeholder="Search"
              />
              <FaSearch />
            </div>
            <input type="btn" className="d-none" value="Submit" />
          </form>
        </div>
        <div className="table-order shadow">
          <table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th>Order ID</th>
                <th>Bid ID</th>
                <th>Order Amount</th>
                <th>Supplier offer</th>
                <th>Supplier Name</th>
                <th>Order Date</th>
                <th>Delivered Date</th>
                <th>DUE DATE</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {order && order.length ? ( order.filter((val) => {
                  const date = val.order?.date
                  const date2 = val.order?.order_delivered_date
                    if (searchTerm === "") 
                    {
                      return val;
                    } 
                    else if (val.order?.order_id !== null && val.order?.order_id !== undefined && val.order?.order_id.toString().toLowerCase().includes(searchTerm.toLowerCase())
                    ) 
                    {
                      return val;
                    } 
                    else if (val.order_bid?.bid_id !== null && val.order_bid?.bid_id !== undefined &&  val.order_bid?.bid_id.toString().toLowerCase().includes(searchTerm.toLowerCase())
                    ) 
                    {
                      return val;
                    } 
                    else if (val.order?.final_amt !== null && val.order?.final_amt !== undefined && val.order?.final_amt.toString().toLowerCase().includes(searchTerm.toLowerCase())
                    ) 
                    {
                      return val;
                    } 
                    else if (val.supplier_price !== null && val.supplier_price !== undefined && val.supplier_price.toString().toLowerCase().includes(searchTerm.toLowerCase())
                    ) 
                    {
                      return val;
                    } 
                    else if (val.supplier?.first_name !== null && val.supplier?.first_name !== undefined && val.supplier?.first_name.toString().toLowerCase().includes(searchTerm.toLowerCase())
                    ) 
                    {
                      return val;
                    } 
                    else if (val.supplier?.last_name !== null && val.supplier?.last_name !== undefined && val.supplier?.last_name.toString().toLowerCase().includes(searchTerm.toLowerCase())
                    ) 
                    {
                      return val;
                    } 
                    else if (date !== null && date !== undefined && moment(date).format("MM/DD/yyyy").toLowerCase().includes(searchTerm.toLowerCase())
                    ) 
                    {
                      return val;
                    } 
                    else if (date2 !== null && date2 !== undefined && moment(date2).format("MM/DD/yyyy").toLowerCase().includes(searchTerm.toLowerCase())
                    ) 
                    {
                      return val;
                    } 
                    
                    else {
                      return false;
                    }

                  })
                  
                  .map((order, index) => (
                    <tr key={order.id}>
                      <td className="s-no" scope="row">
                        {perPage * (indexPage - 1) + (index + 1)}
                      </td>
                      <td>#{order?.order?.order_id}</td>
                      <td>#{order?.order_bid?.bid_id}</td>
                      <td>$ {order?.custoemr_price}</td>
                      <td>$ {order?.supplier_price}</td>
                      <td>{order?.supplier?.first_name} {order?.supplier?.last_name}</td>
                      {/* <td>{moment(order?.order?.date).format("MM/DD/yyyy")}</td> */}

                      <td>
                        {moment(order.order?.date).format("MM/DD/yyyy")}
                      </td>

                     <td>{order?.order_bid?.delivered_date == null ? '-'
                    :
                    moment(order?.order_bid?.delivered_date).format("MM/DD/yyyy")}</td>
                       <td>{order?.payment_due_date !== null ? 
                     moment(order?.payment_due_date).format("MM/DD/yyyy")
                    :
                    '-'}</td>



                      {order?.supplier_paid == "0" ?    <td><span className="badge bg-success inactive">Unpaid</span></td>:<td><span className="badge bg-success">Paid</span></td>}
                   
                      <td className="">
                        {order?.supplier_paid == "0" && (order?.order_bid?.delivered_date != null) ?  
                      
                          <span title='Mark Paid'
                        onClick={(e) => {
                        // setOrderIdnew(order.id);
                        activeHandleShow3();
                        setpaymentdta({order_id:order?.id})}}
                          className="icons-action mb-0"><FaDollarSign /></span>
                    
                        : ''  
                        }
                       
                     
                      </td>
                      {/* <td className="action-top">
                                        <Link title='View Order' to={`${order.id}`} className="icons-action"><FaEye /></Link>
                                        <span title='Add Payment' 
                                        onClick={() => {
                                            activeHandleShow(order.id, order.status);
                                            setStatusType(order.status);
                                            setStatusId(order.id)}
                                            } 
                                            className={`text-success ${order.status}`}><FaDollarSign />
                                        </span>
                                    </td> */}
                    </tr>
                  ))
              ) : (
                <tr>
                  <td className="no-data">NO TRANSACTIONS FOUND</td>
                </tr>
              )}
            </tbody>
          </table>
          {!loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
        </div>
        <div className="pagination">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={perPage}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        </div>
          {/* active modal */}
            <Modal size="md" className="payment-model" animation={false} show={activeShow3} onHide={activeHandleClose3}>
                <form className='position-relative' onSubmit={handleSubmit3(AddPayment)}>
                {
                    !loading ?
                        <div className="loader-wrapper">
                            <div className="loader">
                                <div className="loading-svg">
                                    <GiAutoRepair />
                                </div>
                            </div>
                        </div> : ""
                }
                <Modal.Body>
                <h4 className="text-center"><strong>Are you sure want to Mark Paid ?</strong></h4>
                </Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-end">
                        <Button type='submit' variant="secondary">
                            Yes
                        </Button>
                        <Button type='button' variant="primary" onClick={activeHandleClose3}>
                            No
                        </Button>
                    </div>
                </Modal.Footer>
                </form>
            </Modal>
      </div>

      <ToastContainer />
      <ChangePassword
        api={`/customers/change-password/${customerId}`}
        show={showPassword}
        dNone={dNone}
        onHide={() => setShowPassword(false)}
      />
    </div>
  );
};
export default SupplierTransaction;
