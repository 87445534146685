import React, { useEffect, useState } from "react";
// import { ImageObj } from '../../../assets/images/imgeObject'
import { useNavigate, useLocation, Navigate, Link } from "react-router-dom";
// import loginAPI from "../../api/api";

import { useForm } from "react-hook-form";
import axios from "axios";
import { Button, Spinner } from "react-bootstrap";

// import { toasterValue } from '../../../redux/actions/action'
// import { useDispatch } from 'react-redux';

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  authHocTrueValue,
  adminDataValue,
} from "../../../../../redux/actions/action";
import { useDispatch } from "react-redux";

const Login = ({ isAuthedAdmin }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const dispatch = useDispatch();
  const [errLogin, setErrLogin] = useState();
  const [errPass, setErrPass] = useState();
  const [visibility, setVisibility] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit } = useForm();

  const pwVisibility = (value) => {
    setVisibility(value);
  };

  useEffect(() => {
    dispatch(authHocTrueValue(true));
    const Auth2 = localStorage.getItem("token");

    if (Auth2 !== null) {
      navigate("/admin/dashboard");
    }
  }, []);

  useEffect(() => {
    document.body.classList.add("remove-header");
    return () => {
      document.body.classList.remove("remove-header");
    };
  }, []);

  useEffect(() => {
    document.body.classList.add("remove-user");

    return () => {
      document.body.classList.remove("remove-user");
    };
  }, []);

  // useEffect(() => {
  //     const Auth1 = localStorage.getItem("username");
  //     const Auth2 = localStorage.getItem("password");

  //     if (Auth1 !== null && Auth2 !== null) {
  //         return <Navigate to="/" />
  //     } else {
  //         return <Navigate to="/dashboard" />
  //     }
  // },[])

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${BASE_URL}/login`, { email: data.email, password: data.password })
      .then((res) => {
        if (res.data.status !== true ) {
          setLoading(false);
          setErrPass(res.data.message);
          toast("You Cannot Login.");
          
       
        } else {
          if( res.data.result.data.user.status !== "active"){
            setLoading(false);
            setErrLogin("");
            setErrPass("");
            toast("You Cannot Login.");
      }
      else{
           // localStorage.setItem("username", data.email);
           localStorage.setItem(
            "firtName",
            res.data.result.data.user.first_name
          );
          // sending value to redux store of admin login
          // console.log("line 100 ", res.data);
          dispatch(adminDataValue(res.data.result));
          localStorage.setItem("adminDetails", JSON.stringify(res.data.result));
          //sending value to redux ends here
          localStorage.setItem("token", res.data.result.data.token);
          toast("You have logged in successfully.");
          isAuthedAdmin();
          navigate("dashboard");
      }
       
        }
      });
  };

  return (
    <div className="container">
      <div className="max-new-second login">
        <div className="main-theme bg-theme border otp-pad new shadow">
          <section className="select-state">
            <div className="state-bor">
              <div className="log-in otp-num">
                <div className="select-steps-box">
                  <div className="input-head">
                    <h2>Welcome Back</h2>
                  </div>
                </div>
                <div className="select-box-top pr-0">
                  <div className="input-box">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                {...register("email", { required: true })}
                                placeholder="Enter Email"
                                required
                              />
                              <small
                                id="emailHelp"
                                className=" form-text text-muted"
                              >
                                {errLogin}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group icon-position">
                              <input
                                type={visibility ? "password" : "text"}
                                className="form-control"
                                {...register("password", { required: true })}
                                placeholder="Enter Password"
                                required
                              />
                              {visibility ? (
                                <span
                                  className="icon"
                                  onClick={() => pwVisibility(false)}
                                >
                                  <FaEyeSlash />
                                </span>
                              ) : (
                                <span
                                  className="icon"
                                  onClick={() => pwVisibility(true)}
                                >
                                  <FaEye />
                                </span>
                              )}
                              <small
                                id="emailHelp"
                                className="  form-text text-muted"
                              >
                                {errPass}
                              </small>
                              <p className="resend new mt-2">
                                <Link to="resetmail">Forgot Password?</Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="theme-btn select-m">
                        {loading === false ? (
                          <button className="btn verfy-btn" type="submit">
                            LOGIN <span></span>
                          </button>
                        ) : (
                          <Button
                            className="verfy-btn spinner"
                            variant="dark"
                            disabled
                          >
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Sign in...
                          </Button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Login;
