import React, { useEffect, useState } from 'react'
// import { ImageObj } from '../../../assets/images/imgeObject'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import axios from 'axios'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import FormValidation from '../../../../../../formValidation/formValidation';

import { Modal } from 'react-bootstrap';
import { modalToggelValue } from '../../../../../../redux/actions/action';
import { useDispatch } from 'react-redux';
// import RESETPASSWORD_API from '../../../views/api/api'



const ResetPassword = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ConfirmErr, setConfErr] = useState()
    const { id } = useParams();
    const BASE_URL_USER = process.env.REACT_APP_BASE_URL_USER;
    const [passErr, setPassErr] = useState();



    const [visibilityNew, setVisibilityNew] = useState(true)

    const [visibilityConfirm, setVisibilityConfirm] = useState(true)

    const Token = localStorage.getItem("token")


    const pwVisibilityNew = (value) => {
        setVisibilityNew(value)
    }

    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }


    const validationSchema = Yup.object().shape({
        password: Yup.string().min(6, 'Password is too short - should be 6 chars minimum.').max(18, 'Password is too long - should be 18 chars minimum.').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
            "Please Check Password Format"
        ) .required("Password is required"),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match").min(6, 'Confirm password is too short - should be 6 chars minimum.').max(18, 'Password is too long - should be 18 chars minimum.')
            .required("Confirm Password is required"),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const FormValidationNew = FormValidation

    const resetLink = (data) => {

        axios.post(`${BASE_URL_USER}/user/update-reset-password`, { password: data.password, token: id })
            .then((res) => {
        
                if (res.data.status === true) {
                    toast.success("Password Is Changed Successfully.")
                    dispatch(modalToggelValue(true))
                    navigate("/")

                }


            })




    }
    //  useEffect(() => {
    //     reset()
    // }, [props])

    useEffect(() => {
        FormValidationNew()
    }, [FormValidationNew])


    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <div className="modal-content rounded-0 position-relative">
               
                <div className="sign-in">
                    <div className="input-head">
                        <h3 className='mb-3'>Reset Password</h3>
                    </div>
                
                <div className="select-box-top pr-0">
                    <div className="input-box">
                        <form onSubmit={handleSubmit(resetLink)} className="needs-validation" noValidate>
                            <div className="col-12 p-0">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group icon-position">
                                            <input type={visibilityNew ? "password" : "text"} maxLength="18" className="form-control" id="validation2"  {...register('password', { required: false })} placeholder="New Password" required />
                                            {
                                                visibilityNew ?
                                                    <span className="icon" onClick={() => pwVisibilityNew(false)}>
                                                        <FaEyeSlash />
                                                    </span> :
                                                    <span className="icon" onClick={() => pwVisibilityNew(true)}>
                                                        <FaEye />
                                                    </span>
                                            }
                                            <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>
                                            <small className='text-danger'> {errors.password?.message}</small>

                                            <div className="invalid-feedback">
                                                Please Enter  Password.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group icon-position">
                                            <input type={visibilityConfirm ? "password" : "text"} maxLength="18" className="form-control" id="validation3" {...register('confirm_password', { required: false })} placeholder="Confirm Password" required />
                                            {
                                                visibilityConfirm ?
                                                    <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                        <FaEyeSlash />
                                                    </span> :
                                                    <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                        <FaEye />
                                                    </span>
                                            }
                                            <small className='font-12 d-block'>(   6 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter  )</small>
                                            <small className='text-danger'> {errors.confirm_password?.message}</small>
                                            <div className="invalid-feedback">
                                                Please Enter Confirm Password.
                                            </div>
                                            <small id="emailHelp" className=" form-text text-muted">{passErr}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" className="btn btn-primary-orange m-auto mb-3" >Reset Password</button>
                            <p className="resend new">Have an Account? <span><Link className='user-text' onClick={()=>dispatch(modalToggelValue(true))} to="/">Login <i className="fas fa-angle-right " aria-hidden="true" /></Link></span></p>

                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </Modal>

    )
}

export default ResetPassword;