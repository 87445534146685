
import React, { useState, useEffect, useCallback } from 'react'
import { Link } from "react-router-dom"
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from 'react-phone-number-input/input'
import { Button, Spinner, Modal } from 'react-bootstrap'

import { Form } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";

import { getMethodDataSupplier, getMethodDataUser, postMethodData, postMethodDataUser } from '../../../../../admin/adminApi/api';
import LoginUser from '../loginUser/loginUser';
import { modalToggelValue, modalRgisterlValue, modalConditionValue, modalPolicyValue } from '../../../../../../redux/actions/action';
import { useDispatch, useSelector } from 'react-redux';

import FormValidation from '../../../../../../formValidation/formValidation';
import Multiselect from "multiselect-react-dropdown";

const RegisterSupplier = (props) => {
    const dispatch = useDispatch()
    const [value, setValue] = useState()
    const [mobileErr, setMobileErr] = useState([""])
    const [userData, setUserData] = useState([""])

    // const [tolleranceData,setTolleranceData]=useState([]);
    // const [surfaceRoughness ,setSurfaceRoughness]=useState([]);

    const [emailErr, setEmailErr] = useState([""])
    const [modalLoginShow, setModalLoginShow] = React.useState(false);
    const USERTOKEN = localStorage.getItem("userToken")
    const SupplierValue = useSelector((state) => state.modalRegisterSuppReducer)

    
    // form validation rules
    const validationSchema = Yup.object().shape({


        zipcode: Yup.string()
            .min(5)
            .required('A zipcode is required')
            .max(5),

            image: Yup.mixed().required('Image file is required')
            .test("type", "Only .pdf Formate is accepted", (value) => { 
                return value && (
                value[0].type === 'application/pdf'
                );
            })
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    // const dispatch = useDispatch()
    // const PORT = PORTNUMBER.PORTNUMBER

    const Token = localStorage.getItem("token")
    const navigate = useNavigate()

    const [passwordErr, setPassErr] = useState()

    const [checkedValue, setCheckedValue] = useState(true)
    const [manufacturing_process, setmanufacturing_process] = useState([]);
    const [tolerance, settolerence] = useState([]);
    const [Surface_roughness, setSurface_roughness] = useState([]);
    const [Inspection, setInspection] = useState([]);
    const [qualifications, setqualifications] = useState([]);
    const [Material , setMaterial ] = useState([]);
    const [Coating, setCoating] = useState([]);


    const [inspectionerr, setinspectionerr] = useState("")
    const [manufacturingerr, setmanufacturing2err] = useState("")
    const [materialerr, setmaterialerr] = useState("")
    const [part_qualificationserr, setpart_qualificationserr] = useState("")
    const [surface_roughnesserr , setsurface_roughnesserr  ] = useState("")
    const [tolerance_newerr    , settoleranceerr  ] = useState("")
    const [coatingerr, setcoatingerr] = useState("")



    const [visibility, setVisibility] = useState(true)
    const [loading, setLoading] = useState(false)


    const [visibilityConfirm, setVisibilityConfirm] = useState(true)
    const [capabilityData, setCapabilityData] = useState([])

    const FormValidationNew = FormValidation

    const [formValidation, setFormValidation] = useState({
        Email: "",
        Mobile: "",
        default: "",
        Phone: "",
        setPassErrFormat: "",
        Success: null
    })


    useEffect(() => {
        setValue("+1" + String(props.userdata.phone).replace(/[()\ \s-]+/g, ''))
    }, [props])





    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }


    useEffect(() => {
    
        setMobileErr("")
        setEmailErr("")
     

    }, [props])


    useEffect(() => {
        reset()
        setValue("")
    }, [SupplierValue])

    const TechnicalCapabilities = (e) => {
        getMethodDataSupplier(`/get-technical-capabilities`, USERTOKEN).then((res)=>{
            setCapabilityData(res.data.result.data)
            setInspection(...res?.data?.result?.data?.inspection[0])
            //// console.log(setCapabilityData,"setcapa")
        })
    }

    useEffect(()=>{
        TechnicalCapabilities()
    },[])


    const onSubmit = (data) => {

      console.log("====>>>>>>",manufacturing_process.length)

      if (manufacturing_process.length === 0) {
      
        // alert("Please select values");
        setmanufacturing2err("Please select Manufacturing Process")
        // return;
      }
      if(Surface_roughness.length === 0){
        setsurface_roughnesserr("Please select Surface Roughness")
        // alert("Please select values");
        // return;
      }
      if(tolerance.length === 0){
        settoleranceerr("Please select Tolerance")
        // alert("Please select values");
        // return;
      }
      if(Inspection.length === 0){
        setinspectionerr("Please select Inspection")
        // alert("Please select values");
        // return;
      }
      if(qualifications.length === 0){
        setpart_qualificationserr("Please select Part Quality Certifications")
        // alert("Please select values");
        // return;
      }
      if(Material.length === 0){
        setmaterialerr("Please select Part Material Certifications")
        // alert("Please select values");
        // return;
      }
      if(Coating.length === 0){
        setcoatingerr("Please select Coating")
        // alert("Please select values");
        // return;
      }

      if(manufacturing_process.length === 0 || Surface_roughness.length === 0 || tolerance.length === 0 || Inspection.length === 0 || qualifications.length === 0 || Material.length === 0 || Coating.length === 0){
        return;
      }


        setLoading(true)
        var inputs = document.getElementsByClassName('form-control');
        const imageFiles = document.getElementById('document').files.length;
        const fd = new FormData();
        for (let i = 0; i < inputs.length; i++) {
            fd.append(inputs[i].name, inputs[i].value);
            // // console.log("gfggg", inputs[i].name, inputs[i].value)
        }
        if (imageFiles > 0) {
            const File = document.getElementById('document').files[0];
            fd.append("w9document", File);
        }
        fd.append("manufacturing_process", manufacturing_process);
        fd.append("surface_roughness", Surface_roughness);
        fd.append("tolerance", tolerance);
        fd.append("inspection", Inspection);
        fd.append("part_qualifications_certifications", qualifications);
        fd.append("material_certifications", Material);
        fd.append("coatings", Coating);
        // fd.append("manufacturing_process", manufacturing_process);
        postMethodDataUser("/register-as-supplier", fd, USERTOKEN)
            .then((res) => {
        
                if (res?.data?.status === true) {
                    setLoading(false)
                    toast(res?.data?.message)
                    props.onHide()
                    // window.location.reload();

                    // navigate("/admin/supplier-management")
                    reset()
                    setmanufacturing_process([]);
                    setSurface_roughness([]);
                    settolerence([]);
                    setInspection([]);
                    setqualifications([]);
                    setMaterial([]);
                    setCoating([]);

                } else {
                    setLoading(false)
                    setMobileErr(res?.data?.result?.errors?.phone)
                    setEmailErr(res?.data?.result?.errors?.email)
                    toast.error(res?.data?.result?.errors?.email[0])
                    toast.error(res?.data?.result?.errors?.phone[0])
                    toast.error(res?.data?.message)
                   
                    




                }

                setLoading(false)


            }).catch((error) => {
              props.onHide()
              setLoading(false);
              reset()
                // console.log(error);
                setmanufacturing_process([]);
                    setSurface_roughness([]);
                    settolerence([]);
                    setInspection([]);
                    setqualifications([]);
                    setMaterial([]);
                    setCoating([]);
            })

        return false;

    };

    useEffect(() => {
        FormValidationNew()
    }, [FormValidationNew, formOptions,manufacturing_process,Surface_roughness,tolerance,Inspection,qualifications,Material])

    const dispatchToggle = () => {
        dispatch(modalToggelValue(true))
        dispatch(modalRgisterlValue(false))
    }

    const removePlayer = async (arr, element) => {
        for (let i = 0; i < arr.length; i++) {
            if(arr[i] == element) {
                arr.splice(i, 1);
                // console.log(arr)
                // setmanufacturing_process(arr);      
                break;
            }
        }
    }

    return (
        <React.Fragment>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <div className="modal-content">
                    <div className="create-account">
                        <div className="row">
                            <div className="col-md-12 position-relative;">
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => props.onHide()}
                                />
                                <h3 className="text-center m-0 py-3 text-capitalize">
                                   Hi, {props.userdata.first_name} {props.userdata.last_name}
                                </h3>
                                <h6 className="text-center m-0 pb-2">
                                Welcome once again…..

                                </h6>
                                <p className="text-center mb-2 mb-sm-5">We are missing the following information in our records in order to have you enrolled as a “Supplier” with us.</p>
                                <small className=" form-text d-block text-center  text-muted">{formValidation.default}</small>
                                <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                                    <div>
                                        <p><strong>Personal Details</strong></p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 d-none">
                                            <div className="form-group">
                                                <input type="text" maxLength="15" defaultValue={props.userdata.first_name} className="form-control" id="validationCustom01"  {...register('first_name', { required: true })} placeholder="First Name*" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Customer First Name.
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6 d-none">
                                            <div className="form-group">
                                                <input type="text" maxLength="15" defaultValue={props.userdata?.last_name} className="form-control" id="validationCustomNew"  {...register('last_name', { required: true })} placeholder="Last Name*" required />
                                                <div className="invalid-feedback">
                                                    Please Enter Customer Last Name.
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6 d-none">

                                            <div className="form-group">
                                                <input type="email" maxLength="100" defaultValue={props.userdata.email} className="form-control" name="email" id="validationCustomNew1"  {...register('email', { required: true })} placeholder="Work Email*" required />

                                                <small className='text-danger'> {errors.email?.message}</small>
                                                <div className="invalid-feedback">
                                                    Please Enter Valid Email Address.
                                                </div>
                                                <small className="form-text text-muted">{emailErr}</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 d-none">
                                            <div className="form-group">
                                                <Input
                                                    className="form-control"
                                                    minLength={14}
                                                    maxLength={14}
                                                    name="phone"
                                                    defaultCountry="US"
                                                    placeholder="Phone*"
                                                    {...register('phone', { required: true })}
                                                    value={value}
                                                    onChange={setValue} 
                                                    required
                                                    />


                                                <small className='font-12 d-block'>E.g (xxx) xxx-xxxx , No Need for Country Code</small>
                                                <small className='text-danger'> {errors.phone?.message}</small>
                                                <small className="form-text text-muted">{mobileErr}</small>
                                                <div className="invalid-feedback">
                                                    Please enter mobile number.
                                                </div>

                                            </div>
                                        </div>


                                       
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" maxLength="40" className="form-control" id="validationCustomnew4" {...register('company_name', { required: true })} placeholder="Company*" required />
                                                <div className="invalid-feedback">
                                                    Please enter company.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="number" maxLength="6" className="form-control" id="validationCustomnew2" {...register('zipcode', { required: true })} placeholder="Zipcode*" required minLength={1} min={0}/>
                                                <small className='text-danger'> {errors.zipcode?.message}</small>
                                                <div className="invalid-feedback">
                                                    Please enter zipcode.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group w-9">
                                                <div className='file-upload-div' >
                                        <input 
                                        accept="application/pdf" 
                                        className="form-control" 
                                        id="document"
                                        type="file" 
                                        {...register('image', { required: true })} 
                                        name="image"
                                        required />
                                        <span>W-9 File</span>
                                        </div>
                                        <small className='text-danger'> {errors.image?.message}</small>
                                        <div className="invalid-feedback">
                                            Please upload document.
                                        </div>
                                            </div>
                                        </div>

                                        <div>
                                            <p><strong>Technical Capabilities</strong></p>
                                        </div>
                                        <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Manufacturing Process*</label>
                                        <Multiselect
                                        className="cust-select"
                                          options={capabilityData?.manufacturing_process} // Options to display in the dropdown
                                          // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                                          onSelect={(selectedList, selectedItem) => {
                                            setmanufacturing2err("");
                                            setmanufacturing_process([...selectedList]);
                                          }} // Function will trigger on select event
                                          onRemove={(selectedList, selectedItem) => {
                                            removePlayer(manufacturing_process,selectedItem);
                                          }} // Function will trigger on remove event
                                          // displayValue="name" // Property name to display in the dropdown options
                                          showCheckbox={true}
                                          isObject={false}
                                        />
                                        {manufacturingerr != "" &&
                                        <div className='invalid-feedback d-block'>
                                          {manufacturingerr}
                                        </div>
                                        }
                                      </div>
                                    </div>
                    {capabilityData?.tolerance &&
                    capabilityData?.tolerance.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tolerance*</label>


                          <Multiselect
                          className="cust-select"
                            options={capabilityData?.tolerance} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            selectedValues= {tolerance}
                            
                            // onSelect={(selectedList, selectedItem) => {
                            //   settoleranceerr("");
                            //   settolerence([...selectedList]);
                            //   if(selectedItem===capabilityData?.tolerance[capabilityData?.tolerance.length-1]){
                            //     selectedList=capabilityData?.tolerance;
                               
                            //     settolerence([...capabilityData?.tolerance])
                            //     // document.getElementById("pepega").children[1].childNodes[0].childNodes.forEach(i=>i.setAttribute('checked','true'));
                            //   }
                            //   if(selectedItem!==capabilityData?.tolerance[capabilityData?.tolerance.length-1]){ 
                            //     settolerence([...selectedList]);
                               
                            //     }
                            // }} 

                            onSelect={(selectedList, selectedItem) => {
                              settoleranceerr("");
                              const selectedIndex = capabilityData?.tolerance.findIndex(
                                (option) => option === selectedItem
                              );
                              if (selectedIndex !== -1) {
                                // Down option selected
                                selectedList = capabilityData?.tolerance.slice(0, selectedIndex + 1);
                                settolerence([...selectedList]);
                              } else {
                                settolerence([...selectedList]);
                              }
                            }}
                            
                            
                            // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer(tolerance,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {tolerance_newerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {tolerance_newerr}
                          </div>
                          }
                        </div>
                      </div>
                    )}
                    {capabilityData?.surface_roughness &&
                    capabilityData?.surface_roughness.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Surface Roughness*</label>
                          <Multiselect
                          className="cust-select"
                            options={ capabilityData?.surface_roughness} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            selectedValues={Surface_roughness}
                            // onSelect={(selectedList, selectedItem) => {
                            //   setsurface_roughnesserr("")
                            //   setSurface_roughness([...selectedList]);
                            //   if(selectedItem===capabilityData?.surface_roughness[capabilityData?.surface_roughness.length-1]){
                            //     selectedList=capabilityData?.surface_roughness;
                               
                            //     setSurface_roughness([...capabilityData?.surface_roughness])
                            //     // document.getElementById("pepega").children[1].childNodes[0].childNodes.forEach(i=>i.setAttribute('checked','true'));
                            //   }
                            //   if(selectedItem!==capabilityData?.surface_roughness[capabilityData?.surface_roughness.length-1]){ 
                            //     setSurface_roughness([...selectedList]);
                               
                            //     }
                            // }} 

                            
                            onSelect={(selectedList, selectedItem) => {
                              setsurface_roughnesserr("");
                              const selectedIndex = capabilityData?.surface_roughness.findIndex(
                                (option) => option === selectedItem
                              );
                              if (selectedIndex !== -1) {
                                // Down option selected
                                selectedList = capabilityData?.surface_roughness.slice(0, selectedIndex + 1);
                                setSurface_roughness([...selectedList]);
                              } else {
                                setSurface_roughness([...selectedList]);
                              }
                            }}
                            
                            
                            // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer(Surface_roughness,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {surface_roughnesserr != "" &&
                          <div className='invalid-feedback d-block'>
                            {surface_roughnesserr}
                          </div>
                          }
                        </div>
                      </div>
                    )}
                    {capabilityData?.inspection &&
                    capabilityData?.inspection.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Inspection*</label>
                          <Multiselect
                           selectedValues={[capabilityData?.inspection && capabilityData?.inspection[0]]}
                          className="cust-select"
                            options={capabilityData?.inspection} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setinspectionerr("");
                              setInspection([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer(Inspection,selectedItem);
                              if(Inspection.length === 1){
                                setInspection([])
                              }
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                         {inspectionerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {inspectionerr}
                          </div>
                          }
                        </div>
                      </div>
                    )}

                    {capabilityData?.part_qualifications_certifications &&
                    capabilityData?.part_qualifications_certifications.length ==
                      0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Part Quality Certifications*</label>
                          <Multiselect
                          className="cust-select"
                            options={ capabilityData?.part_qualifications_certifications } // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setpart_qualificationserr("");
                              setqualifications([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer(qualifications,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                        {part_qualificationserr != "" &&
                          <div className='invalid-feedback d-block'>
                            {part_qualificationserr}
                          </div>
                          }
                        </div>
                      </div>
                    )}

                    {capabilityData?.material_certifications &&
                    capabilityData?.material_certifications.length == 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Material Certifications*</label>
                          <Multiselect
                          className="cust-select"
                            options={  capabilityData?.material_certifications } // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setmaterialerr("")
                              setMaterial([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer(Material,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                         {materialerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {materialerr}
                          </div>
                          }
                        </div>
                      </div>
                    )}

                  {capabilityData?.coatings &&
                    capabilityData?.coatings.length === 0 ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Coating*</label>
                          <Multiselect
                            className="cust-select"
                            options={capabilityData?.coatings} // Options to display in the dropdown
                            // selectedValues={ capabilityData?.manufacturing_process} // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) => {
                              setcoatingerr("")
                              setCoating([...selectedList]);
                            }} // Function will trigger on select event
                            onRemove={(selectedList, selectedItem) => {
                              removePlayer(Coating,selectedItem);
                            }} // Function will trigger on remove event
                            // displayValue="name" // Property name to display in the dropdown options
                            showCheckbox={true}
                            isObject={false}
                          />
                          {coatingerr != "" &&
                          <div className='invalid-feedback d-block'>
                            {coatingerr}
                          </div>
                          }
                        </div>
                      </div>
                    )}

                    

                                        <div className='col-12'>
                                        <div className="form-check ">
                                            <input className="form-check-input" type="checkbox" value="" id="invalidCheck2" onChange={() => setCheckedValue(!checkedValue)} required checked={checkedValue} />
                                            <label className="form-check-label" >
                                            <span className='text-light-gray  ms-2'>I have read and accept the <span onClick={() => dispatch(modalConditionValue(true))} className='text-decoration-none text-primary pointer'>Terms of Service</span> and  <span onClick={() => dispatch(modalPolicyValue(true))} className='text-decoration-none pointer text-primary'>Privacy Policy</span></span>
                                            </label>
                                            <div id="invalidCheck3Feedback" className="invalid-feedback">
                                                You must agree before submitting.
                                            </div>
                                        </div>
                                        </div>

                                        <div className="col-lg-12 text-center">
                                        <div className=' text-center justify-content-center'>
                                            {

                                                loading === false ?
                                                
                                                    <button type="submit" className="btn btn-primary-orange m-auto ms-2 me-2  mt-3">Sign Up</button> 
                                                   :
                                                    <Button className='btn  btn-primary-orange m-auto  mt-3 spinner' variant='dark' disabled>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            className='me-2'
                                                            role="status"
                                                            size="sm"
                                                            aria-hidden="true"
                                                        />
                                                        Please wait...
                                                    </Button>
                                            }
                                             <button type="reset" onClick={()=>props.onHide()} className="btn btn-primary-outline m-auto  ms-2 me-2 mt-3">Cancel</button> 
                                                    </div>


                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>

               

            </Modal>

        </React.Fragment>
    )
}
export default RegisterSupplier;