import React, { useEffect, useState, useLayoutEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import Input from "react-phone-number-input/input";
import { Button, Spinner } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormValidation from "../../../../formValidation/formValidation";
import { getMethodData, postMethodData } from "../../adminApi/api";
import { MdSendToMobile } from "react-icons/md";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const EditCustomer = () => {
  const [redirection,setRedirection] = useState(false);
  let  showDataToUser =false; 
  useLayoutEffect(() => {
      const ls=JSON.parse(localStorage.getItem("adminDetails"));
   // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
     
      for(let i=0; i<=ls?.my_permissions?.length;i++){
          if(ls?.my_permissions[i]?.name==="Customer Management"){
              for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                  if(ls?.my_permissions[i]?.permissions[j]?.name==='Edit'){
                      // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      showDataToUser = true;
                  }
              }
          }
      }
      // console.log(showDataToUser);
      if(showDataToUser===false){
        // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
  }, []);
    //permission redirection
  
    const navigate = useNavigate();
    if (redirection) navigate('/admin/customer-management/');
    // \\permission redirection


  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("The email must be a valid email. "),
    phone: Yup.string().min(14).required("A phone number is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const { id } = useParams();
  // const PORT = PORTNUMBER.PORTNUMBER
  const Token = localStorage.getItem("token");
  const [editData, setEditData] = useState(["not"]);
  const [mobileErr, setMobileErr] = useState([""]);
  const [emailErr, setEmailErr] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [focusArea, setFocusArea] = useState(false);
  const [value, setValue] = useState();

  const FormValidationNew = FormValidation;

  const [formValidation, setFormValidation] = useState({
    Email: null,
    Mobile: "",
    default: "",
    Phone: "",
    setPassErrFormat: "",
    Success: null,
  });

  useEffect(() => {
    getMethodData(`/customers/edit/${id}`, Token).then((res) => {
      setEditData(res.data.result.data);
      setValue("+1" + res.data.result.data.phone.replace(/[()\ \s-]+/g, ""));
    });
  }, [getMethodData, id]);

  const onSubmit = (data) => {
    setLoading(true);
    var fd = new FormData();

    var inputs = document.getElementsByClassName("form-control");

    for (let i = 0; i < inputs.length; i++) {
      fd.append(inputs[i].name, inputs[i].value);
    }

    postMethodData(`/customers/update/${id}`, fd, Token)
      .then((res) => {
        if (res?.data?.status === true) {
          setLoading(false);
          toast(res?.data?.message);
          navigate("/admin/customer-management");
          reset();
        } else {
          setLoading(false);

          setMobileErr(res?.data?.result?.errors?.phone);
          setEmailErr(res?.data?.result?.errors?.email);

          toast.error("Somthing Went Wrong!");
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });

    return false;
  };

  const alertFocus = () => {
    alert(1);
  };

  useEffect(() => {
    FormValidationNew();
  }, [FormValidationNew]);
  return (
    <>
                <div className="tables-field">
                  <div className="project-table">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-6">
                          <div className="main-head">
                            <h4>Edit Customer</h4>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="theme-btn text-end top-head">
                            <Link
                              to="/admin/customer-management"
                              className="btn"
                            >
                              Back
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-order-box shadow">
                      <div className="tab-head">
                        <small className=" form-text d-block text-center mb-4 text-muted">
                          {formValidation.default}
                        </small>
                        <form
                          className="needs-validation"
                          noValidate
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>First Name</label>
                                <input
                                  type="text"
                                  maxLength="15"
                                  id="validationCustom01"
                                  className="form-control"
                                  defaultValue={editData.first_name}
                                  name="first_name"
                                  {...register("first_name", {
                                    required: false,
                                  })}
                                  placeholder="First Name"
                                  required
                                />
                                <div className="invalid-feedback">
                                  Please Enter Customer First Name.
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  maxLength="15"
                                  className="form-control"
                                  id="validationCustomNew"
                                  defaultValue={editData.last_name}
                                  {...register("last_name", {
                                    required: false,
                                  })}
                                  placeholder="Last Name"
                                  required
                                />
                                <div className="invalid-feedback">
                                  Please Enter Customer Last Name.
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  type="email"
                                  maxLength="100"
                                  id="validationCustom02"
                                  className="form-control"
                                  defaultValue={editData.email}
                                  name="email"
                                  {...register("email", { required: false })}
                                  placeholder="Email"
                                  required
                                />
                                <small className="text-danger">
                                  {" "}
                                  {errors.email?.message}
                                </small>

                                <div className="invalid-feedback">
                                  Please Enter Valid Email Address.
                                </div>

                                <small className="form-text text-muted">
                                  {emailErr}
                                </small>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Company Name</label>
                                <input
                                  type="text"
                                  id="validationCustom02"
                                  className="form-control"
                                  defaultValue={editData.company_name}
                                  name="company_name"
                                  {...register("company_name", {
                                    required: false,
                                  })}
                                  placeholder="Company Name"
                                  
                                />
                                <div className="invalid-feedback">
                                  Please Enter Company Name.
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Mobile</label>

                                {/* <input type="text"  className="form-control" name="phone" defaultValue={editData.phone}   placeholder="Enter phone number" id="validation34"   {...register('phone', { required: false })} required/> */}
                                <Input
                                  className="form-control"
                                  minLength={14}
                                  maxLength={14}
                                  value={value}
                                  onChange={setValue}
                                  autoFocus
                                  name="phone"
                                  defaultCountry="US"
                                  placeholder="Enter phone number"
                                  {...register("phone", { required: true })}
                                  required
                                />

                                <small className="font-12 d-block">
                                  E.g (xxx) xxx-xxxx , No Need for Country Code
                                </small>
                                <small className="text-danger">
                                  {" "}
                                  {errors.phone?.message}
                                </small>
                                <div className="invalid-feedback">
                                  Please enter mobile number.
                                </div>
                                <small className="form-text text-muted">
                                  {formValidation.Phone}
                                </small>
                                <small className="form-text text-muted">
                                  {mobileErr}
                                </small>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Status</label>
                                <select
                                  className="form-select form-control"
                                  {...register("status", { required: false })}
                                  id="validationCustom04"
                                  aria-label="Default select example"
                                  required
                                >
                                  <option
                                    className="disabled-value d-none"
                                    value={editData.status === "active" ? 1 : 0}
                                  >
                                    {editData.status}
                                  </option>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </select>
                                <div className="invalid-feedback">
                                  Please select status.
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="theme-btn btn-group-right text-right">
                                {loading === false ? (
                                  <button type="submit" className="btn">
                                    Save
                                  </button>
                                ) : (
                                  <Button
                                    className="btn  spinner"
                                    variant="dark"
                                    disabled
                                  >
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    Updating...
                                  </Button>
                                )}

                                <Link
                                  to="/admin/customer-management"
                                  className="btn"
                                >
                                  Cancel
                                </Link>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <ToastContainer />
                </div>
             
    </>
  );
};
export default EditCustomer;
