import React, { useEffect, useState } from "react";
import { GiAutoRepair } from "react-icons/gi";
import { ImageObj } from "../../../../../assets/images/imgeObject";
import QuoteCollapse from "../../../common/quoteCollapse/quoteCollapse";
import { getMethodDataUser } from "../../../../admin/adminApi/api";
import { useParams, Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import { FaCube, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import DeleteId from "../../../common/deleteId/deleteId";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import fileDownload from 'react-file-download';


const Quotedetails = () => {
  const { id } = useParams();
  const USERTOKEN = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [quoteparts, setQuoteparts] = useState();
  const [orderSummery, setorderSummery] = useState();
  const [openDelete, setOpenDelete] = useState();

  const [quoteid, setquoteid] = useState();

  const [acceptpop, setacceptpop] = useState(false);
  const [openManual, setOpenManual] = useState();

  const [indexDelete, setIndexDelete] = useState();
  const [quoteTitle,setQuoteTitle] = useState("Want to add another part to this Quote?");
  const quoteLength = quoteparts ? quoteparts?.length : "";

  const getQuotedetails = () => {
    setLoading(false);
    getMethodDataUser(`/quotes/details/${id}`, USERTOKEN).then((res) => {
      if (res.data.status == true) {
        setorderSummery(res.data.result.data);
        setQuoteparts(res.data.result.data.quote_parts);
        setLoading(true);
      } else {
        toast.warning(res.data.message);
      }
    });
  };
  const popUpDetele = (index) => {
    setIndexDelete(index);
    setOpenDelete(true);
  };

  const popUpManual = (index) => {
    setIndexDelete(index);
    setOpenManual(true);
  };

  const deletePart = () => {
    getMethodDataUser(`/quotes-item/delete/${indexDelete}`, USERTOKEN).then(
      (res) => {
        if (res.data.result.data.is_quote_deleted === 1) {
          navigate("../quotes");
        } else {
          getQuotedetails();
        }
        setOpenDelete(false);
        toast.success(res.data.message);
        setLoading(true);
      }
    );
  };


  const [btnloading,setbtnLoading] = useState(false)

  const getacceptprice = () => {
    setbtnLoading(true)
    getMethodDataUser(`/quotes/accept-proposal/${quoteid}`, USERTOKEN).then(
      (res) => {
        if (res.data.status == true){
          setacceptpop(false)
          setbtnLoading(false)
          toast(res.data.message);
          getQuotedetails()
          
        }
        else {
          setacceptpop(false)
          setbtnLoading(false)
          toast.warning(res.data.message);
        }
      }
      
    );
  }




  useEffect(() => {
    getQuotedetails();
  }, []);
  const BASE_URL = process.env.REACT_APP_BASE_URL_USER;
  const [modifyData, setModifyData] = useState();
  const [expirydate, setexpirydate] = useState();

  useEffect(() => {
    axios.get(`${BASE_URL}/user/get-globle-config/QUOTE_MODIFY`).then((req) => {
      setModifyData(req?.data?.result?.data?.value);
      if(req?.data?.result?.data?.value==="Yes"){
         setQuoteTitle("Got a part to manufacture?");
      }
    });
  }, []);

  useEffect(() => {
    axios.get(`${BASE_URL}/user/get-globle-config/QUOTE_EXPIRY_DAYS`).then((req) => {
      setexpirydate(req?.data?.result?.data?.value);
    });
  }, []);

  const handleDownload = (data,fileurl) => {
    const fileUrl = data;
    const fileName = fileurl;
    fileDownload(fileUrl, fileName);
  };


  return (
    <React.Fragment>
      {orderSummery?.status != "closed" && (orderSummery?.is_manual_quote !== 1) && (modifyData !== "Yes") && <QuoteCollapse quotetitle={quoteTitle} /> }
      
      <section className="pt-5 mt-3">
        <div className="container dashboard-heading-block py-3">
          <Link
            className="text-decoration-none text-dark mb-sm-4 mb-3 d-inline-block"
            to="../quotes"
          >
            {"< Back"}
          </Link>
          <div className="row align-items-center">
            <div className="col-lg-12 dashboard-headline text-start">
              <h2>My Quotes - #{orderSummery?.quote_id}</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-4 pb-5">
        <div className="container">
          <div className="row">
            <div
              className={
                orderSummery?.is_manual_quote === 2 ? "col-lg-9 position-relative" : orderSummery?.is_proposal_accepted === 1 ? "col-lg-9 position-relative" : "col-lg-12 position-relative" }
            >
              {!loading ? (
                <div className="loader-wrapper">
                  <div className="loader">
                    <div className="loading-svg">
                      <GiAutoRepair />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {quoteparts && quoteparts !== undefined ? (
                quoteparts.map((items, index) => (
                  <div key={index} className="mb-3">
                     {items?.is_manual_quote === 1 &&
                    <div className="grey-box row mb-3">
                    <div className=" col-md-12">
                    
                        <div className="accept-proposal">
                          {items?.is_proposal_send === 0 ?
                          <p>We have received your quote request and Mach2Mfg team is working on presenting you a good deal. Please give us sometime.</p>
                          :
                          <div className="row">
                            <div className=" col-md-12">
                              <h5>Proposal From Mach2Mfg</h5>
                              {items?.is_proposal_send === 1 && (items?.is_proposal_accepted === 0) &&
                              <p className="mt-3 mb-4"><i>The proposal is valid for {expirydate} days, till {moment(items?.last_edited).add(expirydate, 'days').format("MM/DD/YYYY")}</i></p>
                              }
                              
                              <p className="">Proposed Price:- ${orderSummery.proposal?.proposal_amount} + Shipping</p>
                              <p className="">Proposal Document:- <a target="_blank" href={orderSummery.proposal?.attachment}>View</a></p>
                            </div>
                            {(items?.is_proposal_send === 1) && (items?.is_proposal_accepted === 0) && (items?.status === "proposal submitted") &&
                              <div className=" col-md-12">
                                <button onClick={()=>{setquoteid(items?.id);setacceptpop(true)}} type="button" className="btn btn-primary-orange mt-2">Accept Price</button>
                              </div>
                            }
                            {(items?.is_proposal_send === 1) && (items?.is_proposal_accepted === 0) && (items?.status === "expired") &&  <h5 className="mt-4">Quote is now Expired</h5>}
                            {(items?.is_proposal_send === 1) && (items?.is_proposal_accepted === 1) && (items?.status === "expired") &&  <h5 className="mt-4">Quote is now Expired</h5>}
                          </div>}
                        </div>
                       
                    </div>
                  </div>
                    }

                    <div className="row my-cart-items">
                     {/* {modifyData==="No"? <div className="col-lg-3">
                        <img src={ImageObj.ProductBig} alt="..."/>
                      </div>:null} */}
                      <div className={"col-md-12"}>
                        <div className="row mt-3">
                          <div className="col-lg-12 relative-box">
                            <h5>{items.file_name}</h5>

                            <div className="icon-set">
                            <a
                              // target={'_blank'}
                              download
                              // onClick={()=> handleDownload(items?.auto_cad_file_path,items?.file_name)}
                              title="Download 3d File"
                              href={items?.auto_cad_file_path}
                              className="icons-action" 
                              // rel="noreferrer" 
                            >
                              
                              <FaCube className="text-primary"/>
                            </a>
                           
                            {(orderSummery?.is_manual_quote === 2 || orderSummery?.is_manual_quote === 0) &&  
                            <div>

                                 



                            {items.status === "open" && (

                            <>
                          {modifyData === "Yes" && (orderSummery?.is_manual_quote === 2) ?
                            null
                            :
                            <React.Fragment>
                                {items.is_manual_converted !== 1 && (
                                  <Link 
                                  title="Update Quote"
                                    to={`../quotes/editdetails${items.id}`}
                                  >
                                    <img src={ImageObj.PenIcon} />
                                  </Link>
                                )}
                                <span
                                  onClick={() => popUpDetele(items.id)}
                                  className="ms-3 pointer opacity-50"
                                >
                                  <FaTrashAlt />
                                </span>
                            </React.Fragment>
                          }
                            </>

                              
                            )}
                            {items.status === "expired" && (
                              <span
                                onClick={() => popUpDetele(items.id)}
                                className="ms-3 pointer opacity-50"
                              >
                                <FaTrashAlt />
                              </span>
                            )}
                            {/* <a href="#"><img src={ImageObj.ClockIcon} /></a> */}
                          </div>
                              // :  modifyData ===
                              // "No" ? (

                              // <div className="icon-set">
                              //   {items.status === "open" && (
                              //     <React.Fragment>
                              //       {items.is_manual_converted !== 1 && (
                              //         <Link
                              //           to={`../quotes/editdetails${items.id}`}
                              //         >
                              //           <img src={ImageObj.PenIcon} />
                              //         </Link>
                              //       )}
                              //       <span
                              //         onClick={() => popUpDetele(items.id)}
                              //         className="ms-3 pointer opacity-50"
                              //       >
                              //         <FaTrashAlt />
                              //       </span>
                              //     </React.Fragment>
                              //   )}
                              //   {items.status === "expired" && (
                              //     <span
                              //       onClick={() => popUpDetele(items.id)}
                              //       className="ms-3 pointer opacity-50"
                              //     >
                              //       <FaTrashAlt />
                              //     </span>
                              //   )}
                              // </div>
                              // ) : null
                              }
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="row">
                              <div className="col-3">
                                <small>Qty</small>
                                <p>{items?.qty}</p>
                              </div>
                              {/* <div className="col-6"><small>Date</small><p>{moment(items?.created_at).format("MMM DD, YYYY")}</p></div> */}
                                <div className="col-3">
                                  <small>Unit Cost</small>
                                  <p>${items?.unit_price}</p>
                                </div>
                                <div className="col-3">
                                  <small>Total Price</small>
                                  <p>${items?.total_price}</p>
                                </div>
                              <div className="col-3">
                                <small>Notes</small>
                                <p className="nots">
                                  {items
                                    ? items?.notes
                                      ? items?.notes.substring(0, 50)
                                      : "-"
                                    : "-"}
                                </p>
                              </div>
                              {/* {modifyData === "Yes" ? null : modifyData ===
                                "No" ? ( */}
                                <div className="col-3">
                                  <small>Last edited</small>
                                  <p>
                                    {moment(items?.last_edited).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </p>
                                </div>
                              {/* ) : null} */}
                            </div>
                            <ul className="m-0 p-0 d-flex align-items-center flex-wrap">
                              {items?.manufacturing_process && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">
                                    Manufacturing Process
                                  </small>
                                  :
                                  <p className="mb-0 ms-1 me-3 new text-capitalize">
                                    {items?.manufacturing_process}
                                  </p>
                                </li>
                              )}
                              {items?.custom_manufacturing_process && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">
                                    Custom Manufacturing Process
                                  </small>
                                  :
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.custom_manufacturing_process}
                                  </p>
                                </li>
                              )}
                              {items?.material && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">Material </small> :
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.material}
                                  </p>
                                </li>
                              )}
                              {items?.custom_material && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">Custom Material Value</small> :
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.custom_material}
                                  </p>
                                </li>
                              )}
                              {items?.finish && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">Coating </small> :
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.finish}
                                  </p>
                                </li>
                              )}
                              {items?.custom_finish && (
                                <li className="d-flex align-items-center">
                                  <small className="mb-0">Custom Coating Value</small>
                                  :
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.custom_finish}
                                  </p>
                                </li>
                              )}
                              {items?.threads && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">Threads </small> :
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.threads}
                                  </p>
                                </li>
                              )}
                              {items?.tolerance && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">Tolerance </small> :
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.tolerance}
                                  </p>
                                </li>
                              )}
                              {items?.surface_roughness && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">
                                    Surface Roughness
                                  </small>
                                  :
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.surface_roughness}
                                  </p>
                                </li>
                              )}
                              {items?.part_marking && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">Part Marking </small>:
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.part_marking}
                                  </p>
                                </li>
                              )}
                              {items?.inspection && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">Inspection </small> :
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.inspection}
                                  </p>
                                </li>
                              )}
                              {items?.custom_inspection && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">Custom Inspection Value</small> :
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.custom_inspection}
                                  </p>
                                </li>
                              )}
                              {items?.part_qualifications_certifications && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">
                                    Part Quality Certifications
                                  </small>
                                  :
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.part_qualifications_certifications}
                                  </p>
                                </li>
                              )}
                              {items?.material_certifications && (
                                <li className="d-flex align-items-center">
                                  <small className="me-1">
                                    Material Certifications
                                  </small>
                                  :
                                  <p className="mb-0 ms-1 me-3 new">
                                    {items?.material_certifications}
                                  </p>
                                </li>
                              )}
                            </ul>
                          </div>

                          
                        </div>

                        
                       {modifyData==="Yes"?null:modifyData==="No"?
                       <>{items?.is_manual_converted !== 1 ? (
                        items?.is_custom_part === 1 && (
                          <button
                            onClick={() => popUpManual(items?.id)}
                            id="manualQuote"
                            className="btn btn-primary-orange m-0 w-100 mt-3"
                          >
                            Request Manual Quote
                          </button>
                        )
                      ) : (
                        <button
                          disabled
                          className="btn rounded-pill btn-primary-transparent m-0 w-100 mt-3"
                        >
                          Manual Quote Request Submitted
                        </button>
                      )}</>
                      :null 
                      }
                      </div>
                    </div>

                   

                  </div>
                ))
              ) : (
                <span className="no-data fs-1 fw-bolder text-uppercase opacity-25 text-dark">
                  No Quotes Found
                </span>
              )}

      
            </div>

            {orderSummery?.is_manual_quote === 2 ? 
            <div className="col-lg-3 my-cart-side-bar">
            <p className=" mt-4 fw-bold">Order Summary</p>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-6">
                <p>Total Items</p>
              </div>
              <div className="col-lg-6 col-md-6 col-6 text-end">
                <p>{orderSummery ? orderSummery.quote_parts_count : 0}</p>
              </div>
            </div>
            <div className="row border-top-total-price mt-3 pt-3">
              <div className="col-lg-6 col-md-6 col-6">
                <p>Sub Total</p>
              </div>
              <div className="col-lg-6 col-md-6 col-6 text-end">
                <p>${orderSummery ? orderSummery.product_price : 0}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-6">
                <p>Shipping Fees</p>
              </div>
              <div className="col-lg-6 col-md-6 col-6 text-end">
                <p>TBA</p>
              </div>
            </div>
            {/* <div className="row">
                            <div className="col-lg-6 col-md-6 col-6"><p>Tax</p></div>
                            <div className="col-lg-6 col-md-6 col-6 text-end"><p>-</p></div>
                        </div> */}
            <div className="row pt-3 pb-3 border-top-total-price mt-3">
              <div className="col-lg-6 col-md-6 col-6">
                <p className="semi-bold-font d-flex text-nowrap ">
                  Estimated Total
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-6 text-end">
                <h5>${orderSummery ? orderSummery.product_price : 0}</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {orderSummery ? (
                  orderSummery.product_price !== 0 ? (
                    orderSummery.status !== "expired" &&
                    orderSummery.status !== "closed" && (
                      <Link
                        className="btn btn-primary-orange m-0 w-100"
                        to={`../checkout${
                          orderSummery ? orderSummery.id : null
                        }`}
                      >
                        Place Order
                      </Link>
                    )
                  ) : (
                    <button
                      type="button"
                      disabled
                      className="btn btn-primary-orange m-0 w-100"
                    >
                      Place Order
                    </button>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="payment-methods">
                  <p className="semi-bold-font mb-0">Payment Methods</p>
                  <img src={ImageObj.CardPayment} alt="..." />
                </div>
              </div>
            </div>
          </div>
          :
          <>
            {orderSummery?.is_manual_quote === 1 && (orderSummery?.is_proposal_accepted === 1) &&
            <div className="col-lg-3 my-cart-side-bar">
            <p className=" mt-4 fw-bold">Order Summary</p>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-6">
                <p>Total Items</p>
              </div>
              <div className="col-lg-6 col-md-6 col-6 text-end">
                <p>{orderSummery ? orderSummery.quote_parts_count : 0}</p>
              </div>
            </div>
            <div className="row border-top-total-price mt-3 pt-3">
              <div className="col-lg-6 col-md-6 col-6">
                <p>Sub Total</p>
              </div>
              <div className="col-lg-6 col-md-6 col-6 text-end">
                <p>${orderSummery ? orderSummery.product_price : 0}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-6">
                <p>Shipping Fees</p>
              </div>
              <div className="col-lg-6 col-md-6 col-6 text-end">
                <p>TBA</p>
              </div>
            </div>
            {/* <div className="row">
                            <div className="col-lg-6 col-md-6 col-6"><p>Tax</p></div>
                            <div className="col-lg-6 col-md-6 col-6 text-end"><p>-</p></div>
                        </div> */}
            <div className="row pt-3 pb-3 border-top-total-price mt-3">
              <div className="col-lg-6 col-md-6 col-6">
                <p className="semi-bold-font d-flex text-nowrap ">
                  Estimated Total
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-6 text-end">
                <h5>${orderSummery ? orderSummery.product_price : 0}</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {orderSummery ? (
                  orderSummery.product_price !== 0 ? (
                    orderSummery.status !== "expired" &&
                    orderSummery.status !== "closed" && (
                      <Link
                        className="btn btn-primary-orange m-0 w-100"
                        to={`../checkout${
                          orderSummery ? orderSummery.id : null
                        }`}
                      >
                        Place Order
                      </Link>
                    )
                  ) : (
                    <button
                      type="button"
                      disabled
                      className="btn btn-primary-orange m-0 w-100"
                    >
                      Place Order
                    </button>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="payment-methods">
                  <p className="semi-bold-font mb-0">Payment Methods</p>
                  <img src={ImageObj.CardPayment} alt="..." />
                </div>
              </div>
            </div>
          </div>}
          </>
            } 


            {/* {modifyData === "Yes" ? null : modifyData === "No" ? (
              <div className="col-lg-3 my-cart-side-bar">
                <p className=" mt-4 fw-bold">Order Summary</p>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-6">
                    <p>Total Items</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-6 text-end">
                    <p>{orderSummery ? orderSummery.quote_parts_count : 0}</p>
                  </div>
                </div>
                <div className="row border-top-total-price mt-3 pt-3">
                  <div className="col-lg-6 col-md-6 col-6">
                    <p>Sub Total</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-6 text-end">
                    <p>${orderSummery ? orderSummery.product_price : 0}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-6">
                    <p>Shipping Fees</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-6 text-end">
                    <p>TBA</p>
                  </div>
                </div>
                <div className="row pt-3 pb-3 border-top-total-price mt-3">
                  <div className="col-lg-6 col-md-6 col-6">
                    <p className="semi-bold-font d-flex text-nowrap ">
                      Estimated Total
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-6 text-end">
                    <h5>${orderSummery ? orderSummery.product_price : 0}</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    {orderSummery ? (
                      orderSummery.product_price !== 0 ? (
                        orderSummery.status !== "expired" &&
                        orderSummery.status !== "closed" && (
                          <Link
                            className="btn btn-primary-orange m-0 w-100"
                            to={`../checkout${
                              orderSummery ? orderSummery.id : null
                            }`}
                          >
                            Place Order
                          </Link>
                        )
                      ) : (
                        <button
                          type="button"
                          disabled
                          className="btn btn-primary-orange m-0 w-100"
                        >
                          Place Order
                        </button>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-12">
                    <div className="payment-methods">
                      <p className="semi-bold-font mb-0">Payment Methods</p>
                      <img src={ImageObj.CardPayment} alt="..." />
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}
          </div>
        </div>
      </section>

      <span
        className={
          openDelete === true
            ? "deleteBox selected new fixed"
            : "deleteBox new fixed"
        }
      >
        <p className="text-light mt-2 mb-2">Are you sure you want to delete?</p>
        <span className="cancel" onClick={() => setOpenDelete(false)}>
          Cancel
        </span>
        <span className="confirm" onClick={() => deletePart()}>
          Yes
        </span>
      </span>

      <DeleteId
        title={"Are you sure you want to request a manual quote for this Part?"}
        index={indexDelete}
        api={"/convert-part-to-manual-quote/"}
        toggel={openManual}
        setopen={setOpenManual}
        renderapi={getQuotedetails}
        disableBTN={"manualQuote"}
      />


{/* accept price popup */}
    <span className={
          acceptpop === true
            ? "deleteBox selected new fixed"
            : "deleteBox new fixed"
        }>
      <p className='text-light mt-2 mb-2'>Are you sure you want to accept this price and place order for this Part?</p>
      <span className="cancel" onClick={() => {setacceptpop(false)}}>No</span>
      {btnloading ?
      <span className='confirm' variant='dark' disabled>
        wait...
      </span>
      :
      <span className="confirm" onClick={() => {getacceptprice()}}>Yes</span>
      }
    </span>




    </React.Fragment>
  );
};
//items.is_manual_converted

export default Quotedetails;
