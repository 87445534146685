import React, { useEffect, useState,useRef } from 'react'
import Card from "react-credit-cards";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from "./utils";
import "react-credit-cards/es/styles-compiled.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useParams,Link, useNavigate } from 'react-router-dom';
import { GiAutoRepair } from 'react-icons/gi';
import { getMethodDataUser,postMethodDataUser } from '../../../../admin/adminApi/api'
import { toast } from 'react-toastify';

const Payment = () => {
  const USERTOKEN = localStorage.getItem("userToken");
  const SUPPLIERTOKEN = localStorage.getItem("supplierToken");

  const navigate = useNavigate();
  const {id} = useParams()

// payement start
    const [number, setNumber] = useState("0000 0000 0000 0000");
    const [name, setName] = useState("Name");
    const [expiry, setExpiry] = useState("00/00");
    const [cvc, setCvc] = useState("000");
    const [issuer, setIssuer] = useState("");
    const [focused, setFocused] = useState("");
    const [hash, setHash] = useState("");
    const formRef = useRef(null);

  

    const handleCallback = ({ issuer }, isValid) => {
        if (isValid) {
          setIssuer(issuer);
        }
    };

    const handleInputFocus = ({ target }) => {
        setFocused(target.name);
    };

    const handleInputChange = ({ target }) => {
        if (target.name === "number") {
          setNumber(target.value);
          target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
          setExpiry(target.value);
          target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
          target.value = formatCVC(target.value);
          setCvc(target.value);
        } else {
          setName(target.value);
        }
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        const formData = [...e.target.elements]
          .filter((d) => d.name)
          .reduce((acc, d) => {
            acc[d.name] = d.value;
            return acc;
          }, {});
        const cardData = {
          card_number: formData.number.replaceAll(" ", ""),
          card_holder_name: formData.name,
          cvc_code: formData.cvc,
        //   expirationMonth: formData.expiry.split("/")[0],
        //   expirationYear: `20${formData.expiry.split("/")[1]}`,
          expiry_date: formData.expiry
        };
        // // console.log(cardData)
        if(
            id != null 
            &&
            cardData.card_number != null 
            && 
            cardData.card_holder_name != null 
            && 
            cardData.cvc_code != null 
            && 
            cardData.expiry_date != null
        ){
            setLoading(false)
            setLoadingcard(true)
           
            postMethodDataUser(`/place-order`,{
                payment_method:"Credit Card",
                order_id: id,
                card_number: formData.number.replaceAll(" ", ""),
                card_holder_name: formData.name,
                expiry_date: formData.expiry,
                cvc_code: formData.cvc
            },USERTOKEN || SUPPLIERTOKEN)
           
            .then((res) => {
                if(res.data.status === true){
                // console.log("hy");
                setLoading(false)
                setLoadingcard(false)
                toast.success(res.data.message)
                navigate(`/customer/orders`)
                
                }
                else{
                  setLoading(true)
                  toast.error("Please Enter Valid Card Details.")
                }
                
                }).catch((error) => {
                    // console.log(error);
                })
        }
      };

// payment end

    
    const [loading, setLoading] = useState(true)
    const [loadingcard, setLoadingcard] = useState(false)

    const [codeBtn, setcodeBtn] = useState(false);
    const [quoteparts,setQuoteparts] = useState()
    const [orderSummery,setorderSummery] = useState()
    const [promocode,setPromocode] = useState()
    const [Shippingmain,setShippingmain] = useState(true)
    
    
    const [Billingmain,setBillingmain] = useState(false)

    const [ShippingId,setShippingId] = useState()

    const [BillinggId,setBillinggId] = useState()
    
// quote details
const BankTransfer = () => {
    setLoadingcard(false)
    postMethodDataUser(`/place-order`,{
      order_id: id,
      payment_method:"Bank Transfer"
  },
  USERTOKEN || SUPPLIERTOKEN)
  .then((res) => {
      if(res.data.status === true){
      // // console.log(res)
      setLoading(true)
      setLoadingcard(false)
      toast.success(res.data.message)
      navigate(`/customer/orders`)
      
      }
      
      }).catch((error) => {
          // console.log(error);
      })
  }

  const getQuotedetails = () => {
    setLoadingcard(false)
      getMethodDataUser(`/orders/details/${id}`,USERTOKEN || SUPPLIERTOKEN).then((res) => {
          if(res.data.status == true) {
            setorderSummery(res.data.result.data)
            setLoadingcard(true)
          }
          setLoadingcard(true)
      })
  }


  useEffect(() => {
      getQuotedetails()
  }, [])

 

  // proceed to payment api

//   const ProceedPaymentNow = (e) => {
//     postMethodDataUser(`/place-order`,{
//       quote_id:id
//     },
//       USERTOKEN)
//     .then((res) => {
//       if(res.data.status === true){
//         // console.log(res)
//         toast.success(res.data.message)
//         navigate(`/customer/payment${id}`)
        
//       }
//       // console.log("else response",res)
//       }).catch((error) => {
//           // console.log(error);
//       })
//     }

    return (
        <React.Fragment>
        <section className="pt-5 mt-3">
          <div className="container dashboard-heading-block py-3">
            <div className="row align-items-center">
              <div className="col-lg-12 dashboard-headline text-start">
                <h2>Make Payment</h2>
              
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row flex-lg-row-reverse flex-md-row-reverse">
              <div className="col-lg-5 position-relative">
              {
                  !loadingcard ?
                    <div className="loader-wrapper">
                      <div className="loader">
                        <div className="loading-svg">
                          <GiAutoRepair />
                        </div>
                      </div>
                    </div> : ""
                  }
                <div className="white-bg-box">
                  {/* <div className="row">
                    <div className="col-lg-6 col-md-6 col-6"><p>EST delivery</p></div>
                    <div className="col-lg-6 col-md-6 col-6"><p>5-07-2022</p></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-6"><p>Lead time</p></div>
                    <div className="col-lg-6 col-md-6 col-6"><p>10.00 am</p></div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6 col-md-6 col-6"><p>Checkout ( 1 item )</p></div>
                    <div className="col-lg-6 col-md-6 col-6"><p>5 Items</p></div>
                  </div> */}
                  {/* <div className="row mb-2">
                    <div className="col-lg-12">
                      <div className="item-box">
                        <div className="row">
                          <div className="col-lg-4 col-md-4">
                            <img src={ImageObj.Item1} className="image-flude" />
                          </div>
                          <div className="col-lg-8 col-md-8">
                            <div className="row mb-2">
                              <div className="col-lg-12 relative-box"><small>Part name</small><p>001003009.st</p><div className="icon-set"><a href="#"><img src={ImageObj.PenIcon} /></a><a href="#"><img src={ImageObj.CloseIcon}/></a></div></div>
                            </div>
                            <div className="row">
                              <div className="col-2"><small>Qty</small><p>2</p></div>
                              <div className="col-6"><small>Date</small><p>Jun 30, 2022</p></div>
                              <div className="col-4"><small>Price</small><p>$132.00</p></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  
  
                  <div className="row pt-3">
                      <div className="col-lg-6 col-md-6 col-6"><p>Subtotal</p></div>
                      <div className="col-lg-6 col-md-6 col-6 text-end"><p>${orderSummery ? orderSummery.product_price : 0}</p></div>
                  </div>
                  <div className="row">
                      <div className="col-lg-6 col-md-6 col-6"><p>Shipping Fees</p></div>
                      <div className="col-lg-6 col-md-6 col-6 text-end"><p>${orderSummery ? orderSummery.shipping_price : 'TBA'}</p></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-6"><p>Discount</p></div>
                    <div className="col-lg-6 col-md-6 col-6 text-end"><p>${orderSummery ? orderSummery.discounted_amt : 0}</p></div>
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-6 col-md-6 col-6"><p>Tax</p></div>
                    <div className="col-lg-6 col-md-6 col-6 text-end"><p>$0.00</p></div>
                  </div> */}
                  <div className="row pt-3 border-top-total-price mt-3">
                    <div className="col-lg-6 col-md-6 col-6"><h5>Total Price</h5></div>
                    <div className="col-lg-6 col-md-6 col-6 text-end">
                      <h5>${orderSummery ? orderSummery.final_amt : 0}</h5>
                    </div>
                  </div>
                  {/* <div className="row">
                          <div className="col-lg-12">
                                  {!Shippingmain && !Billingmain && ShippingId && BillinggId
                                  ?
                                  <button 
                                //   onClick={(e) => {ProceedPaymentNow(e)}}
                                  type="button" 
                                  className="btn btn-primary-orange m-0 w-100">Proceed to Payment</button>
                                  :
                                  <button 
                                  disabled
                                  type="button" 
                                  className="btn btn-primary-orange m-0 w-100">Proceed to Payment</button>
                                  }
                                  
                          </div>
                      </div> */}
                </div>
              </div>
              <div className='col-lg-7 ps-0 position-relative'>
              <div className=''>
                    <Accordion allowZeroExpanded ={true}>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                  Pay with credit card 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div key="Payment" className='my-5'>
                                <div className="App-payment">
                                    <Card
                                    number={number}
                                    name={name}
                                    expiry={expiry}
                                    cvc={cvc}
                                    focused={focused}
                                    callback={handleCallback}
                                    placeholders={{
                                        name: "NAME"
                                    }}
                                    />
                                    <form className="mt-3" ref={formRef} onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input
                                        type="tel"
                                        name="number"
                                        // defaultValue="5227 2919 0443 2961"
                                        className="form-control input-field"
                                        placeholder="Number on Card"
                                        pattern="[\d| ]{16,22}"
                                        required
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                        type="text"
                                        name="name"
                                        // defaultValue="John Doe"
                                        className="form-control input-field"
                                        placeholder="Name on Card"
                                        required
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                        <input
                                            type="tel"
                                            // defaultValue="12/25"
                                            name="expiry"
                                            className="form-control input-field"
                                            placeholder="Valid Till"
                                            pattern="\d\d/\d\d"
                                            required
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                        </div>
                                        <div className="col-6">
                                        <input
                                            type="tel"
                                            name="cvc"
                                            // defaultValue="745"
                                            className="form-control input-field"
                                            placeholder="CVC"
                                            pattern="\d{3,4}"
                                            required
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                        </div>
                                    </div>
                                    <input type="hidden" name="issuer" value={issuer} />
                                    <div className="form-actions mt-3">
                                        <button 
                                        disabled={!loadingcard ?? loadingcard}
                                        className="btn btn-primary-orange m-0 w-100" 
                                        type='submit'>
                                        Pay & Complete Order
                                        </button>
                                    </div>
                                    </form>
                                </div>
                                </div> 
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                  Pay with Bank Transfer
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div key="Payment" className='my-5'>
                                      <div className='row'>
                                        <div className='col-4'>
                                          <p>
                                            <strong>Bank Name :- </strong>
                                          </p>
                                        </div>
                                        <div className='col-8'>
                                          <p>Bank of Georgia</p>
                                        </div>
                                        <div className='col-4'>
                                          <p>
                                            <strong>Bank Account :- </strong>
                                          </p>
                                        </div>
                                        <div className='col-8'>
                                          <p>#1234567890</p>
                                        </div>
                                        <div className='col-4'>
                                          <p>
                                            <strong>Bank Address :- </strong>
                                          </p>
                                        </div>
                                        <div className='col-8'>
                                          <p>850 Progress Center Court, Suite 500 Lawrenceville, GA 30043</p>
                                        </div>
                                        <div className='col-4'>
                                          <p>
                                            <strong>Bank Code :- </strong>
                                          </p>
                                        </div>
                                        <div className='col-8'>
                                          <p>BKGB0225S3D</p>
                                        </div>
                                        <div className='col-4'>
                                          <p>
                                            <strong>Routing Number :- </strong>
                                          </p>
                                        </div>
                                        <div className='col-8'>
                                          <p>202021515512100</p>
                                        </div>
                                      </div>
                                      <div className="form-actions mt-3">
                                        <button 
                                        disabled={!loadingcard ?? loadingcard}
                                        onClick={() => BankTransfer()} 
                                        className="btn btn-primary-orange m-0 w-100" type='button'>
                                        Complete Order
                                        </button>
                                    </div>
                                </div> 
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
              {
                  !loading ?
                    <div className="loader-wrapper">
                      <div className="loader">
                        <div className="loading-svg">
                          <GiAutoRepair />
                        </div>
                      </div>
                    </div> : ""
                  }
              </div>
            </div>
          </div>
        </section>
        </React.Fragment>
    )
}

export default Payment;