import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Navigate, Link, Outlet } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash, FaUps, FaStar } from 'react-icons/fa';
import 'react-phone-number-input/style.css'
import Input from 'react-phone-number-input/input'
import logo from '../../../../../assets/images/logo.png'
import RegisterUser from '../auth/registerUser/registerUser';
import LoginUser from '../auth/loginUser/loginUser';
import { ImageObj } from '../../../../../assets/images/imgeObject';
import ResetPassword from '../auth/resetPassword/resetPassword';
import VerificationMsg from '../auth/verificationMsg/verificationMsg';

const Home = ({show,showMessage}) => {

  const search = useLocation().search;
  const message = new URLSearchParams(search).get('message');



    return (
        <React.Fragment>
            <section className="banner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h1 className="text-white">Leading the Manufacturing Revolution</h1>
                            
                            <button type="button" className="btn btn-primary-orange my-4">
                                Get You AutoMach Quote
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="rating-section mb-5">
                <div className="container pb-2">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-auto my-1 mx-3">
                            <img src={ImageObj.CalendarIcon} className="me-1" alt="Calendar" /> Parts in as Fast
                            as 1 Day
                        </div>
                        <div className="col-auto my-1 mx-3">
                            <img
                                src={ImageObj.HighPrecision} className="me-1"
                                alt="High-Precision Capabilities"
                            />
                            High-Precision Capabilities
                        </div>
                        <div className="col-auto my-1 mx-3">
                            <img src={ImageObj.CalendarIcon} className="me-1" alt="ISO 9001" /> ISO 9001 ITAR Registered
                        </div>
                        <div className="col-auto excellent-rating mx-3">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <div className="d-flex">
                                <h4>Excellent</h4>
                                <p className="font-size-15px pt-1 ps-1">
                                    <strong>( 120 reviews )</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="green-bg-box pt-5 pb-4 text-center">
                    <h2>Got A Part To Manufacture?</h2>
                    <button type="button" className="btn btn-primary-orange my-4">
                        Start Your AutoMach Quote
                    </button>
                </div>
            </section>
            <section className="tool-in-the-cloud">
                <div className="container">
                    <div className="row align-tems-center">
                        <div className="col-md-6">
                            <img
                                src={ImageObj.ToolsIn}
                                alt="tools"
                                className="img-fluid responsive-img"
                            />
                        </div>
                        <div className="col-md-6 text-center text-md-start">
                            <div className="p-xl-5 mt-5">
                                <h2>Tools in the cloud, Parts in your hands</h2>
                            
                                <button type="button" className="btn btn-primary-orange my-4 mx-auto">
                                    Instant Quote
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="trusted-logos my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 align-items-center justify-content-center text-center">
                            <h4 className="my-3">We are trusted by</h4>
                            <a href="#" className="p-3 d-inline-block">
                                <img src={ImageObj.HpLogo} alt="hp" className="img-fluid" />
                            </a>
                            <a href="#" className="p-3 d-inline-block">
                                <img
                                    src={ImageObj.StairLogo}
                                    alt="Satair"
                                    className="img-fluid"
                                />
                            </a>
                            <a href="#" className="p-3 d-inline-block">
                                <img src={ImageObj.BmwLogo} alt="BMW" className="img-fluid" />
                            </a>
                            <a href="#" className="p-3 d-inline-block">
                                <img src={ImageObj.Bosch} alt="Bosch" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="turbocharge mt-5 mt-sm-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 align-items-center justify-content-center text-center">
                            <h2 className="mb-3">Turbocharge the Way You Make Custom Parts</h2>
                           
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center justify-content-center text-left">
                        <div className="col-sm-12 col-lg-6 turbocharge-col-wrap mb-2">
                            <div className="turbocharge-col mx-1 mx-sm-4">
                                <div className="turbocharge-internal">
                                    <h4 className="pb-2">Instant Quoting</h4>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 turbocharge-col-wrap mb-2">
                            <div className="turbocharge-col mx-1 mx-sm-4">
                                <div className="turbocharge-internal">
                                    <h4 className="pb-2">Massive Network Capacity</h4>
                                  
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 turbocharge-col-wrap mb-2">
                            <div className="turbocharge-col mx-1 mx-sm-4">
                                <div className="turbocharge-internal">
                                    <h4 className="pb-2">Guaranteed Quality</h4>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 turbocharge-col-wrap mb-2">
                            <div className="turbocharge-col mx-1 mx-sm-4">
                                <div className="turbocharge-internal">
                                    <h4 className="pb-2">Industry Experience</h4>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="new-to-mach2 py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-lg-8 text-center text-lg-start">
                            <h3>New to Mach2 ?</h3>
                            <p className="mb-4">
                                Take advantage of our network and see what Mach2 can do for you.
                            </p>
                        </div>
                        <div className="col-sm-12 col-lg-4 text-center text-lg-end">
                            <button type="button" className="btn btn-primary-outline">
                                Get Your Instant Quote
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="parts-made-real mt-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 order-2 order-md-1 text-center text-md-start">
                            <div className="p-xl-5">
                                <h2 className="mt-5">Why you choose us</h2>
                               
                            
                                <button
                                    type="button"
                                    className="btn btn-primary-outline my-4 mx-auto"
                                >
                                    Read More
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6 order-1 order-md-2">
                            <img
                                src={ImageObj.WhyChoose}
                                alt="tools"
                                className="img-fluid responsive-img"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="feature-col p-4 border rounded">
                                <img
                                    className="pt-3"
                                    src={ImageObj.ClockIcon}
                                    alt="clock"
                                    height={60}
                                />
                                <h2 className="py-2">25+</h2>
                                <p>Different Manufacturing Processes</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="feature-col p-4 border rounded">
                                <img
                                    className="pt-3"
                                    src={ImageObj.RoboticIcon}
                                    alt="robotic-arm"
                                    height={60}
                                />
                                <h2 className="py-2">24 hours</h2>
                                <p>Receive Custom Parts in as soon as 24 hours</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="feature-col p-4 border rounded">
                                <img
                                    className="pt-3"
                                    src={ImageObj.LocationIcon}
                                    alt="Location"
                                    height={60}
                                />
                                <h2 className="py-2">12</h2>
                                <p>
                                    Manufacturing
                                    <br />
                                    Locations
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="feature-col p-4 border rounded">
                                <img
                                    className="pt-3"
                                    src={ImageObj.ElectronIcon}
                                    alt="Electron"
                                    height={60}
                                />
                                <h2 className="py-2">08</h2>
                                <p>
                                    Additive
                                    <br />
                                    Technologies
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="instant-quote my-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 position-relative">
                            <img
                                src={ImageObj.VideoPlaceNew}
                                alt="tools"
                                className="img-fluid responsive-img"
                            />
                            <a href="#" className="play-button">
                                <img src={ImageObj.PlayButton} alt="tools" className="img-fluid" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <div className="text-center text-md-start ps-0 ps-md-5 pt-5 pt-md-0">
                                <h2>
                                    How To Get Your
                                    <br />
                                    Instant Quote
                                </h2>
                               
                                <button type="button" className="btn btn-primary-orange my-4 mx-auto">
                                    Learn More
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="parts-made-real my-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 order-2 order-md-1">
              <div className="p-xl-5 mt-5 text-center text-md-start">
                <h2>Your parts made real</h2>
             
                <button type="button" className="btn btn-primary-outline my-4 mx-auto">
                  Read More
                </button>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <img src={ImageObj.PartsMade} alt="tools" className="img-fluid responsive-img" />
            </div>
          </div>
        </div>
      </section>

      <section className="our-capabilities mt-5 pt-5">
        <div className="container-fluid our-capabilities-wrap py-5">
          <div className="container pb-5">
            <div className="row py-4"><h2>Our capabilities</h2></div>
            <div className="row">
              <div className="col-md-6 col-lg-3 p-2 position-relative capabilities-col">
                <img src={ImageObj.Capabilities} alt="capabilities-pic1" className="img-fluid" />
                <div className="our-capabilities-text-block">
                  <h4 className="text-white">Additive<br />Manufacturing</h4>
                  <a href="#" className="capabilities-plus-btn active1"><span>+</span></a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2 position-relative capabilities-col">
                <img src={ImageObj.Capabilities2} alt="capabilities-pic2" className="img-fluid" />
                <div className="our-capabilities-text-block">
                  <h4 className="text-white">CNC<br />Machining</h4>
                  <a href="#" className="capabilities-plus-btn"><span>+</span></a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2 position-relative capabilities-col">
                <img src={ImageObj.Capabilities3} alt="capabilities-pic3" className="img-fluid" />
                <div className="our-capabilities-text-block">
                  <h4 className="text-white">Urethane<br />Casting</h4>
                  <a href="#" className="capabilities-plus-btn"><span>+</span></a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2 position-relative capabilities-col">
                <img src={ImageObj.Capabilities4} alt="capabilities-pic4" className="img-fluid" />
                <div className="our-capabilities-text-block">
                  <h4 className="text-white">Injection<br />Molding</h4>
                  <a href="#" className="capabilities-plus-btn"><span>+</span></a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2 position-relative capabilities-col">
                <img src={ImageObj.Capabilities5} alt="capabilities-pic5" className="img-fluid" />
                <div className="our-capabilities-text-block">
                  <h4 className="text-white">Quality<br />Assurance</h4>
                  <a href="#" className="capabilities-plus-btn"><span>+</span></a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2 position-relative capabilities-col">
                <img src={ImageObj.Capabilities6} alt="capabilities-pic6" className="img-fluid" />
                <div className="our-capabilities-text-block">
                  <h4 className="text-white">Metal Cutting<br />&amp; Forming</h4>
                  <a href="#" className="capabilities-plus-btn"><span>+</span></a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2 position-relative capabilities-col">
                <img src={ImageObj.Capabilities7} alt="capabilities-pic7" className="img-fluid" />
                <div className="our-capabilities-text-block">
                  <h4 className="text-white">PolyJet<br />Technology</h4>
                  <a href="#" className="capabilities-plus-btn"><span>+</span></a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2 position-relative capabilities-col">
                <img src={ImageObj.Capabilities8} alt="capabilities-pic8" className="img-fluid m-auto" />
                <div className="our-capabilities-text-block">
                  <h4 className="text-white">
                    Design,<br />Engineering &amp;<br />DFM Support
                  </h4>
                  <a href="#" className="capabilities-plus-btn"><span>+</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="words-are-good-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-10 text-center m-auto">
              <h2>Words are good. Examples are better.</h2>
             
              <button type="button" className="btn btn-primary-orange my-4">
                Instant Quote
              </button>
            </div>
          </div>
        </div>
      </section>
      
      <section className="delivery light-gray-bg py-5">
        <div className="container py-4">
          <div className="row">
            <div className="col-12 align-items-center justify-content-center text-center py-4">
              <h2 className="mb-3">From design to delivery, all in one place</h2>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-4 mb-3">
              <div>
                <img src={ImageObj.Delivery1} alt="Delivery" className="img-fluid" />
                <h4 className="pt-4">Evaluate your parts</h4>
              
                <button type="button" className="btn btn-primary-outline my-2 mx-auto">
                  Read More
                </button>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div>
                <img src={ImageObj.Delivery2} alt="Delivery" className="img-fluid" />
                <h4 className="pt-4">Quote and go</h4>
               
                <button type="button" className="btn btn-primary-outline my-2 mx-auto">
                  Read More
                </button>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div>
                <img src={ImageObj.Delivery3} alt="Delivery" className="img-fluid" />
                <h4 className="pt-4">Track every step</h4>
               
                <button type="button" className="btn btn-primary-outline my-2 mx-auto">
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

<ResetPassword show={show} />
<VerificationMsg  show={showMessage} message={message} />

        </React.Fragment>

        // <div className="container home-redirect">

        //     <div className="max-new-second login m-auto">
        //         <div className="main-theme border otp-pad shadow bg-dark-new p-lg-5">
        //             <section className="select-state">
        //                 <div className="state-bor">
        //                     <div className="log-in otp-num">
        //                         <img className='mx-auto my-5 d-flex ' width={250} src={logo} />
        //                         <h3 className='text-center'>
        //                             <Link to="/admin" className='color-text-green'>
        //                                 Click For Admin Login
        //                             </Link>
        //                         </h3>
        //                         <div className='theme-btn'>
        //                         <button className='btn w-100' onClick={() => setModalShow(true)}>
        //                            Create An Account
        //                         </button>
        //                         </div>
        //                         <div className='theme-btn'>
        //                         <button className='btn w-100' onClick={() => setModalLoginShow(true)}>
        //                            Login User
        //                         </button>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </section>
        //         </div>
        //     </div>
        //     <RegisterUser show={modalShow}
        //         onHide={() => setModalShow(false)}
        //     />
        //     <LoginUser show={modalLoginShow}
        //         onHide={() => setModalLoginShow(false)}
        //         />

        //        <Outlet/>
        // </div>
    )
}

export default Home;
