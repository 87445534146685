
import React, { useState } from "react";
import { ImageObj } from "../../../../assets/images/imgeObject";
import { FaFacebookF, FaTwitter, FaInstagram, FaPhoneVolume, FaLinkedin } from 'react-icons/fa'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    modalToggelValue,
    modalRgisterlValue,
  } from "../../../../redux/actions/action";

const NavList=()=>{
    const dispatch = useDispatch();
    const [jumpDashboard, setJumpDashboard] = useState(false);
    const dispatchToggle = () => {
        setJumpDashboard(true);
    
        dispatch(modalRgisterlValue(false));
        dispatch(modalToggelValue(true));
      };
    const USERTOKEN = localStorage.getItem("userToken");
    const SUPPLIERTOKEN = localStorage.getItem("supplierToken");
    if(USERTOKEN){
        return(
        <>
            <li><Link to="customer/conditions">Terms of Use</Link></li>
            <li><Link to ="customer/policy">Privacy Policy</Link></li>
            {/* <li><Link to ="customer/resources">Resources</Link></li> */}
        </>
        )
    }
    else if(SUPPLIERTOKEN){
        return(
        <>
            <li><Link to="supplier/conditions">Terms of Use</Link></li>
            <li><Link to ="supplier/policy">Privacy Policy</Link></li>
            {/* <li><Link to = "supplier/resources">Resources</Link></li> */}
        </>
        )
    }
    else{
        return(
            <>
                <li style={{cursor: "pointer"}} className=""><p onClick={() => {dispatchToggle(); document.getElementById("navbarCollapse").classList.toggle("show")}}>Admin Login</p></li>
                <li><Link to="/conditions">Terms of Use</Link></li>
                <li><Link to ="/policy">Privacy Policy</Link></li>
                
            </>
            )
    }
}
const Footer = () => {
    
    
    return (
        <React.Fragment>
            <footer className="user-footer user-footer">
                <div className="container">
                    <div className="row order-2 order-md-1">
                        <div className="col-md-6">
                            <h2 className="text-white">Manufacturing locations across the world</h2>
                            <div className="row pt-5">
                                <div className="col-md-6 mb-5">
                                    <p className="mb-3"><strong>Head Office</strong></p>
                                  
                                    {/* <div className="d-flex footer-social-icon mt-4">
                                        <a href="#" className="ps-0"><FaFacebookF /></a>
                                        <a href="#" className="ps-0"><FaTwitter /></a>
                                        <a href="#" className="ps-0"><FaInstagram /></a>
                                    </div> */}
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-3"><strong>Office 1</strong></p>
                                  
                                    <div className="d-flex mt-4">
                                        <h4>
                                            <a className="text-green text-decoration-none" href="#"><FaPhoneVolume />
                                                123 098765</a>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={ImageObj.FooterMap} alt="Map" className="img-fluid" />
                        </div>
                    </div>
                    <div className="row footer-bottom py-5 mt-5 order-1 order-md-2">
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-6">
                                    <a href="#"><img src={ImageObj.FooterMach} alt="Map" className="img-fluid" /></a>
                                    <p className="pt-3">© 2022 All rights reserved.</p>
                                </div>
                                <div className="col-md-6 pt-3 mb-4">
                                    <p className="mb-2">Customer Service Agreement Partner</p>
                                    <a href="#">Terms and Conditions</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 offset-md-1">
                            <div className="row">
                                <div className="col-md-6">
                                    <ul>
                                        {/* <li><a href="#">Capabilities</a></li> */}
                                        <li><a href="#">Industries</a></li>
                                        <li><a href="#">Services</a></li>
                                        <li><a href="#">Resources</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul>
                                        <li><a href="#">Expertise</a></li>
                                        <li><a href="#">Company</a></li>
                                        <li><a href="#">About Us</a></li>
                                        <li><a href="#">Terms &amp; Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


            <footer className=" user-dash">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 col-sm-12 text-align-center">
                            <p className="mb-0">
                                <Link to="/"><img src={ImageObj.FooterMach} alt="Map" className="img-fluid" /></Link>
                                <span className="padding-left">© {new Date().getFullYear()} All rights reserved.</span>
                            </p>
                        </div>
                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                            {/* <div className="d-flex footer-social-icon">
                                <a href="#" className="ps-0"><FaFacebookF /></a>
                                <a href="#" className="ps-0"><FaTwitter /></a>
                                <a href="#" className="ps-0"><FaInstagram /></a>
                                <a href="#" className="ps-0"><FaLinkedin /></a>
                            </div> */}
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-12 col-sm-12 text-align-right">
                            
                            <ul className="footer-listing">
                                <NavList/>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}
export default Footer