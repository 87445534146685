import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { GiAutoRepair } from "react-icons/gi";
import { postMethodDataUser } from "../../../admin/adminApi/api";
import { useDispatch, useSelector } from "react-redux";
import { contactUsForm } from "../../../../redux/actions/action";
import Input from "react-phone-number-input/input";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
const Contactus = () => {
  const [loading, setLoading] = useState(false);
  const showform = useSelector((state) => state.contactUsForm);

  const onSubmit = (data) => {
    const USERTOKEN = localStorage.getItem("userToken");
  const SUPPLIERTOKEN = localStorage.getItem("supplierToken");
      setLoading(!loading);
      postMethodDataUser(
        `/send-contact-email`,
        data,
        SUPPLIERTOKEN || USERTOKEN
      ).then((res) => {
        if (res.data.status !== false) {
          reset()
          setLoading(false);
          toast.success("Enquiry Sent Successfully")
          dispatch(contactUsForm(false))
        } else {
          toast.error(res.data.result.errors.name[0]);
          setLoading(false);
        }
      }).catch(err=>toast.err('Cannot send data to server. Please Try Again'));
  };
  const dispatch = useDispatch();
// form validation rules
// const validationSchema = Yup.object().shape({
//    name: Yup.string().required("Name is required"),
//    email: Yup.string().email("The email must be a valid email."),
//    phone: Yup.string().min(14).required("Contact no.is required"),
//    message: Yup.string().min(5).required("Message is required").max(255),
// });
// const formOptions = { resolver: yupResolver(validationSchema) };
// get functions to build form with useForm() hook
const { 
  register, 
  handleSubmit, 
  reset,
  formState: { errors },
   } =
  useForm();
// const { errors } = formState;
// 
// console.log(errors)
  return (
    <>
      <Modal
        className="status-modal"
        animation={true}
        show={showform} aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 p-0">
            <Modal.Body>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <h3>Contact Us</h3>
                    <div className="mb-3">
                      <p className="mb-0">Name</p>
                      <input
                        name="name"
                        defaultValue=""
                        type="text"
                        className="form-control"
                        {...register("name", { required: true })}
                        placeholder="Enter Name"
                      />
                       {errors.name && (
                        <small className="form-text text-muted">Name is required</small> 
                      )}
                     
                    </div>
                    <div className="mb-3">
                      <p className="mb-0">Email</p>
                      <input
                        name="email"
                        defaultValue=""
                        type="email"
                        className="form-control"
                        {...register("email", { required: true })}
                        placeholder="Enter Email"
                      />
                      {errors.email && (
                        <small className="form-text text-muted">Email is required</small> 
                      )}
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Mobile</label>

                        {/* <input type="text"  className={ "form-control" } name="phone" defaultValue={editData.phone}   placeholder="Enter phone number" id="validation34"   {...register('phone', { required: false })} required/> */}
                        <Input
                          className={"form-control"}
                          minLength={14}
                          maxLength={14}
                          defaultCountry="US"
                          placeholder="Enter phone number"
                          {...register("phone", { required: true })}
                          name="phone"
                        />

                        <small className="font-12 d-block mt-1">
                          E.g (xxx) xxx-xxxx , No Need for Country Code
                        </small>
                        {errors.phone && (
                          <small className="form-text text-muted">Contact no. is required</small> 
                        )} 
                        
                      </div>
                    </div>

                    <div className="mb-3">
                      <p className="mb-0">Message</p>
                      <textarea
                        name="message"
                        placeholder="Enter message"
                        className="form-control"
                        {...register("message", { required: true })}
                        style={{ borderColor: "black", resize: "none" }}
                        
                      ></textarea>
                      {errors.message && (
                        <small className="form-text text-muted">Message is required</small> 
                      )}  
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="btn-group-right text-end">
                <button
                  className="btn btn-primary-orange m-0 me-3"
                  type="submit"
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-primary-transparent"
                  onClick={() => dispatch(contactUsForm(false))}
                  style={{ padding: "12px 30px", borderRadius: "99px" }}
                >
                  Close
                </button>
              </div>
            </Modal.Footer>
          </div>
          {loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </form>
      </Modal>
    </>
  );
};
export default Contactus;
