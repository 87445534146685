import React from 'react'
import { useLocation } from 'react-router-dom'
import { FaStar } from 'react-icons/fa';
import 'react-phone-number-input/style.css'
import { ImageObj } from '../../../../../assets/images/imgeObject';
import bannerside from '../../../../../assets/images/bannerside.png';
import ResetPassword from '../auth/resetPassword/resetPassword';
import VerificationMsg from '../auth/verificationMsg/verificationMsg';
import CreateQuote2 from '../customerPages/createAquoute/createAquoute2';
import Manufacture from '../customerPages/manufacture/manufacture';
import Upload3d from '../customerPages/createAquoute/uploadFile3d';


const Home2 = ({show,showMessage}) => {
  const search = useLocation().search;
  const message = new URLSearchParams(search).get('message');
    return (
        <React.Fragment>
             <section className="banner-section">
                <div className="container-fluid col-md-11 mx-auto">
                    <div className="row">
                        <div className="col-lg-6" id='home-drop'>
                            <h1 className="text-white">Manufacturing on Demand</h1>
                            <p className="text-white">
                              From prototyping to production, our network of suppliers has the capacity for all of your manufacturing projects
                            </p>
                            {/* <span className="mt-4 py-2 px-3 text-white" style={{cursor:"default",background:"#E8833A",borderRadius:"50px"}}>
                              Get Your Part For Quote  
                            </span> */}
                            <Upload3d uploadid={"upload1"} bannerupload={"banner-upload"}/>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                          <img className='w-100' src={bannerside}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="rating-section mb-5">
                <div className="container-fluid pb-2 pt-2">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-auto my-1 mx-3">
                            <img src={ImageObj.CalendarIcon} className="me-1" alt="Calendar" /> Parts in as Fast as 3 Days
                        </div>
                        <div className="col-auto my-1 mx-3">
                            <img
                                src={ImageObj.HighPrecision} className="me-1"
                                alt="High-Precision Capabilities"
                            />
                            High-Precision Capabilities
                        </div>
                        <div className="col-auto my-1 mx-3">
                            <img src={ImageObj.ISO} className="me-1" style={{width:"25px"}} alt="ISO 9001" /> ISO 9001
                        </div>
                        <div className="col-auto my-1 mx-3">
                            <img src={ImageObj.Trademark} className="me-1" alt="ITAR Registered" style={{width:"28px"}} /> ITAR Registered
                        </div>
                        {/* <div className="col-auto excellent-rating mx-3">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <div className="d-flex">
                                <h4>Excellent</h4>
                                <p className="font-size-15px pt-1 ps-1">
                                    <strong>( 120 reviews )</strong>
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>
           
            </section>
        <section className="features p-0 m-0" id="scrollhere">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="pb-4">The Evolution of Smart Manufacturing</h2>
              </div>
            </div>
            <CreateQuote2 />
          
          </div>
        </section>
        {/* <Manufacture/> */}
{/*       
        <section className="section-gray-bg py-5">
          <div className="container my-5">
            <div className="row align-tems-center pt-3">
              <div className="col-md-6 text-center text-md-start order-md-1 order-2">
                <div className="list-group" id="list-tab" role="tablist">
                  <a className="list-group-item list-group-item-action active" id="link-id-1" data-bs-toggle="list" href="#href-content-block-1" role="tab" aria-controls="href-content-block-1">Got A Part To Manufacture?</a>
                  <a className="list-group-item list-group-item-action" id="link-id-2" data-bs-toggle="list" href="#href-content-block-2" role="tab" aria-controls="href-content-block-2">Why you choose us</a>
                  <a className="list-group-item list-group-item-action" id="link-id-3" data-bs-toggle="list" href="#href-content-block-3" role="tab" aria-controls="href-content-block-3">Your parts made real</a>
                  <a className="list-group-item list-group-item-action" id="link-id-4" data-bs-toggle="list" href="#href-content-block-4" role="tab" aria-controls="href-content-block-4">How To Get Your Instant Quote</a>
                  <a className="list-group-item list-group-item-action" id="link-id-5" data-bs-toggle="list" href="#href-content-block-5" role="tab" aria-controls="href-content-block-5">Our capabilities</a>
                  <a className="list-group-item list-group-item-action" id="link-id-6" data-bs-toggle="list" href="#href-content-block-6" role="tab" aria-controls="href-content-block-6">Got A Part To Manufacture?</a>
                </div>
              </div>
              <div className="col-md-5 offset-md-1 text-center text-md-start order-md-2 order-1 mb-4">
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="href-content-block-1" role="tabpanel" aria-labelledby="link-id-1">
                    <div>
                      <img src={ImageObj.WhyChoose2} alt="Got A Part To Manufacture?" className="img-fluid" />
                      <h2 className="pt-4">Got A Part To Manufacture?</h2>
                      <p>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book. It has survived not onl
                      </p>
                      <button type="button" className="btn btn-primary-outline my-4 mx-auto">
                        Read More
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="href-content-block-2" role="tabpanel" aria-labelledby="link-id-2">
                    <div>
                      <img src={ImageObj.WhyChoose2} alt="Why you choose us" className="img-fluid" />
                      <h2 className="pt-4">Why you choose us</h2>
                      <p>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book. It has survived not onl
                      </p>
                      <button type="button" className="btn btn-primary-outline my-4 mx-auto">
                        Read More
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="href-content-block-3" role="tabpanel" aria-labelledby="link-id-3">
                    <div>
                      <img src={ImageObj.WhyChoose2} alt="Your parts made real" className="img-fluid" />
                      <h2 className="pt-4">Your parts made real</h2>
                      <p>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book. It has survived not onl
                      </p>
                      <button type="button" className="btn btn-primary-outline my-4 mx-auto">
                        Read More
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="href-content-block-4" role="tabpanel" aria-labelledby="link-id-4">
                    <div>
                      <img src={ImageObj.WhyChoose2} alt="How To Get Your Instant Quote" className="img-fluid" />
                      <h2 className="pt-4">How To Get Your Instant Quote</h2>
                      <p>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book. It has survived not onl
                      </p>
                      <button type="button" className="btn btn-primary-outline my-4 mx-auto">
                        Read More
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="href-content-block-5" role="tabpanel" aria-labelledby="link-id-5">
                    <div>
                      <img src={ImageObj.WhyChoose2} alt="Our capabilities" className="img-fluid" />
                      <h2 className="pt-4">Our capabilities</h2>
                      <p>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book. It has survived not onl
                      </p>
                      <button type="button" className="btn btn-primary-outline my-4 mx-auto">
                        Read More
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="href-content-block-6" role="tabpanel" aria-labelledby="link-id-6">
                    <div>
                      <img src={ImageObj.WhyChoose2} alt="Got A Part To Manufacture?" className="img-fluid" />
                      <h2 className="pt-4">Got A Part To Manufacture?</h2>
                      <p>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book. It has survived not onl
                      </p>
                      <button type="button" className="btn btn-primary-outline my-4 mx-auto">
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

<ResetPassword show={show} />
<VerificationMsg  show={showMessage} message={message} />

        </React.Fragment>
    )
}

export default Home2;
