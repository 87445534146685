import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Footer from "../components/admin/common/footer/footer";
import Header from "../components/admin/common/header/header";
import Login from "../components/admin/pages/auth/login/login";
import ResetMail from "../components/admin/pages/auth/resetMail/resetMail";
import ResetPassword from "../components/admin/pages/auth/resetPassword/resetPassword";
import ResetPasswordLink from "../components/admin/pages/auth/resetPwLink/resetPasswordLink";
import AdminRoutes from "./routes/adminRoutes";
import FooterHome from "./../components/user/common/footer/footer";
import Home from "../components/user/components/pages/home/home";
import TermAndServices from "../components/user/common/termAndService/termAndService";
import { useDispatch, useSelector } from "react-redux";
import { adminDataValue, authHocTrueValue } from "../redux/actions/action";

const RequireAuth = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AuthHoc = useSelector((state) => state.authHocValueReducer);
  const ToggleValue = useSelector((state) => state.modalValueReducer);
  const UserData = useSelector((state) => state.userDataObjReducer);
  // console.log("required auth 24", UserData);
  const location = useLocation();

  const [authTrue, setAuthTrue] = useState(false);
  const [authAdminTrue, setAuthAdminTrue] = useState(true);

  const [pathname, setPathname] = useState();

  const adminPath = location.pathname.split("/")[1];
  const PathName = location.pathname;

  const authAdmin = () => {
    dispatch(authHocTrueValue(true));
  };

  const authUser = () => {
    dispatch(authHocTrueValue(false));
  };
  const Auth3 = localStorage.getItem("userToken");
  const Auth2 = localStorage.getItem("supplierToken");
  const Auth4 = localStorage.getItem("token");
  const IsSupplier = localStorage.getItem("is_supplier");
  const IsAdminApproved = localStorage.getItem("is_admin_approved");

  useEffect(() => {
    if (AuthHoc === false) {
      // dispatch(authHocTrueValue(false))
      document.body.classList.remove("admin-part");
    }

    if (
      String(adminPath) === "" ||
      String(adminPath) === "supplier" ||
      String(adminPath) === "customer"
    ) {
      if (Auth2 !== null) {
        navigate("/supplier/dashboard");
        if (String(adminPath) === "supplier") {
          navigate(
            String(
              location !== undefined && location?.pathname !== undefined
                ? location?.pathname
                : "/"
            )
          );
        }
        dispatch(authHocTrueValue(false));
      } else if (Auth3 !== null) {
        if (
          UserData?.is_admin_approved === 1 &&
          UserData?.is_supplier_active === "active"
        ) {
          navigate("/customer/dashboard");
          if (String(adminPath) === "supplier") {
            navigate(
              String(
                location !== undefined && location?.pathname !== undefined
                  ? location?.pathname
                  : "/"
              )
            );
          }
          if (String(adminPath) === "customer") {
            navigate(
              String(
                location !== undefined && location?.pathname !== undefined
                  ? location?.pathname
                  : "/"
              )
            );
          }
        } else {
          navigate("/customer/dashboard");
          if (String(adminPath) === "customer") {
            navigate(
              String(
                location !== undefined && location?.pathname !== undefined
                  ? location?.pathname
                  : "/"
              )
            );
          }
        }
        dispatch(authHocTrueValue(false));
      } else {
        navigate("/");
      }

      // if (Auth3 !== null) {
      //     if (String(adminPath) === "customer") {
      //         navigate(String(location !== undefined && location?.pathname !== undefined ? location?.pathname : "/"))

      //     } else {

      //         navigate("/customer/dashboard")

      //     }
      // }

      // else if (Auth2 !== null) {
      //     if(UserData?.is_admin_approved === 0){
      //         navigate("/customer/dashboard")
      //     }else{
      //         if (String(adminPath) === "supplier") {
      //             navigate(String(location !== undefined && location?.pathname !== undefined ? location?.pathname : "/"))
      //         } else {
      //             navigate("/supplier/dashboard")
      //         }
      //     }

      // }

      // else if (Auth2 !== null) {
      //     if(UserData?.is_admin_approved === 1){
      //         if (String(adminPath) === "supplier") {
      //             navigate(String(location !== undefined && location?.pathname !== undefined ? location?.pathname : "/"))
      //         }
      //         else if(UserData?.is_supplier_active === "inactive"){
      //             navigate("/customer/dashboard")
      //         }
      //         else if(UserData?.is_supplier_active === "active"){
      //             navigate("/supplier/dashboard")
      //         }
      //         else {
      //             navigate("/supplier/dashboard")
      //         }
      //         //
      //     }
      //     else{
      //         navigate("/customer/dashboard")
      //     }
      // }
      // else {
      //     navigate("/")
      //     alert("no one")

      // }
    }

    // if (IsSupplier !== null && Auth3 !== null && String(adminPath) === "supplier") {
    //     if(UserData?.is_admin_approved === 0){
    //         navigate("/customer/dashboard")
    //     }else{
    //     navigate(String(location !== undefined && location?.pathname !== undefined ? location?.pathname : "/"))
    //     }

    // }

    // if (IsSupplier !== null && Auth3 !== null) {
    //     if(UserData?.is_admin_approved === 1){
    //         // navigate("/supplier/dashboard")
    //         navigate(String(location !== undefined && location?.pathname !== undefined ? location?.pathname : "/"))
    //     }
    //     else if(UserData?.is_supplier_active === "active"){
    //         navigate("/supplier/dashboard")
    //     }
    //     else{
    //     navigate("/customer/dashboard")
    //     }

    // }

    // if (IsSupplier !== null && Auth3 !== null && String(adminPath) === "customer") {
    //     navigate(String(location !== undefined && location?.pathname !== undefined ? location?.pathname : "/"))

    // }

    if (String(adminPath) === "admin") {
      dispatch(authHocTrueValue(true));
      document.body.classList.add("admin-part");
      if (Auth4 === null) {
        navigate("/admin");
      } else {
        navigate(
          String(
            location !== undefined && location?.pathname !== undefined
              ? location?.pathname
              : "/"
          )
        );
      }
    }

    // if (String(adminPath) === "customer") {
    //     dispatch(authHocTrueValue(false))
    //     document.body.classList.remove('admin-part');

    // }

    // if (String(adminPath) === "supplier") {
    //     if(UserData?.is_admin_approved === 0){
    //         navigate("/customer/dashboard")
    //     }
    //     else if(UserData?.is_supplier_active === "inactive"){
    //         navigate("/customer/dashboard")
    //     }
    //     else if(UserData?.is_supplier_active === "active"){
    //         // navigate("/supplier/dashboard")
    //         navigate(String(location !== undefined && location?.pathname !== undefined ? location?.pathname : "/"))
    //     }
    //     else{
    //     dispatch(authHocTrueValue(false))
    //     document.body.classList.remove('admin-part');
    //     }

    // }
  }, [PathName, ToggleValue, Auth2, Auth3]);

  const isAuthedAdmin = () => {
    dispatch(authHocTrueValue(true));
    document.body.classList.add("admin-part");
  };

  const unAuth = () => {
    localStorage.removeItem("firtName");
    localStorage.removeItem("token");
    localStorage.removeItem("adminDetails");
    dispatch(authHocTrueValue(false));
    dispatch(adminDataValue(null));
    document.body.classList.remove("admin-part");
  };

  return AuthHoc === false ? (
    <Fragment>
      {/* {() => authUser()} */}

      {/* <HeaderLogin unAuth={unAuth}/> */}
      <div className="parent">
        <Routes>
          {children}

          <Route path="/admin" element={<Header unAuth={unAuth} />}>
            <Route index element={<Login isAuthedAdmin={isAuthedAdmin} />} />
            <Route path="resetmail" element={<ResetMail />} />
            <Route
              path="/admin/reset-passwordlink/:id"
              element={<ResetPassword />}
            />
            <Route path="sent-link-mail" element={<ResetPasswordLink />} />
          </Route>
          {/* <Route path="*" element={<Navigate to="/admin" />} /> */}
        </Routes>
      </div>
      <FooterHome />
    </Fragment>
  ) : (
    <Fragment>
      {/* {() => authAdmin()} */}

      {/* <HeaderAdmin isAuthed={isAuthed} unAuthedAdmin={unAuthedAdmin} /> */}
      <Header unAuth={unAuth} />
      <main className="main">
        <AdminRoutes setauth={setAuthTrue} isAuthedAdmin={isAuthedAdmin} />
      </main>
      <Footer />
    </Fragment>
  );
};

export default RequireAuth;
