import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { GiAutoRepair } from "react-icons/gi";
import { FaCube, FaFile } from "react-icons/fa";
import { Button, Spinner, Modal } from "react-bootstrap";
// icons
import { FaFilePdf, FaSearch,FaEnvelopeOpenText } from "react-icons/fa";
import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");
// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";
// react bootstrap
import ChangePassword from "../../common/changePassword/changePassword";
import { getMethodData, getSubmitData, postMethodData } from "../../adminApi/api";
import SendEmail from "../../common/sendEmail/sendEmail";
import { addPendingValue,adminManualQuote } from "../../../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import fileDownload from 'react-file-download';


const QuoteManagement = (props) => {
  let showDataToUser = false;
  const [donwloadquote,setdownloadquote]=useState(false);
  const [redirection,setRedirection] = useState(false);
  useLayoutEffect(() => {
    const ls = JSON.parse(localStorage.getItem("adminDetails"));
    // // console.log("uselayout", JSON.parse(localStorage.getItem("adminDetails")));
    
    for (let i = 0; i <= ls?.my_permissions?.length; i++) {
      if (ls?.my_permissions[i]?.name === "Quote Management") {
        for (let j = 0; j <= ls?.my_permissions[i]?.permissions?.length; j++) {
          if (ls?.my_permissions[i]?.permissions[j]?.name === "List") {
            // // console.log(ls?.my_permissions[i]?.permissions[j].name);
            showDataToUser = true;
          }
          if (ls?.my_permissions[i]?.permissions[j]?.name === 'Download Quote Details PDF') {
            // // console.log(ls?.my_permissions[i]?.permissions[j].name);
            setdownloadquote(true);
          }
        }
      }
    }
    // // console.log(showDataToUser);
    if(showDataToUser===false){
      // // console.log("showDataToUser ",showDataToUser)
      setRedirection(true);
    }
  }, []);
  const navigate = useNavigate();
   if (redirection) navigate('/admin/dashboard');
  // \\permission redirection
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const { id } = useParams();
  // const PORT = PORTNUMBER.PORTNUMBER
  const Token = localStorage.getItem("token");
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState();
  // modal active
  const [activeShow, setActiveShow] = useState(false);
  const [statusId, setStatusId] = useState();
  const [customerId, setCustomerId] = useState();
  const [quoteMail, setQuoteMail] = useState();
  const [loading, setLoading] = useState(false);
  const [statusType, setStatusType] = useState();
  // const [Loading, setLoading] = useState(false);
  const [quote, setQuote] = useState([]);
  const [Count, setCount] = useState(1);
  const [perPage, setPerPage] = useState(1);
  const [indexPage, setIndexPage] = useState(1);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  // const [filterData, setFilterData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterVisibility, setFilterVisibility] = useState(true);

  const [proposalload, setproposalload] = useState(false);
  const [quoteid, setquoteid] = useState("");
  const [quotepartid, setquotepartid] = useState("");
  const [customername, setcustomername] = useState("");

  const { 
    register, 
    control, 
    handleSubmit, 
    reset,
    formState: { errors } 
  } = useForm();

  const { 
    register:register2, 
    control:control2, 
    handleSubmit:handleSubmit2, 
    reset:reset2,
    formState: { errors:errors2 } 
  } = useForm();

  const dNone = "d-none";
  const activeHandleClose = () => setActiveShow(false);
  const activeHandleShow = (id) => {
    // setStatusType(status);
    setStatusId(id);
    setActiveShow(true);
  };

  const activeUser = async () => {
    getMethodData(`/quotes/change-status/${statusId}`, Token).then((res) => {
      if (res.data.status !== false) {
        LoadTableData();
        toast.success(res.data.message);
        activeHandleClose();
      } else {
        toast.error(res.data.message);
        activeHandleClose();
      }
    });
  };

  useEffect(() => {
    const data={
      first_name: "",
      from_date: "",
      is_manual_quote: "1",
      last_name: "",
      quote_id: "",
      status : "",
      to_date : ""
    }
  
   
  if(adminManualQuotestate===true){
  onSubmit(data);
  }
  else{
    LoadTableData();
  }
  dispatch(adminManualQuote(false))
   
  }, []);

  const sendPageFilter = (pageNumber) => {
  
    // if(pageNumber!==undefined){
      getSubmitData(
        `${props.filterApi}?page=${pageNumber}&${props.searchId}=${id}`,
        Token
      ).then((res) => {
        setQuote(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setLoading(true);
      });
    // }
  };

  const LoadTableData = useCallback(
    async (pageNumber) => {
      // if(id?.length){
      //     navigate("/admin/quote-management")
      // }
      setIndexPage(1);

      setLoading(false);
      await axios
        .get(`${BASE_URL}/quotes?page=${pageNumber === 1 ? 1 : pageNumber}`, {
          headers: { authorization: `Bearer ${Token}` },
        })
        .then((res) => {
          setQuote(res.data.result.data.data);
          setCount(res.data.result.data.total);
          setPerPage(res.data.result.data.per_page);

          setLoading(true);
          setFilterVisibility(true);
        });

      reset();
    },
    [BASE_URL, Token, reset],
    [quote, Count]
  );
  const sendPage = (pageNumber) => {
    axios
      .get(
        `${BASE_URL}/quotes?page=${pageNumber}&${new URLSearchParams(
          filterData
        ).toString()}`,
        { headers: { authorization: `Bearer ${Token}` } }
      )
      .then((res) => {
        setQuote(res.data.result.data.data);
        setCount(res.data.result.data.total);
        setPerPage(res.data.result.data.per_page);
        setLoading(true);
      });
  };

 
  const handlePageChange = (pageNumber) => {
    // sendPage(pageNumber)
    // LoadTableData(pageNumber)
    if (id?.length) {
      // LoadTableData(pageNumber)

      sendPageFilter(pageNumber);
    } else {
      if (filterVisibility === true) {
        LoadTableData(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
        setLoading(false);
      } else {
        sendPage(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    sendPageFilter();

    if (id) {
      sendPageFilter();
    } else {
      if(adminManualQuotestate===false){
        sendPage();
        LoadTableData();
      }
    }
  }, []);
  const adminManualQuotestate=useSelector(state=>state.adminManualQuote);

  const onSubmit = async (data) => {
    dispatch(addPendingValue(false));

   
    setLoadingButton(true);
    setIndexPage(1);
    setFilterData(data);
    data.from_date =
      !data.from_date || data.from_date === null || data.from_date === undefined
        ? ""
        : moment(data.from_date).format("YYYY/MM/DD");
    data.to_date =
      !data.to_date || data.to_date === null || data.to_date === undefined
        ? ""
        : moment(data.to_date).format("YYYY/MM/DD");

    setLoading(false);
    // sendPage()
    await axios
      .get(`${BASE_URL}/quotes?${new URLSearchParams(data).toString()}`, {
        headers: { authorization: `Bearer ${Token}` },
      })
      .then((res) => {
        setLoadingButton(false);

        setQuote(res.data.result.data.data);
        setCount(res.data.result.data.total);

        // setCount(res.data.data.userCount)
        // setFilterData(res.data.data.userData)
        setLoading(true);
        setFilterVisibility(false);
      });
    setLoadingButton(false);
  };
  const [modifyData, setModifyData] = useState();

  
  useEffect(() => {
    const BASE_URL = process.env.REACT_APP_BASE_URL_USER;
    axios.get(`${BASE_URL}/user/get-globle-config/QUOTE_MODIFY`).then((req) => {
      setModifyData(req?.data?.result?.data?.value);
      // // console.log(req?.data?.result?.data?.value);
    });
  }, []);


  const Proposalsend = async (data) =>{

    setproposalload(true)

    const formData = new FormData();
    const appendedKeys = [];
    Object.keys(data).forEach((key) => {
    
      if(key === "attachment"){
        formData.append("attachment", data.attachment[0]);
      }
      else {
        if (!appendedKeys.includes(key)) {
          formData.append(key, data[key]);
          appendedKeys.push(key); // add to the appended keys list
        }
      }
    });


    postMethodData(`/quotes/send-proposal/${quotepartid}`, formData,Token)
    .then((res) => {
        
      if (res?.data?.status === true) {
        setproposalload(false)
        toast(res?.data?.message)
        setActiveShow(false);
        reset2()
        LoadTableData()
        
      } else {
        setproposalload(false)
        setActiveShow(false);
        reset2()
        toast(res?.data?.message)
      }

      }).catch((error) => {
      // console.log(error);
      setproposalload(false)
      })
  }


    
  const handleDownload = (data,fileurl) => {
    const fileUrl = data;
    const fileName = fileurl;
    fileDownload(fileUrl, fileName);
  };



  const [todayDate,setTodayDate]=useState(null);
  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
           
            <div className="col-6">
              <div className="main-head">
               <h4 className="text-capitalize">Quote List {props.idProps && <>For:-{quote[0]?.customer?.first_name+" "+quote[0]?.customer?.last_name}</>}</h4>
              </div>
            </div>

            {props.idProps 
            && 
            <div className="col-6">
            <div className="theme-btn text-end top-head">
                <Link to="/admin/customer-management" className="btn">Back</Link>
              </div>
            </div>
          }
            
            
          </div>
        </div>
        {!props.idProps &&
        <div className="table-order-box shadow rounded overflow-hidden">
        <div className="tab-head">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="text"
                    maxLength="15"
                    {...register("first_name", { required: false })}
                    className="form-control"
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="text"
                    maxLength="15"
                    {...register("last_name", { required: false })}
                    className="form-control"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group before-con">
                  <Controller
                    control={control}
                    name="from_date"
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Click to select a date"
                        onChange={(date) => {
                          field.onChange(date);
                          setTodayDate(date);}}
                        selected={field.value}
                        maxDate={new Date()}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group before-con after">
                  <Controller
                    control={control}
                    name="to_date"
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Click to select a date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        minDate={todayDate==null? new Date() : todayDate}
                        maxDate={new Date()}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="text"
                    maxLength="15"
                    {...register("quote_id", { required: false })}
                    className="form-control"
                    placeholder="Quote Id"
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <select
                    className="form-select form-control"
                    {...register("status", { required: false })}
                    aria-label="Default select example"
                  >
                    <option className="disabled-value" value="">
                      All Status
                    </option>
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                    <option value="expired">Expired</option>
                    <option value="request submitted">Request Submitted</option>
                    <option value="proposal submitted">Proposal Submitted</option>
                    <option value="proposal accepted">Proposal Accepted</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <select
                    className="form-select form-control"
                    {...register("is_manual_quote", { required: false })}
                    aria-label="Quote Type" 
                  >
                    <option value="" className="disabled-value ">
                      All Quote
                    </option>
                    <option value="1" >Manual Quote</option>
                    <option value="2">Automated Quote</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="theme-btn btn-group-right text-end">
                  {loadingButton === false ? (
                    <button type="submit" className="btn">
                      Search
                    </button>
                  ) : (
                    <Button className="btn  spinner" variant="dark" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        role="status"
                        aria-hidden="true"
                      />
                      Searching...
                    </Button>
                  )}
                  <button
                    type="reset"
                    onClick={() => LoadTableData()}
                    className="btn"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
       }
        
      </div>
      <div className="project-table">
        <div className="form-search">
          <form>
            <div className="form-group icon-input top-search">
              <input
                type="text"
                id="searchForm"
                className="form-control"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                placeholder="Search"
              />
              <FaSearch />
            </div>
            <input type="btn" className="d-none" value="Submit" />
          </form>
        </div>
        <div className="table-order shadow">
          <table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th>Quote ID</th>
                <th>No. Of Parts</th>
                <th>Customer Name</th>
                <th>Quote Type</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
                <th>Actions </th>
              </tr>
            </thead>
            <tbody>
              {quote && quote.length ? (
                quote
                  .filter((val) => {
                    const date = val.created_at;
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.quote_id
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.customer.first_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.customer.last_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      moment(date)
                        .format("MM/DD/yyyy")
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else {
                      return false;
                    }
                  })
                  .map((quote, index) => (
                    <tr key={quote.id}>
                      <td className="s-no" scope="row">
                        {perPage * (indexPage - 1) + (index + 1)}
                      </td>
                      <td>#{quote.quote_id}</td>
                      <td>{quote.quote_parts_count}</td>
                      <td>
                        {quote?.customer?.first_name+" "+quote?.customer?.last_name}
                      </td>
                      <td>
                        {quote?.is_manual_quote===0 ?"Manual Quote":quote?.is_manual_quote===1 ?"Manual Quote":quote?.is_manual_quote===2 ?"Automated Quote":"-"}
                      </td>
                      <td>{quote.product_price != 0 ? "$"+quote.product_price :"-"}</td>
                      <td>{moment(quote.created_at).format("MM/DD/yyyy")}</td>

                      <td className="">
                      <span
                                  className={quote?.status==="open"?`badge bg-success ${quote?.status}`:quote?.status==="expired"?`badge bg-danger ${quote?.status}`:null}
                                >
                                  {quote.status}
                                </span>
                      </td>
                      <td className="action-top">
                        {quote.details_pdf ? (
                          <a
                            target={donwloadquote!==false?'_blank':""}
                            title="Download pdf"
                            href={donwloadquote!==false?`${quote.details_pdf}`:'#'}
                            className="icons-action" rel="noreferrer" 
                          >
                            <FaFilePdf />
                          </a>
                         
                        ) : (
                          <Link
                            title="Download pdf"
                            to=""
                            className="icons-action"
                          >
                            <FaFilePdf className="text-secondary" />
                          </Link>
                        )}
                        {modifyData==="Yes"?
                         quote?.quote_parts[0].auto_cad_file_path && <a
                        //  target={'_blank'}
                          download
                         title="Download 3d File"
                        //  onClick={()=> handleDownload(quote?.quote_parts[0]?.auto_cad_file_path,quote?.quote_parts[0]?.file_name)}
                         href={quote?.quote_parts[0].auto_cad_file_path}
                         className="icons-action" 
                        //  rel="noreferrer" 
                       >
                         
                         <FaCube className="text-primary"/>
                       </a> 
                        :null}

                        {quote?.is_manual_quote === 1 && (quote?.is_proposal_send != 1) && (quote.product_price === 0) &&
                          <span 
                          onClick={() =>
                            {
                            activeHandleShow(quote.quote_parts[0].id);
                            setquoteid(quote.quote_id);
                            setquotepartid(quote.quote_parts[0].id)
                            setcustomername(quote.customer.first_name + " " + quote.customer.last_name)
                          }
                            
                          }
                          title="Send Proposal" className="icons-action">
                            <FaEnvelopeOpenText/>
                          </span>
                        }

                        {quote?.proposal && 
                        <a
                        target={'_blank'}
                        title="View Proposal"
                        className="icons-action" rel="noreferrer"
                        href={quote?.proposal.attachment}>
                          <FaFile/>
                        </a>
                      }
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td className="no-data">NO QUOTE FOUND</td>
                </tr>
              )}
            </tbody>
          </table>
          {!loading ? (
            <div className="loader-wrapper">
              <div className="loader">
                <div className="loading-svg">
                  <GiAutoRepair />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
        </div>
        {!props.idProps && 
        <div className="pagination">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={perPage}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        </div>}
        
      </div>

      {/* modals */}

      {/* active modal */}
      <Modal
        className="status-modal"
        animation={false}
        show={activeShow}
        onHide={activeHandleClose}
      >
        <Modal.Body>
          <b>Proposal Submission for Quote:- #{quoteid}</b>

          <form onSubmit={handleSubmit2(Proposalsend)}>
            <div className="row mt-2">
              <div className="col-lg-12">
                <div className="form-group">
                  <label><b>Proposal Price</b></label>
                  <div className="position-relative">
                  <span className="dollr-icn">$</span>
                  <input
                    min={1}
                    type="number"
                    step="any"
                    // maxLength="10"
                    {...register2("price", { required: true })}
                    className="form-control"
                    id=""
                    aria-describedby=""
                    placeholder="Price"
                  />
                  </div>
                  {errors2.price && (
                      <span className="text-xs invalid-feedback-new">
                        This field is required
                      </span>
                    )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label><b>Proposal Document</b></label>
                  <input
                    type="file"
                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,image/gif, image/jpeg,image/png"
                    // maxLength="10"
                    {...register2("attachment", { required: true })}
                    className="form-control"
                    id=""
                    aria-describedby=""
                    placeholder="Attachment"
                  />
                  {errors2.attachment && (
                      <span className="text-xs invalid-feedback-new">
                        This field is required
                      </span>
                    )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label><b>Email Message</b></label>
                  <textarea
                  {...register2("content", { required: true })}
                  placeholder="Content"
                  className="form-control"
                  defaultValue={`Hi ${customername}\nYour Text here...\n\n\n\nBest Regards,\nMach2Mfg Team`}
                  >
                  {errors2.content && (
                      <span className="text-xs invalid-feedback-new">
                        This field is required
                      </span>
                    )}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="theme-btn btn-group-right text-end">

              {proposalload ?
              <Button className="btn  spinner" variant="dark" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
                Sending...
              </Button>
              :
              <Button type="submit" variant="secondary">
                  Send
              </Button>
              }
              
              {/* <Button variant="primary" onClick={activeHandleClose}>
                No
              </Button> */}
            </div>
          </form>

        </Modal.Body>
        {/* <Modal.Footer>
          <div className="theme-btn btn-group-right text-end">
            <Button variant="secondary" onClick={activeUser}>
              Yes
            </Button>
            <Button variant="primary" onClick={activeHandleClose}>
              No
            </Button>
          </div>
        </Modal.Footer> */}
      </Modal>
      {/* active modal end */}

      <ToastContainer />
      <ChangePassword
        api={`/customers/change-password/${customerId}`}
        show={showPassword}
        dNone={dNone}
        onHide={() => setShowPassword(false)}
      />
      <SendEmail
        api={"/suppliers/send-contect-email"}
        show={showEmail}
        quoteMail={quoteMail}
        dNone={dNone}
        onHide={() => setShowEmail(false)}
      />
    </div>
  );
};
export default QuoteManagement;
