import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ImageObj } from "../../../../assets/images/imgeObject";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { getMethodData } from "../../adminApi/api";
import moment from "moment";
import { FaCheckCircle, FaClock, FaCube, FaFilePdf } from "react-icons/fa";
import fileDownload from 'react-file-download';

const BidDetails = () => {
    let showDataToUser = false;
    const [downloadcadfile,setdownloadcadfile]=useState(false)
    const [redirection,setRedirection] = useState(false);

    const [expirytime,setexpirytime] = useState()
    const [opentime,setopentime] = useState()
    const [servertime,setservertime] = useState()

    const [currentTime, setCurrentTime] = useState();



  useLayoutEffect(() => {
    const ls = JSON.parse(localStorage.getItem("adminDetails"));
    // console.log("uselayout", JSON.parse(localStorage.getItem("adminDetails")));

    for (let i = 0; i <= ls?.my_permissions?.length; i++) {
      if (ls?.my_permissions[i]?.name === "Bid Management") {
        for (let j = 0; j <= ls?.my_permissions[i]?.permissions?.length; j++) {
          if (
            ls?.my_permissions[i]?.permissions[j]?.name === 'View Bid Details'
          ) {
            // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
            showDataToUser = true;           
          }
          if (
            ls?.my_permissions[i]?.permissions[j]?.name === "Download  Auto Cad  File"
          ) {
            // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
            setdownloadcadfile(true);            
            // console.log('setdownloadcadfile',downloadcadfile)
          }
        }
      }
    }
    // console.log(showDataToUser);
    if(showDataToUser===false){
      // console.log("showDataToUser ",showDataToUser)
      setRedirection(true);
    }
  }, []);
  
  const navigate = useNavigate();
    if (redirection) navigate('/admin/bid-management');
  const { id } = useParams();
  // const PORT = PORTNUMBER.PORTNUMBER
  const Token = localStorage.getItem("token");
  const [editData, setEditData] = useState(["not"]);

  const getdetails = () => {
    getMethodData(`/bid-details/${id}`, Token).then((res) => {
      setEditData(res.data.result.data);
      setexpirytime(res?.data?.result?.data?.expiry_date_time)
      setservertime(res?.data?.result?.data?.server_time)
      setCurrentTime(res?.data?.result?.data?.server_time)
      setopentime(res?.data?.result?.data?.open_date_time)
      // if(res.data.result.data?.status === "Open"){
      //   startTimer(res?.data?.result?.data?.expiry_date_time,res?.data?.result?.data?.server_time);
      //   console.log("Open")
      // }
      // if(res.data.result.data?.status === "Upcoming"){
      //   startTimer(res?.data?.result?.data?.open_date_time,res?.data?.result?.data?.server_time);
      //   console.log("Upcoming")
      // }
    });
  };

  useEffect(() => {
    getdetails();
  }, []);

  const handleDownload = (data,fileurl) => {
    const fileUrl = data;
    const fileName = fileurl;
    fileDownload(fileUrl, fileName);
  };

  // let interval = useRef();

  // const startTimer = ()=> {
  //   interval.current = setInterval(() => {
  //     const now = new Date(servertime);
  //     const expiryDate = new Date(expirytime);
  //     const diff = Math.floor((expiryDate - now) / 1000); // get difference in seconds

  //     if (diff <= 0) {
  //       clearInterval(interval.current);
  //       setTimeRemaining('Timer expired');
  //     } else {
  //       const hours = Math.floor(diff / 3600);
  //       const minutes = Math.floor((diff % 3600) / 60);
  //       const seconds = diff % 60;
  //       setTimeRemaining(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
  //     }
  //   }, 1000);
  // }

  // useEffect(() => {
 

    
  // }, [servertime, expirytime]);


  const [timerDay, setTimerDay] = useState("00");
  const [timerHour, setTimerHour] = useState("00");
  const [timerMinute, setTimerMinute] = useState("00");
  const [timerSecond, setTimerSecond] = useState("00");

  let interval = useRef();

  const startTimer = (expire,server) => {
    const countDowndate = new Date(expire).getTime();

    interval.current = setInterval(() => {
      // const now = new Date(servertime).getTime();
      let distance = countDowndate - new Date(server).getTime();
      // console.log("=============>>>>>>>>>>>",distance)
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + (days * 24));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDay(days.toLocaleString().length <= 1 ? "0" + days : days);
        setTimerHour(hours.toLocaleString().length <= 1 ? "0" + hours : hours);
        setTimerMinute(minutes.toLocaleString().length <= 1 ? "0" + minutes : minutes);
        setTimerSecond(seconds.toLocaleString().length <= 1 ? "0" + seconds : seconds);
        setCurrentTime(new Date(currentTime).getTime() + 1000); 
      }
    }, 1000);
  };

  useEffect(() => {
    if(editData?.status === "Open"){
      startTimer(expirytime,currentTime);
      console.log("Open")
    }
    if(editData?.status === "Upcoming"){
      startTimer(opentime,currentTime);
      console.log("Upcoming")
    }
    return () => {
      clearInterval(interval.current);
    };
    // return () => clearInterval(intervalId);
  },[expirytime,currentTime,opentime]);


  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="main-head">
                <h4>Bid Details -#{editData?.bid_id ?? 0}</h4>
              </div>
            </div>
            <div className="col-6">
              <div className="theme-btn text-end top-head">
                <Link to="../bid-management" className="btn">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="table-order-box shadow">
          <div className="tab-head">
            <div className="row">
              <div className="col-lg-6 position-relative">
                <div className="row">
                  <div className="col-6">
                    <p>Customer Name</p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span className="bold-text">
                        {editData?.order?.customer?.first_name}{" "}
                        {editData?.order?.customer?.last_name}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Order ID</p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span className="bold-text">
                        #{editData?.order?.order_id}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Bid ID</p>
                  </div>
                  <div className="col-6">
                    <p>
                      <span className="bold-text">#{editData?.bid_id}</span>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Order Amount</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      ${editData?.order?.order_product[0]?.total_price}
                    </span>
                    <p />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Bid Status</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">{editData?.status}</span>
                    <p />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-6">
                    <p>Bid Start Date</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      {moment(editData?.open_date_time).format("MM/DD/yyyy")}
                    </span>
                    <p />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Bid Start Time</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      {moment(editData?.open_date_time).format("hh:mm a")}
                    </span>
                    <p />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Bid End Date</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      {moment(editData?.expiry_date_time).format("MM/DD/yyyy")}
                    </span>
                    <p />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>Bid End Time</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      {moment(editData?.expiry_date_time).format("hh:mm a")}
                    </span>
                    <p />
                  </div>
                </div>
                {editData?.status === "Open"
                &&
                <div className="row text-danger">
                <div className="col-6">
                    <p>Bid Ending In</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      {timerHour || "00"}:{timerMinute || "00"}:{timerSecond || "00"} <FaClock/>
                    </span>
                    <p />
                  </div>
                </div>
                }

                {editData?.status === "Upcoming" 
                &&
                <div className="row">
                <div className="col-6">
                    <p>Bid Opening In</p>
                  </div>
                  <div className="col-6">
                    <span className="bold-text">
                      {timerHour || "00"}:{timerMinute || "00"}:{timerSecond || "00"} <FaClock/>
                    </span>
                    <p />
                  </div>
                </div>
                }
                
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-12 d-flex justify-content-center justify-content-lg-start dashboard-heading-block">
                {/* <img src={ImageObj.PartDetail} width="56px" height="56px" className="mt-2 me-4 d-none d-lg-block" /> */}
                <div>
                  <h3 className="text-center mt-4 text-lg-start">
                    Bid Details
                  </h3>
                </div>
              </div>
              <div className="col-lg-3" />
            </div>

            <div className="gx-0 grid-data">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row gx-0 grid-head-row">
                    <div className="col">
                      <span className="data-value">Sr. No.</span>
                    </div>
                    <div className="col">
                      <span className="data-value">Part Name</span>
                    </div>
                    <div className="col">
                      <span className="data-value">Unit Cost</span>
                    </div>
                    <div className="col">
                      <span className="data-value">Quantity</span>
                    </div>
                    <div className="col">
                      <span className="data-value"> Bid Amount</span>
                    </div>
                    <div className="col">
                      <span className="data-value">Action</span>
                    </div>
                  </div>
                  {editData &&
                  editData !== undefined &&
                  editData?.order?.order_product !== undefined ? (
                    editData?.order?.order_product?.map((res, i) => {
                      return (
                        <div className="row px-0 gx-0 grid-data-row grey-box">
                          <div className="row p-0 m-0">
                            <div className="col" data-label="Sr. No.: ">
                              <span className="data-value">{i + 1}</span>
                            </div>
                            <div className="col" data-label="Part Name: ">
                              <span className="data-value">
                                {res.file_name}
                              </span>
                            </div>
                            <div className="col" data-label="Unit Cost: ">
                              <span className="data-value">
                                ${res.unit_price}
                              </span>
                            </div>
                            <div className="col" data-label="Quantity: ">
                              <span className="data-value">{res.qty}</span>
                            </div>

                            <div className="col" data-label="Total: ">
                              <span className="data-value">
                                ${editData?.offer_amount}
                              </span>
                            </div>
                            <div className="col" data-label="Total: ">
                              <a
                                download
                                // onClick={()=> handleDownload(res?.auto_cad_file_path,res?.file_name)}
                                // target="_blank"
                                // target={downloadcadfile !==false ? "_blank":""}
                                href={res?.auto_cad_file_path}
                                className="icons-action"
                                title="Download 3D File"
                              >
                                <FaCube /> 
                              </a>
                            </div>
                          </div>

                          <div className="d-flex flex-wrap">
                            <div className="col" data-label="Sr. No.: ">
                              {/* <img
                                className="h-115 w-100"
                                src={ImageObj.ProductBig}
                              /> */}
                            </div>
                            <div className="col" data-label="Sr. No.: ">
                              <span className="data-value me-3">
                                <strong>Dimensions:-</strong> {res.dimensions}
                              </span>
                              <span className="data-value me-3">
                                <strong>Volume:-</strong> {res.volume}
                              </span>
                              <span className="data-value me-3">
                                <strong>File Units:-</strong> {res.file_units}
                              </span>
                              <span className="data-value me-3">
                                <strong>Manufacturing Process:-</strong>{" "}
                                {res.manufacturing_process}{" "}
                                {res.custom_manufacturing_process ?? ""}
                              </span>
                              <span className="data-value me-3">
                                <strong>Material:-</strong> {res.material}{" "}
                              </span>
                              <span className="data-value me-3">
                                  <strong>Custom Material Value:-</strong> {res.custom_material ?? ""}
                                </span>
                              <span className="data-value me-3">
                                <strong>Coating:-</strong> {res.finish ?? ""}
                              </span>
                              <span className="data-value me-3">
                                <strong>Custom Coating:-</strong>
                                {res.custom_finish ?? ""}
                              </span>
                              {/* <span className="data-value me-3">
                                <strong>Threads:-</strong> {res.threads}
                              </span> */}
                              <span className="data-value me-3">
                                <strong>Tolerance:-</strong> {res.tolerance}{" "}
                              </span>
                              <span className="data-value me-3">
                                <strong>Surface Roughness:-</strong>{" "}
                                {res.surface_roughness}
                              </span>
                              <span className="data-value me-3">
                                <strong>Part Marking:-</strong>{" "}
                                {res.part_marking}
                              </span>
                              <span className="data-value me-3">
                                <strong>Inspection:-</strong> {res.inspection}{" "}
                              </span>
                              <span className="data-value me-3">
                                <strong>Custom Inspection:-</strong>
                                {res.custom_inspection ?? ""}
                              </span>
                              <span className="data-value me-3">
                                <strong>
                                  Part Quality Certifications:-
                                </strong>{" "}
                                {res.part_qualifications_certifications}
                              </span>
                              <span className="data-value me-3">
                                <strong>Material Certifications:-</strong>{" "}
                                {res.material_certifications}
                              </span>
                              <span className="data-value me-3">
                                <strong>Notes:-</strong> {res.notes ?? ""}
                              </span>
                            </div>
                            <div className="col-2" data-label="Sr. No.: ">
                              <span className="data-value"></span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <span className="no-data fs-1 fw-bolder text-uppercase opacity-25 text-dark">
                      No Parts Found
                    </span>
                  )}
                </div>
                <div className="col-lg-3" />
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-lg-12 d-flex justify-content-center justify-content-lg-start dashboard-heading-block">
                {/* <img src={ImageObj.PartDetail} width="56px" height="56px" className="mt-2 me-4 d-none d-lg-block" /> */}
                <div>
                  <h3 className="text-center text-lg-start mt-5">Offer Bids</h3>
                </div>
              </div>
              <div className="col-lg-3" />
            </div>

            <div className="gx-0 grid-data">
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="row gx-0 grid-head-row mb-3">
                    <div className="col">
                      <span className="data-value">Sr. No.</span>
                    </div>
                    <div className="col">
                      <span className="data-value">Supplier Name</span>
                    </div>
                    <div className="col">
                      <span className="data-value">Bid Date</span>
                    </div>
                    <div className="col">
                      <span className="data-value">Offer Amount</span>
                    </div>
                    <div className="col">
                      <span className="data-value">Awarded To </span>
                    </div>
                  </div>
                  {editData &&
                  editData !== undefined &&
                  editData?.bid_response !== undefined ? (
                    editData?.bid_response?.map((res, i) => {
                      return (
                        <div className="row px-0 gx-0 grid-data-row alternate-color">
                          <div className="row p-0 m-0 px10-col ">
                            <div className="col" data-label="Sr. No.: ">
                              <span className="data-value">{i + 1}</span>
                            </div>
                            <div className="col" data-label="supplier Name: ">
                              <span className="data-value">
                                {res?.supplier?.first_name}{" "}
                                {res?.supplier?.last_name}
                              </span>
                            </div>
                            <div className="col" data-label="Date: ">
                              <span className="data-value me-2">
                                {res.proposal_resive_date !== null
                                  ? moment(res.proposal_resive_date).format(
                                      "MM/DD/yyyy"
                                    )
                                  : ""}
                              </span>
                              <span>
                                {" "}
                                {res.proposal_resive_date !== null
                                  ? moment(res.proposal_resive_date).format(
                                      "HH:mm"
                                    )
                                  : "00"}{" "}
                                Hrs
                              </span>
                            </div>

                            <div className="col" data-label="Proposal Amount: ">
                              <span className="data-value">
                                ${res?.proposal_amount}
                              </span>
                            </div>
                            <div className="col" data-label="Status: ">
                              <span className="data-value">
                                {res?.status === "Awarded" && (
                                  <FaCheckCircle className="text-success fs-5" />
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <span className="no-data fs-1 fw-bolder text-uppercase opacity-25 text-dark">
                      No Parts Found
                    </span>
                  )}
                </div>
                <div className="col-lg-3" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};
export default BidDetails;
