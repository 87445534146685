import React, { useCallback, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// import { Bar } from 'react-chartjs-2';
import faker from "faker";
import { getMethodData } from "../../adminApi/api";
import BarChart from "./chart";
import { FaFileImage, FaRegFile } from "react-icons/fa";
import { GiAutoRepair } from "react-icons/gi";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { addPendingValue, adminManualQuote } from "../../../../redux/actions/action";
import { useDispatch,useSelector } from "react-redux";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);
  // const [count, setcount] = useState();
  const [customerChartData, setCustomerChartData] = useState([]);
  const [supplierChartData, setSupplierChartData] = useState([]);
  // const [materialChartData, setMaterialChartData] = useState([]);
  // const [paymentChartData, setPaymentChartData] = useState([]);
  const [revenueChartData, setRevenueChartData] = useState([]);
  const [sellChartData, setSellChartData] = useState([]);
  const [resentOrders, setResentOrders] = useState([]); 
  const [resentQuotes, setResentQuotes] = useState([]);
  const [ordersChartData, setOrdersChartData] = useState([]);
  const [quotesChartData, setQuotesChartData] = useState([]);

  const Token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);

  const dispatchPending = () => {
    dispatch(addPendingValue(true));
    navigate("/admin/supplier-management");
  };

  const getDashboardData = useCallback(() => {
    getMethodData("/get-dashboard-data", Token).then((res) => {
      setLoading(true);

      setDashboardData(res.data.result.data);
      //  setcount(res?.data?.result?.data?.recent_orders[0]?.total_attended);
      setCustomerChartData(res.data.result.data.customer_chart_data);
      setSupplierChartData(res.data.result.data.suplier_chart_data);
      // setMaterialChartData(res.data.result.data.material_chart_data);
      // setPaymentChartData(res.data.result.data.payment_chart_data);
      setRevenueChartData(res.data.result.data.revenue_chart_data);
      setSellChartData(res.data.result.data.sell_chart_data);
      setResentOrders(res.data.result.data.recent_orders);
      setResentQuotes(res.data.result.data.recent_quotes);
      setOrdersChartData(res.data.result.data.orders_chart_data);
      setQuotesChartData(res.data.result.data.quotes_chart_data);
    });
  }, []);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);
  const toggle = useSelector((state) => state);
  // console.log(toggle.adminManualQuote)

useEffect(() => {
  if(JSON.parse(localStorage.getItem("adminDetails")).my_permissions.find(module => module.name != 'Dashboard')){
    return navigate(`/admin/${JSON.parse(localStorage.getItem("adminDetails")).my_permissions[0]?.url}`)
  }
},[])
 


  return (
    <>
      <section className="analyst-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-head">
                <h4>Dashboard</h4>
              </div>
            </div>
            <div className="col-12 p-0">
              <div className="row">
                <div className="col-md-12 ">
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <Link
                        to="/admin/customer-management"
                        className="box rounded graph shadow"
                      >
                       
                        <div className="box-con">
                          <p>Total Customer </p>
                          <h3>{dashboardData.total_customers}</h3>
                        </div>
                      </Link>
                    </div>

                    <div className="col-md-3 col-sm-12">
                      <Link
                        to="/admin/supplier-management"
                        className="box graph rounded shadow"
                      >
                        <div className="box-con green">
                          <p>Total Supplier</p>
                          <h3>{dashboardData.total_supliers}</h3>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <Link
                        to="/admin/quote-management"
                        className="box graph rounded shadow"
                        onClick={()=>dispatch(adminManualQuote(true))}
                      >
                        <div className="box-con green">
                          <p>Manual Quotes</p>
                          <h3>{dashboardData.total_manual_quote}</h3>
                        </div>
                      </Link>
                    </div>

                    <div className="col-md-3 col-sm-12">
                      <Link
                        to="/admin/attention-order"
                        className="box  graph rounded shadow bg-danger"
                      >
                        <div className="box-con ">
                          <p className="text-white">Need Attention Orders</p>
                          <h3 className="text-white">
                            {dashboardData.need_attestation ?? 0}
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="tab-head box rounded shadow">
                        <div className="graph ">
                          <div className="box-con mb-2 p-0">
                            <p>Graph - Supplier/Month </p>
                          </div>
                          <BarChart chartData={supplierChartData} />

                          {/* <Bar data={data} /> */}
                          {/* <Bar options={options} data={data} /> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="tab-head box rounded shadow">
                        <div className="graph ">
                          <div className="box-con mb-2 p-0">
                            <p>Graph - Customer/Month </p>
                          </div>
                          {/* <Bar data={data} /> */}
                          {/* <Bar options={options} data={data} /> */}
                          <BarChart chartData={customerChartData} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="box graph rounded shadow">
                        <div className="box-con green">
                          <p>Quote queue </p>
                          <h3>{dashboardData?.total_quote_queue || "0"}</h3>
                        </div>
                      </div>
                      {/* <div className="tab-head box rounded shadow">
                                            <div className="graph ">
                                                <div className="box-con mb-2 p-0">
                                                    <p>Material Graph</p>
                                                </div>
                                                <BarChart chartData={materialChartData} />
                                            </div>
                                        </div> */}
                    </div>
                    <div
                      onClick={() => dispatchPending()}
                      className="col-md-6 col-sm-6 pointer"
                    >
                      <div className="box graph rounded shadow">
                        <div className="box-con green">
                          <p>Supplier Waiting Approval </p>
                          <h3>{dashboardData.approve_pending_supliers}</h3>
                        </div>
                      </div>
                      {/* <div className="tab-head box rounded shadow">
                                            <div className="graph ">
                                                <div className="box-con mb-2 p-0">
                                                    <p>Material Graph</p>
                                                </div>
                                                <BarChart chartData={materialChartData} />
                                            </div>
                                        </div> */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="box rounded graph shadow">
                        <div className="box-con">
                          <p>Total Sell</p>
                          <h3>$ {dashboardData.total_sell}</h3>
                        </div>
                      </div>
                      <div className="tab-head box rounded shadow">
                        <div className="graph ">
                          <div className="box-con mb-2 p-0">
                            <p>Graph - Sell/Month </p>
                          </div>
                          <BarChart chartData={sellChartData} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="box rounded graph shadow">
                        <div className="box-con">
                          <p>Total Revenue</p>
                          <h3>$ {dashboardData.total_revenue}</h3>
                        </div>
                      </div>
                      <div className="tab-head box rounded shadow">
                        <div className="graph ">
                          <div className="box-con mb-2 p-0">
                            <p>Graph - Revenue/Month </p>
                          </div>
                          <BarChart chartData={revenueChartData} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-sm-6">
                                        <div className="box rounded graph shadow">
                                            <div className="box-con">
                                                <p>Total Supplier Payment	</p>
                                                <h3>{dashboardData.total_supplier_payment}</h3>
                                            </div>
                                        </div>
                                        <div className="tab-head box rounded shadow">
                                            <div className="graph ">
                                                <div className="box-con mb-2 p-0">
                                                    <p>Graph - Payment/Month </p>
                                                </div>
                                                <BarChart chartData={paymentChartData} />

                                            </div>
                                        </div>
                                    </div> */}

                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <Link
                        to="/admin/order-management"
                        className="box rounded graph shadow"
                      >
                        <div className="box-con">
                          <p>Total Orders </p>
                          <h3>{dashboardData.total_orders}</h3>
                        </div>
                      </Link>
                      <div className="tab-head box rounded shadow">
                        <div className="graph ">
                          <div className="box-con mb-2 p-0">
                            <p>Orders/Month </p>
                          </div>
                          <BarChart chartData={ordersChartData} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <Link
                        to="/admin/quote-management"
                        className="box rounded graph shadow"
                      >
                        <div className="box-con">
                          <p>Total Quotes </p>
                          <h3>{dashboardData.total_quotes}</h3>
                        </div>
                      </Link>
                      <div className="tab-head box rounded shadow">
                        <div className="graph ">
                          <div className="box-con mb-2">
                            <p>Quotes/Month </p>
                          </div>
                          <BarChart chartData={quotesChartData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-12 p-0">
              <div className="tables-field">
                <div className="project-head">
                  <div className="main-head">
                    <h4 className="text-dark">Recent Orders </h4>
                  </div>
                </div>
                <div className="project-table">
                  <div className="table-order shadow">
                    <table>
                      <tbody>
                        <tr>
                          <th>Sr. No</th>
                          <th>Date</th>
                          <th>Order ID</th>
                          <th>Customer Name</th>
                          <th>Status</th>
                        </tr>
                        {resentOrders !== null && resentOrders.length ? (
                          resentOrders.map((order, index) => (
                            <tr key={order._id}>
                              <td className="s-no" scope="row">
                                {index + 1}
                              </td>
                              <td>
                                {moment(order.created_at).format("MM/DD/yyyy")}
                              </td>

                              {/* <td>{order.order_id}</td> */}
                              <td>{order?.order_id}</td>
                              <td>
                                {order?.customer?.first_name}{" "}
                                {order?.customer?.last_name}
                              </td>
                              <td className="">
                                <span
                                  className={`badge bg-success ${order.status}`}
                                >
                                  {order.status}
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="no-data text-uppercase">
                              NO Orders Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {!loading ? (
                      <div className="loader-wrapper">
                        <div className="loader">
                          <div className="loading-svg">
                            <GiAutoRepair />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12  p-0">
              <div className="tables-field">
                <div className="project-head">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="main-head">
                          <h4 className="text-dark">Recent Quotes</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="project-table">
                  <div className="table-order shadow">
                    <table>
                      <tbody>
                        <tr>
                          <th>Sr. No</th>
                          <th>Date</th>
                          <th>Quote ID</th>
                          <th>Customer Name</th>
                          <th>Quote Type</th>
                          <th>Status</th>
                        </tr>
                        {resentQuotes !== null && resentQuotes.length ? (
                          resentQuotes.map((quotes, index) => (
                            <tr key={quotes._id}>
                              <td className="s-no" scope="row">
                                {index + 1}
                              </td>
                              <td>
                                {moment(quotes.created_at).format("MM/DD/yyyy")}
                              </td>

                              <td>{quotes.quote_id}</td>
                              <td>
                                {quotes?.customer?.first_name}{" "}
                                {quotes?.customer?.last_name}
                              </td>
                              <td>
                        {quotes?.is_manual_quote===0 ?"Manual Quote":quotes?.is_manual_quote===1 ?"Manual Quote":quotes?.is_manual_quote===2 ?"Automated Quote":"-"}
                      </td>
                              <td className="">
                                <span
                                  className={quotes?.status==="open"?`badge bg-success text-capitalize ${quotes?.status}`:quotes?.status==="expired"?`badge bg-danger text-capitalize ${quotes?.status}`:null}
                                >
                                  {quotes.status}
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="no-data text-uppercase">
                              NO Quotes Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

};
export default Dashboard;
