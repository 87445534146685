import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'


const ResetPasswordLink = () => {
    const navigate = useNavigate()
    const Jump = () => {
        navigate("/admin")

    }
    useEffect(() => {
        document.body.classList.add("remove-header");
    }, [])

    useEffect(() => {
        document.body.classList.add("remove-user");


        return () => {
            document.body.classList.remove("remove-user");
        }
    }, [])

    return (
        <div className="container">
            <div className="max-new-second login">
                <div className="main-theme bg-theme border otp-pad new shadow">
                    <section className="select-state">
                        <div className="state-bor">
                            <div className="log-in otp-num">
                                <div className="select-steps-box">
                                    <div className="input-head">
                                        <h2>Check Your Email</h2>
                                        <p>We have sent an email to your registered email address. Please check that to continue.</p>
                                    </div>
                                </div>
                                <div className="select-box-top pr-0">

                                    <div className="theme-btn">
                                        <button className="btn verfy-btn" onClick={Jump}>Go Back To Login </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordLink;