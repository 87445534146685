import React, { useState, useEffect, useCallback, Link } from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import SideNav from "../../common/sidenav/sidenav";
import { useForm, Controller } from "react-hook-form";
import { getMethodData } from "../../adminApi/api";
import { postMethodData } from "../../adminApi/api";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegBell } from "react-icons/fa";



const NotificationBell = () => {
  const Token = localStorage.getItem("token");
  const [value, setValue] = useState([]);
  const [time, settime] = useState();

  const getProfile = useCallback(() => {
    getMethodData("/notifications", Token).then((res) => {
      setValue(res.data.result.data);
    });
  }, [Token]);

   function  clear_notification() {
    getMethodData("/clear-notifications", Token)
    setValue([])
  }

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <section className="analyst-sec">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="main-head">
                <h4>Notification List</h4>
              </div>
            </div>
            {value?.length === 0 ? (
              <></>
            ) : (
              <div className="col-6">
                <div className="theme-btn top-head text-end">
                  <button onClick={() => { clear_notification()}}  className="btn">
                    Clear All
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-md-12 col-sm-12 mt-3  ">
          {value?.length === 0 ? (
            <div className="notification-font">No Notification</div>
          ) : (
            <>
              {" "}
              {value?.map((item) => {
              
                return (
                  <div className="tab-head box rounded shadow d-flex align-items-center py-2 px-5 mb-3">
                  <div style={{ fontSize: "30px" }}>
                    <FaRegBell />
                  </div>
                  <div className="graph w-100" >
                    <div className="box-con pl-3  d-flex align-items-center justify-content-between  ">
                      <p> {item.title} </p>
                      <span>{item.message}</span>
                 
                      <span style={{ fontSize: "11px" }}>
                        {item.notification_age}
                      </span>
                    </div>
                  </div>
                </div>
                );
              })}
            </>
          )}
        </div>
      </section>
    </>
  );
};
export default NotificationBell;
