import React, { useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { Button, Spinner } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {postMethodData } from "../../adminApi/api";

const AddNewcoupen = () => {

  let  showDataToUser =false; 
  const [redirection,setRedirection] = useState(false);
  useLayoutEffect(() => {
      const ls=JSON.parse(localStorage.getItem("adminDetails"));
    // // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));
     
      for(let i=0; i<=ls?.my_permissions?.length;i++){
          if(ls?.my_permissions[i]?.name==="Coupon code"){
              
              for(let j=0; j<=ls?.my_permissions[i]?.permissions?.length;j++){ 
                  if(ls?.my_permissions[i]?.permissions[j]?.name==="Add"){
                      // // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
                      showDataToUser = true;
                      i=ls?.my_permissions?.length;
                      break;
                  }
              }
          }
      }
      // // console.log(showDataToUser);
      if(showDataToUser===false){
        // // console.log("showDataToUser ",showDataToUser)
        setRedirection(true);
      }
  }, []);
  const navigate = useNavigate();
    if (redirection) navigate('/admin/coupen-management/');
  // form validation rules
  const validationSchema = Yup.object().shape({
    // password: Yup.string()
    //   .required("Password is required")
    //   .min(6, "Password is too short - should be 6 chars minimum.")
    //   .max(18, "Password is too long - should be 18 chars minimum.")
    //   .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
    //     "Please Check Password Format"
    //   ),
    // confirm_password: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "Passwords must match")
    //   .min(6, "Confirm password is too short - should be 6 chars minimum.")
    //   .max(18, "Password is too long - should be 18 chars minimum.")
    //   .required("Confirm Password is required"),
    // email: Yup.string().email("The email must be a valid email. "),
    // phone: Yup.string().min(14).required("A phone number is required"),
    // zipcode: Yup.string().min(5).required("A zipcode is required").max(5),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, control, formState } =
    useForm(formOptions);
  const { errors } = formState;

  // const dispatch = useDispatch()
  // const PORT = PORTNUMBER.PORTNUMBER

  const Token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);


  const onSubmit = (data) => {
    setLoading(true);

    data.from =
      !data.from || data.from === null || data.from === undefined
        ? ""
        : moment(data.from).format("YYYY/MM/DD");
    data.to =
      !data.to || data.to === null || data.to === undefined
        ? ""
        : moment(data.to).format("YYYY/MM/DD");
    const fs = new FormData();
    for (var key in data) {
      fs.append(key, data[key]);
    }

    postMethodData("/couponcodes/store", fs, Token)
      .then((res) => {
        if (res?.data?.status === true) {
          setLoading(false);
          toast(res?.data?.message);
          navigate("/admin/coupen-management");
          reset();
        } else {
          setLoading(false);
        
          toast.error("Somthing Went Wrong!");
        }

        setLoading(false);
      })
      .catch((error) => {
        // // console.log(error);
      });

    return false;
  };

const [todayDate,setTodayDate]=useState(null);
  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="main-head">
                <h4>Add New Coupon</h4>
              </div>
            </div>
            <div className="col-6">
              <div className="theme-btn text-end top-head">
                <Link to="/admin/coupen-management" className="btn">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="table-order-box shadow">
          <div className="tab-head">
            <small className=" form-text d-block text-center mb-4 text-muted">
           
            </small>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="needs-validation"
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      {...register("coupon_name", { required: false })}
                      className="form-control"
                      placeholder="Coupon Name"
                      required
                    />
                    <div className="invalid-feedback">
                      Please Enter Coupen Name.
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="form-group">
                      <select
                        className="form-select form-control"
                        {...register("discount_type", { required: false })}
                        id="validationCustom12"
                        aria-label="Default select example"
                        required
                      >
                        <option className="disabled-value d-none" value="">
                        Discount Type
                        </option>
                        <option value="percentage">percentage</option>
                        <option value="flat">flat</option>
                      </select>
                      <div className="invalid-feedback">
                        Please Enter Discount Type.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                    min={1}
                      type="number"
                      {...register("discount", { required: true })}
                      className="form-control"
                      placeholder="Discount Amount"
                    />
                    <small className="text-danger">
                      {" "}
                      {errors.email?.message}
                    </small>

                    <div className="invalid-feedback">
                      Please Enter Discount Amount.
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group before-con">
                    <Controller
                      control={control}
                      name="from"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Click to select a date"
                          onChange={(date) => {
                            field.onChange(date)
                            setTodayDate(date);
                          }}
                          selected={field.value}
                          minDate={new Date()}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group before-con after">
                    <Controller
                      control={control}
                      name="to"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Click to select a date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          minDate={todayDate==null? new Date() : todayDate}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <select
                      className="form-select form-control"
                      {...register("status", { required: false })}
                      id="validationCustom12"
                      aria-label="Default select example"
                      required
                    >
                      <option className="disabled-value d-none" value="">
                        Select Status
                      </option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                    <div className="invalid-feedback">
                      Please select status.
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="theme-btn btn-group-right text-end">
                    {loading === false ? (
                      <button type="submit" className="btn">
                        Save
                      </button>
                    ) : (
                      <Button className="btn  spinner" variant="dark" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                        />
                        Please Wait...
                      </Button>
                    )}

                    <Link to="/admin/coupen-management" className="btn">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default AddNewcoupen;
