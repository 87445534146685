import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { Button, Spinner } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormValidation from "../../../../formValidation/formValidation";
import { getMethodDataSupplier, postMethodData } from "../../adminApi/api";

//jodit-editor
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import { createElement } from "react";

const AddNewCms = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [content, setContent] = useState("");

  let showDataToUser = false;

  const [redirection, setRedirection] = useState(false);
  useLayoutEffect(() => {
    const ls = JSON.parse(localStorage.getItem("adminDetails"));
    // console.log("uselayout",JSON.parse(localStorage.getItem("adminDetails")));

    for (let i = 0; i <= ls?.my_permissions?.length; i++) {
      if (ls?.my_permissions[i]?.name === "CMS") {
        for (let j = 0; j <= ls?.my_permissions[i]?.permissions?.length; j++) {
          if (ls?.my_permissions[i]?.permissions[j]?.name === "Add ") {
            // console.log(ls?.my_permissions[i]?.permissions[j]?.name);
            showDataToUser = true;
          }
        }
      }
    }
    // console.log(showDataToUser);
    if (showDataToUser === false) {
      // console.log("showDataToUser ",showDataToUser)
      setRedirection(true);
    }
  }, [content]);

  const navigate = useNavigate();
  // if (redirection) navigate('/admin/cms');
  // form validation rules
  const validationSchema = Yup.object().shape({
    // password: Yup.string()
    //   .required("Password is required")
    //   .min(6, "Password is too short - should be 6 chars minimum.")
    //   .max(18, "Password is too long - should be 18 chars minimum.")
    //   .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
    //     "Please Check Password Format"
    //   ),
    // confirm_password: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "Passwords must match")
    //   .min(6, "Confirm password is too short - should be 6 chars minimum.")
    //   .max(18, "Password is too long - should be 18 chars minimum.")
    //   .required("Confirm Password is required"),
    // email: Yup.string().email("The email must be a valid email. "),
    // phone: Yup.string().min(14).required("A phone number is required"),
    // zipcode: Yup.string().min(5).required("A zipcode is required").max(5),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset } = useForm(formOptions);
  // const dispatch = useDispatch()
  // const PORT = PORTNUMBER.PORTNUMBER

  const Token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  // const FormValidationNew = FormValidation;

  // const [formValidation, setFormValidation] = useState({
  //   Email: "",
  //   Mobile: "",
  //   default: "",
  //   Phone: "",
  //   setPassErrFormat: "",
  //   Success: null,
  // });

  const onSubmit = (data) => {
    // console.log("Data", data);
    // // console.log(editorData.JSON.stringfy())
    // const replaced=editorData.replace(/\"/g, "&quote;");
    const replaced = content.replace('\\"', /&quot;/g);
    const reverted = replaced.replace(/&quot;/g, '\\"');
    // console.log('replace',replaced);
    // console.log('after replace',reverted);
    setLoading(true);

    const fs = new FormData();
    for (var key in data) {
      fs.append(key, data[key]);
    }
    fs.append("content", replaced);

    postMethodData("/cms-pages/store", fs, Token)
      .then((res) => {
        if (res?.data?.status === true) {
          setLoading(false);
          toast(res?.data?.message);
          navigate("/admin/cms");
          reset();
        } else {
          setLoading(false);
          // console.log(res);
          toast.error("Somthing Went Wrong!");
        }

        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });

    return false;
  };

  // ck editor
  // const [editorData, setEditorData] = useState();

  //jodit editor
  

 

  // const onChange = useCallback((newContent) => {
  //   // console.log('onchange',newContent);
  //   setContent(newContent);
  // }, [setContent]);
 const [Contentddd,setContentddd] = useState("");
const editor = useRef();
const editorRef = useRef(null);

const handleChange = useCallback((value) => {
  setContentddd(value);

},[setContentddd])



  // const onBlur = (content) => {
  //   console.log("Blur event. Content: ", content);
  //   editorRef.current?.events?.fire("focus");
  // };

  // const onChange = (content) => {
  //   console.log("Change event. Content: ", content);
  //   setContent(content);
  //   editorRef.current?.events?.fire("focus");
  // };


// const handleImageUpload = (data) => {
//   setContent(content + `<img src="${data.files[0]}"/>`);
// };

  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="main-head">
                <h4>Add New CMS</h4>
              </div>
            </div>
            <div className="col-6">
              <div className="theme-btn text-end top-head">
                <Link to="/admin/cms" className="btn">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="project-table">
        <div className="col-12 table-order-box shadow">
          <div className="tab-head">
            <small className=" form-text d-block text-center mb-1 text-muted">
              {/* {formValidation.default} */}
            </small>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="needs-validation row"
            >
              <div className="mb-3 col-lg-6">
                <div className="mb-2">Title</div>
                <input
                  type="text"
                  {...register("title", { required: true })}
                  className="form-control col-md-6"
                  placeholder="Title"
                  required
                />
              </div>
              <div className="mb-3 col-lg-6">
                <div className="mb-2">Meta Keyword</div>
                <input
                  type="text"
                  {...register("meta_keyword", { required: true })}
                  className="form-control"
                  placeholder="Meta Keyword"
                  required
                />
              </div>
              <div className="mb-3 col-lg-6">
                <div className="mb-2">Description</div>
                <input
                  type="text"
                  {...register("meta_description", { required: true })}
                  className="form-control"
                  placeholder="Description"
                  required
                />
              </div>
              <div className="mb-3 col-lg-6">
                <div className="mb-2">Status</div>
                <select
                  className="form-select form-control"
                  {...register("status", { required: false })}
                  id="validationCustom12"
                  aria-label="Default select example"
                  required
                >
                  <option className="disabled-value d-none" value="">
                    Select Status
                  </option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <div className="mb-3">
                <div className="mb-2">Content</div>
           
                <JoditEditor
                  ref={editorRef}
                  // imageEditable={true}
                  tabIndex={1}
                  value={content}
                  onBlur={newContent => setContent(newContent)}
                  onChange={newContent => handleChange(newContent)}
                  config={{
                    toolbar: true,
                    autofocus: false,
                    tabIndex: 1,
                    defaultActionOnPaste: 'insert_clear_html',
                    beautyHTML: true,
                    // image: true,
                    language: "en-us",
                    toolbarButtonSize: "large",
                    uploader: {
                      url: BASE_URL+"/upload-editor-files", //your upload api url
                      insertImageAsBase64URI: false, //not inster base64
                      // imagesExtensions: ["jpg", "png", "jpeg", "gif"],
                      headers: {
                        authorization: `Bearer  ${localStorage.getItem(
                          "token"
                        )}`,
                      },
                      filesVariableName: function (t) {
                        // return 'files[' + t + ']';
                        // console.log("t",t)
                        return "file";
                      }, //"files",
                      withCredentials: false,
                      pathVariableName: "path",
                      format: "json",
                      method: "POST",
                      prepareData: function (formdata) {
                        // debugger;
                        // console.log(formdata)
                        return formdata;
                      },
                      isSuccess:async function (e) {
                        // debugger;
                        console.log(e,"isSuccess")
                        return await e.status;
                      },
                      getMessage: function (e) {
                        // debugger;
                        console.log("getMessage")
                        return [""];
                      },
                      process: function (resp) {
                        //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                        // debugger;
                        console.log(resp);
                        // console.log(resp.result.data.file_url);
                        // let files = [];
                        // files.unshift(resp.data);
                        return {
                          files: [resp?.result?.data?.file_url],
                          error: resp?.msg,
                          msg: [""],
                        };
                      },
                      defaultHandlerSuccess: function(data){
                          if(data){
                            // function (data) {
                              setContent(content+`<img src="${data?.files[0]}"/>`);
                              console.log(data)
                            // }
                          }
                      },
                    },
                  }}
                />
              </div>
              <div className="theme-btn btn-group-right text-end mt-3">
                <button type="submit" className="btn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default AddNewCms;
